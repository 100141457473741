import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import DataTable from "react-data-table-component";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Form, Radio, Select, DatePicker } from "antd";
import Table_agent from "./Table_agent";
import { incNumber } from "../../Redux/actions";
import { MdReceipt } from "react-icons/md";

const Application_Console = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Agentforms = useSelector(
    (state) => state.SingleAgentForms.agentTehsilforms
  );
  console.log(28, Agentforms);
  const [items, setItems] = useState();
  const ID = items?.user?._id;
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const [retailerID, setretailerID] = useState("");
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));
  const [Data, setData] = useState(Agentforms);
  const [Form11Data, setForm11Data] = useState(Agentforms);
  const [FilteredData, setfilteredData] = useState(Agentforms);
  const [application_type, setapplication_type] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchdata, setsearch] = useState("");
  useEffect(() => {
    axios
      .get("https://api.maharashtraseva.com/api/getAll")
      .then(async (res) => {
        const data = await res.data;
        if (Agentforms.length <= 0) {
          const AgentFormFilter = data.filter(
            (item) => item.agentId == userData.user._id
          );
          setData(AgentFormFilter);
          setForm11Data(AgentFormFilter);
          setfilteredData(AgentFormFilter);
        } else {
          setData(Agentforms);
          setForm11Data(Agentforms);
          setfilteredData(Agentforms);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handler = () => {
    if (application_type != null && status != null) {
      const filtered = Data.filter((item) => {
        if (
          item.application_type === application_type && item.status === status
        ) {
          return item;
        }
      });
      setForm11Data(filtered);
      setfilteredData(filtered);
    } else {
      setForm11Data(Data);
      setfilteredData(Data);
    }
  };

  useEffect(() => {
    handler();
    console.log(85,application_type);
    console.log(85,status);
  }, [application_type, status]);

  const searchByName = (e) => {
    setsearch(e.target.value);
    const value = e.target.value;

    if (value.length > 0) {
      const searchdata = Form11Data.filter((item, index) => {
        if (item.createdByName != undefined && item.createdByName.length > 0) {
          const name = item.createdByName;
          if (name.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        }
      });
      setfilteredData(searchdata);
    } else {
      setfilteredData(Form11Data);
    }
  };
  // useEffect(() => {
  //   axios
  //     .get("https://api.maharashtraseva.com/api/getAll")
  //     .then(async (res) => {
  //       const data = await res.data;
  //       setfilteredData(data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);
  const receiptFun = (id) => {
    navigate(`${process.env.PUBLIC_URL}/FormFurReceipt/${id}`);
  };
  const ewsview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_ews/${id}`);
  };

  const incomeview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_income/${id}`);
  };

  const Non_Cremylayerview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_Non_Cremylayer/${id}`);
  };

  const ageNationalityview = (id) => {
    navigate(`${process.env.PUBLIC_URL}/retailer_ageNationality/${id}`);
  };
  // const customStyles = {
  //   rows: {
  //     style: {
  //       border: "1px solid black",
  //       // Add any other CSS properties you want to apply to the rows
  //     },
  //   },
  //   headRow: {
  //     style: {
  //       // Add any styles for the header row
  //       border: "1px solid black",
  //       backgroundColor: "skyblue",
  //       color: "black",
  //     },
  //   },
  //   headCells: {
  //     style: {
  //       // Add any styles for the header cells
  //       border: "1px solid black",
  //     },
  //   },
  //   cells: {
  //     style: {
  //       // Add any styles for the regular cells
  //       border: "1px solid black",
  //     },
  //   },
  // };
  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWeight:"600",
       fontSize:"18px",
      //  width:"fit-content"
        // Add any other CSS properties you want to apply to the rows
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        border: "1px solid black",
        backgroundColor:"#0094ef  ",
        color: "white",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        border: "1px solid black",
         width:"fit-content"
      },
    },
    cells: {
      style: {
        // Add any styles for the regular cells
        border: "1px solid black",
        width:"fit-content"
      },
    },
  };
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  const columns = [
    {
      name: "New",
      width: "100px",

      selector: (row, i) => (
        <div
          className="d-flex justify-content-space-between"
          style={{
            fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
          }}
        >
          {i + 1} {row?.isNew && row.status == "IN-PROGRESS" ? <div className="new">New</div> : <></>}
        </div>
      ),
    },
    {
      name: "Application-Type",
      width:"300px",
      selector: (row) => {
        if (row.application_type == "EWS") {
          return (
            <>
              <button
                type="button "
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
                  color:"blue",
                  marginRight:"65px",
                  
                }}
                onClick={() => {
                  ewsview(row._id);
                  dispatch(incNumber());
                }}
              >
                EWS certificate
              </button>
              <MdReceipt  style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

            </>
          );
        } else if (row.application_type == "Age_Nationality") {
          return (
            <>
              <button
                type="button "
                onClick={() => {
                  ageNationalityview(row._id);
                  dispatch(incNumber());
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
                  color:"blue",
                  marginRight:"65px",
                 
                }}
              >
                Age certificate
              </button>
              <MdReceipt  style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

            </>
          );
        } else if (row.application_type == "Non_Cremylayer certificate") {
          return (
            <>
              <button
                type="button "
                onClick={() => {
                  Non_Cremylayerview(row._id);
                  dispatch(incNumber());
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
                  color:"blue",
                  marginRight:"65px",
                }}
              >
                {" "}
                Non_Cremylayer certificate
              </button>
              <MdReceipt  style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

            </>
          );
        } else if (row.application_type == "Income certificate") {
          return (
            <>
              <button
                type="button "
                onClick={() => {
                  incomeview(row._id);
                  dispatch(incNumber());
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
                  color:"blue",
                  marginRight:"65px",
                }}
              >
                {" "}
                Income certificate
              </button>
              <MdReceipt  style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

            </>
          );
        } else {
          return row.application_type;
        }
      },
    },
    // {
    //   name: "Created By ",
    //   selector: (row) => (
    //     <>
    //       <div
    //         style={{
    //           backgroundColor: "transparent",
    //           border: "none",
    //           fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
    //         }}
    //       >
    //         {row.createdByName}
    //       </div>
    //     </>
    //   ),
    // },
    {
      name: "Applicant",
      width:"300px",
      selector: (row) => (
        <>
          <div
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
            }}
          >
            {row.Data.fullName_English}
          </div>
        </>
      ),
    },
    {
      name: "Status ",
      selector: (row) => (
        <>
          <div
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
            }}
          >
            {row.status}
          </div>
        </>
      ),
    },

    {
      name: "Created On ",
      width:"300px",
      selector: (row) => (
        <>
          <div
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
            }}
          >
            {GetDate(row.createdAt)}
          </div>
        </>
      ),
    },
    {
      name: "Updated Date ",
      width:"300px",
      selector: (row) => (
        <>
          <div
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "bold" : "",
            }}
          >
            {GetDate(row.updatedAt)}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb title={" Application Console :"} parent={"Certificate"} />

      <div
        className="border  p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div
          className="col-lg-12 text-center border rounded"
          style={{ backgroundColor: "#e0e0e0" }}
        >
          <h3 class="box-title">
            <span id="CPH_lbl_AppDtl">Tehsil Application Type </span>
          </h3>
        </div>
        <Form name="basic" autoComplete="off" layout={"vertical"}>
          <div className="row">
            {" "}
            <div className="col-md-4">
              <label htmlFor="" className="mb-3">
                {" "}
                Certificate Name
                <span className="red">*</span>
              </label>
              <Form.Item>
                <Select
                  placeholder="--Select Application Stauts--"
                  onChange={(e) => setapplication_type(e)}
                >
                  <Select.Option value="EWS">EWS certificate</Select.Option>
                  <Select.Option value="Income certificate">
                    Income certificate
                  </Select.Option>
                  <Select.Option value="Non_Cremylayer certificate">
                    Non-cremylayer certificate
                  </Select.Option>
                  <Select.Option value="Age_Nationality">
                    Age-Nationality certificate
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-4">
              <label htmlFor="" className="mb-3">
                {" "}
                Application Status
                <span className="red">*</span>
              </label>
              <Form.Item>
                <Select
                  placeholder="--Select Application Stauts--"
                  onChange={(e) => setStatus(e)}
                >
                  <Select.Option value="IN-PROGRESS">IN-PROGRESS</Select.Option>
                  <Select.Option value="SUBMITTED">SUBMITTED</Select.Option>
                  <Select.Option value="PENDING">PENDING</Select.Option>
                  <Select.Option value="COMPLETE">COMPLETE</Select.Option>
                  <Select.Option value="REJECTED">REJECTED</Select.Option>
                </Select>
              </Form.Item>
            </div>
           
            <div className="col-lg-12">
              <h6>
                Search :{" "}
                <span>
                  <input
                    type="text "
                    value={searchdata}
                    onChange={searchByName}
                    style={{
                      border: "1px solid black",
                      borderRadius: "3px",
                    }}
                  />
                </span>
              </h6>

              <div className="row">
                {
                  // FilteredData.length>0 ? <Table_admin FilteredData={FilteredData}/>:<div> No Found Data</div>
                  FilteredData.length > 0 ? (
                    <>
                      <DataTable
                        columns={columns}
                        data={FilteredData}
                        pagination={true}
                        customStyles={customStyles}
                      />
                    </>
                  ) : (
                    <div> No Found Data</div>
                  )
                }
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Application_Console;
