import React, { useEffect, useState } from "react";
import man from "../../../assets/images/dashboard/man.png";
import axios from "axios";

const UserPanel = () => {
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const [role, setRole] = useState();
  const [ProfileImg, setProfileImg] = useState();
  useEffect(()=>{

	  if (userData.user.role === "retailer") {
		setRole("MESK USER");
	  } else if (userData.user.role === "agent") {
		setRole("MESK Co-Ordinetor");
	  } else if (userData.user.role === "admin") {
		setRole("ADMIN");
	  }
    axios
    .get(`https://api.maharashtraseva.com/api//user/getoneuser/${userData.user._id}`)
    .then((res) => {
      const response = res.data;
      console.log(37,response[0].profilePicture)
      setProfileImg(response[0].profilePicture)
    })
    .catch((err) => console.log(40, err));
  },[userData]);

  return (
    <div>
      <div className="sidebar-user text-center">
        <div >
          <img
            className="img-60 rounded-circle lazyloaded blur-up"
            // style={{height:"100%",width:"100%",borderRadius:"50%"}}
            src={ProfileImg}
            alt="#"
          />
        </div>
        <h6 className="mt-3 f-14 " style={{color:"#ff5e0e"}}>{userData.user.name}</h6>
        <p  className="text-white">{role}</p>
      </div>
    </div>
  );
};

export default UserPanel;
