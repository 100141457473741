import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Breadcrumb from "../common/breadcrumb";

function UserPriceReport() {
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));
  const [AllUserFormData, setAllUserFormData] = useState([]);
  const [compGst, setCompGst] = useState([]);
  const [indiGst, setIndiGst] = useState([]);
  const [e_gazzet, sete_Gazzet] = useState([]);
  const [foodLicence, setfoodLicence] = useState([]);
  const [learning, setLearning] = useState([]);
  const [permanant, setpermanant] = useState([]);
  const [renew, setRenew] = useState([]);
  const [ShopAct, setShopAct] = useState([]);
  console.log(11, AllUserFormData);
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/getAll/Form11`)
      .then(async (res) => {
        const data = await res.data;
        console.log(19, data);
        const Company_Gst = data.filter(
          (item) =>
            item.application_type == "CompanyGST" &&
            item.createdByName === userRespons.user.name
        );
        setCompGst(Company_Gst);
        const individual_Gst = data.filter(
          (item) =>
            item.application_type == "indGST" && item.createdByName == userRespons.user.name
        );
        setIndiGst(individual_Gst);
        const e_Gazzet = data.filter(
          (item) =>
            item.application_type == "e_gazzet" && item.createdByName == userRespons.user.name
        );
        sete_Gazzet(e_Gazzet);
        const foodLicence = data.filter(
          (item) => item.application_type === "foodLicence" && item.createdByName == userRespons.user.name);
        setfoodLicence(foodLicence);
        const Leanning = data.filter(
          (item) => item.application_type == "learning" && item.createdByName == userRespons.user.name);
        setLearning(Leanning);
        const permanant = data.filter((item) => item.application_type == "permanant" && item.createdByName == userRespons.user.name);
        setpermanant(permanant);
        const renew = data.filter(
          (item) =>
            item.application_type == "renew" && item.createdByName == userRespons.user.name
        );
        setRenew(renew);
        const ShopAct = data.filter(
          (item) =>
            item.application_type == "shopAct" && item.createdByName == userRespons.user.name
        );
        setShopAct(ShopAct);
      }).catch((err)=>console.log(err));
    axios.get(`https://api.maharashtraseva.com/api/recharge`).then(async (res) => {
      const data = await res.data;
      const getData = data.filter((item) => item.user === userRespons.user._id);
      setAllUserFormData(getData);
    });
  }, []);

  const GetCompanyGstFormPrice = () => {
    let totalCompanyPrice = 0;
    const getCompanyGstPrice = AllUserFormData.filter(
      (item) => item.expenceFor === "companyGST"
    );
    if (getCompanyGstPrice.length > 0) {
      getCompanyGstPrice.forEach((item) => {
        if (item.isExpence === "true") {
          totalCompanyPrice += item.amount;
        }
      });
    }
    return totalCompanyPrice;
  };
  const GetIndividualGstFormPrice = () => {
    let individualGST = 0;
    const getindividualGSTPrice = AllUserFormData.filter(
      (item) => item.expenceFor === "individualGST"
    );
    if (getindividualGSTPrice.length > 0) {
      getindividualGSTPrice.forEach((item) => {
        if (item.isExpence === "true") {
          individualGST += item.amount;
        }
      });
    }
    return individualGST;
  };
  const GeteghazzettFormPrice = () => {
    let individualGST = 0;
    const getindividualGSTPrice = AllUserFormData.filter(
      (item) => item.expenceFor === "eGazzet"
    );
    if (getindividualGSTPrice.length > 0) {
      getindividualGSTPrice.forEach((item) => {
        if (item.isExpence === "true") {
          individualGST += item.amount;
        }
      });
    }
    return individualGST;
  };
  const GetFoodLicenceFormPrice = () => {
    let individualGST = 0;
    const getindividualGSTPrice = AllUserFormData.filter(
      (item) => item.expenceFor === "foodLicence"
    );
    if (getindividualGSTPrice.length > 0) {
      getindividualGSTPrice.forEach((item) => {
        if (item.isExpence === "true") {
          individualGST += item.amount;
        }
      });
    }
    return individualGST;
  };
  const GetLearningDLFormPrice = () => {
    let individualGST = 0;
    const getindividualGSTPrice = AllUserFormData.filter(
      (item) => item.expenceFor === "LearningDL"
    );
    if (getindividualGSTPrice.length > 0) {
      getindividualGSTPrice.forEach((item) => {
        if (item.isExpence === "true") {
          individualGST += item.amount;
        }
      });
    }
    return individualGST;
  };
  const GetpermanantDLFormPrice = () => {
    let individualGST = 0;
    const getindividualGSTPrice = AllUserFormData.filter(
      (item) => item.expenceFor === "PermanatDl"
    );
    if (getindividualGSTPrice.length > 0) {
      getindividualGSTPrice.forEach((item) => {
        if (item.isExpence === "true") {
          individualGST += item.amount;
        }
      });
    }
    return individualGST;
  };
  const GetRenewDLFormPrice = () => {
    let individualGST = 0;
    const getindividualGSTPrice = AllUserFormData.filter(
      (item) => item.expenceFor === "renewDl"
    );
    if (getindividualGSTPrice.length > 0) {
      getindividualGSTPrice.forEach((item) => {
        if (item.isExpence === "true") {
          individualGST += item.amount;
        }
      });
    }
    return individualGST;
  };
  const GetShopActFormPrice = () => {
    let individualGST = 0;
    const getindividualGSTPrice = AllUserFormData.filter(
      (item) => item.expenceFor === "ShopAct"
    );
    if (getindividualGSTPrice.length > 0) {
      getindividualGSTPrice.forEach((item) => {
        if (item.isExpence === "true") {
          individualGST += item.amount;
        }
      });
    }
    return individualGST;
  };
  const companyPrice = GetCompanyGstFormPrice();
  const Individual = GetIndividualGstFormPrice();
  const Egazzet = GeteghazzettFormPrice();
  const foodLice = GetFoodLicenceFormPrice();
  const learnning = GetLearningDLFormPrice();
  const per = GetpermanantDLFormPrice();
  const renewDL = GetRenewDLFormPrice();
  const ShopAc = GetShopActFormPrice();

  return (
    <>
    <Breadcrumb title={"Application Statistics"} parent={"license"} />
      <table class="table border border-3 mt-5">
        <thead>
          <tr className="border border-3">
            <th scope="col border border-3 bg-dark">Sr No</th>
            <th scope="col border border-3 bg-dark">Application Type</th>
            <th scope="col border border-3 bg-dark">Total Forms</th>
            <th scope="col border border-3 bg-dark">Form Creadit</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border border-3">
            <th scope="row border border-3 bg-dark">1</th>
            <td  className="border border-3">Company GST</td>
            <td  className="border border-3">{compGst.length}</td>
            <td  className="border border-3">{companyPrice}</td>
          </tr>
          <tr className="border border-3">
            <th scope="row border border-3 bg-dark">2</th>
            <td className="border border-3">individual GST</td>
            <td className="border border-3">{indiGst.length}</td>
            <td className="border border-3">{Individual}</td>
          </tr>
          <tr className="border border-3">
            <th scope="row border border-3 bg-dark">3</th>
            <td className="border border-3">e-Gazette</td>
            <td className="border border-3">{e_gazzet.length}</td>
            <td className="border border-3">{Egazzet}</td>
          </tr>
          <tr className="border border-3">
            <th scope="row border border-3 bg-dark">4</th>
            <td className="border border-3">food Licence</td>
            <td className="border border-3">{foodLicence.length}</td>
            <td className="border border-3">{foodLice}</td>
          </tr>
          <tr className="border border-3">
            <th scope="row border border-3 bg-dark">5</th>
            <td className="border border-3">Learning DL</td>
            <td className="border border-3">{learning.length}</td>
            <td className="border border-3">{learnning}</td>
          </tr>
          <tr className="border border-3">
            <th scope="row border border-3 bg-dark">6</th>
            <td className="border border-3">Permanant DL</td>
            <td className="border border-3">{permanant.length}</td>
            <td className="border border-3">{per}</td>
          </tr>
          <tr className="border border-3">
            <th scope="row border border-3 bg-dark">7</th>
            <td className="border border-3">Renew DL</td>
            <td className="border border-3">{renew.length}</td>
            <td className="border border-3">{renewDL}</td>
          </tr>
          <tr className="border border-3" >
            <th scope="row border border-3 bg-dark">8</th>
            <td className="border border-3" >Shop Act</td>
            <td className="border border-3">{ShopAct.length}</td>
            <td className="border border-3">{ShopAc}</td>
          </tr>
    
        </tbody>
      </table>
      <div className="div">
       <h3><b>Total :{companyPrice+Individual+Egazzet+foodLice+learnning+per+renewDL+ShopAc}</b></h3>
      </div>
    </>
  );
}

export default UserPriceReport;
