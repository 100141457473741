import React from "react";
// import logo1 from '../../assets/images/image/logo1.png';
// import logo2 from '../../assets/images/image/logo2.png';
// import logo3 from '../../assets/images/image/logo3.png';
// import logo4 from '../../assets/images/image/logo4.png';
import aboutpic1 from "../../assets/images/image/aboutpic1.jpeg";
import aboutpic2 from "../../assets/images/image/aboutpic2.jpeg";
import Header from "./Header";
import Footer from "./Footer";

// import './Landing.css'
const About = () => {
  return (
    <>
      <div className="head">
        <Header />
      </div>
      {/* <div className="AboutPage">
        <h1 className=" about-txt mt-4 text-center">About Us</h1>
        <hr className=" hrline" />
        <div class="row">
          <div className="col-lg-8 aboutleft">
            <h4 className="text-head fw-bold"> व्यवसाय सेट करणे सोपे:</h4>
            <p>
              महाराष्ट्र इन्फॉर्मेटिक्स प्राइवेट लिमिटेड व्यवसाय स्थापित करणे
              अत्यंत सोपे आहे. आमच्या मार्गदर्शनामुळे आपण केवळ 3 ते 7 दिवसात
              स्वत: चे महाराष्ट्र इन्फॉर्मेटिक्स प्राइवेट लिमिटेड सेट करू शकता,व
              तुमच्या अपेक्षेपेक्षा जास्त कमाई करू शकता.
            </p>
            <h4 className="text-head fw-bold"> अत्यंत कमी गुंतवणूक :</h4>
            <p>
              महाराष्ट्र इन्फॉर्मेटिक्स प्राइवेट लिमिटेड ाचा सर्वात मोठा फायदा
              म्हणजे त्याची गुंतवणूक आणि प्रभावीपणा.आपण मार्केटमध्ये असणारे अनेक
              व्यवसाय आणि महाराष्ट्र इन्फॉर्मेटिक्स प्राइवेट लिमिटेड हा व्यवसाय
              यांची तुलना करा,नक्कीच तुमच्या लक्षात येईल,अत्यंत कमी
              गुंतवणुकीमध्ये जास्तीत जास्त नफा म्हणजेच महिना लाख रुपयापेक्षा
              अधिक आणि कमीत कमी ७० हजारापर्यंत मिळवून देणारा अत्यंत कमी
              गुंतवणुकीतला व्यवसाय म्हणजे फक्त महाराष्ट्र इन्फॉर्मेटिक्स
              प्राइवेट लिमिटेड .
            </p>
          </div>
          <div className="col-lg-4 aboutright">
            <img className="aboutpic1" src={aboutpic1} alt="" />
          </div>
        </div>
        <hr className=" mb-5 hrline" />
        <div class="row">
          <div className="col-lg-4 aboutleft2">
            <img className="aboutpic2" src={aboutpic2} alt="" />
          </div>
          <div className="col-lg-8 aboutright2">
            <h4 className="text-head fw-bold"> प्रशिक्षण आणि कार्यशाळा:</h4>
            <p>
              प्रशिक्षण आणि विकास या क्षेत्रात महाराष्ट्र इन्फोटेक. ग्रुपची
              स्वतःची गुणवत्ता आहे. आमच्या महाराष्ट्र इन्फॉर्मेटिक्स प्राइवेट
              लिमिटेड घेणाऱ्यासाठी व त्यांना प्रत्येक सर्व्हिसमध्ये मार्गदर्शन
              करण्यासाठी आमच्याकडे वेगळा समर्पित प्रशिक्षकांचा कार्यसंघ आहे.
              तसेच महिला ही हा व्यवसाय अत्यंत व्यवस्थित सांभाळू शकतात फक्त
              त्यांना गरज असते योग्यरित्या उभे होईपर्यंत अतिरिक्त प्रशिक्षणाची
              आणि आमचा प्रशिक्षण संघ नेमके हेच करतो. अशा सत्रामध्ये जात असणाऱ्या
              महिला, तरुण,तरुणीसाठी आम्ही त्यांना अधिक सुरक्षित बनविण्यासाठी
              विशेष बॅच आयोजित करतो. आम्ही अभिमानाने म्हणू शकतो की,प्रशिक्षण आणि
              विकास समर्थनाच्या दृष्टीने आम्ही सर्वांत उत्कृष्ट आहोत. कारण
              आमच्या महाराष्ट्र इन्फोटेक. चा प्रशिक्षण ग्रुप हा अत्यंत अनुभवी
              आणि उच्च शिक्षित आहे. तर मग सुरु करा साथीने महाराष्ट्र
              इन्फॉर्मेटिक्स प्राइवेट लिमिटेड हा व्यवसाय आणि कमवा महिना भरघोस
              कमाई .
            </p>
          </div>
        </div>
        <div className="row single">
          <div className="col-lg-12 ">
            <h4 className="text-head fw-bold"> वेळेचे व्यवस्थापन :</h4>
            <p>
              महाराष्ट्र इन्फॉर्मेटिक्स प्राइवेट लिमिटेड हा व्यवसाय आपण एकदा एका
              जागी किवा ऑफिस मध्ये सेट केल्यास तुम्ही कोणत्या ही वेळी कुठेही
              असला तरी तुम्ही तुमच्या वेळेनुसार नागरिकांची कामे अत्यंत कमी
              कालवधीमध्ये करू शकता.
            </p>
          </div>
        </div>
      </div> */}
      <div className="" style={{ margin: "6rem" }}>
        <div
          className="row text-center border border-3"
          style={{ marginTop: "120px" }}
        >
          <div className="col-lg-6 my-4">
            {/* <div className="col-lg-8 aboutleft"> */}
            <h3 className="text-head fw-bold"> व्यवसाय सेट करणे सोपे:</h3>
            <p style={{ lineHeight: "4",fontSize:"15px" }}>
              <b>
                महाराष्ट्र ई-सेवा केंद्र™ व्यवसाय स्थापित करणे अत्यंत सोपे आहे.
                महाराष्ट्र इन्फॉर्मेटिक्स प्रा.ली कंपनीच्या मार्गदर्शनामुळे आपण
                केवळ 3 ते 7 दिवसात स्वत: चे महाराष्ट्र ई-सेवा केंद्र™ सेट करू
                शकता,व तुमच्या अपेक्षेपेक्षा जास्त कमाई करू शकता.
              </b>
            </p>
            <h3 className="text-head fw-bold"> अत्यंत कमी गुंतवणूक :</h3>
            <p style={{ lineHeight: "4",fontSize:"15px" }}>
              {" "}
              <b>
                महाराष्ट्र ई-सेवा केंद्र™ व्यवसायाचा सर्वात मोठा फायदा म्हणजे
                त्याची गुंतवणूक आणि प्रभावीपणा.आपण मार्केटमध्ये असणारे अनेक
                व्यवसाय आणि महाराष्ट्र ई-सेवा केंद्र™ हा व्यवसाय यांची तुलना
                करा,नक्कीच तुमच्या लक्षात येईल,अत्यंत कमी गुंतवणुकीमध्ये जास्तीत
                जास्त नफा म्हणजेच महिना लाख रुपयापेक्षा अधिक आणि कमीत कमी ७०
                हजारापर्यंत मिळवून देणारा अत्यंत कमी गुंतवणुकीतला व्यवसाय म्हणजे
                फक्त महाराष्ट्र ई-सेवा केंद्र
              </b>
            </p>
            {/* </div> */}
          </div>
          <div className="col-lg-6 my-4">
            <div
              className="imgbox"
              style={{ height: "30rem", borderRadius: "50px" }}
            >
              <img
                className=""
                style={{ height: "100%", borderRadius: "50px" }}
                src="https://mhae-seva.s3.amazonaws.com/aboutpic1.60c72346f47f57bbfea8.jpeg.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          className="row text-center border border-3"
          style={{ marginTop: "60px" }}
        >
          <div className="col-lg-6 my-4">
            <div className="imgbox" style={{ height: "30rem" }}>
              <img
                className=""
                style={{ height: "100%", borderRadius: "50px" }}
                src="https://mhae-seva.s3.amazonaws.com/aboutpic2.26baaf055924f20b45b1+(1).jpeg.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 my-4">
            <h3 className="text-head fw-bold"> प्रशिक्षण आणि कार्यशाळा:</h3>
            <p style={{ lineHeight: "4",fontSize:"15px" }}>
              {" "}
              <b>
                लीगल सर्व्हिसेस, टेक्निकल सर्व्हिसेस, डेव्लपमेंट, प्रशिक्षण आणि
                महाराष्ट्र ई-सेवा केंद्र™ या क्षेत्रात महाराष्ट्र इन्फॉर्मेटिक्स
                ग्रुपची स्वतःची गुणवत्ता आहे. आमच्या महाराष्ट्र ई-सेवा केंद्र™
                प्रकल्प घेणाऱ्यासाठी व त्यांना प्रत्येक सर्व्हिसमध्ये मार्गदर्शन
                करण्यासाठी आमच्याकडे वेगळा समर्पित प्रशिक्षकांचा कार्यसंघ आहे.
                तसेच महिला ही हा व्यवसाय अत्यंत व्यवस्थित सांभाळू शकतात फक्त
                त्यांना गरज असते योग्यरित्या उभे होईपर्यंत अतिरिक्त प्रशिक्षणाची
                आणि आमचा प्रशिक्षण संघ नेमके हेच करतो. अशा सत्रामध्ये जात
                असणाऱ्या महिला, तरुण,तरुणीसाठी आम्ही त्यांना अधिक सुरक्षित
                बनविण्यासाठी विशेष बॅच आयोजित करतो. आम्ही अभिमानाने म्हणू शकतो
                की,प्रशिक्षण आणि विकास समर्थनाच्या दृष्टीने आम्ही सर्वांत
                उत्कृष्ट आहोत. कारण आमच्या महाराष्ट्र ई-सेवा केंद्र™ प्रशिक्षण
                ग्रुप हा अत्यंत अनुभवी आणि उच्च शिक्षित आहे. तर मग सुरु करा
                महाराष्ट्र इन्फॉर्मेटिक्सच्या साथीने महाराष्ट्र ई-सेवा केंद्र™
                हा व्यवसाय आणि कमवा महिना भरघोस कमाई 
              </b>
            </p>
          </div>
        </div>
        <div
          className="row text-center border border-3"
          style={{ marginTop: "60px" }}
        >
          <div className="col-lg-12 my-4">
            <h3 className="text-head fw-bold"> वेळेचे व्यवस्थापन :</h3>
            <p style={{ lineHeight: "3",fontSize:"15px" }}>
              {" "}
              <b>
                महाराष्ट्र ई-सेवा केंद्र™ हा व्यवसाय आपण एकदा एका जागी किवा ऑफिस
                मध्ये सेट केल्यास तुम्ही कोणत्या ही वेळी कुठेही असला तरी तुम्ही
                तुमच्या वेळेनुसार नागरिकांची कामे अत्यंत कमी कालवधीमध्ये करू
                शकता आणि चांगल्या प्रकारे सेवा देऊन पैसे कमवू शकता, आपणास
                कंपनीकडून कुठलेच बंधन घातले जात नाही जसे की शॉप किंवा शिक्षणाची
                अट आपण आहे त्या ठिकाणावरून आहे त्या वेळेमध्ये आपल्या सोयीनुसार
                महाराष्ट्र ई-सेवा केंद्र™ व्यवसाय करू शकता
              </b>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
