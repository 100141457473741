import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { MdReceipt } from "react-icons/md";

const MainTable_admin = ({ FilteredData }) => {
  const navigate = useNavigate();


  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWeight:"600",
       fontSize:"18px",
      //  width:"fit-content"
        // Add any other CSS properties you want to apply to the rows
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        border: "1px solid black",
        backgroundColor: " #0094ef 	",
        color: "white",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        border: "1px solid black",
         width:"fit-content"
      },
    },
    cells: {
      style: {
        // Add any styles for the regular cells
        border: "1px solid black",
        width:"fit-content"
      },
    },
  };

  const receiptFun = (id) => {
    navigate(`${process.env.PUBLIC_URL}/receipt/${id}`);
  };

  const columns = [
    {
      name: "New",
      width: "150px",

      selector: (row, i) => (
        <div
          className="d-flex justify-content-space-between"
          style={{
            fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "",
          }}
        >
          {i + 1} {row?.isNew && row.status == "IN-PROGRESS"? <div className="new">New</div> : <></>}
        </div>
      ),
    },
    {
      name: "Application-Type",
      width:"200px",
      selector: (row) => {
        if (row.application_type == "learning") {
          return (
            <div
              style={{
                fontWeight:row?.isNew && row.status == "IN-PROGRESS" ? "900" : "700",
              }}
              className="row"
            >
              <div className="col-lg-8">

              <button
                type="button "
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "900" : "700",
                 
                }}
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_learning/${row._id}`
                  )
                }
              >
              Learning DL
              </button>
              </div>
           <div className="col-lg-2 text-center">

             <MdReceipt  style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />
           </div>
            </div>
          );
        } else if (row.application_type == "permanant") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">

              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_permanent/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"&& row.status != "IN-PROGRESS" ? "900" : "700",
                 
                }}
              >
                permanent DL
              </button>
              </div>
              <div className="col-lg-2">
             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

              </div>
            </div>
            </>
          );
        } else if (row.application_type == "renew") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">

              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_renewal/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "900" : "700",
                 
                }}
              >
                {" "}
                Renew DL{" "}
              </button>
              </div>
              <div className="col-lg-2">
             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

              </div>
            </div>
            </>
          );
        } else if (row.application_type == "e_gazzet") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">
              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_eGazzet/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS" ? "900" : "700",
                 
                }}
              >
                e_gazette
              </button>
              </div>
              <div className="col-lg-2">
             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

              </div>
            </div>
            </>
          );
        } else if (row.application_type == "foodLicence") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">

              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_foodLicence/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "900" : "700",
                 
                }}
              >
                {" "}
                Food Licence
              </button>
              </div>
              <div className="col-lg-2">
             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

              </div>
            </div>
            </>
          );
        } else if (row.application_type == "CompanyGST") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">

              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_compGST/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "900" : "700",
                 
                }}
              >
                {" "}
                companyGST
              </button>
              </div>
              <div className="col-lg-2">
             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

              </div>
            </div>
            </>
          );
        } else if (row.application_type == "indGST") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">
              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_indiGST/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "900" : "700",
                 
                }}
              >
                individualGST{" "}
              </button>

              </div>
              <div className="col-lg-2">
             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

              </div>
            </div>
            </>
          );
        } else if (row.application_type == "passport") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">

              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_passport/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "900" : "700",
                 
                }}
              >
                passport{" "}
              </button>
              </div>
              <div className="col-lg-2">

             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />
              </div>
            </div>
            </>
          );
        } else if (row.application_type == "shopAct") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">

              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_shopAct/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "900" : "700",
                 
                }}
              >
                shopAct{" "}
              </button>
              </div>
              <div className="col-lg-2">
             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

              </div>
            </div>
            </>
          );
        } else if (row.application_type == "udhamAdhar") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">

              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_udhamadhar/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "900" : "700",
                 
                }}
              >
                UdhamAdhar{" "}
              </button>
              </div>
              <div className="col-lg-2">

             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />
              </div>
            </div>
            </>
          );
        } else if (row.application_type == "voterCard") {
          return (
            <>
            <div className="row">
              <div className="col-lg-8">

              <button
                type="button "
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/application_voterCard/${row._id}`
                  )
                }
               style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor:"pointer",
                 
                  color:"blue",
                  fontSize:"15px",
                  fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "900" : "700",
                 
                }}
              >
                voter Card{" "}
              </button>
              </div>
              <div className="col-lg-2">
             <MdReceipt style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />

              </div>
            </div>
            </>
          );
        } else {
          return row.application_type;
        }
      },
    },

    {
      name: "Created By ",
      width:"250px",
      selector: (row) => (
        <div
          style={{
            fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "",
          }}
        >
          {" "}
          {row.createdByName}
        </div>
      ),
    },
    {
      name: "Applicant",
      width:"350px",
      selector: (row) => {
        if (row.application_type == "learning"|| row.application_type == "permanant" || row.application_type == "renew") {
          return (
            <div
              style={{fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "",}}>{row.application_first_name + " " + row.application_last_name}
            </div>
          );
        }else if(row.application_type == "CompanyGST"){
          return (
            <div
              style={{fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "" }}>
              {row.companyName}
            </div>
          );
        }else if(row.application_type == "indGST"){
          return (
            <div
              style={{fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "" }}>
              {row.customerName}
            </div>
          );
        }else if( row.application_type == "passport" || row.application_type == "e_gazzet" || row.application_type == "foodLicence"){
          return (
            <div
              style={{fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "" }}>
              {row.firstName + " " + row.lastName}
            </div>
          );
        } else if(row.application_type == "shopAct"){
          return (
             <div style={{ fontWeight: row?.isNew && row.status != "IN-PROGRESS"? "bold" : "" }}>
               {row.FullNameEnglish}
             </div>
          )
         }else if(row.application_type == "udhamAdhar" ){
          return (
            <div
              style={{fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "" }}>
              {row.Owner_Name }
            </div>
          );
        }else if(row.application_type == "voterCard" ){
          return (
            <div
              style={{fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "" }}>
              {row.name+" "+ row.Surname }
            </div>
          );
        }
      },
    },
    {
      name: "Status ",
      selector: (row) => (
        <div
          style={{
            fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "",
          }}
        >
          {" "}
          {row.status}
        </div>
      ),
    },

    {
      name: "Created On ",
      width:"270px",
      selector: (row) => (
        <div
          style={{
            fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "",
          }}
        >
          {" "}
          {GetDate(row.createdAt)}
        </div>
      ),
    },
    {
      name: "Updated Date",
      width:"270px",
      selector: (row) => (
        <div
          style={{
            fontWeight: row?.isNew && row.status == "IN-PROGRESS"? "bold" : "",
          }}
        >
          {" "}
          {GetDate(row.updatedAt)}
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={FilteredData}
        pagination={true}
        customStyles={customStyles}
      />
    </div>
  );
};

export default MainTable_admin;
