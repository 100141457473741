import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import axios from "axios";

function YoutubeVideoCard() {
  const [videoData, setVideoData] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.maharashtraseva.com/api/youtube")
      .then(async (res) => {
        const data = await res.data.reverse();
        console.log(12, data);
        setVideoData(data);
        //   dispatch(setAllForm(data));
      })
      .catch((err) => console.log(err));
  }, []);

  
  return (
    <>
      <Breadcrumb title="Treanning Video" parent="Create User Passport" />
      <div className="row">
        {videoData.map((item) => {
          return (
            <>
              <div className="col-lg-6">
                <div class="card">
                  <iframe
                    height="315"
                    src={item.youtubeLink}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <div class="card-body">
                    <p class="card-text">{item.title}</p>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>

      <div className="row">
        <div className="col-lg-12">

        </div>
      </div>
    </>
  );
}

export default YoutubeVideoCard;
