import React from "react";
import Breadcrumb from "./common/breadcrumb";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

function NoticeData() {
  const [getNotice, setNotice] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/notice`)
      .then((res) => {
        const response = res.data.reverse();
        const retailerNotice = response.filter(
          (item) => item.noticeFor == "Retailer" && item.IsNoticeView == true
        );
        setNotice(retailerNotice);
        console.log(503, retailerNotice);
      })
      .catch((err) => console.log(40, err));
  }, []);
  return (
    <>
      <Breadcrumb title="Notice" parent="Retailer" />
      <div className="row">
        {getNotice.map((item) => {
          return (
            <>
              <div className="col-lg-12 border border-3 my-5">
                <div
                  className="pageborder m-3 p-4"
                  style={{ height: "fit-content", width: "fit-content" }}
                >
                  <div
                    className="text-wrap"
                    // style={{ width: "10rem" }}
                    dangerouslySetInnerHTML={{ __html: item?.containt }}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default NoticeData;
