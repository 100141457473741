import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import './styles.css';
// import './Application.css';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { incNumber } from "../../Redux/actions";
import Breadcrumb from "../common/breadcrumb";
import swal from "sweetalert";

function Retailer_ews() {
  const navigate =useNavigate();

  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));
  const [status, setStatus] = useState("");
  let { id } = useParams();
  const [selectedItem, setselectedItem] = useState(null);
  const dispatch = useDispatch();
  const [subNote, setSubNote] = useState("");
  const [compNote, setCompNote] = useState("");
  const [rejNote, setRejNote] = useState("");
  const form4 = useSelector((state) => state.formFour.form4);
  const [AllFOrm, setAllForm] = useState(form4);
  const getData = () => {
    if (AllFOrm.length > 0) {
      const selectedData = AllFOrm.find((item) => item._id === id);
      setselectedItem(selectedData);
    } else {
      axios
        .get("https://api.maharashtraseva.com/api/getAll")
        .then((res) => {
          const data = res.data;
          const selectedData = data.find((item) => item._id === id);
          setselectedItem(selectedData);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getData();
    // if (
    //   userRespons.user.role === "retailer" &&
    //   selectedItem?.status === "IN-PROGRESS"
    // ) {
     
    // } else if (
    //   userRespons.user.role === "admin" &&
    //   selectedItem?.status != "IN-PROGRESS"
    // ) {
     
    // } else {
    // axios
    //   .put(`https://api.maharashtraseva.com/api/Ews/${id}`, { isNew: false })
    //   .then((res) => {
    //     dispatch(incNumber());
    //   })
    //   .catch((err) => console.log(err));
    // }
    if(
      userRespons.user.role === "agent" &&
      selectedItem?.status != "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ){
      axios
      .put(`https://api.maharashtraseva.com/api/Ews/${id}`, { isNew: true })
      .then((res) => {
        dispatch(incNumber());
      })
      .catch((err) => console.log(err));
    }
   else if (
      userRespons.user.role === "agent" &&
      selectedItem?.status == "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/Ews/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status == "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/Ews/${id}`, { isNew: true })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status != "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/Ews/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    }

  }, [id, form4]);

  const [Acknowledgment, setAcknowledgment] = useState({
    Acknowledgment: "",
  });
  const [finalDocs, setfinalDocs] = useState({
    finalDocs: "",
  });

  const obj = {
    ...selectedItem,
    status: status,
  };

  const FormSubmit = (e) => {
    e.preventDefault();

    if (
      selectedItem.status === "IN-PROGRESS" ||
      selectedItem.status === "IN-Progress"
    ) {
      const formData = new FormData();

      formData.append("acknowledgmentDocument", Acknowledgment.Acknowledgment);
      axios
        .put(
          `https://api.maharashtraseva.com/api/EWS_acknowledgmentDocument/${id}`,
          formData
        )
        .then((res) => {
          console.log("acknowledgmentDocument", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
        const obj = {
          ...selectedItem,
          status: status,
          subNote: subNote,
          submitAt: new Date(),
          isNew: true,
        };
      axios
        .put(`https://api.maharashtraseva.com/api/Ews/${id}`, obj)
        .then((res) => {
          console.log("acknowledgmentDocument", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedItem.status === "SUBMITTED") {
      const formData = new FormData();

      formData.append("finalDocument", finalDocs.finalDocs);
      axios
        .put(`https://api.maharashtraseva.com/api/EWS_finalDocument/${id}`, formData)
        .then((res) => console.log("finalDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
        const obj = {
          ...selectedItem,
          status: status,
          compNote: compNote,
          CompleteAt: new Date(),
          isNew: true,
        };
      axios
        .put(`https://api.maharashtraseva.com/api/Ews/${id}`, obj)
        .then((res) => {
          console.log("finalDocument", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    }

    if (status === "REJECTED") {
      const obj = {
        ...selectedItem,
        status: status,
        rejNote: rejNote,
        rejectAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/Ews/${id}`, obj)
        .then((res) => {
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => console.log(err));
    }
  };
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  return (
    <>
      <Breadcrumb title={"Ews Certificate"} />
      {selectedItem != null ? (
        <Container fluid={true}>
          <Row>
            <Col sm="">
              <Card>
                <CardBody>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                     <div className="row mb-2">
                        <div className="col-lg-10">
                        {/* <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Details </span>
                        </h3> */}
                          
                        </div>
                        <div className="col-lg-2 mt-1">
                        <button
                            class="btn btn-outline-dark"
                            type="button"
                            onClick={() =>{
                              if (userRespons.user.role=="retailer") {
                                navigate(
                                  `${process.env.PUBLIC_URL}/RetailerForm4`
                                )
                              }else if(userRespons.user.role=="admin"){
                              navigate(
                                `${process.env.PUBLIC_URL}/ts`
                              )
                              }else{
                                navigate(
                                  `${process.env.PUBLIC_URL}/agent/application_Console`
                                )
                              }
                            }
                            }
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    <form class="inline-block form-inline ms-auto ">
                      <div id="layoutSidenav_content">
                        <main>
                          <div class="container-fluid px-4 mt-4">
                            <div class="formlayout">
                              {/* Applicant Details */}
                              <div class="row g-3">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Applicant Details
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> Full Name(English) :</b>
                                  </label>
                                  {selectedItem?.Data["statusfname"] +
                                    ".  " +
                                    selectedItem?.Data["fullName_English"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b>Full Name(Marathi) :</b>
                                  </label>
                                  {selectedItem?.Data["statusfname"] +
                                    ".  " +
                                    selectedItem?.Data["fullName_Marathi"]}{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Fathers Full Name (English) :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["fatherName_english"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Fathers Full Name (English) :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["fatherName_marathi"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> BirthDate :</b>
                                  </label>
                                  {selectedItem?.Data["BirthDate"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> Age :</b>
                                  </label>
                                  {selectedItem?.Data["age"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> Gender :</b>
                                  </label>

                                  {selectedItem?.Data["Gender"]}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> Occupation :</b>
                                  </label>

                                  {selectedItem?.Data["Bussness"]}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> Mobile No. :</b>
                                  </label>

                                  {selectedItem?.Data["phoneNUm"]}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> E-Mail ID :</b>
                                  </label>

                                  {selectedItem?.Data["email"]}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>UID No. :</b>
                                  </label>
                                  {selectedItem?.Data["AdharNo"]}
                                </div>
                              </div>
                              {/* Residence Details */}
                              <div class="row g-3">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Residence Details
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Address : </b>{" "}
                                  </label>
                                  {selectedItem?.Data["Applicant_address"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Street :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["Applicant_street"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Building :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["Applicant_Building"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Locality :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["Applicant_locality"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Landmark :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["Applicant_landmark"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> District :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["Applicant_district"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Taluka :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["Applicant_taluka"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Village :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["Applicant_village"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Pincode :</b>{" "}
                                  </label>
                                  {selectedItem?.Data["Applicant_pincode"]}{" "}
                                </div>
                              </div>
                              {/* Beneficiary Details*/}
                              <div class="row g-3">
                                {/* relationOfBanificiaryWithApplicant: "",
    benificiary_salution: "",
    Benificiary_Name_English: "",
    Benificiary_Name_marathi: "",
    Benificiary_Dob: "",
    Benificiary_age: "",
    Benificiary_gender: "",
    Benificiary_relation: "",
    Benificiary_mobNumber: "",
    Benificiary_Email: "",
    Benificiary_AdharNo: "",
    benificiary_occupation: "",
    Benificiary_Address: "",
    Benificiary_street: "",
    Benificiary_building: "",
    Benificiary_locality: "",
    Benificiary_landmark: "",
    Benificiary_District: "",
    Benificiary_taluka: "",
    Benificiary_village: "",
    Benificiary_pincode: "",
    benificiary_cast: "", */}
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Beneficiary Details
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                      {" "}
                                      Relation of Applicant with Beneficiary :{" "}
                                    </b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "relationOfBanificiaryWithApplicant"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Beneficiary Name (English) :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_Name_English"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Beneficiary Name (Marathi) :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_Name_marathi"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> BirthDate :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_Dob"
                                    ]
                                  }{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Gender :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_gender"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Mobile No. :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_mobNumber"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> E-Mail ID :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_Email"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> UID No. :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_AdharNo"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Occupation :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "benificiary_occupation"
                                    ]
                                  }{" "}
                                </div>
                              </div>
                              {/* Permanent Address:*/}
                              <div class="row g-3">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Permanent Address
                                    </span>
                                  </h3>
                                </div>
                                {/* Benificiary_Address
Benificiary_street
Benificiary_building
Benificiary_locality
Benificiary_landmark
Benificiary_District
Benificiary_taluka
Benificiary_village
Benificiary_pincode */}
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Address: </b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_Address"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Street :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_street"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Building :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "permanant_Bulding"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Locality :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_locality"
                                    ]
                                  }{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Landmark :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_landmark"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> District :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_District"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Taluka :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_taluka"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Village :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_village"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> PinCode :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "Benificiary_pincode"
                                    ]
                                  }{" "}
                                </div>
                              </div>

                              {/* Migration Address Details:*/}
                              <div class="row g-3">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Migration Address Details:
                                    </span>
                                  </h3>
                                </div>
                                {/* <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Address: </b>{" "}
                                  </label>
                                  {selectedItem?.permanant_address["permanant_address"]}{" "}
                                </div> */}
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> State :</b>{" "}
                                  </label>
                                  {selectedItem?.migration["migration_state"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> District :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.migration[
                                      "migration_district"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Taluka :</b>{" "}
                                  </label>
                                  {selectedItem?.migration["migration_taluka"]}{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Village :</b>{" "}
                                  </label>
                                  {selectedItem?.migration["migration_village"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> From Date :</b>{" "}
                                  </label>
                                  {
                                    selectedItem?.migration[
                                      "migration_fromDate"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>To Date :</b>{" "}
                                  </label>
                                  {selectedItem?.migration["migration_endDate"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Year :</b>{" "}
                                  </label>
                                  {selectedItem?.migration["migration_year"]}{" "}
                                </div>
                              </div>

                              {/* Beneficiary Caste/Category Details*/}
                              <div class="row g-3">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Beneficiary Caste/Category Details
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Caste :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_info[
                                      "benificiary_cast"
                                    ]
                                  }{" "}
                                </div>
                              </div>

                              {/* Beneficiary's Father Details*/}
                              <div class="row g-3">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Beneficiary's Father Details
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Full Name (English) :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_fullName_english"
                                    ]
                                  }{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Full Name (Marathi) :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_fullName_marathi"
                                    ]
                                  }{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Date Of Birth :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_DOB"
                                    ]
                                  }{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Occupation :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_occupation"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Mobile No :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_mobileNO"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Email :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_email"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> UID No. :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_UID"
                                    ]
                                  }{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Address :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_Address"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Street :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_street"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Building :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_building"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Locality :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_Locality"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Landmark :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_Landmark"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>District :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_District"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Taluka :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_Taluka"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>village :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_Village"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Pincode :</b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_PinCode"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                      Beneficiary's Father Caste/ Category
                                      Details :
                                    </b>
                                  </label>
                                  {
                                    selectedItem?.benificiary_father_details[
                                      "benificiaryFather_cast"
                                    ]
                                  }{" "}
                                </div>
                              </div>
                              {/*Land Details*/}
                              <div class="row g-3">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Land Details
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Land Holder Name (English) :</b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "land_holderName_english"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Land Holder Name (Marathi) :</b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "land_holderName_marathi"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Relation :</b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "land_holder_relation"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> District :</b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "land_holder_district"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Taluka :</b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "land_holder_taluka"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Village :</b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "land_holder_village"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> PinCode :</b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "land_holder_pincode"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Group No. :</b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "land_holder_groupNO"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Area :</b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "land_holder_groupNO"
                                    ]
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                      {" "}
                                      Is your land owned outside the village? :
                                    </b>
                                  </label>
                                  {
                                    selectedItem?.land_info[
                                      "is_land_outside_village"
                                    ]
                                  }{" "}
                                </div>
                              </div>
                              {/* Family income details through various sources*/}
                              <div class="row g-3">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Family income details through various
                                      sources
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Income from Salary / Wages :</b>
                                  </label>
                                  {selectedItem?.Data["Income_salary"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Income from Business Enterprises :</b>
                                  </label>
                                  {selectedItem?.Data["Income_business"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Income from Agriculture :</b>
                                  </label>
                                  {selectedItem?.Data["Income_agriculture"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Income from Investment :</b>
                                  </label>
                                  {selectedItem?.Data["Income_investment"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Income from Others :</b>
                                  </label>
                                  {selectedItem?.Data["Income_others"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Total Income :</b>
                                  </label>
                                  {selectedItem?.Data["total"]}{" "}
                                </div>
                              </div>
                              <div className="row">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Upload required documents
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-12">
                                <b style={{ color: "red" }}>
                                  अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व
                                  आवश्यक कागदपत्रे तपासून व स्कॅन करून अपलोड
                                  करावे. जर आवश्यक कागदपत्रे चुकीची किंवा
                                  अस्पष्ट आढळल्यास सदर चा अर्ज फेटाळला जाऊ शकतो.
                                </b>{" "}
                              </div>
                              <div class="col-md-6 ">
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.FatherAdhar}>
                                    Father Adhar Card
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.childrenAdhar}>
                                    Children Adhar
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.castProof}>
                                    १९६७ पूर्वीचा परवाना
                                  </a>
                                </button>
                                <br />

                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.lightBill}>
                                    light Bill
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.applicantPhoto}>
                                    Applicant Photo
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.benificiarryPhoto}>
                                    Benificiary Photo
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.reshaCard}>
                                    Reshan Card
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.selfDeclearation}>
                                    self Declearation
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a
                                    href={
                                      selectedItem?.incomeCertficate3yearOrForm16
                                    }
                                  >
                                    वषउत्पन्न दाखला/ फॉम १६
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.fatherSchoolLeaveCer}>
                                    Father School leave Certificate
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a
                                    href={selectedItem?.childrenSchoolLeaveCer}
                                  >
                                    Children School leave Certificate
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.otherSecond}>
                                    other Second
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    marginTop: "8px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <a href={selectedItem?.otherFirst}>
                                    other First
                                  </a>
                                </button>
                                <br />
                                {selectedItem?.acknowledgmentDocument !=
                                  null && (
                                  <>
                                    <button
                                      style={{
                                        border: "none",
                                        color: "blue",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <a
                                        href={
                                          selectedItem?.acknowledgmentDocument
                                        }
                                      >
                                        acknowledgmentDocument
                                      </a>
                                    </button>
                                  </>
                                )}
                                <br />
                                {selectedItem?.finalDocument != null && (
                                  <>
                                    <button
                                      style={{
                                        border: "none",
                                        color: "blue",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <a href={selectedItem?.finalDocument}>
                                        finalDocument
                                      </a>
                                    </button>
                                  </>
                                )}
                              </div>
                              <div className="col-lg-6">
                                      <div className="row">
                                        <div className="col-lg-12 "><h3 className="fs-4 fw-3"><b>HISTORY</b></h3></div>
                                        <div className="col-lg-12 mt-2"><b>Current Status : </b>{selectedItem.status}</div>
                                        <div className="col-lg-12 "><b>Created On : </b>{GetDate(selectedItem.createdAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Submit Note : </b>{selectedItem.subNote}</div>
                                        <div className="col-lg-12 "><b>submit On : </b>{GetDate(selectedItem.submitAt)}</div>
                                        <div className="col-lg-12 mt-2"><b>Reject Note : </b>{selectedItem.rejNote}</div>
                                        <div className="col-lg-12 "><b>Reject On : </b>{GetDate(selectedItem.rejectAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Complete Note : </b>{selectedItem.compNote}</div>
                                        <div className="col-lg-12 "><b>Complete On : </b>{GetDate(selectedItem.CompleteAt)}</div>
                                        
                                      </div>
                                    </div>
                                    </div>
                              {userData.user.role == "agent" ? (
                                <>
                                  {selectedItem.status == "IN-PROGRESS" ? (
                                    <div class="row g-3" id="dvtext">
                                      <div class="col-md-6">
                                        <label>
                                          {" "}
                                          <b>
                                            {" "}
                                            Status:
                                            <span class="text-danger">*</span>
                                          </b>{" "}
                                        </label>
                                        <br />
                                        <select
                                          name="state"
                                          class="form-select"
                                          required
                                          id="state"
                                          onChange={(e) =>
                                            setStatus(e.target.value)
                                          }
                                        >
                                          <option value="" selected>
                                            Select State
                                          </option>
                                          <option Value="SUBMITTED">
                                            SUBMITTED
                                          </option>
                                          <option Value="REJECTED">
                                            REJECTED
                                          </option>
                                        </select>
                                      </div>

                                      <div class="col-md-6">
                                        <label>
                                          <b>
                                            Note:
                                            <span class="text-danger">*</span>
                                          </b>{" "}
                                        </label>{" "}
                                        <br />
                                        <textarea
                                          name="note"
                                          class="form-control"
                                          onChange={(e)=>{
                                            if (status == "SUBMITTED") {
                                              setSubNote(e.target.value)
                                            }else{
                                              setRejNote(e.target.value)
                                            }
                                          }}
                                        >
                                          {" "}
                                        </textarea>{" "}
                                      </div>
                                      {status === "SUBMITTED" && (
                                        <div class="col-md-6">
                                          <label>
                                            <b>Acknowledgment Document </b>{" "}
                                          </label>
                                          <input
                                            type="file"
                                            onChange={(e) => {
                                              setAcknowledgment({
                                                ...Acknowledgment,
                                                Acknowledgment:
                                                  e.target.files[0],
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  ) : selectedItem.status == "SUBMITTED" ? (
                                    <div class="row g-3" id="dvtext">
                                      <div class="col-md-6">
                                        <label>
                                          {" "}
                                          <b>
                                            {" "}
                                            Status:
                                            <span class="text-danger">*</span>
                                          </b>{" "}
                                        </label>
                                        <br />
                                        <select
                                          name="state"
                                          class="form-select"
                                          required
                                          id="state"
                                          onChange={(e) =>
                                            setStatus(e.target.value)
                                          }
                                        >
                                          <option value="" selected>
                                            Select State
                                          </option>

                                          <option Value="COMPLETE">
                                            COMPLETE
                                          </option>
                                        </select>
                                      </div>

                                      <div class="col-md-6">
                                        <label>
                                          <b>
                                            Note:
                                            <span class="text-danger">*</span>
                                          </b>{" "}
                                        </label>{" "}
                                        <br />
                                        <textarea
                                          name="note"
                                          class="form-control"
                                          onChange={(e)=>{
                                            setCompNote(e.target.value)
                                          }}
                                        >
                                          {" "}
                                        </textarea>{" "}
                                      </div>
                                      {status === "COMPLETE" && (
                                        <div class="col-md-6">
                                          <label>
                                            <b>Final Document </b>{" "}
                                          </label>
                                          <input
                                            type="file"
                                            onChange={(e) => {
                                              setfinalDocs({
                                                ...finalDocs,
                                                finalDocs: e.target.files[0],
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div class="row g-3 " id="dvtext">
                                    <div class="col-md-12">
                                      <button
                                        type="submit"
                                        name="submit"
                                        id="btnsubmit"
                                        class="btn btn-primary"
                                        value="Submit"
                                        onClick={(e) => {
                                          FormSubmit(e);
                                        }}
                                      >
                                        Submit
                                      </button>

                                      <button
                                        type="submit"
                                        name="submit"
                                        id="btnsubmit"
                                        class="btn btn-primary "
                                        value="Submit"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </main>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Card
            style={{
              height: "80vh",
              width: "80vw",
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <h3>Loading...</h3>
          </Card>
        </>
      )}
    </>
  );
}

export default Retailer_ews;
