import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./certificate.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import { useEffect } from "react";
import axios from "axios";
function Certificategenrate() {
  const { id } = useParams();
//   console.log(8,id);
const[user,setUser]=useState([])
// console.log(14,user);
  useEffect(()=>{
    axios
    .get(`https://api.maharashtraseva.com/api/user/getoneuser/${id}`)
    .then((res) => {
      const response = res.data;
      console.log(20,response);
      setUser(response);
      
      
    })
    .catch((err) => console.log(40, err));
  },[ id])
//   const downloadPDF = () => {
//     const capture = document.querySelector(".actual-receipt");
  
//     html2canvas(capture).then((canvas) => {
//       const imgData = canvas.toDataURL("img/png");
//       const doc = new jsPDF("p", "mm", "a4");
//       const componentWidth = doc.internal.pageSize.getWidth();
//       const componentHeight = doc.internal.pageSize.getHeight();
//       doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
//       doc.save("Certificate.pdf");
//     });
//   };
const downloadPDF = () => {
    const capture = document.querySelector(".actual-receipt");

    html2canvas(capture, { scale: 5 }).then((canvas) => { // Adjust scale for better quality
        const imgData = canvas.toDataURL("image/png");
        const doc = new jsPDF("l", "mm", "a4"); // Use default page size (A4)
        
        // Calculate dimensions to fit content while maintaining aspect ratio
        const contentWidth = doc.internal.pageSize.getWidth() - 20; // Subtracting some margin
        const contentHeight = canvas.height * contentWidth / canvas.width;

        doc.addImage(imgData, "PNG", 10, 10, contentWidth, contentHeight); // Add some margin
        doc.save("Certificate.pdf");
    });
};

  return (
    <>
        <div className="-col-lg-12 text-center m-4">
            <button className="btn btn-outline-dark" onClick={(e)=>downloadPDF(e)}>Download Certificate</button>
        </div>
    <div className="actual-receipt">
      <div className="row">
        <div className="col-lg-12">
          <div className="cerficatebox">
            <div className="Fullname">
              <h3>{user[0]?.name}</h3>
            </div>
            <div className="location">
                <h3 className="">{user[0]?.address1}</h3>
            </div>
            <div className="Distic">
                <h3>{user[0]?.district}</h3>
            </div>
            <div className="barcode">
                <div className="barcodeBg">

            <QRCode value={`महाराष्ट्र इन्फॉर्मेटिक्स कंपनीच्या महाराष्ट्र ई सेवा केंद्र franchise च्या ${user[0]?.address1} लोकेशन साठी अधिकृत.ID - ${user[0]?.username}`} />
                </div>
            </div>

          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Certificategenrate;
