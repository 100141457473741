import React from 'react'
import Breadcrumb from '../common/breadcrumb'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

function MemberKit() {
  const [memberKit, setMemberKit] = useState([]);
  const reducer = useSelector((state) => state.changeNumber);

  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/memberkit_banner`)
      .then((res) => {
        console.log("banner", res.data);
        const data = res.data.reverse();
        setMemberKit(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reducer]);

  return (
    <>
       <Breadcrumb title="Member Kit" parent="Retailer" />
       <div className="row">
       {memberKit.map((item) => {
          return (
            <>
              <div className="col-lg-4 mt-3">
                <div className="row border text-Center">
                  <div className="col-lg-12 mt-2">
                    <img
                      src={item.banner}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <div className="col-lg-12 text-Center pl-5">
                  <h3 className='m-2'><b> <a style={{marginLeft:"180px"}} href={item.banner}>Download</a></b></h3>
                  </div>
                </div>
              </div>
            </>
          );
        })}
       </div>
    </>
  )
}

export default MemberKit
