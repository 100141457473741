import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
	return (
		<div>
			<footer className="">
				<container >
					<row>
						<div className="col-lg-12">

							<p className="mb-0">
								<b>Copyright © 2019  Maharashtra Informatics Pvt.Ltd , All Right Reserved</b>
							</p>
						</div>
						
					</row>
				</container>
			</footer>
		</div>
	);
};

export default Footer;
