import React, { useEffect, useState } from "react";
// import Mesk01 from '../../../public/assets/image/Me'
import Mesk01 from "../../assets/images/image/Mesk01.jpg";
import Mesk02 from "../../assets/images/image/Mesk02.jpg";
import Mesk03 from "../../assets/images/image/Mesk03.jpg";
import Mesk04 from "../../assets/images/image/Mesk04.jpg";
import imgg from "../../assets/images/image/imgg.jpg";
import imgg2 from "../../assets/images/image/imgg2.jpg";
import Profile from "../../assets/images/image/Profile.jpeg";
import pic1 from "../../assets/images/image/pic1.jpeg";
import pic2 from "../../assets/images/image/pic2.jpeg";
import pic3 from "../../assets/images/image/pic3.jpeg";
import pic4 from "../../assets/images/image/pic4.jpeg";
import pic5 from "../../assets/images/image/pic5.jpeg";
import pic6 from "../../assets/images/image/pic6.jpeg";
import axios from "axios";
import { useSelector } from "react-redux";
import swal from "sweetalert";
// import './Landing.css'
const Home = () => {
  const reducer = useSelector((state) => state.changeNumber);
  const userData = useSelector((state) => state.userData.user);
console.log(23,userData);
  const [images, setimages] = useState();
  const [Getimages1, setGetimages1] = useState([]);
  const [Getimages2, setGetimages2] = useState([]);
  const [Getimages3, setGetimages3] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/Coro1_images`)
      .then((res) => {
        console.log("banner", res.data);
        const data = res.data.reverse();
        setGetimages1(data);
        console.log(31, data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`https://api.maharashtraseva.com/api/Coro2_images`)
      .then((res) => {
        console.log("banner", res.data);
        const data = res.data.reverse();
        setGetimages2(data);
        console.log(31, data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`https://api.maharashtraseva.com/api/Coro3_images`)
      .then((res) => {
        console.log("banner", res.data);
        const data = res.data.reverse();
        setGetimages3(data);
        console.log(31, data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reducer]);

  const createAdmin=()=>{

    const obj = {
      name:"Gorakh",
      email :"gorakhent.7@gmail.com",
      mobileNumber:"1234578",
      district:"pune",
      tehsil:"pune",
      village:"",
      role:"admin",
      password:"123456",
      username: "admin",
    };
    console.log(137, obj);
  
    axios
      .post("https://api.maharashtraseva.com/api/signup", obj)
      .then((res) => {
  
          swal(" Admin Created successfully!", "success");
  
        // navigate("/users/list-user")
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <div className="" style={{ marginTop: "150px" }}>
        {/* <div
          id="carouselExampleInterval"
          class="carousel slide "
          style={{
            border: "2px solid gray",
          }}
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="1700">
              <img
                src="https://mhae-seva.s3.amazonaws.com/Mesk01.jpg"
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="1700">
              <img
                src="https://mhae-seva.s3.amazonaws.com/image/Mesk02.jpg"
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="1700">
              <img
                src="https://mhae-seva.s3.amazonaws.com/image/Mesk03.jpg"
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item" data-bs-interval="1700">
              <img
                src="https://mhae-seva.s3.amazonaws.com/image/Mesk04.jpg"
                class="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div> */}
        {/* <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="https://mhae-seva.s3.amazonaws.com/Mesk01.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="https://mhae-seva.s3.amazonaws.com/image/Mesk02.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="https://mhae-seva.s3.amazonaws.com/image/Mesk03.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="https://mhae-seva.s3.amazonaws.com/image/Mesk04.jpg" class="d-block w-100" alt="..."/>
    </div>
  </div>
</div> */}
<div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="2000">
      <img src="https://mhae-seva.s3.amazonaws.com/Mesk01.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="https://mhae-seva.s3.amazonaws.com/image/Mesk02.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="https://mhae-seva.s3.amazonaws.com/image/Mesk03.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="https://mhae-seva.s3.amazonaws.com/image/Mesk04.jpg" class="d-block w-100" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

        <div className="About">
          <div className=" row text-center my-5">
            <div className="col-lg-12">
              <h3 className="">WELCOME TO MAHARASHTRA E-SEVA KENDRA™</h3>
            </div>
            <div className="col-lg-12">
              <h2 className="">
                आजच सुरू करा महाराष्ट्र ई-सेवा केंद्र™ आणि बना हमखास
                आत्मनिर्भर..!
              </h2>
            </div>
          </div>
          <hr className="mx-2" />
          <div class=" ">
            <div class=" row">
              <div className="col-lg-12 text-center">
                <h3 className="fs-2">
                  {" "}
                  <b>आमची ताकत आमचे विस्तारणारे कुटुंबच...! </b>
                </h3>
                {/* <h3>संपूर्ण महाराष्ट्रात 450 पेक्षा जास्त यशस्वी महाराष्ट्र-ई-सेवा केंद्र कार्यरत</h3> */}
              </div>
              <div className="col-lg-4 mt-4">
                <div
                  id="carouselExampleControlsNoTouching"
                  class="carousel slide"
                  data-bs-touch="false"
                >
                  <div class="carousel-inner">
                    {Getimages1.map((item) => {
                      return (
                        <>
                          <div
                            class="carousel-item active"
                            data-bs-interval="1700"
                          >
                            <img
                              src={item.images}
                              class=" d-block w-100"
                              alt="..."
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControlsNoTouching"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControlsNoTouching"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>

              <div className="col-lg-4 mt-4">
                <div
                  id="carouselExampleControlsNoTouching1"
                  class="carousel slide"
                  data-bs-touch="false"
                >
                  <div class="carousel-inner">
                    {Getimages2.map((item) => {
                      return (
                        <>
                          <div
                            class="carousel-item active"
                            data-bs-interval="1700"
                          >
                            <img
                              src={item.images}
                              class=" d-block w-100"
                              alt="..."
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControlsNoTouching1"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControlsNoTouching1"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>

              <div className="col-lg-4 mt-4">
                <div
                  id="carouselExampleControlsNoTouching2"
                  class="carousel slide"
                  data-bs-touch="false"
                >
                  <div class="carousel-inner">
                    {Getimages3.map((item) => {
                      return (
                        <>
                          <div
                            class="carousel-item active"
                            data-bs-interval="1700"
                          >
                            <img
                              src={item.images}
                              class=" d-block w-100"
                              alt="..."
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControlsNoTouching2"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControlsNoTouching2"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="col-lg-12 text-center mt-4">
                <h3 className="fs-2 ">
                  संपूर्ण महाराष्ट्रात 450 पेक्षा जास्त यशस्वी महाराष्ट्र-ई-सेवा
                  केंद्रे कार्यरत
                </h3>
                <h3 className="mt-4">
                  <b>साथ आमची, प्रगती होईल तुमची...!</b>
                </h3>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div
              id="carouselExampleInterval"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner-sec carousel-inner">
                <div class="carousel-item active" data-bs-interval="1700">
                  <img
                    src="https://mhae-seva.s3.amazonaws.com/image/imgg.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item" data-bs-interval="1700">
                  <img
                    src="https://mhae-seva.s3.amazonaws.com/image/imgg2.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="container my-3 ">
            <div className="row">
              <div className="col-lg-12 ">
                <p className="fs-6 " style={{ lineHeight: "3.5" }}>
                  <b>
                    महाराष्ट्र ई-सेवा केंद्र™ हा उपक्रम प्रामाणिकपणे,लोकांच्या
                    विश्वासास पात्र राहून पैसे कमविण्याचा एक चांगला पर्याय आहे.
                    महाराष्ट्र ई-सेवा केंद्र™ या व्यवसायातून समाजातील सर्वच
                    स्तरातील अनेक समाजबांधवांशी संबंध येतो आणि त्यातून तुम्ही
                    समाजाशी देखील जोडले जाता. तुमची स्वतःची एक ओळख निर्माण होते.
                    आणि सहजपणे तुम्ही तुमच्या वेळेनुसार व्यवसाय करू शकता व
                    सोईनुसार वाढवू शकता. महाराष्ट्र ई-सेवा केंद्र™ या उपक्रमातून
                    तुम्ही लोकांना योग्य व कमी काळात चांगली सेवा दिली तर लोक
                    तुमच्याकडे मोठ्या विश्वासाने पाहतात आणि तुमच्या व्यवसायास
                    मोठे स्वरूप प्राप्त होते व तुम्हीच तुमच्या व्यवसायाचे मालक
                    होता.त्याचे फायदेही खूप आहेत. मग वाट कसली बघताय सुरु करा
                    महाराष्ट्र इन्फॉर्मेटिक्सच्या साथीने स्व:ताचा व्यवसाय आणि
                    कमवा महिना लाख रुपये आणि द्या इतरांना रोजगार
                  </b>
                </p>
              </div>
            </div>
          </div>

          <div class="picture row">
            <div class="cards-pic col-lg-4">
              <div className="neww_card">
                <img
                  className="pics"
                  src="https://mhae-seva.s3.amazonaws.com/image/pic1.jpeg"
                  alt=""
                />
              </div>
            </div>
            <div class=" cards-pic col-lg-4">
              <div className="neww_card">
                <img
                  className="pics"
                  src="https://mhae-seva.s3.amazonaws.com/image/pic2.jpeg"
                  alt=""
                />
              </div>
            </div>
            <div class=" cards-pic col-lg-4">
              <div className="neww_card">
                <img
                  className="pics"
                  src="https://mhae-seva.s3.amazonaws.com/image/pic3.jpeg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="picture row">
            <div class=" cards-pic col-lg-4">
              <div className="neww_card">
                <img
                  className="pics-odd"
                  src="https://mhae-seva.s3.amazonaws.com/image/pic4.jpeg"
                  alt=""
                />
              </div>
            </div>
            <div class=" cards-pic col-lg-4">
              <div className="neww_card">
                <img
                  className="pics"
                  src="https://mhae-seva.s3.amazonaws.com/image/pic5.jpeg"
                  alt=""
                />
              </div>
            </div>
            <div class=" cards-pic col-lg-4">
              <div className="neww_card">
                <img
                  className="pics"
                  src="https://mhae-seva.s3.amazonaws.com/image/pic6.jpeg"
                  alt=""
                />
              </div>
            </div>
            <hr className="hrline mt-3" />
          </div>

          <div className="row mt-5" style={{ backgroundColor: "lightgray" }}>
            <div className="col-lg-12 paragraph" style={{ fontSize: "17px" }}>
              <h5 className="para">
                महाराष्ट्र ई-सेवा केंद्र™ हा उपक्रम प्रामाणिकपणे,लोकांच्या
                विश्वासास पात्र राहून पैसे कमविण्याचा एक चांगला पर्याय आहे. या
                व्यवसायातून समाजातील सर्वच स्तरातील अनेक समाजबांधवांशी संबंध
                येतो आणि त्यातून तुम्ही समाजाशी देखील जोडले जाता. तुमची स्वतःची
                एक ओळख निर्माण होते. आणि सहजपणे तुम्ही तुमच्या वेळेनुसार व्यवसाय
                करू शकता व सोईनुसार वाढवू शकता. महाराष्ट्र ई-सेवा केंद्र™ या
                उपक्रमातून तुम्ही लोकांना योग्य व कमी काळात चांगली सेवा दिली तर
                लोक तुमच्याकडे मोठ्या विश्वासाने पाहतात आणि तुमच्या व्यवसायास
                मोठे स्वरूप प्राप्त होते व तुम्हीच तुमच्या व्यवसायाचे मालक
                होता.त्याचे फायदेही खूप आहेत. जसे की आर्थिक स्वातंत्र्य.म्हणजे
                तुम्ही तुमच्या वेळेनुसार आर्थिक सोर्स निर्माण करू शकता.या
                व्यवसायात तुम्हाला वैयक्तिक कामाचे स्वातंत्र्य राहते. कामकाजाचे
                स्वातंत्र्य आणि अनेक फायदे ज्यांनी व्यक्तीचे जीवन अधिक
                अर्थपूर्ण बनते.
              </h5>
            </div>
            {/* <div className="col-lg-4">
              <ul className="para-profilee">
                <li>
                  <img
                    className="profilee p-3 "
                    src="https://mhae-seva.s3.amazonaws.com/image/Profile.jpeg"
                    alt=""
                  />
                </li>
                <li>
                  <h5 class="profilee-txt ">Gorakh Bangar CEO</h5>
                </li>
              </ul>
             
            </div> */}
          </div>
        </div>
        {/* </div > */}

        {/* <div className='contact-us'>
                <h1 className='contact text-center' >CONTACT US</h1>
                <hr className='hrline' />
                <form className='' >
                    <label for="fname">First Name</label>
                    <input type="text" id="fname" name="firstname" placeholder="Enter your name.." required />

                    <label for="fname">Phone no.</label>
                    <input type="text" id="fname" name="firstname" placeholder="Phone no.." required />

                    <label for="fname">Address</label>
                    <input type="text" id="fname" name="firstname" placeholder="Address" required />

                    <label for="lname">Email-Id</label>
                    <input type="text" id="lname" name="lastname" placeholder="Enter your E-mail.." required />

                    <label for="exampleFormControlTextarea1" class="form-label" >Message</label>
                    <textarea class="textarea form-control" placeholder='Enter your message..' id="exampleFormControlTextarea1" rows="3"></textarea>

                    <button type="submit" className='submit btn btn-success' >Submit</button>
                </form >
            </div> */}
        {/* <hr className="hrline" /> */}
      </div>
    </>
  );
};

export default Home;
