import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import axios from "axios";
import DataTable from "react-data-table-component";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Breadcrumb from "./common/breadcrumb";
import swal from "sweetalert";

const AdminFormprice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [rechargeData, setRechargeData] = useState([]);
  const userData = useSelector((state) => state.userData.user);
  const pricess = useSelector((state) => state.price.formPrices);
  const [user, setUser] = useState([]);
  const users = [];
  const [selectedUserID, setselectedUser] = useState("");
  const [selectedUser, setUserselected] = useState("");
  const [updateID, setUpdateID] = useState("");
  const [btn, setbtn] = useState(false);
  const [showbtn, setshowbtn] = useState(false);
  const [data, setdata] = useState({
    userID: "",
    shopActNew: "",
    shopActReNew: "",
    udhamAbhar: "",
    companyGST: "",
    rentAgreement: "",
    individualGST: "",
    foodLicense1year: "",
    foodLicense2year: "",
    foodLicense3year: "",
    eGazette_OpenOBC: "",
    eGazette_SC: "",
    eGazette_SBC: "",
    eGazette_ST: "",
    eGazette_VJ: "",
    eGazette_NT: "",
    passport_Normal: "",
    passport_Tatkal: "",
    learningDl_MCWOG: "",
    learningDl_MCWG: "",
    learningDl_LMV: "",
    learningDl_LMV_TR: "",
    learningDl_MCWOG_MCWG: "",
    learningDl_MCWOG_LMV: "",
    learningDl_MCWG_LMV: "",
    learningDl_MCWOG_MCWG_LMV: "",
    learningDl_MCWG_LMVTR: "",
    learningDl_MCWOG_LMVTR: "",
    learningDl_MCWOG_MCWG_LMV_TR: "",
    permanent_MCWOG: "",
    permanent_MCWG: "",
    permanent_LMV: "",
    permanent_LMV_TR: "",
    permanent_MCWOG_MCWG: "",
    permanent_MCWOG_LMV: "",
    permanent_MCWG_LMV: "",
    permanent_MCWOG_MCWG_LMV: "",
    permanent_MCWG_LMVTR: "",
    permanent_MCWOG_LMVTR: "",
    permanent_MCWOG_MCWG_LMV_TR: "",
    renew_MCWOG: "",
    renew_MCWG: "",
    renew_LMV: "",
    renew_LMV_TR: "",
    renew_MCWOG_MCWG: "",
    renew_MCWOG_LMV: "",
    renew_MCWG_LMV: "",
    renew_MCWOG_MCWG_LMV: "",
    renew_MCWG_LMVTR: "",
    renew_MCWOG_LMVTR: "",
    renew_MCWOG_MCWG_LMV_TR: "",
    panCard: "",
    voterCard: "",
  });
  console.log(25, selectedUserID);
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/user/getAll`)
      .then((res) => {
        const response = res.data;
        const filtered = response.filter((item) => item.role == "retailer");

        setUser(filtered);
      })
      .catch((err) => console.log(40, err));
  }, []);

  const columns = [
    {
      name: "Sr No",
      style: {
        textAlign: "center",
      },
      selector: (row, index) => index + 1,
    },
    {
      name: "Action",
      // center:true,
      style: {
        textAlign: "center",
        cursor: "pointer",
        color: "blue",
      },
      selector: (row) => (
        <>
          <i
            class="bi bi-check2-circle fs-3"
            onClick={(e) => {
              setselectedUser(row._id);
              // setdata({...data,userID:row._id});
              setUserselected(row.name);
              SetUserFormPrice(row._id);
            }}
            data-bs-dismiss="modal"
          ></i>
        </>
      ),
    },

    {
      name: " Name",
      style: {
        textAlign: "center",
      },
      // center:true,
      selector: (row) => row.name,
    },

    {
      name: "Email Address",
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => row.email,
    },
    {
      name: "district",
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => row.district,
    },
    {
      name: "Mobile Number",
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => row.mobileNumber,
    },
    {
      name: "Role",
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => row.role,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWaight: "900",
        // Add any other CSS properties you want to apply to the rows
        fontSize: "22px",
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        fontWaight: "900",
        fontSize: "15px",
        border: "1px solid black",
        background: "#f36621",
        color: "white",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        fontSize: "12px",
        border: "1px solid black",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
        color: "black",
        // Add any styles for the regular cells
        border: "1px solid black",
      },
    },
    pagination: {
      style: {
        // Add any styles for the pagination section
        border: "1px solid black",
      },
    },
  };
  const searchByName = (e) => {
    setQuery(e.target.value);
    const value = e.target.value;

    if (value.length > 0) {
      const searchData = user.filter((item, index) => {
        if (item.name != undefined && item.name.length > 0) {
          const name = item.name;
          if (name.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        }
      });
      setUser(searchData);
    } else {
      setUser(userData);
    }
  };
  const SetUserFormPrice = (id) => {
    // setselectedUser(id);
   
    setshowbtn(true);
    axios
      .get(`https://api.maharashtraseva.com/api/formPrice`)
      .then((res) => {
        const response = res.data;
        console.log("response", response);

        const filtered = response.filter((item) => item.userID == id);

  
        if (filtered.length==0) {
          const filtered = response.filter((item) => item.userID == "ALL");
          setbtn(false);
          setdata({
            userID: id,
            shopActNew: filtered[0].shopActNew,
            shopActReNew: filtered[0].shopActReNew,
            udhamAbhar: filtered[0].udhamAbhar,
            companyGST: filtered[0].companyGST,
            rentAgreement: filtered[0].rentAgreement,
            individualGST: filtered[0].individualGST,
            foodLicense1year: filtered[0].foodLicense1year,
            foodLicense2year: filtered[0].foodLicense2year,
            foodLicense3year: filtered[0].foodLicense3year,
            eGazette_OpenOBC: filtered[0].eGazette_OpenOBC,
            eGazette_SC: filtered[0].eGazette_SC,
            eGazette_SBC: filtered[0].eGazette_SBC,
            eGazette_ST: filtered[0].eGazette_ST,
            eGazette_VJ: filtered[0].eGazette_VJ,
            eGazette_NT: filtered[0].eGazette_NT,
            passport_Normal: filtered[0].passport_Normal,
            passport_Tatkal: filtered[0].passport_Tatkal,
            learningDl_MCWOG: filtered[0].learningDl_MCWOG,
            learningDl_MCWG: filtered[0].learningDl_MCWG,
            learningDl_LMV: filtered[0].learningDl_LMV,
            learningDl_LMV_TR: filtered[0].learningDl_LMV_TR,
            learningDl_MCWOG_MCWG: filtered[0].learningDl_MCWOG_MCWG,
            learningDl_MCWOG_LMV: filtered[0].learningDl_MCWOG_LMV,
            learningDl_MCWG_LMV: filtered[0].learningDl_MCWG_LMV,
            learningDl_MCWOG_MCWG_LMV: filtered[0].learningDl_MCWOG_MCWG_LMV,
            learningDl_MCWG_LMVTR: filtered[0].learningDl_MCWG_LMVTR,
            learningDl_MCWOG_LMVTR: filtered[0].learningDl_MCWOG_LMVTR,
            learningDl_MCWOG_MCWG_LMV_TR:
              filtered[0].learningDl_MCWOG_MCWG_LMV_TR,
            permanent_MCWOG: filtered[0].permanent_MCWOG,
            permanent_MCWG: filtered[0].permanent_MCWG,
            permanent_LMV: filtered[0].permanent_LMV,
            permanent_LMV_TR: filtered[0].permanent_LMV_TR,
            permanent_MCWOG_MCWG: filtered[0].permanent_MCWOG_MCWG,
            permanent_MCWOG_LMV: filtered[0].permanent_MCWOG_LMV,
            permanent_MCWG_LMV: filtered[0].permanent_MCWG_LMV,
            permanent_MCWOG_MCWG_LMV: filtered[0].permanent_MCWOG_MCWG_LMV,
            permanent_MCWG_LMVTR: filtered[0].permanent_MCWG_LMVTR,
            permanent_MCWOG_LMVTR: filtered[0].permanent_MCWOG_LMVTR,
            permanent_MCWOG_MCWG_LMV_TR:
              filtered[0].permanent_MCWOG_MCWG_LMV_TR,
            renew_MCWOG: filtered[0].renew_MCWOG,
            renew_MCWG: filtered[0].renew_MCWG,
            renew_LMV: filtered[0].renew_LMV,
            renew_LMV_TR: filtered[0].renew_LMV_TR,
            renew_MCWOG_MCWG: filtered[0].renew_MCWOG_MCWG,
            renew_MCWOG_LMV: filtered[0].renew_MCWOG_LMV,
            renew_MCWG_LMV: filtered[0].renew_MCWG_LMV,
            renew_MCWOG_MCWG_LMV: filtered[0].renew_MCWOG_MCWG_LMV,
            renew_MCWG_LMVTR: filtered[0].renew_MCWG_LMVTR,
            renew_MCWOG_LMVTR: filtered[0].renew_MCWOG_LMVTR,
            renew_MCWOG_MCWG_LMV_TR: filtered[0].renew_MCWOG_MCWG_LMV_TR,
            panCard: filtered[0].panCard,
            voterCard: filtered[0].voterCard,
          });
        } else {
          setbtn(true);
          const filtered = response.filter((item) => item.userID == id);
          console.log(309,filtered)
          setUpdateID(filtered[0]._id);
          setdata({
            userID: id,
            shopActNew: filtered[0].shopActNew,
            shopActReNew: filtered[0].shopActReNew,
            udhamAbhar: filtered[0].udhamAbhar,
            companyGST: filtered[0].companyGST,
            rentAgreement: filtered[0].rentAgreement,
            individualGST: filtered[0].individualGST,
            foodLicense1year: filtered[0].foodLicense1year,
            foodLicense2year: filtered[0].foodLicense2year,
            foodLicense3year: filtered[0].foodLicense3year,
            eGazette_OpenOBC: filtered[0].eGazette_OpenOBC,
            eGazette_SC: filtered[0].eGazette_SC,
            eGazette_SBC: filtered[0].eGazette_SBC,
            eGazette_ST: filtered[0].eGazette_ST,
            eGazette_VJ: filtered[0].eGazette_VJ,
            eGazette_NT: filtered[0].eGazette_NT,
            passport_Normal: filtered[0].passport_Normal,
            passport_Tatkal: filtered[0].passport_Tatkal,
            learningDl_MCWOG: filtered[0].learningDl_MCWOG,
            learningDl_MCWG: filtered[0].learningDl_MCWG,
            learningDl_LMV: filtered[0].learningDl_LMV,
            learningDl_LMV_TR: filtered[0].learningDl_LMV_TR,
            learningDl_MCWOG_MCWG: filtered[0].learningDl_MCWOG_MCWG,
            learningDl_MCWOG_LMV: filtered[0].learningDl_MCWOG_LMV,
            learningDl_MCWG_LMV: filtered[0].learningDl_MCWG_LMV,
            learningDl_MCWOG_MCWG_LMV: filtered[0].learningDl_MCWOG_MCWG_LMV,
            learningDl_MCWG_LMVTR: filtered[0].learningDl_MCWG_LMVTR,
            learningDl_MCWOG_LMVTR: filtered[0].learningDl_MCWOG_LMVTR,
            learningDl_MCWOG_MCWG_LMV_TR:
              filtered[0].learningDl_MCWOG_MCWG_LMV_TR,
            permanent_MCWOG: filtered[0].permanent_MCWOG,
            permanent_MCWG: filtered[0].permanent_MCWG,
            permanent_LMV: filtered[0].permanent_LMV,
            permanent_LMV_TR: filtered[0].permanent_LMV_TR,
            permanent_MCWOG_MCWG: filtered[0].permanent_MCWOG_MCWG,
            permanent_MCWOG_LMV: filtered[0].permanent_MCWOG_LMV,
            permanent_MCWG_LMV: filtered[0].permanent_MCWG_LMV,
            permanent_MCWOG_MCWG_LMV: filtered[0].permanent_MCWOG_MCWG_LMV,
            permanent_MCWG_LMVTR: filtered[0].permanent_MCWG_LMVTR,
            permanent_MCWOG_LMVTR: filtered[0].permanent_MCWOG_LMVTR,
            permanent_MCWOG_MCWG_LMV_TR:
              filtered[0].permanent_MCWOG_MCWG_LMV_TR,
            renew_MCWOG: filtered[0].renew_MCWOG,
            renew_MCWG: filtered[0].renew_MCWG,
            renew_LMV: filtered[0].renew_LMV,
            renew_LMV_TR: filtered[0].renew_LMV_TR,
            renew_MCWOG_MCWG: filtered[0].renew_MCWOG_MCWG,
            renew_MCWOG_LMV: filtered[0].renew_MCWOG_LMV,
            renew_MCWG_LMV: filtered[0].renew_MCWG_LMV,
            renew_MCWOG_MCWG_LMV: filtered[0].renew_MCWOG_MCWG_LMV,
            renew_MCWG_LMVTR: filtered[0].renew_MCWG_LMVTR,
            renew_MCWOG_LMVTR: filtered[0].renew_MCWOG_LMVTR,
            renew_MCWOG_MCWG_LMV_TR: filtered[0].renew_MCWOG_MCWG_LMV_TR,
            panCard: filtered[0].panCard,
            voterCard: filtered[0].voterCard,
          });
        }
      })

      .catch((err) => console.log(34, err));
  };

  const SaveData = (e) => {
    e.preventDefault();
    console.log(371, selectedUserID);
    console.log(61, data);
    axios
      .post(`https://api.maharashtraseva.com/api/formPrice/create`, data)
      .then((res) => {
        const response = res.data;
        setdata({
          shopActNew: "",
          shopActReNew: "",
          udhamAbhar: "",
          companyGST: "",
          rentAgreement: "",
          individualGST: "",
          foodLicense1year: "",
          foodLicense2year: "",
          foodLicense3year: "",
          eGazette_OpenOBC: "",
          eGazette_SC: "",
          eGazette_SBC: "",
          eGazette_ST: "",
          eGazette_VJ: "",
          eGazette_NT: "",
          passport_Normal: "",
          passport_Tatkal: "",
          learningDl_MCWOG: "",
          learningDl_MCWG: "",
          learningDl_LMV: "",
          learningDl_LMV_TR: "",
          learningDl_MCWOG_MCWG: "",
          learningDl_MCWOG_LMV: "",
          learningDl_MCWG_LMV: "",
          learningDl_MCWOG_MCWG_LMV: "",
          learningDl_MCWG_LMVTR: "",
          learningDl_MCWOG_LMVTR: "",
          learningDl_MCWOG_MCWG_LMV_TR: "",
          permanent_MCWOG: "",
          permanent_MCWG: "",
          permanent_LMV: "",
          permanent_LMV_TR: "",
          permanent_MCWOG_MCWG: "",
          permanent_MCWOG_LMV: "",
          permanent_MCWG_LMV: "",
          permanent_MCWOG_MCWG_LMV: "",
          permanent_MCWG_LMVTR: "",
          permanent_MCWOG_LMVTR: "",
          permanent_MCWOG_MCWG_LMV_TR: "",
          renew_MCWOG: "",
          renew_MCWG: "",
          renew_LMV: "",
          renew_LMV_TR: "",
          renew_MCWOG_MCWG: "",
          renew_MCWOG_LMV: "",
          renew_MCWG_LMV: "",
          renew_MCWOG_MCWG_LMV: "",
          renew_MCWG_LMVTR: "",
          renew_MCWOG_LMVTR: "",
          renew_MCWOG_MCWG_LMV_TR: "",
          panCard: "",
          voterCard: "",
        });
        swal(     
          ` ${query} Form Details Save Successfully...`,
         
        )
      })

      .catch((err) => console.log(34, err));
  };
  const UpdateData = (e,id) => {
    e.preventDefault();
    console.log(61, data);
    console.log(447,id)
    axios
    .put(`https://api.maharashtraseva.com/api/formPrice/${id}`, data)
    .then((res) => {
      const response = res.data;
      setdata({
        shopActNew: "",
        shopActReNew: "",
        udhamAbhar: "",
        companyGST: "",
        rentAgreement: "",
        individualGST: "",
        foodLicense1year: "",
        foodLicense2year: "",
        foodLicense3year: "",
        eGazette_OpenOBC: "",
        eGazette_SC: "",
        eGazette_SBC: "",
        eGazette_ST: "",
        eGazette_VJ: "",
        eGazette_NT: "",
        passport_Normal: "",
        passport_Tatkal: "",
        learningDl_MCWOG: "",
        learningDl_MCWG: "",
        learningDl_LMV: "",
        learningDl_LMV_TR: "",
        learningDl_MCWOG_MCWG: "",
        learningDl_MCWOG_LMV: "",
        learningDl_MCWG_LMV: "",
        learningDl_MCWOG_MCWG_LMV: "",
        learningDl_MCWG_LMVTR: "",
        learningDl_MCWOG_LMVTR: "",
        learningDl_MCWOG_MCWG_LMV_TR: "",
        permanent_MCWOG: "",
        permanent_MCWG: "",
        permanent_LMV: "",
        permanent_LMV_TR: "",
        permanent_MCWOG_MCWG: "",
        permanent_MCWOG_LMV: "",
        permanent_MCWG_LMV: "",
        permanent_MCWOG_MCWG_LMV: "",
        permanent_MCWG_LMVTR: "",
        permanent_MCWOG_LMVTR: "",
        permanent_MCWOG_MCWG_LMV_TR: "",
        renew_MCWOG: "",
        renew_MCWG: "",
        renew_LMV: "",
        renew_LMV_TR: "",
        renew_MCWOG_MCWG: "",
        renew_MCWOG_LMV: "",
        renew_MCWG_LMV: "",
        renew_MCWOG_MCWG_LMV: "",
        renew_MCWG_LMVTR: "",
        renew_MCWOG_LMVTR: "",
        renew_MCWOG_MCWG_LMV_TR: "",
        panCard: "",
        voterCard: "",
      });
      swal(     
        `  Form Details Update Successfully...`,
       
      )
    })

    .catch((err) => console.log(34, err));
  };
  return (
    <>
      <form action="changeuserpassword_php.php" method="POST">
        <Container fluid={true}>
          <Breadcrumb title="Form Creadits" parent="Create User Passport" />

          <Card>
            <CardBody>
              <div id="layoutSidenav_content">
                <main>
                  <div class="container-fluid px-4 mt-4">
                    <div class="formlayout">
                      <div class="row g-3">
                        <div class="col-md-6">
                          <div class="form-heading">
                            <label>User</label>
                          </div>
                          <br />
                          <input
                            type="text"
                            class="form-control form-control-lg"
                            data-bs-toggle="modal"
                            data-bs-target="#recharge"
                            placeholder="Click For Select User..."
                            value={selectedUser}
                          >
                            {/* Launch demo modal */}
                          </input>

                          {/* <!-- Modal --> */}
                          <div
                            class="modal body w-100"
                            id="recharge"
                            tabindex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-xl">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h1
                                    class="modal-title fs-5 w-100"
                                    id="exampleModalLabel"
                                  >
                                    Select User
                                  </h1>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <label htmlFor="">
                                    <Container fluid={true}>
                                      <div className="row">
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6 d-flex">
                                          <label htmlFor="serch1">
                                            Search:{" "}
                                          </label>
                                          <input
                                            type="text"
                                            name=""
                                            id="serch1"
                                            placeholder="Search"
                                            className="form-control form-control-sm"
                                            value={query}
                                            onChange={searchByName}
                                          />
                                        </div>
                                      </div>
                                      <Card>
                                        <CardBody>
                                          <div className="clearfix"></div>
                                          <div className="row ">
                                            <DataTable
                                              columns={columns}
                                              data={user}
                                              pagination
                                              highlightOnHover
                                              fixedHeader
                                              fixedHeaderScrollHeight="600px"
                                              customStyles={customStyles}
                                            />
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Container>
                                  </label>
                                </div>
                                {/* <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button type="button" class="btn btn-primary">
                                    Save changes
                                  </button>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />

                      <div class="modal fade" id="myModal" role="dialog" />
                      <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"></div>
                    </div>
                  </div>
                </main>
              </div>

              <main>
                <div className="container">
                  <h4 className="form-txt p-2 mt-5">Application Charges </h4>
                  <div className="form">
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th>Application Type</th>
                          <th width="30%">Credit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Shop-Act - Shop-Act New</td>
                          <td>
                            <input
                              type="number"
                              name="FC1"
                              value={data.shopActNew}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  shopActNew: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Shop-Act - Shop-Act Renew</td>
                          <td>
                            <input
                              type="number"
                              name="FC2"
                              value={data.shopActReNew}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  shopActReNew: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Udyog-Aadhar - Udyog-Aadhar</td>
                          <td>
                            <input
                              type="number"
                              name="FC3"
                              value={data.udhamAbhar}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  udhamAbhar: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Company GST - Company GST</td>
                          <td>
                            <input
                              type="number"
                              name="FC4"
                              value={data.companyGST}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  companyGST: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Rent Agreement - Rent Agreement</td>
                          <td>
                            <input
                              type="number"
                              name="FC5"
                              value={data.rentAgreement}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  rentAgreement: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Individual GST - Individual GST</td>
                          <td>
                            <input
                              type="number"
                              name="FC6"
                              value={data.individualGST}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  individualGST: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Food License - Food License (1 Year)</td>
                          <td>
                            <input
                              type="number"
                              name="FC7"
                              value={data.foodLicense1year}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  foodLicense1year: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Food License - Food License (3 Year)</td>
                          <td>
                            <input
                              type="number"
                              name="FC8"
                              value={data.foodLicense2year}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  foodLicense2year: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Food License - Food License (5 Year)</td>
                          <td>
                            <input
                              type="number"
                              name="FC9"
                              value={data.foodLicense3year}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  foodLicense3year: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>e-Gazette - OPEN/OBC</td>
                          <td>
                            <input
                              type="number"
                              name="FC10"
                              value={data.eGazette_OpenOBC}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  eGazette_OpenOBC: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>e-Gazette - SC</td>
                          <td>
                            <input
                              type="number"
                              name="FC11"
                              value={data.eGazette_SC}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  eGazette_SC: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>e-Gazette - SBC</td>
                          <td>
                            <input
                              type="number"
                              name="FC12"
                              value={data.eGazette_SBC}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  eGazette_SBC: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>e-Gazette - ST</td>
                          <td>
                            <input
                              type="number"
                              name="FC13"
                              value={data.eGazette_ST}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  eGazette_ST: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>e-Gazette - VJ</td>
                          <td>
                            <input
                              type="number"
                              name="FC14"
                              value={data.eGazette_VJ}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  eGazette_VJ: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>e-Gazette - NT</td>
                          <td>
                            <input
                              type="number"
                              name="FC15"
                              value={data.eGazette_NT}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  eGazette_NT: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Passport - Normal</td>
                          <td>
                            <input
                              type="number"
                              name="FC16"
                              value={data.passport_Normal}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  passport_Normal: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Passport - Tatkal</td>
                          <td>
                            <input
                              type="number"
                              name="FC17"
                              value={data.passport_Tatkal}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  passport_Tatkal: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Learning DL - Motor cycle without Gear (Non
                            Transport) (MCWOG)
                          </td>
                          <td>
                            <input
                              type="number"
                              name="FC18"
                              value={data.learningDl_MCWOG}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_MCWOG: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Learning DL - Motor Cycle with Gear(Non Transport)
                            (MCWG)
                          </td>
                          <td>
                            <input
                              type="number"
                              name="FC19"
                              value={data.learningDl_MCWG}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_MCWG: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Learning DL - Light Motor Vehicle (LMV)</td>
                          <td>
                            <input
                              type="number"
                              name="FC20"
                              value={data.learningDl_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_LMV: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Learning DL - Light Motor Vehicle Transport (LMV-TR)
                          </td>
                          <td>
                            <input
                              type="number"
                              name="FC21"
                              value={data.learningDl_LMV_TR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_LMV_TR: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Learning DL - MCWOG + MCWG</td>
                          <td>
                            <input
                              type="number"
                              name="FC22"
                              value={data.learningDl_MCWOG_MCWG}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_MCWOG_MCWG: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Learning DL - MCWOG + LMV</td>
                          <td>
                            <input
                              type="number"
                              name="FC23"
                              value={data.learningDl_MCWOG_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_MCWOG_LMV: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Learning DL - MCWG + LMV</td>
                          <td>
                            <input
                              type="number"
                              name="FC24"
                              value={data.learningDl_MCWG_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_MCWG_LMV: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Learning DL - MCWOG + MCWG + LMV</td>
                          <td>
                            <input
                              type="number"
                              name="FC25"
                              value={data.learningDl_MCWOG_MCWG_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_MCWOG_MCWG_LMV: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Learning DL - MCWG + LMV-TR</td>
                          <td>
                            <input
                              type="number"
                              name="FC26"
                              value={data.learningDl_MCWG_LMVTR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_MCWG_LMVTR: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Learning DL - MCWOG + LMV-TR</td>
                          <td>
                            <input
                              type="number"
                              name="FC27"
                              value={data.learningDl_MCWOG_LMVTR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_MCWOG_LMVTR: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Learning DL - MCWOG + MCWG + LMV-TR</td>
                          <td>
                            <input
                              type="number"
                              name="FC28"
                              value={data.learningDl_MCWOG_MCWG_LMV_TR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  learningDl_MCWOG_MCWG_LMV_TR: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Permanent DL - Motor cycle without Gear (Non
                            Transport) (MCWOG)
                          </td>
                          <td>
                            <input
                              type="number"
                              name="FC29"
                              value={data.permanent_MCWOG}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_MCWOG: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Permanent DL - Motor Cycle with Gear(Non Transport)
                            (MCWG)
                          </td>
                          <td>
                            <input
                              type="number"
                              name="FC30"
                              value={data.permanent_MCWG}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_MCWG: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Permanent DL - Light Motor Vehicle (LMV)</td>
                          <td>
                            <input
                              type="number"
                              name="FC31"
                              value={data.permanent_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_LMV: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Permanent DL - Light Motor Vehicle Transport
                            (LMV-TR)
                          </td>
                          <td>
                            <input
                              type="number"
                              name="FC32"
                              value={data.permanent_LMV_TR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_LMV_TR: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Permanent DL - MCWOG + MCWG</td>
                          <td>
                            <input
                              type="number"
                              name="FC33"
                              value={data.permanent_MCWOG_MCWG}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_MCWOG_MCWG: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Permanent DL - MCWOG + LMV</td>
                          <td>
                            <input
                              type="number"
                              name="FC34"
                              value={data.permanent_MCWOG_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_MCWOG_LMV: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Permanent DL - MCWG + LMV</td>
                          <td>
                            <input
                              type="number"
                              name="FC35"
                              value={data.permanent_MCWG_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_MCWG_LMV: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td> Permanent DL - MCWOG + MCWG + LMV</td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              name="FC36"
                              value={data.permanent_MCWOG_MCWG_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_MCWOG_MCWG_LMV: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>Permanent DL - MCWG + LMV-TR</td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              name="FC37"
                              value={data.permanent_MCWG_LMVTR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_MCWG_LMVTR: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>Permanent DL - MCWOG + LMV-TR</td>
                          <td>
                            <input
                              type="number"
                              name="FC38"
                              value={data.permanent_MCWOG_LMVTR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_MCWOG_LMVTR: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td> Permanent DL - MCWOG + MCWG + LMV-TR</td>
                          <td>
                            <input
                              type="number"
                              name="FC39"
                              value={data.permanent_MCWOG_MCWG_LMV_TR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  permanent_MCWOG_MCWG_LMV_TR: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>
                            Renew DL - Motor cycle without Gear (Non Transport)
                            (MCWOG)
                          </td>
                          <td>
                            <input
                              type="number"
                              name="FC40"
                              value={data.renew_MCWOG}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_MCWOG: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>
                            Renew DL - Motor Cycle with Gear(Non Transport)
                            (MCWG)
                          </td>
                          <td>
                            <input
                              type="number"
                              name="FC41"
                              value={data.renew_MCWG}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_MCWG: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>Renew DL - Light Motor Vehicle (LMV)</td>
                          <td>
                            <input
                              type="number"
                              name="FC42"
                              value={data.renew_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_LMV: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>
                            Renew DL - Light Motor Vehicle Transport (LMV-TR)
                          </td>
                          <td>
                            <input
                              type="number"
                              name="FC43"
                              value={data.renew_LMV_TR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_LMV_TR: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>Renew DL - MCWOG + MCWG</td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              name="FC44"
                              value={data.renew_MCWOG_MCWG}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_MCWOG_MCWG: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>Renew DL - MCWOG + LMV</td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              name="FC45"
                              value={data.renew_MCWOG_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_MCWOG_LMV: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>Renew DL - MCWG + LMV</td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              name="FC46"
                              value={data.renew_MCWG_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_MCWG_LMV: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td> Renew DL - MCWOG + MCWG + LMV</td>
                          <td>
                            <input
                              type="number"
                              name="FC47"
                              value={data.renew_MCWOG_MCWG_LMV}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_MCWOG_MCWG_LMV: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td> Renew DL - MCWG + LMV-TR</td>
                          <td>
                            <input
                              type="number"
                              name="FC48"
                              value={data.renew_MCWG_LMVTR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_MCWG_LMVTR: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td> Renew DL - MCWOG + LMV-TR</td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              name="FC49"
                              value={data.renew_MCWOG_LMVTR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_MCWOG_LMVTR: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>Renew DL - MCWOG + MCWG + LMV-TR</td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              name="FC50"
                              value={data.renew_MCWOG_MCWG_LMV_TR}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  renew_MCWOG_MCWG_LMV_TR: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td> PAN Card - New PAN Card</td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              name="FC51"
                              value={data.panCard}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  panCard: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Voter Card - Voter Card</td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              name="FC52"
                              value={data.voterCard}
                              class="form-control"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  voterCard: parseInt(e.target.value),
                                })
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {showbtn && (
                      <>
                        {btn ? (
                          <>
                            <div class="col-md-12 text-center">
                              <input
                                type="submit"
                                name="Update"
                                class="savebtn btn btn-primary "
                                value="update Changes "
                                onClick={(e) => {
                                  UpdateData(e,updateID);
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div class="col-md-12 text-center">
                              <input
                                type="submit"
                                name="submit"
                                class="savebtn btn btn-primary "
                                value="Save"
                                onClick={(e) => {
                                  SaveData(e);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </main>
            </CardBody>
          </Card>
        </Container>
      </form>
    </>
  );
};

export default AdminFormprice;
