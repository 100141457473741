import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import data from "../../assets/data/listUser";
import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import axios from "axios";
import DataTable from "react-data-table-component";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import { incNumber } from "../../Redux/actions";

const List_user = () => {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const userData = useSelector((state) => state.userData.user);
  const [rechargeData, setRechargeData] = useState([]);
  const [user, setUser] = useState(userData ? userData : []);
  const users = [];
  const [AllUserData, setAllUserData] = useState([]);

  if (user?.length > 0) {
    user.forEach((data) => {
      let amount = 0;
      let expence = 0;
      rechargeData.forEach((item) => {
        if (item.user === data._id) {
          if (item.isExpence === "true") {
            expence += item.amount;
          } else {
            amount += item.amount;
          }
        }
      });
      users.push({ ...data, balance: amount - expence });
    });
  }

  useEffect(() => {
    axios.get("https://api.maharashtraseva.com/api/recharge").then((res) => {
      const response = res.data;
      setRechargeData(response);
    });
    axios
      .get(`https://api.maharashtraseva.com/api/user/getAll`)
      .then((res) => {
        const response = res.data;
        setUser(response);
      })
      .catch((err) => console.log(40, err));
  }, []);
  // const navigate = useNavigate();

  const [Data, SetData] = useState({
    modalcontent: "",
  });

  const deleteUser = (id) => {
    console.log(56,id);
    
    axios
      .delete(`https://api.maharashtraseva.com/api/deleteUser/${id}`)
      .then((res) => {
      swal("Deleted", "User Deleted successfully!", "success");
      dispatch(incNumber()); 
    })   
      .catch((err) => {
        console.log(err);
      });

  };
  const columns = [
    {
      name: "Sr No",
      width:"90px",
      style: {
        textAlign: "center",
        Width: "120px",
      },
      selector: (row, index) => index + 1,
    },
    {
      name: "Action",
      width:"140px",
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => (
        <>
          <div className="row">
            <div className="col-ld-4 d-flex ">
              <i
                class="bi bi-pencil-square fs-4 text-success"
                style={{ cursor: "pointer" }}
                onClick={(e)=>navigate(`/update-user/${row._id}`)}
              ></i>
              <i
                class="bi bi-trash3 fs-4 text-danger mx-5"
                style={{ cursor: "pointer" }}
                onClick={(e) => deleteUser(row._id)}
              ></i>
            </div>
          </div>
        </>
      ),
    },
    {
      name: " Name",
      width:"300px",
      style: {
        textAlign: "center",
      },
      // center:true,
      selector: (row) => row.name,
    },
    {
      name: " User name",
      width:"220px",
      style: {
        textAlign: "center",
      },
      // center:true,
      selector: (row) => row.username,
    },

    {
      name: "Email Address",
      width:"350px",
      // center:true,
      style: {
        textAlign: "center",
        Width: "200px",
      },
      selector: (row) => row.email,
    },
    {
      name: "Address",
      width:"350px",
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => (
        <>{`${row.district} ,${row.tehsil}, ${row.address1} `}</>
      ),
    },
    {
      name: "Mobile No.",
      width:"180px",
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => row.mobileNumber,
    },
    {
      name: "Role",
      width:"120px",
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => row.role,
    },
    {
      name: "Balance",
      width:"220px",
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => row.balance,
    },
    
  ];
  const customStyles = {
    rows: {
      style: {
        border: "0.5px solid black",
        fontWaight: "900",
        // Add any other CSS properties you want to apply to the rows
        fontSize: "10px",
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        fontWaight: "900",
        fontSize: "12px",
        border: "0.5px solid black",
        background: "#0094ef",
        color: "white",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        fontSize: "12px",
        border: "0.5px solid black",
      },
    },
    cells: {
      style: {
        // fontSize:"9px",
        color: "black",
        fontWaight: "900",
        // Add any styles for the regular cells
        border: "0.5px solid black",
        fontSize: "15px",
      },
    },
    pagination: {
      style: {
        // Add any styles for the pagination section
        border: "0.5px solid black",
      },
    },
  };
  const [search, setSearch] = useState();
  const [issearch, setISSearch] = useState(false);
  const [query, setQuery] = useState("");

  const searchByName = (e) => {
    setQuery(e.target.value);
    const value = e.target.value;

    if (value.length > 0) {
      const searchData = user.filter((item, index) => {
        if (item.name != undefined && item.name.length > 0) {
          const name = item.name;
          if (name.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        }
      });
      setUser(searchData);
    } else {
      setUser(userData);
    }
    // setISSearch(true);
    // setSearch(searchData);
  };

  return (
    <Fragment>

      <Container fluid={true}>
      <Breadcrumb title="User Details"  />
        <Card>
          <CardBody>
            <div className="row">
             
              <div className="col-lg-6">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="ENter UserName"
                    value={query}
                    onChange={searchByName}
                  />
                  <label for="floatingInput"> Search User </label>
                </div>
              </div>
            </div>
            <div className=""></div>

            <DataTable
              // title="User List"
              columns={columns}
              data={users}
              pagination
              highlightOnHover
              fixedHeaderScrollHeight="600px"
              customStyles={customStyles}
            />
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default List_user;
