import React from "react";
import axios from "axios";
import { MdUploadFile } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import { Fragment, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { toast } from "react-toastify";
// import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { incNumber } from "../../Redux/actions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { json } from "react-router-dom";
import changeNumber from "../../Redux/reducers/reducers";
// import { incNumber } from "../../Redux/actions";
// import { toast } from "react-toastify";
import swal from "sweetalert";

import {
  work_type,
  districtData,
  ApllicantBenificiaryOtherState,
} from "../../constants/data";
const VoterCard = () => {
  const navigate = useNavigate();
  const { vid } = useParams();

  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [form] = Form.useForm();
  const [showloading, setShowloading] = useState(false);
  const users = useSelector((state) => state.userData.user);
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const SingleUser = users?.filter((ele) => ele._id === userData.user._id);
  const pricess = useSelector((state) => state.price.formPrices);
  const [balance, setBalance] = useState(0);
  const [userFormDetails, setuserFormDetails] = useState();
  const reducer = useSelector((state) => state.changeNumber);
  const [FormFileerror, setFormFileerror] = useState({});
  const [ShowperAdd, setShowPerAddress] = useState(false);
  const [ShowperDisBox, setShowperDisBox] = useState(false);
  const [ShowOldWoterCard, setShowOldWoterCard] = useState(false);
  const [Data, SetData] = useState({
    createdBy: userData.user._id,
    createdByName: userData.user?.name,
    application_type: "voterCard",
    isNew: true,
    status: "IN-PROGRESS",
    formPrice:"",
    assembly_Parliamentary_Constituency: "",
    request_name_electoral_roll_Constituency: "",
    name: "",
    Surname: "",
    gender: "",
    date_of_Birth: "",
    birth_Town_Village_Office: "",
    birth_District: "",
    birth_State_Union_Territory: "",
    relation: "",
    currentAddress_houseNumber: "",
    currentAddress_streetArea_Locality: "",
    currentAddress_townVillage_Office: "",
    currentAddress_postOffice: "",
    currentAddress_district: "",
    currentAddress_stateUnion_Territory: "",
    currentAddress_pincodeZip_code: "",
    currentAddress_ordinarily_resident_address_since: "",
    currentAddress_permanentAddress_currentAddress: "",
    permanent_address: "",
    permanentAddress_House_No: "",
    permanentAddress_streetArea_Locality: "",
    permanentAddress_townVillage_Office: "",
    permanentAddress_postOffice: "",
    permanentAddress_district: "",
    permanentAddress_stateUnion_territory: "",
    permanentAddress_pincodeZip_code: "",
    permanentAddress_telephoneMobile_Number: "",
    email: "",
    EPIC_No: "",
    disability: "",
    disabilityType: "",
    previous_Constituency: "",
    previousConstituency_state: "",
  });
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/formPrice`)
      .then((res) => {
        const response = res.data;
        const filtered = response.filter(
          (item) => item.userID == userData.user._id
        );
        console.log(50, filtered);
        if (filtered.length == 0) {
          const userFormDetails = response.filter(
            (item) => item.userID === "ALL"
          );
          setuserFormDetails(userFormDetails[0]?.voterCard);
          SetData({...Data,formPrice:userFormDetails[0]?.voterCard})
        } else {
          console.log(51, "User");
          const userFormDetails = response.filter(
            (item) => item.userID == userData.user._id
          );
          setuserFormDetails(userFormDetails[0]?.voterCard);
          SetData({...Data,formPrice:userFormDetails[0]?.voterCard})
         
        }
      })
      .catch((err) => console.log(40, err));
  }, []);
  // console.log(58,userFormDetails[0].voterCard);
  useEffect(() => {
    if (userData) {
      axios.get(`https://api.maharashtraseva.com/api/recharge`).then((res) => {
        console.log(88, res.data);

        const allData = res.data.filter(
          (ele) => ele.user === userData.user._id
        );
        let amount = 0;
        let expence = 0;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.isExpence === "true") {
              expence += item.amount;
            } else {
              amount += item.amount;
            }
          });
        }
        setBalance(amount - expence);
      });
    }
  }, [reducer]);
  console.log(25, userFormDetails);
  const [docs, setDocs] = useState({
    votercard_addressProofDoc: "",
    votercard_ageProofDOc: "",
    votercard_PassportSizePhoto: "",
    votercard_ageDeclarationForm: "",
  });

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
 
  const [Formerror, setFormerror] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  useEffect(() => {
    console.log(Formerror);
    if (Object.keys(Formerror).length == 0 && isSubmit) {
      console.log(Data);
    }
  }, [Formerror]);
  const validate = (values) => {
    const error = {};
    if (!values.assembly_Parliamentary_Constituency) {
      error.assembly_Parliamentary_Constituency =
        " assembly_Parliamentary_Constituency is required";
    }
    if (!values.request_name_electoral_roll_Constituency) {
      error.request_name_electoral_roll_Constituency =
        " request_name_electoral_roll_Constituency is required";
    }
    if (!values.name) {
      error.name = " FullName  Is required";
    }
    if (!values.Surname) {
      error.Surname = " Surname is required";
    }
    if (!values.gender) {
      error.gender = " gender is required";
    }
    if (!values.date_of_Birth) {
      error.date_of_Birth = " Date_of_Birth is required";
    }
    if (!values.relation) {
      error.relation = " relation is required";
    }
    if (!values.currentAddress_houseNumber) {
      error.currentAddress_houseNumber =
        " currentAddress_houseNumber is required";
    }
    if (!values.currentAddress_streetArea_Locality) {
      error.currentAddress_streetArea_Locality =
        " currentAddress_streetArea_Locality is required";
    }
    if (!values.currentAddress_stateUnion_Territory) {
      error.currentAddress_stateUnion_Territory =
        "currentAddress_stateUnion_Territory is required";
    }
    if (!values.currentAddress_townVillage_Office) {
      error.currentAddress_townVillage_Office =
        " currentAddress_townVillage_Office is required";
    }
    if (!values.currentAddress_postOffice) {
      error.currentAddress_postOffice =
        " currentAddress_postOffice is required";
    }
    if (!values.currentAddress_district) {
      error.currentAddress_district = " Distict is required";
    }
    if (!values.currentAddress_pincodeZip_code) {
      error.currentAddress_pincodeZip_code = "Pincode / Zip codeis required";
    }
    if (!values.currentAddress_ordinarily_resident_address_since) {
      error.currentAddress_ordinarily_resident_address_since = " is required";
    }
    if (!values.permanentAddress_House_No) {
      error.permanentAddress_House_No = "House Number is required";
    }
    if (!values.permanentAddress_streetArea_Locality) {
      error.permanentAddress_streetArea_Locality =
        "Street/Area/Locality is required";
    }
    if (!values.permanentAddress_district) {
      error.permanentAddress_district = "Distict is required";
    }
    if (!values.permanentAddress_postOffice) {
      error.permanentAddress_postOffice = "Is required";
    }
    if (!values.permanentAddress_stateUnion_territory) {
      error.permanentAddress_stateUnion_territory = "Is required";
    }
    if (!values.permanentAddress_pincodeZip_code) {
      error.permanentAddress_pincodeZip_code = "Is required";
    }
    if (!values.permanentAddress_telephoneMobile_Number) {
      error.permanentAddress_telephoneMobile_Number = "Is required";
    }

    console.log("error object", error);
    return error;
  };

  const FileValidate = (Val) => {
    let fileError = {};
    if (!Val.votercard_addressProofDoc) {
      fileError.votercard_addressProofDoc = "File is Required";
    } else {
      if (Val.votercard_addressProofDoc.size > 1024 * 1024) {
        fileError.votercard_addressProofDoc =
          " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.votercard_ageProofDOc) {
      fileError.votercard_ageProofDOc = "File is Required";
    } else {
      if (Val.votercard_ageProofDOc.size > 1024 * 1024) {
        fileError.votercard_ageProofDOc = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.votercard_PassportSizePhoto) {
      fileError.votercard_PassportSizePhoto = "File is Required";
    } else {
      if (Val.votercard_PassportSizePhoto.size > 1024 * 1024) {
        fileError.votercard_PassportSizePhoto =
          " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.votercard_ageDeclarationForm) {
      fileError.votercard_ageDeclarationForm = "File is Required";
    } else {
      if (Val.votercard_ageDeclarationForm.size > 1024 * 1024) {
        fileError.votercard_ageDeclarationForm =
          " File size exceeds the limit of 1MB";
      }
    }
    return fileError;
  };
  const handleDateChange = (Bddate, dateString) => {
    SetData({ ...Data, date_of_Birth: dateString });
  };
  const handleResident = (resident, dateString) => {
    SetData({
      ...Data,
      currentAddress_ordinarily_resident_address_since: dateString,
    });
  };
  const postLDJData = (e) => {
    e.preventDefault();
    setShowloading(true);
    const FileError = FileValidate(docs);
    const errors = validate(Data);
    console.log(312, Data);
    console.log("formErrors:", errors);
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(FileError).length === 0
    ) {
      SetIsSubmit(true);
      if (balance > userFormDetails) {
        setShowloading(true)
        const mainDataPromise = new Promise((resolve, reject) => {
          // console.log(77, "OK");
          axios
            .post(`https://api.maharashtraseva.com/api/votercard/create`, Data)
            .then((res) => {
              const response = res;
              resolve({
                status: true,
                message: "data Posted Successfully",
                data: res.data,
              });
              setShowloading(false);
            })
            .catch((err) => {
              console.log(err);
              setShowloading(false)

              reject({
                status: false,
                message: "Data Not posted",
              });
              setShowloading(false);
            });
        });

        mainDataPromise
          .then((res) => {
            // console.log(124,res.data)

            addressProof(res.data.data._id);
            ageProof(res.data.data._id);
            passportSize(res.data.data._id);
            agedeclaration(res.data.data._id);
            debitFormBalance();
            SetData({
              assembly_Parliamentary_Constituency: "",
              request_name_electoral_roll_Constituency: "",
              name: "",
              Surname: "",
              gender: "",
              date_of_Birth: "",
              birth_Town_Village_Office: "",
              birth_District: "",
              birth_State_Union_Territory: "",
              relation: "",
              currentAddress_houseNumber: "",
              currentAddress_streetArea_Locality: "",
              currentAddress_townVillage_Office: "",
              currentAddress_postOffice: "",
              currentAddress_district: "",
              currentAddress_stateUnion_Territory: "",
              currentAddress_pincodeZip_code: "",
              currentAddress_ordinarily_resident_address_since: "",
              currentAddress_permanentAddress_currentAddress: "",
              permanent_address: "",
              permanentAddress_House_No: "",
              permanentAddress_streetArea_Locality: "",
              permanentAddress_townVillage_Office: "",
              permanentAddress_postOffice: "",
              permanentAddress_district: "",
              permanentAddress_stateUnion_territory: "",
              permanentAddress_pincodeZip_code: "",
              permanentAddress_telephoneMobile_Number: "",
              email: "",
              EPIC_No: "",
              disability: "",
              disabilityType: "",
              previous_Constituency: "",
              previousConstituency_state: "",
            });
          }).then(() => navigate("/RetailerDashboard"))
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error("Please Recharge");
        setShowloading(false);
      }
    } else {
      setFormerror(errors);
      setFormFileerror(FileError);
      swal("Filled the required form");
      setShowloading(false);
    }
  };
  const obj = {
    user: userData?.user._id,
    mode: "offline",
    amount: userFormDetails,
    isExpence: true,
    expenceFor: "voterCardCtl",
  };
  console.log(146, obj);
  const debitFormBalance = () => {
    axios
      .post("https://api.maharashtraseva.com/api/recharge/create", obj)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());
        toast.success("form Submitted");
        swal("Good job!", "form submitted successfully!", "success");
        // navigate("/retailer_console");
      })
      .catch((err) => console.log(34, err));
  };
  const addressProof = (id) => {
    const formData = new FormData();
    formData.append("addressProof_Doc", docs.votercard_addressProofDoc);
    axios
      .put(
        `https://api.maharashtraseva.com/api/votercard_addressProofDoc/${id}`,
        formData
      )
      .then((res) => console.log("addressUploaded", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const ageProof = (id) => {
    console.log(143, id);
    const formData = new FormData();
    formData.append("ageProof_DOc", docs.votercard_ageProofDOc);
    axios
      .put(`https://api.maharashtraseva.com/api/votercard_ageProofDOc/${id}`, formData)
      .then((res) => console.log("ageProofUploaded", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const passportSize = (id) => {
    const formData = new FormData();
    formData.append("PassportSize_photo", docs.votercard_PassportSizePhoto);
    axios
      .put(
        `https://api.maharashtraseva.com/api/votercard_PassportSizePhoto/${id}`,
        formData
      )
      .then((res) => console.log("passportSizeUploadaed", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const agedeclaration = (id) => {
    const formData = new FormData();
    formData.append("age_declaration_form", docs.votercard_ageDeclarationForm);
    axios
      .put(
        `https://api.maharashtraseva.com/api/votercard_ageDeclarationForm/${id}`,
        formData
      )
      .then((res) => console.log("ageDeclarationForm Uploaded", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (vid != undefined) {
      axios.get(`https://api.maharashtraseva.com/api/votercard/${vid}`).then((res) => {
        const data = res.data[0];

        console.log("data", data);
        SetData(data);
        if (
          data?.isNew == true &&
          data?.status == "REJECTED" &&
          userData.user.role === "retailer"
        ) {
          axios
            .put(`https://api.maharashtraseva.com/api/votercard/${vid}`, {
              isNew: false,
            })
            .then((res) => {
              dispatch(incNumber());
            })
            .catch((err) => console.log(err));
        }
      });
    }
  }, [vid]);

  const [talukas, setTalukas] = useState([]);
  const setDestrictName = (e) => {
    console.log("districtData:", districtData);

    districtData.filter((dist) => {
      if (dist.name.toLowerCase() == e.toString().toLowerCase()) {
        console.log(dist.tahasil);
        setTalukas(dist.tahasil);
      }
    });
  };

  const editHandler = (e) => {
    e.preventDefault();

    const obj = {
      ...Data,
      createdBy: userData.user._id,
      createdByName: userData.user?.name,
      application_type: "voterCard",
      status: "IN-PROGRESS",
    };

    const mainDataPromise = new Promise((resolve, reject) => {
      axios
        .put(`https://api.maharashtraseva.com/api/votercard/${vid}`, obj)
        .then((res) => {
          const response = res;
          resolve({
            status: true,
            message: "data Updated Successfully",
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            status: false,
            message: "Data Not posted",
          });
        });
    });

    mainDataPromise
      .then((res) => {
        // console.log(124,res.data)

        addressProof(res.data._id);
        ageProof(res.data._id);
        passportSize(res.data._id);
        agedeclaration(res.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Breadcrumb title={"Voter Card Form"} />
      <Container fluid={true}>
        <h3>Form Price :{userFormDetails}</h3>

        <Row>
          <Col sm="">
            <Card>
              <CardBody>
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div className="row">
                    <div className="col-md-6">
                      <label
                        htmlFor=""
                        className="mb-3 fs-6"
                      >
                        {" "}
                        <span className="red">*</span>{" "}
                        Assembly / Parliamentary Constituency
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Assembly / Parliamentary Constituency!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your Assembly / Parliamentary Constituency!"
                          value={Data.assembly_Parliamentary_Constituency}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              assembly_Parliamentary_Constituency:
                                e.target.value,
                            });
                          }}
                        />{" "}
                        <p className="red">
                          {Formerror.assembly_Parliamentary_Constituency}
                        </p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        
                          I request that my name be included in the electoral
                          roll for the above Constituency
                        
                      </label>

                      <Form.Item>
                        <Radio.Group
                          placeholder="--select--"
                          value={Data.request_name_electoral_roll_Constituency}
                          onChange={(e) => {
                            if (
                              e.target.value ==
                              "Due to shifting from another constituency"
                            ) {
                              setShowOldWoterCard(true);
                            } else {
                              setShowOldWoterCard(false);
                            }
                            SetData({
                              ...Data,
                              request_name_electoral_roll_Constituency:
                                e.target.value,
                            });
                            console.log("request data:", e.target.value);
                          }}
                        >
                          <Radio value="As a first time voter">
                            As a first time voter
                          </Radio>
                          <br />
                          <Radio value="Due to shifting from another constituency">
                            Due to shifting from another constituency
                          </Radio>
                        </Radio.Group>
                        <p className="red">
                          {Formerror.request_name_electoral_roll_Constituency}
                        </p>
                      </Form.Item>
                    </div>
                    {ShowOldWoterCard && (
                      <>
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {" "}
                            <span className="red">*</span>{" "}
                            Old Voter Card Number
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input
                              className="p-2"
                              placeholder="Please input your First Name!"
                              value={Data.oldVoterCardNo}
                              onChange={(e) => {
                                SetData({
                                  ...Data,
                                  oldVoterCardNo: e.target.value,
                                });
                              }}
                            />{" "}
                          </Form.Item>
                        </div>
                      </>
                    )}
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your First Name!"
                          value={Data.name}
                          onChange={(e) => {
                            SetData({ ...Data, name: e.target.value });
                          }}
                        />{" "}
                        <p className="red">{Formerror.name}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Surname
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your Surname !"
                          value={Data.Surname}
                          onChange={(e) => {
                            SetData({ ...Data, Surname: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.Surname}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Gender
                      </label>

                      <Form.Item>
                        <Radio.Group
                          placeholder="--select--"
                          onChange={(e) => {
                            SetData({ ...Data, gender: e.target.value });
                          }}
                        >
                          <Radio value="male">Male</Radio>
                          <Radio value="female">Female</Radio>
                          <Radio value="others">Others</Radio>
                        </Radio.Group>
                        <p className="red">{Formerror.gender}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Date Of Birth
                      </label>

                      <Form.Item>
                        <DatePicker
                          onChange={handleDateChange}
                          format="DD-MM-YYYY"
                        />
                        <p className="red">{Formerror.date_of_Birth}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Birth Town/Village Office
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Village!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your Birth Town/Village Office"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              birth_Town_Village_Office: e.target.value,
                            });
                          }}
                        />{" "}
                        <p className="red">
                          {Formerror.birth_Town_Village_Office}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Birth District
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your District!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="--Select District--"
                          onChange={(e) => {
                            SetData({ ...Data, birth_District: e });
                            setDestrictName(e);
                          }}
                          showSearch
                        >
                          {districtData.map((item, i) => {
                            return (
                              <Select.Option value={item.name} key={i}>
                                {item.name}{" "}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Birth State
                      </label>
                      <Form.Item>
                        <Select
                          placeholder="Select State"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              birth_State_Union_Territory: e,
                            });
                          }}
                          showSearch
                        >
                          {ApllicantBenificiaryOtherState.map((row, index) => (
                            <option value={row} key={index}>
                              {row}
                            </option>
                          ))}
                        </Select>
                        <p className="red">
                          {Formerror.birth_State_Union_Territory}
                        </p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Relation
                      </label>

                      <Form.Item>
                        <Select
                          placeholder="--Select Relation--"
                          value={Data.relation}
                          onChange={(e) => {
                            SetData({ ...Data, relation: e });
                          }}
                          showSearch
                        >
                          <Select.Option value="Father"></Select.Option>
                          <Select.Option value="Mother"></Select.Option>
                          <Select.Option value="Husband"></Select.Option>
                          <Select.Option value="Gaurdian"></Select.Option>
                          <Select.Option value="other"></Select.Option>
                        </Select>{" "}
                        <p className="red">{Formerror.relation}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6 mb-3 text-decoration-underline font-bold">
                      Current Address
                    </div>
                    <div className="col-md-6"></div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        House No.
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Enter House No"
                          value={Data.currentAddress_houseNumber}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              currentAddress_houseNumber: e.target.value,
                            });
                          }}
                        />
                        <p className="red">
                          {Formerror.currentAddress_houseNumber}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Street/Area/Locality
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your Street/Area/Locality!"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              currentAddress_streetArea_Locality:
                                e.target.value,
                            });
                          }}
                        />
                        <p className="red">
                          {Formerror.currentAddress_streetArea_Locality}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Village <span className="red">*</span>
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Village!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your Village!"
                          value={Data.currentAddress_townVillage_Office}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              currentAddress_townVillage_Office: e.target.value,
                            });
                          }}
                        />
                        <p className="red">
                          {Formerror.currentAddress_townVillage_Office}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Post Office
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder=" Please input your Post Office"
                          value={Data.currentAddress_postOffice}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              currentAddress_postOffice: e.target.value,
                            });
                          }}
                        />
                        <p className="red">
                          {Formerror.currentAddress_postOffice}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        <span className="red">*</span>
                        District
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="--Select District--"
                          value={Data.currentAddress_district}
                          onChange={(e) => {
                            SetData({ ...Data, currentAddress_district: e });
                            setDestrictName(e);
                          }}
                          showSearch
                        >
                          {districtData.map((item, i) => {
                            return (
                              <Select.Option value={item.name} key={i}>
                                {item.name}{" "}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <p className="red">
                        {Formerror.currentAddress_townVillage_Office}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        State / Union Territory
                      </label>

                      <Form.Item>
                        <Select
                          placeholder="Select State"
                          value={Data.currentAddress_stateUnion_Territory}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              currentAddress_stateUnion_Territory: e,
                            });
                          }}
                          showSearch
                        >
                          <Select.Option value="Maharashtra">
                            Maharashtra
                          </Select.Option>
                        </Select>
                        <p className="red">
                          {Formerror.currentAddress_stateUnion_Territory}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Pincode / Zip code
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          type="Number"
                          placeholder="Please input your Pincode"
                          value={Data.currentAddress_pincodeZip_code}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              currentAddress_pincodeZip_code: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        
                          I am ordinarily resident at the address given at above
                          since
                        
                      </label>

                      <Form.Item>
                        <DatePicker
                          onChange={handleResident}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Is Current Address Same As Permanant Address
                      </label>

                      <Form.Item>
                        <Select
                          placeholder="Select State"
                          onChange={(e) => {
                            SetData({ ...Data, permanent_address: e });
                            if (e === "yes") {
                              SetData({
                                ...Data,
                                permanentAddress_House_No:
                                  Data.currentAddress_houseNumber,
                                permanentAddress_streetArea_Locality:
                                  Data.currentAddress_streetArea_Locality,
                                permanentAddress_townVillage_Office:
                                  Data.currentAddress_townVillage_Office,
                                permanentAddress_postOffice:
                                  Data.currentAddress_postOffice,
                                permanentAddress_district:
                                  Data.currentAddress_district,
                                permanentAddress_stateUnion_territory:
                                  Data.currentAddress_stateUnion_Territory,
                                permanentAddress_pincodeZip_code:
                                  Data.currentAddress_pincodeZip_code,
                              });
                              setShowPerAddress(true);
                            } else {
                              SetData({
                                ...Data,
                                permanentAddress_House_No: "",
                                permanentAddress_streetArea_Locality: "",
                                permanentAddress_townVillage_Office: "",
                                permanentAddress_postOffice: "",
                                permanentAddress_district: "",
                                permanentAddress_stateUnion_territory: "",
                                permanentAddress_pincodeZip_code: "",
                                // permanentAddress_telephoneMobile_Number: "",
                              });
                              setShowPerAddress(true);
                            }
                          }}
                          showSearch
                        >
                          <Select.Option value="yes">yes</Select.Option>
                          <Select.Option value="no">no</Select.Option>
                        </Select>
                        <p className="red">
                          {Formerror.currentAddress_stateUnion_Territory}
                        </p>
                      </Form.Item>
                    </div>
                    {ShowperAdd && (
                      <>
                        <div className="row">
                          <div className="col-md-6 mb-3 text-decoration-underline font-bold">
                            Permanent Address
                          </div>

                          <div className="col-md-6"></div>

                          <div className="col-md-6">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span>
                              House No.
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your First Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                // className="p-2"
                                placeholder="Enter House Number"
                                value={Data.permanentAddress_House_No}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    permanentAddress_House_No: e.target.value,
                                  });
                                }}
                              />
                              <p className="red">
                                {Formerror.permanentAddress_House_No}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span>{" "}
                               Street/Area/Locality
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your First Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                placeholder="Please Street/Area/Locality"
                                value={
                                  Data.permanentAddress_streetArea_Locality
                                }
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    permanentAddress_streetArea_Locality:
                                      e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                            <p className="red">
                              {Formerror.permanentAddress_streetArea_Locality}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span>
                              Town/Village Office
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Village!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                placeholder="Please input your Village!"
                                value={
                                  Data.permanentAddress_streetArea_Locality
                                }
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    permanentAddress_townVillage_Office:
                                      e.target.value,
                                  });
                                }}
                              />
                              <p className="red">
                                {Formerror.permanentAddress_townVillage_Office}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span> Post Office
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your First Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                placeholder="Please Enter Your Post Office!"
                                value={Data.permanentAddress_postOffice}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    permanentAddress_postOffice: e.target.value,
                                  });
                                }}
                              />
                              <p className="red">
                                {Formerror.permanentAddress_postOffice}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span> District
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your First Name!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="--Select District--"
                                value={Data.permanentAddress_district}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    permanentAddress_district: e,
                                  });
                                  setDestrictName(e);
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select Distict--
                                </Select.Option>
                                {districtData.map((item, i) => {
                                  return (
                                    <Select.Option value={item.name} key={i}>
                                      {item.name}{" "}
                                    </Select.Option>
                                  );
                                })}
                              </Select>{" "}
                              <p className="red">
                                {Formerror.permanentAddress_district}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span>{" "}
                               State / Union Territory
                            </label>

                            <Form.Item>
                              <Select
                                placeholder="Select State"
                                value={
                                  Data.permanentAddress_stateUnion_territory
                                }
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    permanentAddress_stateUnion_territory: e,
                                  });
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select State--
                                </Select.Option>
                                {ApllicantBenificiaryOtherState.map(
                                  (row, index) => (
                                    <option value={row} key={index}>
                                      {row}
                                    </option>
                                  )
                                )}
                              </Select>
                              <p className="red">
                                {
                                  Formerror.permanentAddress_stateUnion_territory
                                }
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span>{" "}
                              Pincode / Zip code
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your First Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                placeholder="Enter your Pincode!"
                                value={Data.permanentAddress_pincodeZip_code}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    permanentAddress_pincodeZip_code:
                                      e.target.value,
                                  });
                                }}
                              />
                              <p className="red">
                                {Formerror.permanentAddress_pincodeZip_code}
                              </p>
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          type="Number"
                          placeholder="Enter Your Mobile Number!"
                          value={Data.permanentAddress_telephoneMobile_Number}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 10) {
                              SetData({
                                ...Data,
                                permanentAddress_telephoneMobile_Number:
                                  sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                            }
                          }}
                        />
                        <p className="red">
                          {Formerror.permanentAddress_telephoneMobile_Number}
                        </p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Email
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Enter Your Email!"
                          value={Data.email}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              email: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.email}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        EPIC No
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="please Enter EPIC No"
                          value={Data.EPIC_No}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              EPIC_No: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Disability
                      </label>

                      <Form.Item>
                        <Select
                          placeholder="--Select Disability--"
                          onChange={(e) => {
                            if (e == "others") {
                              setShowperDisBox(true);
                            }
                            SetData({ ...Data, disability: e });
                          }}
                          showSearch
                        >
                          <Select.Option value="">--Select --</Select.Option>
                          <Select.Option value="Visual Impairment"></Select.Option>
                          <Select.Option value="Speech & Hearning Disability"></Select.Option>
                          <Select.Option value="Locomotor Disability"></Select.Option>
                          <Select.Option value="others"></Select.Option>
                          <Select.Option value="No Disability"></Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    {ShowperDisBox && (
                      <>
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {" "}
                            <span className="red">*</span>{" "}
                             disability Type
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input your Full Name as per Records!",
                              },
                            ]}
                          >
                            <Input
                              className="p-2"
                              value={Data.disabilityType}
                              onChange={(e) => {
                                SetData({
                                  ...Data,
                                  disabilityType: e.target.value,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Previous Constituency
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          value={Data.previous_Constituency}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              previous_Constituency: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.previous_Constituency}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Previous Constituency State
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select State"
                          onChange={(e) => {
                            SetData({ ...Data, previousConstituency_state: e });
                          }}
                          showSearch
                        >
                          {ApllicantBenificiaryOtherState.map((row, index) => (
                            <option value={row} key={index}>
                              {row}
                            </option>
                          ))}
                        </Select>
                        <p>{Formerror.previousConstituency_state}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                         Forms
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <h5>
                          <a target="_blank" href="https://mhseva.com/upload_frms.php?file=Age_Declaration_Form.pdf">
                            {" "}
                            Age Declaration Form
                          </a>
                        </h5>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <a target="_blank" href="https://mhae-seva.s3.amazonaws.com/dcleration/Age_Declaration_Form.pdf"> Declration Form</a>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <h5 className="red">
                      अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व आवश्यक कागदपत्रे
                      तपासून व स्कॅन करून अपलोड करावे. जर आवश्यक कागदपत्रे
                      चुकीची किंवा अस्पष्ट आढळल्यास सदर चा अर्ज फेटाळला जाऊ
                      शकतो.{" "}
                    </h5>
                  </div>

                  <div className="col-md-12">
                    <h5>Upload required documents: </h5>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Address proof (Size -
                        Maximum 1 MB) [Only (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            votercard_addressProofDoc: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">
                        {FormFileerror.votercard_addressProofDoc}
                      </p>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Age Proof (Size - Maximum
                        1 MB) [Only (jpg,jpeg,pdf)]{" "}
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            votercard_ageProofDOc: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">
                        {FormFileerror.votercard_ageProofDOc}
                      </p>
                    </div>

                    <div className="col-md-6">
                      <span className="red">*</span>{" "}
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Passport Size Photo (Size - Maximum 500 Kb) [Only
                        (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            votercard_PassportSizePhoto: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">
                        {FormFileerror.votercard_PassportSizePhoto}
                      </p>
                    </div>

                    <div className="col-md-6">
                      <span className="red">*</span>{" "}
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Age Declaration Form (Size - Maximum 1 MB) [Only
                        (jpg,jpeg,pdf)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            votercard_ageDeclarationForm: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">
                        {FormFileerror.votercard_ageDeclarationForm}
                      </p>
                    </div>
                  </div>
                  <div className="row text-center mt-5">
                    {showloading ? (
                      <>
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {
                        showloading ? (
                          <>
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </>
                        ) : 
                        vid == undefined ? (
                          <div className="col-md-12">
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={(e) => {
                                  postLDJData(e);
                                }}
                              >
                                Submit
                              </Button>
                            </Form.Item>
                          </div>
                        ) : (
                          <div className="col-md-12">
                            <Form.Item>
                              <Button
                                onClick={(e) => {
                                  editHandler(e);
                                }}
                              >
                                Update
                              </Button>
                            </Form.Item>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default VoterCard;
