import React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { MdUploadFile } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { occupationData } from "../../constants/data";
import {
  relation,
  income_input,
  income_Singleinput,
} from "../../constants/data";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { incNumber, setUsersData } from "../../Redux/actions";
import { wordsToNumbers } from "words-to-numbers";
import {
  work_type,
  districtData,
  ApllicantBenificiaryOtherState,
} from "../../constants/data";
import swal from "sweetalert";

function Income() {
  const navigate=useNavigate()
  const [showloading, setShowloading] = useState(false);
  const { id } = useParams();
  const [balance, setBalance] = useState(0);

  console.log(25, income_Singleinput);
  const dispatch = useDispatch();
  const [nameLoading, setNameLoading] = useState(false);
  const [nameMarathi, setnameMarathi] = useState("");
  const [fnameMarathi, setfnameMarathi] = useState("");
  const [agree, setAgree] = useState(false);
  const [items, setItems] = useState();
  const ID = items?.user?._id;
  const createdByName = items?.user?.name;
  useEffect(() => {
    const userRespons = JSON.parse(localStorage.getItem("userResponse"));
    if (userRespons) {
      setItems(userRespons);
    }
  }, []);
  const pricess = useSelector((state) => state.price.formPrices);
  // console.log(277,Expence);
  // const users = useSelector((state) => state.userData.user);
  const [user, setUser] = useState([]);
  const [userData, setuserData] = useState({});
  const [getAgent, setgetAgent] = useState([]);
  const [GetFormPrice, setGetFormPrice] = useState();
  const [distict, setDistict] = useState();
  const [IncomeFor3year, setIncomeFor3year] = useState(true);
  const [Formerror, setFormerror] = useState({});
  const [incomeSource, setIncomeSource] = useState([]);
  const [totalFirst, settotalFirst] = useState(0);
  const [totalSecond, settotalSecond] = useState(0);
  const [totalThird, setThird] = useState(0);
  const [FormFileerror, setFormFileerror] = useState({});
  const [form] = Form.useForm();
  const [Data, SetData] = useState({
    statusfname: "",
    formPrice:"",
    fullName_English: "",
    fullName_Marathi: "",
    fatherName_english: "",
    fatherName_marathi: "",
    incoCerYear: "",
    BirthDate: "",
    age: "",
    Gender: "",
    work_type: "",
    phoneNUm: "",
    email: "",
    PanNo: "",
    AdharNo: "",
    address: "",
    streetName: "",
    depart: "",
    Building: "",
    landmark: "",
    district: "",
    taluka: "",
    village: "",
    pincode: "",
    relationOfApplicant: "",
    statusOfApplicant: "",
    NameOfApplicant: "",
    ReasonOfApllication: "",
    ReasonOfincome_get: "",
    IncomeDOcsName: "",
  });
  const [docs, setDocs] = useState({
    reshaCard: "",
    adharCard: "",
    lightBill: "",
    photo: "",
    form16: "",
    consentform: "",
    selfDeclearation: "",
    FatherAdhar: "",
    childrenAdhar: "",
    applicantPhoto: "",
    benificiarryPhoto: "",
    otherFirst: "",
    otherSecond: "",
  });
  const [familyData, setfamilyData] = useState({
    relation: "",
    name: "",
    age: "",
    occupation: "",
    income: "",
  });
  const [family, setFamily] = useState([]);
  const [IncomeData, setIncomeData] = useState({
    accountHolder_Name: "",
    totalfarm: "",
    farmtype: "",
    farmingdetails: "",
    accountHolder_DIstrict: "",
    accountHolder_Taluka: "",
    accountHolder_village: "",
  });
  const [isSubmit, SetIsSubmit] = useState(false);
  const getData = () => {
    axios
      .get(`https://api.maharashtraseva.com/api/user/getAll`)
      .then((res) => {
        const response = res.data;
        console.log(54, response);
        setUser(response);
        const userdata = JSON.parse(localStorage.getItem("userResponse"));
        console.log(57, userdata.user._id);
        if (userdata) {
          const getFormPrice = response.find(
            (item) => item._id == userdata.user._id
          );
          const getagent = response.filter(
            (item) => item._id === getFormPrice.agent
          );
          setuserData(userdata);
          setgetAgent(getagent);
          setGetFormPrice(getFormPrice?.retaile_formPrice?.retailer_income);
          console.log(66, getFormPrice?.retaile_formPrice?.retailer_income);
          SetData({...Data,formPrice:getFormPrice?.retaile_formPrice?.retailer_income})
        }
        // console.log(37,response)
      })
      .catch((err) => console.log(40, err));
  };

  useEffect(() => {
    setTimeout(getData(), 1000);
  }, []);
  const FileValidate = (Val) => {
    let fileError = {};

    if (!Val.benificiarryPhoto) {
      fileError.benificiarryPhoto = "File is Required";
    } else {
      if (Val.benificiarryPhoto.size > 1024 * 1024) {
        fileError.benificiarryPhoto = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.applicantPhoto) {
      fileError.applicantPhoto = "File is Required";
    } else {
      if (Val.applicantPhoto.size > 1024 * 1024) {
        fileError.applicantPhoto = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.childrenAdhar) {
      fileError.childrenAdhar = "File is Required";
    } else {
      if (Val.childrenAdhar.size > 1024 * 1024) {
        fileError.childrenAdhar = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.FatherAdhar) {
      fileError.FatherAdhar = "File is Required";
    } else {
      if (Val.FatherAdhar.size > 1024 * 1024) {
        fileError.FatherAdhar = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.reshaCard) {
      fileError.reshaCard = "File is Required";
    } else {
      if (Val.reshaCard.size > 1024 * 1024) {
        fileError.reshaCard = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.lightBill) {
      fileError.lightBill = "File is Required";
    } else {
      if (Val.lightBill.size > 500 * 1024) {
        fileError.lightBill = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.form16) {
      fileError.form16 = "File is Required";
    } else {
      if (Val.form16.size > 500 * 1024) {
        fileError.form16 = " File size exceeds the limit of 500kb";
      }
    }
    // if (!Val.consentform) {
    //   fileError.consentform = "File is Required";
    // } else {
    //   if (Val.consentform.size > 500 * 1024) {
    //     fileError.consentform = " File size exceeds the limit of 500kb";
    //   }
    // }

    if (!Val.selfDeclearation) {
      fileError.selfDeclearation = "File is Required";
    } else {
      if (Val.selfDeclearation.size > 500 * 1024) {
        fileError.selfDeclearation = " File size exceeds the limit of 500kb";
      }
    }
    return fileError;
  };
  const validate = (values) => {
    const error = {};
    if (!values.statusfname) {
      error.statusfname = " कृपया अर्जदाराच्या संबोधनाची निवड करा.";
    }
    if (!values.fullName_English) {
      error.fullName_English =
        "कृपया अर्जदाराच्या पूर्ण नावाची (इंग्रजी) नोंद करा.";
    }

    if (!values.fullName_Marathi) {
      error.fullName_Marathi =
        "कृपया अर्जदाराच्या पूर्ण नावाची (मराठी) नोंद करा.";
    }
    if (!values.fatherName_english) {
      error.fatherName_english =
        "कृपया अर्जदाराच्या वडिलांच्या पूर्ण नावाची (इंग्रजी) नोंद करा.";
    }
    if (!values.fullName_Marathi) {
      error.fullName_Marathi =
        "कृपया अर्जदाराच्या वडिलांच्या पूर्ण नावाची (मराठी) नोंद करा.";
    }
    if (!values.incoCerYear) {
      error.incoCerYear = "कृपया मिळकत प्रमाणपत्र वर्ष निवडा.";
    }
    if (!values.age) {
      error.age = "कृपया अर्जदाराच्या वयाची नोंद करा.";
    }
    if (!values.Gender) {
      error.Gender = "कृपया अर्जदाराच्या लिंगाची निवड करा.";
    }
    if (!values.phoneNUm) {
      error.phoneNUm =
        "कृपया अर्जदाराच्या भ्रमणध्वनी क्रमांकाची नोंद करा. कृपया वैध भ्रमणध्वनी क्रमांकाची नोंद करा.";
    }
    if (!values.address) {
      error.address = "कृपया आपला पत्ता नोंद करा";
    }
    if (!values.district) {
      error.district = "कृपया आपला जिल्हा नोंद करा";
    }
    if (!values.taluka) {
      error.taluka = "कृपया आपला तालुका नोंद करा";
    }
    if (!values.pincode) {
      error.pincode = "कृपया आपला पिनकोड नोंद करा";
    }
    if (!values.village) {
      error.village = "कृपया आपला गाव नोंद करा";
    }
    if (!values.relationOfApplicant) {
      error.relationOfApplicant = "सदर व्यक्तीचे अर्जदाराशी नाते नोंद करा";
    }
    if (!values.NameOfApplicant) {
      error.NameOfApplicant = "लाभार्थीचे नाव नोंद करा";
    }
    if (!values.ReasonOfApllication) {
      error.ReasonOfApllication = "प्रमाणपत्र का आवश्यक आहे नोंद करा";
    }
    if (!values.ReasonOfincome_get) {
      error.ReasonOfincome_get =
        "उत्पन्नाचे प्रमाणपत्र कशासाठी हवे त्याचा तपशील नोंद करा";
    }
    console.log("error object", error);
    return error;
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userResponse"));
    if (userData) {
      axios.get(`https://api.maharashtraseva.com/api/recharge`).then((res) => {
        console.log(88, res.data);

        const allData = res.data.filter(
          (ele) => ele.user === userData.user._id
        );
        let amount = 0;
        let expence = 0;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.isExpence == "true") {
              expence += item.amount;
            } else {
              amount += item.amount;
            }
          });
        }
        console.log(53, amount, expence);
        setBalance(amount - expence);
      });
    }
    setIncomeSource(
      IncomeFor3year
        ? [
            {
              sourceName: "शेती",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "शेती पुरक व्यवसाय",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "दुग्ध व्यवसाय",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "शेत मजुरी",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "इतर मजुरी",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "इतर व्यवसाय/ उद्योग / धंदा/ व्यापार",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "शेतीशिवाय इतर स्थावर मिळकती पासूनच उत्पन्न",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName:
                "नोकरीतील वेतन/ सेवानिवृत वेतन / मानधन/अनुदान इत्यादी मासिक उत्पन्नापासूनचे वार्षिक उत्पन्न",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "गुंतवणुकीवरील व्याज/ लाभांश व तत्सम उत्पन्न",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName:
                "वर नमूद साधनांशिवाय इतर उत्पन्नाची साधने असल्यास त्या पासूनचे उत्पन्न",
              [new Date().getFullYear() - 2]: 0,
              [new Date().getFullYear() - 1]: 0,
              [new Date().getFullYear()]: 0,
              discription: "",
            },
          ]
        : [
            {
              sourceName: "शेती",
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "शेती पुरक व्यवसाय",

              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "दुग्ध व्यवसाय",

              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "शेत मजुरी",

              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "इतर मजुरी",

              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "इतर व्यवसाय/ उद्योग / धंदा/ व्यापार",

              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "शेतीशिवाय इतर स्थावर मिळकती पासूनच उत्पन्न",
              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName:
                "नोकरीतील वेतन/ सेवानिवृत वेतन / मानधन/अनुदान इत्यादी मासिक उत्पन्नापासूनचे वार्षिक उत्पन्न",

              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName: "गुंतवणुकीवरील व्याज/ लाभांश व तत्सम उत्पन्न",

              [new Date().getFullYear()]: 0,
              discription: "",
            },
            {
              sourceName:
                "वर नमूद साधनांशिवाय इतर उत्पन्नाची साधने असल्यास त्या पासूनचे उत्पन्न",

              [new Date().getFullYear()]: 0,
              discription: "",
            },
          ]
    );
  }, []);
  const handleIncomeChange = (e) => {
    const find = incomeSource.find((data) => data.sourceName === e.target.name);
    if (e.target.tabIndex === 0) {
      incomeSource[incomeSource.indexOf(find)]["discription"] = e.target.value;
    } else {
      incomeSource[incomeSource.indexOf(find)][e.target.tabIndex] =
        e.target.value;
    }
    if (IncomeFor3year) {
      let totalfirst = 0;
      let totalsec = 0;
      let totalthird = 0;
      const yearTwoYearsAgo = new Date().getFullYear();
      Object.entries(incomeSource).forEach(([key, value]) => {
        if (key === String(yearTwoYearsAgo)) {
          totalfirst = Number(value);
          console.log(384, String(yearTwoYearsAgo));
        }
        // if (key === String(yearTwoYearsAgo + 1)) {
        //   totalsec = Number(value);
        // }
        // if (key === String(yearTwoYearsAgo + 2)) {
        //   totalthird = Number(value);
        // }
      });
      console.log(392, totalfirst, totalsec, totalthird);
    }
  };
  const translateName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.fullName_English,
      })
      .then((res) => {
        setnameMarathi(res.data.output);
        // setNameLoading(false);
        SetData({ ...Data, fullName_Marathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };

  const translateFName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.fatherName_english,
      })
      .then((res) => {
        console.log(res.data.output);
        setfnameMarathi(res.data.output);
        SetData({ ...Data, fatherName_marathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    console.log(221, e.target.value);
    const { name, value } = e.target.value;
    SetData({ ...Data, [name]: value });
  };

  const [distibutionOfIncomefromAgri, setdistibutionOfIncomefromAgri] =
    useState([]);
  const handleDateChange = (Date, dateString) => {
    SetData({ ...Data, BirthDate: dateString });
  };
  const [talukas, setTalukas] = useState([]);
  const setDestrictName = (e) => {
    console.log("districtData:", districtData);

    districtData.filter((dist) => {
      if (dist.name.toLowerCase() == e.toString().toLowerCase()) {
        console.log(dist.tahasil);
        setTalukas(dist.tahasil);
      }
    });
  };

  const postLDJData = (e) => {
    e.preventDefault();
    setShowloading(true)
    const fileerror = FileValidate(docs);

    const error = validate(Data);
    if (Object.keys(error).length == 0 && Object.keys(fileerror).length == 0) {
      if (balance > GetFormPrice) {

        const obj = {
          application_type: "Income certificate",
          status: "IN-PROGRESS",
          createdBy: ID,
          createdByName,
          agentId: getAgent[0]._id,
          agentName: getAgent[0].name,
          Data,
          distibutionOfIncomefromAgri,
          incomeSource,
          family,
        };
        const mainDataPromise = new Promise((resolve, reject) => {
          axios
            .post(`https://api.maharashtraseva.com/api/income_Certificate/create`, obj)
            .then((res) => {
              const response = res;
              resolve({
                status: true,
                message: "data Posted Successfully",
                data: res.data,
              });
            })
            .catch((err) => {
              console.log(err);
              reject({
                status: false,
                message: "Data Not posted",
              });
    setShowloading(false)

            });
        });

        mainDataPromise
          .then((res) => {
            console.log(124, res.data);

            uploadreshaCard(res.data.data._id);
            // uploadadharCard(res.data.data._id);
            uploadlightBill(res.data.data._id);
            uploadlightBill(res.data.data._id);
            // uploadphoto(res.data.data._id);
            uploadform16(res.data.data._id);
            uploadconsentform(res.data.data._id);
            uploadselfDeclearation(res.data.data._id);
            uploadFatherAdhar(res.data.data._id);
            uploadchildrenAdhar(res.data.data._id);
            uploadapplicantPhoto(res.data.data._id);
            uploadbenificiarryPhoto(res.data.data._id);
            uploadotherFirst(res.data.data._id);
            uploadotherSecond(res.data.data._id);
            debitFormBalance();
            CreaditAgent();

            SetData({
              statusfname: "",
              fullName_English: "",
              fullName_Marathi: "",
              fatherName_english: "",
              fatherName_marathi: "",
              incoCerYear: "",
              BirthDate: "",
              age: "",
              Gender: "",
              work_type: "",
              phoneNUm: "",
              email: "",
              PanNo: "",
              AdharNo: "",
              address: "",
              streetName: "",
              depart: "",
              Building: "",
              landmark: "",
              district: "",
              taluka: "",
              village: "",
              pincode: "",
              relationOfApplicant: "",
              statusOfApplicant: "",
              NameOfApplicant: "",
              ReasonOfApllication: "",
              ReasonOfincome_get: "",
              IncomeDOcsName: "",
            });
            setDocs({
              reshaCard: "",
              adharCard: "",
              lightBill: "",
              photo: "",
              form16: "",
              consentform: "",
              selfDeclearation: "",
              FatherAdhar: "",
              childrenAdhar: "",
              applicantPhoto: "",
              benificiarryPhoto: "",
              otherFirst: "",
              otherSecond: "",
            });
            setfamilyData({
              relation: "",
              name: "",
              age: "",
              occupation: "",
              income: "",
            });
            setIncomeData({
              accountHolder_Name: "",
              totalfarm: "",
              farmtype: "",
              farmingdetails: "",
              accountHolder_DIstrict: "",
              accountHolder_Taluka: "",
              accountHolder_village: "",
            });
            setdistibutionOfIncomefromAgri([]);
            setIncomeSource([]);
            setFamily([]);
          setShowloading(false)

          }).then(() => navigate("/RetailerDashboard"))
          .catch((err) => {
            console.log(err);
    setShowloading(false)
    
  });
} else {
  toast.error("Please Recharge");
  setShowloading(false)
}
} else {
  setFormerror(error);
  setFormFileerror(fileerror);
  swal("कृपया आवश्यक क्षेत्र भरा.");
  setShowloading(false)
    }
  };
  const CreaditAgent = () => {
    const obj = {
      agentId: getAgent[0]._id,
      agentName: getAgent[0].name,
      creaditAmount: getAgent[0].agent_formPrice.agent_income,
      isWithdrowl: false,
      isrequest: false,
      creaditFor: "Income Certificate",
      creaditBy: userData.user._id,
    };
    axios
      .post(`https://api.maharashtraseva.com/api/wallet/create`, obj)
      .then((res) => {
        const response = res;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const debitFormBalance = () => {
    const obj = {
      user: userData?.user._id,
      mode: "offline",
      amount: GetFormPrice,
      isExpence: true,
      expenceFor: "income Certificate",
    };
    axios
      .post("https://api.maharashtraseva.com/api/recharge/create", obj)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());
        swal("Good job!", "form submitted successfully!", "success");
        // navigate("/RetailerForm4")
      })
      .catch((err) => console.log(34, err));
  };
  const uploadreshaCard = (id) => {
    const formData = new FormData();
    formData.append("reshaCard", docs.reshaCard);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_reshancard/${id}`,
        formData
      )
      .then((res) => console.log("reshaCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadadharCard = (id) => {
    const formData = new FormData();
    formData.append("adharCard", docs.adharCard);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_adharCard/${id}`,
        formData
      )
      .then((res) => console.log("adharCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadlightBill = (id) => {
    const formData = new FormData();
    formData.append("lightBill", docs.lightBill);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_lightBill/${id}`,
        formData
      )
      .then((res) => console.log("lightBill", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadphoto = (id) => {
    const formData = new FormData();
    formData.append("photo", docs.photo);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_photo/${id}`,
        formData
      )
      .then((res) => console.log("photo", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadform16 = (id) => {
    const formData = new FormData();
    formData.append("form16", docs.form16);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_form16/${id}`,
        formData
      )
      .then((res) => console.log("form16", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadconsentform = (id) => {
    const formData = new FormData();
    formData.append("consentform", docs.consentform);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_consentform/${id}`,
        formData
      )
      .then((res) => console.log("consentform", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadselfDeclearation = (id) => {
    const formData = new FormData();
    formData.append("selfDeclearation", docs.selfDeclearation);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_selfDeclearation/${id}`,
        formData
      )
      .then((res) => console.log("selfDeclearation", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadFatherAdhar = (id) => {
    const formData = new FormData();
    formData.append("FatherAdhar", docs.FatherAdhar);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_FatherAdhar/${id}`,
        formData
      )
      .then((res) => console.log("FatherAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadchildrenAdhar = (id) => {
    const formData = new FormData();
    formData.append("childrenAdhar", docs.childrenAdhar);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_childrenAdhar/${id}`,
        formData
      )
      .then((res) => console.log("childrenAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadapplicantPhoto = (id) => {
    const formData = new FormData();
    formData.append("applicantPhoto", docs.applicantPhoto);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_applicantPhoto/${id}`,
        formData
      )
      .then((res) => console.log("applicantPhoto", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadbenificiarryPhoto = (id) => {
    const formData = new FormData();
    formData.append("benificiarryPhoto", docs.benificiarryPhoto);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_benificiarryPhoto/${id}`,
        formData
      )
      .then((res) => console.log("benificiarryPhoto", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadotherFirst = (id) => {
    const formData = new FormData();
    formData.append("otherFirst", docs.otherFirst);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_otherFirst/${id}`,
        formData
      )
      .then((res) => console.log("otherSecond", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadotherSecond = (id) => {
    const formData = new FormData();
    formData.append("otherSecond", docs.otherSecond);
    axios
      .put(
        `https://api.maharashtraseva.com/api/incomeCertificate_otherSecond/${id}`,
        formData
      )
      .then((res) => console.log("otherSecond", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id != undefined) {
      axios
        .get(`https://api.maharashtraseva.com/api/income_Certificate/${id}`)
        .then((res) => {
          const data = res.data[0];
          SetData(data.Data);
          setdistibutionOfIncomefromAgri(data.distibutionOfIncomefromAgri);
          setIncomeData(data.incomeSource);
          setFamily(family);
          if (
            data?.isNew == true &&
            data?.status == "REJECTED" &&
            userData.user.role === "retailer"
          ) {
            axios
              .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, {
                isNew: false,
              })
              .then((res) => {
                dispatch(incNumber());
              })
              .catch((err) => console.log(err));
          }
        });
    }
  }, [id]);

  const editHandler = (e) => {
    const obj = {
      application_type: "Income certificate",
      status: "IN-PROGRESS",
      createdBy: ID,
      createdByName,
      Data,
      distibutionOfIncomefromAgri,
      incomeSource,
      family,
    };

    if (agree) {
      const mainDataPromise = new Promise((resolve, reject) => {
        axios
          .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, obj)
          .then((res) => {
            const response = res;
            resolve({
              status: true,
              message: "data Posted Successfully",
              data: res.data,
            });
          })
          .catch((err) => {
            console.log(err);
            reject({
              status: false,
              message: "Data Not posted",
            });
          });
      });
      mainDataPromise
        .then((res) => {
          console.log(124, res.data);
        uploadreshaCard(res.data.data._id);
            // uploadadharCard(res.data.data._id);
            uploadlightBill(res.data.data._id);
            uploadlightBill(res.data.data._id);
            // uploadphoto(res.data.data._id);
            uploadform16(res.data.data._id);
            uploadconsentform(res.data.data._id);
            uploadselfDeclearation(res.data.data._id);
            uploadFatherAdhar(res.data.data._id);
            uploadchildrenAdhar(res.data.data._id);
            uploadapplicantPhoto(res.data.data._id);
            uploadbenificiarryPhoto(res.data.data._id);
            uploadotherFirst(res.data.data._id);
            uploadotherSecond(res.data.data._id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const clickHandler = () => {
    // setNameLoading(true);
    translateName();
  };
  const clickHandler1 = () => {
    translateFName();
  };

  const setTalukasData = (e) => {
    console.log(340, e);
    if (e) {
      const taluka = districtData.filter((item) => item.name === e);
      console.log(597, taluka[0].tahasil);
      setTalukas(taluka[0].tahasil);
    } else {
      console.log("Flop");
    }
  };
  const [word, setWord] = useState("0");
  const [totalincome, settotalincome] = useState();

  const HandalBlur = (number) => {
    numberToMarathiWord(number);
  };

  const marathiNumbers = [
    "शून्य",
    "एक",
    "दोन",
    "तीन",
    "चार",
    "पाच",
    "सहा",
    "सात",
    "आठ",
    "नऊ",
    // Add more numbers as needed
  ];

  const marathiNumberTens = [
    "",
    "दहा",
    "वीस",
    "तीस",
    "चाळीस",
    "पंधरावीस",
    "सोऱ्यात्रीस",
    "सत्त्यात्रीस",
    "ऐक्यात्रीस",
    "एकोणत्रीस",
    // Add more tens as needed
  ];

  const numberToMarathiWord = (number) => {
    if (number < 0 || number > 10000000) {
      return "Invalid Number";
    }

    if (number === 0) {
      return marathiNumbers[0];
    }

    let result = "";

    // Convert crores place
    const crores = Math.floor(number / 10000000);
    if (crores > 0) {
      result += marathiNumbers[crores] + " कोटी ";
    }

    // Convert rest of the number
    const remainingNumber = number % 10000000;
    const lakh = Math.floor(remainingNumber / 100000);
    const thousands = Math.floor((remainingNumber % 100000) / 1000);
    const hundreds = Math.floor((remainingNumber % 1000) / 100);
    const tensAndOnes = remainingNumber % 100;

    if (lakh > 0) {
      result += marathiNumbers[lakh] + " लाख ";
    }

    if (thousands > 0) {
      result += marathiNumbers[thousands] + " हजार ";
    }

    if (hundreds > 0) {
      result += marathiNumbers[hundreds] + " शे ";
    }

    if (tensAndOnes > 0) {
      if (tensAndOnes <= 10) {
        result += marathiNumbers[tensAndOnes];
      } else if (tensAndOnes <= 19) {
        result += marathiNumberTens[tensAndOnes - 10];
      } else {
        const tens = Math.floor(tensAndOnes / 10);
        const ones = tensAndOnes % 10;
        result += marathiNumberTens[tens] + " " + marathiNumbers[ones];
      }
    }

    return result;
  };
  // console.log(592,districtData)
  const number = word; // Make sure to pass the number as a string
  const marathiWord = numberToMarathiWord(number);
  return (
    <Fragment>
      <Breadcrumb title={"Income Certificate"} parent={"license"} />

      {/* <h2>{marathiWord}</h2> */}
      <Container fluid={true}>
        <h3>Form Price :{GetFormPrice}</h3>
        <Row>
          <Col sm="">
            <Card>
              <CardBody>
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div className="row">
                    <div class="col-md-12">
                      <div class="form-bg" role="form">
                        <div class="box-body">
                          <div class="row">
                            <div
                              className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                              style={{ backgroundColor: "#f9f9f9" }}
                            >
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    अर्जदाराचा तपशील{" "}
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-12">
                                <div class="row mt-3  ">
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label
                                        for="CPH_ddlSalutation"
                                        id="CPH_Label5"
                                      >
                                        संबोधन
                                      </label>
                                      <span className="red">*</span>

                                      <select
                                        name="statusfname"
                                        id="CPH_ddlSalutation"
                                        ////tabindex="5"
                                        class="form-control"
                                        value={Data.statusfname}
                                        onChange={(e) =>
                                          SetData({
                                            ...Data,
                                            statusfname: e.target.value,
                                          })
                                        }
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="---निवडा---">
                                          ---कृपया अर्जदाराच्या संबोधनाची निवड
                                          करा.---
                                        </option>
                                        <option value="कुमार">कुमार</option>
                                        <option value="कुमारी">कुमारी</option>
                                        <option value="वकील">वकील</option>
                                        <option value="श्री.">श्री.</option>
                                        <option value="श्रीमती">श्रीमती</option>
                                        <option value="सौ">सौ</option>
                                      </select>
                                      <p className="red">
                                        {Formerror.statusfname}
                                      </p>
                                      {/* <span
                                      id="CPH_RequiredFieldValidator11"
                                      class="fs-6"
                                      style={{ fontSize: "20px" }}
                                    >
                                      कृपया अर्जदाराच्या संबोधनाची निवड करा.
                                    </span> */}
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label
                                        for="CPH_txtappfullname"
                                        id="CPH_lblappfullname"
                                      >
                                        पूर्ण नाव(इंग्रजी)
                                      </label>
                                      <span className="red">*</span>

                                      <input
                                        name="fullName_English"
                                        placeholder="कृपया अर्जदाराच्या पूर्ण नावाची (इंग्रजी)
                                      नोंद करा."
                                        type="text"
                                        maxlength="90"
                                        id="CPH_txtappfullname"
                                        ////tabindex="6"
                                        class="form-control"
                                        value={Data.fullName_English}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            fullName_English: e.target.value,
                                          });
                                          // setNameLoading(true);
                                        }}
                                        onBlur={clickHandler}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.fullName_English}
                                      </p>
                                      {/* <span
                                      id="CPH_RequiredFieldValidator1"
                                      class="fs-6"
                                      style={{ fontSize: "20px" }}
                                    >
                                      कृपया अर्जदाराच्या पूर्ण नावाची (इंग्रजी)
                                      नोंद करा.
                                    </span> */}
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label
                                        for="CPH_txtfullname_ll"
                                        id="CPH_lblappfullnamell"
                                      >
                                        पूर्ण नाव (मराठी)
                                      </label>
                                      <span className="red">*</span>

                                      <input
                                        name="fullName_Marathi"
                                        placeholder="कृपया अर्जदाराच्या पूर्ण नावाची (मराठी)
                                      नोंद करा."
                                        type="text"
                                        maxlength="90"
                                        id="CPH_txtfullname_ll"
                                        ////tabindex="7"
                                        class="form-control"
                                        value={Data.fullName_Marathi}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            fullName_Marathi: e.target.value,
                                          });
                                        }}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.fullName_Marathi}
                                      </p>
                                      {/* <span
                                      id="CPH_RequiredFieldValidator2"
                                      class="fs-6"
                                      style={{ fontSize: "20px" }}
                                    >
                                      कृपया अर्जदाराच्या पूर्ण नावाची (मराठी)
                                      नोंद करा.
                                    </span> */}
                                    </div>
                                  </div>
                                </div>

                                <div class="row border-bottom border-1  ">
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label
                                        for="CPH_ddlfathersal"
                                        id="CPH_C04_lblAppSal0"
                                      >
                                        संबोधन
                                      </label>
                                      <span class="red">*</span>
                                      <select
                                        name="statusfname"
                                        id="CPH_ddlfathersal"
                                        ////tabindex="8"
                                        class="form-control"
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="---निवडा---">
                                          ---कृपया अर्जदाराच्या वडिलांच्या
                                          संबोधनाची निवड करा.---
                                        </option>
                                        <option value="श्री.">श्री.</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label
                                        for="CPH_txtfathername_en"
                                        id="CPH_CO7_lblmar1"
                                      >
                                        वडिलांचे नाव(इंग्रजी)
                                      </label>
                                      <span className="red">*</span>

                                      <input
                                        name="fatherName_english"
                                        placeholder="कृपया अर्जदाराच्या वडिलांच्या पूर्ण नावाची
                                      (इंग्रजी) नोंद करा."
                                        type="text"
                                        maxlength="90"
                                        id="CPH_txtfathername_en"
                                        ////tabindex="9"
                                        class="form-control"
                                        value={Data.fatherName_english}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            fatherName_english: e.target.value,
                                          });
                                          // setNameLoading(true);
                                        }}
                                        onBlur={clickHandler1}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.fatherName_english}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label
                                        for="CPH_txtfathername_mr"
                                        id="CPH_CO7_lblmar0"
                                      >
                                        वडिलांचे नाव(मराठी)
                                      </label>
                                      <span className="red">*</span>

                                      <input
                                        name="fatherName_marathi"
                                        placeholder="कृपया अर्जदाराच्या वडिलांच्या पूर्ण नावाची
                                      (मराठी) नोंद करा."
                                        type="text"
                                        maxlength="90"
                                        id="CPH_txtfathername_mr"
                                        ////tabindex="10"
                                        class="form-control"
                                        value={Data.fatherName_marathi}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            fatherName_marathi: e.target.value,
                                          });
                                        }}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.fullName_Marathi}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="separator"></div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label
                                      for="CPH_DropDownList1"
                                      id="CPH_Label7"
                                    >
                                      {" "}
                                      मिळकत प्रमाणपत्र
                                    </label>
                                    <span className="red">*</span>
                                    <select
                                      name="incoCerYear"
                                      id="CPH_DropDownList1"
                                      ////tabindex="11"
                                      class="form-control"
                                      value={Data.incoCerYear}
                                      onChange={(e) => {
                                        SetData({
                                          ...Data,
                                          incoCerYear: e.target.value,
                                        });
                                        if (e.target.value === "१ वर्षासाठी") {
                                          setIncomeFor3year(false);
                                        } else {
                                          setIncomeFor3year(true);
                                        }
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    >
                                      <option value="0" selected="selected">
                                        ---निवडा---
                                      </option>
                                      <option value="१ वर्षासाठी">
                                        १ वर्षासाठी
                                      </option>
                                      <option value="३ वर्षांसाठी">
                                        ३ वर्षांसाठी
                                      </option>
                                    </select>
                                    <p className="red">
                                      {Formerror.incoCerYear}
                                    </p>{" "}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label
                                      for="CPH_txtappDOB"
                                      id="CPH_lblappdob"
                                    >
                                      जन्मतारीख
                                    </label>
                                    <br />
                                    <DatePicker
                                      name="BirthDate"
                                      placeholder="कृपया अर्जदाराची जन्मतारीख नोंद करा."
                                      type="text"
                                      maxlength="10"
                                      class="form-control"
                                      onChange={handleDateChange}
                                      format="DD-MM-YYYY"
                                      style={{ width: "370px" }}
                                    />
                                    <p className="red">{Formerror.BirthDate}</p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <div class="form-group">
                                      <label
                                        for="CPH_txtappage"
                                        id="CPH_lblappage"
                                      >
                                        वय
                                      </label>
                                      <span className="red">*</span>

                                      <input
                                        name="age"
                                        placeholder="कृपया अर्जदाराच्या वयाची नोंद करा!"
                                        type="text"
                                        maxlength="3"
                                        id="CPH_txtappage"
                                        ////tabindex="13"
                                        value={Data.age}
                                        class="form-control"
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            age: e.target.value,
                                          });
                                          // setNameLoading(true);
                                        }}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">{Formerror.age}</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="CPH_Dropgen3">लिंग</label>
                                    <span className="red">*</span>

                                    <select
                                      name="Gender"
                                      class="form-control"
                                      onChange={(e) => {
                                        SetData({
                                          ...Data,
                                          Gender: e.target.value,
                                        });
                                        //  console.log(966,e.target.value);
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    >
                                      <option value="">
                                        ---कृपया अर्जदाराच्या लिंगाची निवड
                                        करा.---
                                      </option>
                                      <option value="इतर">इतर </option>
                                      <option value="पुरुष">पुरुष</option>
                                      <option value="स्त्री">स्त्री</option>
                                    </select>
                                    <p className="red">{Formerror.Gender}</p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="CPH_Occupation">व्यवसाय</label>
                                    <span className="red">*</span>
                                    <select
                                      name="work_type"
                                      class="form-control"
                                      value={Data.work_type}
                                      onChange={(e) =>
                                        SetData({
                                          ...Data,
                                          work_type: e.target.value,
                                        })
                                      }
                                      onkeypress="return IsAlphabet(event);"
                                    >
                                      {occupationData.map((item, i) => (
                                        <option value={item} key={i}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                    <p className="red">{Formerror.work_type}</p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label
                                      for="CPH_txtappMobile"
                                      id="CPH_lblappmobile"
                                    >
                                      भ्रमणध्वनी क्रमांक{" "}
                                    </label>
                                    <span className="red">*</span>

                                    <input
                                      name="phoneNUm"
                                      placeholder="कृपया वैध भ्रमणध्वनी क्रमांकाची नोंद करा."
                                      type="number"
                                      maxlength="10"
                                      value={Data.phoneNUm}
                                      id="CPH_txtappMobile"
                                      // ////tabindex="14"
                                      class="form-control"
                                      onChange={(e) => {
                                        // SetData({
                                        //   ...Data,
                                        //   phoneNUm: e.target.value,
                                        // });

                                        const value = e.target.value;
                                        // Remove non-numeric characters
                                        const sanitizedValue = value.replace(/\D/g,"");

                                        // Check if the input is a valid phone number (exactly 10 digits)
                                        if (sanitizedValue.length <= 10) {
                                          SetData({
                                            ...Data,
                                            phoneNUm: sanitizedValue,
                                          });
                                        } else {
                                          alert(
                                            "Please Enter Valid Phone Number"
                                          );
                                          SetData({
                                            ...Data,
                                            phoneNUm: "", // Reset the adharNumber if it's too long
                                          });
                                        }
                                        // setNameLoading(true);
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    />
                                    <p className="red">{Formerror.phoneNUm}</p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label
                                      for="CPH_txtemail"
                                      id="CPH_lblappemail"
                                    >
                                      ई-मेल
                                    </label>
                                    <input
                                      name="email"
                                      placeholder="कृपया ईमेल आयडी भरा!"
                                      type="text"
                                      maxlength="40"
                                      id="CPH_txtemail"
                                      // ////tabindex="21"
                                      class="form-control"
                                      onChange={(e) => {
                                        SetData({
                                          ...Data,
                                          email: e.target.value,
                                        });
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <span id="CPH_CO7_lbl_pancard">
                                      पॅनकार्ड क्रमांक
                                    </span>
                                    <input
                                      name="PanNo"
                                      placeholder="(AAAAA1111A)"
                                      type="text"
                                      maxlength="10"
                                      id="CPH_txt_pancard"
                                      ////tabindex="27"
                                      class="form-control"
                                      onChange={(e) => {
                                        SetData({
                                          ...Data,
                                          PanNo: e.target.value,
                                        });
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label
                                      for="CPH_txtUID"
                                      id="CPH_C11_lbl_uid0"
                                    >
                                      आधारकार्ड क्रमांक
                                    </label>
                                    <input
                                      placeholder="कृपया आधार कार्ड क्रमांक भरा !"
                                      name="AdharNo"
                                      type="number"
                                      maxlength="12"
                                      value={Data.AdharNo}
                                      id="CPH_txtUID"
                                      ////tabindex="28"
                                      class="form-control"
                                      onChange={(e) => {
                                        // SetData({
                                        //   ...Data,
                                        //   AdharNo: e.target.value,
                                        // });
                                        const value = e.target.value;
                                        // Remove non-numeric characters
                                        const sanitizedValue = value.replace(/\D/g,"");

                                        // Check if the input is a valid phone number (exactly 10 digits)
                                        if (sanitizedValue.length <= 12) {
                                          SetData({
                                            ...Data,
                                            AdharNo: sanitizedValue,
                                          });
                                        } else {
                                          alert(
                                            "Please Enter Valid Adhar Card No"
                                          );
                                          SetData({
                                            ...Data,
                                            AdharNo: "", // Reset the adharNumber if it's too long
                                          });
                                        }
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      className="border mt-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded"
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div class="box-body">
                            <div class="row">
                              <div
                                className="col-lg-12 text-center border rounded mb-4"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    अर्जदाराच्या निवासाचे तपशील{" "}
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_AddrCare"
                                    id="CPH_lblappaddresscareof"
                                  >
                                    पत्ता
                                  </label>
                                  <span className="red">*</span>

                                  <input
                                    name="address"
                                    placeholder="कृपया आपला पत्ता नोंद करा !"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_AddrCare"
                                    ////tabindex="16"
                                    class="form-control"
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        address: e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                  <p className="red">{Formerror.address}</p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_AddrStreet"
                                    id="CPH_lbladdressstreet"
                                  >
                                    मार्ग{" "}
                                  </label>
                                  <input
                                    name="streetName"
                                    placeholder="कृपया आपला मार्ग नोंद करा !"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_AddrStreet"
                                    ////tabindex="17"
                                    class="form-control"
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        streetName: e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_AddrLocality"
                                    id="CPH_lbladdresslocal"
                                  >
                                    विभाग
                                  </label>
                                  <input
                                    name="depart"
                                    placeholder="कृपया आपला विभाग नोंद करा !"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_AddrLocality"
                                    ////tabindex="18"
                                    class="form-control"
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        depart: e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_Building"
                                    id="CPH_lbladdressbuild"
                                  >
                                    इमारत
                                  </label>
                                  <input
                                    name="Building"
                                    placeholder="कृपया आपला इमारत नोंद करा !"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_Building"
                                    ////tabindex="19"
                                    class="form-control"
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Building: e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_Landmark"
                                    id="CPH_lblapplandmark"
                                  >
                                    लॅंडमार्क
                                  </label>
                                  <input
                                    name="landmark"
                                    placeholder="कृपया आपला  लॅंडमार्क नोंद करा !"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_Landmark"
                                    ////tabindex="20"
                                    class="form-control"
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        landmark: e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3">
                                  {" "}
                                  <b>जिल्हा</b>
                                </label>
                                <span className="red">*</span>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your District!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="--Select District--"
                                    value={Data.district}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        district: e,
                                      });
                                      setDistict(e);
                                      setTalukasData(e);
                                      // console.log(1305,e)
                                    }}
                                    showSearch
                                  >
                                    <Select.Option value="" key="default">
                                      --जिल्हा निवडा --
                                    </Select.Option>
                                    {districtData.map((item, i) => {
                                      return (
                                        <Select.Option
                                          value={item.name}
                                          key={i}
                                        >
                                          {item.name}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                  <p className="red">{Formerror.district}</p>
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3">
                                  {" "}
                                  <b>तालुका</b>
                                </label>
                                <span className="red">*</span>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your District!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="--Select District--"
                                    value={Data.taluka}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        taluka: e,
                                      });
                                    }}
                                    showSearch
                                  >
                                    <Select.Option value="" key="default">
                                      --तालुका निवडा --
                                    </Select.Option>
                                    {talukas.map((item, i) => {
                                      return (
                                        <Select.Option value={item} key={i}>
                                          {item}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                  <p className="red">{Formerror.taluka}</p>
                                </Form.Item>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_Village"
                                    id="CPH_lblappvillage"
                                  >
                                    गाव
                                  </label>
                                  <span className="red">*</span>

                                  <input
                                    name="village"
                                    placeholder="कृपया आपला गाव नोंद करा !"
                                    id="CPH_Village"
                                    ////tabindex="24"
                                    class="form-control"
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        village: e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                  <p className="red">{Formerror.village}</p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_Pincode"
                                    id="CPH_lblapppincode"
                                  >
                                    पिनकोड
                                  </label>
                                  <span className="red">*</span>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input your पिनकोड!",
                                      },
                                    ]}
                                  >
                                    <input
                                      name="pincode"
                                      placeholder="पिन कोड ४ या अंकानेच सुरू व्हायला हवा"
                                      type="text"
                                      maxlength="6"
                                      id="CPH_Pincode"
                                      ////tabindex="25"
                                      class="form-control"
                                      onChange={(e) => {
                                        SetData({
                                          ...Data,
                                          pincode: e.target.value,
                                        });
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    />
                                    <p className="red">{Formerror.pincode}</p>
                                  </Form.Item>
                                  {/* <span
                                  id="CPH_RangeValidator2"
                                  class="fs-6"
                                  style={{ fontSize: "20px" }}
                                >
                                  पिन कोड ४ या अंकानेच सुरू व्हायला हवा
                                </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      className="border mt-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded"
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          {/* <div class="box-header box-header-bg">
                          <h3 class="box-title">
                            <span id="family">
                              कुटुंबातील सज्ञान व्यक्तींची माहीती :
                            </span>
                          </h3>
                        </div> */}
                          <div class="box-body">
                            <div class="row">
                              <div
                                className="col-lg-12 text-center border rounded mb-4"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    कुटुंबातील सज्ञान व्यक्तींची माहीती :{" "}
                                  </span>
                                </h3>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="CPH_ddlrelation">नाते</label>
                                  <select
                                    name="relation"
                                    type="text"
                                    maxlength="50"
                                    // ////tabindex="29"
                                    class="form-control"
                                    value={familyData.relation}
                                    onChange={(e) => {
                                      setfamilyData({
                                        ...familyData,
                                        relation: e.target.value,
                                      });
                                      console.log(e.target.value);
                                      if (e.target.value == "स्वतः") {
                                        console.log(familyData.fullName_English);
                                        setfamilyData({
                                          ...familyData,
                                          name: Data.fullName_English,
                                          age: Data.age,
                                          relation:e.target.value
                                        });
                                        console.log(1975, familyData);
                                      } else {
                                        setfamilyData({
                                          ...familyData,
                                          name: "",
                                          age: "",
                                          relation:e.target.value
                                        });
                                        console.log(1975, familyData);
                                      }
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    {relation.map((itm, index) => (
                                      <option value={itm} key={index}>
                                        {itm}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>कुटुंबातील व्यक्तीचे नाव</label>
                                  <input
                                    name="Name"
                                    type="text"
                                    maxlength="50"
                                    class="form-control"
                                    value={familyData.name}
                                    onChange={(e) =>
                                      setfamilyData({
                                        ...familyData,
                                        name: e.target.value,
                                      })
                                    }
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>वय</label>
                                  <input
                                    name="AgeOfRelative"
                                    type="number"
                                    maxlength="2"
                                    class="form-control"
                                    value={familyData.age}
                                    onChange={(e) =>
                                      setfamilyData({
                                        ...familyData,
                                        age: e.target.value,
                                      })
                                    }
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_ddlfamilyoccupation"
                                    id="CPH_lblfamilyoccupation"
                                  >
                                    व्यवसाय/नोकरी
                                  </label>
                                  <select
                                    name="WorkTypeOfRelative"
                                    type="text"
                                    maxlength="2"
                                    class="form-control"
                                    value={familyData.occupation}
                                    onChange={(e) =>
                                      setfamilyData({
                                        ...familyData,
                                        occupation: e.target.value,
                                      })
                                    }
                                  >
                                    {occupationData.map((itm, index) => (
                                      <option value={itm} key={index}>
                                        {itm}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>उत्पन्न</label>
                                  <input
                                    name="incomeOfRelative"
                                    type="number"
                                    maxlength="16"
                                    class="form-control"
                                    value={familyData.income}
                                    onChange={(e) =>
                                      setfamilyData({
                                        ...familyData,
                                        income: e.target.value,
                                      })
                                    }
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <Button
                            className="btn btn-success"
                            onClick={(e) => {
                              e.preventDefault();
                              const found = family.find(
                                (itm) => itm.name === familyData.name
                              );
                              if (!found) {
                                setFamily([
                                  ...family,
                                  {
                                    name: familyData.name,
                                    relation: familyData.relation,
                                    income: parseInt(familyData.income),
                                    occupation: familyData.occupation,
                                    age: parseInt(familyData.age),
                                  },
                                ]);
                              }
                            }}
                          >
                            जोडा
                          </Button>
                        </div>
                      </div>
                      {family.map((itm, i) => (
                        <>
                          <div class="col-md-12 table-responsive">
                            <table
                              class="table table-striped"
                              id="tdRelationList"
                            >
                              <tbody>
                                <tr>
                                  <th align="center">
                                    अ.क्र
                                    <td>{i + 1}</td>
                                  </th>

                                  <th align="center">
                                    कुटुंबातील व्यक्तीचे नाव/ व्यक्तींची नावे
                                    <td>
                                      <h5>{itm.name}</h5>
                                    </td>
                                  </th>
                                  <th align="center">
                                    नाते
                                    <td>
                                      <h5>{itm.relation}</h5>
                                    </td>
                                  </th>
                                  <th align="center">
                                    उत्पन्न
                                    <td>
                                      <h5>{itm.income}</h5>
                                    </td>
                                  </th>
                                  <th align="center">
                                    व्यवसाय/नोकरी
                                    <td>
                                      <h5>{itm.occupation}</h5>
                                    </td>
                                  </th>
                                  <th align="center">
                                    वय
                                    <td>
                                      <h5>{itm.age}</h5>
                                    </td>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div class="row">
                    <div
                      className="border mt-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded"
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          {/* <div class="box-header box-header-bg">
                          <h3 class="box-title">
                            <span id="CPH_label10">लाभार्थीचा तपशील:</span>
                          </h3>
                        </div> */}
                          <div class="box-body">
                            <div class="row">
                              <div
                                className="col-lg-12 text-center border rounded mb-4"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    लाभार्थीचा तपशील{" "}
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_ddlbenfRelation"
                                    id="CPH_Label11"
                                  >
                                    सदर व्यक्तीचे अर्जदाराशी नाते
                                  </label>
                                  <span className="red">*</span>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <select
                                      name="relationOfApplicant"
                                      type="text"
                                      maxlength="50"
                                      class="form-control"
                                      value={Data.relationOfApplicant}
                                      onChange={(e) => {
                                        SetData({
                                          ...Data,
                                          relationOfApplicant: e.target.value,
                                        });
                                        console.log(2167, e.target.value);
                                        if (e.target.value == "स्वतः") {
                                         
                                          SetData({
                                            ...Data,
                                            statusOfApplicant: Data.statusfname,
                                            NameOfApplicant:Data.fullName_English,
                                            relationOfApplicant: e.target.value
                                          });
                                          console.log(Data);
                                        }else{
                                          SetData({
                                            ...Data,
                                            statusOfApplicant: "",
                                            NameOfApplicant:"",
                                            relationOfApplicant: e.target.value
                                          });
                                          console.log(Data);
                                        }
                                       
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    >
                                      {relation.map((itm, index) => (
                                        <option value={itm} key={index}>
                                          {itm}
                                        </option>
                                      ))}
                                    </select>
                                    <p className="red">
                                      {Formerror.relationOfApplicant}
                                    </p>
                                  </Form.Item>
                                </div>
                              </div>

                              <div class="col-md-8">
                                <div class="row">
                                  <div class="form-group col-md-4">
                                    <label
                                      for="CPH_ddlSalutatn"
                                      id="CPH_Label71"
                                    >
                                      संबोधन
                                    </label>
                                    <span className="red">*</span>
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <select
                                        name="statusOfApplicant"
                                        id="CPH_ddlSalutatn"
                                        ////tabindex="35"
                                        class="form-control"
                                        value={Data.statusOfApplicant}
                                        onChange={(e) =>
                                          SetData({
                                            ...Data,
                                            statusOfApplicant: e.target.value,
                                          })
                                        }
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="---निवडा---">
                                          ---निवडा---
                                        </option>
                                        <option value="कुमार">कुमार</option>
                                        <option value="कुमारी">कुमारी</option>
                                        <option value="वकील">वकील</option>
                                        <option value="श्री.">श्री.</option>
                                        <option value="श्रीमती">श्रीमती</option>
                                        <option value="सौ">सौ</option>
                                      </select>
                                      <p className="red">
                                        {Formerror.statusOfApplicant}
                                      </p>
                                    </Form.Item>
                                  </div>
                                  <div class="form-group col-md-4">
                                    <label
                                      for="CPH_txtfullname_ll"
                                      id="CPH_lblappfullnamell"
                                    >
                                      लाभार्थीचे नाव
                                    </label>
                                    <span className="red">*</span>
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <input
                                        name="fullName_Marathi"
                                        type="text"
                                        maxlength="90"
                                        id="CPH_txtfullname_ll"
                                        ////tabindex="7"
                                        class="form-control"
                                        value={Data.NameOfApplicant}
                                        onChange={(e) =>
                                          SetData({
                                            ...Data,
                                            NameOfApplicant: e.target.value,
                                          })
                                        }
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.NameOfApplicant}
                                      </p>
                                    </Form.Item>
                                  </div>

                                  <div class="form-group col-md-4">
                                    <label>प्रमाणपत्र का आवश्यक आहे</label>
                                    <span className="red">*</span>
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <select
                                        name="ReasonOfApllication"
                                        class="form-control"
                                        value={Data.ReasonOfApllication}
                                        onChange={(e) =>
                                          SetData({
                                            ...Data,
                                            ReasonOfApllication: e.target.value,
                                          })
                                        }
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="---निवडा---">
                                          ---निवडा---
                                        </option>
                                        <option value="शिक्षण">शिक्षण</option>
                                        <option value="वैद्यकीय">
                                          वैद्यकीय
                                        </option>
                                        <option value="इतर">इतर</option>
                                      </select>
                                      <p className="red">
                                        {Formerror.ReasonOfApllication}
                                      </p>
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_txtfrstscalepurpose"
                                    id="CPH_lblpurpose"
                                  >
                                    उत्पन्नाचे प्रमाणपत्र कशासाठी हवे त्याचा
                                    तपशील
                                  </label>
                                  <span className="red">*</span>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <textarea
                                      name="IncomefromAgri"
                                      rows="2"
                                      cols="20"
                                      id="CPH_txtfrstscalepurpose"
                                      ////tabindex="37"
                                      class="form-control"
                                      value={Data.ReasonOfincome_get}
                                      onChange={(e) =>
                                        SetData({
                                          ...Data,
                                          ReasonOfincome_get: e.target.value,
                                        })
                                      }
                                      onkeypress="return IsAlphabet(event);"
                                    ></textarea>
                                    <p className="red">
                                      {Formerror.ReasonOfincome_get}
                                    </p>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      className="border mt-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded"
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div class="box-body">
                            <div class="row">
                              <div
                                className="col-lg-12 text-center border rounded mb-4"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    कुटुंबाला शेतीपासून मिळणाऱ्या उत्पन्नाचे
                                    विवरण
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_txtappaccountname"
                                    id="CPH_lblappaccountname"
                                  >
                                    खातेदाराचे नाव
                                  </label>
                                  <input
                                    name="accountHolder_Name"
                                    type="text"
                                    maxlength="50"
                                    id="CPH_txtfamilyname"
                                    ////tabindex="29"
                                    class="form-control"
                                    value={IncomeData.accountHolder_Name}
                                    onChange={(e) =>
                                      setIncomeData({
                                        ...IncomeData,
                                        accountHolder_Name: e.target.value,
                                      })
                                    }
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class="row">
                                  <div class="form-group col-md-6">
                                    <label
                                      for="CPH_txtappfarmingacre"
                                      id="CPH_lblappfarmingacre"
                                    >
                                      शेतीचे एकूण क्षेत्र
                                    </label>
                                    <input
                                      name="totalfarm"
                                      type="Number"
                                      maxlength="6"
                                      id="CPH_txtappfarmingacre"
                                      ////tabindex="39"
                                      class="form-control"
                                      value={IncomeData.totalfarm}
                                      onChange={(e) =>
                                        setIncomeData({
                                          ...IncomeData,
                                          totalfarm: e.target.value,
                                        })
                                      }
                                      onkeypress="return IsAlphabet(event);"
                                    />
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label></label>
                                    <input
                                      type="hidden"
                                      name="ctl00$CPH$hdvvalue"
                                      id="CPH_hdvvalue"
                                    />
                                    <select
                                      name="totalfarm"
                                      type="text"
                                      maxlength="50"
                                      id="CPH_txtfamilyname"
                                      ////tabindex="29"
                                      class="form-control"
                                      onkeypress="return IsAlphabet(event);"
                                      onChange={(e) =>
                                        setIncomeData({
                                          ...IncomeData,
                                          farmtype: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="---निवडा---">
                                        ---निवडा---
                                      </option>
                                      <option value="आर">आर</option>
                                      <option value="एकर">एकर </option>
                                      <option value="गुंठे">गुंठे </option>
                                      <option value="हेक्टर">हेक्टर </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_txtappfarmingdetails"
                                    id="CPH_lblappfarmingdetails"
                                  >
                                    शेतीचे विवरण
                                  </label>
                                  <input
                                    name="farmingdetails"
                                    type="text"
                                    maxlength="50"
                                    id="CPH_txtfamilyname"
                                    ////tabindex="29"
                                    class="form-control"
                                    value={IncomeData.farmingdetails}
                                    onChange={(e) =>
                                      setIncomeData({
                                        ...IncomeData,
                                        farmingdetails: e.target.value,
                                      })
                                    }
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_AppDistrict"
                                    id="CPH_lblappvildistrict"
                                  >
                                    जिल्हा
                                  </label>
                                  <select
                                    name="accountHolder_DIstrict"
                                    type="text"
                                    maxlength="50"
                                    id="CPH_txtfamilyname"
                                    ////tabindex="29"
                                    class="form-control"
                                    value={IncomeData.accountHolder_DIstrict}
                                    onChange={(e) => {
                                      setIncomeData({
                                        ...IncomeData,
                                        accountHolder_DIstrict: e.target.value,
                                      });
                                      setTalukasData(e.target.value);
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="">--Select Distict--</option>
                                    {districtData.map((item, i) => {
                                      return (
                                        <>
                                          <option value={item.name}>
                                            {item.name}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </select>
                                  <input
                                    type="hidden"
                                    name="ctl00$CPH$CascadingDist_ClientState"
                                    id="CPH_CascadingDist_ClientState"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_AppSubdistrict"
                                    id="CPH_lblappvilsubdistrict"
                                  >
                                    तालुका
                                  </label>
                                  <select
                                    name="accountHolder_Taluka"
                                    type="text"
                                    maxlength="50"
                                    id="CPH_txtfamilyname"
                                    ////tabindex="29"
                                    class="form-control"
                                    value={IncomeData.accountHolder_Taluka}
                                    onChange={(e) => {
                                      setIncomeData({
                                        ...IncomeData,
                                        accountHolder_Taluka: e.target.value,
                                      });
                                      console.log(12345, e.target.value);
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="">--Select Taluka--</option>
                                    {talukas.map((item, i) => {
                                      return (
                                        <>
                                          <option value={item}>{item}</option>
                                        </>
                                      );
                                    })}
                                  </select>
                                  <input
                                    type="hidden"
                                    name="ctl00$CPH$CascadingSubDist_ClientState"
                                    id="CPH_CascadingSubDist_ClientState"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label
                                    for="CPH_AppVillage"
                                    id="CPH_lblappvillname"
                                  >
                                    गाव
                                  </label>

                                  <input
                                    name="accountHolder_village"
                                    type="text"
                                    maxlength="6"
                                    id="CPH_txtappfarmingacre"
                                    ////tabindex="39"
                                    class="form-control"
                                    value={IncomeData.accountHolder_village}
                                    onChange={(e) =>
                                      setIncomeData({
                                        ...IncomeData,
                                        accountHolder_village: e.target.value,
                                      })
                                    }
                                    onkeypress="return IsAlphabet(event);"
                                  />

                                  <input
                                    type="hidden"
                                    name="ctl00$CPH$CascadingVillage_ClientState"
                                    id="CPH_CascadingVillage_ClientState"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 text-center my-3">
                            <Button
                              className="btn btn-success"
                              onClick={(e) => {
                                e.preventDefault();
                                const found = distibutionOfIncomefromAgri.find(
                                  (itm) =>
                                    itm.accountHolder_Name ===
                                    IncomeData.accountHolder_Name
                                );
                                if (!found) {
                                  setdistibutionOfIncomefromAgri([
                                    ...distibutionOfIncomefromAgri,
                                    {
                                      accountHolder_Name:
                                        IncomeData.accountHolder_Name,
                                      totalfarm: parseInt(IncomeData.totalfarm),
                                      farmingdetails: IncomeData.farmingdetails,
                                      accountHolder_DIstrict:
                                        IncomeData.accountHolder_DIstrict,
                                      accountHolder_Taluka:
                                        IncomeData.accountHolder_Taluka,
                                      accountHolder_village:
                                        IncomeData.accountHolder_village,
                                    },
                                  ]);
                                }
                              }}
                            >
                              जोडा
                            </Button>
                          </div>
                        </div>
                        {distibutionOfIncomefromAgri.map((itm) => (
                          <>
                            <div class="col-md-12 table-responsive">
                              <table
                                class="table table-striped"
                                id="tdRelationList"
                              >
                                <tbody>
                                  <tr>
                                    <th align="center">
                                      खातेदाराचे नाव
                                      <td>
                                        <h5>{itm.accountHolder_Name}</h5>
                                      </td>
                                    </th>
                                    <th align="center">
                                      शेतीचे एकूण क्षेत्र
                                      <td>
                                        <h5>{itm.totalfarm}</h5>
                                      </td>
                                    </th>
                                    <th align="center">
                                      शेतीचे विवरण
                                      <td>
                                        <h5>{itm.farmingdetails}</h5>
                                      </td>
                                    </th>
                                    <th align="center">
                                      जिल्हा
                                      <td>
                                        <h5>{itm.accountHolder_DIstrict}</h5>
                                      </td>
                                    </th>
                                    <th align="center">
                                      तालुका
                                      <td>
                                        <h5>{itm.accountHolder_Taluka}</h5>
                                      </td>
                                    </th>
                                    <th align="center">
                                      गाव
                                      <td>
                                        <h5>{itm.accountHolder_village}</h5>
                                      </td>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        ))}
                      </div>
                      <div class="col-md-12 table-responsive">
                        <table
                          class="table table-striped"
                          id="tdRelationList1"
                        ></table>
                      </div>

                      <div class="clearfix"></div>
                    </div>
                  </div>

                  <div class="row">
                    <div
                      className="border mt-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded"
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div class="box-body">
                            <div class="row">
                              <div
                                className="col-lg-12 text-center border rounded mb-4"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    विविध स्रोतांमार्फत कुटुंबाच्या उत्पन्नाचा
                                    तपशील
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-12 table-responsive">
                                {IncomeFor3year ? (
                                  <>
                                    <table
                                      id="incomeYear"
                                      cellpadding="0"
                                      cellspacing="0"
                                      class="table-striped form-table"
                                    >
                                      <tr>
                                        <th>
                                          <span id="CPH_lblseno">क्र.</span>
                                        </th>
                                        <th>
                                          <span id="CPH_lblincmesources">
                                            उत्पन्नाचे स्रोत
                                          </span>
                                        </th>
                                        <th id="CPH_td1">
                                          <span id="CPH_lblyr1">{`${
                                            new Date().getFullYear() - 3
                                          } -${
                                            new Date().getFullYear() - 2
                                          } `}</span>
                                        </th>
                                        <th id="CPH_td2">
                                          <span id="CPH_lblyr2">{`${
                                            new Date().getFullYear() - 2
                                          } -${
                                            new Date().getFullYear() - 1
                                          } `}</span>
                                        </th>
                                        <th>
                                          <span id="CPH_lblyr3">{`${
                                            new Date().getFullYear() - 1
                                          } -${new Date().getFullYear()} `}</span>
                                        </th>
                                        <th>
                                          <span id="CPH_lblbriefdetails">
                                            उत्पन्न व उत्पन्नाच्या स्रोताची
                                            थोडक्यात माहिती
                                          </span>
                                        </th>
                                      </tr>

                                      {income_input.map((item, i) => (
                                        <tr key={i}>
                                          <td className="mx-5">
                                            <span
                                              id="CPH_lbl1"
                                              className="mx-2"
                                            >
                                              {i + 1}.{" "}
                                            </span>
                                          </td>
                                          <td>
                                            <span id="CPH_lblfarming">
                                              {item.source_name}
                                            </span>
                                          </td>
                                          <td id="CPH_td11">
                                            <div class="input-group mb-3 ">
                                              <span class="input-group-text">
                                                <i class="fa fa-inr"></i>
                                              </span>
                                              <input
                                                class="form-control"
                                                aria-label="Amount (to the nearest dollar)"
                                                name={item.source_name}
                                                type="text"
                                                maxlength="9"
                                                id="txtfrstscale"
                                                ////tabindex={item.t1}
                                                onChange={(e) =>
                                                  handleIncomeChange(e)
                                                }
                                                onkeypress="return IsAlphabet(event);"
                                              />
                                            </div>
                                          </td>
                                          <td id="CPH_td21">
                                            <div class="input-group mb-3">
                                              <div className="mx-3 input-group">
                                                <span class="input-group-text">
                                                  <i class="fa fa-inr"></i>
                                                </span>
                                                <input
                                                  class="form-control mr-2"
                                                  aria-label="Amount (to the nearest dollar)"
                                                  name={item.source_name}
                                                  type="text"
                                                  maxlength="9"
                                                  id="txtfrstscale"
                                                  ////tabindex={item.t2}
                                                  onChange={(e) =>
                                                    handleIncomeChange(e)
                                                  }
                                                  onkeypress="return IsAlphabet(event);"
                                                />
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="input-group mb-3 ">
                                              <span class="input-group-text">
                                                <i class="fa fa-inr"></i>
                                              </span>
                                              <input
                                                class="form-control mr-2"
                                                aria-label="Amount (to the nearest dollar)"
                                                name={item.source_name}
                                                type="text"
                                                maxlength="9"
                                                id="txtfrstscale"
                                                ////tabindex={item.t3}
                                                onChange={(e) =>
                                                  handleIncomeChange(e)
                                                }
                                                onkeypress="return IsAlphabet(event);"
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <input
                                              name={item.source_name}
                                              type="text"
                                              maxlength="50"
                                              id="CPH_txtbriefdetails"
                                              ////tabindex={item.t4}
                                              class="lbl_value form-control mb-3 mx-2"
                                              onChange={(e) =>
                                                handleIncomeChange(e)
                                              }
                                            />
                                          </td>
                                        </tr>
                                      ))}

                                      <tr>
                                        <td></td>
                                        <td>
                                          <span id="CPH_lblannuincome">
                                            एकूण
                                          </span>
                                          <span className="red">*</span>
                                        </td>
                                        <td id="CPH_td111">
                                          <div class="input-group mb-3 ">
                                            <span class="input-group-text">
                                              <i class="fa fa-inr"></i>
                                            </span>
                                            <input
                                              class="form-control mr-2"
                                              aria-label="Amount (to the nearest dollar)"
                                              type="text"
                                              maxlength="9"
                                              id="txtfrstscale"
                                              onChange={handleChange}
                                              onkeypress="return IsAlphabet(event);"
                                            />
                                          </div>
                                        </td>
                                        <td id="CPH_td211">
                                          <div class="input-group mb-3  ">
                                            <div className="mx-2 input-group">
                                              <span class="input-group-text">
                                                <i class="fa fa-inr"></i>
                                              </span>
                                              <input
                                                class="form-control mr-2"
                                                aria-label="Amount (to the nearest dollar)"
                                                type="text"
                                                maxlength="9"
                                                id="txtfrstscale"
                                                onChange={handleChange}
                                                onkeypress="return IsAlphabet(event);"
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="input-group mb-3 ">
                                            <span class="input-group-text">
                                              <i class="fa fa-inr"></i>
                                            </span>
                                            <input
                                              class="form-control mr-2"
                                              aria-label="Amount (to the nearest dollar)"
                                              type="text"
                                              maxlength="9"
                                              id="txtfrstscale"
                                              onChange={handleChange}
                                              onkeypress="return IsAlphabet(event);"
                                            />
                                          </div>
                                        </td>
                                        <td width="150px" valign="top"></td>
                                      </tr>
                                    </table>
                                  </>
                                ) : (
                                  <>
                                    <table
                                      id="incomeYear"
                                      cellpadding="0"
                                      cellspacing="0"
                                      class="table-striped form-table"
                                    >
                                      <tr>
                                        <th className="mb-5">
                                          <span id="CPH_lblseno">क्र.</span>
                                        </th>
                                        <th className="mb-5">
                                          <span id="CPH_lblincmesources">
                                            उत्पन्नाचे स्रोत
                                          </span>
                                        </th>

                                        <th className="mb-5">
                                          <span id="CPH_lblyr3">{`${
                                            new Date().getFullYear() - 1
                                          } -${new Date().getFullYear()} `}</span>
                                        </th>
                                        <th className="mb-5">
                                          <span id="CPH_lblbriefdetails">
                                            उत्पन्न व उत्पन्नाच्या स्रोताची
                                            थोडक्यात माहिती
                                          </span>
                                        </th>
                                      </tr>

                                      {income_Singleinput.map((item, i) => (
                                        <tr key={i}>
                                          <td>
                                            <span id="CPH_lbl1">{i + 1}</span>
                                          </td>
                                          <td>
                                            <span id="CPH_lblfarming">
                                              {item.source_name}
                                            </span>
                                          </td>
                                          <td id="CPH_td11">
                                            <div class="icon-addon">
                                              <div class="input-group mb-2">
                                                <span class="input-group-text mt-2">
                                                  {/* $ */}
                                                  <i class="fa fa-inr"></i>
                                                  {/* </span>
                                                <span> */}
                                                </span>
                                                <input
                                                  type="text"
                                                  class="form-control mt-2"
                                                  aria-label="Amount (to the nearest dollar)"
                                                  name={item.source_name}
                                                  maxlength="9"
                                                  id="txtfrstscale"
                                                  ////tabindex={item.t1}
                                                  // class="lbl_value rupees form-control form-control"
                                                  onChange={(e) =>
                                                    handleIncomeChange(e)
                                                  }
                                                  onkeypress="return IsAlphabet(event);"
                                                />
                                              </div>
                                            </div>
                                          </td>

                                          <td id="CPH_td11">
                                            <input
                                              name={item.source_name}
                                              type="text"
                                              maxlength="50"
                                              id="CPH_txtbriefdetails"
                                              ////tabindex={item.t4}
                                              class="form-control mx-2"
                                              onChange={(e) =>
                                                handleIncomeChange(e)
                                              }
                                            />
                                          </td>
                                        </tr>
                                      ))}

                                      <tr>
                                        <td></td>
                                        <td>
                                          <span id="CPH_lblannuincome">
                                            एकूण
                                          </span>
                                          <span className="red">*</span>
                                        </td>
                                        <td id="CPH_td111">
                                          <div class="input-group mb-2">
                                            <span class="input-group-text mt-2">
                                              <i class="fa fa-inr"></i>
                                            </span>
                                            <input
                                              type="text"
                                              class="form-control mt-2"
                                              aria-label="Amount (to the nearest dollar)"
                                              name="frstscaleannu"
                                              maxlength="9"
                                              id="CPH_txtfrstscaleannu"
                                              ////tabindex="13333"
                                              value={totalincome}
                                              onkeypress="return IsAlphabet(event);"
                                            />
                                          </div>
                                        </td>

                                        <td width="150px" valign="top"></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td valign="top">
                                          <span id="year">एकूण (अक्षरी)</span>
                                        </td>
                                        <td id="CPH_td112">
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <label htmlFor="">
                                            {marathiWord}
                                          </label>
                                        </td>
                                      </tr>
                                    </table>
                                  </>
                                )}

                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label
                                      for="CPH_DropDownList1"
                                      id="CPH_Label87"
                                    >
                                      उत्पन्नाबाबत जोड कागदपत्र{" "}
                                    </label>
                                    <span className="red">*</span>
                                    <select
                                      name="IncomeDOcsName"
                                      id="CPH_ddlattach"
                                      ////tabindex="86"
                                      class="form-control"
                                      value={Data.IncomeDOcsName}
                                      onChange={(e) =>
                                        SetData({
                                          ...Data,
                                          IncomeDOcsName: e.target.value,
                                        })
                                      }
                                      onkeypress="return IsAlphabet(event);"
                                    >
                                      <option value="0">---निवडा---</option>
                                      <option value="प्रतिज्ञापत्र">
                                        प्रतिज्ञापत्र
                                      </option>
                                      <option value="तलाठी अहवाल">
                                        तलाठी अहवाल
                                      </option>
                                      <option value="आयकर विवरणपत्र ">
                                        आयकर विवरणपत्र{" "}
                                      </option>
                                      <option value="वेतन प्रमाणपत्र">
                                        वेतन प्रमाणपत्र
                                      </option>
                                      <option value=" नगर सेवक यांचे प्रमाणपत्र">
                                        नगर सेवक यांचे प्रमाणपत्र
                                      </option>
                                      <option value="मंडळ अधिकारी अहवाल">
                                        मंडळ अधिकारी अहवाल
                                      </option>
                                      <p className="red">
                                        {Formerror.IncomeDOcsName}
                                      </p>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            Upload required documents:
                          </span>
                        </h3>
                      </div>

                      <div className="col-md-12">
                        <h5>Upload required documents: </h5>
                        <h6 className="red fs-5 bolder">
                          <b>
                            अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व आवश्यक
                            कागदपत्रे तपासून व स्कॅन करून अपलोड करावे. जर आवश्यक
                            कागदपत्रे चुकीची किंवा अस्पष्ट आढळल्यास सदर चा अर्ज
                            फेटाळला जाऊ शकतो.{" "}
                          </b>
                        </h6>
                        <p className="red mt-2 fs-6 bolder">
                          <b>
                            IMP Note :- १) तलाठी उत्पन्न अहवाल किंवा फॉर्म नं १६
                            पार्ट A ,B अनिवार्य. २) रेशन-कार्ड अनिवार्य. ३)
                            वैद्यकीय व संजय गांधी निराधार योजनेकरिता उत्पन्न
                            दाखला हवा असल्यास मंडल अधिकारी (सर्कल) चौकशी अहवाल
                            अनिवार्य.
                          </b>
                        </p>
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="" className="mt-3 bolt">
                          {" "}
                          <span className="red">*</span>आधार कार्ड (Maximum
                          500Kb)PDF
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              adharCard: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.adharCard}</p>
                      </div> */}

                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          <span className="red">*</span>लाईट बिल (Maximum
                          500Kb)PDF
                        </label>

                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              lightBill: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.lightBill}</p>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          <span className="red">*</span>रेशनकार्ड Size Photo
                          (Maximum 500Kb)PDF
                        </label>

                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              reshaCard: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.reshaCard}</p>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          <span className="red">*</span>तलाठी / मंडल अधिकारी
                          उत्पन्न अहवाल / फॉम १६ A,B (Maximum 500Kb)PDF
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              form16: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.form16}</p>
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          <span className="red">*</span>फोटो (अर्डदार , लाभार्थी
                          ) (5Kb to 20Kb)JPG/JEPG
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              photo: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.photo}</p>
                      </div> */}
                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          घर भाड्याने असल्यास संमती पत्र (5Kb to 20Kb)JPG/JEPG
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              consentform: e.target.files[0],
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          <span className="red">*</span>स्वःघोषणापत्र(5Kb to
                  20Kb)JPG/JEPG
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              selfDeclearation: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.selfDeclaration}</p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          <span className="red">*</span>वडिलांचे आधार कार्ड (5Kb
                          to 20Kb)JPG/JEPG
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              FatherAdhar: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.FatherAdhar}</p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          <span className="red">*</span>मुलगा / मुलगी आधार कार्ड
                          (5Kb to 20Kb)JPG/JEPG
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              childrenAdhar: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.childrenAdhar}</p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          <span className="red">*</span>अर्जदार फोटो (5Kb to
                          20Kb)JPG/JEPG
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              applicantPhoto: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.applicantPhoto}</p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          <span className="red">*</span>लाभार्थी फोटो (5Kb to
                          20Kb)JPG/JEPG
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              benificiarryPhoto: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.benificiarryPhoto}</p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          Other (5Kb to 20Kb)JPG/JEPG
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              otherFirst: e.target.files[0],
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mt-3">
                          {" "}
                          Other || (5Kb to 20Kb)JPG/JEPG
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              otherSecond: e.target.files[0],
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                      <a href="" target="_blank">स्वघोषणापत्र</a>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-bg" role="form">
                        <div class="box-header box-header-bg">
                          <h3 class="box-title">
                            <span id="CPH_label4">कराराचा तपशील:</span>
                            <legend> &nbsp;</legend>
                          </h3>
                        </div>
                        <div class="box-body">
                          <div class="row">
                            <div class="col-md-12">
                              <span
                                id="CPH_lblbenefagreement"
                                class="lbl_property "
                              >
                                मी घोषित करतो/ करते की वरील सर्व माहिती माझ्या
                                व्यक्तीगत माहिती व समजुतीनुसार खरी आहे. सदर
                                माहिती खोटी आढळून आल्यास, भारतीय दंड संहिता १९६०
                                कलम १९९ व २०० व अन्य/ संबंधित कायदयानुसार
                                माझ्यावर खटला भरला जाईल व त्यानुसार मी शिक्षेस
                                पात्र राहीन याची मला पूर्ण जाणीव आहे.
                              </span>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <span className="red">*</span>
                                <span class="minimal">
                                  <input
                                    id="CPH_chkaccept"
                                    type="checkbox"
                                    name="ctl00$CPH$chkaccept"
                                    ////tabindex="87"
                                    onChange={(e) => setAgree(true)}
                                  />
                                  <p className="red">
                                    {Formerror.ctl00$CPH$chkaccept}
                                  </p>

                                  <label for="CPH_chkaccept">मला मंजूर</label>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 text-center  gap-5">
                  {showloading ? (
                      <>
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) :(<>
                    {id == undefined ? (
                      <input
                        type="submit"
                        name="btnsubmit"
                        value="समावेश करा"
                        onClick={(e) => {
                          postLDJData(e);
                        }}
                        id="CPH_btnsubmit"
                        ////tabindex="88"
                        className="btn btn-success gap-5"
                        style={{ marginRight: "30" }}
                      />
                    ) : (
                      <input
                        type="submit"
                        name="btnsubmit"
                        value="Update"
                        onClick={(e) => {
                          editHandler(e);
                        }}
                        id="CPH_btnsubmit"
                        ////tabindex="88"
                        className="btn btn-success gap-5"
                        style={{ marginRight: "30" }}
                      />
                    )}

                    <input
                      type="submit"
                      name="btnback"
                      value="मागे"
                      onclick="return back();"
                      id="CPH_btnback"
                      ////tabindex="89"
                      class="btn btn-warning"
                    />
                    <input
                      type="submit"
                      name="btnreset"
                      value="पुनर्स्थित"
                      onclick="return disp_confirm();"
                      id="CPH_btnreset"
                      ////tabindex="90"
                      class="btn btn-danger"
                    />
                    </>
                    )}
                  </div>

                  <div class="clearfix"></div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Income;
