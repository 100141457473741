import React from "react";
import axios from "axios";
import { MdUploadFile } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { toast } from "react-toastify";
// import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { incNumber } from "../../Redux/actions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import TextArea from "antd/es/input/TextArea";
import swal from "sweetalert";

const PassportForm = () => {
  const navigate = useNavigate();
  const { passid } = useParams();
  const [BrithAddress, SetBrithAddress] = useState(false);
  const [FormFileerror, setFormFileerror] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const [balance, setBalance] = useState();
  const [showreissuereason, setreasonofreissue] = useState(false);
  const [showloading, setShowloading] = useState(false);
  const [Data, setData] = useState({
    createdBy: userData.user._id,
    createdByName: userData.user?.name,
    application_type: "passport",
    status: "IN-PROGRESS",
    formPrice: "",
    typePassport: "",
    ReasonOfReissue: "",
    oldPassNo: "",
    fileNo: "",
    ExpDate: "",
    issueDate: "",
    AdharNo: "",
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    gender: "",
    Dob: "",
    pob: "",
    maritalStatus: "",
    father_firstName: "",
    father_middleName: "",
    father_lastName: "",
    mother_firstName: "",
    mother_middleName: "",
    mother_lastName: "",
    spouse_firstName: "",
    spouse_middleName: "",
    spouse_lastName: "",
    presentAddress: "",
    permanantAdress_as_presentAdress: "",
    permanantAdress: "",
    preferred_policeStation: "",
    isYourParents_governmentServant: "",
    educationQualification: "",
    employmentType: "",
    appliedPassport: "",
    EmergencyContact_FirstName: "",
    EmergencyContact_middleName: "",
    EmergencyContact_lastName: "",
    EmergencyContact_mobileNO: "",
    EmergencyAddress: "",
  });
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const pricess = useSelector((state) => state.price.formPrices);
  console.log(28, pricess);

  const reducer = useSelector((state) => state.changeNumber);

  const [isVisible, setisVisible] = useState(false);
  const [userFormDetails, setuserFormDetails] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/formPrice`)
      .then((res) => {
        const response = res.data;
        const filtered = response.filter(
          (item) => item.userID == userData.user._id
        );
        console.log(50, filtered);
        if (filtered.length == 0) {
          const userFormDetails = response.filter(
            (item) => item.userID === "ALL"
          );
          setuserFormDetails(userFormDetails);
        } else {
          const userFormDetails = response.filter(
            (item) => item.userID === userData.user._id
          );
          setuserFormDetails(userFormDetails);
        }
      })
      .catch((err) => console.log(40, err));
  }, []);

  const [selectedRtoPricewe, setSelectedRtoPrice] = useState();
  const setFormPrice = (formType) => {
    switch (formType) {
      case "Normal":
        {
          setSelectedRtoPrice(userFormDetails[0].passport_Normal);
          setisVisible(true);
          setData({
            ...Data,
            typeApplication: formType,
            formPrice: userFormDetails[0].passport_Normal,
          });
        }
        break;
      case "Tatkal":
        {
          setSelectedRtoPrice(userFormDetails[0].passport_Tatkal);
          setisVisible(true);
          setData({
            ...Data,
            typeApplication: formType,
            formPrice: userFormDetails[0]?.passport_Tatkal,
          });
        }
        break;
      default:
        break;
    }
  };
  console.log(134, Data);
  const [Formerror, setFormerror] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  useEffect(() => {
    console.log(Formerror);
    if (Object.keys(Formerror).length == 0 && isSubmit) {
      console.log(Data);
    }
  }, [Formerror]);
  const validate = (values) => {
    const error = {};
    if (!values.typeApplication) {
      error.typeApplication = " Type Application is required";
    }
    if (!values.typePassport) {
      error.typePassport = " Select type Passport Is required";
    }
    if (!values.title) {
      error.title = " Title is required";
    }
    if (!values.firstName) {
      error.firstName = " FullName English is required";
    }
    if (!values.middleName) {
      error.middleName = " Middle Name is required";
    }
    if (!values.lastName) {
      error.lastName = " Last Name is required";
    }
    if (!values.mobileNumber) {
      error.mobileNumber = " Mobile Number is required";
    }
    if (!values.email) {
      error.email = " Email is required";
    }
    if (!values.mother_lastName) {
      error.mother_lastName = " mother_lastName is required";
    }
    if (!values.spouse_firstName) {
      error.spouse_firstName = " Spouse firstName is required";
    }
    if (!values.spouse_middleName) {
      error.spouse_middleName = " Spouse Middle Name is required";
    }
    if (!values.spouse_lastName) {
      error.spouse_lastName = " Spouse Last Name is required";
    }
    if (!values.mother_middleName) {
      error.mother_middleName = " Mother middleName is required";
    }
    if (!values.gender) {
      error.gender = " Gender is required";
    }
    if (!values.maritalStatus) {
      error.maritalStatus = " Marital Status is required";
    }

    if (!values.preferred_policeStation) {
      error.preferred_policeStation = " Preferred policeStation is required";
    }
    if (!values.isYourParents_governmentServant) {
      error.isYourParents_governmentServant =
        " is Your Parents_governmentServant required ";
    }
    if (!values.educationQualification) {
      error.educationQualification = "Education Qualification is required";
    }
    if (!values.employmentType) {
      error.employmentType = "Employment Type is required";
    }
    if (!values.appliedPassport) {
      error.appliedPassport = "Applied Passport is required";
    }
    if (!values.EmergencyContact_FirstName) {
      error.EmergencyContact_FirstName =
        "Emergency Contact FirstName is required";
    }
    if (!values.EmergencyContact_middleName) {
      error.EmergencyContact_middleName =
        "Emergency Contact Middle Name is required";
    }
    if (!values.EmergencyContact_lastName) {
      error.EmergencyContact_lastName =
        "Emergency Contact Last Name is required";
    }
    if (!values.EmergencyAddress) {
      error.EmergencyAddress = "Emergency Address is required";
    }
    if (!values.Dob) {
      error.Dob = " Dob is required";
    }
    if (!values.pob) {
      error.pob = " pob is required";
    }
    if (!values.father_firstName) {
      error.father_firstName = " Father_firstName is required";
    }
    if (!values.father_lastName) {
      error.father_lastName = " Father_Last Name is required";
    }
    if (!values.mother_firstName) {
      error.mother_firstName = " Father_Last Name is required";
    }
    if (!values.father_middleName) {
      error.father_middleName = " Father_middleName is required";
    }
    console.log("error object", error);
    return error;
  };
  const FileValidate = (Val) => {
    let fileError = {};
    if (!Val.ApplicantAdbhar) {
      fileError.ApplicantAdbhar = "File is Required";
    } else {
      if (Val.ApplicantAdbhar.size > 1024 * 1024) {
        fileError.ApplicantAdbhar = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.ApplicantFatherAdhar) {
      fileError.ApplicantFatherAdhar = "File is Required";
    } else {
      if (Val.ApplicantFatherAdhar.size > 1024 * 1024) {
        fileError.ApplicantFatherAdhar = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.ApplicantSpoceAdhar) {
      fileError.ApplicantSpoceAdhar = "File is Required";
    } else {
      if (Val.ApplicantSpoceAdhar.size > 1024 * 1024) {
        fileError.ApplicantSpoceAdhar = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.ApplicantSchoolLc) {
      fileError.ApplicantSchoolLc = "File is Required";
    } else {
      if (Val.ApplicantSchoolLc.size > 1024 * 1024) {
        fileError.ApplicantSchoolLc = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.ApplicantMotherAdhar) {
      fileError.ApplicantMotherAdhar = "File is Required";
    } else {
      if (Val.ApplicantMotherAdhar.size > 1024 * 1024) {
        fileError.ApplicantMotherAdhar = " File size exceeds the limit of 1MB";
      }
    }
    console.log("error object", fileError);
    return fileError;
  };

  const addPreviousAddress = () => {
    SetBrithAddress(false);
    setData((prevState) => {
      return {
        ...prevState,

        permanantAdress: prevState.presentAddress,
      };
    });
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userResponse"));

    if (userData) {
      axios.get(`https://api.maharashtraseva.com/api/recharge`).then((res) => {
        console.log(88, res.data);

        const allData = res.data.filter(
          (ele) => ele.user === userData.user._id
        );
        let amount = 0;
        let expence = 0;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.isExpence === "true") {
              expence += item.amount;
            } else {
              amount += item.amount;
            }
          });
        }
        setBalance(amount - expence);
      });
    }
  }, [reducer]);

  const handleDateChange = (date, dateString) => {
    setData({ ...Data, Dob: dateString });
  };
  const handleExpDateChange = (date, dateString) => {
    setData({ ...Data, ExpDate: dateString });
  };
  const handleissueDateChange = (date, dateString) => {
    setData({ ...Data, issueDate: dateString });
  };

  const [docs, setDocs] = useState({
    zipAllDocuments: "",
    ApplicantAdbhar: "",
    ApplicantFatherAdhar: "",
    ApplicantSpoceAdhar: "",
    ApplicantSchoolLc: "",
    ApplicantMotherAdhar: "",
  });
  const SubmitPassForm = (e) => {
    e.preventDefault();
    const FileError = FileValidate(docs);
    const errors = validate(Data);
    console.log("formErrors:", errors);
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(FileError).length === 0
    ) {
      SetIsSubmit(true);
      const obj = {
        Data,
      };
      if (balance > selectedRtoPricewe) {
        const mainDataPromise = new Promise((resolve, reject) => {
          setShowloading(true);
          axios
            .post(`https://api.maharashtraseva.com/api/passport/create`, Data)
            .then((res) => {
              const response = res;
              resolve({
                status: true,
                message: "data Posted Successfully",
                data: res.data,
              });
            })
            .catch((err) => {
              console.log(err);
              setShowloading(false);
              reject({
                status: false,
                message: "Data Not posted",
              });
            });
        });

        mainDataPromise
          .then((res) => {
            console.log(124, res.data);

            // uploadzipAllDocuments(res.data.data._id);
            uploadApplicatAdhar(res.data.data._id);
            uploadApplicantFatherAdhar(res.data.data._id);
            uploadApplicantSpoceAdhar(res.data.data._id);
            uploadApplicantSchoolLc(res.data.data._id);
            uploadApplicantMotherAdhar(res.data.data._id);
            debitFormBalance();
            setData({
              ...Data,
              typePassport: "",
              ReasonOfReissue: "",
              oldPassNo: "",
              fileNo: "",
              ExpDate: "",
              issueDate: "",
              AdharNo: "",
              title: "",
              firstName: "",
              middleName: "",
              lastName: "",
              mobileNumber: "",
              email: "",
              gender: "",
              Dob: "",
              pob: "",
              maritalStatus: "",
              father_firstName: "",
              father_middleName: "",
              father_lastName: "",
              mother_firstName: "",
              mother_middleName: "",
              mother_lastName: "",
              spouse_firstName: "",
              spouse_middleName: "",
              spouse_lastName: "",
              presentAddress: "",
              permanantAdress_as_presentAdress: "",
              permanantAdress: "",
              preferred_policeStation: "",
              isYourParents_governmentServant: "",
              educationQualification: "",
              employmentType: "",
              appliedPassport: "",
              EmergencyContact_FirstName: "",
              EmergencyContact_middleName: "",
              EmergencyContact_lastName: "",
              EmergencyContact_mobileNO: "",
              EmergencyAddress: "",
            });
            
          })
          // .then(() =>{setShowloading(false);
          // })
          .then(() => navigate("/RetailerDashboard"))
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error("Please Recharge");
      }
    } else {
      setFormerror(errors);
      setFormFileerror(FileError);
      swal("Filled the required form");
    }
  };

  const obj = {
    user: userData?.user._id,
    mode: "offline",
    amount: selectedRtoPricewe,
    isExpence: true,
    expenceFor: "individualGST",
  };
  const debitFormBalance = () => {
    axios
      .post("https://api.maharashtraseva.com/api/recharge/create", obj)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());
        swal("Good job!", "form submitted successfully!", "success");
      })
      .catch((err) => console.log(34, err));
  };
  useEffect(() => {
    if (passid != undefined) {
      axios.get(`https://api.maharashtraseva.com/api/passport/${passid}`).then((res) => {
        const data = res.data[0];

        console.log("data", data);
        setData(data);
        if (
          data?.isNew == true &&
          data?.status == "REJECTED" &&
          userData.user.role === "retailer"
        ) {
          axios
            .put(`https://api.maharashtraseva.com/api/passport/${passid}`, {
              isNew: false,
            })
            .then((res) => {
              dispatch(incNumber());
            })
            .catch((err) => console.log(err));
        }
      });
    }
  }, [passid]);

  const editHandler = (e) => {
    e.preventDefault();

    const obj = {
      ...Data,
      createdBy: userData.user._id,
      createdByName: userData.user?.name,
      application_type: "passport",
      status: "IN-PROGRESS",
    };

    const mainDataPromise = new Promise((resolve, reject) => {
      axios
        .put(`https://api.maharashtraseva.com/api/passport/${passid}`, obj)
        .then((res) => {
          const response = res;
          resolve({
            status: true,
            message: "data Updated Successfully",
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            status: false,
            message: "Data Not posted",
          });
        });
    });

    mainDataPromise
      .then((res) => {
        // console.log(124,res.data)

        // uploadzipAllDocuments(res.data._id);
        uploadApplicatAdhar(res.data._id);
        uploadApplicantFatherAdhar(res.data._id);
        uploadApplicantSpoceAdhar(res.data._id);
        uploadApplicantSchoolLc(res.data._id);
        uploadApplicantMotherAdhar(res.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadzipAllDocuments = (id) => {
    const formData = new FormData();
    formData.append("zipAllDocuments", docs.zipAllDocuments);
    axios
      .put(`https://api.maharashtraseva.com/api/passport_zipAllDocuments/${id}`, formData)
      .then((res) => console.log("uploadzipAllDocuments", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadApplicatAdhar = (id) => {
    const formData = new FormData();
    formData.append("ApplicantAdbhar", docs.ApplicantAdbhar);
    axios
      .put(`https://api.maharashtraseva.com/api/passport_ApplicantAdbhar/${id}`, formData)
      .then((res) => console.log("ApplicantAdbhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadApplicantFatherAdhar = (id) => {
    const formData = new FormData();
    formData.append("ApplicantFatherAdhar", docs.ApplicantFatherAdhar);
    axios
      .put(
        `https://api.maharashtraseva.com/api/passport_ApplicantFatherAdhar/${id}`,
        formData
      )
      .then((res) => console.log("ApplicantFatherAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadApplicantSpoceAdhar = (id) => {
    const formData = new FormData();
    formData.append("ApplicantSpoceAdhar", docs.ApplicantSpoceAdhar);
    axios
      .put(
        `https://api.maharashtraseva.com/api/passport_ApplicantSpoceAdhar/${id}`,
        formData
      )
      .then((res) => console.log("ApplicantSpoceAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadApplicantSchoolLc = (id) => {
    const formData = new FormData();
    formData.append("ApplicantSchoolLc", docs.ApplicantSchoolLc);
    axios
      .put(
        `https://api.maharashtraseva.com/api/passport_ApplicantSchoolLc/${id}`,
        formData
      )
      .then((res) => console.log("ApplicantSchoolLc", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadApplicantMotherAdhar = (id) => {
    const formData = new FormData();
    formData.append("ApplicantMotherAdhar", docs.ApplicantMotherAdhar);
    axios
      .put(
        `https://api.maharashtraseva.com/api/passport_ApplicantMotherAdhar/${id}`,
        formData
      )
      .then((res) => console.log("ApplicantMotherAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Breadcrumb title={"Passport Form"} />
      <Container fluid={true}>
        {isVisible && <h3>{` Form Price :${selectedRtoPricewe}`}</h3>}

        <Row>
          <Col sm="">
            <Card>
              <CardBody>
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Type of Application
                      </label>
                      <Form.Item>
                        <Select
                          placeholder="Selec Type Of Application!"
                          value={Data.typeApplication}
                          onChange={(e) => {
                            // setData({ ...Data, typeApplication: e });
                            setFormPrice(e);
                          }}
                        >
                          <Select.Option value="Normal">Normal</Select.Option>
                          <Select.Option value="Tatkal">Tatkal</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Select Type of Passport
                      </label>

                      <Form.Item>
                        <Radio.Group
                          onChange={(e) => {
                            console.log(569, e.target.value);
                            if (e.target.value == "Reissue") {
                              setreasonofreissue(true);
                            } else {
                              setreasonofreissue(false);
                            }
                            setData({ ...Data, typePassport: e.target.value });
                          }}
                        >
                          <Radio value="Fresh">Fresh</Radio>
                          <Radio value="Reissue">Reissue </Radio>
                        </Radio.Group>
                        <p className="red">{Formerror.typePassport}</p>
                      </Form.Item>
                    </div>
                    {showreissuereason && (
                      <>
                        <div className="col-md-4">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {" "}
                            <span className="red">*</span>{" "}
                            Reason Of Reissue
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input
                              className="p-2"
                              placeholder="Please input your Reason Of Reissue!"
                              value={Data.ReasonOfReissue}
                              onChange={(e) => {
                                setData({
                                  ...Data,
                                  ReasonOfReissue: e.target.value,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {/* oldPassNo:"",
    fileNo:"",
    ExpDate:"",
    issueDate:"",
    AdharNo:"", */}{" "}
                            <span className="red">*</span>{" "}
                            Old Passport Number
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input
                              className="p-2"
                              type="Number"
                              placeholder="Please input your Reason Of Reissue!"
                              value={Data.oldPassNo}
                              onChange={(e) => {
                                setData({
                                  ...Data,
                                  oldPassNo: e.target.value,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {/* oldPassNo:"",
    fileNo:"",
    ExpDate:"",
    issueDate:"",
    AdharNo:"", */}{" "}
                            <span className="red">*</span> File Number
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input
                              className="p-2"
                              type="Number"
                              placeholder="Please input your Reason Of Reissue!"
                              value={Data.fileNo}
                              onChange={(e) => {
                                setData({
                                  ...Data,
                                  fileNo: e.target.value,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {/* oldPassNo:"",
    fileNo:"",
    ExpDate:"",
    issueDate:"",
    AdharNo:"", */}{" "}
                            <span className="red">*</span> Expiry Date
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={handleExpDateChange}
                              format="DD-MM-YYYY"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {/* oldPassNo:"",
    fileNo:"",
    ExpDate:"",
    issueDate:"",
    AdharNo:"", */}{" "}
                            <span className="red">*</span> issued Date
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={handleissueDateChange}
                              format="DD-MM-YYYY"
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Title
                      </label>

                      <Form.Item>
                        <Select
                          placeholder="Please Select Title"
                          value={Data.title}
                          onChange={(e) => {
                            setData({ ...Data, title: e });
                          }}
                        >
                          <Select.Option value="Mr.">Mr.</Select.Option>
                          <Select.Option value="Mrs.">Mrs.</Select.Option>
                          <Select.Option value="Ms.">Ms.</Select.Option>
                        </Select>
                        <p className="red">{Formerror.title}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> First Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your First Name!"
                          value={Data.firstName}
                          onChange={(e) => {
                            setData({ ...Data, firstName: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.firstName}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Middle Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your middle Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your middle Name!"
                          value={Data.middleName}
                          onChange={(e) => {
                            setData({ ...Data, middleName: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.middleName}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Last Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Last Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your Last Name!"
                          value={Data.lastName}
                          onChange={(e) => {
                            setData({ ...Data, lastName: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.lastName}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Mobile No
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Mobile Number!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          type="Number"
                          placeholder="Please input your Mobile Number!"
                          value={Data.mobileNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 10) {
                              setData({
                                ...Data,
                                mobileNumber: sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                              setData({
                                ...Data,
                                mobileNumber: "", // Reset the adharNumber if it's too long
                              });
                            }
                            // setData({ ...Data, mobileNumber: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.mobileNumber}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Aadhar No
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Mobile Number!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          type="Number"
                          placeholder="Please input your AAdhar Number!"
                          value={Data.AdharNo}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 12) {
                              setData({
                                ...Data,
                                AdharNo: sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                              setData({
                                ...Data,
                                AdharNo: "", // Reset the adharNumber if it's too long
                              });
                            }
                            // setData({ ...Data, mobileNumber: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.mobileNumber}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Email
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Email!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your Email!"
                          value={Data.email}
                          onChange={(e) => {
                            setData({ ...Data, email: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.email}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Gender
                      </label>

                      <Form.Item>
                        <Radio.Group
                          value={Data.gender}
                          onChange={(e) => {
                            setData({ ...Data, gender: e.target.value });
                          }}
                        >
                          <Radio value="male">Male</Radio>
                          <Radio value="female">Female</Radio>
                          <Radio value="others">Others</Radio>
                        </Radio.Group>
                        <p className="red">{Formerror.gender}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Date Of Birth
                      </label>

                      <Form.Item>
                        <DatePicker
                          onChange={handleDateChange}
                          format="DD-MM-YYYY"
                        />
                        <p className="red">{Formerror.Dob}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        Place Of Birth
                      </label>

                      <Form.Item>
                        <Input
                          className="p-2"
                          placeholder="Please input your Country Of Birth!"
                          onChange={(e) => {
                            setData({ ...Data, pob: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.pob}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Marital Status
                      </label>

                      <Form.Item>
                        <Select
                          placeholder="Plaese Select Marital Status"
                          value={Data.maritalStatus}
                          onChange={(e) => {
                            setData({ ...Data, maritalStatus: e });
                          }}
                        >
                          <Select.Option value="Single">Single</Select.Option>
                          <Select.Option value="Married">Married</Select.Option>
                          <Select.Option value="Divorced">
                            Divorced
                          </Select.Option>
                          <Select.Option value="Window/Widower">
                            Window/Widower
                          </Select.Option>
                          <Select.Option value="Separated">
                            Separated
                          </Select.Option>
                        </Select>
                        <p className="red">{Formerror.maritalStatus}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Father First Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Father First Name!"
                          value={Data.father_firstName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              father_firstName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.father_firstName}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Father Middle Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Enter Father Middle Name!"
                          value={Data.father_middleName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              father_middleName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.father_middleName}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Father Last Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="please Enter  Father last name"
                          value={Data.father_lastName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              father_lastName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.father_lastName}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Mother First Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Enter Mother First Name!"
                          value={Data.mother_firstName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              mother_firstName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.mother_firstName}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Mother Middle Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Enter mother Middle Name!"
                          value={Data.mother_middleName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              mother_middleName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.mother_middleName}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Mother Last Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="please Enter Mother last name"
                          value={Data.mother_lastName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              mother_lastName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.mother_lastName}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Spouse First Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Enter Spouse First Name!"
                          value={Data.spouse_firstName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              spouse_firstName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.spouse_firstName}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Criminal Record
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Enter Spouse First Name!"
                          value={Data.CriminalRecord}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              CriminalRecord: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.CriminalRecord}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Spouse Middle Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Enter Spouse Middle Name!"
                          value={Data.spouse_middleName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              spouse_middleName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.spouse_middleName}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Spouse Last Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="please Enter Mother last name"
                          value={Data.spouse_lastName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              spouse_lastName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.spouse_lastName}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Present Address
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder=" please Enter Present Address!"
                          value={Data.presentAddress}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              presentAddress: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Permanent Address Same as Present Address?
                      </label>

                      <Form.Item>
                        <Radio.Group>
                          <Radio
                            value="yes"
                            // onClick={() => SetBrithAddress(false)}
                            onClick={() => addPreviousAddress()}
                          >
                            Yes
                          </Radio>
                          <Radio
                            value="No"
                            onClick={() => SetBrithAddress(true)}
                          >
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    {BrithAddress && (
                      <>
                        <div className="col-md-4">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {" "}
                            Permanent Address
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input your Full Name as per Records!",
                              },
                            ]}
                          >
                            <TextArea
                              placeholder=" please Enter Present Address!"
                              value={Data.permanantAdress}
                              onChange={(e) => {
                                setData({
                                  ...Data,
                                  permanantAdress: e.target.value,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Preferred Police Station
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Enter Preferred Police Station!"
                          value={Data.preferred_policeStation}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              preferred_policeStation: e.target.value,
                            });
                          }}
                        />
                        <p className="red">
                          {Formerror.preferred_policeStation}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        
                          Is either of your parent(in case of minor)/spouse, a
                          government servant?
                        
                      </label>

                      <Form.Item>
                        <Radio.Group
                          value={Data.isYourParents_governmentServant}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              isYourParents_governmentServant: e.target.value,
                            });
                          }}
                        >
                          <Radio value="Yes">Yes</Radio>
                          <Radio value="No">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <p className="red">
                        {Formerror.isYourParents_governmentServant}
                      </p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Educational qualification?
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Enter Edgucation Qualification!"
                          value={Data.educationQualification}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              educationQualification: e.target.value,
                            });
                          }}
                        />
                        <p className="red">
                          {Formerror.educationQualification}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Employment Type
                      </label>
                      <Form.Item>
                        <Select
                          placeholder="Select Type Of Employment!"
                          value={Data.employmentType}
                          onChange={(e) => {
                            setData({ ...Data, employmentType: e });
                          }}
                        >
                          <Select.Option value="Government">
                            Government
                          </Select.Option>
                          <Select.Option value="Homemaker">
                            Homemaker
                          </Select.Option>
                          <Select.Option value="Not Employee">
                            Not Employee
                          </Select.Option>
                          <Select.Option value="Private">Private</Select.Option>
                          <Select.Option value="PSU">PSU</Select.Option>
                          <Select.Option value="Retired Government Servent">
                            Retired Government Servent
                          </Select.Option>
                        </Select>
                        <p className="red">{Formerror.employmentType}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        
                          Have you ever applied for passport, but not issued
                        
                      </label>

                      <Form.Item>
                        <Radio.Group
                          value={Data.appliedPassport}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              appliedPassport: e.target.value,
                            });
                          }}
                        >
                          <Radio value="yes">Yes</Radio>
                          <Radio value="No">NO</Radio>
                        </Radio.Group>
                        <p className="red">{Formerror.appliedPassport}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Emergency Contact First Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Enter Emergency Contact First Name!"
                          value={Data.EmergencyContact_FirstName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              EmergencyContact_FirstName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">
                          {Formerror.EmergencyContact_FirstName}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                         Emergency Contact Middle Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Plaese Enter Emergency Contact Middle Name!"
                          value={Data.EmergencyContact_middleName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              EmergencyContact_middleName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">
                          {Formerror.EmergencyContact_middleName}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Emergency Contact Last Name
                      </label>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please Enter Emergency Contact Last Name!"
                          value={Data.EmergencyContact_lastName}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              EmergencyContact_lastName: e.target.value,
                            });
                          }}
                        />
                        <p className="red">
                          {Formerror.EmergencyContact_lastName}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        Emergency Contact Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          type="Number"
                          placeholder="Please Enter Emergency Contact Mobile Number!"
                          value={Data.EmergencyContact_mobileNO}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              EmergencyContact_mobileNO: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Emergency Address
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Please Enter Emergency Address!"
                          value={Data.EmergencyAddress}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              EmergencyAddress: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.EmergencyAddress}</p>
                      </Form.Item>
                    </div>
                    <div className="col-lg-12">
                      <h5 className="red">
                        अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व आवश्यक
                        कागदपत्रे तपासून व स्कॅन करून अपलोड करावे. जर आवश्यक
                        कागदपत्रे चुकीची किंवा अस्पष्ट आढळल्यास सदर चा अर्ज
                        फेटाळला जाऊ शकतो.{" "}
                      </h5>
                    </div>

                    <div className="col-md-6 mt-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Applicant Adhar upload
                        (Size - Maximum 500kb) [Only (pdf,PDF)]
                      </label>

                      <input
                        type="file"
                        name=""
                        id=""
                        className="form-control"
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            ApplicantAdbhar: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.ApplicantAdbhar}</p>
                    </div>
                    <div className="col-md-6 mt-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Applicant Father Adhar
                        upload (Size - Maximum 500kb) [Only (pdf,PDF)]
                      </label>

                      <input
                        type="file"
                        name=""
                        id=""
                        className="form-control"
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            ApplicantFatherAdhar: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">
                        {FormFileerror.ApplicantFatherAdhar}
                      </p>
                    </div>
                    <div className="col-md-6 mt-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Applicant Spouse Adhar
                        upload (Size - Maximum 500kb) [Only (pdf,PDF)]
                      </label>

                      <input
                        type="file"
                        name=""
                        id=""
                        className="form-control"
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            ApplicantSpoceAdhar: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.ApplicantSpoceAdhar}</p>
                    </div>
                    <div className="col-md-6 mt-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Applicant School Leaving
                        Certificate upload (Size - Maximum 500kb) [Only
                        (pdf,PDF)]
                      </label>

                      <input
                        type="file"
                        name=""
                        id=""
                        className="form-control"
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            ApplicantSchoolLc: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.ApplicantSchoolLc}</p>
                    </div>
                    <div className="col-md-6 mt-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Applicant Mother Adhar
                        upload (Size - Maximum 500kb) [Only (pdf,PDF)]
                      </label>

                      <input
                        type="file"
                        name=""
                        id=""
                        className="form-control"
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            ApplicantMotherAdhar: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">
                        {FormFileerror.ApplicantMotherAdhar}
                      </p>
                    </div>

                    <div className="row mt-5 text-center ">
                      {showloading ? (
                        <>
                          <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </>
                      ) : passid == undefined ? (
                        <div className="col-md-12">
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={(e) => SubmitPassForm(e)}
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={(e) => editHandler(e)}
                            >
                              Update
                            </Button>
                          </Form.Item>
                        </div>
                      )}

                      {/* <div className="col-md-1">
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Reset
                        </Button>
                      </Form.Item>
                    </div> */}
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PassportForm;
