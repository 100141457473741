import React, { useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { incNumber } from "../../Redux/actions";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import swal from "sweetalert";
function AddYoutube() {
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.changeNumber);

  const [Data, setData] = useState({
    title: "",
    youtubeLink: "",
  });
  const deleteUser = (id) => {
    console.log(56,id);
    
    axios
      .delete(`https://api.maharashtraseva.com/api/youtube/${id}`)
      .then((res) => {
      swal("Deleted", "Video Deleted Succesfully....!", "success");
      dispatch(incNumber()); 
    })   
      .catch((err) => {
        console.log(err);
      });

  };
  const [videoData, setVideoData] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.maharashtraseva.com/api/youtube")
      .then(async (res) => {
        const data = await res.data.reverse();
        console.log(12, data);
        setVideoData(data);
        //   dispatch(setAllForm(data));
      })
      .catch((err) => console.log(err));
  }, [reducer]);
  const SaveData = (e) => {
    // e.preventDefault();
    // console.log(11,Data);
    e.preventDefault();
    console.log(77, Data);
    axios
      .post(`https://api.maharashtraseva.com/api/youtube/create/`, Data)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());
        toast.success("Video Upload Successfully..");
      })
      .catch((err) => console.log(34, err));
  };
  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWaight: "900",
        // Add any other CSS properties you want to apply to the rows
        fontSize: "22px",
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        fontWaight: "900",
        fontSize: "18px",
        border: "1px solid black",
        background: "#0094ef",
        color: "white",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        fontSize: "20px",
        border: "1px solid black",
      },
    },
    cells: {
      style: {
        fontSize: "15px",
        color: "black",
        // Add any styles for the regular cells
        border: "1px solid black",
      },
    },
    pagination: {
      style: {
        // Add any styles for the pagination section
        border: "1px solid black",
      },
    },
  };
  const columns = [
    {
      name: "Sr No",
      width: "80px",
      style: {
        textAlign: "center",
      },
      selector: (row, index) => index + 1,
    },
    {
      name: "Action",
      width: "90px",
      // center:true,
      style: {
        textAlign: "center",
        cursor: "pointer",
        color: "blue",
      },
      selector: (row) => (
        <>
         <i
                class="bi bi-trash3 fs-4 text-danger"
                style={{ cursor: "pointer" }}
                onClick={(e) => deleteUser(row._id)}
              ></i>
        </>
      ),
    },
    {
      name: " Video Title",
 
      style: {
        textAlign: "center",
      },
      // center:true,
      selector: (row) => row.title,
    },

    {
      name: "Video Link",
 
      // center:true,
      style: {
        textAlign: "center",
      },
      selector: (row) => row.youtubeLink,
    }
  ];
  return (
    <>
      <Breadcrumb title="Add Youtube Links" parent="Create User Passport" />

      <div className="row">
        <div class="col-md-6">
          <div class="form-heading">
            <label>Video Title</label>
          </div>
          <br />
          <input
            type="text"
            id="newpass"
            value={Data.title}
            class="form-control form-control-lg"
            placeholder="Enter Video Title"
            required
            onChange={(e) => {
              setData({ ...Data, title: e.target.value });
            }}
          />
        </div>
        <div class="col-md-6">
          <div class="form-heading">
            <label>Video Link </label>
          </div>
          <br />
          <input
            type="text"
            id="newpass"
            value={Data.youtubeLink}
            class="form-control form-control-lg"
            placeholder="Enter Video Link"
            required
            onChange={(e) => {
              setData({ ...Data, youtubeLink: e.target.value });
            }}
          />
        </div>
      </div>
      <br />
      <div class="col-md-12">
        <input
          type="submit"
          name="submit"
          class="btn btn-primary"
          value="Save"
          onClick={(e) => {
            SaveData(e);
          }}
        />
        &nbsp;&nbsp;
        <input
          style={{ backgroundColor: "#1877F2", color: "white" }}
          type="reset"
          name="submit"
          class="btn "
          value="Cancle"
        />
        &nbsp;&nbsp;
      </div>
      <div className="col-lg-12 mt-5">
        <DataTable
          columns={columns}
          data={videoData}
          pagination={videoData.length > 3 ? true : false}
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight="300px"
          customStyles={customStyles}
        />
      </div>
    </>
  );
}

export default AddYoutube;
