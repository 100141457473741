import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate } from "react-router-dom";
import Table_admin from "./Table_admin";
import { MdReceipt } from "react-icons/md";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import DataTable from "react-data-table-component";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Form, Radio, Select, DatePicker } from "antd";
import { incNumber } from "../../Redux/actions";


const Console_form4 = () => {
  const navigate = useNavigate();
  const dispatch =useDispatch()

  const form4 = useSelector((state) => state.formFour.form4);
  const form4SingleUser = useSelector((state) => state.singleUserTehsilForms.Tehsilforms);
  console.log(33,form4SingleUser)
  const userRespons = JSON.parse(localStorage.getItem('userResponse'));

  const [AllData, setData] = useState(form4);
  const [Form11Data, setForm11Data] = useState(form4);
  const [FilteredData, setfilteredData] = useState(form4);
  const [application_type, setapplication_type] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchdata, setsearch] = useState("");
  const receiptFun = (id) => {
    navigate(`${process.env.PUBLIC_URL}/FormFurReceipt/${id}`);
  };
  useEffect(() => {
    axios
      .get("https://api.maharashtraseva.com/api/getAll")
      .then(async (res) => {
        const data = await res.data;
        if (form4.length<=0) {
          
          setData(data);
          setForm11Data(data);
          setfilteredData(data);
        }else{
          setData(form4);
          setForm11Data(form4);
          setfilteredData(form4);

        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handler = () => {
    if (application_type != null && status != null) {
      const filtered = AllData.filter((item) => {
        if (
          item.application_type === application_type &&
          item.status === status
        ) {
          return item;
        }
      });
      setForm11Data(filtered);
      setfilteredData(filtered);
    } else {
      setForm11Data(AllData);
      setfilteredData(AllData);
    }
  };

  useEffect(() => {
    handler();
  }, [application_type, status]);

  const searchByName = (e) => {
    setsearch(e.target.value);
    const value = e.target.value;

    if (value.length > 0) {
      const searchdata = Form11Data.filter((item, index) => {
        if (item.createdByName != undefined && item.createdByName.length > 0) {
          const name = item.createdByName;
          if (name.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        }
      });
      setfilteredData(searchdata);
    } else {
      setfilteredData(Form11Data);
    }
  };
// const navigate =useNavigate()
// const [ FilteredData , setfilteredData ] =useState([])
useEffect(()=>{

  axios.get('https://api.maharashtraseva.com/api/getAll' )
  .then(async(res)=>{
      const data=await res.data
      // setData(data)
      // setForm11Data(data)
      setfilteredData(data)


  })
  .catch((err)=>console.log(err))
  },[])
const ewsview = (id) => {
  navigate(`${process.env.PUBLIC_URL}/retailer_ews/${id}`);
};

const incomeview = (id) => {
  navigate(`${process.env.PUBLIC_URL}/retailer_income/${id}`);
};

const Non_Cremylayerview = (id) => {
  navigate(`${process.env.PUBLIC_URL}/retailer_Non_Cremylayer/${id}`);
};

const ageNationalityview = (id) => {
  navigate(`${process.env.PUBLIC_URL}/retailer_ageNationality/${id}`);
};
  // const customStyles = {
  //   rows: {
  //     style: {
  //      border:"1px solid black",
  //      fontWeight:"600",
  //      fontSize:"18px",
  //       // Add any other CSS properties you want to apply to the rows
  //     },
  //   },
  //   headRow: {
  //     style: {
  //       // Add any styles for the header row
  //       border:"1px solid black",
  //       backgroundColor:"skyblue",
  //       color:"black"
  //     },
  //   },
  //   headCells: {
  //     style: {
  //       // Add any styles for the header cells
  //       border:"1px solid black"
  //     },
  //   },
  //   cells: {
  //     style: {
  //       // Add any styles for the regular cells
  //       border:"1px solid black"
  //     },
  //   }, 
  // };
  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWeight:"600",
       fontSize:"18px",
      //  width:"fit-content"
        // Add any other CSS properties you want to apply to the rows
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        border: "1px solid black",
        backgroundColor:"#0094ef  ",
        color: "white",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        border: "1px solid black",
         width:"fit-content"
      },
    },
    cells: {
      style: {
        // Add any styles for the regular cells
        border: "1px solid black",
        width:"fit-content"
      },
    },
  };
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  const columns = [
    {
      name: "New",
      width: "110px",

      selector: (row, i) => (
        <div
          className="d-flex justify-content-space-between"
          style={{
            fontWeight: row?.isNew ? "bold" : "",
          }}
        >
          {i + 1} {row.isNew ? <div className="new">New</div> : <></>}
        </div>
      ),
    },
    {
      name: "Application-Type",
      width:"280px",
      selector: (row) =>{if(row.application_type=="EWS")
      {
        return <><button type="button " style={{ backgroundColor:"transparent",border:"none",
          fontWeight: row?.isNew ? "800" : "600",
          color:"blue",
          cursor:"pointer",
          marginRight:"65px",
         }}
        onClick={()=>{ewsview(row._id);dispatch(incNumber())}}>EWS certificate</button>
        
        <MdReceipt  style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />
        </>
      }
      else if(row.application_type=="Age_Nationality"){
        return <><button type="button " 
        onClick={()=>{ageNationalityview(row._id);dispatch(incNumber())}}
        style={{ backgroundColor:"transparent",border:"none",
          fontWeight: row?.isNew ? "800" : "600",
          color:"blue",
          marginRight:"65px",
          cursor:"pointer",}}
       >Age certificate</button>
       <MdReceipt  style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />
       </>

      }
      else if(row.application_type=="Non_Cremylayer certificate"){
        return <><button type="button " 
        onClick={()=>{Non_Cremylayerview(row._id);dispatch(incNumber())}}
        style={{ backgroundColor:"transparent",border:"none",
          fontWeight: row?.isNew ? "800" : "600",
          color:"blue",
          marginRight:"65px",
          cursor:"pointer",}}
       > NonCremylayer certificate</button>
       <MdReceipt  style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />
       </>

      }
      else if(row.application_type=="Income certificate"){
        return <><button type="button "
        onClick={()=>{incomeview(row._id);dispatch(incNumber())}}
         style={{ backgroundColor:"transparent",border:"none",
          fontWeight: row?.isNew ? "800" : "600",
          color:"blue",
          marginRight:"65px",
          cursor:"pointer",}}
       > Income certificate</button>
       <MdReceipt  style={{cursor:"pointer", fontSize:"20px"}} onClick={() => receiptFun(row._id)} />
       </>

      }
      else{
        return row.application_type
      }
    }
      
    },
    {
      name: "Created By ",
      width:"200px",
      selector: (row) =>(
        <>
         <div style={{ backgroundColor:"transparent",border:"none",
          fontWeight: row?.isNew ? "bold" : "",
          }}>
          {row.createdByName}
        </div>
        </>
      )
      // <>
      // {

      // row.createdByName,
      // }
      // </> 
    },
    {
      name: "Co-ordinator",
      width:"200px",
      selector: (row) =>(
        <>
         <div style={{ backgroundColor:"transparent",border:"none",
          fontWeight: row?.isNew ? "bold" : "",
          }}>
          { row.agentName}
        </div>
        </>
      )
     
    },
    {
      name: "Status ",
      selector: (row) =>
      (
        <>
         <div style={{ backgroundColor:"transparent",border:"none",
          fontWeight: row?.isNew ? "bold" : "",
          }}>
          { row.status}
        </div>
        </>
      )
      
    },

    {
      name: "Created On ",
      width:"250px",
      selector: (row) =>(
        <>
         <div style={{ backgroundColor:"transparent",border:"none",
          fontWeight: row?.isNew ? "bold" : "",
          }}>
          {GetDate(row.createdAt)}
        </div>
        </>
      )
    },
    {
      name: "Updated Date ",
      width:"250px",
      selector: (row) =>(
        <>
         <div style={{ backgroundColor:"transparent",border:"none",
          fontWeight: row?.isNew ? "bold" : "",
          }}>
          {GetDate(row.updatedAt)}
        </div>
        </>
      )
     
    }  

  ];

  return (
    <>
      <Breadcrumb title={" Application Console :"} parent={"Certificate"} />

     
      <div
                    className="border  p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div
                      className="col-lg-12 text-center border rounded"
                      style={{ backgroundColor: "#e0e0e0" }}
                    >
                      <h3 class="box-title">
                        <span id="CPH_lbl_AppDtl">Tehsil Application Type </span>
                      </h3>
                    </div>
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div className="row">
                    {" "}
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        Certificate Name
                        <span className="red">*</span>
                      </label>
                      <Form.Item>
                        <Select
                          placeholder="--Select Application Stauts--"
                          onChange={(e) => setapplication_type(e)}
                        >
                          <Select.Option value="EWS">
                            EWS certificate
                          </Select.Option>
                          <Select.Option value="Income certificate">
                            Income certificate
                          </Select.Option>
                          <Select.Option value="Non_Cremylayer">
                            Non-cremylayer certificate
                          </Select.Option>
                          <Select.Option value="Age_Nationality">
                            Age-Nationality certificate
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        Application Status
                        <span className="red">*</span>
                      </label>
                      <Form.Item>
                        <Select
                          placeholder="--Select Application Stauts--"
                          onChange={(e) => setStatus(e)}
                        >
                          <Select.Option value="IN-PROGRESS">
                            IN-PROGRESS
                          </Select.Option>
                          <Select.Option value="SUBMITTED">
                            SUBMITTED
                          </Select.Option>
                          <Select.Option value="PENDING">PENDING</Select.Option>
                          <Select.Option value="COMPLETE">
                            COMPLETE
                          </Select.Option>
                          <Select.Option value="REJECTED">
                            REJECTED
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-4 mt-2">
                      <Form.Item>
                        <div class="d-grid gap-1 d-md-flex justify-content-md-end mt-4 ">
                          <button
                            class="btn btn-primary"
                            type="button"
                            onClick={() =>
                              navigate(
                                `${process.env.PUBLIC_URL}/admin_application`
                              )
                            }
                          >
                            Back
                          </button>
                        </div>
                      </Form.Item>
                    </div>
                    <div className="col-lg-12">
                    <h6>
                            Search :{" "}
                            <span>
                              <input
                                type="text "
                                value={searchdata}
                                onChange={searchByName}
                                style={{
                                  border: "1px solid black",
                                  borderRadius: "3px",
                                }}
                              />
                            </span>
                          </h6>

                          <div className="row">
                            {
                              // FilteredData.length>0 ? <Table_admin FilteredData={FilteredData}/>:<div> No Found Data</div>
                              FilteredData.length > 0 ? (
                                <>
                                  <DataTable
                                    columns={columns}
                                    data={FilteredData}
                                    pagination={true}
                                    customStyles={customStyles}
                                  />
                                </>
                              ) : (
                                <div> No Found Data</div>
                              )
                            }
                          </div>
                    </div>
                  </div>
                </Form>
                </div>
    </>
  );
};

export default Console_form4;
