import React from "react";
import { MdUploadFile } from "react-icons/md";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { useState, useEffect } from "react";
import axios from "axios";

import {
  districtData,
  relationApplicant_beneficiary,
} from "../../constants/data";
import { statusfname } from "../../constants/data";
import { work_type } from "../../constants/data";
import { useNavigate, useParams } from "react-router-dom";
import { incNumber } from "../../Redux/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

function AgeDomicile() {
  const navigate = useNavigate();
  const [nameMarathi, setnameMarathi] = useState("");
  const [fnameMarathi, setfnameMarathi] = useState("");
  const [EDDetails, setEDDetails] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const [agree, setAgree] = useState(false);
  const [items, setItems] = useState();
  const [BrithAddress, SetBrithAddress] = useState(false);
  const [MoveableProperty, SetMoveableProperty] = useState(false);
  const [Otherstate, SetOtherstate] = useState(false);
  const [MigratedDetails, setMigratedDetails] = useState(false);
  const [Formerror, setFormerror] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  const ID = items?.user?._id;
  const createdByName = items?.user?.name;
  const users = useSelector((state) => state.userData.user);
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const [balance, setBalance] = useState(0);
  const [FormFileerror, setFormFileerror] = useState({});
  const [docs, setDocs] = useState({
    reshanCard: "",
    adharCard: "",
    lightBill: "",
    schoolLeaveCertificate: "",
    taxBillOr15yerOldLightbill: "",
    photo: "",
    selfDeclaration: "",
    FatherAdhar: "",
    childrenAdhar: "",
    applicantPhoto: "",
    benificiarryPhoto: "",
    fatherSchoolLeaveCer: "",
    childrenSchoolLeaveCer: "",
    otherFirst: "",
    otherSecond: "",
  });
  const [user, setUser] = useState([]);
  const [getAgent, setgetAgent] = useState([]);
  const [GetFormPrice, setGetFormPrice] = useState();
  const [talukas, setTalukas] = useState([]);
  const [showloading, setShowloading] = useState(false);

  useEffect(() => {
    const userRespons = JSON.parse(localStorage.getItem("userResponse"));
    if (userRespons) {
      setItems(userRespons);
    }
  }, []);

  const getData = () => {
    axios
      .get(`https://api.maharashtraseva.com/api/user/getAll`)
      .then((res) => {
        const response = res.data;
        setUser(response);
        const userdata = JSON.parse(localStorage.getItem("userResponse"));
        if (userdata) {
          const getFormPrice = response.find(
            (item) => item._id === userdata.user._id
          );
          const getagent = response.filter(
            (item) => item._id === getFormPrice.agent
          );
          setgetAgent(getagent);
          setGetFormPrice(
            getFormPrice?.retaile_formPrice?.retailer_ageNashnality
          );
          
         SetData({...Data,formPrice:getFormPrice?.retaile_formPrice?.retailer_ageNashnality})
        }
      })
      .catch((err) => console.log(40, err));
  };
  useEffect(() => {
    setTimeout(getData(), 1000);
  }, []);

  useEffect(() => {
    if (userData) {
      axios.get(`https://api.maharashtraseva.com/api/recharge`).then((res) => {
        const allData = res.data.filter(
          (ele) => ele.user === userData.user._id
        );
        let amount = 0;
        let expence = 0;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.isExpence == "true") {
              expence += item.amount;
            } else {
              amount += item.amount;
            }
          });
        }
        console.log(53, amount, expence);
        setBalance(amount - expence);
      });
    }
  }, []);

  const FileValidate = (Val) => {
    let fileError = {};
    if (!Val.reshanCard) {
      fileError.reshanCard = "File is Required";
    } else {
      if (Val.reshanCard.size > 1024 * 1024) {
        fileError.reshanCard = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.lightBill) {
      fileError.lightBill = "File is Required";
    } else {
      if (Val.lightBill.size > 500 * 1024) {
        fileError.lightBill = " File size exceeds the limit of 500kb";
      }
    }

    if (!Val.taxBillOr15yerOldLightbill) {
      fileError.taxBillOr15yerOldLightbill = "File is Required";
    } else {
      if (Val.taxBillOr15yerOldLightbill.size > 500 * 1024) {
        fileError.taxBillOr15yerOldLightbill =
          " File size exceeds the limit of 500kb";
      }
    }

    if (!Val.selfDeclaration) {
      fileError.selfDeclaration = "File is Required";
    } else {
      if (Val.selfDeclaration.size > 500 * 1024) {
        fileError.selfDeclaration = " File size exceeds the limit of 500kb";
      }
    }

    if (!Val.childrenSchoolLeaveCer) {
      fileError.childrenSchoolLeaveCer = "File is Required";
    } else {
      if (Val.childrenSchoolLeaveCer.size > 500 * 1024) {
        fileError.childrenSchoolLeaveCer =
          " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.fatherSchoolLeaveCer) {
      fileError.fatherSchoolLeaveCer = "File is Required";
    } else {
      if (Val.fatherSchoolLeaveCer.size > 500 * 1024) {
        fileError.fatherSchoolLeaveCer =
          " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.benificiarryPhoto) {
      fileError.benificiarryPhoto = "File is Required";
    } else {
      if (Val.benificiarryPhoto.size > 500 * 1024) {
        fileError.benificiarryPhoto = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.applicantPhoto) {
      fileError.applicantPhoto = "File is Required";
    } else {
      if (Val.applicantPhoto.size > 500 * 1024) {
        fileError.applicantPhoto = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.childrenAdhar) {
      fileError.childrenAdhar = "File is Required";
    } else {
      if (Val.childrenAdhar.size > 500 * 1024) {
        fileError.childrenAdhar = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.FatherAdhar) {
      fileError.FatherAdhar = "File is Required";
    } else {
      if (Val.FatherAdhar.size > 500 * 1024) {
        fileError.FatherAdhar = " File size exceeds the limit of 500kb";
      }
    }

    return fileError;
  };

  const [form] = Form.useForm();
  const [Data, SetData] = useState({
    docName: "",
    formPrice:"",
    statusfname: "",
    fullName_English: "",
    fullName_Marathi: "",
    fatherName_english: "",
    fatherName_marathi: "",
    BirthDate: "",
    Locality: "",
    age: "",
    Gender: "",
    work_type: "",
    phoneNUm: "",
    email: "",
    AdharNo: "",
    address: "",
    Building: "",
    street: "",
    landmark: "",
    district: "",
    taluka: "",
    village: "",
    pincode: "",
    onAddressLiveing: "",
    currentlivingInyear: "",
    Applicunt_Live_In_MH_Inyear: "",
    relationApplicant_beneficiary: "",
    benificiary_NameStatus: "",
    benificiary_Name: "",
    benificiary_DOB: "",
    Benificiary_Phoneno: "",
    BirthDetails_address: "",
    BirthDetails_streetName: "",
    BirthDetails_Building: "",
    BirthDetails_locality: "",
    BirthDetails_Country: "",
    BirthDetails_state: "",
    BirthDetails_district: "",
    BirthDetails_tehsil: "",
    BirthDetails_village: "",
    BirthDetails_pincode: "",
    // isMovablePropartyOfApp_FAther_Hus: false,
    Proparty_address: "",
    Proparty_street: "",
    Proparty_Building: "",
    Proparty_locality: "",
    Proparty_Country: "",
    Proparty_State: "",
    Proparty_district: "",
    Proparty_Tehsil: "",
    Proparty_village: "",
    Proparty_pincode: "",
    Benificiary_propartyHolder_Relation: "",
    Benificiary_propartyDetails: "",
    Benificiary_Father_HusRecidance: "",
    Benificiary_FatherRecidaceOfBirth: "",
    isBenificiary_FatherRecidaceOfBirth_outOf_MH: "",
    placeOfEmploment: "",
    isApllicantBenificiaryOtherState: false,
    ApllicantBenificiaryOtherState: "",
    ApllicantBenificiaryOtherState_district: "",
    ApllicantBenificiaryOtherState_Taluka: "",
    ApllicantBenificiaryOtherState_Village: "",
    ApllicantBenificiaryOtherState_pincode: "",
    CertificateReason: "",
  });

  const translateName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.fullName_English,
      })
      .then((res) => {
        setnameMarathi(res.data.output);
        SetData({
          ...Data,
          fullName_Marathi: res.data.output,
        });
      })
      .catch((err) => console.log(err));
  };

  const translateFName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.fatherName_english,
      })
      .then((res) => {
        console.log(res.data.output);
        setfnameMarathi(res.data.output);
        SetData({
          ...Data,
          fatherName_marathi: res.data.output,
        });
      })
      .catch((err) => console.log(err));
  };

  const clickHandler = () => {
    translateName();
  };

  const clickHandler1 = () => {
    translateFName();
  };

  const validate = (values) => {
    const error = {};
    if (!values.statusfname) {
      error.statusfname = " Status full name is required";
    }
    if (!values.fullName_English) {
      error.fullName_English = " FullName English is required";
    }

    if (!values.statusfname) {
      error.statusfname = "statusfname is required";
    }
    if (!values.fatherName_english) {
      error.fatherName_english = " father Name english nameis required";
    }

    if (!values.age) {
      error.age = " Age is required";
    }

    if (!values.Gender) {
      error.Gender = "  Gender is required";
    }
    if (!values.work_type) {
      error.work_type = " work_type is required";
    }
    if (!values.phoneNUm) {
      error.phoneNUm = "  Place Brithdate  is required";
    }

    if (!values.address) {
      error.address = "  address  is required";
    }

    if (!values.district) {
      error.district = "district is required";
    }
    if (!values.taluka) {
      error.taluka = "Taluka is required";
    }
    if (!values.village) {
      error.village = "village is required";
    }
    if (!values.pincode) {
      error.pincode = "pincode is required";
    }
    if (!values.onAddressLiveing) {
      error.onAddressLiveing = "Residing at present address since is required";
    }
    if (!values.currentlivingInyear) {
      error.currentlivingInyear = "Please enter the year in yyyy format.";
    }
    if (!values.relationApplicant_beneficiary) {
      error.relationApplicant_beneficiary =
        "Please select applicant's relation with beneficiary.";
    }
    if (!values.Applicunt_Live_In_MH_Inyear) {
      error.Applicunt_Live_In_MH_Inyear =
        "Please enter the number of years the applicant has resided in Maharashtra.";
    }
    if (!values.benificiary_Name) {
      error.benificiary_Name = "Please enter beneficiary's full name.";
    }
    if (!values.benificiary_DOB) {
      error.benificiary_DOB = "Please enter beneficiary date of birth.";
    }
    if (!values.docName) {
      error.docName = "docName is required";
    }
    console.log("error object", error);
    return error;
  };

  const handleDateChange = (Bddate, dateString) => {
    SetData({ ...Data, BirthDate: dateString });
  };
  const ChangeDate = (Bdata, dateString) => {
    SetData({ ...Data, benificiary_DOB: dateString });
  };
  const handalStartDate = (MSdata, dateString) => {
    SetMigrationBeneficiary({
      ...MigrationBeneficiary,
      MigrationStartYear: dateString,
    });
  };
  const handalEndDate = (MEdata, dateString) => {
    SetMigrationBeneficiary({
      ...MigrationBeneficiary,
      MigrationEndYear: dateString,
    });
  };

  const [EducationDetails, SetEducationDetails] = useState({
    begree: "",
    organizationName: "",
    AddmisionYear: "",
    leaveYear: "",
    EducationPlace: "",
  });
  const ValidateEDDetails = (value) => {
    const error = {};
    if (!value.begree) {
      error.begree = "Degree Is Require";
    }
    if (!value.organizationName) {
      error.organizationName = "Organization Name Is Require";
    }
    if (!value.AddmisionYear) {
      error.AddmisionYear = "Addmision Year Is Require";
    }
    if (!value.leaveYear) {
      error.leaveYear = "leave Year Is Require";
    }
    if (!value.EducationPlace) {
      error.EducationPlace = "Education Place Is Require";
    }
    console.log("error object", error);
    return error;
  };
  const [EducationDetailsArray, SetEducationDetailsArray] = useState([]);
  // console.log(178, EducationDetailsArray);
  const [MigrationBeneficiary, SetMigrationBeneficiary] = useState({
    migrationToMHYear: "",
    BeforeMigrationLocation: "",
    reasonOfMigration: "",
    placeOfMigration: "",
    MigrationStartYear: "",
    MigrationEndYear: "",
  });
  const [MigrationBeneficiaryArray, SetMigrationBeneficiaryArray] = useState(
    []
  );
  // console.log(178, MigrationBeneficiaryArray);

  const [FMemberBeneficiary, SetFMemberBeneficiary] = useState({
    relation_beneficiary: "",
    familyMember_name: "",
  });
  const [FMemberBeneficiaryArray, SetFMemberBeneficiaryArray] = useState([]);
  const AgeSubmitdomicile = (e) => {
    e.preventDefault();
    setShowloading(true);
    const fileerror = FileValidate(docs);
    const errors = validate(Data);
    console.log(460, errors);
    console.log(460, fileerror);
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(fileerror).length === 0
    ) {
      SetIsSubmit(true);
      const obj = {
        Data,
      };
      console.log(obj);

      if (agree) {
        if (balance > GetFormPrice) {
          // setShowloading(true);
          const obj = {
            application_type: "Age_Nationality",
            status: "IN-PROGRESS",
            createdBy: ID,
            createdByName,
            agentId: getAgent[0]._id,
            agentName: getAgent[0].name,
            Data,
            EducationDetailsArray,
            MigrationBeneficiaryArray,
            FMemberBeneficiaryArray,
          };

          const mainDataPromise = new Promise((resolve, reject) => {
            // console.log(77, Data);
            axios
              .post(`https://api.maharashtraseva.com/api/Age_Nashnality/create`, obj)
              .then((res) => {
                const response = res;
                resolve({
                  status: true,
                  message: "data Posted Successfully",
                  data: res.data,
                });
                setShowloading(false);
              })
              .catch((err) => {
                console.log(err);
                reject({
                  status: false,
                  message: "Data Not posted",
                });
                setShowloading(false);
              });
          });

          mainDataPromise
            .then((res) => {
              console.log(124, res.data);

              uploadreshaCard(res.data.data._id);
              // uploadadharCard(res.data.data._id);
              uploadlightBill(res.data.data._id);
              uploadschoolLeaveCertificate(res.data.data._id);
              // uploadphoto(res.data.data._id);
              uploadtaxBillOr15yerOldLightbill(res.data.data._id);
              uploadselfDeclearation(res.data.data._id);
              uploadotherSecond(res.data.data._id);
              uploadotherFirst(res.data.data._id);
              uploadchildrenSchoolLeaveCer(res.data.data._id);
              uploadfatherSchoolLeaveCer(res.data.data._id);
              uploadbenificiarryPhoto(res.data.data._id);
              uploadapplicantPhoto(res.data.data._id);
              uploadchildrenAdhar(res.data.data._id);
              uploadFatherAdhar(res.data.data._id);
              debitFormBalance();
              CreaditAgent();
              SetData({
                docName: "",
                statusfname: "",
                fullName_English: "",
                fullName_Marathi: "",
                fatherName_english: "",
                fatherName_marathi: "",
                BirthDate: "",
                Locality: "",
                age: "",
                Gender: "",
                work_type: "",
                phoneNUm: "",
                email: "",
                AdharNo: "",
                address: "",
                Building: "",
                street: "",
                landmark: "",
                district: "",
                taluka: "",
                village: "",
                pincode: "",
                onAddressLiveing: "",
                currentlivingInyear: "",
                Applicunt_Live_In_MH_Inyear: "",
                relationApplicant_beneficiary: "",
                benificiary_NameStatus: "",
                benificiary_Name: "",
                benificiary_DOB: "",
                Benificiary_Phoneno: "",
                BirthDetails_address: "",
                BirthDetails_streetName: "",
                BirthDetails_Building: "",
                BirthDetails_locality: "",
                BirthDetails_Country: "",
                BirthDetails_state: "",
                BirthDetails_district: "",
                BirthDetails_tehsil: "",
                BirthDetails_village: "",
                BirthDetails_pincode: "",
                Proparty_address: "",
                Proparty_street: "",
                Proparty_Building: "",
                Proparty_locality: "",
                Proparty_Country: "",
                Proparty_State: "",
                Proparty_district: "",
                Proparty_Tehsil: "",
                Proparty_village: "",
                Proparty_pincode: "",
                Benificiary_propartyHolder_Relation: "",
                Benificiary_propartyDetails: "",
                Benificiary_Father_HusRecidance: "",
                Benificiary_FatherRecidaceOfBirth: "",
                isBenificiary_FatherRecidaceOfBirth_outOf_MH: "",
                placeOfEmploment: "",
                isApllicantBenificiaryOtherState: false,
                ApllicantBenificiaryOtherState: "",
                ApllicantBenificiaryOtherState_district: "",
                ApllicantBenificiaryOtherState_Taluka: "",
                ApllicantBenificiaryOtherState_Village: "",
                ApllicantBenificiaryOtherState_pincode: "",
                CertificateReason: "",
              });
              SetMigrationBeneficiary({
                migrationToMHYear: "",
                BeforeMigrationLocation: "",
                reasonOfMigration: "",
                placeOfMigration: "",
                MigrationStartYear: "",
                MigrationEndYear: "",
              });
              SetFMemberBeneficiaryArray([]);
              SetEducationDetailsArray([]);
            }).then(() => navigate("/RetailerDashboard"))
            .catch((err) => {
              console.log(err);
            });
        } else {
          toast.error("Please Recharge");
          setShowloading(false);
        }
      }
    } else {
      setFormerror(errors);
      setShowloading(false);

      setFormFileerror(fileerror);
      swal("Filled the required form");
      setShowloading(false);
    }
  };
  const CreaditAgent = () => {
    const obj = {
      agentId: getAgent[0]._id,
      agentName: getAgent[0].name,
      creaditAmount: getAgent[0].agent_formPrice.agent_ageNasnality,
      isWithdrowl: false,
      isrequest: false,
      creaditFor: "Age Nashnality ",
      creaditBy: userData.user._id,
    };
    console.log(292, obj);
    axios
      .post(`https://api.maharashtraseva.com/api/wallet/create`, obj)
      .then((res) => {
        const response = res;
        dispatch(incNumber());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const debitFormBalance = () => {
    const obj = {
      user: userData?.user._id,
      mode: "offline",
      amount: GetFormPrice,
      isExpence: true,
      expenceFor: "Age Nashnality",
    };
    axios
      .post("https://api.maharashtraseva.com/api/recharge/create", obj)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());
        swal("Good job!", "form submitted successfully!", "success");
        // navigate("/RetailerForm4");
      })
      .catch((err) => console.log(34, err));
  };

  const uploadreshaCard = (id) => {
    const formData = new FormData();
    formData.append("reshanCard", docs.reshanCard);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_reshanCard/${id}`,
        formData
      )
      .then((res) => console.log("reshanCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadadharCard = (id) => {
    const formData = new FormData();
    formData.append("adharcard", docs.adharCard);
    axios
      .put(`https://api.maharashtraseva.com/api/Age_Nashnality_adharCard/${id}`, formData)
      .then((res) => console.log("adharCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadlightBill = (id) => {
    const formData = new FormData();
    formData.append("lightbill", docs.lightBill);
    axios
      .put(`https://api.maharashtraseva.com/api/Age_Nashnality_lightbill/${id}`, formData)
      .then((res) => console.log("lightbill", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadschoolLeaveCertificate = (id) => {
    const formData = new FormData();
    formData.append("schoolLeaveCertificate", docs.schoolLeaveCertificate);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_schoolLeaveCertificate/${id}`,
        formData
      )
      .then((res) => console.log("schoolLeaveCertificate", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadphoto = (id) => {
    const formData = new FormData();
    formData.append("photo", docs.photo);
    axios
      .put(`https://api.maharashtraseva.com/api/Age_Nashnality_photo/${id}`, formData)
      .then((res) => console.log("form16", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadtaxBillOr15yerOldLightbill = (id) => {
    const formData = new FormData();
    formData.append("taxBill", docs.taxBillOr15yerOldLightbill);
    axios
      .put(`https://api.maharashtraseva.com/api/Age_Nashnality_taxBill/${id}`, formData)
      .then((res) => console.log("taxBillOr15yerOldLightbill", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadselfDeclearation = (id) => {
    const formData = new FormData();
    formData.append("selfDeclaretion", docs.selfDeclaration);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_selfDeclaretion/${id}`,
        formData
      )
      .then((res) => console.log("selfDeclearation", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadFatherAdhar = (id) => {
    const formData = new FormData();
    formData.append("FatherAdhar", docs.FatherAdhar);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_FatherAdhar/${id}`,
        formData
      )
      .then((res) => console.log("FatherAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadchildrenAdhar = (id) => {
    const formData = new FormData();
    formData.append("childrenAdhar", docs.childrenAdhar);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_childrenAdhar/${id}`,
        formData
      )
      .then((res) => console.log("FatherAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadapplicantPhoto = (id) => {
    const formData = new FormData();
    formData.append("applicantPhoto", docs.applicantPhoto);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_applicantPhoto/${id}`,
        formData
      )
      .then((res) => console.log("applicantPhoto", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadbenificiarryPhoto = (id) => {
    const formData = new FormData();
    formData.append("benificiarryPhoto", docs.benificiarryPhoto);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_benificiarryPhoto/${id}`,
        formData
      )
      .then((res) => console.log("benificiarryPhoto", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadfatherSchoolLeaveCer = (id) => {
    const formData = new FormData();
    formData.append("fatherSchoolLeaveCer", docs.fatherSchoolLeaveCer);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_fatherSchoolLeaveCer/${id}`,
        formData
      )
      .then((res) => console.log("fatherSchoolLeaveCer", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadchildrenSchoolLeaveCer = (id) => {
    const formData = new FormData();
    formData.append("childrenSchoolLeaveCer", docs.childrenSchoolLeaveCer);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_childrenSchoolLeaveCer/${id}`,
        formData
      )
      .then((res) => console.log("childrenSchoolLeaveCer", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadotherFirst = (id) => {
    const formData = new FormData();
    formData.append("otherFirst", docs.otherFirst);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_otherFirst/${id}`,
        formData
      )
      .then((res) => console.log("otherFirst", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadotherSecond = (id) => {
    const formData = new FormData();
    formData.append("otherSecond", docs.otherSecond);
    axios
      .put(
        `https://api.maharashtraseva.com/api/Age_Nashnality_otherSecond/${id}`,
        formData
      )
      .then((res) => console.log("otherSecond", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id != undefined) {
      axios
        .get(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`)
        .then((res) => {
          const data = res.data[0];
          // console.log('datas',data.benificiary_father_details.benificiaryFather_fullName_english)
          SetData(data.Data);
          SetEducationDetailsArray(data.EducationDetailsArray);
          SetMigrationBeneficiaryArray(data.MigrationBeneficiaryArray);
          SetFMemberBeneficiaryArray(data.FMemberBeneficiaryArray);
          if (
            data?.isNew == true &&
            data?.status == "REJECTED" &&
            userData.user.role === "retailer"
          ) {
            axios
              .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, {
                isNew: false,
              })
              .then((res) => {
                dispatch(incNumber());
              })
              .catch((err) => console.log(err));
          }
        });
    }
  }, [id]);

  const editHandler = (e) => {
    const obj = {
      application_type: "EWS",
      status: "IN-PROGRESS",
      createdBy: ID,
      createdByName,
      Data,
      EducationDetailsArray,
      MigrationBeneficiaryArray,
      FMemberBeneficiaryArray,
    };

    if (agree) {
      const mainDataPromise = new Promise((resolve, reject) => {
        axios
          .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, obj)
          .then((res) => {
            const response = res;
            resolve({
              status: true,
              message: "data Posted Successfully",
              data: res.data,
            });
          })
          .catch((err) => {
            console.log(err);
            reject({
              status: false,
              message: "Data Not posted",
            });
          });
      });

      mainDataPromise
        .then((res) => {
          console.log(124, res.data);

          uploadreshaCard(res.data.data._id);
          // uploadadharCard(res.data.data._id);
          uploadlightBill(res.data.data._id);
          uploadschoolLeaveCertificate(res.data.data._id);
          // uploadphoto(res.data.data._id);
          uploadtaxBillOr15yerOldLightbill(res.data.data._id);
          uploadselfDeclearation(res.data.data._id);
          uploadotherSecond(res.data.data._id);
          uploadotherFirst(res.data.data._id);
          uploadchildrenSchoolLeaveCer(res.data.data._id);
          uploadfatherSchoolLeaveCer(res.data.data._id);
          uploadbenificiarryPhoto(res.data.data._id);
          uploadapplicantPhoto(res.data.data._id);
          uploadchildrenAdhar(res.data.data._id);
          uploadFatherAdhar(res.data.data._id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [self, setSelf] = useState(false);
  const addSelfData = (dorpName) => {
    if (dorpName.toString().toLowerCase() == "self") {
      setSelf(true);
      SetData((prevState) => {
        return {
          ...prevState,
          benificiary_NameStatus: prevState.statusfname,
          benificiary_Name: prevState.fullName_English,
          benificiary_DOB: prevState.BirthDate,
          Benificiary_Phoneno: prevState.phoneNUm,
        };
      });
    } else {
      SetData((prevState) => {
        return {
          ...prevState,
          benificiary_NameStatus: "",
          benificiary_Name: "",
          benificiary_DOB: "",
          Benificiary_Phoneno: "",
        };
      });
    }
  };
  const addPreviousAddress = () => {
    SetBrithAddress(false);
    SetData((prevState) => {
      return {
        ...prevState,
        BirthDetails_address: prevState.address,
        BirthDetails_Building: prevState.Building,
        BirthDetails_streetName: prevState.street,
        BirthDetails_locality: prevState.Locality,
        BirthDetails_district: prevState.district,
        BirthDetails_village: prevState.village,
        BirthDetails_pincode: prevState.pincode,
        BirthDetails_landmark: prevState.landmark,
        BirthDetails_tehsil: prevState.taluka,
      };
    });
  };
  const setTalukasData = (e) => {
    console.log(340, e);
    if (e) {
      const taluka = districtData.filter((item) => item.name === e);
      console.log(597, taluka[0].tahasil);
      setTalukas(taluka[0].tahasil);
      // console.log(340,taluka[0].tahasil);
    } else {
      console.log("Flop");
    }
  };
  const [componentSize, setComponentSize] = useState("default");
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  return (
    <>
      <Breadcrumb
        title={" Age Nationality and Domicile :"}
        parent={"Certificate"}
      />
      <Container fluid={true}>
        <h3>Form Price :{GetFormPrice ? GetFormPrice : "0"}</h3>
        <Row>
          <Col sm="">
            <Card>
              <CardBody className="">
                {/* Certificate Name */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div
                      className="col-lg-12 text-center border rounded"
                      style={{ backgroundColor: "#e0e0e0" }}
                    >
                      <h3 class="box-title">
                        <span id="CPH_lbl_AppDtl">Certificate Name</span>
                      </h3>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Certificate Name
                          <span className="red">*</span>
                        </label>
                        <Form.Item className="">
                          <Select
                            // className="form-control"
                            // style={{padding:"10px"}}
                            placeholder="--select--"
                            value={Data.docName}
                            onChange={(e) => {
                              SetData({ ...Data, docName: e });
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select Certificate Name--
                            </Select.Option>
                            <Select.Option value="Certificate of Age,Nationality and Domicile">
                              Certificate of Age,Nationality and Domicile
                            </Select.Option>
                            <Select.Option value="Certificate of Age and Domicile">
                              Certificate of Age and Domicile
                            </Select.Option>
                            <Select.Option value="Certificate of Age and Nationality">
                              Certificate of Age and Nationality
                            </Select.Option>
                          </Select>
                          <p className="red">{Formerror.docName}</p>
                        </Form.Item>
                        {/* <Form.Item label="Select">
                          
        <Select
        className="form-control"
        >
          <Select.Option value="demo">Demo</Select.Option>
        </Select>
        </Form.Item> */}
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Applicant Details */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Applicant Details</span>
                        </h3>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-5">
                          {" "}
                          Salutation<span className="red"></span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={Data.statusfname}
                            onChange={(e) => {
                              SetData({ ...Data, statusfname: e });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Salutation--
                            </Select.Option>
                            {statusfname.map((row, index) => (
                              <option value={row} key={index}>
                                {row}
                              </option>
                            ))}
                          </Select>
                          <p className="red">{Formerror.statusfname}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Full Name (English) <span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Full Name!"
                            value={Data.fullName_English}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                fullName_English: e.target.value,
                              });
                            }}
                            onBlur={clickHandler}
                          />
                          <p className="red">{Formerror.fullName_English}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Full Name (Marathi) <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Full Name!"
                            value={Data.fullName_Marathi}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                fullName_Marathi: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red"></span> Salutation
                        </label>

                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            // value={Data.statusfname}
                            // onChange={(e) => {
                            //   SetData({ ...Data, statusfname: e });
                            // }}
                          >
                            <Select.Option value="" key="default">
                              --Select --
                            </Select.Option>
                            <Select.Option value="Mr">Mr.</Select.Option>
                            <Select.Option value="Shri">Shri</Select.Option>
                          </Select>
                          <p className="red">{Formerror.statusfname}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Father's Name(English) <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full father  Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Full father  Name!"
                            value={Data.fatherName_english}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                fatherName_english: e.target.value,
                              });
                            }}
                            onBlur={clickHandler1}
                          />
                          <p className="red">{Formerror.fatherName_english}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Father's Name (Marathi)<span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Father Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Father Full Name"
                            value={Data.fatherName_marathi}
                            onChange={(e) => {
                              setfnameMarathi(e.target.value);
                              SetData({
                                ...Data,
                                fatherName_marathi: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Date Of Birth
                        </label>

                        <Form.Item>
                          <DatePicker
                            onChange={handleDateChange}
                            format="DD-MM-YYYY"
                          />
                          <p className="red">{Formerror.BirthDate}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Age <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Age!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="number"
                            placeholder="Please input your age"
                            value={Data.age}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                age: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.age}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Gender <span className="red">*</span>
                        </label>

                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={Data.Gender}
                            onChange={(e) => {
                              SetData({ ...Data, Gender: e });
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select Gender--
                            </Select.Option>
                            <Select.Option value="Male">Male</Select.Option>
                            <Select.Option value="Female">Female</Select.Option>
                            <Select.Option value="Other">Other</Select.Option>
                          </Select>
                          <p className="red">{Formerror.Gender}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Occupation<span className="red">*</span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={Data.work_type}
                            onChange={(e) => {
                              SetData({ ...Data, work_type: e });
                              // SetData({ ...Data, Gender: e });
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select work Type--
                            </Select.Option>
                            {work_type.map((row, index) => {
                              return (
                                <>
                                  <Select.Option value={row} key={index}>
                                    {row}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                          <p className="red">{Formerror.work_type}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Mobile No <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Mobile No!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="number"
                            id="mobileNo"
                            name="mobile"
                            maxLength="10"
                            defaultValue={Data?.mobile || ""}
                            placeholder="Please input your Mobile No!"
                            value={Data.phoneNUm}
                            onChange={(e) => {
                              // SetData({
                              //   ...Data,
                              //   phoneNUm: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 10) {
                                SetData({
                                  ...Data,
                                  phoneNUm: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Phone Number");
                                SetData({
                                  ...Data,
                                  phoneNUm: "", // Reset the adharNumber if it's too long
                                });
                              }
                              // setData({ ...Data, mobileNO: e.target.value });
                            }}
                          />
                          <p className="red">{Formerror.phoneNUm}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Email
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your  Email!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            id="emailId"
                            name="emailID"
                            defaultValue={Data?.emailID || ""}
                            placeholder="Please input your  Email!"
                            value={Data.email}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                email: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Adhar UID No.
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your UID!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            id="adhaarUIDNo"
                            name="adhaarUIDNO"
                            defaultValue={Data?.adhaarUIDNO || ""}
                            type="Number"
                            placeholder="Please input your UID!"
                            value={Data.AdharNo}
                            onChange={(e) => {
                              // SetData({
                              //   ...Data,
                              //   AdharNo: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 12) {
                                SetData({
                                  ...Data,
                                  AdharNo: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Phone Number");
                                SetData({
                                  ...Data,
                                  AdharNo: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Residence Details */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Residence Details</span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Address<span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Address!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="text"
                            name="address"
                            placeholder="Please input your Address!"
                            value={Data.address}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                address: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.address}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Street
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Street!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="text"
                            name="streetName"
                            placeholder="Please input your Street Name!"
                            value={Data.street}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                street: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.street}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Building
                        </label>

                        <Form.Item>
                          <Input
                            className="p-2"
                            type="text"
                            name="buildingNumber"
                            placeholder="Please input your building number!"
                            value={Data.Building}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                Building: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.Building}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Locality
                        </label>

                        <Form.Item>
                          <Input
                            className="p-2"
                            type="text"
                            name="locality"
                            placeholder="Please input your locality!"
                            value={Data.Locality}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                Locality: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Landmark
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Land Mark!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            id="landMark"
                            type="text"
                            name="LandMark"
                            placeholder="Please input your LandMark!"
                            value={Data.landmark}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                landmark: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span> District
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your District!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="--Select District--"
                            value={Data.district}
                            onChange={(e) => {
                              SetData({ ...Data, district: e });
                              setTalukasData(e);
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select District --
                            </Select.Option>
                            {districtData.map((item, i) => {
                              return (
                                <>
                                  <Select.Option value={item.name}>
                                    {item.name}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                          <p className="red">{Formerror.district}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Taluka <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Taluka!",
                            },
                          ]}
                        >
                          <Select
                            value={Data.taluka}
                            placeholder="--Select Taluka--"
                            onChange={(e) => {
                              SetData({ ...Data, taluka: e });
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select Taluka--
                            </Select.Option>
                            {talukas.map((item, i) => {
                              return (
                                <>
                                  <Select.Option value={item}>
                                    {item}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                          <p className="red">{Formerror.taluka}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Village <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Village!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Village!"
                            value={Data.village}
                            onChange={(e) => {
                              SetData({ ...Data, village: e.target.value });
                            }}
                          />
                          <p className="red">{Formerror.village}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Pincode <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your pincode!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="number"
                            placeholder="Please input your pincode"
                            value={Data.pincode}
                            onChange={(e) => {
                              // SetData({
                              //   ...Data,
                              //   pincode: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 6) {
                                SetData({
                                  ...Data,
                                  pincode: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Pincode");
                                SetData({
                                  ...Data,
                                  pincode: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                          <p style={{ color: "#ff0000" }}>
                            {Formerror.pincode}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Residing at present address since
                        </label>
                        <span className="red">*</span>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input  Residing !",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            // defaultValue={moment(new Date()).format("YYYY")}
                            type="Number"
                            placeholder="please Residing at present address since"
                            value={Data.onAddressLiveing}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                onAddressLiveing: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.onAddressLiveing}</p>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Beneficiary Relation */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Beneficiary Relation</span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Years of residence at current address{" "}
                          <span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input residing !",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={Data.currentlivingInyear}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                currentlivingInyear: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.currentlivingInyear}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Applicant residing in Maharashtra mini 15y
                          <span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input residing !",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={Data.Applicunt_Live_In_MH_Inyear}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                Applicunt_Live_In_MH_Inyear: e.target.value,
                              });
                            }}
                          />
                          <p className="red">
                            {Formerror.Applicunt_Live_In_MH_Inyear}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Relation of Applicant with Beneficiary
                          <span className="red">*</span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={Data.relationApplicant_beneficiary}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                relationApplicant_beneficiary: e,
                              });
                              addSelfData(e);
                            }}
                          >
                            {relationApplicant_beneficiary.map((row, index) => (
                              <option value={row} key={index}>
                                {row}
                              </option>
                            ))}
                          </Select>
                          <p className="red">
                            {Formerror.relationApplicant_beneficiary}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Beneficiary Full Name <span className="red">*</span>
                        </label>
                        <div className="row">
                          <div className="col-lg-4">
                            <Form.Item>
                              <Select
                                placeholder="--select--"
                                value={Data.benificiary_NameStatus}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    benificiary_NameStatus: e,
                                  });
                                }}
                              >
                                <Select.Option value="Kumar">
                                  Kumar
                                </Select.Option>
                                <Select.Option value="Advocate">
                                  Advocate
                                </Select.Option>
                                <Select.Option value="Shri">Mr.</Select.Option>
                                <Select.Option value="Kumari">
                                  Kumari
                                </Select.Option>
                                <Select.Option value="Ms">Ms</Select.Option>
                                <Select.Option value="Shrimati">
                                  Shrimati
                                </Select.Option>
                                <Select.Option value="Mrs">Mrs</Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-lg-8">
                            <Form.Item>
                              <Input
                                className="p-2"
                                value={Data.benificiary_Name}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    benificiary_Name: e.target.value,
                                  });
                                }}
                              />
                              <p className="red">
                                {Formerror.benificiary_Name}
                              </p>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Date Of Birth
                        </label>

                        <Form.Item>
                          <DatePicker
                            // value={Data.benificiary_DOB}
                            onChange={ChangeDate}
                            format="DD-MM-YYYY"
                          />
                          <p className="red">{Formerror.benificiary_DOB}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Mobile <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please Mobile!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={Data.Benificiary_Phoneno}
                            onChange={(e) => {
                              // SetData({
                              //   ...Data,
                              //   Benificiary_Phoneno: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 10) {
                                SetData({
                                  ...Data,
                                  Benificiary_Phoneno: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Phone Number");
                                SetData({
                                  ...Data,
                                  Benificiary_Phoneno: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Birth Detail */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Birth Detail</span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-12">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Birth Address as above?
                        </label>
                        <Form.Item>
                          <Radio.Group>
                            <Radio
                              value="yes"
                              onClick={(e) => {
                                addPreviousAddress();
                                SetData({
                                  ...Data,
                                  BirthDetailsAsAbove: e.target.value,
                                });
                              }}
                            >
                              Yes
                            </Radio>
                            <Radio
                              value="No"
                              onClick={(e) => {
                                SetBrithAddress(true);
                                SetData({
                                  ...Data,
                                  BirthDetailsAsAbove: e.target.value,
                                });
                              }}
                            >
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>{" "}
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Birth Detail Child*/}
                {BrithAddress && (
                  <>
                    <Form name="basic" autoComplete="off" layout={"vertical"}>
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-12 text-center border rounded"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Birth Detail (In case you select any country,
                                apart from India, mention complete address in
                                columns for District, Tehsil and village.)
                              </span>
                            </h3>
                          </div>{" "}
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Address <span className="red">*</span>
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Address!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={Data.BirthDetails_address}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    BirthDetails_address: e.target.value,
                                  });
                                }}
                              />
                              <p className="red">
                                {Formerror.BirthDetails_address}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Street
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Street!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={Data.BirthDetails_streetName}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    BirthDetails_streetName: e.target.value,
                                  });
                                }}
                              />
                              <p className="red">
                                {Formerror.BirthDetails_streetName}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Building
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Building!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={Data.BirthDetails_Building}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    BirthDetails_Building: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Locality
                            </label>

                            <Form.Item>
                              <Input
                                className="p-2"
                                value={Data.BirthDetails_locality}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    BirthDetails_locality: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Landmark
                              <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Country!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={Data.BirthDetails_Country}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    BirthDetails_Country: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span> District
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your District!",
                                },
                              ]}
                            >
                              {/* <Select
                                placeholder="--Select District--"
                                value={Data.BirthDetails_district}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    BirthDetails_district: e,
                                  });
                                }}
                              >
                                <Select.Option value="Sindhudurg">
                                  Sindhudurg
                                </Select.Option>
                                <Select.Option value="Solapur">
                                  Solapur
                                </Select.Option>
                                <Select.Option value="Thane">
                                  Thane
                                </Select.Option>
                                <Select.Option value="Wardha">
                                  Wardha
                                </Select.Option>
                                <Select.Option value="Washim">
                                  Washim
                                </Select.Option>
                                <Select.Option value="Yavatmal">
                                  Yavatmal
                                </Select.Option>
                              </Select> */}
                              <Select
                                placeholder="--Select District--"
                                value={Data.BirthDetails_district}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    BirthDetails_district: e,
                                  });
                                  setTalukasData(e);
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select District --
                                </Select.Option>
                                {districtData.map((item, i) => {
                                  return (
                                    <>
                                      <Select.Option value={item.name}>
                                        {item.name}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Taluka <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Taluka!",
                                },
                              ]}
                            >
                              {/* <Select
                                placeholder="--Select Taluka--"
                                value={Data.BirthDetails_tehsil}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    BirthDetails_tehsil: e,
                                  });
                                }}
                              >
                                <Select.Option value="Bhor">Bhor</Select.Option>
                              </Select> */}
                              <Select
                                value={Data.BirthDetails_tehsil}
                                placeholder="--Select Taluka--"
                                onChange={(e) => {
                                  SetData({ ...Data, BirthDetails_tehsil: e });
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select Taluka--
                                </Select.Option>
                                {talukas.map((item, i) => {
                                  return (
                                    <>
                                      <Select.Option value={item}>
                                        {item}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Village <span className="red">*</span>
                            </label>

                            {/* <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Village!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="--Select Village--"
                                value={Data.BirthDetails_village}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    BirthDetails_village: e,
                                  });
                                }}
                              >
                                <Select.Option
                                  value=" sangvi
"
                                >
                                  sangvi
                                </Select.Option>
                              </Select>
                            </Form.Item> */}
                            <Input
                              className="p-2"
                              value={Data.BirthDetails_village}
                              onChange={(e) => {
                                SetData({
                                  ...Data,
                                  BirthDetails_village: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Pincode <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Pincode!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                type="Number"
                                value={Data.BirthDetails_pincode}
                                onChange={(e) => {
                                  // SetData({
                                  //   ...Data,
                                  //   BirthDetails_pincode: e.target.value,
                                  // });
                                  const value = e.target.value;
                                  // Remove non-numeric characters
                                  const sanitizedValue = value.replace(/\D/g,"");

                                  // Check if the input is a valid phone number (exactly 10 digits)
                                  if (sanitizedValue.length <= 6) {
                                    SetData({
                                      ...Data,
                                      BirthDetails_pincode: sanitizedValue,
                                    });
                                  } else {
                                    alert("Please Enter Valid Phone Number");
                                    SetData({
                                      ...Data,
                                      BirthDetails_pincode: "", // Reset the adharNumber if it's too long
                                    });
                                  }
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </>
                )}

                {/* Education Details of Beneficiary */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            Education Details of Beneficiary{" "}
                          </span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Education Details of Beneficiary{" "}
                          <span className="red">*</span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={EducationDetails.begree}
                            onChange={(e) => {
                              SetEducationDetails({
                                ...EducationDetails,
                                begree: e,
                              });
                            }}
                          >
                            <Select.Option value="Below SSC">
                              Below SSC
                            </Select.Option>
                            <Select.Option value="Diploma">
                              Diploma
                            </Select.Option>
                            <Select.Option value="Graduate">
                              Graduate
                            </Select.Option>
                            <Select.Option value="HSC">HSC</Select.Option>
                            <Select.Option value="Illiterate">
                              Illiterate
                            </Select.Option>
                            <Select.Option value="ITI">ITI</Select.Option>
                            <Select.Option value="Post Graduate">
                              Post Graduate
                            </Select.Option>
                            <Select.Option value="SSC">SSC</Select.Option>
                          </Select>
                          <p className="red m-2">{EDDetails.begree}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          University / Board<span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input Enter  University!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={EducationDetails.organizationName}
                            onChange={(e) => {
                              SetEducationDetails({
                                ...EducationDetails,
                                organizationName: e.target.value,
                              });
                            }}
                          />
                          <p className="red m-2">
                            {EDDetails.organizationName}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Admission Year <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input Enter Admission year!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={EducationDetails.AddmisionYear}
                            onChange={(e) => {
                              SetEducationDetails({
                                ...EducationDetails,
                                AddmisionYear: e.target.value,
                              });
                            }}
                          />
                          <p className="red m-2">{EDDetails.AddmisionYear}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red"></span> Completion Year
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input Enter Completion year!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={EducationDetails.leaveYear}
                            onChange={(e) => {
                              SetEducationDetails({
                                ...EducationDetails,
                                leaveYear: e.target.value,
                              });
                            }}
                          />
                          <p className="red m-2">{EDDetails.leaveYear}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red"></span> Institute Details
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input Enter Institute Details!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={EducationDetails.EducationPlace}
                            onChange={(e) => {
                              SetEducationDetails({
                                ...EducationDetails,
                                EducationPlace: e.target.value,
                              });
                            }}
                          />
                          <p className="red m-2">{EDDetails.EducationPlace}</p>
                        </Form.Item>
                      </div>
                      <div className="row text-center">
                        <div className="col-md-12">
                          <Form.Item>
                            <Button
                              className="btn btn-success"
                              onClick={(e) => {
                                e.preventDefault();
                                const EdError =
                                  ValidateEDDetails(EducationDetails);
                                if (Object.keys(EdError).length === 0) {
                                  const find = EducationDetailsArray.find(
                                    (item) =>
                                      item.organizationName ===
                                      EducationDetails.organizationName
                                  );
                                  if (!find) {
                                    SetEducationDetailsArray([
                                      ...EducationDetailsArray,
                                      EducationDetails,
                                    ]);
                                  }
                                } else {
                                  setEDDetails(EdError);
                                  swal(
                                    "Please Fill All Education Details",
                                    "error"
                                  );
                                }
                              }}
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </div>
                        {EducationDetailsArray.map((row) => (
                          <>
                            <div class="col-md-12 table-responsive">
                              <table class="table table-striped">
                                <tbody>
                                  {/* <tr>Dgree</tr>
                                  <tr></tr> */}
                                  <tr>
                                    <th>
                                      Dgree <br />
                                      {row.begree}
                                    </th>
                                    <th>
                                      University / Board <br />
                                      {row.organizationName}
                                    </th>
                                    <th>
                                      {" "}
                                      Admission Year <br />
                                      {row.AddmisionYear}
                                    </th>
                                    <th>
                                      Completion Year <br />
                                      {row.leaveYear}
                                    </th>
                                    <th>
                                      Institute Details <br />
                                      {row.EducationPlace}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Has beneficiary migrated from a different state? (Yes/No) */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            Has beneficiary migrated from a different state?
                            (Yes/No)
                          </span>
                        </h3>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Has beneficiary migrated
                          from a different state? (Yes/No)
                        </label>
                        <Form.Item>
                          <Radio.Group>
                            <Radio
                              value="yes"
                              onClick={(e) => setMigratedDetails(true)}
                            >
                              Yes
                            </Radio>
                            <Radio
                              value="No"
                              onClick={(e) => setMigratedDetails(false)}
                            >
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>{" "}
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Has beneficiary migrated from a different state? (Yes/No) */}
                {MigratedDetails && (
                  <>
                    {/* Migration Address Details */}
                    <Form name="basic" autoComplete="off" layout={"vertical"}>
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Migration Address Details
                              </span>
                            </h3>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Years of residence in the state of Maharashtra
                              <span className="red">*</span>
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Migrated year to maharashtra!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                type="number"
                                placeholder="Enter Years of residence in the state of Maharashtra!"
                                value={MigrationBeneficiary.migrationToMHYear}
                                onChange={(e) => {
                                  SetMigrationBeneficiary({
                                    ...MigrationBeneficiary,
                                    migrationToMHYear: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Family's native place before migration to
                              Maharashtra
                              <span className="red">*</span>
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your District!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                placeholder="Enter Family's native place before migration to Maharashtra"
                                value={
                                  MigrationBeneficiary.BeforeMigrationLocation
                                }
                                onChange={(e) => {
                                  SetMigrationBeneficiary({
                                    ...MigrationBeneficiary,
                                    BeforeMigrationLocation: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Reason for migration
                              <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: " Enter Reason of migration!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                placeholder="Enter Reason for migration"
                                value={MigrationBeneficiary.reasonOfMigration}
                                onChange={(e) => {
                                  SetMigrationBeneficiary({
                                    ...MigrationBeneficiary,
                                    reasonOfMigration: e.target.value,
                                  });
                                }}
                              />
                              <p className="red">
                                {Formerror.reasonOfMigration}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Migration place <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Place of Migration!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                placeholder="Enter Place of Migration ! "
                                value={MigrationBeneficiary.placeOfMigration}
                                onChange={(e) => {
                                  SetMigrationBeneficiary({
                                    ...MigrationBeneficiary,
                                    placeOfMigration: e.target.value,
                                  });
                                }}
                              />
                              <p className="red">
                                {Formerror.placeOfMigration}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              From Date
                              <span className="red">*</span>
                            </label>

                            <Form.Item>
                              <DatePicker
                                onChange={handalStartDate}
                                format="DD-MM-YYYY"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              To Date
                              <span className="red">*</span>
                            </label>
                            <Form.Item>
                              <DatePicker
                                onChange={handalEndDate}
                                format="DD-MM-YYYY"
                              />
                            </Form.Item>{" "}
                          </div>
                          {/* <div className="col-md-4">
                           <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Migration-End-Year{" "}
                            </label>

                            <Form.Item>
                              <Input
className="p-2"
                                value={MigrationBeneficiary.MigrationEndYea}
                                onChange={(e) => {
                                  SetMigrationBeneficiary({
                                    ...MigrationBeneficiary,
                                    MigrationEndYea: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div> */}
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <Form.Item>
                                <Button
                                  className="btn btn-success"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const find = MigrationBeneficiaryArray.find(
                                      (item) =>
                                        item.BeforeMigrationLocation ===
                                        MigrationBeneficiary.BeforeMigrationLocation
                                    );
                                    if (!find) {
                                      SetMigrationBeneficiaryArray([
                                        ...MigrationBeneficiaryArray,
                                        MigrationBeneficiary,
                                      ]);
                                    }
                                  }}
                                >
                                  Add
                                </Button>
                              </Form.Item>
                            </div>
                            {MigrationBeneficiaryArray.map((row) => (
                              <>
                                <div class="col-md-12 table-responsive">
                                  <table
                                    class="table table-striped"
                                    id="tdRelationList"
                                  >
                                    <tbody>
                                      <tr>
                                        <th align="center">
                                          {" "}
                                          Migration-To-MHYear
                                          <td>
                                            <h5>{row.migrationToMHYear}</h5>
                                          </td>
                                        </th>
                                        <th align="center">
                                          Before-Migration-Location
                                          <td>
                                            <h5>
                                              {row.BeforeMigrationLocation}
                                            </h5>
                                          </td>
                                        </th>
                                        <th align="center">
                                          {" "}
                                          Reason-Of-Migration
                                          <td>
                                            <h5>{row.reasonOfMigration}</h5>
                                          </td>
                                        </th>
                                        <th align="center">
                                          {" "}
                                          Place-Of-Migration
                                          <td>
                                            <h5>{row.placeOfMigration}</h5>
                                          </td>
                                        </th>
                                        <th align="center">
                                          {" "}
                                          Migration-Start-Year
                                          <td>
                                            <h5>{row.MigrationStartYear}</h5>
                                          </td>
                                        </th>
                                        <th align="center">
                                          {" "}
                                          Migration-End-Year
                                          <td>
                                            <h5>{row.MigrationEndYear}</h5>
                                          </td>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Form>

                    {/* Details of movable property, belong to Applicant/Father/husband in the state of Maharashtra */}
                    <Form name="basic" autoComplete="off" layout={"vertical"}>
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Details of movable property, belong to
                                Applicant/Father/husband in the state of
                                Maharashtra
                              </span>
                            </h3>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span> Details of movable
                              property, belong to Applicant/Father/husband in
                              the state of Maharashtra
                            </label>
                            {/* <div>
                                      Details of movable property, belong to
                                      Applicant/Father/husband in the state of
                                      Maharashtra *
                                    </div> */}
                            <Form.Item>
                              <Radio.Group>
                                <Radio
                                  value="yes"
                                  onClick={() => SetMoveableProperty(true)}
                                >
                                  Yes
                                </Radio>
                                <Radio
                                  value="No"
                                  onClick={() => SetMoveableProperty(false)}
                                >
                                  No
                                </Radio>
                              </Radio.Group>
                            </Form.Item>{" "}
                          </div>
                        </div>
                      </div>
                    </Form>
                    {/* Property Details */}
                    {MoveableProperty && (
                      <>
                        <Form
                          name="basic"
                          autoComplete="off"
                          layout={"vertical"}
                        >
                          <div
                            className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                            style={{ backgroundColor: "#f9f9f9" }}
                          >
                            <div className="row">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Property Details
                                  </span>
                                </h3>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                                  {" "}
                                  Address <span className="red">*</span>
                                </label>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your Address!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="p-2"
                                    type="text"
                                    placeholder="Please input your Address!"
                                    value={Data.Proparty_address}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Proparty_address: e.target.value,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                                  {" "}
                                  Street
                                </label>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your Street!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="p-2"
                                    name="street"
                                    type="text"
                                    placeholder="Please input your Street"
                                    value={Data.Proparty_street}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Proparty_street: e.target.value,
                                      });
                                    }}
                                  />
                                  <p className="red">
                                    {Formerror.Proparty_street}
                                  </p>
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                                  {" "}
                                  Building
                                </label>

                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your Building!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="p-2"
                                    name="building"
                                    type="number"
                                    placeholder="please input your Building!"
                                    value={Data.Proparty_Building}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Proparty_Building: e.target.value,
                                      });
                                    }}
                                  />
                                  <p className="red">
                                    {Formerror.Proparty_Building}
                                  </p>
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                                  {" "}
                                  Locality
                                </label>

                                <Form.Item>
                                  <Input
                                    className="p-2"
                                    name="locality"
                                    placeholder="please Enter Locality"
                                    value={Data.Proparty_locality}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Proparty_locality: e.target.value,
                                      });
                                    }}
                                  />
                                  <p className="red">
                                    {Formerror.Proparty_locality}
                                  </p>
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                                  {" "}
                                  Country
                                  <span className="red">*</span>
                                </label>

                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your Country!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="p-2"
                                    type="text"
                                    name="country"
                                    placeholder="Please input your Country!"
                                    value={Data.Proparty_Country}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Proparty_Country: e.target.value,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                                  {" "}
                                  <span className="red">*</span> District
                                </label>

                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your District!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="--Select District--"
                                    value={Data.Proparty_district}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Proparty_district: e,
                                      });
                                      setTalukasData(e);
                                    }}
                                    showSearch
                                  >
                                    <Select.Option value="" key="default">
                                      --Select District --
                                    </Select.Option>
                                    {districtData.map((item, i) => {
                                      return (
                                        <>
                                          <Select.Option value={item.name}>
                                            {item.name}
                                          </Select.Option>
                                        </>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                                  {" "}
                                  Taluka <span className="red">*</span>
                                </label>

                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your Taluka!",
                                    },
                                  ]}
                                >
                                  <Select
                                    value={Data.Proparty_Tehsil}
                                    placeholder="--Select Taluka--"
                                    onChange={(e) => {
                                      SetData({ ...Data, Proparty_Tehsil: e });
                                    }}
                                    showSearch
                                  >
                                    <Select.Option value="" key="default">
                                      --Select Taluka--
                                    </Select.Option>
                                    {talukas.map((item, i) => {
                                      return (
                                        <>
                                          <Select.Option value={item}>
                                            {item}
                                          </Select.Option>
                                        </>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                                  {" "}
                                  Village <span className="red">*</span>
                                </label>

                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your Village!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="p-2"
                                    placeholder="Please input your Village!"
                                    value={Data.Proparty_village}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Proparty_village: e.target.value,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                                  {" "}
                                  Pincode <span className="red">*</span>
                                </label>

                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your Pincode!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="p-2"
                                    placeholder="Please input your pin code"
                                    type={"number"}
                                    value={Data.Proparty_pincode}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Proparty_pincode: e.target.value,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                    {/* Property Details */}
                    <Form name="basic" autoComplete="off" layout={"vertical"}>
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">Property Details:</span>
                            </h3>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red"></span> Property Holder
                              Relation with Beneficiary
                            </label>

                            <Form.Item>
                              <Select
                                placeholder="--select--"
                                value={Data.Benificiary_propartyHolder_Relation}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    Benificiary_propartyHolder_Relation: e,
                                  });
                                }}
                              >
                                {relationApplicant_beneficiary.map(
                                  (row, index) => (
                                    <option value={row} key={index}>
                                      {row}
                                    </option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Property Detail{" "}
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your property detail!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={Data.Benificiary_propartyDetails}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    Benificiary_propartyDetails: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Beneficiary's father's / husband's place of
                              residence
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter input ",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={Data.Benificiary_Father_HusRecidance}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    Benificiary_Father_HusRecidance:
                                      e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              At the time of birth of the beneficiary, place of
                              residence of father{" "}
                            </label>

                            <Form.Item>
                              <Input
                                className="p-2"
                                value={Data.Benificiary_FatherRecidaceOfBirth}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    Benificiary_FatherRecidaceOfBirth:
                                      e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              If Beneficiary's father / husband is staying
                              outside Maharashtra, then place of residence{" "}
                            </label>

                            <Form.Item>
                              <Input
                                className="p-2"
                                value={
                                  Data.isBenificiary_FatherRecidaceOfBirth_outOf_MH
                                }
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    isBenificiary_FatherRecidaceOfBirth_outOf_MH:
                                      e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Place of employment / enrolment for scheme if any{" "}
                            </label>

                            <Form.Item>
                              <Input
                                className="p-2"
                                value={Data.placeOfEmploment}
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    placeOfEmploment: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </>
                )}

                {/* Whether applicant is beneficiary of government scheme in other district? If yes, provide the details of the place. */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            {" "}
                            Whether applicant is beneficiary of government
                            scheme in other district? If yes, provide the
                            details of the place.
                          </span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-12">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Whether applicant is beneficiary of government scheme
                          in other district? If yes, provide the details of the
                          place
                          <span className="red">*</span>
                        </label>
                        <Form.Item>
                          <Radio.Group>
                            <Radio
                              value="yes"
                              onClick={() => SetOtherstate(true)}
                            >
                              Yes
                            </Radio>
                            <Radio
                              value="No"
                              onClick={() => SetOtherstate(false)}
                            >
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>{" "}
                      </div>
                    </div>
                  </div>
                </Form>

                {Otherstate && (
                  <>
                    <Form name="basic" autoComplete="off" layout={"vertical"}>
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Whether applicant is beneficiary of government
                                scheme in other district? If yes, provide the
                                details of the place.
                              </span>
                            </h3>
                          </div>{" "}
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span> District
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your District!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="--Select District--"
                                value={
                                  Data.ApllicantBenificiaryOtherState_district
                                }
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    ApllicantBenificiaryOtherState_district: e,
                                  });
                                  setTalukasData(e);
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select District --
                                </Select.Option>
                                {districtData.map((item, i) => {
                                  return (
                                    <>
                                      <Select.Option value={item.name}>
                                        {item.name}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Taluka <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Taluka!",
                                },
                              ]}
                            >
                              <Select
                                value={
                                  Data.ApllicantBenificiaryOtherState_Taluka
                                }
                                placeholder="--Select Taluka--"
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    ApllicantBenificiaryOtherState_Taluka: e,
                                  });
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select Taluka--
                                </Select.Option>
                                {talukas.map((item, i) => {
                                  return (
                                    <>
                                      <Select.Option value={item}>
                                        {item}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Village <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Village!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                placeholder="Please input your Village!"
                                value={
                                  Data.ApllicantBenificiaryOtherState_Village
                                }
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    ApllicantBenificiaryOtherState_Village:
                                      e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Pincode <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your pincode!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                type="number"
                                placeholder="Please input your pincode"
                                value={
                                  Data.ApllicantBenificiaryOtherState_pincode
                                }
                                onChange={(e) => {
                                  SetData({
                                    ...Data,
                                    ApllicantBenificiaryOtherState_pincode:
                                      e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </>
                )}

                {/* Family Member Details of Beneficiary (If he / she holds a valid Age, Domicile and Nationality Certificate) */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row mb-3">
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            Family Member Details of Beneficiary (If he / she
                            holds a valid Age, Domicile and Nationality
                            Certificate)
                          </span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-4 ">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Relation with Beneficiary
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={FMemberBeneficiary.relation_beneficiary}
                            onChange={(e) => {
                              SetFMemberBeneficiary({
                                ...FMemberBeneficiary,
                                relation_beneficiary: e,
                              });
                            }}
                          >
                            {relationApplicant_beneficiary.map((row, index) => (
                              <option value={row} key={index}>
                                {row}
                              </option>
                            ))}
                          </Select>
                        </Form.Item>{" "}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Family member name
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Family member name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={FMemberBeneficiary.familyMember_name}
                            onChange={(e) => {
                              SetFMemberBeneficiary({
                                ...FMemberBeneficiary,
                                familyMember_name: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Form.Item>
                            <Button
                              className="btn btn-success"
                              onClick={(e) => {
                                e.preventDefault();
                                const find = FMemberBeneficiaryArray.find(
                                  (item) =>
                                    item.relation_beneficiary ===
                                    FMemberBeneficiary.relation_beneficiary
                                );
                                if (!find) {
                                  SetFMemberBeneficiaryArray([
                                    ...FMemberBeneficiaryArray,
                                    FMemberBeneficiary,
                                  ]);
                                }
                              }}
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </div>
                        {FMemberBeneficiaryArray.map((row) => (
                          <>
                            <div class="col-md-12 table-responsive">
                              <table
                                class="table table-striped"
                                id="tdRelationList"
                              >
                                <tbody>
                                  <tr>
                                    {/* <th align="center" >Sr.no
                                <td>
                                    </td>
                                    </th> */}

                                    <th align="center">
                                      {" "}
                                      Relation_Beneficiary
                                      <td>
                                        <h5>{row.relation_beneficiary}</h5>
                                      </td>
                                    </th>
                                    <th align="center">
                                      {" "}
                                      Family-Member-name
                                      <td>
                                        <h5>{row.familyMember_name}</h5>
                                      </td>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Certificate */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Certificate</span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-mt-12">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Reason<span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input !",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={Data.CertificateReason}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                CertificateReason: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>
                <div className="row">
                  <div className="col-lg-12">
                  <a href="https://mhae-seva.s3.amazonaws.com/PanAccept/DOAMCILEE+SELFFF.pdf" target="_blank" className="fs-5"><b>स्वघोषणापत्र</b></a>
                  </div>
                </div>
                {/* Upload required documents: */}
                <div
                  className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  <div className="row">
                    <div
                      className="col-lg-12 text-center border rounded mb-3"
                      style={{ backgroundColor: "#e0e0e0" }}
                    >
                      <h3 class="box-title">
                        <span id="CPH_lbl_AppDtl">
                          Upload required documents
                        </span>
                      </h3>
                    </div>{" "}
                    <div className="col-lg-12">
                      <h6 className="red mb-3">
                        अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व आवश्यक
                        कागदपत्रे तपासून व स्कॅन करून अपलोड करावे. जर आवश्यक
                        कागदपत्रे चुकीची किंवा अस्पष्ट आढळल्यास सदर चा अर्ज
                        फेटाळला जाऊ शकतो.{" "}
                      </h6>
                    </div>
                    <p
                      className="red mt-2 fs-6 bolder"
                      style={{ color: "red" }}
                    >
                      <b style={{ color: "red" }}>
                        IMP NOTE :- १) अर्जदार व लाभार्थी महाराष्ट्रातील जन्म
                        असावा ,२) दोघांच्या शाळा सोडल्याच्या दाखल्यावर जन्म स्थळ
                        (BIRTH PLACE) नमूद असावे, ३)१५ वर्षा पूवीचे लाईट बिल
                        अनिवार्य. ४)वडील हयात नसल्यास मृत्यू दाखला अनिवार्य.
                      </b>
                    </p>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Father Adhar Card (Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            FatherAdhar: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.FatherAdhar}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Benificiary Adhar (Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            childrenAdhar: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">{FormFileerror.childrenAdhar}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Father School leaving
                        Certificate (Size - Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            fatherSchoolLeaveCer: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">
                        {FormFileerror.fatherSchoolLeaveCer}
                      </p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Benificiary School
                        Leaving Certificate (Size - Maximum 500 Kb) [Only
                        (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            childrenSchoolLeaveCer: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">
                        {FormFileerror.childrenSchoolLeaveCer}
                      </p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Electrical bill (Size -
                        Maximum 1 MB) [Only (jpg,jpeg,pdf)]{" "}
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            lightBill: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.lightBill}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Beficiary 15 Year Old
                        Light Bill /MSEB Bonafide (Size - Maximum 500 Kb) [Only
                        (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            taxBillOr15yerOldLightbill: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">
                        {FormFileerror.taxBillOr15yerOldLightbill}
                      </p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Self Decleration (Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            selfDeclaration: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.selfDeclaration}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Applicant Photo(Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            applicantPhoto: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">{FormFileerror.applicantPhoto}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Benificiary Photo(Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            benificiarryPhoto: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">{FormFileerror.benificiarryPhoto}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Ration Card (Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            reshanCard: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">{FormFileerror.reshanCard}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Other (Size - Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            otherFirst: e.target.files[0],
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Other|| (Size - Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            otherSecond: e.target.files[0],
                          });
                        }}
                      />
                    </div>
                    {/* <div className="col-md-6">
                      <a href="https://mhae-seva.s3.amazonaws.com/PanAccept/DOAMCILEE+SELFFF.pdf" target="_blank"><b>स्वघोषणापत्र</b></a>
                      </div> */}
                  </div>
                </div>

                {/* Aggrement */}
                <div
                  className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  <div className="row">
                    <div
                      className="col-lg-12 text-center border rounded mb-3"
                      style={{ backgroundColor: "#e0e0e0" }}
                    >
                      <h3 class="box-title">
                        <span id="CPH_lbl_AppDtl">Agreement</span>
                      </h3>
                    </div>
                    <Form name="basic" autoComplete="off" layout={"vertical"}>
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {" "}
                            <span className="red">*</span> I solemnly affirm
                            that the above mentioned information submitted by me
                            is true and correct to my knowledge and belief. I
                            hereby agree to be liable for legal consequences for
                            any information found incorrect or untrue at a later
                            date...
                          </label>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please fill that!",
                              },
                            ]}
                          >
                            <Radio.Group onChange={(e) => setAgree(true)}>
                              <Radio value="yes">I accept</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                {/* SubmitButton */}
                <div className="row">
                  <div class="col-md-12 text-center">
                    {showloading ? (
                      <>
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {id == undefined ? (
                          <input
                            type="submit"
                            name="ctl00$CPH$btnsubmit"
                            value="Proceed"
                            onClick={(e) => {
                              AgeSubmitdomicile(e);
                            }}
                            id="CPH_btnsubmit"
                            tabindex="141"
                            class="btn btn-success m-3"
                          />
                        ) : (
                          <>
                            <input
                              type="submit"
                              name="ctl00$CPH$btnsubmit"
                              value="Update"
                              onClick={(e) => {
                                editHandler(e);
                              }}
                              id="CPH_btnsubmit"
                              tabindex="141"
                              class="btn btn-success m-3"
                            />
                            <input
                              type="submit"
                              name="ctl00$CPH$btnback"
                              value="Back"
                              onclick='return back();WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions("ctl00$CPH$btnback", "", true, "", "", false, false))'
                              id="CPH_btnback"
                              tabindex="142"
                              class="btn btn-warning m-3"
                            />
                            <input
                              type="submit"
                              name="ctl00$CPH$btnreset"
                              value="Reset"
                              onclick='return disp_confirm();WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions("ctl00$CPH$btnreset", "", true, "", "", false, false))'
                              id="CPH_btnreset"
                              tabindex="143"
                              class="btn btn-danger m-3"
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AgeDomicile;
