import React from "react";
// import './Landing.css'
const Footer = () => {
  return (
    <div>
      <div className="foot">
        <div className="row text-center ">
          <div className="col-lg-5">
            <p>
             <b> Copyright © 2019 Maharashtra Informatics PVT LTD. All rights reserved</b>
            </p>
          </div>
          <div className="col-lg-3">
            <ul className="menu-link">
              <li>Home</li>
              <li>About</li>
              <li>Places</li>
              <li>Contact Us</li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul className="icons">
              <li>
                <i class="icon bi bi-facebook"></i>
              </li>
              <li>
                {" "}
                <i class="icon bi bi-instagram"></i>
              </li>
              <li>
                <i class="icon bi bi-twitter"></i>
              </li>
              <li>
                {" "}
                <i class="icon bi bi-linkedin"></i>
              </li>
              <li>
                <i class="icon bi bi-youtube"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
