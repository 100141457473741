import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import './styles.css';
// import './Application.css';
import swal from "sweetalert";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { incNumber } from "../../Redux/actions";
import Breadcrumb from "../common/breadcrumb";

function Application_eGazzet() {
  const navigate =useNavigate();

  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [subNote, setSubNote] = useState("");
  const [compNote, setCompNote] = useState("");
  const [rejNote, setRejNote] = useState("");
  let { id } = useParams();

  const userRespons = JSON.parse(localStorage.getItem("userResponse"));
  const [selectedItem, setselectedItem] = useState(null);
  const formEle = useSelector((state) => state.formeleven.formEle);
  const [AllFOrm, setAllForm] = useState(formEle);
  const getData = () => {
    if (AllFOrm.length > 0) {
      console.log(31, AllFOrm);
      const selectedData = AllFOrm.find((item) => item._id === id);
      setselectedItem(selectedData);
    } else {
      axios
        .get("https://api.maharashtraseva.com/api/getAll/Form11")
        .then((res) => {
          const data = res.data;
          const selectedData = data.find((item) => item._id === id);
          setselectedItem(selectedData);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getData();
    if(
      userRespons.user.role === "admin" &&
      selectedItem?.status != "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ){
      console.log(78, "addminn");
      axios
      .put(`https://api.maharashtraseva.com/api/e_gazzet/${id}`, { isNew: true })
      .then((res) => {
        dispatch(incNumber());
      })
      .catch((err) => console.log(err));
    }
   else if (
      userRespons.user.role === "admin" &&
      selectedItem?.status == "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ) {
      console.log(78, "admin");
      axios
        .put(`https://api.maharashtraseva.com/api/e_gazzet/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status == "IN-PROGRESS" &&
      userRespons.user.role == "retailer"
    ) {
      console.log(78, "retailer");
      axios
        .put(`https://api.maharashtraseva.com/api/e_gazzet/${id}`, { isNew: true })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status != "IN-PROGRESS" &&
      userRespons.user.role == "retailer"
    ) {
      console.log(78, "Ok");
      axios
        .put(`https://api.maharashtraseva.com/api/e_gazzet/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    }

  }, [id, formEle,selectedItem]);

  console.log("40", selectedItem);

  const [Acknowledgment, setAcknowledgment] = useState({
    Acknowledgment: "",
  });
  const [finalDocs, setfinalDocs] = useState({
    finalDocs: "",
  });

  const FormSubmit = (e) => {
    e.preventDefault();

    if (
      selectedItem.status === "IN-PROGRESS" ||
      selectedItem.status === "IN-Progress"
    ) {
      const formData = new FormData();

      formData.append("acknowledgmentDocument", Acknowledgment.Acknowledgment);
      axios
        .put(
          `https://api.maharashtraseva.com/api/gazzet_acknowledgmentDocument/${id}`,
          formData
        )
        .then((res) => console.log("acknowledgmentDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        subNote: subNote,
        submitAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/e_gazzet/${id}`, obj)
        .then((res) => {console.log("acknowledgmentDocument", res.data)
        dispatch(incNumber())
        swal("updated successfully", "success");
      }).then(()=> navigate("/admin_application"))
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedItem.status === "SUBMITTED") {
      const formData = new FormData();
      console.log("ok");

      formData.append("finalDocument", finalDocs.finalDocs);
      axios
        .put(`https://api.maharashtraseva.com/api/gazzet_finalDocument/${id}`, formData)
        .then((res) => console.log("finalDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        compNote: compNote,
        CompleteAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/e_gazzet/${id}`, obj)
        .then((res) =>{ console.log("finalDocument", res.data)
        dispatch(incNumber())
        swal("updated successfully", "success");
      }).then(()=> navigate("/admin_application"))
        .catch((err) => {
          console.log(err);
        });
    }

    // console.log("updated object",obj);
    if (status === "REJECTED") {
      const obj = {
        ...selectedItem,
        status: status,
        rejNote: rejNote,
        rejectAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/e_gazzet/${id}`, obj)
        .then((res) => {
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=> navigate("/admin_application"))
        .catch((err) => console.log(err));
    }
  };
  const handleDownloadClick = (url) => {
    const imageUrl = url;

    const downloadLink = document.createElement("a");
    downloadLink.href = imageUrl;
    downloadLink.download = "image.jpg";
    downloadLink.click();
  };
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  return (
    <>
      <Breadcrumb title={"E_Gazzet"} />
      {selectedItem != null ? (
        <Container fluid={true}>
          <Row>
            <Col sm="">
              <Card>
                <CardBody>
                  <form class="inline-block form-inline ms-auto ">
                    <div
                      className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <div className="row">
                        <div className="col-lg-8">
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Details </span>
                        </h3>
                          
                        </div>
                        <div className="col-lg-4 mt-1">
                        <button
                            class="btn btn-outline-dark"
                            type="button"
                            onClick={() =>{
                              if (userRespons.user.role=="retailer") {
                                navigate(
                                  `${process.env.PUBLIC_URL}/retailer_console`
                                )
                              }else
                              navigate(
                                `${process.env.PUBLIC_URL}/admin_application`
                              )
                            }
                            }
                          >
                            Back
                          </button>
                        </div>
                      </div>
                      </div>
                      <div id="layoutSidenav_content">
                        <main>
                          <div class="container-fluid px-4 mt-4">
                            <div class="formlayout">
                              <br />

                              <div class="row g-3">
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Service Type :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.service_type}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Type of Cast :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.type_cast}{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>title :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.title}{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> First Name :</b>
                                  </label>
                                  <br />
                                  {selectedItem.firstName +
                                    " " +
                                    selectedItem.lastName}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Mobile Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.mobileNumber}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Email :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.email}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>AADHAAR Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.adharNumber}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                      Mobile number registered with Aadhar card?
                                      :
                                    </b>{" "}
                                  </label>
                                  <br />
                                  {
                                    selectedItem.mobileNumber_registered_with_adharCard
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Gender :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.gender}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Date Of Birth :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Dob}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Service Type(Old Date) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Old_Dob}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Service Type (New Date) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.New_Dob}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Service Type(Old Name) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.OldName}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Service Type (New Name) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.NewName}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Reason :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.reason}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Address :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.address}{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>status History :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.status +
                                    "  " +
                                    selectedItem.createdAt}{" "}
                                </div>
                                <div class="col-md-12">
                                  <label>
                                    <b>Upload required documents: :</b>{" "}
                                  </label>
                                  <br />
                                  <b style={{ color: "red" }}>
                                    अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व
                                    आवश्यक कागदपत्रे तपासून व स्कॅन करून अपलोड
                                    करावे. जर आवश्यक कागदपत्रे चुकीची किंवा
                                    अस्पष्ट आढळल्यास सदर चा अर्ज फेटाळला जाऊ
                                    शकतो.
                                  </b>{" "}
                                </div>

                                <div class="col-md-6 mt-4 ">
                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                      marginTop:"10px"
                                    }}
                                  >
                                    <a href={selectedItem.photoID}>Photo ID </a>
                                  </button>
                                  <br />
                                  <br />

                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                      marginTop:"10px"
                                    }}
                                  >
                                    <a href={selectedItem.addressProof}>
                                      Address Proof
                                    </a>
                                  </button>
                                  <br />
                                  <br />

                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                      marginTop:"10px"
                                    }}
                                  >
                                    <a href={selectedItem.Cast_Certificate}>
                                      Cast Certificate
                                    </a>
                                  </button>
                                  <br />
                                  <br />

                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                      marginTop:"10px"
                                    }}
                                  >
                                    <a href={selectedItem.applicationForm}>
                                      Application Form
                                    </a>
                                  </button>
                                  <br />
                                  <br />

                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                      marginTop:"10px"
                                    }}
                                  >
                                    <a href={selectedItem.identityProof}>
                                      Identity Proof
                                    </a>
                                  </button>
                                  <br />
                                  <br />

                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                      marginTop:"10px"
                                    }}
                                  >
                                    <a href={selectedItem.Passport_Size_Photo}>
                                      Passport Size Photo
                                    </a>
                                  </button>
                                  <br />
                                  <br />

                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                      marginTop:"10px"
                                    }}
                                  >
                                    <a href={selectedItem.OldName_Proof}>
                                      Old Name Proof
                                    </a>
                                  </button>
                                  <br />
                                  <br />
                                  <br />

                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                      marginTop:"10px"
                                    }}
                                  >
                                    <a href={selectedItem.PresentName_Proof}>
                                      Present Name Proof
                                    </a>
                                  </button>
                                  <br />

                                  {selectedItem.acknowledgmentDocument !=
                                    null && (
                                    <>
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                          marginTop:"10px"
                                        }}
                                      >
                                        <a
                                          href={
                                            selectedItem.acknowledgmentDocument
                                          }
                                        >
                                          acknowledgmentDocument
                                        </a>
                                      </button>
                                      <br />
                                    </>
                                  )}

                                  {selectedItem.finalDocument != null && (
                                    <>
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                          marginTop:"10px"
                                        }}
                                      >
                                        <a href={selectedItem.finalDocument}>
                                          final Document
                                        </a>
                                      </button>
                                      <br />
                                    </>
                                  )}
                                </div>
                                <div className="col-lg-6">
                                      <div className="row">
                                        <div className="col-lg-12 "><h3 className="fs-4 fw-3"><b>HISTORY</b></h3></div>
                                        <div className="col-lg-12 mt-2"><b>Current Status : </b>{selectedItem.status}</div>
                                        <div className="col-lg-12 "><b>Created On : </b>{GetDate(selectedItem.createdAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Submit Note : </b>{selectedItem.subNote}</div>
                                        <div className="col-lg-12 "><b>submit On : </b>{GetDate(selectedItem.submitAt)}</div>
                                        <div className="col-lg-12 mt-2"><b>Reject Note : </b>{selectedItem.rejNote}</div>
                                        <div className="col-lg-12 "><b>Reject On : </b>{GetDate(selectedItem.rejectAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Complete Note : </b>{selectedItem.compNote}</div>
                                        <div className="col-lg-12 "><b>Complete On : </b>{GetDate(selectedItem.CompleteAt)}</div>
                                        
                                      </div>
                                    </div>
                              </div>
                              <br />
                              {userRespons.user.role === "retailer" ? (
                                ""
                              ) : (
                                <>
                                  <div class="row g-3">
                                    {selectedItem.status == "IN-PROGRESS" ||
                                    selectedItem.status == "IN-Progress" ? (
                                      <div class="row g-3" id="dvtext">
                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            {" "}
                                            <b>
                                              {" "}
                                              Status:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>
                                          <br />
                                          <select
                                            name="state"
                                            class="form-select"
                                            required
                                            id="state"
                                            onChange={(e) =>
                                              
                                              setStatus(e.target.value)
                                            }
                                          >
                                            <option value="" selected>
                                              Select State
                                            </option>
                                            <option Value="SUBMITTED">
                                              SUBMITTED
                                            </option>
                                            <option Value="REJECTED">
                                              REJECTED
                                            </option>
                                          </select>
                                        </div>

                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            <b>
                                              Note:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>{" "}
                                          <br />
                                          <textarea
                                            name="note"
                                            class="form-control"
                                            onChange={(e)=>{
                                              if (status=="SUBMITTED") {
                                                setSubNote(e.target.value)
                                              }else{
                                                setRejNote(e.target.value)
                                              }
                                            }}
                                          >
                                            {" "}
                                          </textarea>{" "}
                                        </div>
                                        {status === "SUBMITTED" && (
                                          <div class="col-md-6 mt-4 ">
                                            <label>
                                              <b>Acknowledgment Document </b>{" "}
                                            </label>
                                            <input
                                              type="file"
                                              name=""
                                              id=""
                                              onChange={(e) => {
                                                setAcknowledgment({
                                                  ...Acknowledgment,
                                                  Acknowledgment:
                                                    e.target.files[0],
                                                });
                                                // console.log(992,e);
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : selectedItem.status == "SUBMITTED" ? (
                                      <div class="row g-3" id="dvtext">
                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            {" "}
                                            <b>
                                              {" "}
                                              Status:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>
                                          <br />
                                          <select
                                            name="state"
                                            class="form-select"
                                            required
                                            id="state"
                                            onChange={(e) =>
                                              setStatus(e.target.value)
                                            }
                                          >
                                            <option value="" selected>
                                              Select State
                                            </option>

                                            <option Value="COMPLETE">
                                              COMPLETE
                                            </option>
                                          </select>
                                        </div>

                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            <b>
                                              Note:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>{" "}
                                          <br />
                                          <textarea
                                            name="note"
                                            class="form-control"
                                            onChange={(e)=>{
                                              setCompNote(e.target.value)
                                            }}
                                          >
                                            {" "}
                                          </textarea>{" "}
                                        </div>
                                        {status === "COMPLETE" && (
                                          <div class="col-md-6 mt-4 ">
                                            <label>
                                              <b>Final Document </b>{" "}
                                            </label>
                                            <input
                                              type="file"
                                              name=""
                                              id=""
                                              onChange={(e) => {
                                                setfinalDocs({
                                                  ...finalDocs,
                                                  finalDocs: e.target.files[0],
                                                });
                                                // console.log(992,e);
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <div class="row g-3 " id="dvtext">
                                      <div class="col-md-12">
                                        <button
                                          type="submit"
                                          name="submit"
                                          id="btnsubmit"
                                          class="btn btn-primary"
                                          value="Submit"
                                          onClick={(e) => {
                                            FormSubmit(e);
                                          }}
                                        >
                                          Submit
                                        </button>

                                        <button
                                          type="submit"
                                          name="submit"
                                          id="btnsubmit"
                                          class="btn btn-primary "
                                          value="Submit"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                    {/* </div>  */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </main>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Card
            style={{
              height: "80vh",
              width: "80vw",
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <h3>Loading...</h3>
          </Card>
        </>
      )}
    </>
  );
}

export default Application_eGazzet;
