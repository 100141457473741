import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import './styles.css';
// import './Application.css';
import swal from "sweetalert";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { incNumber } from "../../Redux/actions";
import Breadcrumb from "../common/breadcrumb";
import { Form } from "antd";

function Application_shopAct() {
  const navigate =useNavigate();
  const [status, setStatus] = useState("");
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));
  const [subNote, setSubNote] = useState("");
  const [compNote, setCompNote] = useState("");
  const [rejNote, setRejNote] = useState("");
  const dispatch = useDispatch();
  let { id } = useParams();
  const [selectedItem, setselectedItem] = useState(null);
  const formEle = useSelector((state) => state.formeleven.formEle);
  const [AllFOrm, setAllForm] = useState(formEle);
  const getData = () => {
    if (AllFOrm.length > 0) {
      console.log(31, AllFOrm);
      const selectedData = AllFOrm.find((item) => item._id === id);
      setselectedItem(selectedData);
    } else {
      axios
        .get("https://api.maharashtraseva.com/api/getAll/Form11")
        .then((res) => {
          const data = res.data;
          const selectedData = data.find((item) => item._id === id);
          setselectedItem(selectedData);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getData();
    // if (
    //   userRespons.user.role === "retailer" &&
    //   selectedItem?.status === "IN-PROGRESS"
    // ) {
    // } else if (
    //   userRespons.user.role === "admin" &&
    //   selectedItem?.status != "IN-PROGRESS"
    // ) {
    // } else {
    //   if (selectedItem.isNew==true && selectedItem?.status=="IN-PROGRESS" && userRespons.user.role === "retailer") {
        
    //     axios
    //       .put(`https://api.maharashtraseva.com/api/shopact/${id}`, { isNew: true })
    //       .then((res) => {
    //         dispatch(incNumber());
    //       })
    //       .catch((err) => console.log(err));
    //     }else{
    //     axios
    //       .put(`https://api.maharashtraseva.com/api/shopact/${id}`, { isNew: false })
    //       .then((res) => {
    //         dispatch(incNumber());
    //       })
    //       .catch((err) => console.log(err));

    //   }
    // }
    if(
      userRespons.user.role === "admin" &&
      selectedItem?.status != "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ){
      axios
      .put(`https://api.maharashtraseva.com/api/shopact/${id}`, { isNew: true })
      .then((res) => {
        dispatch(incNumber());
      })
      .catch((err) => console.log(err));
    }
   else if (
      userRespons.user.role === "admin" &&
      selectedItem?.status == "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/shopact/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status == "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/shopact/${id}`, { isNew: true })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status != "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      console.log(78, "blck");
      axios
        .put(`https://api.maharashtraseva.com/api/shopact/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    }
  }, [id, formEle]);

  console.log("40", selectedItem);

  const [Acknowledgment, setAcknowledgment] = useState({
    Acknowledgment: "",
  });
  const [finalDocs, setfinalDocs] = useState({
    finalDocs: "",
  });
  const obj = {
    ...selectedItem,
    status: status,
    // note:note
  };
  const FormSubmit = (e) => {
    e.preventDefault();

    if (
      selectedItem.status === "IN-PROGRESS" ||
      selectedItem.status === "IN-Progress"
    ) {
      const formData = new FormData();

      formData.append("acknowledgmentDocument", Acknowledgment.Acknowledgment);
      axios
        .put(
          `https://api.maharashtraseva.com/api/shopact_acknowledgmentDocumentl/${id}`,
          formData
        )
        .then((res) => console.log("acknowledgmentDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        subNote: subNote,
        submitAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/shopact/${id}`, obj)
        .then((res) => {console.log("final document", res.data)
        dispatch(incNumber())
        swal("updated successfully", "success");
      }).then(()=> navigate("/admin_application"))
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedItem.status === "SUBMITTED") {
      const formData = new FormData();

      formData.append("finalDocument", finalDocs.finalDocs);
      axios
        .put(`https://api.maharashtraseva.com/api/shopact_finalDocument/${id}`, formData)
        .then((res) => {console.log("finalDocument", res.data)
        dispatch(incNumber());
      })
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        compNote: compNote,
        CompleteAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/shopact/${id}`, obj)
        .then((res) => {console.log("final Document", res.data)
        dispatch(incNumber());
        swal("updated successfully", "success");
      }).then(()=> navigate("/admin_application"))
        .catch((err) => {
          console.log(err);
        });
    }

    // console.log("updated object",obj);
    if (status === "REJECTED") {
      const obj = {
        ...selectedItem,
        status: status,
        rejNote: rejNote,
        rejectAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/shopact/${id}`, obj)
        .then((res) => {
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=> navigate("/admin_application"))
        .catch((err) => console.log(err));
      }
  };
  const handleDownloadClick = (url) => {
    const imageUrl = url;

    const downloadLink = document.createElement("a");
    downloadLink.href = imageUrl;
    downloadLink.download = "image.jpg";
    downloadLink.click();
  };
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  return (
    <>
      <Breadcrumb title={"Shop Act"} />
      {selectedItem != null ? (
        <Container fluid={true}>
          <Row>
            <Col sm="">
              <Card>
                <CardBody>
                  {/* <button
                  class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
                  id="sidebarToggle"
                  href="#!"
                >
                  <i class="fas fa-bars"></i>
                </button> */}
                 {/* <div className="col-md-4 mt-2">
                      <Form.Item>
                        <div class="d-grid gap-1 d-md-flex justify-content-md-end mt-4 ">
                          <button
                            class="btn btn-primary"
                            type="button"
                            onClick={() =>
                              navigate(
                                `${process.env.PUBLIC_URL}/admin_application`
                              )
                            }
                          >
                            Back
                          </button>
                        </div>
                      </Form.Item>
                    </div> */}

                  <form class="inline-block form-inline ms-auto ">
                    <div
                      className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <div className="row">
                        <div className="col-lg-8">
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Details </span>
                        </h3>
                          
                        </div>
                        <div className="col-lg-4 mt-1">
                        <button
                            class="btn btn-outline-dark"
                            type="button"
                            onClick={() =>{
                              if (userRespons.user.role=="retailer") {
                                navigate(
                                  `${process.env.PUBLIC_URL}/retailer_console`
                                )
                              }else
                              navigate(
                                `${process.env.PUBLIC_URL}/admin_application`
                              )
                            }
                            }
                          >
                            Back
                          </button>
                        </div>
                      </div>
                      </div>
                      <div id="layoutSidenav_content">
                        <main>
                          <div class="container-fluid px-4 mt-4">
                            <div class="formlayout">
                              <div class="row g-3">
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Application Type :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.selectType}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Title :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.title}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Old SHop Act Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.oldShopActNo}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Full Name(English) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.FullNameEnglish}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Full Name(Marathi) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.FullNameMarathi}{" "}
                                </div>
                                {/* <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Middle Name :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.middleName}{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> Last Name :</b>
                                  </label>
                                  <br />
                                  {selectedItem.lastName}{" "}
                                </div> */}
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Mobile Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.mobileNo}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Email :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.email}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>AADHAAR Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.adharNumber}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                      Mobile number registered with Aadhar card?
                                      :
                                    </b>{" "}
                                  </label>
                                  <br />
                                  {
                                    selectedItem.mobileNumber_registered_WithAdharCard
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Gender :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.gender}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Date of Birth:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.dob}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                      Residence Address Same as Aadhar Address?
                                      :
                                    </b>{" "}
                                  </label>
                                  <br />
                                  {
                                    selectedItem.residenceAddress_same_AadharAddress
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Residence Address (English):</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.residenceAddressEnglish}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Residence Address (Marathi):</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.residenceAddressMarathi}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                      No. of years staying in Residence Address
                                      :
                                    </b>{" "}
                                  </label>
                                  <br />
                                  {
                                    selectedItem.no_of_years_staying_in_residenceAddress
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Shop/Business Name (English):</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.shopNameEnglish}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Shop/Business Name(Marathi) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.shopNameMarathi}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Shop Address with Pincode(English) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.shopAddress_with_PincodeEnglish}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Shop Address with Pincode(Marathi) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.shopAddress_with_PincodeMarathi}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Is Shop Rented? :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.IsShop_Rented}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Nature of Business(English) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.natureOfBusinessEnglish}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Nature of Business (Marathi):</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.natureOfBusinessMarathi}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Date Business was Started :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.dateBusiness_Started}{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Number of Employees :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.number_of_Employees}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Number of Men Employees :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.number_of_MenEmployees}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Number of Women Employees :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.number_of_WomenEmployees}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 1 Name (English):</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner1_NameEnglish}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 1 Name (Marathi):</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner1_NameMarathi}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 1 Mobile Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner1_mobileNumber}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 1 Email :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner1_email}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 1 Address :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner1_address}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 2 Name :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner2_Name}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 2 Mobile Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner2_mobileNumber}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 2 Email :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner2_email}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 2 Address :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner2_address}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 3 Name :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner3_Name}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 3 Mobile Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner3_mobileNumber}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 3 Email :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner3_email}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 3 Address :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner3_address}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 4 Name :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner4_Name}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 4 Mobile Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner4_mobileNumber}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Partner 4 Email :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Partner4_email}{" "}
                                </div>
{/* 
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>status History :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.status +
                                    "  " +
                                    selectedItem.createdAt}{" "}
                                </div> */}

                                <div class="col-md-12 mt-4 ">
                                  <label>
                                    <b>Upload required documents: :</b>{" "}
                                  </label>
                                  <br />
                                  <b style={{ color: "red" }}>
                                    अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व
                                    आवश्यक कागदपत्रे तपासून व स्कॅन करून अपलोड
                                    करावे. जर आवश्यक कागदपत्रे चुकीची किंवा
                                    अस्पष्ट आढळल्यास सदर चा अर्ज फेटाळला जाऊ
                                    शकतो.
                                  </b>{" "}
                                </div>

                                <div class="col-md-6 mt-4 ">
                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <a href={selectedItem.ownerPassport_photo}>
                                      Owner Passport photo
                                    </a>
                                  </button>
                                  <br />
                                  <br />

                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <a
                                      href={
                                        selectedItem.ownerSignatureAsPer_PAN
                                      }
                                    >
                                      Owner Signature As Per PAN{" "}
                                    </a>
                                  </button>
                                  <br />
                                  <br />
                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <a href={selectedItem.adharCard}>
                                      Aadhar Card
                                    </a>
                                  </button>
                                  <br />
                                  <br />

                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <a
                                      href={
                                        selectedItem.shopPhotographFrom_FrontSide_WithBusinessBoard
                                      }
                                    >
                                      Shop Photograph From Front Side With
                                      Business Board
                                    </a>
                                  </button>
                                  <br />
                                  <br />
                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <a href={selectedItem.selfDeclaration}>
                                      Self Declaration
                                    </a>
                                  </button>
                                  <br />
                                  <br />
                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <a href={selectedItem.ownerPANCard}>
                                      Owner PAN Card
                                    </a>
                                  </button>
                                  <br />
                                  <br />
                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <a
                                      href={selectedItem.oldShopAct_ForRenewal}
                                    >
                                      Old Shop Act For Renewal (Min 60 Days
                                      Before Of Expiry)
                                    </a>
                                  </button>
                                  <br />
                                  <br />
                                  {selectedItem.acknowledgmentDocument !=
                                    null && (
                                    <>
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a
                                          href={
                                            selectedItem.acknowledgmentDocument
                                          }
                                        >
                                          acknowledgmentDocument
                                        </a>
                                      </button>
                                      <br />
                                    </>
                                  )}

                                  {selectedItem.finalDocument != null && (
                                    <>
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.finalDocument}>
                                          finalDocument
                                        </a>
                                      </button>
                                      <br />
                                    </>
                                  )}
                                </div>
                                <div className="col-lg-6">
                                      <div className="row">
                                        <div className="col-lg-12 "><h3 className="fs-4 fw-3"><b>HISTORY</b></h3></div>
                                        <div className="col-lg-12 mt-2"><b>Current Status : </b>{selectedItem.status}</div>
                                        <div className="col-lg-12 "><b>Created On : </b>{GetDate(selectedItem.createdAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Submit Note : </b>{selectedItem.subNote}</div>
                                        <div className="col-lg-12 "><b>submit On : </b>{GetDate(selectedItem.submitAt)}</div>
                                        <div className="col-lg-12 mt-2"><b>Reject Note : </b>{selectedItem.rejNote}</div>
                                        <div className="col-lg-12 "><b>Reject On : </b>{GetDate(selectedItem.rejectAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Complete Note : </b>{selectedItem.compNote}</div>
                                        <div className="col-lg-12 "><b>Complete On : </b>{GetDate(selectedItem.CompleteAt)}</div>
                                        
                                      </div>
                                    </div>
                              </div>
                              <br />
                              {userRespons.user.role === "retailer" ? (
                                ""
                              ) : (
                                <>
                                  <div class="row g-3">
                                    {selectedItem.status == "IN-PROGRESS" ||
                                    selectedItem.status == "IN-Progress" ? (
                                      <div class="row g-3" id="dvtext">
                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            {" "}
                                            <b>
                                              {" "}
                                              Status:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>
                                          <br />
                                          <select
                                            name="state"
                                            class="form-select"
                                            required
                                            id="state"
                                            onChange={(e) =>
                                              setStatus(e.target.value)
                                            }
                                          >
                                            <option value="" selected>
                                              Select State
                                            </option>
                                            <option Value="SUBMITTED">
                                              SUBMITTED
                                            </option>
                                            <option Value="REJECTED">
                                              REJECTED
                                            </option>
                                          </select>
                                        </div>

                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            <b>
                                              Note:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>{" "}
                                          <br />
                                          <textarea
                                            name="note"
                                            class="form-control"
                                            onChange={(e) => {
                                              if (status == "SUBMITTED") {
                                                setSubNote(e.target.value);
                                              } else {
                                                setRejNote(e.target.value);
                                              }
                                            }}
                                          >
                                            {" "}
                                          </textarea>{" "}
                                        </div>
                                        {status === "SUBMITTED" && (
                                          <div class="col-md-6 mt-4 ">
                                            <label>
                                              <b>Acknowledgment Document </b>{" "}
                                            </label>
                                            <input
                                              type="file"
                                              name=""
                                              id=""
                                              onChange={(e) => {
                                                setAcknowledgment({
                                                  ...Acknowledgment,
                                                  Acknowledgment:
                                                    e.target.files[0],
                                                });
                                                // console.log(992,e);
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : selectedItem.status == "SUBMITTED" ? (
                                      <div class="row g-3" id="dvtext">
                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            {" "}
                                            <b>
                                              {" "}
                                              Status:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>
                                          <br />
                                          <select
                                            name="state"
                                            class="form-select"
                                            required
                                            id="state"
                                            onChange={(e) =>
                                              setStatus(e.target.value)
                                            }
                                          >
                                            <option value="" selected>
                                              Select State
                                            </option>

                                            <option Value="COMPLETE">
                                              COMPLETE
                                            </option>
                                          </select>
                                        </div>

                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            <b>
                                              Note:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>{" "}
                                          <br />
                                          <textarea
                                            name="note"
                                            class="form-control"
                                            onChange={(e) => {
                                              setCompNote(e.target.value);
                                            }}
                                          >
                                            {" "}
                                          </textarea>{" "}
                                        </div>
                                        {status === "COMPLETE" && (
                                          <div class="col-md-6 mt-4 ">
                                            <label>
                                              <b>Final Document </b>{" "}
                                            </label>
                                            <input
                                              type="file"
                                              name=""
                                              id=""
                                              onChange={(e) => {
                                                setfinalDocs({
                                                  ...finalDocs,
                                                  finalDocs: e.target.files[0],
                                                });
                                                // console.log(992,e);
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <div class="row g-3 " id="dvtext">
                                      <div class="col-md-12">
                                        <button
                                          type="submit"
                                          name="submit"
                                          id="btnsubmit"
                                          class="btn btn-primary"
                                          value="Submit"
                                          onClick={(e) => {
                                            FormSubmit(e);
                                          }}
                                        >
                                          Submit
                                        </button>

                                        <button
                                          type="submit"
                                          name="submit"
                                          id="btnsubmit"
                                          class="btn btn-primary "
                                          value="Submit"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                    {/* </div>  */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </main>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Card
            style={{
              height: "80vh",
              width: "80vw",
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <h3>Loading...</h3>
          </Card>
        </>
      )}
    </>
  );
}

export default Application_shopAct;
