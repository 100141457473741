import React, { useEffect } from "react";
// import logo from "../../assets/logo.png";
import { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./receipt.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/images/image/logoTm.jpg";

function FormFOurReceipt() {
  const { id } = useParams();
  const [filterData, setFilterData] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.maharashtraseva.com/api/getAll")
      .then(async (res) => {
        const data = await res.data;
        console.log(23, data);
        const filter = data.filter((item) => item._id === id);
        console.log("filter", filter);
        // setFilterData(filter);
        setFilterData(filter);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  // console.log("filterData", filterData[0].Data.formPrice);

  const [loader, setLoader] = useState(false);

  const downloadPDF = () => {
    const capture = document.querySelector(".actual-receipt");
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save("receipt.pdf");
    });
  };
  const generateRandomToken = () => {
    const min = 100000000000; // Minimum 12-digit number
    const max = 999999999999; // Maximum 12-digit number

    const randomToken = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomToken.toString();
  };
  return (
    <>
      <div className="wrapper">
        <div className="receipt-box">
          <div className="actual-receipt">
            <div className="receipt-organization-logo">
              <img alt="logo" src={logo} />
            </div>

            <b style={{ color: "orange" }}>
              {" "}
              <u>महाराष्ट्र ई-सेवा केंद्र</u>
              <br />{" "}
            </b>

            <br />

            <h6>Acknowledgement Details</h6>

            <div className="colored-row first">
              <span>Acknowledgement Details</span>
            </div>
            <div className="data-row">
              <span className="font-weight">
                Application for:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp;
                {filterData[0]?.application_type}
              </span>
              <span></span>
            </div>
            <div className="data-row">
              <span className="font-weight">Token No:</span>
              <span>{generateRandomToken()}</span>
            </div>
            <div className="data-row">
              <span className="font-weight">Applicant Name:</span>
              <span> {filterData[0]?.Data?.fullName_English}</span>
            </div>

            <div className="colored-row">
              <span>Applicant Details</span>
            </div>

            {/* <div className="data-row">
                  <span className="font-weight">Company Name(English):</span>
                  <span>{filterData[0]?.companyName}</span>
                </div> */}

            <div className="data-row">
              <span className="font-weight">Date:</span>
              <span>{GetDate(filterData[0]?.createdAt)}</span>
             
            </div>
            <div className="data-row">
            <span className="font-weight">Service Charge:</span>
              <span>{filterData[0]?.Data?.formPrice} ₹</span>
             
            </div>

            <div className="colored-row">
              <span>Thank You </span>
              <span />
            </div>
          </div>

          <div className="receipt-actions-div">
            <div className="actions-right">
              <button
                className="receipt-modal-download-button"
                onClick={downloadPDF}
                disabled={!(loader === false)}
              >
                {loader ? <span>Downloading</span> : <span>Download</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormFOurReceipt;
