import React from "react";
import axios from "axios";
import { MdUploadFile } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { toast } from "react-toastify";
// import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { incNumber } from "../../Redux/actions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import TextArea from "antd/es/input/TextArea";
import swal from "sweetalert";

const CompGST = () => {
  const [form] = Form.useForm();
  const navigate=useNavigate();
  const { cid } = useParams();
  const dispatch = useDispatch();
  const [FormFileerror, setFormFileerror] = useState({});
  const [showloading, setShowloading] = useState(false);
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const reducer = useSelector((state) => state.changeNumber);
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const [userFormDetails, setuserFormDetails] = useState([]);
  const[price,setprice]=useState();
  // const pricess = useSelector((state) => state.price.formPrices);
  const [balance, setBalance] = useState(0);
  const [docs, setDocs] = useState({
    adharCard: "",
    electricityBill: "",
    bankPassbook: "",
    panCard: "",
    passportPhoto: "",
    shopAct_licence: "",
    rentAgreement: "",
    signature: "",
  });
 
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/formPrice`)
      .then((res) => {
        const response = res.data;
        const filtered = response.filter((item) => item.userID == userData.user._id);
        if (filtered.length==0) {
          const userFormDetails = response.filter((item) => item.userID === "ALL");
          setuserFormDetails(userFormDetails);
          setData({...Data,formPrice:userFormDetails[0].companyGST})
        }else{
          const userFormDetails = response.filter((item) => item.userID === userData.user._id);
          setuserFormDetails(userFormDetails);
          setData({...Data,formPrice:userFormDetails[0].companyGST})
        }
      })
      .catch((err) => console.log(40, err));
  }, []);
  const [Data, setData] = useState({
    createdBy: userData.user._id,
    createdByName: userData.user?.name,
    application_type: "CompanyGST",
    status: "IN-PROGRESS",
    formPrice:"",
    companyName: "",
    ownerName: "",
    mobileNO: "",
    email: "",
    businessStarted: "",
    companyAddress: "",
    companyPan: "",
    RegCertificate: "",
    MOA_AOA: "",
    authorityLetter: "",
    natureBusiness: "",
  });
  console.log(65,Data)
  const [Formerror, setFormerror] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  const validate = (values) => {
    const error = {};
    if (!values.companyName) {
      error.companyName = " company Name is required";
    }
    if (!values.ownerName) {
      error.ownerName = " Owner Name is required";
    }
    if (!values.mobileNO) {
      error.mobileNO = " Mobile Number is required";
    }
    if (!values.email) {
      error.email = " Email Is required";
    }
    if (!values.businessStarted) {
      error.businessStarted = " Business Started is required";
    }
    if (!values.companyAddress) {
      error.companyAddress = " Company Address is required";
    }
    // if (!values.companyPan) {
    //   error.companyPan = " Company Pan is required";
    // }
    if (!values.RegCertificate) {
      error.RegCertificate = " Company Pan is required";
    }
    // if (!values.RegCertificateMoa) {
    //   error.RegCertificateMoa = " Reg Certificate Moa is required";
    // }
    if (!values.authorityLetter) {
      error.authorityLetter = " Authority Letter is required";
    }
    if (!values.natureBusiness) {
      error.natureBusiness = " Nature Business is required";
    }
    console.log("error object", error);
    return error;
  };
  const FileValidate = (Val) => {

   
    let fileError = {};
    if (!Val.adharCard) {
      fileError.adharCard = "File is Required";
    } else {
      if (Val.adharCard.size > 1024 * 1024) {
        fileError.adharCard = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.electricityBill) {
      fileError.electricityBill = "File is Required";
    } else {
      if (Val.electricityBill.size > 1024 * 1024) {
        fileError.electricityBill = " File size exceeds the limit of 1MB";
      }
    }
    // if (!Val.bankPassbook) {
    //   fileError.bankPassbook = "File is Required";
    // } else {
    //   if (Val.bankPassbook.size > 1024 * 1024) {
    //     fileError.bankPassbook = " File size exceeds the limit of 1MB";
    //   }
    // }
    // if (!Val.passportPhoto) {
    //   fileError.passportPhoto = "File is Required";
    // } else {
    //   if (Val.passportPhoto.size > 1024 * 1024) {
    //     fileError.passportPhoto = " File size exceeds the limit of 1MB";
    //   }
    // }
    // if (!Val.panCard) {
    //   fileError.panCard = "File is Required";
    // } else {
    //   if (Val.panCard.size > 1024 * 1024) {
    //     fileError.panCard = " File size exceeds the limit of 1MB";
    //   }
    // }
    console.log("error object", fileError);
    return fileError;
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userResponse"));
    if (userData) {
      axios.get(`https://api.maharashtraseva.com/api/recharge`).then((res) => {
        console.log(88, res.data);

        const allData = res.data.filter(
          (ele) => ele.user === userData.user._id
        );
        let amount = 0;
        let expence = 0;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.isExpence == "true") {
              expence += item.amount;
            } else {
              amount += item.amount;
            }
          });
        }
        setBalance(amount - expence);
      });
    }
  }, [reducer]);


  const businessStarted = (date, dateString) => {
    setData({ ...Data, businessStarted: dateString });
  };
  const COmpGstForm = (e) => {
    e.preventDefault();
    const FileError =FileValidate(docs)
    const errors = validate(Data);
    console.log(206,errors)
    if (Object.keys(errors).length === 0 && Object.keys(FileError).length === 0) {
      SetIsSubmit(true);
      
      if (balance > userFormDetails[0].companyGST) {
        // setData({...Data,formPrice:userFormDetails[0].companyGST})
        setShowloading(true);
        const mainDataPromise = new Promise((resolve, reject) => {
          console.log(77, Data);
          axios
            .post(`https://api.maharashtraseva.com/api/compgst/create`, Data)
            .then((res) => {
              const response = res;
              resolve({
                status: true,
                message: "data Posted Successfully",
                data: res.data,
              });
            })
            .catch((err) => {
              console.log(err);
              setShowloading(false)
              reject({
                status: false,
                message: "Data Not posted",
              });
            });
        });

        mainDataPromise
          .then((res) => {
            uploadadharCard(res.data.data._id);
            uploadelectricityBill(res.data.data._id);
            uploadbankPassbook(res.data.data._id);
            uploadpanCard(res.data.data._id);
            uploadpassportPhoto(res.data.data._id);
            uploadshopAct_licence(res.data.data._id);
            uploadrentAgreement(res.data.data._id);
            uploadsignature(res.data.data._id);
            debitFormBalance();
            setData({
              ...Data,
              companyName: "",
              ownerName: "",
              mobileNO: "",
              email: "",
              businessStarted: "",
              companyAddress: "",
              companyPan: "",
              RegCertificate: "",
              MOA_AOA: "",
              authorityLetter: "",
              natureBusiness: "",
            })
            setShowloading(false)
          }).then(()=>{navigate("/RetailerDashboard")})
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error("Please Recharge");
      }
    } else {
      setFormerror(errors);
      setFormFileerror(FileError)
      swal("Filled the required form");
    }
  };
  const obj = {
    user: userData?.user._id,
    mode: "offline",
    amount: userFormDetails[0]?.companyGST,
    isExpence: true,
    expenceFor: "companyGST",
  };
  const debitFormBalance = () => {
    axios
      .post("https://api.maharashtraseva.com/api/recharge/create", obj)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());
        swal("Good job!", "form submitted successfully!", "success");
      })
      .catch((err) => console.log(34, err));
  };
  const uploadadharCard = (id) => {
    const formData = new FormData();
    formData.append("adharCard", docs.adharCard);
    axios
      .put(`https://api.maharashtraseva.com/api/compgst_adharCard/${id}`, formData)
      .then((res) => console.log("compgst_adharCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadelectricityBill = (id) => {
    const formData = new FormData();
    formData.append("electricityBill", docs.electricityBill);
    axios
      .put(
        `https://api.maharashtraseva.com/api/compgst_electricityBill/${id}`,
        formData
      )
      .then((res) => console.log("addressProof", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadbankPassbook = (id) => {
    const formData = new FormData();
    formData.append("bankPassbook", docs.bankPassbook);
    axios
      .put(
        `https://api.maharashtraseva.com/api/compgst_bankPassbook/${id}`,
        formData
      )
      .then((res) => console.log("bankPassbook", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadpanCard = (id) => {
    const formData = new FormData();
    formData.append("panCard", docs.panCard);
    axios
      .put(`https://api.maharashtraseva.com/api/compgst_panCard/${id}`, formData)
      .then((res) => console.log("panCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadpassportPhoto = (id) => {
    const formData = new FormData();
    formData.append("passportPhoto", docs.passportPhoto);
    axios
      .put(
        `https://api.maharashtraseva.com/api/compgst_passportPhoto/${id}`,
        formData
      )
      .then((res) => console.log("identityProof", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadshopAct_licence = (id) => {
    const formData = new FormData();
    formData.append("shopAct_licence", docs.shopAct_licence);
    axios
      .put(
        `https://api.maharashtraseva.com/api/compgst_shopActLicence/${id}`,
        formData
      )
      .then((res) => console.log("shopAct_licence", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadrentAgreement = (id) => {
    const formData = new FormData();
    formData.append("rentAgreement", docs.rentAgreement);
    axios
      .put(
        `https://api.maharashtraseva.com/api/compgst_rentAgreement/${id}`,
        formData
      )
      .then((res) => console.log("OldName_Proof", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadsignature = (id) => {
    const formData = new FormData();
    formData.append("signature", docs.signature);
    axios
      .put(`https://api.maharashtraseva.com/api/compgst_signature/${id}`, formData)
      .then((res) => console.log("signature", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (cid != undefined) {
      axios.get(`https://api.maharashtraseva.com/api/compgst/${cid}`).then((res) => {
        const data = res.data[0];

        console.log("data", data);
        setData(data);
        if (
          data?.isNew == true &&
          data?.status == "REJECTED" &&
          userData.user.role === "retailer"
        ) {
          axios
            .put(`https://api.maharashtraseva.com/api/compgst/${cid}`, { isNew: false })
            .then((res) => {
              dispatch(incNumber());
            })
            .catch((err) => console.log(err));
        }
      });
    }
  }, [cid]);

  const editHandler = (e) => {
    e.preventDefault();

    const obj = {
      ...Data,
      createdBy: userData.user._id,
      createdByName: userData.user?.name,
      application_type: "CompanyGST",
      status: "IN-PROGRESS",
    };

    const mainDataPromise = new Promise((resolve, reject) => {
      axios
        .put(`https://api.maharashtraseva.com/api/compgst/${cid}`, obj)
        .then((res) => {
          const response = res;
          resolve({
            status: true,
            message: "data Updated Successfully",
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            status: false,
            message: "Data Not posted",
          });
        });
    });

    mainDataPromise
      .then((res) => {
        // console.log(124,res.data)

        uploadadharCard(res.data._id);
        uploadelectricityBill(res.data._id);
        uploadbankPassbook(res.data._id);
        uploadpanCard(res.data._id);
        uploadpassportPhoto(res.data._id);
        uploadshopAct_licence(res.data._id);
        uploadrentAgreement(res.data._id);
        uploadsignature(res.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Fragment>
      <Breadcrumb title={"Company GST"} parent={"GST registration"} />
      <Container fluid={true}>
        <h3>Form Price : {userFormDetails[0]?.companyGST}</h3>

        <Row>
          <Col sm="">
            <Card>
              <CardBody>
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Company Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Company Name!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="companyName"
                          value={Data.companyName}
                          onChange={(e) => {
                            setData({ ...Data, companyName: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.companyName}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Owner Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Owner Name!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="Please input your Owner Name!"
                          value={Data.ownerName}
                          onChange={(e) => {
                            setData({ ...Data, ownerName: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.ownerName}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Owner Name!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                        type="Number"
                          placeholder="Please input your Owner Name!"
                          value={Data.mobileNO}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 10) {
                              setData({
                                ...Data,
                                mobileNO:
                                  sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                              setData({
                                ...Data,
                                mobileNO: "", // Reset the adharNumber if it's too long
                              });
                            }
                            // setData({ ...Data, mobileNO: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.mobileNO}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Email
                      </label>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="Please input Email!"
                          value={Data.email}
                          onChange={(e) => {
                            setData({ ...Data, email: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.email}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Date Business was Started
                      </label>

                      <Form.Item>
                        <DatePicker onChange={businessStarted} />
                        <p className="red">{Formerror.businessStarted}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Company Address
                      </label>

                      <Form.Item rules={[]}>
                        <TextArea
                          rows={2}
                          placeholder="Please input your Company Address!"
                          onChange={(e) => {
                            setData({
                              ...Data,
                              companyAddress: e.target.value,
                            });
                          }}
                        />{" "}
                        <p className="red">{Formerror.companyAddress}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        {/* <span className="red">*</span> */}
                        Company PAN
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="Please Enter Pan Number"
                          value={Data.companyPan}
                          onChange={(e) => {
                            setData({ ...Data, companyPan: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.companyPan}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Reg Certificate
                      </label>

                      <Form.Item>
                        <Radio.Group
                          onChange={(e) => {
                            setData({
                              ...Data,
                              RegCertificate: e.target.value,
                            });
                          }}
                        >
                          <Radio value="yes">Yes</Radio>
                          <Radio value="No">NO</Radio>
                        </Radio.Group>
                        <p className="red">{Formerror.RegCertificate}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                   
                        <span className="red">*</span> MOA/AOA
                      </label>

                      <Form.Item>
                        <Radio.Group
                          onChange={(e) => {
                            setData({
                              ...Data,
                              MOA_AOA: e.target.value,
                            });
                          }}
                        >
                          <Radio value="yes">Yes</Radio>
                          <Radio value="No">NO</Radio>
                        </Radio.Group>
                        <p className="red">{Formerror.MOA_AOA}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Authority Letter
                      </label>

                      <Form.Item>
                        <Radio.Group
                          onChange={(e) => {
                            setData({
                              ...Data,
                              authorityLetter: e.target.value,
                            });
                          }}
                        >
                          <Radio value="yes">Yes</Radio>
                          <Radio value="No">NO</Radio>
                        </Radio.Group>{" "}
                        <p className="red">{Formerror.authorityLetter}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Nature Of Business
                      </label>

                      <Form.Item rules={[]}>
                        <TextArea
                          rows={2}
                          placeholder="Please input Nature Of Business!"
                          onChange={(e) => {
                            setData({
                              ...Data,
                              natureBusiness: e.target.value,
                            });
                          }}
                        />
                        <p className="red">{Formerror.natureBusiness}</p>
                      </Form.Item>
                    </div>
<div className="col-lg-12">
<h5 className="red">
                    अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व आवश्यक कागदपत्रे
                    तपासून व स्कॅन करून अपलोड करावे. जर आवश्यक कागदपत्रे चुकीची
                    किंवा अस्पष्ट आढळल्यास सदर चा अर्ज फेटाळला जाऊ शकतो.{" "}
                  </h5>
</div>
                    <div className="col-md-12">
                      <h5>Upload required documents: </h5>
                    </div>
                   
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Aadhar Card (Size -
                        Maximum 1 MB) [Only (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            adharCard: e.target.files[0],
                          });
                        }}
                      />
                     <p className="red">{FormFileerror.adharCard}</p>

                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Electricity Bill (Size -
                        Maximum 1 MB) [Only (jpg,jpeg,pdf)]*
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            electricityBill: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                     <p className="red">{FormFileerror.electricityBill}</p>

                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Bank Passbook/Cancel Check (Size - Maximum 1 MB) [Only
                        (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            bankPassbook: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                     <p className="red">{FormFileerror.bankPassbook}</p>

                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Pan Card (Size - Maximum 1 MB) [Only (jpg,jpeg,pdf)
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            panCard: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                                           <p className="red">{FormFileerror.panCard}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Passport Size Photo (Size - Maximum 1 MB) [Only
                        (jpg,jpeg,pdf)] *
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            passportPhoto: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                     <p className="red">{FormFileerror.passportPhoto}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Shop Act License (Size - Maximum 1 MB) [Only
                        (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            shopAct_licence: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Rent Agreement (Size - Maximum 1 MB) [Only
                        (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            rentAgreement: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Signature (Size - Maximum 1 MB) [Only (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            signature: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                    </div>
                  </div>
                 
                  <div className="row text-center mt-5">
                    {
                    showloading ? (
                      <>
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) :
                    cid == undefined ? (
                      <div className="col-md-12">
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={(e) => {
                              COmpGstForm(e);
                            }}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </div>
                    ) : (
                      <div className="col-md-12">
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={(e) => {
                              editHandler(e);
                            }}
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </div>
                    )}

                    <div className="col-md-6">
                      {/* <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Reset
                        </Button>
                      </Form.Item> */}
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CompGST;
