import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { incNumber } from "../../Redux/actions";
import "./OurTeam.css";
function AddOurTeam() {
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.changeNumber);
  const [Employee, setEmployee] = useState([]);
  const [photoID, setPhotoId] = useState();
  const [Data, setData] = useState({
    FullName: "",
    Designation: "",
    Department: "",
  });
  const SubmitTeamData = (e) => {
    e.preventDefault();
    console.log(Data, photoID);
    const mainDataPromise = new Promise((resolve, reject) => {
      axios
        .post(`https://api.maharashtraseva.com/api/ourteam/create`, Data)
        .then((res) => {
          const response = res;
          resolve({
            status: true,
            message: "data Posted Successfully",
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            status: false,
            message: "Data Not posted",
          });
        });
    });
    mainDataPromise
      .then((res) => {
        console.log(124, res.data.Data._id);

        uploadpassportSize_Photo(res.data.Data._id);
        setData({
          ...Data,
          FullName: "",
          Designation: "",
          Department: "",
        });
        setPhotoId("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadpassportSize_Photo = (id) => {
    const formData = new FormData();
    formData.append("photoID", photoID);
    axios
      .put(`https://api.maharashtraseva.com/api/ourteam_PhotoId/${id}`, formData)
      .then((res) => {
        console.log("PhotoId", res.data);
        dispatch(incNumber());
        swal("Data Uploaded SuccesFully....");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/ourteam`)
      .then((res) => {
        console.log("banner", res.data);
        const data = res.data.reverse();
        setEmployee(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reducer]);
  const deleteUser = (id) => {
    console.log(56, id);

    axios
      .delete(`https://api.maharashtraseva.com/api/ourteam/${id}`)
      .then((res) => {
        swal("Deleted", "Image Deleted successfully!", "success");
        dispatch(incNumber());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Breadcrumb title={"Add Out Team "} parent={"license"} />

      <div className="row">
        <div className="col-lg-12">
          {/* <div className="row text-center" > */}

          <form class="row g-3 needs-validation" novalidate>
            <div class="col-md-4">
              <label for="validationCustom01" class="form-label">
                Full Name
              </label>
              <input
                type="text"
                placeholder="Plaese Enter Full Name!"
                class="form-control"
                id="validationCustom01"
                value={Data.FullName}
                onChange={(e) => {
                  setData({ ...Data, FullName: e.target.value });
                }}
                required
              />
              <div class="valid-feedback">Looks good!</div>
            </div>
            <div class="col-md-4">
              <label for="validationCustom02" class="form-label">
                Designation
              </label>
              <input
                type="text"
                placeholder="Enter Designation"
                class="form-control"
                id="validationCustom02"
                value={Data.Designation}
                onChange={(e) => {
                  setData({ ...Data, Designation: e.target.value });
                }}
                required
              />
              <div class="valid-feedback">Looks good!</div>
            </div>
            <div class="col-md-4">
              <label for="validationCustomUsername" class="form-label">
                Department
              </label>
              <div class="input-group has-validation">
                <input
                  type="text"
                  placeholder="Enter Department"
                  class="form-control"
                  id="validationCustomUsername"
                  aria-describedby="inputGroupPrepend"
                  //   value={Data.Department}
                  value={Data.Department}
                  onChange={(e) => {
                    setData({ ...Data, Department: e.target.value });
                  }}
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <label for="validationCustom03" class="form-label">
                Photo
              </label>
              <input
                type="file"
                class="form-control"
                id="validationCustom03"
                // value={photoID?.File?.name}
                onChange={(e) => {
                  setPhotoId(e.target.files[0]);
                }}
                required
              />
              <div class="invalid-feedback">
                Please provide Photo Of Employee.
              </div>
            </div>
            <div class="col-12">
              <button
                class="btn btn-primary"
                type="submit"
                onClick={(e) => {
                  SubmitTeamData(e);
                }}
              >
                Submit form
              </button>
            </div>
          </form>
          {/* </div> */}
        </div>
      </div>
      <hr />
      <div className="row">
        {/* {Employee.map((item) => {
          return (
            <>
              <div className="col-lg-3">
                <div class="card"  >
                    <div className="imgbox"style={{width: "18rem",padding: "5px",borderRadius: "4px",height:"18rem"}}>

                  <img  style={{height:"100%",width:"100%"}} src={item.photoID} class="card-img-top" alt="..." />
                    </div>
                  <div class="card-body">
                    <h5 class="card-title">Name : {item.FullName}</h5>
                    <h5 class="card-title">Designation : {item.Designation}</h5>
                    <h5 class="card-title">Department : {item.Department}</h5>
                    <i
                      class="bi bi-trash3 fs-2 text-danger"
                      style={{ cursor: "pointer", marginLeft: "200px" }}
                      onClick={(e) => deleteUser(item._id)}
                    ></i>
                  </div>
                </div>
                </div>
              
            </>
          );
        })} */}

        <div class="card-category-5">
          {/* <div class="category-name">Personal Cards</div> <br />
          <br /> */}
          <ul class="all-pr-cards">
            {Employee.map((item) => {
              return (
                <>
                  <li className="m-4">
                    <div class="per-card-1">
                      <div class="card-image">
                        <img src={item.photoID} />
                      </div>

                      <div class="card-content">
                        <div class="card-title">{item.FullName}</div>
                        <div class="per-position">{item.Designation}</div>
                        <div class="per-position">{item.Department}</div>
                        <div class="social-icons">
                        </div>
                        <div class="card-btn  text-center">
                          <button type="button" title="Delete">
                            <i
                              class="bi bi-trash3 fs-2 text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => deleteUser(item._id)}
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default AddOurTeam;
