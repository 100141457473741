import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import './styles.css';
// import './Application.css';
import swal from "sweetalert";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { incNumber } from "../../Redux/actions";
import Breadcrumb from "../common/breadcrumb";

function Application_renewal() {
  const navigate =useNavigate();

  const [status, setStatus] = useState("");
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));
  const dispatch = useDispatch();
  let { id } = useParams();
  const [subNote, setSubNote] = useState("");
  const [compNote, setCompNote] = useState("");
  const [rejNote, setRejNote] = useState("");
  const [selectedItem, setselectedItem] = useState(null);
  const formEle = useSelector((state) => state.formeleven.formEle);
  const [AllFOrm, setAllForm] = useState(formEle);
  const getData = () => {
    if (AllFOrm.length > 0) {
      console.log(31, AllFOrm);
      const selectedData = AllFOrm.find((item) => item._id === id);
      setselectedItem(selectedData);
    } else {
      axios
        .get("https://api.maharashtraseva.com/api/getAll/Form11")
        .then((res) => {
          const data = res.data;
          const selectedData = data.find((item) => item._id === id);
          setselectedItem(selectedData);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getData();
    // if (
    //   userRespons.user.role === "retailer" &&
    //   selectedItem?.status === "IN-PROGRESS"
    // ) {
    // } else if (
    //   userRespons.user.role === "admin" &&
    //   selectedItem?.status != "IN-PROGRESS"
    // ) {
    // } else {
    //   axios
    //     .put(`https://api.maharashtraseva.com/api/renew/${id}`, { isNew: false })
    //     .then((res) => {
    //       dispatch(incNumber());
    //     })
    //     .catch((err) => console.log(err));
    // }
    if(
      userRespons.user.role === "admin" &&
      selectedItem?.status != "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ){
      axios
      .put(`https://api.maharashtraseva.com/api/renew/${id}`, { isNew: true })
      .then((res) => {
        dispatch(incNumber());
      })
      .catch((err) => console.log(err));
    }
   else if (
      userRespons.user.role === "admin" &&
      selectedItem?.status == "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/renew/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status == "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/renew/${id}`, { isNew: true })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status != "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      console.log(78, "Ok");
      axios
        .put(`https://api.maharashtraseva.com/api/renew/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    }
  }, [id, formEle]);

  const [Acknowledgment, setAcknowledgment] = useState({
    Acknowledgment: "",
  });
  const [finalDocs, setfinalDocs] = useState({
    finalDocs: "",
  });

  const FormSubmit = (e) => {
    e.preventDefault();

    if (
      selectedItem.status === "IN-PROGRESS" ||
      selectedItem.status === "IN-Progress"
    ) {
      const formData = new FormData();

      formData.append("acknowledgmentDocument", Acknowledgment.Acknowledgment);
      axios
        .put(
          `https://api.maharashtraseva.com/api/renew_acknowledgmentDocument/${id}`,
          formData
        )
        .then((res) => console.log("acknowledgmentDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        subNote: subNote,
        submitAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/renew/${id}`, obj)
        .then((res) => {
          console.log("final document", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=> navigate("/admin_application"))
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedItem.status === "SUBMITTED") {
      const formData = new FormData();

      formData.append("finalDocument", finalDocs.finalDocs);
      axios
        .put(`https://api.maharashtraseva.com/api/renew_finalDocument/${id}`, formData)
        .then((res) => console.log("finalDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        compNote: compNote,
        CompleteAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/renew/${id}`, obj)
        .then((res) => {
          console.log("final Document", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=> navigate("/admin_application"))
        .catch((err) => {
          console.log(err);
        });
    }

    // console.log("updated object",obj);
    if (status === "REJECTED") {
      const obj = {
        ...selectedItem,
        status: status,
        rejNote: rejNote,
        rejectAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/renew/${id}`, obj)
        .then((res) => {
          dispatch(incNumber());
          swal("updated successfully", "success");
        })
        .catch((err) => console.log(err));
    }
  };
  const handleDownloadClick = (url) => {
    const imageUrl = url;

    const downloadLink = document.createElement("a");
    downloadLink.href = imageUrl;
    downloadLink.download = "image.jpg";
    downloadLink.click();
  };
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  return (
    <>
      <Breadcrumb title={"Renew DL"} />
      {selectedItem != null ? (
        <Container fluid={true}>
          <Row>
            <Col sm="">
              <Card>
                <CardBody>
                  <form class="inline-block form-inline ms-auto ">
                    <div
                      className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <div className="row">
                        <div className="col-lg-8">
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Details </span>
                        </h3>
                          
                        </div>
                        <div className="col-lg-4 mt-1">
                        <button
                            class="btn btn-outline-dark"
                            type="button"
                            onClick={() =>{
                              if (userRespons.user.role=="retailer") {
                                navigate(
                                  `${process.env.PUBLIC_URL}/retailer_console`
                                )
                              }else
                              navigate(
                                `${process.env.PUBLIC_URL}/admin_application`
                              )
                            }
                            }
                          >
                            Back
                          </button>
                        </div>
                      </div>
                      </div>
                      <div id="layoutSidenav_content">
                        <main>
                          <div class="container-fluid px-4 mt-4">
                            <div class="formlayout">
                              <div class="form-heading">
                                <label class="form-control form-control-lg">
                                  {selectedItem.application_type}
                                </label>
                              </div>

                              <br />

                              <div class="row g-3">
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>State :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.state}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>RTO:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.rto}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Application Type :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.vehical_type}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Permanant Licence Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.permanantsLicenceNo}{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> Applicant Name :</b>
                                  </label>
                                  <br />
                                  {selectedItem.application_first_name +
                                    " " +
                                    selectedItem.application_last_name}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Relation :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.relation}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Full Name as per Records :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.fullname_asper_record}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Gender :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.gender}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Date Of Birth :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Dob}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Place of Birth :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.pob}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Country of Birth :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.cob}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Qualification :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.qualification}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Blood Group :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.blood_group}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Mobile Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.mobile_No}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Email :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.email}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Identification Marks 1 :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.identification_mark1}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Identification Marks 2 :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.identification_mark2}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Emergency Mobile Number :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.emergency_mobNo}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Present Address State :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.present_Address_State}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Present Address District :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.present_Address_Distict}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Present Address Tehsil: :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.present_Address_tehsil}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Present Address Village/Town: :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Present_Address_Village}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Present Address Line1 :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Present_Address_Line_1}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Present Address Line2 :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Present_Address_Line_2}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Present Address Pincode :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.present_Adderess_PinCode}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                      Permanent Address Same as Present Address?
                                      :
                                    </b>{" "}
                                  </label>
                                  <br />
                                  {
                                    selectedItem.present_Address_Same_AS_Premant_Address
                                  }{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>permanent Address State :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.permanent_Address_State}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>permanent Address Distict :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.permanent_Address_Distict}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>permanent Address tehsil :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.permanent_Address_tehsil}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>permanent Address Village :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.permanent_Address_Village}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>permanent Address Line_1 :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.permanent_Address_Line_1}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>permanent Address Line 2 :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.permanent_Address_Line_2}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>permanent Adderess PinCode :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.permanent_Adderess_PinCode}{" "}
                                </div>
                                {/* <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>status History :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.status +
                                    "  " +
                                    selectedItem.createdAt}{" "}
                                </div> */}

                                <div class="col-md-6 ">
                                  <label>
                                    <b>Upload required documents: :</b>{" "}
                                  </label>
                                  <br />
                                  <b style={{ color: "red" }}>
                                    अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व
                                    आवश्यक कागदपत्रे तपासून व स्कॅन करून अपलोड
                                    करावे. जर आवश्यक कागदपत्रे चुकीची किंवा
                                    अस्पष्ट आढळल्यास सदर चा अर्ज फेटाळला जाऊ
                                    शकतो.
                                  </b>{" "}
                                </div>

                                <div class="col-md-6 ">
                                  <button
                                    style={{
                                      border: "none",
                                      color: "blue",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <a
                                      href={
                                        selectedItem.permanantDrivingLicence
                                      }
                                    >
                                      Permanent Driving License
                                    </a>
                                  </button>
                                  <br />
                                  <br />

                                  {selectedItem.acknowledgmentDocument !=
                                    null && (
                                    <>
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a
                                          href={
                                            selectedItem.acknowledgmentDocument
                                          }
                                        >
                                          Acknowledgment Document
                                        </a>
                                      </button>
                                      <br />
                                    </>
                                  )}

                                  {selectedItem.finalDocument != null && (
                                    <>
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.finalDocument}>
                                          Final Document
                                        </a>
                                      </button>
                                      <br />
                                    </>
                                  )}
                                </div>
                                <div className="col-lg-6">
                                  <div className="row">
                                    <div className="col-lg-12 ">
                                      <h3 className="fs-4 fw-3">
                                        <b>HISTORY</b>
                                      </h3>
                                    </div>
                                    <div className="col-lg-12 mt-2">
                                      <b>Current Status : </b>
                                      {selectedItem.status}
                                    </div>
                                    <div className="col-lg-12 ">
                                      <b>Created On : </b>
                                      {GetDate(selectedItem.createdAt)}
                                    </div>

                                    <div className="col-lg-12 mt-2">
                                      <b>Submit Note : </b>
                                      {selectedItem.subNote}
                                    </div>
                                    <div className="col-lg-12 ">
                                      <b>submit On : </b>
                                      {GetDate(selectedItem.submitAt)}
                                    </div>
                                    <div className="col-lg-12 mt-2">
                                      <b>Reject Note : </b>
                                      {selectedItem.rejNote}
                                    </div>
                                    <div className="col-lg-12 ">
                                      <b>Reject On : </b>
                                      {GetDate(selectedItem.rejectAt)}
                                    </div>

                                    <div className="col-lg-12 mt-2">
                                      <b>Complete Note : </b>
                                      {selectedItem.compNote}
                                    </div>
                                    <div className="col-lg-12 ">
                                      <b>Complete On : </b>
                                      {GetDate(selectedItem.CompleteAt)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                              {userRespons.user.role === "retailer" ? (
                                ""
                              ) : (
                                <>
                                  <div class="row g-3">
                                    {selectedItem.status == "IN-PROGRESS" ||
                                    selectedItem.status == "IN-Progress" ? (
                                      <div class="row g-3" id="dvtext">
                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            {" "}
                                            <b>
                                              {" "}
                                              Status:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>
                                          <br />
                                          <select
                                            name="state"
                                            class="form-select"
                                            required
                                            id="state"
                                            onChange={(e) =>
                                              setStatus(e.target.value)
                                            }
                                          >
                                            <option value="" selected>
                                              Select State
                                            </option>
                                            <option Value="SUBMITTED">
                                              SUBMITTED
                                            </option>
                                            <option Value="REJECTED">
                                              REJECTED
                                            </option>
                                          </select>
                                        </div>

                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            <b>
                                              Note:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>{" "}
                                          <br />
                                          <textarea
                                            name="note"
                                            class="form-control"
                                            onChange={(e) => {
                                              if (status == "SUBMITTED") {
                                                setSubNote(e.target.value);
                                              } else {
                                                setRejNote(e.target.value);
                                              }
                                            }}
                                          >
                                            {" "}
                                          </textarea>{" "}
                                        </div>
                                        {status === "SUBMITTED" && (
                                          <div class="col-md-6 mt-4 ">
                                            <label>
                                              <b>Acknowledgment Document </b>{" "}
                                            </label>
                                            <input
                                              type="file"
                                              name=""
                                              id=""
                                              onChange={(e) => {
                                                setAcknowledgment({
                                                  ...Acknowledgment,
                                                  Acknowledgment:
                                                    e.target.files[0],
                                                });
                                                // console.log(992,e);
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : selectedItem.status == "SUBMITTED" ? (
                                      <div class="row g-3" id="dvtext">
                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            {" "}
                                            <b>
                                              {" "}
                                              Status:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>
                                          <br />
                                          <select
                                            name="state"
                                            class="form-select"
                                            required
                                            id="state"
                                            onChange={(e) =>
                                              setStatus(e.target.value)
                                            }
                                          >
                                            <option value="" selected>
                                              Select State
                                            </option>

                                            <option Value="COMPLETE">
                                              COMPLETE
                                            </option>
                                          </select>
                                        </div>

                                        <div class="col-md-6 mt-4 ">
                                          <label>
                                            <b>
                                              Note:
                                              <span class="text-danger">*</span>
                                            </b>{" "}
                                          </label>{" "}
                                          <br />
                                          <textarea
                                            name="note"
                                            class="form-control"
                                            onChange={(e) => {
                                              setCompNote(e.target.value);
                                            }}
                                          >
                                            {" "}
                                          </textarea>{" "}
                                        </div>
                                        {status === "COMPLETE" && (
                                          <div class="col-md-6 mt-4 ">
                                            <label>
                                              <b>Final Document </b>{" "}
                                            </label>
                                            <input
                                              type="file"
                                              name=""
                                              id=""
                                              onChange={(e) => {
                                                setfinalDocs({
                                                  ...finalDocs,
                                                  finalDocs: e.target.files[0],
                                                });
                                                // console.log(992,e);
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <div class="row g-3 " id="dvtext">
                                      <div class="col-md-12">
                                        <button
                                          type="submit"
                                          name="submit"
                                          id="btnsubmit"
                                          class="btn btn-primary"
                                          value="Submit"
                                          onClick={(e) => {
                                            FormSubmit(e);
                                          }}
                                        >
                                          Submit
                                        </button>

                                        <button
                                          type="submit"
                                          name="submit"
                                          id="btnsubmit"
                                          class="btn btn-primary "
                                          value="Submit"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                    {/* </div>  */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </main>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Card
            style={{
              height: "80vh",
              width: "80vw",
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <h3>Loading...</h3>
          </Card>
        </>
      )}
    </>
  );
}

export default Application_renewal;
