import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { incNumber } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form, Select } from "antd";
import Breadcrumb from "../common/breadcrumb";

function AgentRequests() {
  const dispatch = useDispatch();
  const [Data, setData] = useState();
  const [query, setQuery] = useState("");

  const [reqAccept, setreqAccept] = useState();
  const [AcceptedData, setAcceptData] = useState();
  const [AllData, setAllData] = useState();
  const [startDate, setStartDate] = useState(""); // State to store start date
  const [endDate, setEndDate] = useState("");
  let i = 0;
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const reducer = useSelector((state) => state.changeNumber);
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/withdrowWallet`)
      .then((res) => {
        const response = res.data.reverse();
        if (userData.user.role === "agent") {
          const singleAgent = response.filter(
            (item) => item.agentId === userData.user._id
          );
          setData(singleAgent);
          setAllData(singleAgent);
          // setData(singleAgent);
        } else {
          setData(response);
          setAllData(response);
        }

        // console.log(12, response);
        // const FilterData = response.filter(
        //   (item) => item.isAcceted === "false"
        // );
      })
      .catch((err) => console.log(32, err));
  }, [reducer]);
  // if (userData.user.role=="agent") {
  //   const GetDatabyUser=AllData.filter((item=>item.agentId===userData.user._id))
  //   console.log(21,GetDatabyUser)
  //   setData(GetDatabyUser)

  // }
  const AcceptRequest = (id) => {
    const getOBj = Data.filter((item) => item._id === id);
    console.log(29, getOBj);

    const obj = {
      agentId: getOBj[0].agentId,
      agentName: getOBj[0].agentName,
      creaditAmount: getOBj[0].withdrowReqAmount,
      isWithdrowl: true,
      isrequest: false,
      creaditFor: "Accepted",
      creaditBy: "Admin",
    };
    axios
      .post(`https://api.maharashtraseva.com/api/wallet/create`, obj)
      .then((res) => {
        const response = res;
        dispatch(incNumber());
        // toast.success("Request Accepted Succesfully.....");
      })
      .catch((err) => {
        console.log(err);
      });

    const obj1 = {
      ...getOBj,
      isAcceted: "true",
      status: "Accepted",
    };
    axios
      .put(`https://api.maharashtraseva.com/api/withdrowWalletlet/${id}`, obj1)
      .then((res) => {
        const response = res;
        dispatch(incNumber());
        toast.success("Request Accepted Succesfully.....");
        i++;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: "New",
      selector: (row, i) => i + 1,
    },

    {
      name: "Send Request By ",
      selector: (row) => row.agentName,
    },
    {
      name: "Request Amount",
      selector: (row) => row.withdrowReqAmount,
    },
    {
      name: "note ",
      selector: (row) => row.note,
    },
    {
      name: "status ",
      selector: (row) => row.status,
    },
    {
      name: "Date ",
      selector: (row) => GetDate(row.createdAt),
    },

    // {

    //   name: "Accept Request  ",
    //   selector: (row) => (
    //     <>
    //       {row.isAcceted == "true"  ? (
    //         ""
    //       ) : (
    //         <>
    //           <button
    //             className="btn btn-outline-dark"
    //             onClick={(e) => AcceptRequest(row._id)}
    //           >
    //             Accept{" "}
    //           </button>
    //         </>
    //       )}
    //     </>
    //   ),
    // },
  ];
  if (userData.user.role == "admin") {
    columns.push({
      name: "Accept Request  ",
      selector: (row) => (
        <>
          {row.isAcceted == "true" ? (
            ""
          ) : (
            <>
              <button
                className="btn btn-outline-dark"
                onClick={(e) => AcceptRequest(row._id)}
              >
                Accept{" "}
              </button>
            </>
          )}
        </>
      ),
    });
  }
  // Replace /* your condition here */ with the actual condition that determines whether the "Accept Request" column should be included in the columns array. If the condition is true, the column will be added to the array; otherwise, it won't be included.

  // const customStyles = {
  //   rows: {
  //     style: {
  //       border: "1px solid black",
  //       fontWaight: "900",
  //       // Add any other CSS properties you want to apply to the rows
  //       fontSize: "22px",
  //     },
  //   },
  //   headRow: {
  //     style: {
  //       // Add any styles for the header row
  //       fontWaight: "900",
  //       fontSize: "15px",
  //       border: "1px solid black",
  //       background: "#f36621",
  //       color: "white",
  //       textAlign: "center",
  //     },
  //   },
  //   headCells: {
  //     style: {
  //       // Add any styles for the header cells
  //       fontSize: "12px",
  //       border: "1px solid black",
  //     },
  //   },
  //   cells: {
  //     style: {
  //       fontSize: "12px",
  //       color: "black",
  //       // Add any styles for the regular cells
  //       border: "1px solid black",
  //     },
  //   },
  //   pagination: {
  //     style: {
  //       // Add any styles for the pagination section
  //       border: "1px solid black",
  //     },
  //   },
  // };
  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWeight:"600",
       fontSize:"18px",
      //  width:"fit-content"
        // Add any other CSS properties you want to apply to the rows
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        border: "1px solid black",
        backgroundColor:"#0094ef  ",
        color: "white",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        border: "1px solid black",
         width:"fit-content"
      },
    },
    cells: {
      style: {
        // Add any styles for the regular cells
        border: "1px solid black",
        width:"fit-content"
      },
    },
  };
  const searchByDateRange = () => {
    let credit = 0;
    const filtered = Data.filter((item) => {
      const createdDate = new Date(item.createdAt);
      return (
        createdDate >= new Date(startDate) && createdDate <= new Date(endDate)
      );
    });
    // setFilter(filtered);
    setData(filtered);
  };

  return (
    <>
      <Breadcrumb title="Withdrow Requests" parent="applicaSta" />

      {userData.user.role === "admin" ? (
        <>
          <div className="col-md-4">
            <label htmlFor="" className="mb-3">
              {" "}
              Payment Status
            </label>
            <Form.Item>
              <Select
                placeholder="--Select Application Stauts--"
                onChange={(e) => {
                  if (e == "ALL") {
                    console.log(31, "All");
                    setData(AllData);
                  } else if (e == "PENDING") {
                    const pendding = AllData.filter(
                      (item) => item.isAcceted === "false"
                    );
                    setData(pendding);
                    console.log(31, "PENDING");
                  } else if (e == "ACCEPTED") {
                    // console.log("ok")
                    const FilterData = AllData.filter(
                      (item) => item.isAcceted === "true"
                    );
                    setData(FilterData);
                    console.log(31, "ACCEPTED");
                  }
                }}
              >
                <Select.Option value="ALL">ALL</Select.Option>
                <Select.Option value="PENDING">PENDING</Select.Option>
                <Select.Option value="ACCEPTED">ACCEPTED</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </>
      ) : (
        <>
          <div className="col-md-4">
            <label htmlFor="" className="mb-3">
              {" "}
              Payment Status
              <span className="red">*</span>
            </label>
            <Form.Item>
              <Select
                placeholder="--Select Application Stauts--"
                onChange={(e) => {
                  if (e == "ALL") {
                    console.log(31, "All");
                    setData(AllData);
                  } else if (e == "PENDING") {
                    const pendding = AllData.filter(
                      (item) => item.isAcceted === "false"
                    );
                    setData(pendding);
                    console.log(31, "PENDING");
                  } else if (e == "ACCEPTED") {
                    // console.log("ok")
                    const FilterData = AllData.filter(
                      (item) => item.isAcceted === "true"
                    );
                    setData(FilterData);
                    console.log(31, "ACCEPTED");
                  }
                }}
              >
                <Select.Option value="ALL">ALL</Select.Option>
                <Select.Option value="PENDING">PENDING</Select.Option>
                <Select.Option value="ACCEPTED">ACCEPTED</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </>
      )}
      <div className="row rext center m-4">
        <div className="col-lg-4">
          <input
            type="date"
            class="form-control"
            id="inputEmail4"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-lg-4">
          <input
            type="date"
            placeholder="End Date"
            class="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="col-lg-4">
          <button
            onClick={searchByDateRange}
            className="btn btn-outline-primary"
          >
            Search
          </button>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={Data}
        pagination={true}
        customStyles={customStyles}
      />
    </>
  );
}

export default AgentRequests;
