import React from "react";
import axios from "axios";
import { MdUploadFile } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { toast } from "react-toastify";
// import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { incNumber } from "../../Redux/actions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import data from "../../assets/data/listCoupons";
import {
  NonCreamyLayer_income_input,
  districtData,
} from "../../constants/data";
import { occupation_income_input, work_type } from "../../constants/data";
import swal from "sweetalert";

function NonCreamyLayer() {
  const navigate = useNavigate();
  const [showloading, setShowloading] = useState(false);

  const { id } = useParams();
  const [birthdate, setBirthdate] = useState("");
  const [age, setAge] = useState(null);
  const dispatch = useDispatch();
  const [Formerror, setFormerror] = useState({});
  const [FormFileerror, setFormFileerror] = useState({});
  const [nameMarathi, setnameMarathi] = useState("");
  const [fnameMarathi, setfnameMarathi] = useState("");
  const [mnameMarathi, setmnameMarathi] = useState("");
  const [snameMarathi, setsnameMarathi] = useState("");
  const [ShowBeniDetails, setBenificiaryDetails] = useState(false);

  const [agree, setAgree] = useState(false);
  const [items, setItems] = useState();
  const ID = items?.user?._id;
  const createdByName = items?.user?.name;
   const [form] = Form.useForm();
  const [Data, SetData] = useState({
    statusfname: "",
    formPrice:"",
    fullName_English: "",
    fullName_Marathi: "",
    fatherName_english: "",
    fatherName_marathi: "",
    BirthDate: "",
    age: "",
    Gender: "",
    phoneNUm: "",
    email: "",
    UDI_NO: "",
    Applicant_address: "",
    Applicant_street: "",
    Applicant_Locality: "",
    Applicant_Building: "",
    Applicant_landmark: "",
    Applicant_district: "",
    Applicant_taluka: "",
    Applicant_village: "",
    Applicant_pincode: "",
    Applicant_CurrentOccupation: "",
    relationOfBanificiaryWithApplicant: "",
    benificiary_Salutation: "",
    benificiary_FullNameEnglish: "",
    benificiary_FullNameMarathi: "",
    benificiary_Dob: "",
    benificiary_Gender: "",
    benificiary_MobileNO: "",
    benificiary_Email: "",
    benificiary_Uid: "",
    benificiary_Address: "",
    benificiary_Street: "",
    benificiary_Locality: "",
    benificiary_Building: "",
    benificiary_Landmark: "",
    benificiary_District: "",
    benificiary_Taluka: "",
    benificiary_Village: "",
    benificiary_Pincode: "",
    benificiaryFatherOrHusbund_Salutation: "",
    benificiaryFatherOrHusbund_FullNameEnglish: "",
    benificiaryFatherOrHusbund_FullNameMarathi: "",
    benificiaryFatherOrHusbund_Dob: "",
    benificiaryFatherOrHusbund_Gender: "",
    benificiaryFatherOrHusbund_MobileNO: "",
    benificiaryFatherOrHusbund_Email: "",
    benificiaryFatherOrHusbund_Uid: "",
    benificiary_Cast: "",
    benificiary_Catagary: "",
    brnificiary_Validity_Duration: "",
    migration_MH_From_Another_State: "",
    Migrant_Distict: "",
    Migrant_Taluka: "",
    Migrant_village: "",
    isAppliedForCastCertificate: "",
    is_Certificate_Given: "",
    previos_CastCerNum: "",
    previos_CastCerPlaceIssue: "",
    previos_CastCerIssuedDate: "",
    propowner: "",
    txtLocation: "",
    txtarea: "",
    ddlareaunits: "",
    checkbox: "",
    txtirriarea: "",
    ddlunits: "",
    txtpercentage: "",
    txtformula: "",
    txtceilingactform: "",
    ddlcultitype: "",
    txtcultilocation: "",
    txtcultiarea: "",
    ddlcultiunit: "",
    txtproploc: "",
    txtpropdetails: "",
    txtpropuse: "",
    CPH_radiobtnpayee: "",
    radiobtnwealthtax: "",
    ddlmothersal: "",
    txtmothername: "",
    txtmothername_LL: "",
    ddlspousesal: "",
    txtSpouseName: "",
    txtspousename_LL: "",
    ddlfam_dist: "",
    ddlfam_taluka: "",
    ddlfam_Vill: "",
    txtfam_pincode: "",
    txtbenefreason: "",
    ctl00$CPH$Affd: "",
  });
  useEffect(() => {
    const userRespons = JSON.parse(localStorage.getItem("userResponse"));
    if (userRespons) {
      setItems(userRespons);
    }
  }, []);
  const users = useSelector((state) => state.userData.user);
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const reducer = useSelector((state) => state.changeNumber);
  const [getAgent, setgetAgent] = useState([]);
  const [GetFormPrice, setGetFormPrice] = useState();
  const getData = () => {
    axios
      .get(`https://api.maharashtraseva.com/api/user/getAll`)
      .then((res) => {
        const response = res.data;
        // setUser(response);
        const userdata = JSON.parse(localStorage.getItem("userResponse"));
        if (userdata) {
          const getFormPrice = response.find(
            (item) => item._id === userdata.user._id
          );
          const getagent = response.filter(
            (item) => item._id === getFormPrice.agent
          );
          // setuserData(userdata);
          setgetAgent(getagent);
          setGetFormPrice(
            getFormPrice?.retaile_formPrice?.retailer_nonCriminal
          );
          SetData({...Data,formPrice:getFormPrice?.retaile_formPrice?.retailer_nonCriminal})
        }
        // console.log(37,response)
      })
      .catch((err) => console.log(40, err));
  };
  useEffect(() => {
    setTimeout(getData(), 1000);
  }, []);
  const [balance, setBalance] = useState(0);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userResponse"));
    if (userData) {
      axios.get(`https://api.maharashtraseva.com/api/recharge`).then((res) => {
        console.log(88, res.data);

        const allData = res.data.filter(
          (ele) => ele.user === userData.user._id
        );
        let amount = 0;
        let expence = 0;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.isExpence == "true") {
              expence += item.amount;
            } else {
              amount += item.amount;
            }
          });
        }
        console.log(53, amount, expence);
        setBalance(amount - expence);
      });
    }
  }, [reducer]);
  const [docs, setDocs] = useState({
    reshanCard: "",
    adharCard: "",
    lightBill: "",
    SchoolLeaveCertificate: "",
    photo: "",
    incomeCertificate3year: "",
    castCertificate: "",
    taxRecipt: "",
    FatherAdhar: "",
    childrenAdhar: "",
    applicantPhoto: "",
    benificiarryPhoto: "",
    fatherSchoolLeaveCer: "",
    childrenSchoolLeaveCer: "",
    otherFirst: "",
    otherSecond: "",
  });
 
  const [NonCreamyLayerincomeSource, setNoncreamyIncomeSource] = useState([]);
  const handleIncomeChange = (e) => {
    const find = NonCreamyLayerincomeSource.find(
      (data) => data.source === e.target.name
    );
    console.log(e.target.tabIndex);
    if (e.target.tabIndex === 0) {
      NonCreamyLayerincomeSource[NonCreamyLayerincomeSource.indexOf(find)][
        "discription"
      ] = e.target.value;
    } else {
      NonCreamyLayerincomeSource[NonCreamyLayerincomeSource.indexOf(find)][
        e.target.tabIndex
      ] = e.target.value;
    }
    console.log(NonCreamyLayerincomeSource);
  };

  useEffect(() => {
    setNoncreamyIncomeSource([
      {
        source: "Agriculture & Related Assets",
        2021: 0,
        2122: 0,
        2223: 0,
      },
      {
        source: "Business & other sources",
        2021: 0,
        2022: 0,
        2023: 0,
      },
      {
        source: "Salary/Pension/Other Resource",
        2021: 0,
        2022: 0,
        2023: 0,
      },
    ]);
  }, []);

  const [occupation_income, setoccupation_income] = useState([]);
  const handleOccupationChange = (e) => {
    const find = occupation_income.find(
      (data) => data.sourceName === e.target.name
    );
    console.log(e.target.tabIndex);
    if (e.target.tabIndex === 0) {
      occupation_income[occupation_income.indexOf(find)]["source"] =
        e.target.value;
    } else {
      occupation_income[occupation_income.indexOf(find)][e.target.tabIndex] =
        e.target.value;
    }
    console.log(occupation_income);
  };

  useEffect(() => {
    setoccupation_income([
      {
        person: "Father",
        type: 0,
        organizationName: 0,
        designation: 0,
        department: 0,
        salaryRange: 0,
        annualIncome: 0,
        dateOfJoining: "",
        dateOfRetirement: "",
        typeOfBusiness: 0,
        totalAnnualIncome: 0,
      },
      {
        person: "Mother",
        type: 0,
        organizationName: 0,
        designation: 0,
        department: 0,
        salaryRange: 0,
        annualIncome: 0,
        dateOfJoining: "",
        dateOfRetirement: "",
        typeOfBusiness: 0,
        totalAnnualIncome: 0,
      },
      {
        person: "Spouse",
        type: 0,
        organizationName: 0,
        designation: 0,
        department: 0,
        salaryRange: 0,
        annualIncome: 0,
        dateOfJoining: "",
        dateOfRetirement: "",
        typeOfBusiness: 0,
        totalAnnualIncome: 0,
      },
    ]);
  }, []);
  const FileValidate = (Val) => {
    let fileError = {};

    if (!Val.reshanCard) {
      fileError.reshanCard = "File is Required";
    } else {
      if (Val.reshanCard.size > 1024 * 1024) {
        fileError.reshanCard = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.adharCard) {
      fileError.adharCard = "File is Required";
    } else {
      if (Val.adharCard.size > 1024 * 1024) {
        fileError.adharCard = " File size exceeds the limit of 1MB";
      }
    }
   
  
   
   
  
    if (!Val.childrenSchoolLeaveCer) {
      fileError.childrenSchoolLeaveCer = "File is Required";
    } else {
      if (Val.childrenSchoolLeaveCer.size > 500 * 1024) {
        fileError.childrenSchoolLeaveCer =
          " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.fatherSchoolLeaveCer) {
      fileError.fatherSchoolLeaveCer = "File is Required";
    } else {
      if (Val.fatherSchoolLeaveCer.size > 500 * 1024) {
        fileError.fatherSchoolLeaveCer =
          " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.benificiarryPhoto) {
      fileError.benificiarryPhoto = "File is Required";
    } else {
      if (Val.benificiarryPhoto.size > 500 * 1024) {
        fileError.benificiarryPhoto = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.applicantPhoto) {
      fileError.applicantPhoto = "File is Required";
    } else {
      if (Val.applicantPhoto.size > 500 * 1024) {
        fileError.applicantPhoto = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.childrenAdhar) {
      fileError.childrenAdhar = "File is Required";
    } else {
      if (Val.childrenAdhar.size > 500 * 1024) {
        fileError.childrenAdhar = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.FatherAdhar) {
      fileError.FatherAdhar = "File is Required";
    } else {
      if (Val.FatherAdhar.size > 500 * 1024) {
        fileError.FatherAdhar = " File size exceeds the limit of 500kb";
      }
    }
    return fileError;
  };
  const Validate = (value) => {
    const error = {};
    if (!value.fullName_English) {
      error.fullName_English = "Please enter full Name English";
    }
    if (!value.fullName_Marathi) {
      error.fullName_Marathi = "Please enter full Name Marathi";
    }
    if (!value.fatherName_english) {
      error.fatherName_english = "Please enter father Name Marathi";
    }
    if (!value.fatherName_marathi) {
      error.fatherName_marathi = "Please enter father Name Marathi";
    }
    if (!value.age) {
      error.age = "Please enter Age";
    }
    if (!value.Gender) {
      error.Gender = "Please enter Gender";
    }
    if (!value.phoneNUm) {
      error.phoneNUm = "Please enter Phone Number";
    }
    if (!value.Applicant_address) {
      error.Applicant_address = "Please enter Applicant address";
    }
    if (!value.Applicant_district) {
      error.Applicant_district = "Please enter Applicant District";
    }
    if (!value.Applicant_taluka) {
      error.Applicant_taluka = "Please enter Applicant Taluka";
    }
    if (!value.Applicant_village) {
      error.Applicant_village = "Please enter Applicant Village";
    }
    if (!value.Applicant_pincode) {
      error.Applicant_pincode = "Please enter Applicant Pincode";
    }
    if (!value.relationOfBanificiaryWithApplicant) {
      error.relationOfBanificiaryWithApplicant =
        "Please select relation Of Banificiary With Applicant";
    }
    if (!value.benificiary_Cast) {
      error.benificiary_Cast = "Please select Banificiary Cast";
    }
    if (!value.benificiary_Catagary) {
      error.benificiary_Catagary = "Please select Banificiary Catagary";
    }
    if (!value.brnificiary_Validity_Duration) {
      error.brnificiary_Validity_Duration = "Please select Validity Duration";
    }
    return error;
  };
  const temp = (e) => {
    const find = occupation_income.find(
      (data) => data.person === e.target.name
    );
    if (e.target.tabIndex === 0) {
      occupation_income[occupation_income.indexOf(find)]["type"] =
        e.target.value;
    } else if (e.target.tabIndex === 1) {
      occupation_income[occupation_income.indexOf(find)]["organizationName"] =
        e.target.value;
    } else if (e.target.tabIndex === 2) {
      occupation_income[occupation_income.indexOf(find)]["designation"] =
        e.target.value;
    } else if (e.target.tabIndex === 3) {
      occupation_income[occupation_income.indexOf(find)]["department"] =
        e.target.value;
    } else if (e.target.tabIndex === 4) {
      occupation_income[occupation_income.indexOf(find)]["salaryRange"] =
        parseInt(e.target.value);
    } else if (e.target.tabIndex === 5) {
      occupation_income[occupation_income.indexOf(find)]["annualIncome"] =
        parseInt(e.target.value);
    } else if (e.target.tabIndex === 6) {
      occupation_income[occupation_income.indexOf(find)]["dateOfJoining"] =
        e.target.value;
    } else if (e.target.tabIndex === 7) {
      occupation_income[occupation_income.indexOf(find)]["dateOfRetirement"] =
        e.target.value;
    } else if (e.target.tabIndex === 8) {
      occupation_income[occupation_income.indexOf(find)]["typeOfBusiness"] =
        e.target.value;
    } else if (e.target.tabIndex === 9) {
      occupation_income[occupation_income.indexOf(find)]["totalAnnualIncome"] =
        parseInt(e.target.value);
    }
  };
  const [Caste_Certificate1, SetCastcertificate] = useState(false);
  const [MigrantAddressDetails, SetMigrantAddressDetails] = useState(false);
  const [talukas, setTalukas] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(359, name, value);
    SetData({ ...Data, [name]: value });
  };
  const setTalukasData = (e) => {
    console.log(340, e);
    if (e) {
      const taluka = districtData.filter((item) => item.name === e);
      console.log(597, taluka[0].tahasil);
      setTalukas(taluka[0].tahasil);
    } else {
      console.log("Flop");
    }
  };
  const postLDJData = (e) => {
    e.preventDefault();
    setShowloading(true);
    const Formerr = Validate(Data);
    const Fileerr = FileValidate(docs);
    if (Object.keys(Fileerr).length === 0) {
      if (agree) {
        if (balance > GetFormPrice) {
          const obj = {
            application_type: "Non_Cremylayer certificate",
            status: "IN-PROGRESS",
            createdBy: ID,
            createdByName,
            agentId: getAgent[0]._id,
            agentName: getAgent[0].name,
            Data,
            NonCreamyLayerincomeSource,
            occupation_income,
          };
          console.log(342, obj);

          const mainDataPromise = new Promise((resolve, reject) => {
            // console.log(77, Data);
            axios
              .post(`https://api.maharashtraseva.com/api/Non_Criminal/create`, obj)
              .then((res) => {
                const response = res;
                resolve({
                  status: true,
                  message: "data Posted Successfully",
                  data: res.data,
                });
                setShowloading(false);
              })
              .catch((err) => {
                console.log(err);
                reject({
                  status: false,
                  message: "Data Not posted",
                });
                setShowloading(false);
              });
          });

          mainDataPromise
            .then((res) => {
              console.log(124, res.data);

              uploadreshaCard(res.data.data._id);
              // uploadadharCard(res.data.data._id);
              uploadlightBill(res.data.data._id);
              uploadschoolLeaveCertificate(res.data.data._id);
              // uploadphoto(res.data.data._id);
              uploadincomeCertficate3year(res.data.data._id);
              uploadcastCertificate(res.data.data._id);

              uploadtaxRecipt(res.data.data._id);
              uploadFatherAdhar(res.data.data._id);
              uploadFatherAdhar(res.data.data._id);
              uploadchildrenAdhar(res.data.data._id);
              uploadapplicantPhoto(res.data.data._id);
              uploadbenificiarryPhoto(res.data.data._id);
              uploadfatherSchoolLeaveCer(res.data.data._id);
              uploadchildrenSchoolLeaveCer(res.data.data._id);
              uploadotherFirst(res.data.data._id);
              uploadotherSecond(res.data.data._id);
              debitFormBalance();
              CreaditAgent();
              SetData({
                ...Data,
                statusfname: "",
                fullName_English: "",
                fullName_Marathi: "",
                fatherName_english: "",
                fatherName_marathi: "",
                BirthDate: "",
                age: "",
                Gender: "",
                phoneNUm: "",
                email: "",
                UDI_NO: "",
                Applicant_address: "",
                Applicant_street: "",
                Applicant_Locality: "",
                Applicant_Building: "",
                Applicant_landmark: "",
                Applicant_district: "",
                Applicant_taluka: "",
                Applicant_village: "",
                Applicant_pincode: "",
                Applicant_CurrentOccupation: "",
                relationOfBanificiaryWithApplicant: "",
                benificiary_Salutation: "",
                benificiary_FullNameEnglish: "",
                benificiary_FullNameMarathi: "",
                benificiary_Dob: "",
                benificiary_Gender: "",
                benificiary_MobileNO: "",
                benificiary_Email: "",
                benificiary_Uid: "",
                benificiary_Address: "",
                benificiary_Street: "",
                benificiary_Locality: "",
                benificiary_Building: "",
                benificiary_Landmark: "",
                benificiary_District: "",
                benificiary_Taluka: "",
                benificiary_Village: "",
                benificiary_Pincode: "",
                benificiaryFatherOrHusbund_Salutation: "",
                benificiaryFatherOrHusbund_FullNameEnglish: "",
                benificiaryFatherOrHusbund_FullNameMarathi: "",
                benificiaryFatherOrHusbund_Dob: "",
                benificiaryFatherOrHusbund_Gender: "",
                benificiaryFatherOrHusbund_MobileNO: "",
                benificiaryFatherOrHusbund_Email: "",
                benificiaryFatherOrHusbund_Uid: "",
                benificiary_Cast: "",
                benificiary_Catagary: "",
                brnificiary_Validity_Duration: "",
                migration_MH_From_Another_State: "",
                Migrant_Distict: "",
                Migrant_Taluka: "",
                Migrant_village: "",
                isAppliedForCastCertificate: "",
                is_Certificate_Given: "",
                previos_CastCerNum: "",
                previos_CastCerPlaceIssue: "",
                previos_CastCerIssuedDate: "",
                propowner: "",
                txtLocation: "",
                txtarea: "",
                ddlareaunits: "",
                checkbox: "",
                txtirriarea: "",
                ddlunits: "",
                txtpercentage: "",
                txtformula: "",
                txtceilingactform: "",
                ddlcultitype: "",
                txtcultilocation: "",
                txtcultiarea: "",
                ddlcultiunit: "",
                txtproploc: "",
                txtpropdetails: "",
                txtpropuse: "",
                CPH_radiobtnpayee: "",
                radiobtnwealthtax: "",
                ddlmothersal: "",
                txtmothername: "",
                txtmothername_LL: "",
                ddlspousesal: "",
                txtSpouseName: "",
                txtspousename_LL: "",
                ddlfam_dist: "",
                ddlfam_taluka: "",
                ddlfam_Vill: "",
                txtfam_pincode: "",
                txtbenefreason: "",
                ctl00$CPH$Affd: "",
              });
             
            }).then(() => navigate("/RetailerDashboard"))
            .catch((err) => {
              console.log(err);
            });
        } else {
          toast.error("Please Recharge");
          setShowloading(false);
        }
      } else {
        swal("please Accept Aggrement");
        setShowloading(false);
      }
    } else {
      setFormerror(Formerr);
      setFormFileerror(Fileerr);
      swal("Please FIll Required Fild", "error");
      setShowloading(false);
    }
  };

  const CreaditAgent = () => {
    const obj = {
      agentId: getAgent[0]._id,
      agentName: getAgent[0].name,
      creaditAmount: getAgent[0].agent_formPrice.agent_nonCriminal,
      isWithdrowl: false,
      isrequest: false,
      creaditFor: "Non Criminal",
      creaditBy: userData.user._id,
    };
    console.log(292, obj);
    axios
      .post(`https://api.maharashtraseva.com/api/wallet/create`, obj)
      .then((res) => {
        const response = res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const debitFormBalance = () => {
    const obj = {
      user: userData?.user._id,
      mode: "offline",
      amount: GetFormPrice,
      isExpence: true,
      expenceFor: "Non Criminal",
    };
    axios
      .post("https://api.maharashtraseva.com/api/recharge/create", obj)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());

        swal("Good job!", "form submitted successfully!", "success");
        // navigate("/RetailerForm4")
      })
      .catch((err) => console.log(34, err));
  };

  const uploadreshaCard = (id) => {
    const formData = new FormData();
    formData.append("reshanCard", docs.reshanCard);
    axios
      .put(`https://api.maharashtraseva.com/api/NonCriminal_reshaCard/${id}`, formData)
      .then((res) => console.log("reshanCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadadharCard = (id) => {
    const formData = new FormData();
    formData.append("adharCard", docs.adharCard);
    axios
      .put(`https://api.maharashtraseva.com/api/NonCriminal_adharCard/${id}`, formData)
      .then((res) => console.log("adharCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadlightBill = (id) => {
    const formData = new FormData();
    formData.append("lightBill", docs.lightBill);
    axios
      .put(`https://api.maharashtraseva.com/api/NonCriminal_lightBill/${id}`, formData)
      .then((res) => console.log("lightbill", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadschoolLeaveCertificate = (id) => {
    const formData = new FormData();
    formData.append("schoolLeaveCertificate", docs.SchoolLeaveCertificate);
    axios
      .put(
        `https://api.maharashtraseva.com/api/NonCriminal_schoolLeaveCertificate/${id}`,
        formData
      )
      .then((res) => console.log("schoolLeaveCertificate", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadphoto = (id) => {
    const formData = new FormData();
    formData.append("photo", docs.photo);
    axios
      .put(`https://api.maharashtraseva.com/api/NonCriminal_photo/${id}`, formData)
      .then((res) => console.log("photo", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadincomeCertficate3year = (id) => {
    const formData = new FormData();
    formData.append("incomeCertficate3year", docs.incomeCertificate3year);
    axios
      .put(
        `https://api.maharashtraseva.com/api/NonCriminal_incomeCertficate3year/${id}`,
        formData
      )
      .then((res) => console.log("taxBillOr15yerOldLightbill", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadcastCertificate = (id) => {
    const formData = new FormData();
    formData.append("castCertificate", docs.castCertificate);
    axios
      .put(`https://api.maharashtraseva.com/api/NonCriminal_castProof/${id}`, formData)
      .then((res) => console.log("castCertificate", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadtaxRecipt = (id) => {
    const formData = new FormData();
    formData.append("taxRecipt", docs.taxRecipt);
    axios
      .put(`https://api.maharashtraseva.com/api/NonCriminal_taxrecipt/${id}`, formData)
      .then((res) => console.log("selfDeclearation", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadFatherAdhar = (id) => {
    const formData = new FormData();
    formData.append("FatherAdhar", docs.FatherAdhar);
    axios
      .put(`https://api.maharashtraseva.com/api/NonCriminal_FatherAdhar/${id}`, formData)
      .then((res) => console.log("FatherAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadchildrenAdhar = (id) => {
    const formData = new FormData();
    formData.append("childrenAdhar", docs.childrenAdhar);
    axios
      .put(
        `https://api.maharashtraseva.com/api/NonCriminal_childrenAdhar/${id}`,
        formData
      )
      .then((res) => console.log("childrenAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadapplicantPhoto = (id) => {
    const formData = new FormData();
    formData.append("applicantPhoto", docs.applicantPhoto);
    axios
      .put(
        `https://api.maharashtraseva.com/api/NonCriminal_applicantPhoto/${id}`,
        formData
      )
      .then((res) => console.log("applicantPhoto", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadbenificiarryPhoto = (id) => {
    const formData = new FormData();
    formData.append("benificiarryPhoto", docs.benificiarryPhoto);
    axios
      .put(
        `https://api.maharashtraseva.com/api/NonCriminal_benificiarryPhoto/${id}`,
        formData
      )
      .then((res) => console.log("benificiarryPhoto", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadfatherSchoolLeaveCer = (id) => {
    const formData = new FormData();
    formData.append("fatherSchoolLeaveCer", docs.fatherSchoolLeaveCer);
    axios
      .put(
        `https://api.maharashtraseva.com/api/NonCriminal_fatherSchoolLeaveCer/${id}`,
        formData
      )
      .then((res) => console.log("fatherSchoolLeaveCer", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadchildrenSchoolLeaveCer = (id) => {
    const formData = new FormData();
    formData.append("childrenSchoolLeaveCer", docs.childrenSchoolLeaveCer);
    axios
      .put(
        `https://api.maharashtraseva.com/api/NonCriminal_childrenSchoolLeaveCer/${id}`,
        formData
      )
      .then((res) => console.log("childrenSchoolLeaveCer", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadotherFirst = (id) => {
    const formData = new FormData();
    formData.append("otherFirst", docs.otherFirst);
    axios
      .put(`https://api.maharashtraseva.com/api/NonCriminal_otherFirst/${id}`, formData)
      .then((res) => console.log("otherFirst", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadotherSecond = (id) => {
    const formData = new FormData();
    formData.append("otherSecond", docs.otherSecond);
    axios
      .put(`https://api.maharashtraseva.com/api/NonCriminal_otherSecond/${id}`, formData)
      .then((res) => console.log("otherSecond", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id != undefined) {
      axios.get(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`).then((res) => {
        const data = res.data[0];
        SetData(data.Data);
        setNoncreamyIncomeSource(data.NonCreamyLayerincomeSource);
        setoccupation_income(data.occupation_income);
        if (
          data?.isNew == true &&
          data?.status == "REJECTED" &&
          userData.user.role === "retailer"
        ) {
          axios
            .put(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`, {
              isNew: false,
            })
            .then((res) => {
              dispatch(incNumber());
            })
            .catch((err) => console.log(err));
        }
      });
    }
  }, [id]);

  const editHandler = (e) => {
    const obj = {
      application_type: "Non_Cremylayer certificate",
      status: "IN-PROGRESS",
      createdBy: ID,
      createdByName,
      Data,
      NonCreamyLayerincomeSource,
      occupation_income,
    };
    const mainDataPromise = new Promise((resolve, reject) => {
      axios
        .put(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`, obj)
        .then((res) => {
          const response = res;
          resolve({
            status: true,
            message: "data Posted Successfully",
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            status: false,
            message: "Data Not posted",
          });
        });
    });

    mainDataPromise
      .then((res) => {
        console.log(124, res.data);
        uploadreshaCard(res.data.data._id);
        // uploadadharCard(res.data.data._id);
        uploadlightBill(res.data.data._id);
        uploadschoolLeaveCertificate(res.data.data._id);
        // uploadphoto(res.data.data._id);
        uploadincomeCertficate3year(res.data.data._id);
        uploadcastCertificate(res.data.data._id);

        uploadtaxRecipt(res.data.data._id);
        uploadFatherAdhar(res.data.data._id);
        uploadFatherAdhar(res.data.data._id);
        uploadchildrenAdhar(res.data.data._id);
        uploadapplicantPhoto(res.data.data._id);
        uploadbenificiarryPhoto(res.data.data._id);
        uploadfatherSchoolLeaveCer(res.data.data._id);
        uploadchildrenSchoolLeaveCer(res.data.data._id);
        uploadotherFirst(res.data.data._id);
        uploadotherSecond(res.data.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ChangeDate = (Bdata, bdataString) => {
    console.log(620, bdataString);
    SetData({ ...Data, BirthDate: bdataString });
    setBirthdate(bdataString);
  };
  const ChangeBenifciaryDate = (Bdata, bdataString) => {
    SetData({ ...Data, benificiary_Dob: bdataString });
  };
  const ChangeBenifciaryFatherDate = (Bdata, bdataString) => {
    SetData({ ...Data, benificiaryFatherOrHusbund_Dob: bdataString });
  };
  const handalStartDate = (MSdata, msdataString) => {
    SetData({ ...Data, previos_CastCerIssuedDate: msdataString });
  };

  const handalFatherDate = (MEdata, medataString) => {
    SetData({ ...MEdata, FatherDate: medataString });
  };
  const handalretirementDate = (REdata, ReMdataString) => {
    SetData({ ...REdata, retirementDate: ReMdataString });
  };
  const handalMotherDate = (Mdata, MdataString) => {
    SetData({ ...Mdata, MotherDate: MdataString });
  };
  const handalretirementMDate = (RMdata, retirementdataString) => {
    SetData({ ...RMdata, retirementMDate: retirementdataString });
  };
  const handalSpouseDate = (SPdata, SpousedataString) => {
    SetData({ ...SPdata, SpouseDate: SpousedataString });
  };

  const calculateAge = () => {
    const birthdateDate = new Date(birthdate);
    const currentDate = new Date();

    if (!isNaN(birthdateDate.getTime())) {
      const yearsDiff = currentDate.getFullYear() - birthdateDate.getFullYear();
      setAge(yearsDiff);
      SetData({ ...Data, age: yearsDiff });
    } else {
      alert("Please enter a valid birthdate.");
    }
  };
  const translateName = () => {
    let istrue = false;
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.fullName_English,
      })
      .then((res) => {
        setnameMarathi(res.data.output);
        console.log(611, res.data.output);
        SetData({ ...Data, fullName_Marathi: res.data.output });
      })
      .catch((err) => console.log(err));
    if (istrue) {
      console.log(222, "ok");
    } else {
      console.log(222, "error");
    }
  };
  const translatebenificiaryName = () => {
    let istrue = false;
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.benificiary_FullNameEnglish,
      })
      .then((res) => {
        setnameMarathi(res.data.output);
        console.log(611, res.data.output);
        SetData({ ...Data, benificiary_FullNameMarathi: res.data.output });
      })
      .catch((err) => console.log(err));
    if (istrue) {
      console.log(222, "ok");
    } else {
      console.log(222, "error");
    }
  };
  const translatebenificiaryFatherName = () => {
    let istrue = false;
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.benificiaryFatherOrHusbund_FullNameEnglish,
      })
      .then((res) => {
        setnameMarathi(res.data.output);
        console.log(611, res.data.output);
        SetData({
          ...Data,
          benificiaryFatherOrHusbund_FullNameMarathi: res.data.output,
        });
      })
      .catch((err) => console.log(err));
    if (istrue) {
      console.log(222, "ok");
    } else {
      console.log(222, "error");
    }
  };

  const translateFName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.fatherName_english,
      })
      .then((res) => {
        console.log(res.data.output);
        setfnameMarathi(res.data.output);
        SetData({ ...Data, fatherName_marathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };

  const translateMName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", { text: Data.txtmothername })
      .then((res) => {
        console.log(res.data.output);
        setmnameMarathi(res.data.output);
        SetData({ ...Data, txtmothername_LL: res.data.output });
      })
      .catch((err) => console.log(err));
  };

  const translateSName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", { text: Data.txtSpouseName })
      .then((res) => {
        setsnameMarathi(res.data.output);
        SetData({ ...Data, txtspousename_LL: res.data.output });
      })
      .catch((err) => console.log(err));
  };

  const clickHandler = () => {
    translateName();
  };

  const clickHandler1 = () => {
    translateFName();
  };

  const clickHandler2 = () => {
    translateMName();
  };

  const clickHandler3 = () => {
    translateSName();
  };
  const clickHandler4 = () => {
    translatebenificiaryName();
  };
  const clickHandler5 = () => {
    translatebenificiaryFatherName();
  };

  return (
    <Fragment>
      <Breadcrumb
        title={"Non-Creamy Layer Certificate : "}
        parent={"license"}
      />
      <Container fluid={true}>
        <h3>Form Price :{GetFormPrice ? GetFormPrice : "0"}</h3>
        <Row>
          <Col sm="">
            <Card>
              <CardBody>
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  {/* Applicant Details */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Applicant Details</span>
                        </h3>
                      </div>{" "}
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div class="box-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="statusfname">Title</span>
                                      <span class="red">*</span>
                                      <select
                                        name="statusfname"
                                        class="form-control"
                                        value={Data.statusfname}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="">---Select---</option>
                                        <option value="Advocate">
                                          Advocate
                                        </option>
                                        <option value="Kumar">Kumar</option>
                                        <option value="Kumari">Kumari</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Shri">Shri</option>
                                        <option value="Shrimati">
                                          Shrimati
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label26">
                                        Full Name (English)
                                      </span>
                                      <span class="red">*</span>

                                      <input
                                        placeholder="Please Input Full Name (English)!"
                                        name="fullName_English"
                                        type="text"
                                        maxlength="90"
                                        id="CPH_FullName"
                                        //tabindex="7"
                                        class="form-control"
                                        value={Data.fullName_English}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            fullName_English: e.target.value,
                                          });
                                        }}
                                        onBlur={clickHandler}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.fullName_English}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label52">
                                        Full Name (Marathi)
                                      </span>
                                      <span class="red">*</span>
                                      <input
                                        placeholder="Please Input Full Name (Marathi)!"
                                        name="fullName_Marathi"
                                        type="text"
                                        maxlength="90"
                                        id="CPH_FullName_LL"
                                        //tabindex="8"
                                        class="form-control"
                                        value={Data.fullName_Marathi}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            fullName_Marathi: e.target.value,
                                          });
                                        }}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.fullName_Marathi}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_lblAppSal0">Title</span>
                                      <span class="red"></span>
                                      <select
                                        name="statusfname"
                                        id="CPH_ddlfathersal"
                                        //tabindex="9"
                                        class="form-control"
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="---Select---">
                                          ---Select---
                                        </option>
                                        <option value="">---Select---</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="E5397BCD-87FC-4BBB-8BDB-8AB6E6DE5BBA">
                                          Shri
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label111">
                                        Father's Name (English)
                                      </span>
                                      <span class="red">*</span>
                                      <input
                                        placeholder="Please Input Father Name in (English)"
                                        name="fatherName_english"
                                        type="text"
                                        maxlength="90"
                                        id="CPH_fatherfullname"
                                        //tabindex="10"
                                        class="form-control"
                                        value={Data.fatherName_english}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            fatherName_english: e.target.value,
                                          });
                                        }}
                                        onBlur={clickHandler1}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.fatherName_english}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label112">
                                        Father's Name (Marathi)
                                      </span>
                                      <span class="red">*</span>
                                      <input
                                        placeholder="Please Input Father Name in (Marathi)"
                                        name="fatherName_marathi"
                                        type="text"
                                        maxlength="90"
                                        id="CPH_fatherfullname_mr"
                                        //tabindex="11"
                                        class="form-control"
                                        value={Data.fatherName_marathi}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            fatherName_marathi: e.target.value,
                                          });
                                        }}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.fatherName_marathi}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="separator"></div>
                            <div class="row">
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span>Date of Birth</span>
                                  <br />

                                  <DatePicker
                                    name="BirthDate"
                                    type="text"
                                    maxlength="10"
                                    //tabindex="12"
                                    class="form-control"
                                    onChange={ChangeDate}
                                    // onBlur={calculateAge}
                                    onkeypress="return IsAlphabet(event);"
                                    format="DD-MM-YYYY"
                                  />
                                  <br />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_lblcaste03appage">Age</span>
                                  <span class="red">*</span>
                                  <input
                                    placeholder="Please Input your Age!"
                                    name="age"
                                    type="text"
                                    maxlength="3"
                                    id="CPH_txtappage"
                                    //tabindex="13"
                                    class="form-control"
                                    value={Data.age}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                  <p className="red">{Formerror.age}</p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label54">Gender</span>
                                  <span class="red">*</span>
                                  <select
                                    name="Gender"
                                    id="CPH_ddlappgender"
                                    //tabindex="14"
                                    class="form-control"
                                    value={Data.Gender}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="---Select---">
                                      ---Select---
                                    </option>
                                    <option value="Female">F</option>
                                    <option value="Male">M</option>
                                    <option value="other">T</option>
                                  </select>
                                  <p className="red">{Formerror.Gender}</p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <div class="fs-6">
                                    <span id="CPH_Label3">Mobile No</span>
                                    <span class="red">*</span>
                                    <input
                                      placeholder="Please Input your Mobile Number!"
                                      name="phoneNUm"
                                      type="text"
                                      maxlength="10"
                                      id="CPH_Mobile"
                                      //tabindex="15"
                                      class="form-control"
                                      value={Data.phoneNUm}
                                      onChange={handleChange}
                                      onkeypress="return IsAlphabet(event);"
                                    />
                                    <p className="red">{Formerror.phoneNUm}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label2">Email</span>
                                  <input
                                    placeholder="please Input Your Email"
                                    name="email"
                                    type="text"
                                    maxlength="40"
                                    id="CPH_Email"
                                    //tabindex="16"
                                    class="form-control"
                                    value={Data.email}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label1">UID No.</span>
                                  <input
                                    placeholder="Please Input your UID No!"
                                    name="UDI_NO"
                                    type="text"
                                    maxlength="12"
                                    id="CPH_txtApplicantUID"
                                    //tabindex="20"
                                    class="form-control"
                                    value={Data.UDI_NO}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Applicant Address */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div class="box-body">
                            <div class="row">
                              <div
                                className="col-lg-12 text-center border rounded mb-3"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Applicant Address
                                  </span>
                                </h3>
                              </div>{" "}
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label55">Address </span>
                                  <span class="red">*</span>
                                  <input
                                    placeholder="Please Input Address!"
                                    name="Applicant_address"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_AddrCare"
                                    //tabindex="17"
                                    class="form-control"
                                    value={Data.Applicant_address}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                  <p className="red">
                                    {Formerror.Applicant_address}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label56"> Street</span>
                                  <input
                                    placeholder="Please Input Street!"
                                    name="Applicant_street"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_AddrStreet"
                                    //tabindex="18"
                                    class="form-control"
                                    value={Data.Applicant_street}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label57">Locality</span>
                                  <input
                                    placeholder="Please Input Locality!"
                                    name="Applicant_Locality"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_AddrLocality"
                                    //tabindex="19"
                                    class="form-control"
                                    value={Data.Applicant_Locality}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label5">Building</span>
                                  <input
                                    placeholder="Please Input Building!"
                                    name="Applicant_Building"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_Building"
                                    //tabindex="20"
                                    class="form-control"
                                    value={Data.Applicant_Building}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label4"> Landmark</span>
                                  <input
                                    placeholder="Please Input LandMark!"
                                    name="Applicant_landmark"
                                    type="text"
                                    maxlength="60"
                                    id="CPH_Landmark"
                                    //tabindex="21"
                                    class="form-control"
                                    value={Data.Applicant_landmark}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label59">District</span>
                                  <span class=" red">*</span>
                                  <select
                                    name="Applicant_district"
                                    id="CPH_District"
                                    //tabindex="22"
                                    class="form-control"
                                    value={Data.Applicant_district}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Applicant_district: e.target.value,
                                      });
                                      setTalukasData(e.target.value);
                                      console.log(1131, e.target.value);
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="---Select---">
                                      ---Select---
                                    </option>
                                    {districtData.map((item, i) => {
                                      return (
                                        <option value={item.name}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <p className="red">
                                    {Formerror.Applicant_district}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label6">Taluka</span>
                                  <span class="red">*</span>
                                  <select
                                    placeholder="---Select---"
                                    name="Applicant_taluka"
                                    id="CPH_SubTaluka"
                                    //tabindex="23"
                                    class="form-control"
                                    value={Data.Applicant_taluka}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        Applicant_taluka: e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="---Select---">
                                      ---Select---
                                    </option>
                                    {talukas.map((item, i) => {
                                      return (
                                        <option value={item}>{item}</option>
                                      );
                                    })}
                                    <option value=""></option>
                                  </select>
                                  <p className="red">
                                    {Formerror.Applicant_taluka}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label58">Village</span>
                                  <span class="red">*</span>

                                  <input
                                    placeholder="Entyer Your Village !"
                                    name="Applicant_village"
                                    type="text"
                                    maxlength="6"
                                    id="CPH_Pincode"
                                    //tabindex="25"
                                    class="form-control"
                                    value={Data.Applicant_village}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                  <p className="red">
                                    {Formerror.Applicant_village}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label60">Pincode</span>
                                  <span class="red">*</span>
                                  <input
                                    placeholder="Please Input Your Pincode ! "
                                    name="Applicant_pincode"
                                    type="Number"
                                    maxlength="6"
                                    id="CPH_Pincode"
                                    //tabindex="25"
                                    class="form-control"
                                    value={Data.Applicant_pincode}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                  <p className="red">
                                    {Formerror.Applicant_pincode}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Relation of Beneficiary with Applicant: */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div class="box-body">
                            <div class="row">
                              <div
                                className="col-lg-12 text-center border rounded mb-3"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Relation of Beneficiary with Applicant:
                                  </span>
                                </h3>
                              </div>{" "}
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_lblapprelation">
                                    Relation of Beneficiary with Applicant
                                  </span>
                                  <span class="red">*</span>
                                  <select
                                    name="relationOfBanificiaryWithApplicant"
                                    id="CPH_ddlrelation"
                                    //tabindex="27"
                                    class="form-control"
                                    value={
                                      Data.relationOfBanificiaryWithApplicant
                                    }
                                    onChange={(e) => {
                                      handleChange(e);
                                      setBenificiaryDetails(true);
                                      if (e.target.value == "Self") {
                                        SetData({
                                          ...Data,
                                          // Applicant_address: "",
                                          // Applicant_street: "",
                                          // Applicant_Locality: "",
                                          // Applicant_Building: "",
                                          // Applicant_landmark: "",
                                          // Applicant_district: "",
                                          // Applicant_taluka: "",
                                          // Applicant_village: "",
                                          // Applicant_pincode: "",
                                          benificiary_Salutation:
                                            Data.statusfname,
                                          benificiary_FullNameEnglish:
                                            Data.fullName_English,
                                          benificiary_FullNameMarathi:
                                            Data.fullName_Marathi,
                                          // benificiary_Dob:Data.,
                                          benificiary_Gender: Data.Gender,
                                          benificiary_MobileNO: Data.phoneNUm,
                                          benificiary_Email: Data.email,
                                          benificiary_Uid: Data.UDI_NO,
                                          benificiary_Address:
                                            Data.Applicant_address,
                                          benificiary_Street:
                                            Data.Applicant_street,
                                          benificiary_Locality:
                                            Data.Applicant_Locality,
                                          benificiary_Building:
                                            Data.Applicant_Building,
                                          benificiary_Landmark:
                                            Data.Applicant_landmark,
                                          benificiary_District:
                                            Data.Applicant_district,
                                          benificiary_Taluka:
                                            Data.Applicant_taluka,
                                          benificiary_Village:
                                            Data.Applicant_village,
                                          benificiary_Pincode:
                                            Data.Applicant_pincode,
                                            relationOfBanificiaryWithApplicant:e.target.value
                                        });
                                      } else {
                                        SetData({
                                          ...Data,
                                          benificiary_Salutation: "",
                                          benificiary_FullNameEnglish: "",
                                          benificiary_FullNameMarathi: "",
                                          benificiary_Dob: "",
                                          benificiary_Gender: "",
                                          benificiary_MobileNO: "",
                                          benificiary_Email: "",
                                          benificiary_Uid: "",
                                          benificiary_Address: "",
                                          benificiary_Street: "",
                                          benificiary_Locality: "",
                                          benificiary_Building: "",
                                          benificiary_Landmark: "",
                                          benificiary_District: "",
                                          benificiary_Taluka: "",
                                          benificiary_Village: "",
                                          benificiary_Pincode: "",
                                          relationOfBanificiaryWithApplicant:e.target.value
                                        });
                                      }
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="---Select---">
                                      ---Select---
                                    </option>
                                    <option value="Grand Son">Grand Son</option>
                                    <option value="Grand Daughter">
                                      Grand Daughter
                                    </option>
                                    <option value="Nephew">Nephew</option>
                                    <option value="Husband">Husband</option>
                                    <option value="Sister">Sister</option>
                                    <option value="Son">Son</option>
                                    <option value="Brother">Brother</option>
                                    <option value="Self">Self</option>
                                    <option value="Daughter">Daughter</option>
                                    <option value="Wife">Wife</option>
                                  </select>
                                  <p className="red">
                                    {
                                      Formerror.relationOfBanificiaryWithApplicant
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {ShowBeniDetails && (
                    <>
                      {/* Beneficiary Details */}
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Beneficiary Details
                              </span>
                            </h3>
                          </div>{" "}
                          <div class="col-md-12">
                            <div class="form-bg" role="form">
                              <div class="box-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <div class="fs-6">
                                          <span id="statusfname">Title</span>
                                          <span class="red">*</span>
                                          <select
                                            name="benificiary_Salutation"
                                            class="form-control"
                                            value={Data.benificiary_Salutation}
                                            onChange={handleChange}
                                            onkeypress="return IsAlphabet(event);"
                                          >
                                            <option value="">
                                              ---Select---
                                            </option>
                                            <option value="Advocate">
                                              Advocate
                                            </option>
                                            <option value="Kumar">Kumar</option>
                                            <option value="Kumari">
                                              Kumari
                                            </option>
                                            <option value="Mr.">Mr.</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Shri">Shri</option>
                                            <option value="Shrimati">
                                              Shrimati
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="fs-6">
                                          <span id="CPH_Label26">
                                            Full Name (English)
                                          </span>
                                          <span class="red">*</span>

                                          <input
                                            placeholder="Please Input Full Name (English)!"
                                            name="fullName_English"
                                            type="text"
                                            maxlength="90"
                                            id="CPH_FullName"
                                            //tabindex="7"
                                            class="form-control"
                                            value={
                                              Data.benificiary_FullNameEnglish
                                            }
                                            onChange={(e) => {
                                              SetData({
                                                ...Data,
                                                benificiary_FullNameEnglish:
                                                  e.target.value,
                                              });
                                            }}
                                            onBlur={clickHandler4}
                                            onkeypress="return IsAlphabet(event);"
                                          />
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="fs-6">
                                          <span id="CPH_Label52">
                                            Full Name (Marathi)
                                          </span>
                                          <span class="red">*</span>
                                          <input
                                            placeholder="Please Input Full Name (Marathi)!"
                                            name="benificiary_FullNameMarathi"
                                            type="text"
                                            maxlength="90"
                                            id="CPH_FullName_LL"
                                            //tabindex="8"
                                            class="form-control"
                                            value={
                                              Data.benificiary_FullNameMarathi
                                            }
                                            onChange={(e) => {
                                              SetData({
                                                ...Data,
                                                benificiary_FullNameMarathi:
                                                  e.target.value,
                                              });
                                            }}
                                            onkeypress="return IsAlphabet(event);"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="separator"></div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span>Date of Birth</span>
                                      <br />

                                      <DatePicker
                                        name="BirthDate"
                                        type="text"
                                        maxlength="10"
                                        //tabindex="12"
                                        class="form-control"
                                        onChange={ChangeBenifciaryDate}
                                        // onBlur={calculateAge}
                                        onkeypress="return IsAlphabet(event);"
                                        format="DD-MM-YYYY"
                                      />
                                      <br />
                                    </div>
                                  </div>

                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label54">Gender</span>
                                      <span class="red">*</span>
                                      <select
                                        name="benificiary_Gender"
                                        id="CPH_ddlappgender"
                                        //tabindex="14"
                                        class="form-control"
                                        value={Data.benificiary_Gender}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="---Select---">
                                          ---Select---
                                        </option>
                                        <option value="Female">F</option>
                                        <option value="Male">M</option>
                                        <option value="other">T</option>
                                      </select>
                                      {/* <p className="red">{Formerror.Gender}</p> */}
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <div class="fs-6">
                                        <span id="CPH_Label3">Mobile No</span>
                                        <span class="red">*</span>
                                        <input
                                          placeholder="Please Input your Mobile Number!"
                                          name="benificiary_MobileNO"
                                          type="text"
                                          maxlength="10"
                                          id="CPH_Mobile"
                                          //tabindex="15"
                                          class="form-control"
                                          value={Data.benificiary_MobileNO}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                        <p className="red">
                                          {Formerror.phoneNUm}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label2">Email</span>
                                      <input
                                        placeholder="please Input Your Email"
                                        name="benificiary_Email"
                                        type="text"
                                        maxlength="40"
                                        id="CPH_Email"
                                        //tabindex="16"
                                        class="form-control"
                                        value={Data.benificiary_Email}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                    </div>
                                  </div>

                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label1">UID No.</span>
                                      <input
                                        placeholder="Please Input your UID No!"
                                        name="benificiary_Uid"
                                        type="text"
                                        maxlength="12"
                                        id="CPH_txtApplicantUID"
                                        //tabindex="20"
                                        class="form-control"
                                        value={Data.benificiary_Uid}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Benificiary Address */}
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-bg" role="form">
                              <div class="box-body">
                                <div class="row">
                                  <div
                                    className="col-lg-12 text-center border rounded mb-3"
                                    style={{ backgroundColor: "#e0e0e0" }}
                                  >
                                    <h3 class="box-title">
                                      <span id="CPH_lbl_AppDtl">
                                        Beneficiary Address
                                      </span>
                                    </h3>
                                  </div>{" "}
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label55">Address </span>
                                      <span class="red">*</span>
                                      <input
                                        placeholder="Please Input Address!"
                                        name="benificiary_Address"
                                        type="text"
                                        maxlength="60"
                                        id="CPH_AddrCare"
                                        //tabindex="17"
                                        class="form-control"
                                        value={Data.benificiary_Address}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label56"> Street</span>
                                      <input
                                        placeholder="Please Input Street!"
                                        name="benificiary_Street"
                                        type="text"
                                        maxlength="60"
                                        id="CPH_AddrStreet"
                                        //tabindex="18"
                                        class="form-control"
                                        value={Data.benificiary_Street}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label57">Locality</span>
                                      <input
                                        placeholder="Please Input Locality!"
                                        name="benificiary_Locality"
                                        type="text"
                                        maxlength="60"
                                        id="CPH_AddrLocality"
                                        //tabindex="19"
                                        class="form-control"
                                        value={Data.benificiary_Locality}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label5">Building</span>
                                      <input
                                        placeholder="Please Input Building!"
                                        name="benificiary_Building"
                                        type="text"
                                        maxlength="60"
                                        id="CPH_Building"
                                        //tabindex="20"
                                        class="form-control"
                                        value={Data.benificiary_Building}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label4"> Landmark</span>
                                      <input
                                        placeholder="Please Input LandMark!"
                                        name="benificiary_Landmark"
                                        type="text"
                                        maxlength="60"
                                        id="CPH_Landmark"
                                        //tabindex="21"
                                        class="form-control"
                                        value={Data.benificiary_Landmark}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label59">District</span>
                                      <span class=" red">*</span>
                                      <select
                                        name="benificiary_District"
                                        id="CPH_District"
                                        //tabindex="22"
                                        class="form-control"
                                        value={Data.benificiary_District}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            benificiary_District:
                                              e.target.value,
                                          });
                                          setTalukasData(e.target.value);
                                          console.log(1131, e.target.value);
                                        }}
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="---Select---">
                                          ---Select---
                                        </option>
                                        {districtData.map((item, i) => {
                                          return (
                                            <option value={item.name}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <p className="red">
                                        {Formerror.Applicant_district}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label6">Taluka</span>
                                      <span class="red">*</span>
                                      <select
                                        placeholder="---Select---"
                                        name="benificiary_Taluka"
                                        id="CPH_SubTaluka"
                                        //tabindex="23"
                                        class="form-control"
                                        value={Data.benificiary_Taluka}
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            benificiary_Taluka: e.target.value,
                                          });
                                        }}
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="---Select---">
                                          ---Select---
                                        </option>
                                        {talukas.map((item, i) => {
                                          return (
                                            <option value={item}>{item}</option>
                                          );
                                        })}
                                        <option value=""></option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label58">Village</span>
                                      <span class="red">*</span>

                                      <input
                                        placeholder="Entyer Your Village !"
                                        name="benificiary_Village"
                                        type="text"
                                        maxlength="6"
                                        id="CPH_Pincode"
                                        //tabindex="25"
                                        class="form-control"
                                        value={Data.benificiary_Village}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.benificiary_Village}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label60">Pincode</span>
                                      <span class="red">*</span>
                                      <input
                                        placeholder="Please Input Your Pincode ! "
                                        name="benificiary_Pincode"
                                        type="Number"
                                        maxlength="6"
                                        id="CPH_Pincode"
                                        //tabindex="25"
                                        class="form-control"
                                        value={Data.benificiary_Pincode}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                      <p className="red">
                                        {Formerror.benificiary_Pincode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Benificiary Father Details */}
                      {/* benificiaryFatherOrHusbund_Salutation: "",
    benificiaryFatherOrHusbund_FullNameEnglish: "",
    benificiaryFatherOrHusbund_FullNameMarathi: "",
    benificiaryFatherOrHusbund_Dob: "",
    benificiaryFatherOrHusbund_Gender: "",
    benificiaryFatherOrHusbund_MobileNO: "",
    benificiaryFatherOrHusbund_Email: "",
    benificiaryFatherOrHusbund_Uid: "", */}
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Benificiary Father Details
                              </span>
                            </h3>
                          </div>{" "}
                          <div class="col-md-12">
                            <div class="form-bg" role="form">
                              <div class="box-body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <div class="fs-6">
                                          <span id="statusfname">Title</span>
                                          <span class="red">*</span>
                                          <select
                                            name="benificiaryFatherOrHusbund_Salutation"
                                            class="form-control"
                                            value={
                                              Data.benificiaryFatherOrHusbund_Salutation
                                            }
                                            onChange={handleChange}
                                            onkeypress="return IsAlphabet(event);"
                                          >
                                            <option value="">
                                              ---Select---
                                            </option>
                                            <option value="Advocate">
                                              Advocate
                                            </option>
                                            <option value="Mr.">Mr.</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Shri">Shri</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="fs-6">
                                          <span id="CPH_Label26">
                                            Full Name (English)
                                          </span>
                                          <span class="red">*</span>

                                          <input
                                            placeholder="Please Input Full Name (English)!"
                                            name="benificiaryFatherOrHusbund_FullNameEnglish"
                                            type="text"
                                            maxlength="90"
                                            id="CPH_FullName"
                                            //tabindex="7"
                                            class="form-control"
                                            value={
                                              Data.benificiaryFatherOrHusbund_FullNameEnglish
                                            }
                                            onChange={(e) => {
                                              SetData({
                                                ...Data,
                                                benificiaryFatherOrHusbund_FullNameEnglish:
                                                  e.target.value,
                                              });
                                            }}
                                            onBlur={clickHandler5}
                                            onkeypress="return IsAlphabet(event);"
                                          />
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="fs-6">
                                          <span id="CPH_Label52">
                                            Full Name (Marathi)
                                          </span>
                                          <span class="red">*</span>
                                          <input
                                            placeholder="Please Input Full Name (Marathi)!"
                                            name="benificiaryFatherOrHusbund_FullNameMarathi"
                                            type="text"
                                            maxlength="90"
                                            id="CPH_FullName_LL"
                                            //tabindex="8"
                                            class="form-control"
                                            value={
                                              Data.benificiaryFatherOrHusbund_FullNameMarathi
                                            }
                                            onChange={(e) => {
                                              SetData({
                                                ...Data,
                                                benificiaryFatherOrHusbund_FullNameMarathi:
                                                  e.target.value,
                                              });
                                            }}
                                            onkeypress="return IsAlphabet(event);"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="separator"></div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span>Date of Birth</span>
                                      <br />

                                      <DatePicker
                                        name="BirthDate"
                                        type="text"
                                        maxlength="10"
                                        //tabindex="12"
                                        class="form-control"
                                        onChange={ChangeBenifciaryFatherDate}
                                        // onBlur={calculateAge}
                                        onkeypress="return IsAlphabet(event);"
                                        format="DD-MM-YYYY"
                                      />
                                      <br />
                                    </div>
                                  </div>

                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label54">Gender</span>
                                      <span class="red">*</span>
                                      <select
                                        name="benificiaryFatherOrHusbund_Gender"
                                        id="CPH_ddlappgender"
                                        //tabindex="14"
                                        class="form-control"
                                        value={
                                          Data.benificiaryFatherOrHusbund_Gender
                                        }
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value="---Select---">
                                          ---Select---
                                        </option>
                                        <option value="Female">F</option>
                                        <option value="Male">M</option>
                                        <option value="other">T</option>
                                      </select>
                                      {/* <p className="red">{Formerror.Gender}</p> */}
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <div class="fs-6">
                                        <span id="CPH_Label3">Mobile No</span>
                                        <span class="red">*</span>
                                        <input
                                          placeholder="Please Input your Mobile Number!"
                                          name="benificiaryFatherOrHusbund_MobileNO"
                                          type="text"
                                          maxlength="10"
                                          id="CPH_Mobile"
                                          //tabindex="15"
                                          class="form-control"
                                          value={
                                            Data.benificiaryFatherOrHusbund_MobileNO
                                          }
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label2">Email</span>
                                      <input
                                        placeholder="please Input Your Email"
                                        name="benificiaryFatherOrHusbund_Email"
                                        type="text"
                                        maxlength="40"
                                        id="CPH_Email"
                                        //tabindex="16"
                                        class="form-control"
                                        value={
                                          Data.benificiaryFatherOrHusbund_Email
                                        }
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                    </div>
                                  </div>

                                  <div class="col-md-4">
                                    <div class="fs-6">
                                      <span id="CPH_Label1">UID No.</span>
                                      <input
                                        placeholder="Please Input your UID No!"
                                        name="benificiaryFatherOrHusbund_Uid"
                                        type="number"
                                        maxlength="12"
                                        id="CPH_txtApplicantUID"
                                        //tabindex="20"
                                        class="form-control"
                                        value={
                                          Data.benificiaryFatherOrHusbund_Uid
                                        }
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Beneficiary Caste/ Category Details */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div class="box-body">
                            <div class="row">
                              <div
                                className="col-lg-12 text-center border rounded mb-3"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Beneficiary Caste/ Category Details
                                  </span>
                                </h3>
                              </div>{" "}
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_lblappcaste">Caste</span>
                                  <span class="red">*</span>
                                  <select
                                    name="benificiary_Cast"
                                    id="CPH_ddlappcaste"
                                    //tabindex="50"
                                    class="form-control"
                                    value={Data.benificiary_Cast}
                                    onChange={(e) => handleChange(e)}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="---Select---">
                                      ---Select---
                                    </option>
                                    <option value="	Aagale(169)">
                                      Aagale(169)
                                    </option>
                                    <option value="ADHAPRABHU(182)">
                                      ADHAPRABHU(182)
                                    </option>
                                    <option value="ADHASHETI(182)">
                                      ADHASHETI(182)
                                    </option>
                                    <option value="AGARI(169)">
                                      AGARI(169)
                                    </option>
                                    <option value="Agri(169)">Agri(169)</option>
                                    <option value="AHIR (198)">
                                      AHIR (198)
                                    </option>
                                    <option value="AHIR (29)(1)">
                                      AHIR (29)(1)
                                    </option>
                                    <option value="AHIR(198)">AHIR(198)</option>
                                    <option value="Alitakar(1)">
                                      Alitakar(1)
                                    </option>
                                    <option value="Alitkar">Alitkar</option>
                                    <option value="ALKARI(331)">
                                      ALKARI(331)
                                    </option>
                                    <option value="ANSARI(191)">
                                      ANSARI(191)
                                    </option>
                                    <option value="ANSARI(57)">
                                      ANSARI(57)
                                    </option>
                                    <option value="ARANYA GHARBHARI (1)(10)">
                                      ARANYA GHARBHARI (1)(10)
                                    </option>
                                    <option value="Arya Shatriya(8)">
                                      Arya Shatriya(8)
                                    </option>
                                    <option value="ASWALWALE (37)">
                                      ASWALWALE (37)
                                    </option>
                                    <option value="ATAAR(202)">
                                      ATAAR(202)
                                    </option>
                                    <option value="ATTAR(202)">
                                      ATTAR(202)
                                    </option>
                                    <option value="AUDHIYA(203)">
                                      AUDHIYA(203)
                                    </option>
                                    <option value="AYYAR (26)(E)">
                                      AYYAR (26)(E)
                                    </option>
                                    <option value="AYYARI (26)(E)">
                                      AYYARI (26)(E)
                                    </option>
                                    <option value="BAADHAI(174)">
                                      BAADHAI(174)
                                    </option>
                                    <option value="Badak(204)">
                                      Badak(204)
                                    </option>
                                    <option value="BADGUJAR(326)">
                                      BADGUJAR(326)
                                    </option>
                                    <option value="BADHAI(174)">
                                      BADHAI(174)
                                    </option>
                                    <option value="BADHI(174)">
                                      BADHI(174)
                                    </option>
                                    <option value="Badhinee(218)">
                                      Badhinee(218)
                                    </option>
                                    <option value="BADIA (4)">BADIA (4)</option>
                                    <option value="Badiya(4)">Badiya(4)</option>
                                    <option value="Bagadi(38)">
                                      Bagadi(38)
                                    </option>
                                    <option value="BAGALU(205)">
                                      BAGALU(205)
                                    </option>
                                    <option value="BAGWAN (MUSLIM)(182)">
                                      BAGWAN (MUSLIM)(182)
                                    </option>
                                    <option value="Bagwan(182)">
                                      Bagwan(182)
                                    </option>
                                    <option value="BAHURUPI (26)">
                                      BAHURUPI (26)
                                    </option>
                                    <option value="Bairagi(1)(2)">
                                      Bairagi(1)(2)
                                    </option>
                                    <option value="BAJANIA(5)">
                                      BAJANIA(5)
                                    </option>
                                    <option value="Bajaniya(5)">
                                      Bajaniya(5)
                                    </option>
                                    <option value="Bajigar(6)">
                                      Bajigar(6)
                                    </option>
                                    <option value="BAL SANTOSHI (3)(A)">
                                      BAL SANTOSHI (3)(A)
                                    </option>
                                    <option value="BALASANTU">BALASANTU</option>
                                    <option value="BALSANTHANAM(208)">
                                      BALSANTHANAM(208)
                                    </option>
                                    <option value="BAN (1)(8)">
                                      BAN (1)(8)
                                    </option>
                                    <option value="BANDI(130)">
                                      BANDI(130)
                                    </option>
                                    <option value="Banjara(7)">
                                      Banjara(7)
                                    </option>
                                    <option value="BANJARI (7)(S)">
                                      BANJARI (7)(S)
                                    </option>
                                    <option value="BANKAR(251)">
                                      BANKAR(251)
                                    </option>
                                    <option value="BAORIYA(216)">
                                      BAORIYA(216)
                                    </option>
                                    <option value="Baouriya(216)">
                                      Baouriya(216)
                                    </option>
                                    <option value="Barai(13)">Barai(13)</option>
                                    <option value="BARAV(204)">
                                      BARAV(204)
                                    </option>
                                    <option value="Bari(13)">Bari(13)</option>
                                    <option value="BATHINI(218)">
                                      BATHINI(218)
                                    </option>
                                    <option value="Bava (1) (1 )">
                                      Bava (1) (1 )
                                    </option>
                                    <option value="BAWARCHI(187)">
                                      BAWARCHI(187)
                                    </option>
                                    <option value="BEGARI(219)">
                                      BEGARI(219)
                                    </option>
                                    <option value="BELCHHEDA(224)">
                                      BELCHHEDA(224)
                                    </option>
                                    <option value="BELDAR BUKEKARI (2)">
                                      BELDAR BUKEKARI (2)
                                    </option>
                                    <option value="BELDAR KAPEWAR (2)">
                                      BELDAR KAPEWAR (2)
                                    </option>
                                    <option value="BELDAR MUNNAR-KAPEWAR (2)">
                                      BELDAR MUNNAR-KAPEWAR (2)
                                    </option>
                                    <option value="BELDAR PENTAREDDY (2)">
                                      BELDAR PENTAREDDY (2)
                                    </option>
                                    <option value="BELDAR TELANGA (2)">
                                      BELDAR TELANGA (2)
                                    </option>
                                    <option value="BELDAR TELGI (2)">
                                      BELDAR TELGI (2)
                                    </option>
                                    <option value="Beldar(2)">Beldar(2)</option>
                                    <option value="Berad(1)">Berad(1)</option>
                                    <option value="Beriya(14)">
                                      Beriya(14)
                                    </option>
                                    <option value="Besdeva(15)">
                                      Besdeva(15)
                                    </option>
                                    <option value="Besta(25)">Besta(25)</option>
                                    <option value="Besta(25)">Besta(25)</option>
                                    <option value="BESTA(322)">
                                      BESTA(322)
                                    </option>
                                    <option value="BESTALLU(322)">
                                      BESTALLU(322)
                                    </option>
                                    <option value="BESTAR(2)">BESTAR(2)</option>
                                    <option value="Besti(25)">Besti(25)</option>
                                    <option value="Besti(25)">Besti(25)</option>
                                    <option value="BESTI(322)">
                                      BESTI(322)
                                    </option>
                                    <option value="Bestolu(25)">
                                      Bestolu(25)
                                    </option>
                                    <option value="Bestolu(25)">
                                      Bestolu(25)
                                    </option>
                                    <option value="BHADAI(299)">
                                      BHADAI(299)
                                    </option>
                                    <option value="BHADBHUNJA(16)">
                                      BHADBHUNJA(16)
                                    </option>
                                    <option value="Bhadhi(174)">
                                      Bhadhi(174)
                                    </option>
                                    <option value="Bhadwal(125)">
                                      Bhadwal(125)
                                    </option>
                                    <option value="BHALDAR(330)">
                                      BHALDAR(330)
                                    </option>
                                    <option value="BHAMATA (3)">
                                      BHAMATA (3)
                                    </option>
                                    <option value="BHAMATI (3)(A)">
                                      BHAMATI (3)(A)
                                    </option>
                                    <option value="BHAMPATA(220)">
                                      BHAMPATA(220)
                                    </option>
                                    <option value="Bhamtha(3)">
                                      Bhamtha(3)
                                    </option>
                                    <option value="BHAMTI">BHAMTI</option>
                                    <option value="Bhanara(26)">
                                      Bhanara(26)
                                    </option>
                                    <option value="Bhanare(26)">
                                      Bhanare(26)
                                    </option>
                                    <option value="Bhanari(26)">
                                      Bhanari(26)
                                    </option>
                                    <option value="BHAND(8)">BHAND(8)</option>
                                    <option value="Bhand/Chhapparbhand(8)">
                                      Bhand/Chhapparbhand(8)
                                    </option>
                                    <option value="Bhandari(187)">
                                      Bhandari(187)
                                    </option>
                                    <option value="BHANDURA(224)">
                                      BHANDURA(224)
                                    </option>
                                    <option value="Bhangi(7)">Bhangi(7)</option>
                                    <option value="Bhanta(17)">
                                      Bhanta(17)
                                    </option>
                                    <option value="BHARADBHUNJA(16)">
                                      BHARADBHUNJA(16)
                                    </option>
                                    <option value="BHARADI 3">BHARADI 3</option>
                                    <option value="BHARAT BAGWAN(182)">
                                      BHARAT BAGWAN(182)
                                    </option>
                                    <option value="BHARATI (1)(3)">
                                      BHARATI (1)(3)
                                    </option>
                                    <option value="BHARATI GOSAVI (1)(5)">
                                      BHARATI GOSAVI (1)(5)
                                    </option>
                                    <option value="BHARATIYA IRANI (35)">
                                      BHARATIYA IRANI (35)
                                    </option>
                                    <option value="Bhat(18)">Bhat(18)</option>
                                    <option value="BHATIYARA (MUSLIM)(187)">
                                      BHATIYARA (MUSLIM)(187)
                                    </option>
                                    <option value="BHATT(18)">BHATT(18)</option>
                                    <option value="Bhavagar(293)">
                                      Bhavagar(293)
                                    </option>
                                    <option value="Bhavaiya(9)">
                                      Bhavaiya(9)
                                    </option>
                                    <option value="Bhavaiya/Targal(9)">
                                      Bhavaiya/Targal(9)
                                    </option>
                                    <option value="BHAVGAR(293)">
                                      BHAVGAR(293)
                                    </option>
                                    <option value="Bhavin(10)">
                                      Bhavin(10)
                                    </option>
                                    <option value="Bhavsar Kshatriya Rangari(170)">
                                      Bhavsar Kshatriya Rangari(170)
                                    </option>
                                    <option value="Bhavsar Kshatriya Shimpi(170)">
                                      Bhavsar Kshatriya Shimpi(170)
                                    </option>
                                    <option value="Bhavsar Kshatriya(170)">
                                      Bhavsar Kshatriya(170)
                                    </option>
                                    <option value="Bhavsar Rangari(170)">
                                      Bhavsar Rangari(170)
                                    </option>
                                    <option value="Bhavsar Shimpi(170)">
                                      Bhavsar Shimpi(170)
                                    </option>
                                    <option value="Bhavsar(170)">
                                      Bhavsar(170)
                                    </option>
                                    <option value="Bhawsar(170)">
                                      Bhawsar(170)
                                    </option>
                                    <option value="Bhilala(179)">
                                      Bhilala(179)
                                    </option>
                                    <option value="Bhirati/Pakhali(11)">
                                      Bhirati/Pakhali(11)
                                    </option>
                                    <option value="Bhistee(279)">
                                      Bhistee(279)
                                    </option>
                                    <option value="BHISTI(11)">
                                      BHISTI(11)
                                    </option>
                                    <option value="BHISTI(279)">
                                      BHISTI(279)
                                    </option>
                                    <option value="Bhoi (211)">
                                      Bhoi (211)
                                    </option>
                                    <option value="Bhoi (25)(4)">
                                      Bhoi (25)(4)
                                    </option>
                                    <option value="Bhoi Navadi (25)(18)">
                                      Bhoi Navadi (25)(18)
                                    </option>
                                    <option value="Bhoi(25)">Bhoi(25)</option>
                                    <option value="BHOIR(189)">
                                      BHOIR(189)
                                    </option>
                                    <option value="Bhope(4)">Bhope(4)</option>
                                    <option value="BHORPI 26(C)">
                                      BHORPI 26(C)
                                    </option>
                                    <option value="BHOYAR(189)">
                                      BHOYAR(189)
                                    </option>
                                    <option value="BHOYEER(189)">
                                      BHOYEER(189)
                                    </option>
                                    <option value="BHUJAVA(16)">
                                      BHUJAVA(16)
                                    </option>
                                    <option value="Bhujva(16)">
                                      Bhujva(16)
                                    </option>
                                    <option value="BHUNJ(16)">BHUNJ(16)</option>
                                    <option value="BHURANJI(16)">
                                      BHURANJI(16)
                                    </option>
                                    <option value="BHURJAVA(16)">
                                      BHURJAVA(16)
                                    </option>
                                    <option value="BHURJI(16)">
                                      BHURJI(16)
                                    </option>
                                    <option value="BHUT(4)">BHUT(4)</option>
                                    <option value="BILLAVA(224)">
                                      BILLAVA(224)
                                    </option>
                                    <option value="BINDALI(227)">
                                      BINDALI(227)
                                    </option>
                                    <option value="BORAAL(190)">
                                      BORAAL(190)
                                    </option>
                                    <option value="BORAD(190)">
                                      BORAD(190)
                                    </option>
                                    <option value="BORAL(190)">
                                      BORAL(190)
                                    </option>
                                    <option value="BORUL(190)">
                                      BORUL(190)
                                    </option>
                                    <option value="BUDBUDKI (13)(A)">
                                      BUDBUDKI (13)(A)
                                    </option>
                                    <option value="BUNKAR(251)">
                                      BUNKAR(251)
                                    </option>
                                    <option value="BURBUK(228)">
                                      BURBUK(228)
                                    </option>
                                    <option value="Buttal(7)">Buttal(7)</option>
                                    <option value="CHADAR(229)">
                                      CHADAR(229)
                                    </option>
                                    <option value="Chakravadya(230)">
                                      Chakravadya(230)
                                    </option>
                                    <option value="CHAKRAVDYA-DASAR(230)">
                                      CHAKRAVDYA-DASAR(230)
                                    </option>
                                    <option value="CHAMATHA(19)">
                                      CHAMATHA(19)
                                    </option>
                                    <option value="CHAMATHA(51)">
                                      CHAMATHA(51)
                                    </option>
                                    <option value="Chamtha(19)">
                                      Chamtha(19)
                                    </option>
                                    <option value="CHANDAL(231)">
                                      CHANDAL(231)
                                    </option>
                                    <option value="Chandalagada(20)">
                                      Chandalagada(20)
                                    </option>
                                    <option value="CHANDALGADA(20)">
                                      CHANDALGADA(20)
                                    </option>
                                    <option value="Channewar(3)(9)">
                                      Channewar(3)(9)
                                    </option>
                                    <option value="CHAPPARBAND(14)">
                                      CHAPPARBAND(14)
                                    </option>
                                    <option value="CHARAN BANJARA (7)(D)">
                                      CHARAN BANJARA (7)(D)
                                    </option>
                                    <option value="CHARAN(21)">
                                      CHARAN(21)
                                    </option>
                                    <option value="Charan/Gadhavi(21)">
                                      Charan/Gadhavi(21)
                                    </option>
                                    <option value="Charodi(22)">
                                      Charodi(22)
                                    </option>
                                    <option value="Chenewar(3)(9)">
                                      Chenewar(3)(9)
                                    </option>
                                    <option value="CHENVAWAR(232)">
                                      CHENVAWAR(232)
                                    </option>
                                    <option value="CHENVU(232)">
                                      CHENVU(232)
                                    </option>
                                    <option value="Chenwu(232)">
                                      Chenwu(232)
                                    </option>
                                    <option value="CHHAPARBAND (14)">
                                      CHHAPARBAND (14)
                                    </option>
                                    <option value="CHHAPARBHAND(8)">
                                      CHHAPARBHAND(8)
                                    </option>
                                    <option value="Chhapparband (119)">
                                      Chhapparband (119)
                                    </option>
                                    <option value="Chhapparband (187)">
                                      Chhapparband (187)
                                    </option>
                                    <option value="Chhapparband(14)">
                                      Chhapparband(14)
                                    </option>
                                    <option value="CHHARA (5)(A)">
                                      CHHARA (5)(A)
                                    </option>
                                    <option value="CHHIPA(23)">
                                      CHHIPA(23)
                                    </option>
                                    <option value="CHIMUR(233)">
                                      CHIMUR(233)
                                    </option>
                                    <option value="CHINTALA(234)">
                                      CHINTALA(234)
                                    </option>
                                    <option value="CHIPPA(23)">
                                      CHIPPA(23)
                                    </option>
                                    <option value="Chippa, Chhipa(23)">
                                      Chippa, Chhipa(23)
                                    </option>
                                    <option value="CHITARI (8)">
                                      CHITARI (8)
                                    </option>
                                    <option value="CHITARI/JINGAR(8)">
                                      CHITARI/JINGAR(8)
                                    </option>
                                    <option value="Chitodi Lohar(8)">
                                      Chitodi Lohar(8)
                                    </option>
                                    <option value="CHITODI LOHAR8">
                                      CHITODI LOHAR8
                                    </option>
                                    <option value="CHITODIYA-LOHAR (8)">
                                      CHITODIYA-LOHAR (8)
                                    </option>
                                    <option value="Chitrakathee Hardas(321)">
                                      Chitrakathee Hardas(321)
                                    </option>
                                    <option value="CHITRAKATHI (6)">
                                      CHITRAKATHI (6)
                                    </option>
                                    <option value="CHITRAKATHI HARDAS(321)">
                                      CHITRAKATHI HARDAS(321)
                                    </option>
                                    <option value="Chitrakathi Hardas(6)">
                                      Chitrakathi Hardas(6)
                                    </option>
                                    <option value="CHOUKALASHI(182)">
                                      CHOUKALASHI(182)
                                    </option>
                                    <option value="CHOUKALSHI(182)">
                                      CHOUKALSHI(182)
                                    </option>
                                    <option value="Christain Koli(260)">
                                      Christain Koli(260)
                                    </option>
                                    <option value="Christian (196)">
                                      Christian (196)
                                    </option>
                                    <option value="CHRISTIAN CONVERTED FROM SCHEDULED CASTE (196)">
                                      CHRISTIAN CONVERTED FROM SCHEDULED CASTE
                                      (196)
                                    </option>
                                    <option value="CHRISTIAN KOLI (4)(6)">
                                      CHRISTIAN KOLI (4)(6)
                                    </option>
                                    <option value="CHRISTIAN KOLI(260)">
                                      CHRISTIAN KOLI(260)
                                    </option>
                                    <option value="CHUNARI(267)">
                                      CHUNARI(267)
                                    </option>
                                    <option value="Daas/Dangdidaas(24)">
                                      Daas/Dangdidaas(24)
                                    </option>
                                    <option value="DAFALI(30)">
                                      DAFALI(30)
                                    </option>
                                    <option value="DAGADFODU (12)(E)">
                                      DAGADFODU (12)(E)
                                    </option>
                                    <option value="DAKALERU(235)">
                                      DAKALERU(235)
                                    </option>
                                    <option value="Dakleru(235)">
                                      Dakleru(235)
                                    </option>
                                    <option value="DAMARUWALE (13)(B)">
                                      DAMARUWALE (13)(B)
                                    </option>
                                    <option value="DANGADIDAS">
                                      DANGADIDAS
                                    </option>
                                    <option value="DANGARI(317)">
                                      DANGARI(317)
                                    </option>
                                    <option value="DANGAT">DANGAT</option>
                                    <option value="DANGAT 16(A)">
                                      DANGAT 16(A)
                                    </option>
                                    <option value="DANGE (29)(2)">
                                      DANGE (29)(2)
                                    </option>
                                    <option value="DANGE DHANGAR (29)(25)">
                                      DANGE DHANGAR (29)(25)
                                    </option>
                                    <option value="DARJI(153)">
                                      DARJI(153)
                                    </option>
                                    <option value="DARJI(236)">
                                      DARJI(236)
                                    </option>
                                    <option value="DARVESHI (37)">
                                      DARVESHI (37)
                                    </option>
                                    <option value="DAS(24)">DAS(24)</option>
                                    <option value="DASAR(222)">
                                      DASAR(222)
                                    </option>
                                    <option value="Davari (3)(U)">
                                      Davari (3)(U)
                                    </option>
                                    <option value="DAVGAR(25)">
                                      DAVGAR(25)
                                    </option>
                                    <option value="Depala(26)">
                                      Depala(26)
                                    </option>
                                    <option value="DESHKAR TELI (181)">
                                      DESHKAR TELI (181)
                                    </option>
                                    <option value="DEVADIGA(28)">
                                      DEVADIGA(28)
                                    </option>
                                    <option value="DEVALI(27)">
                                      DEVALI(27)
                                    </option>
                                    <option value="Devdig(28)">
                                      Devdig(28)
                                    </option>
                                    <option value="DEVERI(250)">
                                      DEVERI(250)
                                    </option>
                                    <option value="Dewang(3)(10)">
                                      Dewang(3)(10)
                                    </option>
                                    <option value="Dhadi">Dhadi</option>
                                    <option value="Dhadi Banjara (7)(M)">
                                      Dhadi Banjara (7)(M)
                                    </option>
                                    <option value="DHAKAD(190)">
                                      DHAKAD(190)
                                    </option>
                                    <option value="DHALI (7)(L)">
                                      DHALI (7)(L)
                                    </option>
                                    <option value="DHALIYA (7)(L)">
                                      DHALIYA (7)(L)
                                    </option>
                                    <option value="DHALIYA BANJARA(214)">
                                      DHALIYA BANJARA(214)
                                    </option>
                                    <option value="DHANGAR AHIR(29)(1)">
                                      DHANGAR AHIR(29)(1)
                                    </option>
                                    <option value="Dhangar(29)">
                                      Dhangar(29)
                                    </option>
                                    <option value="Dhanvar (29)(21)">
                                      Dhanvar (29)(21)
                                    </option>
                                    <option value="DHANWAR">DHANWAR</option>
                                    <option value="DHANWAR (29)(21)">
                                      DHANWAR (29)(21)
                                    </option>
                                    <option value="DHARI (7)(G)">
                                      DHARI (7)(G)
                                    </option>
                                    <option value="DHAWAD(319)">
                                      DHAWAD(319)
                                    </option>
                                    <option value="DHIMAR (25)(15)">
                                      DHIMAR (25)(15)
                                    </option>
                                    <option value="Dhiwar (25)(13)">
                                      Dhiwar (25)(13)
                                    </option>
                                    <option value="DHOBI (125)">
                                      DHOBI (125)
                                    </option>
                                    <option value="Dholi(30)">Dholi(30)</option>
                                    <option value="DHONTALE (4)(A)">
                                      DHONTALE (4)(A)
                                    </option>
                                    <option value="DHURIYA KAHAR (25)(7)">
                                      DHURIYA KAHAR (25)(7)
                                    </option>
                                    <option value="DODE GUJAR(326)">
                                      DODE GUJAR(326)
                                    </option>
                                    <option value="DODI(266)">DODI(266)</option>
                                    <option value="DOMBARI(15)">
                                      DOMBARI(15)
                                    </option>
                                    <option value="DOMMARA(241)">
                                      DOMMARA(241)
                                    </option>
                                    <option value="DON BAIL TELI (181)">
                                      DON BAIL TELI (181)
                                    </option>
                                    <option value="DON BAIL TELI(181)">
                                      DON BAIL TELI(181)
                                    </option>
                                    <option value="DONGARI DHANGAR (29)(25)">
                                      DONGARI DHANGAR (29)(25)
                                    </option>
                                    <option value="DORIK(345)">
                                      DORIK(345)
                                    </option>
                                    <option value="EAST INDIAN CATHOLIC(341)">
                                      EAST INDIAN CATHOLIC(341)
                                    </option>
                                    <option value="EAST INDIAN CHRISTIAN(341)">
                                      EAST INDIAN CHRISTIAN(341)
                                    </option>
                                    <option value="EAST INDIAN(341)">
                                      EAST INDIAN(341)
                                    </option>
                                    <option value="Edrisi/Darji(153)">
                                      Edrisi/Darji(153)
                                    </option>
                                    <option value="EK BAHIYA TELI (181)">
                                      EK BAHIYA TELI (181)
                                    </option>
                                    <option value="EKBAIL TELI (181)">
                                      EKBAIL TELI (181)
                                    </option>
                                    <option value="ERANDEL TELI (181)">
                                      ERANDEL TELI (181)
                                    </option>
                                    <option value="FAKIR BANDARWALA(192)">
                                      FAKIR BANDARWALA(192)
                                    </option>
                                    <option value="FAKIR(335)">
                                      FAKIR(335)
                                    </option>
                                    <option value="FANADE BANJARA(212)">
                                      FANADE BANJARA(212)
                                    </option>
                                    <option value="FAR(283)">FAR(283)</option>
                                    <option value="FASECHARI(118)">
                                      FASECHARI(118)
                                    </option>
                                    <option value="FUDAGI(119)">
                                      FUDAGI(119)
                                    </option>
                                    <option value="Ful Mali ( 182 )">
                                      Ful Mali ( 182 )
                                    </option>
                                    <option value="FULARI(127)">
                                      FULARI(127)
                                    </option>
                                    <option value="FULE(182)">FULE(182)</option>
                                    <option value="FULMALI(182)">
                                      FULMALI(182)
                                    </option>
                                    <option value="FUTGUDI(175)">
                                      FUTGUDI(175)
                                    </option>
                                    <option value="Gaadi Lohar(8)">
                                      Gaadi Lohar(8)
                                    </option>
                                    <option value="Gabit(201)">
                                      Gabit(201)
                                    </option>
                                    <option value="GABIT(6)">GABIT(6)</option>
                                    <option value="Gadaaba(242)">
                                      Gadaaba(242)
                                    </option>
                                    <option value="GADABA(242)">
                                      GADABA(242)
                                    </option>
                                    <option value="Gadaria (29)(22)">
                                      Gadaria (29)(22)
                                    </option>
                                    <option value="GADARIYA(29)">
                                      GADARIYA(29)
                                    </option>
                                    <option value="Gadhari (29)(24)">
                                      Gadhari (29)(24)
                                    </option>
                                    <option value="Gadhavi(39)">
                                      Gadhavi(39)
                                    </option>
                                    <option value="GADHEWAL KOSHTI (3)(6)">
                                      GADHEWAL KOSHTI (3)(6)
                                    </option>
                                    <option value="GADHRI (29)(24)">
                                      GADHRI (29)(24)
                                    </option>
                                    <option value="GADI LOHAR8">
                                      GADI LOHAR8
                                    </option>
                                    <option value="GADI WADDAR (12)(A)">
                                      GADI WADDAR (12)(A)
                                    </option>
                                    <option value="Gadri (29)(23)">
                                      Gadri (29)(23)
                                    </option>
                                    <option value="GADRIYA(29)(26)">
                                      GADRIYA(29)(26)
                                    </option>
                                    <option value="GADRIYA(328)">
                                      GADRIYA(328)
                                    </option>
                                    <option value="GAHARA (32)">
                                      GAHARA (32)
                                    </option>
                                    <option value="GANALI(188)">
                                      GANALI(188)
                                    </option>
                                    <option value="Ganchi(300)">
                                      Ganchi(300)
                                    </option>
                                    <option value="Gandharap(35)">
                                      Gandharap(35)
                                    </option>
                                    <option value="GANDLI(188)">
                                      GANDLI(188)
                                    </option>
                                    <option value="GANGANI(243)">
                                      GANGANI(243)
                                    </option>
                                    <option value="Ganli(188)">
                                      Ganli(188)
                                    </option>
                                    <option value="Ganniga(300)">
                                      Ganniga(300)
                                    </option>
                                    <option value="Ganninga(300)">
                                      Ganninga(300)
                                    </option>
                                    <option value="GAON PARADHI (9)(B)">
                                      GAON PARADHI (9)(B)
                                    </option>
                                    <option value="GARODI(244)">
                                      GARODI(244)
                                    </option>
                                    <option value="Garpagari (3)(D)">
                                      Garpagari (3)(D)
                                    </option>
                                    <option value="GARUDI (34)">
                                      GARUDI (34)
                                    </option>
                                    <option value="Garudi(7)">Garudi(7)</option>
                                    <option value="GASE WANMALI(182)">
                                      GASE WANMALI(182)
                                    </option>
                                    <option value="GATARI (29)(3)">
                                      GATARI (29)(3)
                                    </option>
                                    <option value="Gavada (350)">
                                      Gavada (350)
                                    </option>
                                    <option value="Gavade (350)">
                                      Gavade (350)
                                    </option>
                                    <option value="Gavandi(45)">
                                      Gavandi(45)
                                    </option>
                                    <option value="Gawada (350)">
                                      Gawada (350)
                                    </option>
                                    <option value="Gawade (350)">
                                      Gawade (350)
                                    </option>
                                    <option value="GAWALAN(36)">
                                      GAWALAN(36)
                                    </option>
                                    <option value="Gawali (36)">
                                      Gawali (36)
                                    </option>
                                    <option value="Gawali Gopal(36)">
                                      Gawali Gopal(36)
                                    </option>
                                    <option value="GAWANDI(45)">
                                      GAWANDI(45)
                                    </option>
                                    <option value="Gawari(1)">Gawari(1)</option>
                                    <option value="GHADASHI(194)">
                                      GHADASHI(194)
                                    </option>
                                    <option value="Ghadshi(194)">
                                      Ghadshi(194)
                                    </option>
                                    <option value="Ghanchi(300)">
                                      Ghanchi(300)
                                    </option>
                                    <option value="GHANTICHOR(220)">
                                      GHANTICHOR(220)
                                    </option>
                                    <option value="GHISADI LOHAR (8)">
                                      GHISADI LOHAR (8)
                                    </option>
                                    <option value="GHISADI(8)">
                                      GHISADI(8)
                                    </option>
                                    <option value="Ghisadi(8)">
                                      Ghisadi(8)
                                    </option>
                                    <option value="GHODEKHAU(182)">
                                      GHODEKHAU(182)
                                    </option>
                                    <option value="GIHARA (32)">
                                      GIHARA (32)
                                    </option>
                                    <option value="GIRANI WADDAR (3)(B)">
                                      GIRANI WADDAR (3)(B)
                                    </option>
                                    <option value="GIRI GOSAVI(1)(4)">
                                      GIRI GOSAVI(1)(4)
                                    </option>
                                    <option value="Gochaki(42)">
                                      Gochaki(42)
                                    </option>
                                    <option value="GODABA(242)">
                                      GODABA(242)
                                    </option>
                                    <option value="GODALA(246)">
                                      GODALA(246)
                                    </option>
                                    <option value="GODIYA KAHAR (25)(6)">
                                      GODIYA KAHAR (25)(6)
                                    </option>
                                    <option value="GOLER (9)">GOLER (9)</option>
                                    <option value="GOLKAR (09)">
                                      GOLKAR (09)
                                    </option>
                                    <option value="Golla(9)">Golla(9)</option>
                                    <option value="GOLLER(245)">
                                      GOLLER(245)
                                    </option>
                                    <option value="GOLLEWAR (9)">
                                      GOLLEWAR (9)
                                    </option>
                                    <option value="Gondhali(10)">
                                      Gondhali(10)
                                    </option>
                                    <option value="GOPAL BHORAPI (11)(A)">
                                      GOPAL BHORAPI (11)(A)
                                    </option>
                                    <option value="Gopal Gawali(36)">
                                      Gopal Gawali(36)
                                    </option>
                                    <option value="Gopal(11)">Gopal(11)</option>
                                    <option value="Gor Banjara (7)(A)">
                                      Gor Banjara (7)(A)
                                    </option>
                                    <option value="GOSAEE (33)">
                                      GOSAEE (33)
                                    </option>
                                    <option value="Gosai(33)">Gosai(33)</option>
                                    <option value="GOSAVI (1)">
                                      GOSAVI (1)
                                    </option>
                                    <option value="Gosawi(1)">Gosawi(1)</option>
                                    <option value="Goud Kalal (254)">
                                      Goud Kalal (254)
                                    </option>
                                    <option value="Gowari (1)">
                                      Gowari (1)
                                    </option>
                                    <option value="Gujar">Gujar</option>
                                    <option value="GUJAR(326)">
                                      GUJAR(326)
                                    </option>
                                    <option value="Gujrath Bori(36)">
                                      Gujrath Bori(36)
                                    </option>
                                    <option value="GURAADI(349)">
                                      GURAADI(349)
                                    </option>
                                    <option value="Guradi kapewaar(349)">
                                      Guradi kapewaar(349)
                                    </option>
                                    <option value="GURADI(349)">
                                      GURADI(349)
                                    </option>
                                    <option value="GURADI-REDDY(349)">
                                      GURADI-REDDY(349)
                                    </option>
                                    <option value="GURAD-KAPU(349)">
                                      GURAD-KAPU(349)
                                    </option>
                                    <option value="Gurav(43)">Gurav(43)</option>
                                    <option value="Gurda-Kapewar(349)">
                                      Gurda-Kapewar(349)
                                    </option>
                                    <option value="GURDA-KOPEWAR(349)">
                                      GURDA-KOPEWAR(349)
                                    </option>
                                    <option value="Gurjar-Kadiya(45)">
                                      Gurjar-Kadiya(45)
                                    </option>
                                    <option value="GUROU(43)">GUROU(43)</option>
                                    <option value="GUSAEE (33)">
                                      GUSAEE (33)
                                    </option>
                                    <option value="GUTARADI-KAPEWAR(349)">
                                      GUTARADI-KAPEWAR(349)
                                    </option>
                                    <option value="GWALVANSH (36)">
                                      GWALVANSH (36)
                                    </option>
                                    <option value="HAATKAR (29)(7)">
                                      HAATKAR (29)(7)
                                    </option>
                                    <option value="HABURA(247)">
                                      HABURA(247)
                                    </option>
                                    <option value="HADAD(92)">HADAD(92)</option>
                                    <option value="Hadad/Mistri(92)">
                                      Hadad/Mistri(92)
                                    </option>
                                    <option value="HADPAD(108)">
                                      HADPAD(108)
                                    </option>
                                    <option value="HAJAM(108)">
                                      HAJAM(108)
                                    </option>
                                    <option value="HAJJAM (108)">
                                      HAJJAM (108)
                                    </option>
                                    <option value="HAJJAM(276)">
                                      HAJJAM(276)
                                    </option>
                                    <option value="HALADE(182)">
                                      HALADE(182)
                                    </option>
                                    <option value="Halalkhor(7)">
                                      Halalkhor(7)
                                    </option>
                                    <option value="Halba Koshti(3)(2)">
                                      Halba Koshti(3)(2)
                                    </option>
                                    <option value="Halepaik(46)">
                                      Halepaik(46)
                                    </option>
                                    <option value="HANBAR(325)">
                                      HANBAR(325)
                                    </option>
                                    <option value="HANDE 29(4)">
                                      HANDE 29(4)
                                    </option>
                                    <option value="HARAN SHIKARI (9)(C)">
                                      HARAN SHIKARI (9)(C)
                                    </option>
                                    <option value="HARANI(248)">
                                      HARANI(248)
                                    </option>
                                    <option value="Harkantra(239)">
                                      Harkantra(239)
                                    </option>
                                    <option value="HARKRANTA(239)">
                                      HARKRANTA(239)
                                    </option>
                                    <option value="Harni(248)">
                                      Harni(248)
                                    </option>
                                    <option value="HASHMI(30)">
                                      HASHMI(30)
                                    </option>
                                    <option value="Hatakar (29)(7)">
                                      Hatakar (29)(7)
                                    </option>
                                    <option value="Hatkar (29)(6)">
                                      Hatkar (29)(6)
                                    </option>
                                    <option value="Hazaam(276)">
                                      Hazaam(276)
                                    </option>
                                    <option value="HELAVI (12)">
                                      HELAVI (12)
                                    </option>
                                    <option value="HILAVI (12)">
                                      HILAVI (12)
                                    </option>
                                    <option value="HIL-REDIDAS(249)">
                                      HIL-REDIDAS(249)
                                    </option>
                                    <option value="HINDU DHARKAR(164)">
                                      HINDU DHARKAR(164)
                                    </option>
                                    <option value="HINDU KUNBI (83)">
                                      HINDU KUNBI (83)
                                    </option>
                                    <option value="IDRIS(153)">
                                      IDRIS(153)
                                    </option>
                                    <option value="Indian Irani(35)">
                                      Indian Irani(35)
                                    </option>
                                    <option value="Jaatgar(310)">
                                      Jaatgar(310)
                                    </option>
                                    <option value="Jaatiya (25)(11)">
                                      Jaatiya (25)(11)
                                    </option>
                                    <option value="Jadi(60)">Jadi(60)</option>
                                    <option value="JADUGAR (34)">
                                      JADUGAR (34)
                                    </option>
                                    <option value="Jagiyasi(49)">
                                      Jagiyasi(49)
                                    </option>
                                    <option value="JAIN KALAR(254)">
                                      JAIN KALAR(254)
                                    </option>
                                    <option value="JAIN KOSHTI  (3)(15)">
                                      JAIN KOSHTI (3)(15)
                                    </option>
                                    <option value="JAIN SHIMPI(153)">
                                      JAIN SHIMPI(153)
                                    </option>
                                    <option value="Jajaak(50)">
                                      Jajaak(50)
                                    </option>
                                    <option value="JAJAK(50)">JAJAK(50)</option>
                                    <option value="Jangam(58)">
                                      Jangam(58)
                                    </option>
                                    <option value="JATGAR(310)">
                                      JATGAR(310)
                                    </option>
                                    <option value="JATI WADDAR">
                                      JATI WADDAR
                                    </option>
                                    <option value="Jatigar(52)">
                                      Jatigar(52)
                                    </option>
                                    <option value="Jatiya(51)">
                                      Jatiya(51)
                                    </option>
                                    <option value="JAVERI(53)">
                                      JAVERI(53)
                                    </option>
                                    <option value="Javheri(53)">
                                      Javheri(53)
                                    </option>
                                    <option value="JINGAR - CHITARI (59)">
                                      JINGAR - CHITARI (59)
                                    </option>
                                    <option value="Jingar 8">Jingar 8</option>
                                    <option value="Jire 182(9)">
                                      Jire 182(9)
                                    </option>
                                    <option value="JIRE MALI">JIRE MALI</option>
                                    <option value="JIRE MALI(182)">
                                      JIRE MALI(182)
                                    </option>
                                    <option value="JOGI (3)(E)">
                                      JOGI (3)(E)
                                    </option>
                                    <option value="JOGI BANJARA (7)(P)">
                                      JOGI BANJARA (7)(P)
                                    </option>
                                    <option value="Jogin(55)">Jogin(55)</option>
                                    <option value="Johari(56)">
                                      Johari(56)
                                    </option>
                                    <option value="JOSHI (13)">
                                      JOSHI (13)
                                    </option>
                                    <option value="Joshi(13)">Joshi(13)</option>
                                    <option value="JULAHA (57)">
                                      JULAHA (57)
                                    </option>
                                    <option value="Julaha(57)">
                                      Julaha(57)
                                    </option>
                                    <option value="Julaha, Ansari(57)">
                                      Julaha, Ansari(57)
                                    </option>
                                    <option value="KACCHIYA(252)">
                                      KACCHIYA(252)
                                    </option>
                                    <option value="KACHA(182)">
                                      KACHA(182)
                                    </option>
                                    <option value="KACHAKIWALE BANJARA (7)(G)">
                                      KACHAKIWALE BANJARA (7)(G)
                                    </option>
                                    <option value="KACHARI(87)">
                                      KACHARI(87)
                                    </option>
                                    <option value="KACHHI(85)">
                                      KACHHI(85)
                                    </option>
                                    <option value="Kachiya(252)">
                                      Kachiya(252)
                                    </option>
                                    <option value="Kachora(72)">
                                      Kachora(72)
                                    </option>
                                    <option value="KADAKLAKSHMIWALE (31)">
                                      KADAKLAKSHMIWALE (31)
                                    </option>
                                    <option value="Kadera(73)">
                                      Kadera(73)
                                    </option>
                                    <option value="KADU(182)">KADU(182)</option>
                                    <option value="Kahar (25)(5)">
                                      Kahar (25)(5)
                                    </option>
                                    <option value="Kaikadi(4)">
                                      Kaikadi(4)
                                    </option>
                                    <option value="Kakar (344)">
                                      Kakar (344)
                                    </option>
                                    <option value="Kalaal(342)">
                                      Kalaal(342)
                                    </option>
                                    <option value="Kalal (219)">
                                      Kalal (219)
                                    </option>
                                    <option value="KALAL(254)">
                                      KALAL(254)
                                    </option>
                                    <option value="Kalan(169)">
                                      Kalan(169)
                                    </option>
                                    <option value="Kalar(254)">
                                      Kalar(254)
                                    </option>
                                    <option value="KALAWADER(304)">
                                      KALAWADER(304)
                                    </option>
                                    <option value="KALSERU(276)">
                                      KALSERU(276)
                                    </option>
                                    <option value="Kalwar(124)(A)">
                                      Kalwar(124)(A)
                                    </option>
                                    <option value="Kamaati(74)">
                                      Kamaati(74)
                                    </option>
                                    <option value="Kamathi (3)">
                                      Kamathi (3)
                                    </option>
                                    <option value="Kamati (3)(C)">
                                      Kamati (3)(C)
                                    </option>
                                    <option value="Kammi(62)">Kammi(62)</option>
                                    <option value="KAMTI(74)">KAMTI(74)</option>
                                    <option value="KANADE (29)(13)">
                                      KANADE (29)(13)
                                    </option>
                                    <option value="Kanadi(351)">
                                      Kanadi(351)
                                    </option>
                                    <option value="KANCHAR(87)">
                                      KANCHAR(87)
                                    </option>
                                    <option value="KANDEL(255)">
                                      KANDEL(255)
                                    </option>
                                    <option value="KANJAR (5)(B)">
                                      KANJAR (5)(B)
                                    </option>
                                    <option value="KANJARBHAT (5)">
                                      KANJARBHAT (5)
                                    </option>
                                    <option value="Kanodi(351)">
                                      Kanodi(351)
                                    </option>
                                    <option value="KANSHI(276)">
                                      KANSHI(276)
                                    </option>
                                    <option value="KANTHAHAR WANI(190)">
                                      KANTHAHAR WANI(190)
                                    </option>
                                    <option value="Kapadi(63)">
                                      Kapadi(63)
                                    </option>
                                    <option value="KAPEWAR (2)">
                                      KAPEWAR (2)
                                    </option>
                                    <option value="Kapewar(5)(7)">
                                      Kapewar(5)(7)
                                    </option>
                                    <option value="KARADI(311)">
                                      KARADI(311)
                                    </option>
                                    <option value="KASAB(257)">
                                      KASAB(257)
                                    </option>
                                    <option value="KASABI(75)">
                                      KASABI(75)
                                    </option>
                                    <option value="KASAI (257)">
                                      KASAI (257)
                                    </option>
                                    <option value="Kasai(257)">
                                      Kasai(257)
                                    </option>
                                    <option value="KASAI(316)">
                                      KASAI(316)
                                    </option>
                                    <option value="KASAR (160)">
                                      KASAR (160)
                                    </option>
                                    <option value="KASAR (87)">
                                      KASAR (87)
                                    </option>
                                    <option value="Kasar(160)">
                                      Kasar(160)
                                    </option>
                                    <option value="Kasar(87)">Kasar(87)</option>
                                    <option value="Kasbi(75)">Kasbi(75)</option>
                                    <option value="KASERA(256)">
                                      KASERA(256)
                                    </option>
                                    <option value="Kashi Kapadi (14)">
                                      Kashi Kapadi (14)
                                    </option>
                                    <option value="KATABU (6)">
                                      KATABU (6)
                                    </option>
                                    <option value="Katari (21)">
                                      Katari (21)
                                    </option>
                                    <option value="KATARI SHIKALGAR (21)">
                                      KATARI SHIKALGAR (21)
                                    </option>
                                    <option value="KATHAR WANI(190)">
                                      KATHAR WANI(190)
                                    </option>
                                    <option value="Kathar(190)">
                                      Kathar(190)
                                    </option>
                                    <option value="KATHARWANI (190)">
                                      KATHARWANI (190)
                                    </option>
                                    <option value="KATHARWANI (190)">
                                      KATHARWANI (190)
                                    </option>
                                    <option value="Kathi(86)">Kathi(86)</option>
                                    <option value="KATIPAMULA(258)">
                                      KATIPAMULA(258)
                                    </option>
                                    <option value="Kewat(25)(12)">
                                      Kewat(25)(12)
                                    </option>
                                    <option value="Kewat(76)">Kewat(76)</option>
                                    <option value="Khakrob(7)">
                                      Khakrob(7)
                                    </option>
                                    <option value="Kharthi(225)">
                                      Kharthi(225)
                                    </option>
                                    <option value="Kharva(4)(13)">
                                      Kharva(4)(13)
                                    </option>
                                    <option value="KHARVI(225)">
                                      KHARVI(225)
                                    </option>
                                    <option value="Kharvi(4)(13)">
                                      Kharvi(4)(13)
                                    </option>
                                    <option value="KHATAWALI(266)">
                                      KHATAWALI(266)
                                    </option>
                                    <option value="KHATI (65)">
                                      KHATI (65)
                                    </option>
                                    <option value="Khati(65)">Khati(65)</option>
                                    <option value="Khati(8)">Khati(8)</option>
                                    <option value="KHATIK(316)">
                                      KHATIK(316)
                                    </option>
                                    <option value="KHATRI(126)">
                                      KHATRI(126)
                                    </option>
                                    <option value="KHATWADHAI(313)">
                                      KHATWADHAI(313)
                                    </option>
                                    <option value="KHATWADHI(8)">
                                      KHATWADHI(8)
                                    </option>
                                    <option value="KHELKARI (11)(B)">
                                      KHELKARI (11)(B)
                                    </option>
                                    <option value="KHUTEKAR 29(9)">
                                      KHUTEKAR 29(9)
                                    </option>
                                    <option value="KINGARIWALE (3)(B)">
                                      KINGARIWALE (3)(B)
                                    </option>
                                    <option value="Kiraar(259)">
                                      Kiraar(259)
                                    </option>
                                    <option value="Kirad(25)(8)">
                                      Kirad(25)(8)
                                    </option>
                                    <option value="KIRAR(259)">
                                      KIRAR(259)
                                    </option>
                                    <option value="Kirat (25)(8)">
                                      Kirat (25)(8)
                                    </option>
                                    <option value="KODAKU SAH KORAVA(262)">
                                      KODAKU SAH KORAVA(262)
                                    </option>
                                    <option value="Kodaku(262)">
                                      Kodaku(262)
                                    </option>
                                    <option value="KOHALI (315)">
                                      KOHALI (315)
                                    </option>
                                    <option value="KOIRI(85)">KOIRI(85)</option>
                                    <option value="Kokani-Dhangar (29)(12)">
                                      Kokani-Dhangar (29)(12)
                                    </option>
                                    <option value="KOLHATI (15)">
                                      KOLHATI (15)
                                    </option>
                                    <option value="KOLHATI(15)">
                                      KOLHATI(15)
                                    </option>
                                    <option value="Koli (4)(1)">
                                      Koli (4)(1)
                                    </option>
                                    <option value="Koli (4)(13)">
                                      Koli (4)(13)
                                    </option>
                                    <option value="Koli (65)">Koli (65)</option>
                                    <option value="KOLI BARIYA">
                                      KOLI BARIYA
                                    </option>
                                    <option value="Koli Suryavanshi(4)(9)">
                                      Koli Suryavanshi(4)(9)
                                    </option>
                                    <option value="KOMAKAPU(263)">
                                      KOMAKAPU(263)
                                    </option>
                                    <option value="KONCHIKORAWA (4)(C)	">
                                      KONCHIKORAWA (4)(C){" "}
                                    </option>
                                    <option value="KONDU(264)">
                                      KONDU(264)
                                    </option>
                                    <option value="Kongadi(69)">
                                      Kongadi(69)
                                    </option>
                                    <option value="KONKANI-DHANGAR (29)(12)">
                                      KONKANI-DHANGAR (29)(12)
                                    </option>
                                    <option value="KORACH(252)">
                                      KORACH(252)
                                    </option>
                                    <option value="KORACHAR(261)">
                                      KORACHAR(261)
                                    </option>
                                    <option value="Koraghar(261)">
                                      Koraghar(261)
                                    </option>
                                    <option value="KORAVE(261)">
                                      KORAVE(261)
                                    </option>
                                    <option value="KORAVI (4)(E)">
                                      KORAVI (4)(E)
                                    </option>
                                    <option value="KORAWA (4)(B)">
                                      KORAWA (4)(B)
                                    </option>
                                    <option value="Korchar(70)">
                                      Korchar(70)
                                    </option>
                                    <option value="Korva (262)">
                                      Korva (262)
                                    </option>
                                    <option value="Kosakanti devang(173)">
                                      Kosakanti devang(173)
                                    </option>
                                    <option value="KOSARE(182)">
                                      KOSARE(182)
                                    </option>
                                    <option value="Koshti (3)(1)">
                                      Koshti (3)(1)
                                    </option>
                                    <option value="KOSKANTI DEVANG(173)">
                                      KOSKANTI DEVANG(173)
                                    </option>
                                    <option value="KSHATRIYA PATKAR(126)">
                                      KSHATRIYA PATKAR(126)
                                    </option>
                                    <option value="KSHATRIYA(126) ">
                                      KSHATRIYA(126){" "}
                                    </option>
                                    <option value="Ku.Wani(190)">
                                      Ku.Wani(190)
                                    </option>
                                    <option value="Kuchbandh(80)">
                                      Kuchbandh(80)
                                    </option>
                                    <option value="Kuchhariya(81)">
                                      Kuchhariya(81)
                                    </option>
                                    <option value="Kudmude - Joshi (13)(C)">
                                      Kudmude - Joshi (13)(C)
                                    </option>
                                    <option value="KUDMUDE (13)(C)">
                                      KUDMUDE (13)(C)
                                    </option>
                                    <option value="Kudumbi(35)">
                                      Kudumbi(35)
                                    </option>
                                    <option value="Kulakadgi(338)">
                                      Kulakadgi(338)
                                    </option>
                                    <option value="KULALA(82)">
                                      KULALA(82)
                                    </option>
                                    <option value="Kulekadgi(338)">
                                      Kulekadgi(338)
                                    </option>
                                    <option value="Kullakadgi(338)">
                                      Kullakadgi(338)
                                    </option>
                                    <option value="Kullekadgi(338)">
                                      Kullekadgi(338)
                                    </option>
                                    <option value="Kulwant Wani(190)">
                                      Kulwant Wani(190)
                                    </option>
                                    <option value="Kulwant-Wani(190)">
                                      Kulwant-Wani(190)
                                    </option>
                                    <option value="Kumawat(2)">
                                      Kumawat(2)
                                    </option>
                                    <option value="KUMBARA (82)">
                                      KUMBARA (82)
                                    </option>
                                    <option value="KUMBHAR(82)">
                                      KUMBHAR(82)
                                    </option>
                                    <option value="KUMBHARA">KUMBHARA</option>
                                    <option value="KUMHAR(82)">
                                      KUMHAR(82)
                                    </option>
                                    <option value="Kunabi Wani(190)">
                                      Kunabi Wani(190)
                                    </option>
                                    <option value="Kunbi ( 83 )">
                                      Kunbi ( 83 )
                                    </option>
                                    <option value="Kunbi (70)">
                                      Kunbi (70)
                                    </option>
                                    <option value="KUNBI (83)">
                                      KUNBI (83)
                                    </option>
                                    <option value="Kunbi Leva Patidar ( 83 )">
                                      Kunbi Leva Patidar ( 83 )
                                    </option>
                                    <option value="KUNBI MARATHA(83)">
                                      KUNBI MARATHA(83)
                                    </option>
                                    <option value="Kunbi(83)">Kunbi(83)</option>
                                    <option value="Kunbi(83)">Kunbi(83)</option>
                                    <option value="KUNBI(83)">KUNBI(83)</option>
                                    <option value="KUNKUWALE(312)">
                                      KUNKUWALE(312)
                                    </option>
                                    <option value="KURABA(238)">
                                      KURABA(238)
                                    </option>
                                    <option value="Kurba(238)">
                                      Kurba(238)
                                    </option>
                                    <option value="Kureshi(257)">
                                      Kureshi(257)
                                    </option>
                                    <option value="Kurhin(171)">
                                      Kurhin(171)
                                    </option>
                                    <option value="KURHINSHETTI(171)">
                                      KURHINSHETTI(171)
                                    </option>
                                    <option value="Kurmaar (29)(17)">
                                      Kurmaar (29)(17)
                                    </option>
                                    <option value="Kurmar (29)(17)">
                                      Kurmar (29)(17)
                                    </option>
                                    <option value="KURUBAR(238)">
                                      KURUBAR(238)
                                    </option>
                                    <option value="KUSHWAHA(85)">
                                      KUSHWAHA(85)
                                    </option>
                                    <option value="Laabha(88)">
                                      Laabha(88)
                                    </option>
                                    <option value="LABAN (7)(J)">
                                      LABAN (7)(J)
                                    </option>
                                    <option value="LABHA(88)">LABHA(88)</option>
                                    <option value="LABHAN (7)(E)">
                                      LABHAN (7)(E)
                                    </option>
                                    <option value="LAD (254)">LAD (254)</option>
                                    <option value="Lad Vanjari 30">
                                      Lad Vanjari 30
                                    </option>
                                    <option value="LAD(254)">LAD(254)</option>
                                    <option value="LADAF(90)">LADAF(90)</option>
                                    <option value="LADASE (29)(19)">
                                      LADASE (29)(19)
                                    </option>
                                    <option value="Ladasi(199)">
                                      Ladasi(199)
                                    </option>
                                    <option value="Ladhiya(89)">
                                      Ladhiya(89)
                                    </option>
                                    <option value="LADIYA(89)">
                                      LADIYA(89)
                                    </option>
                                    <option value="Ladse (29)(19)">
                                      Ladse (29)(19)
                                    </option>
                                    <option value="LADSHAKHIYA WANI(190)">
                                      LADSHAKHIYA WANI(190)
                                    </option>
                                    <option value="LADSHAKHIYA WANI(343)">
                                      LADSHAKHIYA WANI(343)
                                    </option>
                                    <option value="LADWAK(254)">
                                      LADWAK(254)
                                    </option>
                                    <option value="LAIDAF (NADDAF)(90)">
                                      LAIDAF (NADDAF)(90)
                                    </option>
                                    <option value="Laidaf Naddaf (90)">
                                      Laidaf Naddaf (90)
                                    </option>
                                    <option value="LAKHARI(265)">
                                      LAKHARI(265)
                                    </option>
                                    <option value="Lakheriya(91)">
                                      Lakheriya(91)
                                    </option>
                                    <option value="Lalbeg(7)">Lalbeg(7)</option>
                                    <option value="Laman (7)(I)">
                                      Laman (7)(I)
                                    </option>
                                    <option value="LAMAN BANJARA (7)(H)">
                                      LAMAN BANJARA (7)(H)
                                    </option>
                                    <option value="Lamani (7)(I)">
                                      Lamani (7)(I)
                                    </option>
                                    <option value="LAMBADA (7)(B)">
                                      LAMBADA (7)(B)
                                    </option>
                                    <option value="LAMBADE(211)">
                                      LAMBADE(211)
                                    </option>
                                    <option value="LAMBARA">LAMBARA</option>
                                    <option value="Lanjhad(197)">
                                      Lanjhad(197)
                                    </option>
                                    <option value="LANZAD(197) ">
                                      LANZAD(197){" "}
                                    </option>
                                    <option value="LARIYA(89)">
                                      LARIYA(89)
                                    </option>
                                    <option value="LAZAD(197)">
                                      LAZAD(197)
                                    </option>
                                    <option value="Leva Gujar(326)">
                                      Leva Gujar(326)
                                    </option>
                                    <option value="LEVA KUNBI(83)">
                                      LEVA KUNBI(83)
                                    </option>
                                    <option value="LEVA PATIDAR(83)">
                                      LEVA PATIDAR(83)
                                    </option>
                                    <option value="LEVA PATIL(83)">
                                      LEVA PATIL(83)
                                    </option>
                                    <option value="LEVE GUJAR(326)">
                                      LEVE GUJAR(326)
                                    </option>
                                    <option value="Leve Patidar ( 83 )">
                                      Leve Patidar ( 83 )
                                    </option>
                                    <option value="Lewa Patidar (70)">
                                      Lewa Patidar (70)
                                    </option>
                                    <option value="Lingayat Devang(3)">
                                      Lingayat Devang(3)
                                    </option>
                                    <option value="Lingayat Dhobi (125)">
                                      Lingayat Dhobi (125)
                                    </option>
                                    <option value="Lingayat Dhobi(125)">
                                      Lingayat Dhobi(125)
                                    </option>
                                    <option value="Lingayat Gurav (43)">
                                      Lingayat Gurav (43)
                                    </option>
                                    <option value="Lingayat Gurav(43)">
                                      Lingayat Gurav(43)
                                    </option>
                                    <option value="Lingayat Jangam (58)">
                                      Lingayat Jangam (58)
                                    </option>
                                    <option value="Lingayat Jangam(58)">
                                      Lingayat Jangam(58)
                                    </option>
                                    <option value="Lingayat Koshti (3)">
                                      Lingayat Koshti (3)
                                    </option>
                                    <option value="Lingayat Koshti(3)">
                                      Lingayat Koshti(3)
                                    </option>
                                    <option value="Lingayat Kulekadgi(338)">
                                      Lingayat Kulekadgi(338)
                                    </option>
                                    <option value="Lingayat Kullekadgi (338)">
                                      Lingayat Kullekadgi (338)
                                    </option>
                                    <option value="Lingayat Kumbhar (82)">
                                      Lingayat Kumbhar (82)
                                    </option>
                                    <option value="Lingayat Kumbhar(82)">
                                      Lingayat Kumbhar(82)
                                    </option>
                                    <option value="LINGAYAT MALI(182)">
                                      LINGAYAT MALI(182)
                                    </option>
                                    <option value="Lingayat Nhavi (108)">
                                      Lingayat Nhavi (108)
                                    </option>
                                    <option value="Lingayat Nhavi(108)">
                                      Lingayat Nhavi(108)
                                    </option>
                                    <option value="Lingayat Parit (125)">
                                      Lingayat Parit (125)
                                    </option>
                                    <option value="Lingayat Parit(125)">
                                      Lingayat Parit(125)
                                    </option>
                                    <option value="Lingayat Phulari (127)">
                                      Lingayat Phulari (127)
                                    </option>
                                    <option value="Lingayat Phulari(127)">
                                      Lingayat Phulari(127)
                                    </option>
                                    <option value="Lingayat Sali (3)">
                                      Lingayat Sali (3)
                                    </option>
                                    <option value="Lingayat Sali(3)">
                                      Lingayat Sali(3)
                                    </option>
                                    <option value="Lingayat Sutar (174)">
                                      Lingayat Sutar (174)
                                    </option>
                                    <option value="Lingayat Sutar(174)">
                                      Lingayat Sutar(174)
                                    </option>
                                    <option value="Lingayat Tamboli (190)">
                                      Lingayat Tamboli (190)
                                    </option>
                                    <option value="Lingayat Tamboli(190)">
                                      Lingayat Tamboli(190)
                                    </option>
                                    <option value="LINGAYAT TELI (181)">
                                      LINGAYAT TELI (181)
                                    </option>
                                    <option value="Lingayat Wani(190)">
                                      Lingayat Wani(190)
                                    </option>
                                    <option value="LODH(336)">LODH(336)</option>
                                    <option value="LODHA(336)">
                                      LODHA(336)
                                    </option>
                                    <option value="LODHI(336)">
                                      LODHI(336)
                                    </option>
                                    <option value="Lohar (79)">
                                      Lohar (79)
                                    </option>
                                    <option value="LOHAR (8)">LOHAR (8)</option>
                                    <option value="Lohar(8)">Lohar(8)</option>
                                    <option value="LOHARA-GADA(266)">
                                      LOHARA-GADA(266)
                                    </option>
                                    <option value="Lonari(183)">
                                      Lonari(183)
                                    </option>
                                    <option value="LONDHARI / PENDHARI(326)">
                                      LONDHARI / PENDHARI(326)
                                    </option>
                                    <option value="LONIYA(110)">
                                      LONIYA(110)
                                    </option>
                                    <option value="LUHAR(92)">LUHAR(92)</option>
                                    <option value="LUNIYA(110)">
                                      LUNIYA(110)
                                    </option>
                                    <option value="LUWAR(92)">LUWAR(92)</option>
                                    <option value="Luwar(92)">Luwar(92)</option>
                                    <option value="Maahil(269)">
                                      Maahil(269)
                                    </option>
                                    <option value="Maazwar(271)">
                                      Maazwar(271)
                                    </option>
                                    <option value="MACCHUA (25)(9)">
                                      MACCHUA (25)(9)
                                    </option>
                                    <option value="Machchhimar-Daldi(329)">
                                      Machchhimar-Daldi(329)
                                    </option>
                                    <option value="Machchi(93)">
                                      Machchi(93)
                                    </option>
                                    <option value="Machhi - Mitna (4)(15)">
                                      Machhi - Mitna (4)(15)
                                    </option>
                                    <option value="Machhi (4)(15)">
                                      Machhi (4)(15)
                                    </option>
                                    <option value="Madawal(125)">
                                      Madawal(125)
                                    </option>
                                    <option value="Madhura(104)">
                                      Madhura(104)
                                    </option>
                                    <option value="MAHANUBHAV BHOPI(94)">
                                      MAHANUBHAV BHOPI(94)
                                    </option>
                                    <option value="MAHAT(334)">
                                      MAHAT(334)
                                    </option>
                                    <option value="MAHAWAT(334)">
                                      MAHAWAT(334)
                                    </option>
                                    <option value="MAHILA(269)">
                                      MAHILA(269)
                                    </option>
                                    <option value="Mahlee(99)">
                                      Mahlee(99)
                                    </option>
                                    <option value="Mahli(86)">Mahli(86)</option>
                                    <option value="MAHLI(99)">MAHLI(99)</option>
                                    <option value="MAHURE (29)(18)">
                                      MAHURE (29)(18)
                                    </option>
                                    <option value="MAHUT(334)">
                                      MAHUT(334)
                                    </option>
                                    <option value="MAIDASI(270)">
                                      MAIDASI(270)
                                    </option>
                                    <option value="Mairal (16)">
                                      Mairal (16)
                                    </option>
                                    <option value="MAKADWALE (4)(C)">
                                      MAKADWALE (4)(C)
                                    </option>
                                    <option value="MALAJANGAM VIRBHADRA (58)">
                                      MALAJANGAM VIRBHADRA (58)
                                    </option>
                                    <option value="MALHAR (25)(19)">
                                      MALHAR (25)(19)
                                    </option>
                                    <option value="MALHAV (25)(20)">
                                      MALHAV (25)(20)
                                    </option>
                                    <option value="Malhav(211)">
                                      Malhav(211)
                                    </option>
                                    <option value="MALI(182)">MALI(182)</option>
                                    <option value="MANBHAV BHOPI(94)">
                                      MANBHAV BHOPI(94)
                                    </option>
                                    <option value="Manbhav(94)">
                                      Manbhav(94)
                                    </option>
                                    <option value="MANERI(309)">
                                      MANERI(309)
                                    </option>
                                    <option value="MANGELA (4)(10)">
                                      MANGELA (4)(10)
                                    </option>
                                    <option value="MANGELE (239)">
                                      MANGELE (239)
                                    </option>
                                    <option value="MANGOLI (239)">
                                      MANGOLI (239)
                                    </option>
                                    <option value="MANIYAR(309)">
                                      MANIYAR(309)
                                    </option>
                                    <option value="MANKAR KHALU(273)">
                                      MANKAR KHALU(273)
                                    </option>
                                    <option value="Mankar(273)">
                                      Mankar(273)
                                    </option>
                                    <option value="Mansuri(177)">
                                      Mansuri(177)
                                    </option>
                                    <option value="Mansuri(90)">
                                      Mansuri(90)
                                    </option>
                                    <option value="MANYAR BANGADIWALA(309)">
                                      MANYAR BANGADIWALA(309)
                                    </option>
                                    <option value="MANYAR(309)  ">
                                      MANYAR(309){" "}
                                    </option>
                                    <option value="MANZI (25)(10)">
                                      MANZI (25)(10)
                                    </option>
                                    <option value="Mapanjogi(103)">
                                      Mapanjogi(103)
                                    </option>
                                    <option value="MAPANJOSHI (17)(2)">
                                      MAPANJOSHI (17)(2)
                                    </option>
                                    <option value="MARAGAMMAWALE (31)">
                                      MARAGAMMAWALE (31)
                                    </option>
                                    <option value="MARAL(182)">
                                      MARAL(182)
                                    </option>
                                    <option value="MARAR(182)">
                                      MARAR(182)
                                    </option>
                                    <option value="Maratha">Maratha</option>
                                    <option value="MARATHA KUNBI(83)">
                                      MARATHA KUNBI(83)
                                    </option>
                                    <option value="MARATHA TELI (181)">
                                      MARATHA TELI (181)
                                    </option>
                                    <option value="Marawar,baori(206)">
                                      Marawar,baori(206)
                                    </option>
                                    <option value="MARBAR WAGHARI(206)">
                                      MARBAR WAGHARI(206)
                                    </option>
                                    <option value="MARIAIWALE (31)">
                                      MARIAIWALE (31)
                                    </option>
                                    <option value="Marvar bori(96)">
                                      Marvar bori(96)
                                    </option>
                                    <option value="MARWADI NHAVI(348)">
                                      MARWADI NHAVI(348)
                                    </option>
                                    <option value="Marwadi Sonar (154)">
                                      Marwadi Sonar (154)
                                    </option>
                                    <option value="Marwadi Sonar(154)">
                                      Marwadi Sonar(154)
                                    </option>
                                    <option value="Marwadi Sunar (154)">
                                      Marwadi Sunar (154)
                                    </option>
                                    <option value="Marwadi Sunar(154)">
                                      Marwadi Sunar(154)
                                    </option>
                                    <option value="Marwadi Swarnakar (154)">
                                      Marwadi Swarnakar (154)
                                    </option>
                                    <option value="Marwadi Swarnakar(154)">
                                      Marwadi Swarnakar(154)
                                    </option>
                                    <option value="MARWAR BAORI(206)">
                                      MARWAR BAORI(206)
                                    </option>
                                    <option value="MARWAR BORI(96)">
                                      MARWAR BORI(96)
                                    </option>
                                    <option value="MASANJOGI  (17)">
                                      MASANJOGI (17)
                                    </option>
                                    <option value="MATHURA BANJARA(209)">
                                      MATHURA BANJARA(209)
                                    </option>
                                    <option value="Mathura Bnjara(209)">
                                      Mathura Bnjara(209)
                                    </option>
                                    <option value="MATHURA LABHAN (7)(F)">
                                      MATHURA LABHAN (7)(F)
                                    </option>
                                    <option value="MATHURA(104)">
                                      MATHURA(104)
                                    </option>
                                    <option value="MATI WADDAR (12)(C)">
                                      MATI WADDAR (12)(C)
                                    </option>
                                    <option value="MATIHARA(272)">
                                      MATIHARA(272)
                                    </option>
                                    <option value="MATIYARA(272)">
                                      MATIYARA(272)
                                    </option>
                                    <option value="MAZWAR(271)">
                                      MAZWAR(271)
                                    </option>
                                    <option value="ME(97)">ME(97)</option>
                                    <option value="Medar(100)">
                                      Medar(100)
                                    </option>
                                    <option value="Mehtar(7)">Mehtar(7)</option>
                                    <option value="MENDHAGI (13)(D)">
                                      MENDHAGI (13)(D)
                                    </option>
                                    <option value="MERU (153)">
                                      MERU (153)
                                    </option>
                                    <option value="MERU KSHATRIYA SHIMPI(153)">
                                      MERU KSHATRIYA SHIMPI(153)
                                    </option>
                                    <option value="MERU SHIMPI(153)">
                                      MERU SHIMPI(153)
                                    </option>
                                    <option value="Mey(97)">Mey(97)</option>
                                    <option value="Mhali(101)">
                                      Mhali(101)
                                    </option>
                                    <option value="MHALI(108)">
                                      MHALI(108)
                                    </option>
                                    <option value="Mhali(86)">Mhali(86)</option>
                                    <option value="Mina(98)">Mina(98)</option>
                                    <option value="MISTRI(92)">
                                      MISTRI(92)
                                    </option>
                                    <option value="Mitha(102)">
                                      Mitha(102)
                                    </option>
                                    <option value="MITKARIWANI(190)">
                                      MITKARIWANI(190)
                                    </option>
                                    <option value="Mitna (4)(15)">
                                      Mitna (4)(15)
                                    </option>
                                    <option value="MOILY(28)">MOILY(28)</option>
                                    <option value="MOMIN(191)">
                                      MOMIN(191)
                                    </option>
                                    <option value="MONDIWAR(274)">
                                      MONDIWAR(274)
                                    </option>
                                    <option value="MONDIWARA(274)">
                                      MONDIWARA(274)
                                    </option>
                                    <option value="Mourya(85)">
                                      Mourya(85)
                                    </option>
                                    <option value="MUJAWAR(339)">
                                      MUJAWAR(339)
                                    </option>
                                    <option value="Mulaana(340)">
                                      Mulaana(340)
                                    </option>
                                    <option value="MULANA(340)">
                                      MULANA(340)
                                    </option>
                                    <option value="MULANE(340)">
                                      MULANE(340)
                                    </option>
                                    <option value="MULANI(340)">
                                      MULANI(340)
                                    </option>
                                    <option value="MULYA(82)">MULYA(82)</option>
                                    <option value="MUNDA(275)">
                                      MUNDA(275)
                                    </option>
                                    <option value="MUNNAR KAPU (2)">
                                      MUNNAR KAPU (2)
                                    </option>
                                    <option value="Munnerwar(5)(1)">
                                      Munnerwar(5)(1)
                                    </option>
                                    <option value="Munnurwar (5)(2)">
                                      Munnurwar (5)(2)
                                    </option>
                                    <option value="Munnurwar Telagu(5-5)">
                                      Munnurwar Telagu(5-5)
                                    </option>
                                    <option value="MURAI (85)">
                                      MURAI (85)
                                    </option>
                                    <option value="MUSLIM BELDAR (2)">
                                      MUSLIM BELDAR (2)
                                    </option>
                                    <option value="MUSLIM BHAND(8)">
                                      MUSLIM BHAND(8)
                                    </option>
                                    <option value="MUSLIM BHANGI (7)">
                                      MUSLIM BHANGI (7)
                                    </option>
                                    <option value="Muslim Gavandi (2)">
                                      Muslim Gavandi (2)
                                    </option>
                                    <option value="MUSLIM GAWALI (36)">
                                      MUSLIM GAWALI (36)
                                    </option>
                                    <option value="MUSLIM KAKAR 344">
                                      MUSLIM KAKAR 344
                                    </option>
                                    <option value="MUSLIM MADARI (34)">
                                      MUSLIM MADARI (34)
                                    </option>
                                    <option value="Muslim Raj (2)">
                                      Muslim Raj (2)
                                    </option>
                                    <option value="Naalband(337)">
                                      Naalband(337)
                                    </option>
                                    <option value="Naamdhari paik(106)">
                                      Naamdhari paik(106)
                                    </option>
                                    <option value="Naamdhari(105)">
                                      Naamdhari(105)
                                    </option>
                                    <option value="NABHIK(108)">
                                      NABHIK(108)
                                    </option>
                                    <option value="NABHIK(276)">
                                      NABHIK(276)
                                    </option>
                                    <option value="NACCHIMAR (DALDI)(329)">
                                      NACCHIMAR (DALDI)(329)
                                    </option>
                                    <option value="NAI(276)">NAI(276)</option>
                                    <option value="NAIKWADI (1)(B)">
                                      NAIKWADI (1)(B)
                                    </option>
                                    <option value="Nakkashi(111)">
                                      Nakkashi(111)
                                    </option>
                                    <option value="NALBAND(337)">
                                      NALBAND(337)
                                    </option>
                                    <option value="Namdev Shimpi (153)">
                                      Namdev Shimpi (153)
                                    </option>
                                    <option value="NAMDEV(293)">
                                      NAMDEV(293)
                                    </option>
                                    <option value="NAMDHARI PAIK(106)">
                                      NAMDHARI PAIK(106)
                                    </option>
                                    <option value="NAMDHARI(105)">
                                      NAMDHARI(105)
                                    </option>
                                    <option value="NANDIWALE  (18)">
                                      NANDIWALE (18)
                                    </option>
                                    <option value="Nandiwale ( 18 )">
                                      Nandiwale ( 18 )
                                    </option>
                                    <option value="NAPIT(108)">
                                      NAPIT(108)
                                    </option>
                                    <option value="NATH (3)">NATH (3)</option>
                                    <option value="NATH (3)(E)">
                                      NATH (3)(E)
                                    </option>
                                    <option value="Nath Bava (3)(C)">
                                      Nath Bava (3)(C)
                                    </option>
                                    <option value="Nath Jogi(3)(D)">
                                      Nath Jogi(3)(D)
                                    </option>
                                    <option value="NATHJOGI (3)(D)">
                                      NATHJOGI (3)(D)
                                    </option>
                                    <option value="NATHPANTHI DAWARI GOSAVI (3)(E)">
                                      NATHPANTHI DAWARI GOSAVI (3)(E)
                                    </option>
                                    <option value="NATHPANTI (3)(E)">
                                      NATHPANTI (3)(E)
                                    </option>
                                    <option value="NATHPANTI GOSAVI (1)(12)">
                                      NATHPANTI GOSAVI (1)(12)
                                    </option>
                                    <option value="NAVADI (25)(18)">
                                      NAVADI (25)(18)
                                    </option>
                                    <option value="NAVI (108)">
                                      NAVI (108)
                                    </option>
                                    <option value="NAVI BANJARA">
                                      NAVI BANJARA
                                    </option>
                                    <option value="NAVISEN(108)">
                                      NAVISEN(108)
                                    </option>
                                    <option value="NAVLIGA(276)">
                                      NAVLIGA(276)
                                    </option>
                                    <option value="Neelgar(172)">
                                      Neelgar(172)
                                    </option>
                                    <option value="Neeli(112)">
                                      Neeli(112)
                                    </option>
                                    <option value="Neelkanti(113)">
                                      Neelkanti(113)
                                    </option>
                                    <option value="Nekar jada(114)">
                                      Nekar jada(114)
                                    </option>
                                    <option value="Nethura(109)">
                                      Nethura(109)
                                    </option>
                                    <option value="Neve Wani ( 342 )">
                                      Neve Wani ( 342 )
                                    </option>
                                    <option value="NEVEWANI(342)">
                                      NEVEWANI(342)
                                    </option>
                                    <option value="NEVI(190)">NEVI(190)</option>
                                    <option value="Nhavi (108)">
                                      Nhavi (108)
                                    </option>
                                    <option value="NILGAR(172)">
                                      NILGAR(172)
                                    </option>
                                    <option value="NILI(112)">NILI(112)</option>
                                    <option value="NILI(172)">NILI(172)</option>
                                    <option value="NILKANTI(113)">
                                      NILKANTI(113)
                                    </option>
                                    <option value="NIRALI(172)">
                                      NIRALI(172)
                                    </option>
                                    <option value="Nirhalee(320)">
                                      Nirhalee(320)
                                    </option>
                                    <option value="NIRHALI (NIRALI)(320)">
                                      NIRHALI (NIRALI)(320)
                                    </option>
                                    <option value="Nirshikari(107)">
                                      Nirshikari(107)
                                    </option>
                                    <option value="NONIYA(110)">
                                      NONIYA(110)
                                    </option>
                                    <option value="NUNIYA(110)">
                                      NUNIYA(110)
                                    </option>
                                    <option value="OD 2">OD 2</option>
                                    <option value="Oda(25)">Oda(25)</option>
                                    <option value="Oda(25)">Oda(25)</option>
                                    <option value="Odewar(25)">
                                      Odewar(25)
                                    </option>
                                    <option value="Odewar(25)">
                                      Odewar(25)
                                    </option>
                                    <option value="ODEWAR(308)">
                                      ODEWAR(308)
                                    </option>
                                    <option value="Odolu(25)">Odolu(25)</option>
                                    <option value="Odolu(25)">Odolu(25)</option>
                                    <option value="Ojhari (28 D)">
                                      Ojhari (28 D)
                                    </option>
                                    <option value="Otankar(28)(A)">
                                      Otankar(28)(A)
                                    </option>
                                    <option value="Otari (28)">
                                      Otari (28)
                                    </option>
                                    <option value="Otkar (28) (B)">
                                      Otkar (28) (B)
                                    </option>
                                    <option value="Otkari(28)(E)">
                                      Otkari(28)(E)
                                    </option>
                                    <option value="Otokar(28)(E)">
                                      Otokar(28)(E)
                                    </option>
                                    <option value="Paachbhatela(277)">
                                      Paachbhatela(277)
                                    </option>
                                    <option value="Paamula(280)">
                                      Paamula(280)
                                    </option>
                                    <option value="PAANCHAL(266)">
                                      PAANCHAL(266)
                                    </option>
                                    <option value="PACHBHOTALA(277)">
                                      PACHBHOTALA(277)
                                    </option>
                                    <option value="PACHBOTALA(277)">
                                      PACHBOTALA(277)
                                    </option>
                                    <option value="PACHKALASHI(182)">
                                      PACHKALASHI(182)
                                    </option>
                                    <option value="Padampaari(278)">
                                      Padampaari(278)
                                    </option>
                                    <option value="PADAMPARI(278)">
                                      PADAMPARI(278)
                                    </option>
                                    <option value="Padhariya(115)">
                                      Padhariya(115)
                                    </option>
                                    <option value="Padiyar(116)">
                                      Padiyar(116)
                                    </option>
                                    <option value="PADLOR(253)">
                                      PADLOR(253)
                                    </option>
                                    <option value="Padmashali (3)(9)">
                                      Padmashali (3)(9)
                                    </option>
                                    <option value="PAGE(239)">PAGE(239)</option>
                                    <option value="PAHAD(327)">
                                      PAHAD(327)
                                    </option>
                                    <option value="PAHADI(327)">
                                      PAHADI(327)
                                    </option>
                                    <option value="PAKHALI(11)">
                                      PAKHALI(11)
                                    </option>
                                    <option value="PAKHALI(120)">
                                      PAKHALI(120)
                                    </option>
                                    <option value="Pakhali, Sakka(120)">
                                      Pakhali, Sakka(120)
                                    </option>
                                    <option value="PALEWAR (25)(16)">
                                      PALEWAR (25)(16)
                                    </option>
                                    <option value="PALPARADHI (8)">
                                      PALPARADHI (8)
                                    </option>
                                    <option value="PALUMA(280)">
                                      PALUMA(280)
                                    </option>
                                    <option value="PAMLOR (4)(D)">
                                      PAMLOR (4)(D)
                                    </option>
                                    <option value="Panari(190)">
                                      Panari(190)
                                    </option>
                                    <option value="PANCHAL LOHAR (8)">
                                      PANCHAL LOHAR (8)
                                    </option>
                                    <option value="PANCHAL SUTAR(174)">
                                      PANCHAL SUTAR(174)
                                    </option>
                                    <option value="Panchal(121)">
                                      Panchal(121)
                                    </option>
                                    <option value="PANCHAL(266)">
                                      PANCHAL(266)
                                    </option>
                                    <option value="PANCHAM(281)">
                                      PANCHAM(281)
                                    </option>
                                    <option value="PANCHAMA(281)">
                                      PANCHAMA(281)
                                    </option>
                                    <option value="PANDA(282)">
                                      PANDA(282)
                                    </option>
                                    <option value="Pangul ( 19 )">
                                      Pangul ( 19 )
                                    </option>
                                    <option value="Panka(122)">
                                      Panka(122)
                                    </option>
                                    <option value="PARDESHI BHAMATA (10)(A)">
                                      PARDESHI BHAMATA (10)(A)
                                    </option>
                                    <option value="PARDESHI BHAMATI (10)(B)">
                                      PARDESHI BHAMATI (10)(B)
                                    </option>
                                    <option value="Pardeshi Bhamta (10)(A)">
                                      Pardeshi Bhamta (10)(A)
                                    </option>
                                    <option value="Pardeshi Bhamti (10)(B)">
                                      Pardeshi Bhamti (10)(B)
                                    </option>
                                    <option value="PARDESHI BHOI (25)(2)">
                                      PARDESHI BHOI (25)(2)
                                    </option>
                                    <option value="PARDESHI(220)">
                                      PARDESHI(220)
                                    </option>
                                    <option value="PAREET(125)">
                                      PAREET(125)
                                    </option>
                                    <option value="Parit (125)">
                                      Parit (125)
                                    </option>
                                    <option value="Parit/Dhobi(125)">
                                      Parit/Dhobi(125)
                                    </option>
                                    <option value="PARIWAR(323)">
                                      PARIWAR(323)
                                    </option>
                                    <option value="PARJIYA SONI(53)">
                                      PARJIYA SONI(53)
                                    </option>
                                    <option value="PATAVEGAR(126)">
                                      PATAVEGAR(126)
                                    </option>
                                    <option value="PATAVEKARI(126)">
                                      PATAVEKARI(126)
                                    </option>
                                    <option value="PATAVI(126)">
                                      PATAVI(126)
                                    </option>
                                    <option value="PATAWA 346">
                                      PATAWA 346
                                    </option>
                                    <option value="PATEGAR(126)">
                                      PATEGAR(126)
                                    </option>
                                    <option value="PATHARE KSHATRIYA(182)">
                                      PATHARE KSHATRIYA(182)
                                    </option>
                                    <option value="PATHARE-KSHATRIYA-PACHKALASHI(182)">
                                      PATHARE-KSHATRIYA-PACHKALASHI(182)
                                    </option>
                                    <option value="PATHARWAT (12)(D)">
                                      PATHARWAT (12)(D)
                                    </option>
                                    <option value="PATHROD(304)">
                                      PATHROD(304)
                                    </option>
                                    <option value="PATHRUT 3(D)">
                                      PATHRUT 3(D)
                                    </option>
                                    <option value="Patkar(126)">
                                      Patkar(126)
                                    </option>
                                    <option value="Patradavaru(117)">
                                      Patradavaru(117)
                                    </option>
                                    <option value="PATRADAWARU(117)">
                                      PATRADAWARU(117)
                                    </option>
                                    <option value="PATTEGAR(126)">
                                      PATTEGAR(126)
                                    </option>
                                    <option value="PATVI (126)">
                                      PATVI (126)
                                    </option>
                                    <option value="Patwegar(126)">
                                      Patwegar(126)
                                    </option>
                                    <option value="Patwekari(126)">
                                      Patwekari(126)
                                    </option>
                                    <option value="PAWAR(189)">
                                      PAWAR(189)
                                    </option>
                                    <option value="PENDARI">PENDARI</option>
                                    <option value="Pendhari(332)">
                                      Pendhari(332)
                                    </option>
                                    <option value="PERAKA(123)">
                                      PERAKA(123)
                                    </option>
                                    <option value="PERAKEWAD(123)">
                                      PERAKEWAD(123)
                                    </option>
                                    <option value="PERIKA(123)">
                                      PERIKA(123)
                                    </option>
                                    <option value="PERIKE(123)">
                                      PERIKE(123)
                                    </option>
                                    <option value="Perkewad(123)">
                                      Perkewad(123)
                                    </option>
                                    <option value="Perki(123)">
                                      Perki(123)
                                    </option>
                                    <option value="Phanade Banjara(212)">
                                      Phanade Banjara(212)
                                    </option>
                                    <option value="Phar(283)">Phar(283)</option>
                                    <option value="Phasechari(118)">
                                      Phasechari(118)
                                    </option>
                                    <option value="Phudgee(119)">
                                      Phudgee(119)
                                    </option>
                                    <option value="Phul Mali (159)">
                                      Phul Mali (159)
                                    </option>
                                    <option value="Phulari(127)">
                                      Phulari(127)
                                    </option>
                                    <option value="Phulmali(182)">
                                      Phulmali(182)
                                    </option>
                                    <option value="Phutgudi(175)">
                                      Phutgudi(175)
                                    </option>
                                    <option value="Pinjara(177)">
                                      Pinjara(177)
                                    </option>
                                    <option value="Pinjari (177)">
                                      Pinjari (177)
                                    </option>
                                    <option value="Pinjari(177)">
                                      Pinjari(177)
                                    </option>
                                    <option value="PINJARI(284)">
                                      PINJARI(284)
                                    </option>
                                    <option value="PONG(221)">PONG(221)</option>
                                    <option value="Powar(189)">
                                      Powar(189)
                                    </option>
                                    <option value="PURI (1)(13)">
                                      PURI (1)(13)
                                    </option>
                                    <option value="PURWALI(285)">
                                      PURWALI(285)
                                    </option>
                                    <option value="PUTALI GAR(124)">
                                      PUTALI GAR(124)
                                    </option>
                                    <option value="QURESHI KHATIK(316)">
                                      QURESHI KHATIK(316)
                                    </option>
                                    <option value="QURESHI(257)">
                                      QURESHI(257)
                                    </option>
                                    <option value="Rachbandhiya(131)">
                                      Rachbandhiya(131)
                                    </option>
                                    <option value="RACHBHOYA(286)">
                                      RACHBHOYA(286)
                                    </option>
                                    <option value="RACHEVAR(128)">
                                      RACHEVAR(128)
                                    </option>
                                    <option value="Rachewar(128)">
                                      Rachewar(128)
                                    </option>
                                    <option value="Raghavi(186)">
                                      Raghavi(186)
                                    </option>
                                    <option value="Raikari(129)">
                                      Raikari(129)
                                    </option>
                                    <option value="RAIN (BAGWAN)(182)">
                                      RAIN (BAGWAN)(182)
                                    </option>
                                    <option value="Rajak(125)">
                                      Rajak(125)
                                    </option>
                                    <option value="RAJBHOI (25)(3)">
                                      RAJBHOI (25)(3)
                                    </option>
                                    <option value="RAJPARDHI (9)">
                                      RAJPARDHI (9)
                                    </option>
                                    <option value="Rajput Bhamta (183)">
                                      Rajput Bhamta (183)
                                    </option>
                                    <option value="Rajput Bhamta(10)">
                                      Rajput Bhamta(10)
                                    </option>
                                    <option value="RAJPUT LOHAR (8)">
                                      RAJPUT LOHAR (8)
                                    </option>
                                    <option value="Rajput Lohar(8)">
                                      Rajput Lohar(8)
                                    </option>
                                    <option value="RAMOSHI(11)">
                                      RAMOSHI(11)
                                    </option>
                                    <option value="Rangari Bhavsar(170)">
                                      Rangari Bhavsar(170)
                                    </option>
                                    <option value="Rangari(170)">
                                      Rangari(170)
                                    </option>
                                    <option value="Rangrej(Bhavsar,Rangari)(170)">
                                      Rangrej(Bhavsar,Rangari)(170)
                                    </option>
                                    <option value="RANGREZ(133)">
                                      RANGREZ(133)
                                    </option>
                                    <option value="Rangrez(170)">
                                      Rangrez(170)
                                    </option>
                                    <option value="RAOT(134)">RAOT(134)</option>
                                    <option value="RATHOD (347)">
                                      RATHOD (347)
                                    </option>
                                    <option value="RAUL (20)">RAUL (20)</option>
                                    <option value="RAUTIYA(287)">
                                      RAUTIYA(287)
                                    </option>
                                    <option value="RAWAL (20)">
                                      RAWAL (20)
                                    </option>
                                    <option value="RAWALYOGI">RAWALYOGI</option>
                                    <option value="Rawat(134)">
                                      Rawat(134)
                                    </option>
                                    <option value="Rawtiya(134)">
                                      Rawtiya(134)
                                    </option>
                                    <option value="RAYIKAR(129)">
                                      RAYIKAR(129)
                                    </option>
                                    <option value="REVA GUJAR(326)">
                                      REVA GUJAR(326)
                                    </option>
                                    <option value="REVE GUJAR(326)">
                                      REVE GUJAR(326)
                                    </option>
                                    <option value="S.K.P(182)">
                                      S.K.P(182)
                                    </option>
                                    <option value="Saahis(147)">
                                      Saahis(147)
                                    </option>
                                    <option value="Saais(147)">
                                      Saais(147)
                                    </option>
                                    <option value="Saare(292)">
                                      Saare(292)
                                    </option>
                                    <option value="Saaunta(290)">
                                      Saaunta(290)
                                    </option>
                                    <option value="SAGAR (1)(7)">
                                      SAGAR (1)(7)
                                    </option>
                                    <option value="SAGAR (29)(8)">
                                      SAGAR (29)(8)
                                    </option>
                                    <option value="SAHASRARJUN KSHATRIYA(126)">
                                      SAHASRARJUN KSHATRIYA(126)
                                    </option>
                                    <option value="SAHDEV JOSHI 13(F)">
                                      SAHDEV JOSHI 13(F)
                                    </option>
                                    <option value="SAHIS(147)">
                                      SAHIS(147)
                                    </option>
                                    <option value="SAI (153)">SAI (153)</option>
                                    <option value="SAIKALGAR (21)">
                                      SAIKALGAR (21)
                                    </option>
                                    <option value="SAIKALKAR (21)">
                                      SAIKALKAR (21)
                                    </option>
                                    <option value="SAIS(147)">SAIS(147)</option>
                                    <option value="SAITWAL(153)">
                                      SAITWAL(153)
                                    </option>
                                    <option value="Sakka(11)">Sakka(11)</option>
                                    <option value="SAKKA(120)">
                                      SAKKA(120)
                                    </option>
                                    <option value="SALAT (13)(A)">
                                      SALAT (13)(A)
                                    </option>
                                    <option value="SALAT WAGHARI (13)(B)">
                                      SALAT WAGHARI (13)(B)
                                    </option>
                                    <option value="SALAT(13)">SALAT(13)</option>
                                    <option value="Salewar(3),(8)">
                                      Salewar(3),(8)
                                    </option>
                                    <option value="Sali (3 )( 4 )">
                                      Sali (3 )( 4 )
                                    </option>
                                    <option value="SALI (4)">SALI (4)</option>
                                    <option value="SALMANI(108)">
                                      SALMANI(108)
                                    </option>
                                    <option value="SALMANIYA(108)">
                                      SALMANIYA(108)
                                    </option>
                                    <option value="Sanagar (29)(20)">
                                      Sanagar (29)(20)
                                    </option>
                                    <option value="SANCHALU WADDAR(2)">
                                      SANCHALU WADDAR(2)
                                    </option>
                                    <option value="SANDURI(239)">
                                      SANDURI(239)
                                    </option>
                                    <option value="SANGAR (29)(20)">
                                      SANGAR (29)(20)
                                    </option>
                                    <option value="SANGARI(288)">
                                      SANGARI(288)
                                    </option>
                                    <option value="SANGATARASH (12)(E)">
                                      SANGATARASH (12)(E)
                                    </option>
                                    <option value="Sanjogi(140)">
                                      Sanjogi(140)
                                    </option>
                                    <option value="Santaal(289)">
                                      Santaal(289)
                                    </option>
                                    <option value="SANTAL(289)">
                                      SANTAL(289)
                                    </option>
                                    <option value="SANYASI (1)(11)">
                                      SANYASI (1)(11)
                                    </option>
                                    <option value="sapalig (145)">
                                      sapalig (145)
                                    </option>
                                    <option value="Sapaliga (145)">
                                      Sapaliga (145)
                                    </option>
                                    <option value="SAPERA (148)">
                                      SAPERA (148)
                                    </option>
                                    <option value="SAPGARUDI (7)">
                                      SAPGARUDI (7)
                                    </option>
                                    <option value="Sappaliga (145)">
                                      Sappaliga (145)
                                    </option>
                                    <option value="SAPWALE (34)">
                                      SAPWALE (34)
                                    </option>
                                    <option value="SARANIYA(141)">
                                      SARANIYA(141)
                                    </option>
                                    <option value="SARASWATI PARWAT 1(6)">
                                      SARASWATI PARWAT 1(6)
                                    </option>
                                    <option value="SARAWADE (13)(M)">
                                      SARAWADE (13)(M)
                                    </option>
                                    <option value="SARE(292)">SARE(292)</option>
                                    <option value="SARODA (13)(H)">
                                      SARODA (13)(H)
                                    </option>
                                    <option value="Saroda (142)">
                                      Saroda (142)
                                    </option>
                                    <option value="SARODE (13)(E)">
                                      SARODE (13)(E)
                                    </option>
                                    <option value="SARODI (13)(E)">
                                      SARODI (13)(E)
                                    </option>
                                    <option value="SASTIKAR(182)">
                                      SASTIKAR(182)
                                    </option>
                                    <option value="SAUNTA(290)">
                                      SAUNTA(290)
                                    </option>
                                    <option value="Savkalaar(324)">
                                      Savkalaar(324)
                                    </option>
                                    <option value="SAVKALAR(324)">
                                      SAVKALAR(324)
                                    </option>
                                    <option value="SAVTELI(291)">
                                      SAVTELI(291)
                                    </option>
                                    <option value="SAWATA MALI(182)">
                                      SAWATA MALI(182)
                                    </option>
                                    <option value="SAWTELI (181)">
                                      SAWTELI (181)
                                    </option>
                                    <option value="SAYKALGAR (21)">
                                      SAYKALGAR (21)
                                    </option>
                                    <option value="SEGAR 29(8)">
                                      SEGAR 29(8)
                                    </option>
                                    <option value="SEKKALGAR (MUSLIM) (21)">
                                      SEKKALGAR (MUSLIM) (21)
                                    </option>
                                    <option value="SHARADAKAL (17)(3)">
                                      SHARADAKAL (17)(3)
                                    </option>
                                    <option value="SHARADAKAR (17)(3)">
                                      SHARADAKAR (17)(3)
                                    </option>
                                    <option value="SHARDAKAR (17)(3)">
                                      SHARDAKAR (17)(3)
                                    </option>
                                    <option value="SHEGAR (29)(8)">
                                      SHEGAR (29)(8)
                                    </option>
                                    <option value="SHEGAR(29)(8)">
                                      SHEGAR(29)(8)
                                    </option>
                                    <option value="SHERIGAR(28)">
                                      SHERIGAR(28)
                                    </option>
                                    <option value="SHETWAL(153)">
                                      SHETWAL(153)
                                    </option>
                                    <option value="Shigadiya Banjara(215)">
                                      Shigadiya Banjara(215)
                                    </option>
                                    <option value="SHIKALGAR (21)">
                                      SHIKALGAR (21)
                                    </option>
                                    <option value="Shikalgar(207)">
                                      Shikalgar(207)
                                    </option>
                                    <option value="SHIKALIGAAR (21)">
                                      SHIKALIGAAR (21)
                                    </option>
                                    <option value="SHIKALIGAR (21)">
                                      SHIKALIGAR (21)
                                    </option>
                                    <option value="SHIKALIKAR (21)">
                                      SHIKALIKAR (21)
                                    </option>
                                    <option value="SHIKALKAR(21)">
                                      SHIKALKAR(21)
                                    </option>
                                    <option value="SHIKH-SHIKALIKAR (21)">
                                      SHIKH-SHIKALIKAR (21)
                                    </option>
                                    <option value="SHIKILGAR (21)">
                                      SHIKILGAR (21)
                                    </option>
                                    <option value="SHILAWAT(149)">
                                      SHILAWAT(149)
                                    </option>
                                    <option value="SHIMPI (153)">
                                      SHIMPI (153)
                                    </option>
                                    <option value="SHINGADE BANJARA(210)">
                                      SHINGADE BANJARA(210)
                                    </option>
                                    <option value="SHINGADYA BANJARA(215)">
                                      SHINGADYA BANJARA(215)
                                    </option>
                                    <option value="SHINGADYA(294)  ">
                                      SHINGADYA(294){" "}
                                    </option>
                                    <option value="Shingdav(294)">
                                      Shingdav(294)
                                    </option>
                                    <option value="SHINGDAWA(294)">
                                      SHINGDAWA(294)
                                    </option>
                                    <option value="Shis(147)">Shis(147)</option>
                                    <option value="SHIV SHIMPI (293)">
                                      SHIV SHIMPI (293)
                                    </option>
                                    <option value="SHIVHARE(254)">
                                      SHIVHARE(254)
                                    </option>
                                    <option value="SHRAVAK SHIMPI(153)">
                                      SHRAVAK SHIMPI(153)
                                    </option>
                                    <option value="Sikalgar (207)">
                                      Sikalgar (207)
                                    </option>
                                    <option value="SIKALGAR (21)">
                                      SIKALGAR (21)
                                    </option>
                                    <option value="SIKALIGAR (21)">
                                      SIKALIGAR (21)
                                    </option>
                                    <option value="SIKALIGHAR (21)">
                                      SIKALIGHAR (21)
                                    </option>
                                    <option value="SIKALIKAR (21)">
                                      SIKALIKAR (21)
                                    </option>
                                    <option value="SIKALKAR (21)">
                                      SIKALKAR (21)
                                    </option>
                                    <option value="SIKILGAR (21)">
                                      SIKILGAR (21)
                                    </option>
                                    <option value="SIKILKAR (21)">
                                      SIKILKAR (21)
                                    </option>
                                    <option value="Sikkalgar(21)">
                                      Sikkalgar(21)
                                    </option>
                                    <option value="SIKKALIGAR (21)">
                                      SIKKALIGAR (21)
                                    </option>
                                    <option value="SIKKALKAR (21)">
                                      SIKKALKAR (21)
                                    </option>
                                    <option value="SINDHUR(295)">
                                      SINDHUR(295)
                                    </option>
                                    <option value="SINGARI (7)(N)">
                                      SINGARI (7)(N)
                                    </option>
                                    <option value="Singiwala(150)">
                                      Singiwala(150)
                                    </option>
                                    <option value="Sinyur(295)">
                                      Sinyur(295)
                                    </option>
                                    <option value="Somavanshiya Sahastrarjun Kshatriya(126)">
                                      Somavanshiya Sahastrarjun Kshatriya(126)
                                    </option>
                                    <option value="SOMVANSHIYA(126)">
                                      SOMVANSHIYA(126)
                                    </option>
                                    <option value="SOMVANSHIYA-PATHARE KSHATRIYA(182)">
                                      SOMVANSHIYA-PATHARE KSHATRIYA(182)
                                    </option>
                                    <option value="Somwanshi arya Shatriya(8)">
                                      Somwanshi arya Shatriya(8)
                                    </option>
                                    <option value="Somwanshi(8)">
                                      Somwanshi(8)
                                    </option>
                                    <option value="Sonar(154)">
                                      Sonar(154)
                                    </option>
                                    <option value="SONKOLI(4)(11)">
                                      SONKOLI(4)(11)
                                    </option>
                                    <option value="SONTA(290)">
                                      SONTA(290)
                                    </option>
                                    <option value="SORE(296)">SORE(296)</option>
                                    <option value="SUDGADSIDDHA (17)(1)">
                                      SUDGADSIDDHA (17)(1)
                                    </option>
                                    <option value="Sudhariya(146)">
                                      Sudhariya(146)
                                    </option>
                                    <option value="Sudi(122)">Sudi(122)</option>
                                    <option value="SUNAR BANJARA(213)">
                                      SUNAR BANJARA(213)
                                    </option>
                                    <option value="SUNNA(297)">
                                      SUNNA(297)
                                    </option>
                                    <option value="SUNNAI(298)">
                                      SUNNAI(298)
                                    </option>
                                    <option value="Supalig (145)">
                                      Supalig (145)
                                    </option>
                                    <option value="Suppalig (145)">
                                      Suppalig (145)
                                    </option>
                                    <option value="SUPPALING(145)">
                                      SUPPALING(145)
                                    </option>
                                    <option value="SURYAVANSHI GUJAR(326)">
                                      SURYAVANSHI GUJAR(326)
                                    </option>
                                    <option value="Suryawanshi Koli (4) ( 9 )">
                                      Suryawanshi Koli (4) ( 9 )
                                    </option>
                                    <option value="Sutar(153)">
                                      Sutar(153)
                                    </option>
                                    <option value="SUTAR(174)">
                                      SUTAR(174)
                                    </option>
                                    <option value="SUTAR(182)">
                                      SUTAR(182)
                                    </option>
                                    <option value="SUTHAR(174) ">
                                      SUTHAR(174){" "}
                                    </option>
                                    <option value="SUTHARIYA(146)">
                                      SUTHARIYA(146)
                                    </option>
                                    <option value="SWAKUL SALI (3) (4)">
                                      SWAKUL SALI (3) (4)
                                    </option>
                                    <option value="Swakul Sali(131)">
                                      Swakul Sali(131)
                                    </option>
                                    <option value="Takari (3)(E)">
                                      Takari (3)(E)
                                    </option>
                                    <option value="Takari (3)(E)">
                                      Takari (3)(E)
                                    </option>
                                    <option value="TALWAR (1)(C)">
                                      TALWAR (1)(C)
                                    </option>
                                    <option value="TALWAR-KANADE(185)">
                                      TALWAR-KANADE(185)
                                    </option>
                                    <option value="TALWAR-KANADI(185)">
                                      TALWAR-KANADI(185)
                                    </option>
                                    <option value="Tambat(160)">
                                      Tambat(160)
                                    </option>
                                    <option value="Tambodi(195)">
                                      Tambodi(195)
                                    </option>
                                    <option value="TAMBOLI MUSLIM PANFAROSH(195)">
                                      TAMBOLI MUSLIM PANFAROSH(195)
                                    </option>
                                    <option value="TAMBOLI(190)">
                                      TAMBOLI(190)
                                    </option>
                                    <option value="Tamboli(195)">
                                      Tamboli(195)
                                    </option>
                                    <option value="Tandel(155)">
                                      Tandel(155)
                                    </option>
                                    <option value="TARANE TELI (181)">
                                      TARANE TELI (181)
                                    </option>
                                    <option value="Targal(9)">Targal(9)</option>
                                    <option value="TARGALA(157)">
                                      TARGALA(157)
                                    </option>
                                    <option value="Taru-Navadi (25)(18)">
                                      Taru-Navadi (25)(18)
                                    </option>
                                    <option value="Telagu Kapevar(5-8)">
                                      Telagu Kapevar(5-8)
                                    </option>
                                    <option value="TELAGU MADELWAR PARIT(125)">
                                      TELAGU MADELWAR PARIT(125)
                                    </option>
                                    <option value="Telanga(2)">
                                      Telanga(2)
                                    </option>
                                    <option value="Telangi(2.07)">
                                      Telangi(2.07)
                                    </option>
                                    <option value="Telangi(29)">
                                      Telangi(29)
                                    </option>
                                    <option value="TELANGI(29)(10)">
                                      TELANGI(29)(10)
                                    </option>
                                    <option value="TELGU DARJI(153)">
                                      TELGU DARJI(153)
                                    </option>
                                    <option value="Telgu Madelwar (Parit) (125)">
                                      Telgu Madelwar (Parit) (125)
                                    </option>
                                    <option value="Telgu Madelwar(125)">
                                      Telgu Madelwar(125)
                                    </option>
                                    <option value="Telgu Phulmali (5)(10)">
                                      Telgu Phulmali (5)(10)
                                    </option>
                                    <option value="TELGU SHIMPI(153)">
                                      TELGU SHIMPI(153)
                                    </option>
                                    <option value="Teli (181)">
                                      Teli (181)
                                    </option>
                                    <option value="TELLARI (29)(11)">
                                      TELLARI (29)(11)
                                    </option>
                                    <option value="Telvar (29)(5)">
                                      Telvar (29)(5)
                                    </option>
                                    <option value="TELWAR (29)(5)">
                                      TELWAR (29)(5)
                                    </option>
                                    <option value="Thelari(27)">
                                      Thelari(27)
                                    </option>
                                    <option value="THELLA PAMALWADAS(307)">
                                      THELLA PAMALWADAS(307)
                                    </option>
                                    <option value="Thetwar(158)">
                                      Thetwar(158)
                                    </option>
                                    <option value="THIYA(224)">
                                      THIYA(224)
                                    </option>
                                    <option value="Thogti(161)">
                                      Thogti(161)
                                    </option>
                                    <option value="Thoriya(159)">
                                      Thoriya(159)
                                    </option>
                                    <option value="THOTEWADU(301)">
                                      THOTEWADU(301)
                                    </option>
                                    <option value="TILVAN TELI (181)">
                                      TILVAN TELI (181)
                                    </option>
                                    <option value="Timaali(302)">
                                      Timaali(302)
                                    </option>
                                    <option value="TIMALI(302)">
                                      TIMALI(302)
                                    </option>
                                    <option value="TIRMAL (18)">
                                      TIRMAL (18)
                                    </option>
                                    <option value="Tirmal(18)">
                                      Tirmal(18)
                                    </option>
                                    <option value="TIRTH ASHRAM (1)(9)">
                                      TIRTH ASHRAM (1)(9)
                                    </option>
                                    <option value="Twashta Kasar(160)">
                                      Twashta Kasar(160)
                                    </option>
                                    <option value="UCHALE (3)(F)">
                                      UCHALE (3)(F)
                                    </option>
                                    <option value="UCHILA(223)">
                                      UCHILA(223)
                                    </option>
                                    <option value="UDASI (207)">
                                      UDASI (207)
                                    </option>
                                    <option value="UNDE(182)">UNDE(182)</option>
                                    <option value="V.Wani(190)">
                                      V.Wani(190)
                                    </option>
                                    <option value="Vaadhai(174)">
                                      Vaadhai(174)
                                    </option>
                                    <option value="Vadar(12)">Vadar(12)</option>
                                    <option value="Vaddar (12)">
                                      Vaddar (12)
                                    </option>
                                    <option value="VADWAL(182)">
                                      VADWAL(182)
                                    </option>
                                    <option value="VAIDU (23)">
                                      VAIDU (23)
                                    </option>
                                    <option value="Vaishya Wani(190)">
                                      Vaishya Wani(190)
                                    </option>
                                    <option value="VAITI (4)(12)">
                                      VAITI (4)(12)
                                    </option>
                                    <option value="Vanjari(30)">
                                      Vanjari(30)
                                    </option>
                                    <option value="VANMALI (182)">
                                      VANMALI (182)
                                    </option>
                                    <option value="VANSFOD(164)">
                                      VANSFOD(164)
                                    </option>
                                    <option value="VANYA(251)">
                                      VANYA(251)
                                    </option>
                                    <option value="Varhade Dhangar (29)(14)">
                                      Varhade Dhangar (29)(14)
                                    </option>
                                    <option value="VASUDEO (24)">
                                      VASUDEO (24)
                                    </option>
                                    <option value="VASUDEV (24)">
                                      VASUDEV (24)
                                    </option>
                                    <option value="Vatari (28)(C)">
                                      Vatari (28)(C)
                                    </option>
                                    <option value="Vatkar(28)(E)">
                                      Vatkar(28)(E)
                                    </option>
                                    <option value="Vatkari(28)(E)">
                                      Vatkari(28)(E)
                                    </option>
                                    <option value="Vatok(28)(F)">
                                      Vatok(28)(F)
                                    </option>
                                    <option value="Vatokar(28)(E)">
                                      Vatokar(28)(E)
                                    </option>
                                    <option value="VEDU WAGHARI(318)">
                                      VEDU WAGHARI(318)
                                    </option>
                                    <option value="VINKAR(251)">
                                      VINKAR(251)
                                    </option>
                                    <option value="VIR (16)(B)">
                                      VIR (16)(B)
                                    </option>
                                    <option value="Votankar(28)(E)">
                                      Votankar(28)(E)
                                    </option>
                                    <option value="Waadhai(313)">
                                      Waadhai(313)
                                    </option>
                                    <option value="Waadi(174)">
                                      Waadi(174)
                                    </option>
                                    <option value="Waalvai(303)">
                                      Waalvai(303)
                                    </option>
                                    <option value="WADADER(304)">
                                      WADADER(304)
                                    </option>
                                    <option value="WADAI(174)">
                                      WADAI(174)
                                    </option>
                                    <option value="WADAR (12)">
                                      WADAR (12)
                                    </option>
                                    <option value="WADDAR (12)(E)">
                                      WADDAR (12)(E)
                                    </option>
                                    <option value="Wadee(162)">
                                      Wadee(162)
                                    </option>
                                    <option value="WADHAAI(174)">
                                      WADHAAI(174)
                                    </option>
                                    <option value="WADHAI(174)">
                                      WADHAI(174)
                                    </option>
                                    <option value="WADHI(174)">
                                      WADHI(174)
                                    </option>
                                    <option value="WADI(162)">WADI(162)</option>
                                    <option value="WADI(174)">WADI(174)</option>
                                    <option value="WAGHARI (13)">
                                      WAGHARI (13)
                                    </option>
                                    <option value="Waghri(13)">
                                      Waghri(13)
                                    </option>
                                    <option value="WAGHWALE-SHAH (MUSLIM) (37)">
                                      WAGHWALE-SHAH (MUSLIM) (37)
                                    </option>
                                    <option value="WALAND(108)">
                                      WALAND(108)
                                    </option>
                                    <option value="WALAND(276)">
                                      WALAND(276)
                                    </option>
                                    <option value="WALMIKI (1)(D)">
                                      WALMIKI (1)(D)
                                    </option>
                                    <option value="WALVAI(303)">
                                      WALVAI(303)
                                    </option>
                                    <option value="WAN (1)(8)">
                                      WAN (1)(8)
                                    </option>
                                    <option value="WANADI(305)">
                                      WANADI(305)
                                    </option>
                                    <option value="WANI (190)">
                                      WANI (190)
                                    </option>
                                    <option value="Wani (Kulwant)(190)">
                                      Wani (Kulwant)(190)
                                    </option>
                                    <option value="WANI(190)">WANI(190)</option>
                                    <option value="WANJAR (30)">
                                      WANJAR (30)
                                    </option>
                                    <option value="Wanjar(30)">
                                      Wanjar(30)
                                    </option>
                                    <option value="WANJARA (30)">
                                      WANJARA (30)
                                    </option>
                                    <option value="WANJARI (30)">
                                      WANJARI (30)
                                    </option>
                                    <option value="Wansphod(164)">
                                      Wansphod(164)
                                    </option>
                                    <option value="WARHADE DHANGAR (29)(14)">
                                      WARHADE DHANGAR (29)(14)
                                    </option>
                                    <option value="WARIK(108)">
                                      WARIK(108)
                                    </option>
                                    <option value="Warthi(166)">
                                      Warthi(166)
                                    </option>
                                    <option value="Watas(125)">
                                      Watas(125)
                                    </option>
                                    <option value="Watkar(28)(E)">
                                      Watkar(28)(E)
                                    </option>
                                    <option value="WATOKAR 28(E)">
                                      WATOKAR 28(E)
                                    </option>
                                    <option value="Wedu(318)">Wedu(318)</option>
                                    <option value="YADAV(198)">
                                      YADAV(198)
                                    </option>
                                    <option value="YALAM(333)">
                                      YALAM(333)
                                    </option>
                                    <option value="YALLAM(333)">
                                      YALLAM(333)
                                    </option>
                                    <option value="YELAM(333)">
                                      YELAM(333)
                                    </option>
                                    <option value="YENADIWADAS(306)">
                                      YENADIWADAS(306)
                                    </option>
                                    <option value="Yenadiwads(306)">
                                      Yenadiwads(306)
                                    </option>
                                    <option value="Yeragolawad(307)">
                                      Yeragolawad(307)
                                    </option>
                                    <option value="YERGOLAWAD(307)">
                                      YERGOLAWAD(307)
                                    </option>
                                    <option value="Yerkula(168)">
                                      Yerkula(168)
                                    </option>
                                    <option value="ZADE SUTAR(174)">
                                      ZADE SUTAR(174)
                                    </option>
                                    <option value="Zade(29)(15)">
                                      Zade(29)(15)
                                    </option>
                                    <option value="ZENDE (29)(16)">
                                      ZENDE (29)(16)
                                    </option>
                                    <option value="ZINGA BHOI (25)(1)">
                                      ZINGA BHOI (25)(1)
                                    </option>
                                    <option value="Zinga Bhoi(25)(1)">
                                      Zinga Bhoi(25)(1)
                                    </option>
                                    <option value="zomb">zomb</option>
                                  </select>
                                  <p className="red">
                                    {Formerror.benificiary_Cast}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_lblsubcaste">Category</span>
                                  <span class="red">*</span>
                                  <input
                                    name="benificiary_Catagary"
                                    type="text"
                                    maxlength="6"
                                    id="CPH_Pincode"
                                    //tabindex="25"
                                    class="form-control"
                                    value={Data.benificiary_Catagary}
                                    onChange={(e) => handleChange(e)}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                  <p className="red">
                                    {Formerror.benificiary_Catagary}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label37">
                                    Validity Duration{" "}
                                  </span>
                                  <span class="red">*</span>
                                  <select
                                    name="brnificiary_Validity_Duration"
                                    id="CPH_ddlIncome"
                                    //tabindex="50"
                                    class="form-control"
                                    value={Data.brnificiary_Validity_Duration}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        brnificiary_Validity_Duration:
                                          e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="---Select---">
                                      ---Select---
                                    </option>
                                    <option value="1">1 Year</option>
                                    <option value="3">2 Years</option>
                                    <option value="2">3 Years</option>
                                  </select>
                                  <p className="red">
                                    {Formerror.brnificiary_Validity_Duration}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* If migrated from one district to another district? (Yes/No) */}
                  <div id="CPH_divST" class="form-bg" role="form">
                    <div
                      className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div class="box-body">
                        <div class="row">
                          <div class="col-md-12">
                            <div
                              className="col-lg-12 text-center border rounded mb-3"
                              style={{ backgroundColor: "#e0e0e0" }}
                            >
                              <h3 class="box-title">
                                <span id="CPH_lbl_AppDtl">
                                  If migrated from one district to another
                                  district? (Yes/No)
                                </span>
                              </h3>
                            </div>{" "}
                            <div class="row">
                              <div class="col-md-12">
                                <div class="fs-6">
                                  <span id="CPH_lblST">
                                    If migrated from one district to another
                                    district? (Yes/No)
                                  </span>
                                  <span class="red">*</span>
                                  <table
                                    name="migration_MH_From_Another_State"
                                    value={Data.migration_MH_From_Another_State}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        migration_MH_From_Another_State:
                                          e.target.value,
                                      });
                                      if (e.target.value === "Yes") {
                                        SetMigrantAddressDetails(true);
                                      } else if (e.target.value === "No") {
                                        SetMigrantAddressDetails(false);
                                        SetData({
                                          ...Data,
                                          Migrant_Distict: "",
                                          Migrant_Taluka: "",
                                          Migrant_village: "",
                                          isAppliedForCastCertificate: "",
                                        });
                                      }
                                    }}
                                  >
                                    <tr>
                                      <td>
                                        <input
                                          id="CPH_rdMigDist_0"
                                          type="radio"
                                          name="ctl00$CPH$rdMigDist"
                                          value="No"
                                          //tabindex="51"
                                        />
                                        <label for="CPH_rdMigDist_0">No</label>
                                      </td>
                                      <td>
                                        <input
                                          id="CPH_rdMigDist_1"
                                          type="radio"
                                          name="ctl00$CPH$rdMigDist"
                                          value="Yes"
                                          checked="checked"
                                          //tabindex="51"
                                        />
                                        <label for="CPH_rdMigDist_1">Yes</label>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Migrant Address Details */}
                  {MigrantAddressDetails && (
                    <>
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div class="box-body">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div
                                  className="col-lg-12 text-center border rounded mb-3"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Migrant Address Details
                                    </span>
                                  </h3>
                                </div>{" "}
                                <div class="col-md-4">
                                  <div class="fs-6">
                                    <span id="CPH_Label46">District</span>
                                    <span class="red">*</span>
                                    <select
                                      name="Migrant_Distict"
                                      id="CPH_ddlmigdist"
                                      //tabindex="63"
                                      class="form-control"
                                      value={Data.Migrant_Distict}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setTalukasData(e.target.value);
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    >
                                      <option value="">
                                        -- Select Distict--
                                      </option>
                                      {districtData.map((item, i) => {
                                        return (
                                          // <Select.Option value={item.name} key={i}>
                                          //   {item.name}
                                          // </Select.Option>
                                          <option value={item.name}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <input
                                      type="hidden"
                                      name="ctl00$CPH$CascadingDropDown10_ClientState"
                                      id="CPH_CascadingDropDown10_ClientState"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="fs-6">
                                    <span id="CPH_Label108">Taluka</span>
                                    <span class="red">*</span>
                                    <select
                                      name="Migrant_Taluka"
                                      id="CPH_ddlmigtaluka"
                                      //tabindex="64"
                                      class="form-control"
                                      value={Data.Migrant_Taluka}
                                      onChange={(e) => {
                                        SetData({
                                          ...Data,
                                          Migrant_Taluka: e.target.value,
                                        });
                                      }}
                                      onkeypress="return IsAlphabet(event);"
                                    >
                                      <option value="">
                                        -- Select Taluka--
                                      </option>
                                      {talukas.map((item, i) => {
                                        return (
                                          <option value={item}>{item}</option>
                                        );
                                      })}
                                    </select>
                                    <input
                                      type="hidden"
                                      name="ctl00$CPH$CascadingDropDown11_ClientState"
                                      id="CPH_CascadingDropDown11_ClientState"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="fs-6">
                                    <span id="CPH_Label47">Village</span>
                                    <span class="red">*</span>
                                    {/* <select
                                        name="Migrant_village"
                                        id="CPH_ddlmigvillage"
                                        //tabindex="65"
                                        class="form-control"
                                        value={Data.Migrant_village}
                                        onChange={handleChange}
                                        onkeypress="return IsAlphabet(event);"
                                      >
                                        <option value=""></option>
                                      </select> */}
                                    <input
                                      name="Migrant_village"
                                      type="text"
                                      maxlength="6"
                                      id="CPH_Pincode"
                                      //tabindex="25"
                                      class="form-control"
                                      value={Data.Migrant_village}
                                      onChange={handleChange}
                                      onkeypress="return IsAlphabet(event);"
                                    />
                                    <input
                                      type="hidden"
                                      name="ctl00$CPH$CascadingDropDown12_ClientState"
                                      id="CPH_CascadingDropDown12_ClientState"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Have you applied before for Caste Certificate (including other District) (Yes/No) */}
                      <div id="CPH_divIsFirstGiven" class="form-bg" role="form">
                        <div
                          className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                          style={{ backgroundColor: "#f9f9f9" }}
                        >
                          <div class="box-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    className="col-lg-12 text-center border rounded mb-3"
                                    style={{ backgroundColor: "#e0e0e0" }}
                                  >
                                    <h3 class="box-title">
                                      <span id="CPH_lbl_AppDtl">
                                        Have you applied before for Caste
                                        Certificate (including other District)
                                        (Yes/No)
                                      </span>
                                    </h3>
                                  </div>{" "}
                                  <div class="col-md-12">
                                    <div class="fs-6">
                                      <span id="CPH_Label92">
                                        Have you applied before for Caste
                                        Certificate (including other District)
                                        (Yes/No)
                                      </span>
                                      <span class="red">*</span>
                                      <Form.Item name="isAppliedForCastCertificate">
                                        <Radio.Group
                                          onChange={(e) => {
                                            SetData({
                                              ...Data,
                                              isAppliedForCastCertificate:
                                                e.target.value,
                                            });
                                            if (e.target.value === "yes") {
                                              SetCastcertificate(true);
                                            } else {
                                              SetCastcertificate(false);
                                              SetData({
                                                ...Data,
                                                FirtGiven: "",
                                              });
                                            }
                                          }}
                                        >
                                          <Radio value="yes">Yes</Radio>
                                          <Radio value="No">No</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Is Certificate Given? (Yes/No) */}
                  {Caste_Certificate1 && (
                    <div id="CPH_WasCertiGiven" class="form-bg" role="form">
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div class="box-body">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div
                                  className="col-lg-12 text-center border rounded mb-3"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      Is Certificate Given? (Yes/No)
                                    </span>
                                  </h3>
                                </div>{" "}
                                <div class="col-md-12">
                                  <div class="fs-6">
                                    <span id="CPH_Label93">
                                      Is Certificate Given? (Yes/No)
                                    </span>
                                    <span class="red">*</span>

                                    <Form.Item name="is_Certificate_Given">
                                      <Radio.Group
                                        onChange={(e) => {
                                          SetData({
                                            ...Data,
                                            is_Certificate_Given:
                                              e.target.value,
                                          });
                                        }}
                                      >
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="No">No</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Previous Caste certificate’s details (if applicable) */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="box-body">
                      <div class="row">
                        <div
                          className="col-lg-12 text-center border rounded mb-3"
                          style={{ backgroundColor: "#e0e0e0" }}
                        >
                          <h3 class="box-title">
                            <span id="CPH_lbl_AppDtl">
                              Previous Caste certificate’s details (if
                              applicable)
                            </span>
                          </h3>
                        </div>{" "}
                        <div class="col-md-4">
                          <div class="fs-6">
                            Certificate Number
                            <input
                              name="previos_CastCerNum"
                              type="text"
                              id="CPH_txtcertino"
                              //tabindex="53"
                              class="form-control"
                              value={Data.previos_CastCerNum}
                              onChange={handleChange}
                              onkeypress="return IsAlphabet(event);"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="fs-6">
                            Place of Issue
                            <input
                              name="previos_CastCerPlaceIssue"
                              type="text"
                              id="CPH_txtissueplace"
                              //tabindex="54"
                              class="form-control"
                              value={Data.previos_CastCerPlaceIssue}
                              onChange={handleChange}
                              onkeypress="return IsAlphabet(event);"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="fs-6">
                            Issued date
                            <br />
                            <DatePicker
                              name="previos_CastCerIssuedDate"
                              type="text"
                              maxlength="10"
                              id="CPH_caste03apptxtDOB"
                              //tabindex="12"
                              class="form-control"
                              onChange={handalStartDate}
                              onkeypress="return IsAlphabet(event);"
                              format="DD-MM-YYYY"
                            />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Income Details */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div class="box-body">
                            <div class="row">
                              <div
                                className="col-lg-12 text-center border rounded mb-3"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Income Details
                                  </span>
                                </h3>
                              </div>{" "}
                              <div class="col-md-12 table-responsive">
                                <table
                                  id="CPH_Table1"
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="table-striped form-table"
                                >
                                  <tr>
                                    <th>
                                      <span id="CPH_lblseno">Sr.No </span>
                                    </th>
                                    <th>
                                      <span id="CPH_lblincmesources">
                                        Income Source :
                                      </span>
                                    </th>
                                    <th id="CPH_td1">
                                      <span id="CPH_lblyr1">2020 - 2021</span>
                                    </th>
                                    <th id="CPH_td2">
                                      <span id="CPH_lblyr2">2021 - 2022</span>
                                    </th>
                                    <th id="CPH_td2">
                                      <span id="CPH_lblyr3">2022 - 2023</span>
                                    </th>
                                  </tr>

                                  {NonCreamyLayer_income_input.map(
                                    (item, i) => (
                                      <tr key={i}>
                                        <td>
                                          <span id="CPH_lbl1">{i + 1}</span>
                                        </td>
                                        <td>
                                          <span id="CPH_lblfarming">
                                            {item.source}
                                          </span>
                                        </td>
                                        <td id="CPH_td11">
                                          <div class="input-group mb-3">
                                            <div className="mx-3 input-group">
                                              <span class="input-group-text">
                                                <i class="fa fa-inr"></i>
                                              </span>
                                              <input
                                                class="form-control mr-2"
                                                aria-label="Amount (to the nearest dollar)"
                                                name={item.source}
                                                type="text"
                                                maxlength="9"
                                                id="txtfrstscale"
                                                //tabindex={item.t1}
                                                onChange={(e) =>
                                                  handleIncomeChange(e)
                                                }
                                                onkeypress="return IsAlphabet(event);"
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td id="CPH_td21">
                                          <div class="input-group mb-3">
                                            <div className="mx-3 input-group">
                                              <span class="input-group-text">
                                                <i class="fa fa-inr"></i>
                                              </span>
                                              <input
                                                class="form-control mr-2"
                                                aria-label="Amount (to the nearest dollar)"
                                                name={item.source}
                                                type="text"
                                                maxlength="9"
                                                id="txtfrstscale"
                                                //tabindex={item.t2}
                                                onChange={(e) =>
                                                  handleIncomeChange(e)
                                                }
                                                onkeypress="return IsAlphabet(event);"
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className=" input-group mb-3">
                                            <span class="input-group-text">
                                              <i class="fa fa-inr"></i>
                                            </span>
                                            <input
                                              class="form-control mr-2"
                                              aria-label="Amount (to the nearest dollar)"
                                              name={item.source}
                                              type="text"
                                              maxlength="9"
                                              id="txtfrstscale"
                                              //tabindex={item.t3}
                                              onChange={(e) =>
                                                handleIncomeChange(e)
                                              }
                                              onkeypress="return IsAlphabet(event);"
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                  <tr>
                                    <td></td>
                                    <td>
                                      <span id="CPH_Label86">*</span>
                                      <span id="CPH_Total">Total</span>
                                    </td>
                                    <td id="CPH_td111">
                                      <div class="input-group mb-3">
                                        <div className="mx-3 input-group">
                                          <span class="input-group-text">
                                            <i class="fa fa-inr"></i>
                                          </span>
                                          <input
                                            disabled="disabled"
                                            class="form-control mr-2"
                                            aria-label="Amount (to the nearest dollar)"
                                            type="text"
                                            maxlength="9"
                                            id="txtfrstscale"
                                            onChange={handleChange}
                                            onkeypress="return IsAlphabet(event);"
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td id="CPH_td211">
                                      {/* <div class="icon-addon">
                                        <span>
                                          <i class="fa fa-inr"></i>
                                        </span>
                                        <input
                                          name="txtTotal2"
                                          type="text"
                                          maxlength="9"
                                          id="CPH_txtTotal2"
                                          disabled="disabled"
                                          //tabindex="78"
                                          class="aspNetDisabled lbl_value rupees form-control form-control"
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div> */}
                                      <div class="input-group mb-3">
                                        <div className="mx-3 input-group">
                                          <span class="input-group-text">
                                            <i class="fa fa-inr"></i>
                                          </span>
                                          <input
                                            disabled="disabled"
                                            class="form-control mr-2"
                                            aria-label="Amount (to the nearest dollar)"
                                            onChange={handleChange}
                                            onkeypress="return IsAlphabet(event);"
                                            type="text"
                                            maxlength="9"
                                            id="txtfrstscale"
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="input-group mb-3">
                                        <div className="mx-3 input-group">
                                          <span class="input-group-text">
                                            <i class="fa fa-inr"></i>
                                          </span>
                                          <input
                                            disabled="disabled"
                                            class="form-control "
                                            aria-label="Amount (to the nearest dollar)"
                                            onChange={handleChange}
                                            onkeypress="return IsAlphabet(event);"
                                            type="text"
                                            maxlength="9"
                                            id="txtfrstscale"
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Occupation and Income Details of Applicant’s Family */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Occupation and Income Details of Applicant’s
                                Family
                              </span>
                            </h3>
                          </div>{" "}
                          <div class="box-body">
                            <div class="row">
                              <div class="col-md-12 table-responsive">
                                <table
                                  id="CPH_Table2"
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="table-striped form-table"
                                >
                                  <tr>
                                    <th></th>
                                    <th id="CPH_Th1">
                                      <span id="CPH_Label24">Father</span>
                                    </th>
                                    <th id="CPH_Th2">
                                      <span id="CPH_Label29">Mother</span>
                                    </th>
                                    <th>
                                      <span id="CPH_Label33">Spouse</span>
                                    </th>
                                  </tr>
                                  {occupation_income_input.map(
                                    (item, index) => (
                                    // {
                                    //   item.source_Name1=="Type of Business"?(
                                    //     <>
                                        
                                    //     </>
                                    //   ):()
                                    // }
                                      <tr key={index}>
                                        
                                        <td>
                                          <span id="CPH_lbl1">
                                            {index + 1} &nbsp;&nbsp;&nbsp;&nbsp;
                                          </span>
                                          <span id="CPH_lblfarming">
                                            {item.source_Name1}{console.log(6650,item.source_Name1)}
                                          </span>
                                        </td>

                                        <td id="CPH_td11">
                                          <div class="input-group mb-3">
                                            <div className="mx-3 input-group">
                                              {item.inputType == "date"|| item.source_Name1=="Type of Business" ? (
                                                ""
                                              ) : (
                                                <>
                                                  <span class="input-group-text">
                                                    <i class="fa fa-inr"></i>
                                                  </span>
                                                </>
                                              )}
                                              <input
                                                name={"Father"}
                                                class="form-control mr-2"
                                                aria-label="Amount (to the nearest dollar)"
                                                type={item.inputType}
                                                //tabindex={item.tabIndex}
                                                onChange={(e) => {
                                                  temp(e);
                                                }}
                                                onkeypress="return IsAlphabet(event);"
                                                maxlength="9"
                                                id="txtfrstscale"
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td id="CPH_td21">
                                          <div class="input-group mb-3">
                                            <div className="mx-3 input-group">
                                              {item.inputType == "date"|| item.source_Name1=="Type of Business" ? (
                                                ""
                                              ) : (
                                                <>
                                                  <span class="input-group-text">
                                                    <i class="fa fa-inr"></i>
                                                  </span>
                                                </>
                                              )}
                                              <input
                                                name={"Mother"}
                                                class="form-control mr-2"
                                                aria-label="Amount (to the nearest dollar)"
                                                type={item.inputType}
                                                //tabindex={item.tabIndex}
                                                onChange={(e) => {
                                                  temp(e);
                                                }}
                                                onkeypress="return IsAlphabet(event);"
                                                maxlength="9"
                                                id="txtfrstscale"
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="input-group mb-3">
                                            <div className="mx-3 input-group">
                                              {item.inputType == "date"|| item.source_Name1=="Type of Business" ? (
                                                ""
                                              ) : (
                                                <>
                                                  <span class="input-group-text">
                                                    <i class="fa fa-inr"></i>
                                                  </span>
                                                </>
                                              )}
                                              <input
                                                name={"Spouse"}
                                                class="form-control mr-2"
                                                aria-label="Amount (to the nearest dollar)"
                                                type={item.inputType}
                                                //tabindex={item.tabIndex}
                                                onChange={(e) => {
                                                  temp(e);
                                                }}
                                                onkeypress="return IsAlphabet(event);"
                                                maxlength="9"
                                                id="txtfrstscale"
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}

                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label44">
                                        Owner of property
                                      </span>
                                    </td>
                                    <td id="CPH_td14">
                                      <div class="icon-addon m-2">
                                        <select
                                          name="propowner"
                                          id="CPH_ddlpropowner"
                                          //tabindex="110"
                                          class="form-control"
                                          value={Data.propowner}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        >
                                          <option value="---Select---">
                                            ---Select---
                                          </option>
                                          <option value="Father">Father</option>
                                          <option value="Mother">Mother</option>
                                          <option value="Spouse">Spouse</option>
                                        </select>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label48">Location</span>
                                    </td>
                                    <td id="CPH_td20">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtLocation"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtLocation"
                                          //tabindex="111"
                                          class="form-control"
                                          value={Data.txtLocation}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label51">Area</span>
                                    </td>
                                    <td id="CPH_td31">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtarea"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtarea"
                                          //tabindex="112"
                                          class="form-control"
                                          value={Data.txtarea}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                    <td id="CPH_td32">
                                      <div class="icon-addon m-2">
                                        <select
                                          name="ddlareaunits"
                                          id="CPH_ddlareaunits"
                                          //tabindex="113"
                                          class=" form-control"
                                          value={Data.ddlareaunits}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        >
                                          <option value="---Select---">
                                            ---Select---
                                          </option>
                                          <option value="Acres">Acres</option>
                                          <option value="Square Mtrs">
                                            Square Mtrs
                                          </option>
                                          <option value="Yards">Yards</option>
                                        </select>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label53">
                                        Type of agricultural land
                                      </span>
                                    </td>
                                    <td id="CPH_td15" colspan="3" align="left">
                                      <div class="icon-addon m-2">
                                        <span
                                          id="CPH_chckagriland"
                                          class="non-cre"
                                        >
                                          <input
                                            id="CPH_chckagriland_0"
                                            type="checkbox"
                                            name="ctl00$CPH$chckagriland$0"
                                            //tabindex="114"
                                            value="Irrigated"
                                          />
                                          <label for="CPH_chckagriland_0">
                                            Irrigated
                                          </label>
                                          <input
                                            id="CPH_chckagriland_1"
                                            type="checkbox"
                                            name="ctl00$CPH$chckagriland$1"
                                            //tabindex="114"
                                            value="Dry crop"
                                          />
                                          <label for="CPH_chckagriland_1">
                                            Dry crop
                                          </label>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label61">
                                        Area of irrigated land
                                      </span>
                                    </td>
                                    <td id="CPH_td33">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtirriarea"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtirriarea"
                                          //tabindex="115"
                                          class=" form-control"
                                          value={Data.txtirriarea}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                    <td id="CPH_td35">
                                      <div class="icon-addon m-2">
                                        <select
                                          name="ddlunits"
                                          id="CPH_ddlunits"
                                          //tabindex="116"
                                          class=" form-control"
                                          value={Data.ddlunits}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        >
                                          <option value="---Select---">
                                            ---Select---
                                          </option>
                                          <option value="Acres">Acres</option>
                                          <option value="Square Mtrs">
                                            Square Mtrs
                                          </option>
                                          <option value="Yards">Yards</option>
                                        </select>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label62">
                                        a) Percentage of irrigated land as per
                                        constitutional ceiling under the state
                                        land ceiling act formula
                                      </span>
                                    </td>
                                    <td id="CPH_td30">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtpercentage"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtpercentage"
                                          //tabindex="117"
                                          class=" form-control"
                                          value={Data.txtpercentage}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label66">
                                        b) If you own both irrigated and dry
                                        land, percentage of irrigated land as
                                        per constitutional ceiling under the
                                        state land ceiling act formula
                                      </span>
                                    </td>
                                    <td id="CPH_td36">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtformula"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtformula"
                                          //tabindex="118"
                                          class="form-control"
                                          value={Data.txtformula}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label69">
                                        As per a) total irrigated land as a
                                        percentage as calculated by the land
                                        ceiling act formula
                                      </span>
                                    </td>
                                    <td id="CPH_td37">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtceilingactform"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtceilingactform"
                                          //tabindex="119"
                                          class="form-control"
                                          value={Data.txtceilingactform}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label70">
                                        Cultivation details
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label71">
                                        Type of cultivation
                                      </span>
                                    </td>
                                    <td id="CPH_td38">
                                      <div class="icon-addon m-2">
                                        <select
                                          name="ddlcultitype"
                                          id="CPH_ddlcultitype"
                                          //tabindex="120"
                                          class="form-control"
                                          value={Data.ddlcultitype}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        >
                                          <option value="---Select---">
                                            ---Select---
                                          </option>
                                          <option value="Crop">Crop</option>
                                          <option value="Fruits">Fruits</option>
                                        </select>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label72">Location</span>
                                    </td>
                                    <td id="CPH_td39">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtcultilocation"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtcultilocation"
                                          //tabindex="121"
                                          class=" form-control"
                                          value={Data.txtcultilocation}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label73">
                                        Area of irrigated land
                                      </span>
                                    </td>
                                    <td id="CPH_td40">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtcultiarea"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtcultiarea"
                                          //tabindex="122"
                                          class="form-control"
                                          value={Data.txtcultiarea}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                    <td id="CPH_td41">
                                      <div class="icon-addon m-2">
                                        <select
                                          name="ddlcultiunit"
                                          id="CPH_ddlcultiunit"
                                          //tabindex="123"
                                          class="form-control"
                                          value={Data.ddlcultiunit}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        >
                                          <option value="---Select---">
                                            ---Select---
                                          </option>
                                          <option value="Acres">Acres</option>
                                          <option value="Square Mtrs">
                                            Square Mtrs
                                          </option>
                                          <option value="Yards">Yards</option>
                                        </select>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label74">
                                        Location of property
                                      </span>
                                    </td>
                                    <td id="CPH_td42">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtproploc"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtproploc"
                                          //tabindex="124"
                                          class="form-control"
                                          value={Data.txtproploc}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label77">
                                        Details of property
                                      </span>
                                    </td>
                                    <td id="CPH_td43">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtpropdetails"
                                          type="text"
                                          maxlength="60"
                                          id="CPH_txtpropdetails"
                                          //tabindex="125"
                                          class="form-control"
                                          value={Data.txtpropdetails}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label80">
                                        Current use of property
                                      </span>
                                    </td>
                                    <td id="CPH_td44">
                                      <div class="icon-addon m-2">
                                        <input
                                          name="txtpropuse"
                                          type="text"
                                          id="CPH_txtpropuse"
                                          class="form-control"
                                          value={Data.txtpropuse}
                                          onChange={handleChange}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label81">
                                        Are you an income tax payee (Attach
                                        assessment if yes)
                                      </span>
                                    </td>
                                    <td id="CPH_td45">
                                      <div class="icon-addon m-2">
                                        <span
                                          id="CPH_radiobtnpayee"
                                          class="non-cre"
                                          onChange={(e) => handleChange(e)}
                                          onkeypress="return IsAlphabet(event);"
                                        >
                                          <input
                                            id="CPH_radiobtnpayee_0"
                                            type="radio"
                                            name="radiobtnpayee"
                                            value="Yes"
                                            //tabindex="127"
                                          />
                                          <label for="CPH_radiobtnpayee_0">
                                            Yes
                                          </label>
                                          <input
                                            id="CPH_radiobtnpayee_1"
                                            type="radio"
                                            name="radiobtnpayee"
                                            value="No"
                                            //tabindex="127"
                                          />
                                          <label for="CPH_radiobtnpayee_1">
                                            No
                                          </label>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      class="style5"
                                    >
                                      <span id="CPH_Label82">
                                        Have you paid wealth tax(Attach details)
                                      </span>
                                    </td>
                                    <td id="CPH_td46">
                                      <div class="icon-addon m-2">
                                        <span
                                          id="CPH_radiobtnwealthtax"
                                          class="non-cre"
                                        >
                                          <input
                                            id="CPH_radiobtnwealthtax_0"
                                            type="radio"
                                            name="radiobtnwealthtax"
                                            value="Yes"
                                            //tabindex="128"
                                            onChange={handleChange}
                                            onkeypress="return IsAlphabet(event);"
                                          />
                                          <label for="CPH_radiobtnwealthtax_0">
                                            Yes
                                          </label>
                                          <input
                                            id="CPH_radiobtnwealthtax_1"
                                            type="radio"
                                            name="radiobtnwealthtax"
                                            value="No"
                                            //tabindex="128"
                                          />
                                          <label for="CPH_radiobtnwealthtax_1">
                                            No
                                          </label>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Other Family Details */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Other Family Details
                              </span>
                            </h3>
                          </div>{" "}
                          <div class="box-body">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label100">Salutation</span>
                                  <select
                                    name="ddlmothersal"
                                    id="CPH_ddlmothersal"
                                    //tabindex="129"
                                    class="form-control"
                                    value={Data.ddlmothersal}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="---Select---">
                                      ---Select---
                                    </option>
                                    <option value="Advocate">Advocate</option>
                                    <option value="Kumar">Kumar</option>
                                    <option value="Kumari">Kumari</option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Shri">Shri</option>
                                    <option value="Shrimati">Shrimati</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label101">
                                    Mother Name (English)
                                  </span>
                                  <input
                                    name="txtmothername"
                                    type="text"
                                    maxlength="90"
                                    id="CPH_txtmothername"
                                    //tabindex="130"
                                    class="form-control"
                                    value={Data.txtmothername}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        txtmothername: e.target.value,
                                      });
                                    }}
                                    onBlur={clickHandler2}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label102">
                                    Mother Name (Marathi)
                                  </span>
                                  <input
                                    name="txtmothername_LL"
                                    type="text"
                                    maxlength="90"
                                    id="CPH_txtmothername_LL"
                                    //tabindex="131"
                                    class="form-control"
                                    value={mnameMarathi}
                                    // onChange={(e) => {
                                    //   setmnameMarathi(e.target.value);
                                    // }}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label103">Salutation</span>
                                  <select
                                    name="ddlspousesal"
                                    id="CPH_ddlspousesal"
                                    //tabindex="132"
                                    class="form-control"
                                    value={Data.ddlspousesal}
                                    onChange={(e) => handleChange(e)}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="---Select---">
                                      ---Select---
                                    </option>
                                    <option value="Advocate">Advocate</option>
                                    <option value="Kumar">Kumar</option>
                                    <option value="Kumari">Kumari</option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Shri">Shri</option>
                                    <option value="Shrimati">Shrimati</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label104">
                                    Spouse Name (English)
                                  </span>
                                  <input
                                    name="]txtSpouseName"
                                    type="text"
                                    maxlength="90"
                                    id="CPH_txtSpouseName"
                                    //tabindex="133"
                                    class="form-control"
                                    value={Data.txtSpouseName}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        txtSpouseName: e.target.value,
                                      });
                                    }}
                                    onBlur={clickHandler3}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label105">
                                    Spouse Name (Marathi)
                                  </span>
                                  <input
                                    name="txtspousename_LL"
                                    type="text"
                                    maxlength="90"
                                    id="CPH_txtspousename_LL"
                                    //tabindex="134"
                                    class="form-control"
                                    value={snameMarathi}
                                    // onChange={(e) => {
                                    //   setsnameMarathi(e.target.value);
                                    // }}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label106">District</span>
                                  <select
                                    name="ddlfam_dist"
                                    id="CPH_ddlfam_dist"
                                    //tabindex="135"
                                    class="form-control"
                                    value={Data.ddlfam_dist}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        ddlfam_dist: e.target.value,
                                      });
                                      setTalukasData(e.target.value);
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="">
                                      -- Select Distict--
                                    </option>
                                    {districtData.map((item, i) => {
                                      return (
                                        <option value={item.name}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <input
                                    type="hidden"
                                    name="ctl00$CPH$CascadingDropDown4_ClientState"
                                    id="CPH_CascadingDropDown4_ClientState"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label109">Taluka</span>
                                  <select
                                    name="ddlfam_taluka"
                                    id="CPH_ddlfam_taluka"
                                    //tabindex="136"
                                    class=" form-control"
                                    value={Data.ddlfam_taluka}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        ddlfam_taluka: e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  >
                                    <option value="">-- Select Taluka--</option>
                                    {talukas.map((item, i) => {
                                      return (
                                        <>
                                          <option value={item}>{item}</option>
                                        </>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label107">Village</span>

                                  <input
                                    name="ddlfam_Vill"
                                    type="text"
                                    maxlength="90"
                                    id="CPH_txtSpouseName"
                                    //tabindex="133"
                                    class="form-control"
                                    value={Data.ddlfam_Vill}
                                    onChange={(e) => {
                                      SetData({
                                        ...Data,
                                        ddlfam_Vill: e.target.value,
                                      });
                                    }}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label110">Pincode</span>
                                  <input
                                    name="txtfam_pincode"
                                    type="Number"
                                    maxlength="6"
                                    id="CPH_txtfam_pincode"
                                    //tabindex="138"
                                    class="form-control"
                                    value={Data.txtfam_pincode}
                                    onChange={(e) => handleChange(e)}
                                    onkeypress="return IsAlphabet(event);"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Other Details */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">Other Details</span>
                            </h3>
                          </div>{" "}
                          <div class="box-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="fs-6">
                                  <span id="CPH_lblbenefreason">Reason</span>
                                  <span class="red">*</span>
                                  <textarea
                                    name="txtbenefreason"
                                    rows="2"
                                    cols="20"
                                    id="CPH_txtbenefreason"
                                    //tabindex="139"
                                    class="form-control"
                                    value={Data.txtbenefreason}
                                    onChange={handleChange}
                                    onkeypress="return IsAlphabet(event);"
                                  ></textarea>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  <span id="CPH_Label11">
                                    Do you need the Affidavit?
                                  </span>
                                  <span class="red">*</span>
                                  <table id="CPH_Affd" class="CheckBox">
                                    <tr>
                                      <td>
                                        <input
                                          id="CPH_Affd_0"
                                          type="radio"
                                          name="ctl00$CPH$Affd"
                                          value="Yes"
                                          // checked="checked"
                                          //tabindex="140"
                                          onChange={(e) => handleChange(e)}
                                          onkeypress="return IsAlphabet(event);"
                                        />
                                        <label for="CPH_Affd_0">Yes</label>
                                      </td>
                                      <td>
                                        <input
                                          id="CPH_Affd_1"
                                          type="radio"
                                          name="ctl00$CPH$Affd"
                                          value="No"
                                          onChange={(e) => handleChange(e)}
                                          //tabindex="140"
                                        />
                                        <label for="CPH_Affd_1">No</label>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-lg-12">
                  <a href="https://mhae-seva.s3.amazonaws.com/PanAccept/NON+CREYLAR+SELF+DEC.pdf" target="_blank" className="fs-5"><b>स्वघोषणापत्र</b></a>
                  </div>
                </div>
                  {/* Upload required documents */}
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div
                      className="col-lg-12 text-center border rounded mb-3"
                      style={{ backgroundColor: "#e0e0e0" }}
                    >
                      <h3 class="box-title">
                        <span id="CPH_lbl_AppDtl">
                          Upload required documents
                        </span>
                      </h3>
                    </div>{" "}
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="red">
                          अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व आवश्यक
                          कागदपत्रे तपासून व स्कॅन करून अपलोड करावे. जर आवश्यक
                          कागदपत्रे चुकीची किंवा अस्पष्ट आढळल्यास सदर चा अर्ज
                          फेटाळला जाऊ शकतो.{" "}
                        </h6>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          <span className="red">*</span>Address proof (Size -
                          Maximum 1 MB) [Only (jpg,jpeg,pdf)]
                        </label>

                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              adharCard: e.target.files[0],
                            });
                            console.log(992, e.target.files[0]);
                          }}
                        />
                        <p className="red m-2">{FormFileerror.adharCard}</p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          <span className="red">*</span>Electrical bill (Size -
                          Maximum 1 MB) [Only (jpg,jpeg,pdf)]{" "}
                        </label>

                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              lightBill: e.target.files[0],
                            });
                            // console.log(992,e);
                          }}
                        />
                        <p className="red m-2">{FormFileerror.lightBill}</p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          Ration card Size Photo (Size - Maximum 500 Kb) [Only
                          (jpg,jpeg)]
                        </label>

                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              reshanCard: e.target.files[0],
                            });
                            console.log(992, e.target.files[0]);
                          }}
                        />
                        <p className="red m-2">{FormFileerror.reshanCard}</p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          School Leaving certificate (Size - Maximum 500 Kb)
                          [Only (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              SchoolLeaveCertificate: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red m-2">
                          {FormFileerror.SchoolLeaveCertificate}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          Income certificate (Size - Maximum 500 Kb) [Only
                          (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              incomeCertificate3year: e.target.files[0],
                            });
                            console.log(992, e.target.files[0]);
                          }}
                        />
                        <p className="red m-2">
                          {FormFileerror.incomeCertificate3year}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          Cast Certificate (Size - Maximum 500 Kb) [Only
                          (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              castCertificate: e.target.files[0],
                            });
                            console.log(992, e.target.files[0]);
                          }}
                        />
                        <p className="red m-2">
                          {FormFileerror.castCertificate}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          Photo (Size - Maximum 500 Kb) [Only (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              photo: e.target.files[0],
                            });
                            // console.log(992,e);
                          }}
                        />
                        <p className="red m-2">{FormFileerror.photo}</p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          Tax Recipt (Size - Maximum 500 Kb) [Only (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              taxRecipt: e.target.files[0],
                            });
                            // console.log(992,e);
                          }}
                        />
                        <p className="red m-2">{FormFileerror.taxRecipt}</p>
                      </div>

                      <div className="col-md-6 mb-3 p-2">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          <span className="red">*</span>Father Adhar Card (Size
                          - Maximum 500 Kb) [Only (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              FatherAdhar: e.target.files[0],
                            });
                            // console.log(992,e);
                          }}
                        />
                        <p className="red">{FormFileerror.FatherAdhar}</p>
                      </div>
                      <div className="col-md-6 mb-3 p-2">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          <span className="red">*</span> Benifiary Adhar (Size -
                          Maximum 500 Kb) [Only (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              childrenAdhar: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.childrenAdhar}</p>
                      </div>
                      <div className="col-md-6 mb-3 p-2">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          <span className="red">*</span>Applicant Photo(Size -
                          Maximum 500 Kb) [Only (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              applicantPhoto: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.applicantPhoto}</p>
                      </div>
                      <div className="col-md-6 mb-3 p-2">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          <span className="red">*</span> Benificiary Photo(Size
                          - Maximum 500 Kb) [Only (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              benificiarryPhoto: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">{FormFileerror.benificiarryPhoto}</p>
                      </div>
                      <div className="col-md-6 mb-3 p-2">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          <span className="red">*</span>Father School
                          leaveCertificate (Size - Maximum 500 Kb) [Only
                          (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              fatherSchoolLeaveCer: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">
                          {FormFileerror.fatherSchoolLeaveCer}
                        </p>
                      </div>
                      <div className="col-md-6 mb-3 p-2">
                        <label htmlFor="" className="mb-3">
                          {" "}
                          <span className="red">*</span> Benifiary School
                          leaveCertificate (Size - Maximum 500 Kb) [Only
                          (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              childrenSchoolLeaveCer: e.target.files[0],
                            });
                          }}
                        />
                        <p className="red">
                          {FormFileerror.childrenSchoolLeaveCer}
                        </p>
                      </div>
                      <div className="col-md-6 mb-3 p-2">
                        <label htmlFor="" className="mb-3">
                          {" "}
                         Other (Size - Maximum
                          500 Kb) [Only (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              otherFirst: e.target.files[0],
                            });
                          }}
                        />
                        {/* <p className="red">{FormFileerror.otherFirst}</p> */}
                      </div>
                      <div className="col-md-6 mb-3 p-2">
                        <label htmlFor="" className="mb-3">
                          {" "}
                         Other|| (Size - Maximum
                          500 Kb) [Only (jpg,jpeg)]
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name=""
                          id=""
                          onChange={(e) => {
                            setDocs({
                              ...docs,
                              otherSecond: e.target.files[0],
                            });
                          }}
                        />
                        {/* <p className="red">{FormFileerror.otherSecond}</p> */}
                      </div>
                    </div>
                  </div>

                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">Agreement Details</span>
                            </h3>
                          </div>{" "}
                          <div class="box-body">
                            <div class="row">
                              <div class="col-md-12">
                                <p
                                  id="CPH_lblbenefagreement"
                                  class="agreementArea"
                                >
                                  I solemly affirm that the above mentioned
                                  information submitted by me is true and
                                  correct to my knowledge and belief.I hereby
                                  agree to be liable for legal consequences for
                                  any information found incorrect or untrue at a
                                  later date.
                                </p>
                              </div>
                              <div class="col-md-4">
                                <div class="fs-6">
                                  {/* Agreement Accepted<span class="red">*</span> */}
                                  <span class="CheckBox lbl_value">
                                    <input
                                      id="CPH_chkaccept"
                                      type="checkbox"
                                      name="checkbox"
                                      //tabindex="141"
                                      onChange={() => setAgree(true)}
                                      onkeypress="return IsAlphabet(event);"
                                    />
                                    <label for="CPH_chkaccept"> I accept</label>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 text-center">
                    {showloading ? (
                      <>
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {id == undefined ? (
                          <input
                            type="submit"
                            name="ctl00$CPH$btnsubmit"
                            value="Proceed"
                            onClick={(e) => postLDJData(e)}
                            id="CPH_btnsubmit"
                            //tabindex="141"
                            class="btn btn-success m-2"
                          />
                        ) : (
                          <input
                            type="submit"
                            name="ctl00$CPH$btnsubmit"
                            value="Update"
                            onClick={(e) => editHandler(e)}
                            id="CPH_btnsubmit"
                            //tabindex="141"
                            class="btn btn-success m-2"
                          />
                        )}

                        <input
                          type="submit"
                          name="ctl00$CPH$btnback"
                          value="Back"
                          onclick='return back();WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions("ctl00$CPH$btnback", "", true, "", "", false, false))'
                          id="CPH_btnback"
                          //tabindex="142"
                          class="btn btn-warning m-2"
                        />
                        <input
                          type="submit"
                          name="ctl00$CPH$btnreset"
                          value="Reset"
                          onclick='return disp_confirm();WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions("ctl00$CPH$btnreset", "", true, "", "", false, false))'
                          id="CPH_btnreset"
                          //tabindex="143"
                          class="btn btn-danger m-2"
                        />
                      </>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default NonCreamyLayer;
