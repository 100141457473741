import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import TabsetUser from "./tabset-user";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import axios from "axios";
import {districtData}  from "../../constants/data";
// import{districtData  from "../../constants/data";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
// import Select from "react-select";

const Create_user = () => {
  let { id } = useParams();
  // console.log(15, id);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [mobileNumber, setmobile_No] = useState();
  const [district, setDistrict] = useState("");
  const [tehsil, setTehsil] = useState("");
  const [village, setVillage] = useState("");
  const [role, setRole] = useState("");
  const [area, setarea] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [agent, setAgent] = useState("");
  const [showagentFormPrice, setshowagentFormPrice] = useState(false);
  const [istehsil, setapleSarkar] = useState();
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [showAgent, setshowAgent] = useState(false);
  const [singleFormDetail, setsingleFormDetail] = useState();
  const [showservices, setshowservices] = useState(false);
  const [AdminPerEWS, setAdminPerEWS] = useState();
  const [AdminPer_NonCriminal, setAdminPer_NonCriminal] = useState();
  const [AdminPer_Income, setAdminPer_Income] = useState();
  const [AdminPer_AgeNashnality, setAdminPer_AgeNashnality] = useState();
  const [allData, setAllData] = useState([]);
  const [profilePicture, setprofilePicture] = useState([]);

  // console.log(41, istehsil);

  const [retailerEWS, setretailer_EWS] = useState();
  const [retailer_NonCriminal, setretailer_NonCriminal] = useState();
  const [retailer_Income, setretailer_Income] = useState();
  const [retailer_AgeNashnality, setretailer_AgeNashnality] = useState();

  const [talukas, setTalukas] = useState([]);
  let AgenashnalityFormPrice = 0;
  let IncomeFormPrice = 0;
  let NomCrimonalFormPrice = 0;
  let ewsFormPrice = 0;
  const name = firstName + " " + lastName;
  const [agent_formPrice, setagent_formPrice] = useState({
    agent_nonCriminal: 0,
    agent_EWS: 0,
    agent_income: 0,
    agent_ageNasnality: 0,
  });
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/user/getoneuser/${id}`)
      .then((response) => {
        const data = response.data;
        setfirstName(data[0]?.name);
        setemail(data[0]?.email);
        setmobile_No(data[0]?.mobileNumber);
        setDistrict(data[0]?.district);
        setTehsil(data[0]?.tehsil);
        setVillage(data[0]?.village);
        setRole(data[0]?.role);
        setarea(data[0]?.area);
        setAddress1(data[0]?.address1);
        setAddress2(data[0]?.address2);
        setAgent(data[0]?.agent);
        setapleSarkar(data[0]?.istehsil);
        setagent_formPrice({
          agent_nonCriminal: data[0]?.agent_formPrice.agent_nonCriminal,
          agent_EWS: data[0]?.agent_formPrice.agent_EWS,
          agent_income: data[0]?.agent_formPrice.agent_income,
          agent_ageNasnality: data[0]?.agent_formPrice.agent_ageNasnality,
        });
        // setAllData(data);
        // const filter = data.filter(
        //   (item) => item.tehsil == tehsil && item.role == "agent"
        // );
        // setfilteredData(filter);
      });
  }, [id]);
  useEffect(() => {
    axios.get("https://api.maharashtraseva.com/api/user/getAll").then((response) => {
      const data = response.data;
      setAllData(data);
      const filter = data.filter(
        (item) => item.tehsil == tehsil && item.role == "agent"
      );
      setfilteredData(filter);
    });
  }, [tehsil]);

  useEffect(() => {
    districtData.filter((dist) => {
      if (dist.name?.toLowerCase() === district?.toLowerCase()) {
        setTalukas(dist.tahasil);
      }
    });
  }, [district]);

  useEffect(() => {
    if (role == "retailer") {
      setshowAgent(true);
    } else {
      setshowAgent(false);
    }
  }, [role]);

  useEffect(() => {
    if (role == "retailer") {
      setshowservices(true);
    } else {
      setshowservices(false);
    }
  }, [role]);

  const AgentFormDetail = (id) => {
    const AgentFormDetails = filteredData.filter((item) => item._id === id);
    setsingleFormDetail(AgentFormDetails);
  };

  const user = (e) => {
    e.preventDefault();

    const retPrevCount = allData.filter((item) => item.role == "retailer");
    const agePrevCount = allData.filter((item) => item.role == "agent");

    let fName = firstName.charAt(0).toUpperCase();
    let lName = lastName.charAt(0).toUpperCase();

    let userName;

    if (role == "retailer") {
      userName = `MESK${fName}${lName}${retPrevCount.length + 1}`;
    } else if (role == "agent") {
      userName = `MESKTC${fName}${lName}${agePrevCount.length + 1}`;
    }
    console.log("userName", userName);

    const obj = {
      name,
      email,
      mobileNumber,
      district,
      tehsil,
      village,
      role,
      agent_formPrice,
      retaile_formPrice,
      area,
      address1,
      address2,
      agent,
      istehsil: istehsil,
      password: userName,
      username: userName,
    };
    console.log(137, obj);

    axios
      .post("https://api.maharashtraseva.com/api/signup", obj)
      .then((res) => {
        if (role == "retailer") {
          swal(
            userName,
            ` Retailer Created successfully! ${userName} is your Password`,
            "success"
          );
        } else if (role == "agent") {
          swal(
            userName,
            ` Agent Created successfully! ${userName} is your Password`,
            "success"
          );
        } else {
          swal(" Admin Created successfully!", "success");
        }
        console.log(191, res.data.data._id);
        UploadProfilePicture(res.data.data._id);
        setfirstName("");
        setlastName("");
        setemail("");
        setmobile_No();
        setDistrict("");
        setTehsil("");
        setVillage("");
        setRole("");
        setarea("");
        setAddress1("");
        setAddress2("");
        setAgent("");
        setapleSarkar("");
        // UploadProfilePicture();
        // navigate("/users/list-user")
      })
      .catch((err) => console.log(err));
  };
  const UploadProfilePicture = (id) => {
    const formData = new FormData();
    formData.append("profilePicture", profilePicture);
    axios
      .put(`https://api.maharashtraseva.com/api/userprofilepic/${id}`, formData)
      .then((res) => {
        console.log("profilePicture", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const calculateTotalValue = (initialValue, per) => {
    const percentage = per ? per : 0;
    const total = initialValue + (initialValue * percentage) / 100;
    return total;
  };

  if (showagentFormPrice) {
    ewsFormPrice = calculateTotalValue(
      singleFormDetail[0]?.agent_formPrice?.agent_EWS,
      AdminPerEWS
    );
    NomCrimonalFormPrice = calculateTotalValue(
      singleFormDetail[0]?.agent_formPrice?.agent_nonCriminal,
      AdminPer_NonCriminal
    );
    IncomeFormPrice = calculateTotalValue(
      singleFormDetail[0]?.agent_formPrice?.agent_income,
      AdminPer_Income
    );
    AgenashnalityFormPrice = calculateTotalValue(
      singleFormDetail[0]?.agent_formPrice?.agent_ageNasnality,
      AdminPer_AgeNashnality
    );
  }
  const retaile_formPrice = {
    retailer_nonCriminal: NomCrimonalFormPrice,
    retailer_EWS: ewsFormPrice,
    retailer_income: IncomeFormPrice,
    retailer_ageNashnality: AgenashnalityFormPrice,
  };
  const Userupdate = () => {
    const obj = {
      name,
      email,
      mobileNumber,
      district,
      tehsil,
      village,
      role,
      agent_formPrice,
      retaile_formPrice,
      area,
      address1,
      address2,
      agent,
      istehsil: istehsil,
      // password: userName,
      // username: userName,
    };
    console.log(254, obj);
    axios
      .put(`https://api.maharashtraseva.com/api/updateuser/${id}`, obj)
      .then((response) => {
        const data = response.data;
        console.log(response.data._id)
        swal(" User Update successfully!", "success");
        UploadProfilePicture(response.data._id)
      });
  };

  return (
    <Fragment>
      <Container>
        <Breadcrumb title="Create User" parent="Users" />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        <span className="red">*</span>First Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          value={firstName}
                          onChange={(e) => setfirstName(e.target.value)}
                          placeholder="Enter First Name"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span>Last Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          value={lastName}
                          onChange={(e) => setlastName(e.target.value)}
                          placeholder="Enter Last Name"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span>Email
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          placeholder="Enter Email"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span>Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          type="Number"
                          value={mobileNumber}
                          onChange={(e) => {
                            setmobile_No(e.target.value);
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 10) {
                              setmobile_No(sanitizedValue);
                            } else {
                              alert("Please Enter Valid Phone Number");
                              setmobile_No(""); // Reset the adharNumber if it's too long
                            }
                          }}
                          placeholder="Enter Mobile Number"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span>District
                      </label>

                      <Form.Item>
                        <Select
                          onChange={(e) => setDistrict(e)}
                          placeholder="Select District"
                          value={district}
                          showSearch
                        >
                          <Select.Option value="">--Select--</Select.Option>
                          {districtData.map((item, i) => (
                            <Select.Option value={item.name} key={i}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span>Tehsil
                      </label>

                      <Form.Item>
                        <Select
                          placeholder=" Select Tehsil"
                          onChange={(e) => setTehsil(e)}
                          value={tehsil}
                          showSearch
                        >
                          <Select.Option value="">--Select--</Select.Option>
                          {talukas.map((item, i) => (
                            <Select.Option value={item} key={i}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span>Village
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          value={village}
                          onChange={(e) => setVillage(e.target.value)}
                          placeholder="Enter Village Name"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span> Role
                      </label>

                      <Form.Item>
                        <Select
                          value={role}
                          placeholder="Select Role"
                          onChange={(e) => setRole(e)}
                          showSearch
                        >
                          <Select.Option value="">--Select--</Select.Option>
                          <Select.Option value="admin">Admin</Select.Option>
                          <Select.Option value="retailer">
                            Retailer
                          </Select.Option>
                          <Select.Option value="agent">
                            Tehsil Co-Ordinator
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span>Location
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          value={area}
                          onChange={(e) => setarea(e.target.value)}
                          placeholder=" Enter Location"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span>Address
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          placeholder="Enter Address"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Passport Size Photo
                        (Profile Photo)
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setprofilePicture(e.target.files[0]);
                          // console.log(992,e);
                        }}
                      />
                    </div>
                  </div>
                  {showservices && (
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3">
                        {" "}
                        <span className="red">*</span>Use Tehsil Services
                      </label>

                      {/* <Radio.Group
                        // value={istehsil}
                        
                          onChange={(e) => {
                            if (e.target.value === "true") {
                              setapleSarkar(true);
                            } else {
                              setapleSarkar(false);
                            }
                          }}
                        >
                          <Radio value="true">YES</Radio>
                          <Radio value="false">NO</Radio>
                        </Radio.Group> */}
                      <Form.Item>
                        <Select
                          // value={istehsil}
                          placeholder="Use TehsilServicess"
                          onChange={(e) => {
                            console.log(566, e);
                            console.log(513, e);
                            if (e === "true") {
                              setapleSarkar(true);
                            } else {
                              setapleSarkar(false);
                            }
                          }}
                          showSearch
                        >
                          <Select.Option value="">--Select--</Select.Option>
                          <Select.Option value="true">True</Select.Option>
                          <Select.Option value="false">false</Select.Option>
                        </Select>
                      </Form.Item>
                      {/* <Form.Item>
                        <Select
                          value={role}
                          placeholder="Select Role"
                          onChange={(e) => {
                          if (e == "true") {
                            setapleSarkar(true);
                          } else {
                            setapleSarkar(false);
                          }
                        }}
                          showSearch
                        >
                          <Select.Option value="">--Select--</Select.Option>
                          <Select.Option value="true">yes</Select.Option>
                          <Select.Option value="false">
                            No
                          </Select.Option>
                        </Select>
                      </Form.Item> */}
                    </div>
                  )}
                  {role === "agent" ? (
                    <>
                      <div className="row text-left">
                        <div className="col-lg-4 text-center border  p-1 ">
                          <h4 className="fw-bolder">Form Name </h4>
                        </div>
                        <div className="col-lg-6 text-center border  p-1 ">
                          <h4 className="fw-bolder">Form Price</h4>
                        </div>
                        <div className="col-lg-4 border p-1">
                          <h4 className="fw-bolder">EWS Form Price:</h4>
                        </div>
                        <div className="col-lg-6 border p-1 ">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your Ews Form Price!",
                              },
                            ]}
                          >
                            <Input
                              value={agent_formPrice.agent_EWS}
                              placeholder="Enter Ews Form Price"
                              type="number"
                              onChange={(e) =>
                                setagent_formPrice({
                                  ...agent_formPrice,
                                  agent_EWS: parseInt(e.target.value),
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="col-lg-4 border p-1">
                          <h4 className="fw-bolder">
                            Non creamy layer Form Price :
                          </h4>
                        </div>
                        <div className="col-lg-6 border p-1">
                          {" "}
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your Ews Form Price!",
                              },
                            ]}
                          >
                            <Input
                              value={agent_formPrice.agent_nonCriminal}
                              type="number"
                              placeholder="Enter Non Criminal Form Price"
                              onChange={(e) =>
                                setagent_formPrice({
                                  ...agent_formPrice,
                                  agent_nonCriminal: parseInt(e.target.value),
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="col-lg-4 border p-1">
                          <h4 className="fw-bolder">
                            Age Nationality Form Price :
                          </h4>
                        </div>
                        <div className="col-lg-6 border p-1">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your Ews Form Price!",
                              },
                            ]}
                          >
                            <Input
                              value={agent_formPrice.agent_ageNasnality}
                              type="number"
                              placeholder=" Enter Age Nashnality Form Price "
                              onChange={(e) =>
                                // setPassword(e.target.value)
                                setagent_formPrice({
                                  ...agent_formPrice,
                                  agent_ageNasnality: parseInt(e.target.value),
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="col-lg-4 border p-1">
                          <h4 className="fw-bolder">
                            Income Certificate Form Price :
                          </h4>
                        </div>
                        <div className="col-lg-6 border p-1">
                          {" "}
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your Ews Form Price!",
                              },
                            ]}
                          >
                            <Input
                              value={agent_formPrice.agent_income}
                              type="number"
                              placeholder="Enter Income Certificate Form Price"
                              onChange={(e) =>
                                // setPassword(e.target.value)
                                setagent_formPrice({
                                  ...agent_formPrice,
                                  agent_income: parseInt(e.target.value),
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {istehsil && (
                    <>
                      {showAgent == true && (
                        <div className="col-md-4">
                          <label htmlFor="" className="mb-3">
                            {" "}
                            <span className="red">*</span>Tehsil Co-Ordinator
                          </label>

                          <Form.Item>
                            <Select
                              // value={agent}
                              placeholder="select Agent"
                              showSearch
                              onChange={(e) => {
                                setAgent(e);
                                AgentFormDetail(e);
                                setshowagentFormPrice(true);
                              }}
                            >
                              {filteredData.map((item) => (
                                <Select.Option value={item._id} key={item._id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      )}
                      {showagentFormPrice && (
                        <>
                          <div className="row">
                            <div className="col-lg-3 border text-center p-4 text-center ">
                              <h4 className="fw-bolder">form name</h4>
                            </div>
                            <div className="col-lg-3 border text-center p-4 text-center ">
                              <h4 className="fw-bolder">form Price</h4>
                            </div>
                            <div className="col-lg-3 border text-center p-4 text-center ">
                              <h4 className="fw-bolder">Admin persentage</h4>
                            </div>
                            <div className="col-lg-3 border text-center p-4 text-center ">
                              <h4 className="fw-bolder">Retailer Form Cost</h4>
                            </div>
                            <div className="col-lg-3 border  p-1">
                              <h4 className="fw-bolder">Ews Form Price :</h4>
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {" "}
                              {singleFormDetail[0]?.agent_formPrice?.agent_EWS}
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {" "}
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your Ews Form Price!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  onChange={(e) => {
                                    setAdminPerEWS(e.target.value);
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {" "}
                              {calculateTotalValue(
                                singleFormDetail[0]?.agent_formPrice?.agent_EWS,
                                AdminPerEWS
                              )}
                            </div>

                            <div className="col-lg-3 border  p-1">
                              {" "}
                              <h4 className="fw-bolder">
                                Age Nationality Form Price :
                              </h4>
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {
                                singleFormDetail[0]?.agent_formPrice
                                  ?.agent_ageNasnality
                              }
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {" "}
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your Ews Form Price!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  onChange={(e) =>
                                    setAdminPer_AgeNashnality(e.target.value)
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {" "}
                              {calculateTotalValue(
                                singleFormDetail[0]?.agent_formPrice
                                  ?.agent_ageNasnality,
                                AdminPer_AgeNashnality
                              )}
                            </div>
                            <div className="col-lg-3 border p-1">
                              <h4 className="fw-bolder">
                                Non creamy layer Certificate Form Price :
                              </h4>
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {" "}
                              {
                                singleFormDetail[0]?.agent_formPrice
                                  ?.agent_nonCriminal
                              }
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {" "}
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your Ews Form Price!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  onChange={(e) =>
                                    setAdminPer_NonCriminal(e.target.value)
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {" "}
                              {calculateTotalValue(
                                singleFormDetail[0]?.agent_formPrice
                                  ?.agent_nonCriminal,
                                AdminPer_NonCriminal
                              )}
                            </div>

                            <div className="col-lg-3 border  p-1">
                              <h4 className="fw-bolder">
                                Income Certificate Form Price :
                              </h4>
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {
                                singleFormDetail[0]?.agent_formPrice
                                  ?.agent_income
                              }
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your Ews Form Price!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  onChange={(e) =>
                                    setAdminPer_Income(e.target.value)
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div className="col-lg-3 border text-center p-1">
                              {" "}
                              {calculateTotalValue(
                                singleFormDetail[0]?.agent_formPrice
                                  ?.agent_income,
                                AdminPer_Income
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <div className="col-md-1 m-auto mt-5">
                    {id ? (
                      <>
                        <Form.Item>
                          <Button
                            type="primary"
                            // className="btn-primary"
                            onClick={(e) => {
                              Userupdate(e);
                              navigate("/users/list-user");
                            }}
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <Form.Item>
                          <Button
                            type="primary"
                            // className="primary"
                            onClick={(e) => {
                              user(e);
                            }}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Create_user;
