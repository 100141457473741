import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import axios from "axios";
import { incNumber } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

function AddMemberKit() {
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.changeNumber);

  const [banner, setbanner] = useState();
  const [memberKit, setMemberKit] = useState([]);
  console.log(8, memberKit);
  const AddBanner = (e) => {
    console.log(7, banner);
    const formData = new FormData();
    formData.append("banner", banner);
    axios
      .post(`https://api.maharashtraseva.com/api/memberkit_banner/create`, formData)
      .then((res) => {
        console.log("banner", res.data);
        dispatch(incNumber());
        swal("Upload", "Upload MemberKit successfully!", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/memberkit_banner`)
      .then((res) => {
        console.log("banner", res.data);
        const data = res.data.reverse();
        setMemberKit(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reducer]);
  const deleteUser = (id) => {
    console.log(56, id);

    axios
      .delete(`https://api.maharashtraseva.com/api/memberkit_banner/${id}`)
      .then((res) => {
        swal("Deleted", "MemberKit Deleted successfully!", "success");
        dispatch(incNumber());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Breadcrumb title="Member Kit" parent="Retailer" />

      <div className="row border border-3 py-3">
        <div className="col-lg-3">
          <label htmlFor="banner">
            <h3>
              <b>Upload Member Kit Image : </b>
            </h3>
          </label>
        </div>
        <div className="col-lg-5">
          <input
            className="form-control form-control-lg"
            type="file"
            name=""
            id=""
            onChange={(e) => {
              setbanner(e.target.files[0]);
            }}
          />
        </div>
        <div className="col-lg-4">
          <button
            className="btn btn-outline-dark"
            onClick={(e) => {
              AddBanner(e);
            }}
          >
            Upload
          </button>
        </div>
      </div>
      <div className="row border">
        <div className="col-lg-12 text-center my-3">
          <h3>
            <b>Results</b>
          </h3>
        </div>
        {memberKit.map((item) => {
          return (
            <>
              <div className="col-lg-4 mt-3">
                <div className="row border text-Center">
                  <div className="col-lg-12 mt-2">
                    <img
                      src={item.banner}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <div className="col-lg-12 text-Center pl-5">
                    <i
                      class="bi bi-trash3 fs-2 text-danger"
                      style={{ cursor: "pointer",marginLeft:"200px" }}
                      onClick={(e) => deleteUser(item._id)}
                    ></i>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default AddMemberKit;
