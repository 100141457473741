import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import {
  setAllForm,
  setEleForm,
  setFormPrice,
  setFourForm,
  setRecharge,
  setSingleAgentTehsilForms,
  setSingleUserForms,
  setSingleUserTehsilForms,
  setUserBalance,
  setUserExpences,
  setUsersData,
} from "./actions";
import { toast } from "react-toastify";
import { setVendorProduct } from "./actions";

export default function API() {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const reducer = useSelector((state) => state.changeNumber);
  const state = useSelector((state) => state);
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/recharge`)
      .then((res) => {
        const response = res.data.reverse();
        dispatch(setRecharge(response));
      })
      .catch((err) => console.log(32, err));
    axios
      .get(`https://api.maharashtraseva.com/api/user/getAll`)
      .then((res) => {
        const response = res.data;
        // console.log(37,response)
        dispatch(setUsersData(response));
      })
      .catch((err) => console.log(40, err));
    axios
      .get(`https://api.maharashtraseva.com/api/recharge`)
      .then((res) => {
        const response = res.data;
        console.log(39, response);
        dispatch(setUserBalance(response));
        dispatch(setUserExpences(response));
      })
      .catch((err) => console.log(40, err));
    axios
      .get(`https://api.maharashtraseva.com/api/formPrice`)
      .then((res) => {
        const response = res.data;
        // console.log(39,response)
        dispatch(setFormPrice(response));
      })
      .catch((err) => console.log(40, err));
    axios
      .get("https://api.maharashtraseva.com/api/getAll/Form11")
      .then(async (res) => {
        const data = await res.data;
        dispatch(setAllForm(data));
      })
      .catch((err) => console.log(err));

    axios
      .get("https://api.maharashtraseva.com/api/getAll")
      .then(async (res) => {
        const data = await res.data;
        dispatch(setFourForm(data));
        if (userData.user.role == "agent") {
          const AgentFormFilter = data.filter(
            (item) => item.agentId == userData.user._id
          );
          dispatch(setSingleAgentTehsilForms(AgentFormFilter));
        } else if (userData.user.role == "retailer") {
          const filter = data.filter(
            (item) => item.createdBy == userData.user._id
          );
          dispatch(setSingleUserTehsilForms(filter));
        }
      })
      .catch((err) => console.log(err));

    axios
      .get("https://api.maharashtraseva.com/api/getAll/Form11")
      .then(async (res) => {
        const data = await res.data;
        dispatch(setEleForm(data));
        if (userData.user.role == "retailer") {
          const filter = data.filter(
            (item) => item.createdByName == userData.user.name
          );
          console.log(95,filter)
          dispatch(setSingleUserForms(filter));
        }
      })
      .catch((err) => console.log(err));
  }, [reducer]);

  return <></>;
}
