export const ActionTypes = {
  // SET_PRODUCTS: "SET_PRODUCTS",
   SET_RECHARGE :"SET_RECHARGE",
  SET_USERS: "SET_USERS",
  SET_USERS_BALANCE: "SET_USERS_BALANCE",
  SET_USERS_EXPENCE: "SET_USERS_EXPENCE",
  FORM_AMOUNT: "FORM_AMOUNT",
  All_FORM:"All_FORM",
  FORM_ELEVEN:"FORM_ELEVEN",
  FORM_FOUR:"FORM_FOUR",
  SINGLE_USER_FORMDATA:"SINGLE_USER_FORMDATA",
  SINGLE_USER_TEHSIL_FORMS:"SINGLE_USER_TEHSIL_FORMS",
  SINGLE_AGENT_TEHSIL_FORMS:"SINGLE_AGENT_TEHSIL_FORMS"
};
