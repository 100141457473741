import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import swal from "sweetalert";
import { toast } from "react-toastify";
import axios from "axios";
import { incNumber } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";

function Addlink() {
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.changeNumber);
  
  const [Data, SetData] = useState({
    link: "",
    lable: "",
  });
  const [AllLinks, setLinks] = useState([]);
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/Links`)
      .then((res) => {
        const response = res.data;
        setLinks(response);
      })
      .catch((err) => console.log(40, err));
  }, [reducer]);
  const SubmitData = (e) => {
    e.preventDefault()
    console.log(32,Data);
 
      axios
        .post("https://api.maharashtraseva.com/api/Links/create", Data)
        .then((res) => {
          const response = res.data;
          dispatch(incNumber());
          toast.success("Link Upload  Successfully..");
          SetData({
            link: "",
            lable: "",
          })
        })
        .catch((err) => console.log(34, err));
    
  };
  const rechargeColumn = [
    {
      name: "Sr No",
      width:"80px",
      style: {
        textAlign: "center",
        fontWaight: "700",
      },
      selector: (row, index) => index + 1,
    },
    {
      name: "Title ",
      width:"200px",
      style: {
        textAlign: "center",
        fontWaight: "700",
      },
      selector: (row) => row?.lable,
    },
    {
      name: "Link",
      width:"830px",
      style: {
        textAlign: "center",
      },
      selector: (row) => row?.link,
    },
    {
      name: "Date",
      width:"200px",
      style: {
        textAlign: "center",
      },
      selector: (row) => GetDate(row.createdAt),
    },
    {
      name: "Action",
      width:"90px",
      style: {
        textAlign: "center",
      },
      selector: (row) =>  <i
      class="bi bi-trash3 fs-2 text-danger"
      style={{ cursor: "pointer" }}
      onClick={(e) => deleteUser(row._id)}
    ></i>,
    },
  ];
  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWaight: "900",
        // Add any other CSS properties you want to apply to the rows
        fontSize: "22px",
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        fontWaight: "900",
        fontSize: "20px",
        border: "1px solid black",
        background: "#0094ef",
        color: "white",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        fontSize: "12px",
        border: "1px solid black",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
        color: "black",
        // Add any styles for the regular cells
        border: "1px solid black",
      },
    },
    pagination: {
      style: {
        // Add any styles for the pagination section
        border: "1px solid black",
      },
    },
  };
  const deleteUser = (id) => {

    axios
      .delete(`https://api.maharashtraseva.com/api/ourteam/${id}`)
      .then((res) => {
        swal("Deleted", " Link Deleted successfully!", "success");
        dispatch(incNumber());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Breadcrumb title="Add Links" parent="Users" />
      <div class="row g-3">
        <div class="col-md-6">
          <label></label>
          <input
            type="text"
            name="manuallink"
            id="manuallink"
            value={Data.lable}
            class="form-control"
            placeholder="Enter Title"
            required
            onChange={(e) => {
              SetData({ ...Data, lable: e.target.value });
            }}
          />
        </div>
        <div class="col-md-6">
          <label></label>
          <input
            type="text"
            name="lbl"
            id="lbl"
            value={Data.link}
            class="form-control"
            placeholder="Enter link"
            onChange={(e) => {
              SetData({ ...Data, link: e.target.value });
            }}
            required
          />
        </div>
        <br />
        <div class="col-md-12">
          <input
            type="submit"
            name="submit"
            class="btn btn-primary"
            value="Submit"
            onClick={(e) => {
              SubmitData(e);
            }}
          />
        </div>
      </div>
      <div className="row mt-3">
        <DataTable
          columns={rechargeColumn}
          data={AllLinks}
          pagination={AllLinks.length > 10 ? true : false}
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight="300px"
          customStyles={customStyles}
        />
      </div>
    </div>
  );
}

export default Addlink;
