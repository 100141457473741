import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import './styles.css';
// import './Application.css';
import swal from "sweetalert";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";

import { incNumber } from "../../Redux/actions";
import Breadcrumb from "../common/breadcrumb";

function Retailer_ageNationality() {
  const navigate =useNavigate();

  const [status, setStatus] = useState("");
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));
  let { id } = useParams();
  const [subNote, setSubNote] = useState("");
  const [compNote, setCompNote] = useState("");
  const [rejNote, setRejNote] = useState("");
  const [selectedItem, setselectedItem] = useState(null);
  const dispatch = useDispatch();
  const form4 = useSelector((state) => state.formFour.form4);
  const [AllFOrm, setAllForm] = useState(form4);
  const getData = () => {
    if (AllFOrm.length > 0) {
      const selectedData = AllFOrm.find((item) => item._id === id);
      setselectedItem(selectedData);
    } else {
      axios
        .get("https://api.maharashtraseva.com/api/getAll")
        .then((res) => {
          const data = res.data;
          const selectedData = data.find((item) => item._id === id);
          setselectedItem(selectedData);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getData();
    // if (
    //   userRespons.user.role === "retailer" &&
    //   selectedItem?.status === "IN-PROGRESS"
    // ) {
    // } else if (
    //   userRespons.user.role === "admin" &&
    //   selectedItem?.status != "IN-PROGRESS"
    // ) {
    // } else {
    //   axios
    //     .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, { isNew: false })
    //     .then((res) => {
    //       dispatch(incNumber());
    //     })
    //     .catch((err) => console.log(err));
    // }
    if(
      userRespons.user.role === "agent" &&
      selectedItem?.status != "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ){
      axios
      .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, { isNew: true })
      .then((res) => {
        dispatch(incNumber());
      })
      .catch((err) => console.log(err));
    }
   else if (
      userRespons.user.role === "agent" &&
      selectedItem?.status == "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status == "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, { isNew: true })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status != "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      console.log(78, "Ok");
      axios
        .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    }
  }, [id, form4]);
  const [Acknowledgment, setAcknowledgment] = useState({
    Acknowledgment: "",
  });
  const [finalDocs, setfinalDocs] = useState({
    finalDocs: "",
  });

  const obj = {
    ...selectedItem,
    status: status,
  };

  const FormSubmit = (e) => {
    e.preventDefault();

    if (
      selectedItem.status === "IN-PROGRESS" ||
      selectedItem.status === "IN-Progress"
    ) {
      const formData = new FormData();

      formData.append("acknowledgmentDocument", Acknowledgment.Acknowledgment);
      axios
        .put(
          `https://api.maharashtraseva.com/api/Age_Nashnality_acknowledgmentDocument/${id}`,
          formData
        )
        .then((res) => {console.log("acknowledgmentDocument", res.data)
        dispatch(incNumber());
      })
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        subNote: subNote,
        submitAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, obj)
        .then((res) => {
          console.log("acknowledgmentDocument", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedItem.status === "SUBMITTED") {
      const formData = new FormData();

      formData.append("finalDocument", finalDocs.finalDocs);
      axios
        .put(
          `https://api.maharashtraseva.com/api/Age_Nashnality_finalDocument/${id}`,
          formData
        )
        .then((res) => {console.log("finalDocument", res.data)
        dispatch(incNumber());  
      })
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        compNote: compNote,
        CompleteAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, obj)
        .then((res) => {
          console.log("finalDocument", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    } else if (status === "REJECTED") {
      const obj = {
        ...selectedItem,
        status: status,
        rejNote: rejNote,
        rejectAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/Age_Nashnality/${id}`, obj)
        .then((res) => {
          console.log("finalDocument", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  return (
    <>
      <Breadcrumb title={"Age Nationality and Domicile"} />
      {selectedItem != null ? (
        <Container fluid={true}>
          <Row>
            <Col sm="">
              <Card>
                <CardBody>
                  <form class="inline-block form-inline ms-auto ">
                    <div
                      className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                      style={{ backgroundColor: "#f9f9f9" }}
                    >
                      <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                         <div className="row">
                        <div className="col-lg-8">
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Details </span>
                        </h3>
                          
                        </div>
                        <div className="col-lg-4 mt-1">
                        <button
                            class="btn btn-outline-dark"
                            type="button"
                            onClick={() =>{
                              if (userRespons.user.role=="retailer") {
                                navigate(
                                  `${process.env.PUBLIC_URL}/RetailerForm4`
                                )
                              }else if(userRespons.user.role=="admin"){
                              navigate(
                                `${process.env.PUBLIC_URL}/ts`
                              )
                              }else{
                                navigate(
                                  `${process.env.PUBLIC_URL}/agent/application_Console`
                                )
                              }
                            }
                            }
                          >
                            Back
                          </button>
                        </div>
                      </div>
                      </div>
                      <div id="layoutSidenav_content">
                        <main>
                          <div class="container-fluid px-4 mt-4">
                            <div class="formlayout">
                              <div class="row ">
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b>Certificate Name :</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["docName"] +
                                    ".  " +
                                    selectedItem.Data["fullName_English"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> Full Name (English) :</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["statusfname"] +
                                    ".  " +
                                    selectedItem.Data["fullName_English"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Father's Name(English) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["fatherName_english"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Full Name (Marathi) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["fullName_Marathi"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Father's Name (Marathi) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["fatherName_marathi"]}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>Date Of Birth :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["BirthDate"]}
                                </div>
                                {/* <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> Date Of Birth :</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["BirthDate"]}
                                </div>
                              </div> */}

                                <div class="row g-3">
                                  <div class="col-md-6 mt-4 border-bottom border-3">
                                    <label>
                                      {" "}
                                      <b> Age :</b>
                                    </label>
                                    <br />
                                    {selectedItem.Data["age"]}{" "}
                                  </div>
                                  <div class="col-md-6 mt-4 border-bottom border-3">
                                    <label>
                                      {" "}
                                      <b> Gender :</b>
                                    </label>
                                    <br />
                                    {selectedItem.Data["Gender"]}{" "}
                                  </div>

                                  <div class="row g-3" id="dvtext">
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Occupation</b>
                                      </label>
                                      <br />
                                      {selectedItem.Data["work_type"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Mobile NMunber: </b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["phoneNUm"]}{" "}
                                    </div>
                                  </div>
                                  <div class="row g-3" id="dvtext">
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Email</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["email"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Adhar UID No.</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["AdharNo"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>address</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["address"]}{" "}
                                    </div>
                                    {/* <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b> Applicant_locality</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["Applicant_locality"]}{" "}
                                </div> */}
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Building</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["Building"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>street</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["street"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>landmark</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["landmark"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> District</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["district"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Taluka</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["taluka"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Village</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["village"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Pincode</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data["pincode"]}{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          Residing at present address since *{" "}
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["onAddressLiveing"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          Applicant residing in Maharashtra mini
                                          15y
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "Applicunt_Live_In_MH_Inyear"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          Relation of Applicant with Beneficiary
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "relationApplicant_beneficiary"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Beneficiary Full Name</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "benificiary_NameStatus"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Beneficiary Full Name</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["benificiary_Name"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Date Of Birth</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["benificiary_DOB"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Mobile</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Benificiary_Phoneno"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>BIrth Details IS Above</b>{" "}
                                      </label>
                                      <br />
                                      {selectedItem.Data.BirthDetailsAsAbove}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Birth Detail Address</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["BirthDetails_address"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Birth Detail Street</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["BirthDetails_strateName"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Birth Detail Building</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["BirthDetails_Building"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Birth Detail Locality</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["BirthDetails_depart"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Birth Detail Landmark</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "BirthDetails_Country"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Birth Detail District</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["BirthDetails_district"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Birth Detail Taluka</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["BirthDetails_tehsil"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Birth Detail Village</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data.BirthDetails_village
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> Birth Detail Pincode</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["BirthDetails_pincode"]
                                      }{" "}
                                    </div>
                                    <div
                                      className="col-lg-12 text-center border rounded"
                                      style={{ backgroundColor: "#e0e0e0" }}
                                    >
                                      <h3 class="box-title">
                                        <span id="CPH_lbl_AppDtl">
                                          Proparty Details
                                        </span>
                                      </h3>
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property Address</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Proparty_address"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property Street</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Proparty_street"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property Building</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Proparty_Building"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property locality</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Proparty_locality"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property Country</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Proparty_Country"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property district</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Proparty_district"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property Tehsil</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Proparty_Tehsil"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property village</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Proparty_village"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property pincode</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["Proparty_pincode"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property Holder Relation</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "Benificiary_propartyHolder_Relation"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property Details</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "Benificiary_propartyHolder_Relation"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b> property Details</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "Benificiary_propartyDetails"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          Beneficiary's father's / husband's
                                          place of residence
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "Benificiary_Father_HusRecidance"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          At the time of birth of the
                                          beneficiary, place of residence of
                                          father
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "Benificiary_FatherRecidaceOfBirth"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          If Beneficiary's father / husband is
                                          staying outside Maharashtra, then
                                          place of residence
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "isBenificiary_FatherRecidaceOfBirth_outOf_MH"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          Place of employment / enrolment for
                                          scheme if any
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["placeOfEmploment"]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          Whether applicant is beneficiary of
                                          government scheme in other district?
                                          If yes, provide the details of the
                                          place
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "isApllicantBenificiaryOtherState"
                                        ]
                                      }{" "}
                                    </div>

                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          Whether applicant is beneficiary of
                                          government scheme in other district?
                                          If yes, provide the details of the
                                          place.
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "ApllicantBenificiaryOtherState"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          Apllicant Benificiary Other State
                                          district
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "ApllicantBenificiaryOtherState_district"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          Apllicant Benificiary Other State
                                          district
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "ApllicantBenificiaryOtherState_district"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          Apllicant Benificiary Other State
                                          Taluka
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "ApllicantBenificiaryOtherState_Taluka"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          Apllicant Benificiary Other State
                                          Village
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "ApllicantBenificiaryOtherState_Village"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>
                                          {" "}
                                          Apllicant Benificiary Other State
                                          Pincode
                                        </b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data[
                                          "ApllicantBenificiaryOtherState_pincode"
                                        ]
                                      }{" "}
                                    </div>
                                    <div class="col-md-6 mt-4 border-bottom border-3">
                                      <label>
                                        <b>Reson Of Certificate</b>{" "}
                                      </label>
                                      <br />
                                      {
                                        selectedItem.Data["CertificateReason"]
                                      }{" "}
                                    </div>

                                    <div
                                      className="col-lg-12 text-center border rounded"
                                      style={{ backgroundColor: "#e0e0e0" }}
                                    >
                                      <h3 class="box-title">
                                        <span id="CPH_lbl_AppDtl">
                                          Education Details
                                        </span>
                                      </h3>
                                    </div>
                                    {selectedItem.EducationDetailsArray.map(
                                      (item) => {
                                        return (
                                          <>
                                            <div className="row mt-4 border-bottom border-3">
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>
                                                    {" "}
                                                    Education Details of
                                                    Beneficiary
                                                  </b>{" "}
                                                </label>
                                                <br />
                                                {item.begree}{" "}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>University / Board</b>{" "}
                                                </label>
                                                <br />
                                                {item.organizationName}{" "}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>Admission Year</b>{" "}
                                                </label>
                                                <br />
                                                {item.AddmisionYear}{" "}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>Completion Year</b>{" "}
                                                </label>
                                                <br />
                                                {item.leaveYear}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>Institute Details</b>{" "}
                                                </label>
                                                <br />
                                                {item.EducationPlace}{" "}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}

                                    <div
                                      className="col-lg-12 text-center border rounded"
                                      style={{ backgroundColor: "#e0e0e0" }}
                                    >
                                      <h3 class="box-title">
                                        <span id="CPH_lbl_AppDtl">
                                          Family Member Details of Beneficiary
                                          (If he / she holds a valid Age,
                                          Domicile and Nationality Certificate)
                                        </span>
                                      </h3>
                                    </div>
                                    {selectedItem.FMemberBeneficiaryArray.map(
                                      (item) => {
                                        return (
                                          <>
                                            <div className="row">
                                              <div class="col-md-6 mt-4 border-bottom border-3">
                                                <label>
                                                  <b>
                                                    {" "}
                                                    Relation with Beneficiary
                                                  </b>{" "}
                                                </label>
                                                <br />
                                                {item.relation_beneficiary}{" "}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-3">
                                                <label>
                                                  <b>Family member name</b>{" "}
                                                </label>
                                                <br />
                                                {item.familyMember_name}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}

                                    <div
                                      className="col-lg-12 text-center border rounded"
                                      style={{ backgroundColor: "#e0e0e0" }}
                                    >
                                      <h3 class="box-title">
                                        <span id="CPH_lbl_AppDtl">
                                          Migration Address Details
                                        </span>
                                      </h3>
                                    </div>
                                    {selectedItem.MigrationBeneficiaryArray.map(
                                      (item) => {
                                        return (
                                          <>
                                            <div className="row mt-5 border-bottom border-3">
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>
                                                    {" "}
                                                    Family's native place before
                                                    migration to Maharashtra
                                                  </b>{" "}
                                                </label>
                                                <br />
                                                {
                                                  item.BeforeMigrationLocation
                                                }{" "}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>From Date</b>{" "}
                                                </label>
                                                <br />
                                                {item.MigrationStartYear}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>To Date</b>{" "}
                                                </label>
                                                <br />
                                                {item.MigrationEndYear}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>
                                                    Years of residence in the
                                                    state of Maharashtra
                                                  </b>{" "}
                                                </label>
                                                <br />
                                                {item.migrationToMHYear}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>Migration place</b>{" "}
                                                </label>
                                                <br />
                                                {item.placeOfMigration}
                                              </div>
                                              <div class="col-md-6 mt-4 border-bottom border-1">
                                                <label>
                                                  <b>Reason for migration</b>{" "}
                                                </label>
                                                <br />
                                                {item.reasonOfMigration}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}

                                    <div class="col-md-12">
                                      <label>
                                        <b>Upload required documents: :</b>{" "}
                                      </label>
                                      <br />
                                      <b style={{ color: "red" }}>
                                        अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर
                                        सर्व आवश्यक कागदपत्रे तपासून व स्कॅन
                                        करून अपलोड करावे. जर आवश्यक कागदपत्रे
                                        चुकीची किंवा अस्पष्ट आढळल्यास सदर चा
                                        अर्ज फेटाळला जाऊ शकतो.
                                      </b>{" "}
                                    </div>
                                    <p className="red mt-2 fs-6 bolder" style={{color:"red"}}>
                          <b style={{color:"red"}}>
                          IMP NOTE :- १) अर्जदार व लाभार्थी महाराष्ट्रातील जन्म असावा ,२) दोघांच्या शाळा सोडल्याच्या दाखल्यावर जन्म स्थळ  (BIRTH PLACE) नमूद असावे, ३)१५ वर्षा पूवीचे लाईट बिल अनिवार्य. ४)वडील हयात नसल्यास मृत्यू दाखला अनिवार्य.
                          </b>
                        </p>
                                    <div class="col-md-6 ">
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.FatherAdhar}>
                                          FatherAdhar
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.childrenAdhar}>
                                          childrenAdhar
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a
                                          href={
                                            selectedItem.taxBillOr15yerOldLightbill
                                          }
                                        >
                                          taxBillOr15yerOldLightbill
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.applicantPhoto}>
                                          applicantPhoto
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a
                                          href={selectedItem.benificiarryPhoto}
                                        >
                                          benificiarry Photo
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.lightBill}>
                                          light Bill
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.selfDeclaration}>
                                          Self Declaration
                                        </a>
                                      </button>
                                      <br />
                                      {/* <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.photo}>photo</a>
                                      </button> */}
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.reshanCard}>
                                          Ration Card
                                        </a>
                                      </button>
                                      <br />
                                      {/* <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a
                                          href={
                                            selectedItem.schoolLeaveCertificate
                                          }
                                        >
                                          school Leave Certificate
                                        </a>
                                      </button> */}
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.otherFirst}>
                                          Other First
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.otherSecond}>
                                          Other Second
                                        </a>
                                      </button>
                                      <br />
                                      {selectedItem.acknowledgmentDocument !=
                                        null && (
                                        <>
                                          <button
                                            style={{
                                              border: "none",
                                              color: "blue",
                                              backgroundColor: "transparent",
                                              marginTop:"20px"
                                            }}
                                          >
                                            <a
                                              href={
                                                selectedItem.acknowledgmentDocument
                                              }
                                            >
                                              acknowledgment Document
                                            </a>
                                          </button>
                                          <br />
                                        </>
                                      )}

                                      {selectedItem.finalDocument != null && (
                                        <>
                                          <button
                                            style={{
                                              border: "none",
                                              color: "blue",
                                              backgroundColor: "transparent",
                                            }}
                                          >
                                            <a
                                              href={selectedItem.finalDocument}
                                            >
                                              final Document
                                            </a>
                                          </button>
                                          <br />
                                        </>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="row">
                                        <div className="col-lg-12 "><h3 className="fs-4 fw-3"><b>HISTORY</b></h3></div>
                                        <div className="col-lg-12 mt-2"><b>Current Status : </b>{selectedItem.status}</div>
                                        <div className="col-lg-12 "><b>Created On : </b>{GetDate(selectedItem.createdAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Submit Note : </b>{selectedItem.subNote}</div>
                                        <div className="col-lg-12 "><b>submit On : </b>{GetDate(selectedItem.submitAt)}</div>
                                        <div className="col-lg-12 mt-2"><b>Reject Note : </b>{selectedItem.rejNote}</div>
                                        <div className="col-lg-12 "><b>Reject On : </b>{GetDate(selectedItem.rejectAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Complete Note : </b>{selectedItem.compNote}</div>
                                        <div className="col-lg-12 "><b>Complete On : </b>{GetDate(selectedItem.CompleteAt)}</div>
                                        
                                      </div>
                                    </div>

                                    {userData.user.role == "agent" ? (
                                      <>
                                        {selectedItem.status =="IN-PROGRESS" ? (
                                          <div class="row g-3" id="dvtext">
                                            <div class="col-md-6">
                                              <label>
                                                {" "}
                                                <b>
                                                  {" "}
                                                  Status:
                                                  <span class="text-danger">
                                                    *
                                                  </span>
                                                </b>{" "}
                                              </label>
                                              <br />
                                              <select
                                                name="state"
                                                class="form-select"
                                                required
                                                id="state"
                                                onChange={(e) =>
                                                  setStatus(e.target.value)
                                                }
                                              >
                                                <option value="" selected>
                                                  Select State
                                                </option>
                                                <option Value="SUBMITTED">
                                                  SUBMITTED
                                                </option>
                                                <option Value="REJECTED">
                                                  REJECTED
                                                </option>
                                              </select>
                                            </div>

                                            <div class="col-md-6">
                                              <label>
                                                <b>
                                                  Note:
                                                  <span class="text-danger">
                                                    *
                                                  </span>
                                                </b>{" "}
                                              </label>{" "}
                                              <br />
                                              <textarea
                                                name="note"
                                                class="form-control"
                                                onChange={(e)=>{
                                                  if (status == "SUBMITTED") {
                                                    setSubNote(e.target.value)
                                                  }else{
                                                    setRejNote(e.target.value)
                                                  }
                                                }}
                                              >
                                                {" "}
                                              </textarea>{" "}
                                            </div>
                                            {status === "SUBMITTED" && (
                                              <div class="col-md-6">
                                                <label>
                                                  <b>
                                                    Acknowledgment Document{" "}
                                                  </b>{" "}
                                                </label>
                                                <input
                                                  type="file"
                                                  onChange={(e) => {
                                                    setAcknowledgment({
                                                      ...Acknowledgment,
                                                      Acknowledgment:
                                                        e.target.files[0],
                                                    });
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        ) : selectedItem.status == "SUBMITTED" ? (
                                          <div class="row g-3" id="dvtext">
                                            <div class="col-md-6">
                                              <label>
                                                {" "}
                                                <b>
                                                  {" "}
                                                  Status:
                                                  <span class="text-danger">
                                                    *
                                                  </span>
                                                </b>{" "}
                                              </label>
                                              <br />
                                              <select
                                                name="state"
                                                class="form-select"
                                                required
                                                id="state"
                                                onChange={(e) =>
                                                  setStatus(e.target.value)
                                                }
                                              >
                                                <option value="" selected>
                                                  Select State
                                                </option>

                                                <option Value="COMPLETE">
                                                  COMPLETE
                                                </option>
                                              </select>
                                            </div>

                                            <div class="col-md-6">
                                              <label>
                                                <b>
                                                  Note:
                                                  <span class="text-danger">
                                                    *
                                                  </span>
                                                </b>{" "}
                                              </label>{" "}
                                              <br />
                                              <textarea
                                                name="note"
                                                class="form-control"
                                                onChange={(e)=>{
                                                  setCompNote(e.target.value)
                                                }}
                                              >
                                                {" "}
                                              </textarea>{" "}
                                            </div>
                                            {status === "COMPLETE" && (
                                              <div class="col-md-6">
                                                <label>
                                                  <b>Final Document </b>{" "}
                                                </label>
                                                <input
                                                  type="file"
                                                  onChange={(e) => {
                                                    setfinalDocs({
                                                      ...finalDocs,
                                                      finalDocs:
                                                        e.target.files[0],
                                                    });
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        <div class="row g-3 " id="dvtext">
                                          <div class="col-md-12">
                                            <button
                                              type="submit"
                                              name="submit"
                                              id="btnsubmit"
                                              class="btn btn-primary"
                                              value="Submit"
                                              onClick={(e) => {
                                                FormSubmit(e);
                                              }}
                                            >
                                              Submit
                                            </button>

                                            <button
                                              type="submit"
                                              name="submit"
                                              id="btnsubmit"
                                              class="btn btn-primary "
                                              value="Submit"
                                              style={{ marginLeft: "10px" }}
                                            >
                                              Reset
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </main>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Card
            style={{
              height: "80vh",
              width: "80vw",
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <h3>Loading...</h3>
          </Card>
        </>
      )}
    </>
  );
}

export default Retailer_ageNationality;
