export const occupationData = [
  "---निवडा---",
  "१० वी खालील",
  "गृहिणी",
  "१२ वी",
  "अभियंता",
  "इतर",
  "उद्योग",
  "कामगार",
  "खाजगी सेवक",
  "प्राध्यापक",
  "परिचारिका",
  "मोलमजूरी",
  "लेखक",
  "वकील",
  "वैद्य",
  "विद्यार्थी",
  "शेतकरी",
  "शेतमजुरी",
  "शासकीय कर्मचारी",
  "शिक्षक",
  "सॉफ्टवेयर व्यावसायिक",
  "हार्डवेयर व्यावसायिक",
];
export const vehical_type = [
  "Motorcycles",
  "Scooters",
  "Mopeds",
  "Auto-rickshaws (Passenger-carrying)",
  "Goods Carriers (Pick-up vans)",
  "Cars",
  "Hatchbacks",
  "Sedans",
  "SUVs (Sports Utility Vehicles)",
  "MUVs (Multi Utility Vehicles)",
  "Vans",
  "Coupes",
  "Buses (Passenger Carrying)",
  "Trucks (Goods Carriers)",
  "Tempos",
  "Trailers",
  "Tankers",
  "Construction vehicles (Cranes, Excavators, Bulldozers, etc.)",
  "Electric Cars",
  "Electric Scooters",
  "Electric Bicycles",
  "Government vehicles:",
  "Fire Trucks",
  "Police Vehicles",
  "School Buses",
  "Tourist Vehicles",
  "Goods Carriers (Tempo, Pick-up trucks)",
];
export const rto = [
  "Mumbai (Central) - MH-01",
  "Mumbai (Western Suburbs) - MH-02",
  "Mumbai (Eastern Suburbs) - MH-03",
  "Mumbai (Southern Suburbs) - MH-04",
  "Mumbai (Northern Suburbs) - MH-47",
  "Thane - MH-05",
  "Kalyan - MH-06",
  "Vasai-Virar - MH-48",
  "Palghar - MH-49",
  "Alibag (Raigad) - MH-46",
  "Navi Mumbai - MH-43",
  "Pune - MH-12",
  "Pimpri-Chinchwad - MH-14",
  "Solapur - MH-13",
  "Kolhapur - MH-09",
  "Sangli - MH-10",
  "Satara - MH-11",
  "Ratnagiri - MH-08",
  "Sindhudurg - MH-07",
  "Nashik - MH-15",
  "Ahmednagar - MH-16",
  "Shrirampur - MH-40",
  "Dhule - MH-18",
  "Jalgaon - MH-19",
  "Nandurbar - MH-20",
  "Aurangabad - MH-20",
  "Jalna - MH-21",
  "Beed - MH-22",
  "Osmanabad - MH-23",
  "Latur - MH-24",
  "Parbhani - MH-25",
  "Hingoli - MH-26",
  "Nanded - MH-27",
  "Amravati - MH-29",
  "Akola - MH-30",
  "Buldhana - MH-28",
  "Yavatmal - MH-31",
  "Washim - MH-32",
  "Nagpur (City) - MH-31",
  "Nagpur (Rural) - MH-40",
  "Wardha - MH-34",
  "Chandrapur - MH-32",
  "Gadchiroli - MH-36",
  "Gondia - MH-35",
  "Bhandara - MH-37",
];
                                   
                                    
                                
                                
export const qualification = [
  "8th Passed", 							
  "10+2 or Equivalent" ,							
  "10th Standard or Equivalent",
  "Below 8th",					
  "Diploma in any Discipline",
  "Doctrate in any Discipline", 							
  "Graduate in Non Medical Sciences",
  "Graduate in any Medical Sciences", 							
  "ITI/Certificate Course",
  "Illiterate",						
  "M.Phil. in any Discipline",
  "Not Specified / NA",						
  "Post Graduate Diploma in any Discipline",
  "Post Graduate in Non Medical Sciences",							
  "Post Graduate in any Medical Science",
];
export const relation = [
  "    ---निवडा---",
  "स्वतः",
  "आजोबा",
  "सासू",
  "पालक",
  "नात",
  "वहिणी",
  "पती",
  "दिर",
  "काका",
  "नातू",
  "पत्नी",
  "भाऊ",
  "सून",
  "पुतण्या",
  "सासरे",
  "काकी",
  "मुलगी",
  "वडील",
  "पुतणी",
  "आई",
  "आजी",
  "मुलगा",
  "बहिण",
];

export const relationOfBanificiary_Applicant = [
  "Daughter",
  "Father",
  "Grand Daughter",
  "Grand Son",
  "Husband",
  "Nephew",
  "Niece",
  "Self",
  "Sister",
  "Son",
  "Wife",
];

export const relationApplicant_beneficiary = [
  "Daughter",
  "Father",
  "Grand Daughter",
  "Grand Son",
  "Husband",
  "Nephew",
  "Niece",
  "Self",
  "Sister",
  "Son",
  "Wife",
];

export const statusfname = [
  "Kumar",
  "Advocate",
  "Shri",
  "Mr.",
  "Kumari",
  "Ms",
  "Shrimati",
  "Mrs",
];

export const fatherOccupation = [
  " Goverment Employee",
  "Professor",
  "Engineer",
  " Software Professonal",
  "House Wife",
  " Private Service",
  "Bussiness",
  " Farm Wages",
  "Hardware Professional",
];

export const income_input = [
  {
    source_name: "शेती",
    source: "farm",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "शेती पुरक व्यवसाय",
    source: "farmRelated",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "दुग्ध व्यवसाय",
    source: "frstscaledairy",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "शेत मजुरी",
    source: "txtfrstscaleagri",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "इतर मजुरी",
    source: "frstscalelab",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "इतर व्यवसाय/ उद्योग / धंदा/ व्यापार",
    source: "txtfrstscalebussi",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "शेतीशिवाय इतर स्थावर मिळकती पासूनच उत्पन्न",
    source: "txtfrstscaleimmovabl",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name:
      "नोकरीतील वेतन/ सेवानिवृत वेतन / मानधन/अनुदान इत्यादी मासिक उत्पन्नापासूनचे वार्षिक उत्पन्न",
    source: "txtfrstscaleannualincom",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "गुंतवणुकीवरील व्याज/ लाभांश व तत्सम उत्पन्न",
    source: "txtfrstscaleintrstpro",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name:
      "वर नमूद साधनांशिवाय इतर उत्पन्नाची साधने असल्यास त्या पासूनचे उत्पन्न",
    source: "txtfrstscaleanysource",
    t1: new Date().getFullYear() - 2,
    t2: new Date().getFullYear() - 1,
    t3: new Date().getFullYear(),
    t4: "0",
  },
];

export const income_Singleinput = [
  {
    source_name: "शेती",
    source: "farm",
    t1: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "शेती पुरक व्यवसाय",
    source: "farmRelated",
    t1: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "दुग्ध व्यवसाय",
    source: "frstscaledairy",
    t1: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "शेत मजुरी",
    source: "txtfrstscaleagri",
    t1: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "इतर मजुरी",
    source: "frstscalelab",
    t1: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "इतर व्यवसाय/ उद्योग / धंदा/ व्यापार",
    source: "txtfrstscalebussi",
    t1: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "शेतीशिवाय इतर स्थावर मिळकती पासूनच उत्पन्न",
    source: "txtfrstscaleimmovabl",
    t1: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name:
      "नोकरीतील वेतन/ सेवानिवृत वेतन / मानधन/अनुदान इत्यादी मासिक उत्पन्नापासूनचे वार्षिक उत्पन्न",
    source: "txtfrstscaleannualincom",
    t1: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name: "गुंतवणुकीवरील व्याज/ लाभांश व तत्सम उत्पन्न",
    source: "txtfrstscaleintrstpro",
    t1: new Date().getFullYear(),
    t4: "0",
  },
  {
    source_name:
      "वर नमूद साधनांशिवाय इतर उत्पन्नाची साधने असल्यास त्या पासूनचे उत्पन्न",
    source: "txtfrstscaleanysource",
    t1: new Date().getFullYear(),
    t4: "0",
  },
];

export const work_type = [
  "Goverment Employee",
  "Farmar",
  "Professor",
  "Engineer",
  "Software Professonal",
  "House Wife",
  "Nurse",
  "Private Service",
  "Bussiness",
  "Teacher",
  "B.A",
  "Farm Wages",
  "Wages",
  "B.E",
  "Student",
  "Hardware Professional",
  "Other",
  "Artist",
  "Lawyer",
  "Writer",
];

// export const NonCasts=[
//   Aagale(169),
//   ADHAPRABHU(182),
//   ADHASHETI(182)
//   AGARI(169),
//   "Agri(169)",
//   AHIR (198),
//   AHIR (29)(1),
//   AHIR(198),
//   Alitakar(1),
//   Alitkar,
//   ALKARI(331)
//   ANSARI(191)
//   ANSARI(57)
//   ARANYA GHARBHARI (1)(10)
//   Arya Shatriya(8)
//   ASWALWALE (37)
//   ATAAR(202)
//   ATTAR(202)
//   AUDHIYA(203)
//   AYYAR (26)(E)
//   AYYARI (26)(E)
//   BAADHAI(174)
//   Badak(204)
//   BADGUJAR(326)
//   BADHAI(174)
//   BADHI(174)
//   Badhinee(218)
//   BADIA (4)
//   Badiya(4)
//   Bagadi(38)
//   BAGALU(205)
//   BAGWAN (MUSLIM)(182)
//   Bagwan(182)
//   BAHURUPI (26)
//   Bairagi(1)(2)
//   BAJANIA(5)
//   Bajaniya(5)
//   Bajigar(6)
//   BAL SANTOSHI (3)(A)
//   BALASANTU
//   BALSANTHANAM(208)
//   BAN (1)(8)
//   BANDI(130)
//   Banjara(7)
//   BANJARI (7)(S)
//   BANKAR(251)
//   BAORIYA(216)
//   Baouriya(216)
//   Barai(13)
//   BARAV(204)
//   Bari(13)
//   BATHINI(218)
//   Bava (1) (1 )
//   BAWARCHI(187)
//   BEGARI(219)
//   BELCHHEDA(224)
//   BELDAR BUKEKARI (2)
//   BELDAR KAPEWAR (2)
//   BELDAR MUNNAR-KAPEWAR (2)
//   BELDAR PENTAREDDY (2)
//   BELDAR TELANGA (2)
//   BELDAR TELGI (2)
//   Beldar(2)
//   Berad(1)
//   Beriya(14)
//   Besdeva(15)
//   Besta(25)
//   Besta(25)
//   BESTA(322)
//   BESTALLU(322)
//   BESTAR(2)
//   Besti(25)
//   Besti(25)
//   BESTI(322)
//   Bestolu(25)
//   Bestolu(25)
//   BHADAI(299)
//   BHADBHUNJA(16)
//   Bhadhi(174)
//   Bhadwal(125)
//   BHALDAR(330)
//   BHAMATA (3)
//   BHAMATI (3)(A)
//   BHAMPATA(220)
//   Bhamtha(3)
//   BHAMTI
//   Bhanara(26)
//   Bhanare(26)
//   Bhanari(26)
//   BHAND(8)
//   Bhand/Chhapparbhand(8)
//   Bhandari(187)
//   BHANDURA(224)
//   Bhangi(7)
//   Bhanta(17)
//   BHARADBHUNJA(16)
//   BHARADI 3
//   BHARAT BAGWAN(182)
//   BHARATI (1)(3)
//   BHARATI GOSAVI (1)(5)
//   BHARATIYA IRANI (35)
//   Bhat(18)
//   BHATIYARA (MUSLIM)(187)
//   BHATT(18)
//   Bhavagar(293)
//   Bhavaiya(9)
//   Bhavaiya/Targal(9)
//   BHAVGAR(293)
//   Bhavin(10)
//   Bhavsar Kshatriya Rangari(170)
//   Bhavsar Kshatriya Shimpi(170)
//   Bhavsar Kshatriya(170)
//   Bhavsar Rangari(170)
//   Bhavsar Shimpi(170)
//   Bhavsar(170)
//   Bhawsar(170)
//   Bhilala(179)
//   Bhirati/Pakhali(11)
//   Bhistee(279)
//   BHISTI(11)
//   BHISTI(279)
//   Bhoi (211)
//   Bhoi (25)(4)
//   Bhoi Navadi (25)(18)
//   Bhoi(25)
//   BHOIR(189)
//   Bhope(4)
//   BHORPI 26(C)
//   BHOYAR(189)
//   BHOYEER(189)
//   BHUJAVA(16)
//   Bhujva(16)
//   BHUNJ(16)
//   BHURANJI(16)
//   BHURJAVA(16)
//   BHURJI(16)
//   BHUT(4)
//   BILLAVA(224)
//   BINDALI(227)
//   BORAAL(190)
//   BORAD(190)
//   BORAL(190)
//   BORUL(190)
//   BUDBUDKI (13)(A)
//   BUNKAR(251)
//   BURBUK(228)
//   Buttal(7)
//   CHADAR(229)
//   Chakravadya(230)
//   CHAKRAVDYA-DASAR(230)
//   CHAMATHA(19)
//   CHAMATHA(51)
//   Chamtha(19)
//   CHANDAL(231)
//   Chandalagada(20)
//   CHANDALGADA(20)
//   Channewar(3)(9)
//   CHAPPARBAND(14)
//   CHARAN BANJARA (7)(D)
//   CHARAN(21)
//   Charan/Gadhavi(21)
//   Charodi(22)
//   Chenewar(3)(9)
//   CHENVAWAR(232)
//   CHENVU(232)
//   Chenwu(232)
//   CHHAPARBAND (14)
//   CHHAPARBHAND(8)
//   Chhapparband (119)
//   Chhapparband (187)
//   Chhapparband(14)
//   CHHARA (5)(A)
//   CHHIPA(23)
//   CHIMUR(233)
//   CHINTALA(234)
//   CHIPPA(23)
//   Chippa, Chhipa(23)
//   CHITARI (8)
//   CHITARI/JINGAR(8)
//   Chitodi Lohar(8)
//   CHITODI LOHAR8
//   CHITODIYA-LOHAR (8)
//   Chitrakathee Hardas(321)
//   CHITRAKATHI (6)
//   CHITRAKATHI HARDAS(321)
//   Chitrakathi Hardas(6)
//   CHOUKALASHI(182)
//   CHOUKALSHI(182)
//   Christain Koli(260)
//   Christian (196)
//   CHRISTIAN CONVERTED FROM SCHEDULED CASTE (196)
//   CHRISTIAN KOLI (4)(6)
//   CHRISTIAN KOLI(260)
//   CHUNARI(267)
//   Daas/Dangdidaas(24)
//   DAFALI(30)
//   DAGADFODU (12)(E)
//   DAKALERU(235)
//   Dakleru(235)
//   DAMARUWALE (13)(B)
//   DANGADIDAS
//   DANGARI(317)
//   DANGAT
//   DANGAT 16(A)
//   DANGE (29)(2)
//   DANGE DHANGAR (29)(25)
//   DARJI(153)
//   DARJI(236)
//   DARVESHI (37)
//   DAS(24)
//   DASAR(222)
//   Davari (3)(U)
//   DAVGAR(25)
//   Depala(26)
//   DESHKAR TELI (181)
//   DEVADIGA(28)
//   DEVALI(27)
//   Devdig(28)
//   DEVERI(250)
//   Dewang(3)(10)
//   Dhadi
//   Dhadi Banjara (7)(M)
//   DHAKAD(190)
//   DHALI (7)(L)
//   DHALIYA (7)(L)
//   DHALIYA BANJARA(214)
//   DHANGAR AHIR(29)(1)
//   Dhangar(29)
//   Dhanvar (29)(21)
//   DHANWAR
//   DHANWAR (29)(21)
//   DHARI (7)(G)
//   DHAWAD(319)
//   DHIMAR (25)(15)
//   Dhiwar (25)(13)
//   DHOBI (125)
//   Dholi(30)
//   DHONTALE (4)(A)
//   DHURIYA KAHAR (25)(7)
//   DODE GUJAR(326)
//   DODI(266)
//   DOMBARI(15)
//   DOMMARA(241)
//   DON BAIL TELI (181)
//   DON BAIL TELI(181)
//   DONGARI DHANGAR (29)(25)
//   DORIK(345)
//   EAST INDIAN CATHOLIC(341)
//   EAST INDIAN CHRISTIAN(341)
//   EAST INDIAN(341)
//   Edrisi/Darji(153)
//   EK BAHIYA TELI (181)
//   EKBAIL TELI (181)
//   ERANDEL TELI (181)
//   FAKIR BANDARWALA(192)
//   FAKIR(335)
//   FANADE BANJARA(212)
//   FAR(283)
//   FASECHARI(118)
//   FUDAGI(119)
//   Ful Mali ( 182 )
//   FULARI(127)
//   FULE(182)
//   FULMALI(182)
//   FUTGUDI(175)
//   Gaadi Lohar(8)
//   Gabit(201)
//   GABIT(6)
//   Gadaaba(242)
//   GADABA(242)
//   Gadaria (29)(22)
//   GADARIYA(29)
//   Gadhari (29)(24)
//   Gadhavi(39)
//   GADHEWAL KOSHTI (3)(6)
//   GADHRI (29)(24)
//   GADI LOHAR8
//   GADI WADDAR (12)(A)
//   Gadri (29)(23)
//   GADRIYA(29)(26)
//   GADRIYA(328)
//   GAHARA (32)
//   GANALI(188)
//   Ganchi(300)
//   Gandharap(35)
//   GANDLI(188)
//   GANGANI(243)
//   Ganli(188)
//   Ganniga(300)
//   Ganninga(300)
//   GAON PARADHI (9)(B)
//   GARODI(244)
//   Garpagari (3)(D)
//   GARUDI (34)
//   Garudi(7)
//   GASE WANMALI(182)
//   GATARI (29)(3)
//   Gavada (350)
//   Gavade (350)
//   Gavandi(45)
//   Gawada (350)
//   Gawade (350)
//   GAWALAN(36)
//   Gawali (36)
//   Gawali Gopal(36)
//   GAWANDI(45)
//   Gawari(1)
//   GHADASHI(194)
//   Ghadshi(194)
//   Ghanchi(300)
//   GHANTICHOR(220)
//   GHISADI LOHAR (8)
//   GHISADI(8)
//   Ghisadi(8)
//   GHODEKHAU(182)
//   GIHARA (32)
//   GIRANI WADDAR (3)(B)
//   GIRI GOSAVI(1)(4)
//   Gochaki(42)
//   GODABA(242)
//   GODALA(246)
//   GODIYA KAHAR (25)(6)
//   GOLER (9)
//   GOLKAR (09)
//   Golla(9)
//   GOLLER(245)
//   GOLLEWAR (9)
//   Gondhali(10)
//   GOPAL BHORAPI (11)(A)
//   Gopal Gawali(36)
//   Gopal(11)
//   Gor Banjara (7)(A)
//   GOSAEE (33)
//   Gosai(33)
//   GOSAVI (1)
//   Gosawi(1)
//   Goud Kalal (254)
//   Gowari (1)
//   Gujar
//   GUJAR(326)
//   Gujrath Bori(36)
//   GURAADI(349)
//   Guradi kapewaar(349)
//   GURADI(349)
//   GURADI-REDDY(349)
//   GURAD-KAPU(349)
//   Gurav(43)
//   Gurda-Kapewar(349)
//   GURDA-KOPEWAR(349)
//   Gurjar-Kadiya(45)
//   GUROU(43)
//   GUSAEE (33)
//   GUTARADI-KAPEWAR(349)
//   GWALVANSH (36)
//   HAATKAR (29)(7)
//   HABURA(247)
//   HADAD(92)
//   Hadad/Mistri(92)
//   HADPAD(108)
//   HAJAM(108)
//   HAJJAM (108)
//   HAJJAM(276)
//   HALADE(182)
//   Halalkhor(7)
//   Halba Koshti(3)(2)
//   Halepaik(46)
//   HANBAR(325)
//   HANDE 29(4)
//   HARAN SHIKARI (9)(C)
//   HARANI(248)
//   Harkantra(239)
//   HARKRANTA(239)
//   Harni(248)
//   HASHMI(30)
//   Hatakar (29)(7)
//   Hatkar (29)(6)
//   Hazaam(276)
//   HELAVI (12)
//   HILAVI (12)
//   HIL-REDIDAS(249)
//   HINDU DHARKAR(164)
//   HINDU KUNBI (83)
//   IDRIS(153)
//   Indian Irani(35)
//   Jaatgar(310)
//   Jaatiya (25)(11)
//   Jadi(60)
//   JADUGAR (34)
//   Jagiyasi(49)
//   JAIN KALAR(254)
//   JAIN KOSHTI  (3)(15)
//   JAIN SHIMPI(153)
//   Jajaak(50)
//   JAJAK(50)
//   Jangam(58)
//   JATGAR(310)
//   JATI WADDAR
//   Jatigar(52)
//   Jatiya(51)
//   JAVERI(53)
//   Javheri(53)
//   JINGAR - CHITARI (59)
//   Jingar 8
//   Jire 182(9)
//   JIRE MALI
//   JIRE MALI(182)
//   JOGI (3)(E)
//   JOGI BANJARA (7)(P)
//   Jogin(55)
//   Johari(56)
//   JOSHI (13)
//   Joshi(13)
//   JULAHA (57)
//   Julaha(57)
//   Julaha, Ansari(57)
//   KACCHIYA(252)
//   KACHA(182)
//   KACHAKIWALE BANJARA (7)(G)
//   KACHARI(87)
//   KACHHI(85)
//   Kachiya(252)
//   Kachora(72)
//   KADAKLAKSHMIWALE (31)
//   Kadera(73)
//   KADU(182)
//   Kahar (25)(5)
//   Kaikadi(4)
//   Kakar (344)
//   Kalaal(342)
//   Kalal (219)
//   KALAL(254)
//   Kalan(169)
//   Kalar(254)
//   KALAWADER(304)
//   KALSERU(276)
//   Kalwar(124)(A)
//   Kamaati(74)
//   Kamathi (3)
//   Kamati (3)(C)
//   Kammi(62)
//   KAMTI(74)
//   KANADE (29)(13)
//   Kanadi(351)
//   KANCHAR(87)
//   KANDEL(255)
//   KANJAR (5)(B)
//   KANJARBHAT (5)
//   Kanodi(351)
//   KANSHI(276)
//   KANTHAHAR WANI(190)
//   Kapadi(63)
//   KAPEWAR (2)
//   Kapewar(5)(7)
//   KARADI(311)
//   KASAB(257)
//   KASABI(75)
//   KASAI (257)
//   Kasai(257)
//   KASAI(316)
//   KASAR (160)
//   KASAR (87)
//   Kasar(160)
//   Kasar(87)
//   Kasbi(75)
//   KASERA(256)
//   Kashi Kapadi (14)
//   KATABU (6)
//   Katari (21)
//   KATARI SHIKALGAR (21)
//   KATHAR WANI(190)
//   Kathar(190)
//   KATHARWANI (190)
//   KATHARWANI (190)
//   Kathi(86)
//   KATIPAMULA(258)
//   Kewat(25)(12)
//   Kewat(76)
//   Khakrob(7)
//   Kharthi(225)
//   Kharva(4)(13)
//   KHARVI(225)
//   Kharvi(4)(13)
//   KHATAWALI(266)
//   KHATI (65)
//   Khati(65)
//   Khati(8)
//   KHATIK(316)
//   KHATRI(126)
//   KHATWADHAI(313)
//   KHATWADHI(8)
//   KHELKARI (11)(B)
//   KHUTEKAR 29(9)
//   KINGARIWALE (3)(B)
//   Kiraar(259)
//   Kirad(25)(8)
//   KIRAR(259)
//   Kirat (25)(8)
//   KODAKU SAH KORAVA(262)
//   Kodaku(262)
//   KOHALI (315)
//   KOIRI(85)
//   Kokani-Dhangar (29)(12)
//   KOLHATI (15)
//   KOLHATI(15)
//   Koli (4)(1)
//   Koli (4)(13)
//   Koli (65)
//   KOLI BARIYA
//   Koli Suryavanshi(4)(9)
//   KOMAKAPU(263)
//   KONCHIKORAWA (4)(C)
//   KONDU(264)
//   Kongadi(69)
//   KONKANI-DHANGAR (29)(12)
//   KORACH(252)
//   KORACHAR(261)
//   Koraghar(261)
//   KORAVE(261)
//   KORAVI (4)(E)
//   KORAWA (4)(B)
//   Korchar(70)
//   Korva (262)
//   Kosakanti devang(173)
//   KOSARE(182)
//   Koshti (3)(1)
//   KOSKANTI DEVANG(173)
//   KSHATRIYA PATKAR(126)
//   KSHATRIYA(126)
//   Ku.Wani(190)
//   Kuchbandh(80)
//   Kuchhariya(81)
//   Kudmude - Joshi (13)(C)
//   KUDMUDE (13)(C)
//   Kudumbi(35)
//   Kulakadgi(338)
//   KULALA(82)
//   Kulekadgi(338)
//   Kullakadgi(338)
//   Kullekadgi(338)
//   Kulwant Wani(190)
//   Kulwant-Wani(190)
//   Kumawat(2)
//   KUMBARA (82)
//   KUMBHAR(82)
//   KUMBHARA
//   KUMHAR(82)
//   Kunabi Wani(190)
//   Kunbi ( 83 )
//   Kunbi (70)
//   KUNBI (83)
//   Kunbi Leva Patidar ( 83 )
//   KUNBI MARATHA(83)
//   Kunbi(83)
//   Kunbi(83)
//   KUNBI(83)
//   KUNKUWALE(312)
//   KURABA(238)
//   Kurba(238)
//   Kureshi(257)
//   Kurhin(171)
//   KURHINSHETTI(171)
//   Kurmaar (29)(17)
//   Kurmar (29)(17)
//   KURUBAR(238)
//   KUSHWAHA(85)
//   Laabha(88)
//   LABAN (7)(J)
//   LABHA(88)
//   LABHAN (7)(E)
//   LAD (254)
//   Lad Vanjari 30
//   LAD(254)
//   LADAF(90)
//   LADASE (29)(19)
//   Ladasi(199)
//   Ladhiya(89)
//   LADIYA(89)
//   Ladse (29)(19)
//   LADSHAKHIYA WANI(190)
//   LADSHAKHIYA WANI(343)
//   LADWAK(254)
//   LAIDAF (NADDAF)(90)
//   Laidaf Naddaf (90)
//   LAKHARI(265)
//   Lakheriya(91)
//   Lalbeg(7)
//   Laman (7)(I)
//   LAMAN BANJARA (7)(H)
//   Lamani (7)(I)
//   LAMBADA (7)(B)
//   LAMBADE(211)
//   LAMBARA
//   Lanjhad(197)
//   LANZAD(197)
//   LARIYA(89)
//   LAZAD(197)
//   Leva Gujar(326)
//   LEVA KUNBI(83)
//   LEVA PATIDAR(83)
//   LEVA PATIL(83)
//   LEVE GUJAR(326)
//   Leve Patidar ( 83 )
//   Lewa Patidar (70)
//   Lingayat Devang(3)
//   Lingayat Dhobi (125)
//   Lingayat Dhobi(125)
//   Lingayat Gurav (43)
//   Lingayat Gurav(43)
//   Lingayat Jangam (58)
//   Lingayat Jangam(58)
//   Lingayat Koshti (3)
//   Lingayat Koshti(3)
//   Lingayat Kulekadgi(338)
//   Lingayat Kullekadgi (338)
//   Lingayat Kumbhar (82)
//   Lingayat Kumbhar(82)
//   LINGAYAT MALI(182)
//   Lingayat Nhavi (108)
//   Lingayat Nhavi(108)
//   Lingayat Parit (125)
//   Lingayat Parit(125)
//   Lingayat Phulari (127)
//   Lingayat Phulari(127)
//   Lingayat Sali (3)
//   Lingayat Sali(3)
//   Lingayat Sutar (174)
//   Lingayat Sutar(174)
//   Lingayat Tamboli (190)
//   Lingayat Tamboli(190)
//   LINGAYAT TELI (181)
//   Lingayat Wani(190)
//   LODH(336)
//   LODHA(336)
//   LODHI(336)
//   Lohar (79)
//   LOHAR (8)
//   Lohar(8)
//   LOHARA-GADA(266)
//   Lonari(183)
//   LONDHARI / PENDHARI(326)
//   LONIYA(110)
//   LUHAR(92)
//   LUNIYA(110)
//   LUWAR(92)
//   Luwar(92)
//   Maahil(269)
//   Maazwar(271)
//   MACCHUA (25)(9)
//   Machchhimar-Daldi(329)
//   Machchi(93)
//   Machhi - Mitna (4)(15)
//   Machhi (4)(15)
//   Madawal(125)
//   Madhura(104)
//   MAHANUBHAV BHOPI(94)
//   MAHAT(334)
//   MAHAWAT(334)
//   MAHILA(269)
//   Mahlee(99)
//   Mahli(86)
//   MAHLI(99)
//   MAHURE (29)(18)
//   MAHUT(334)
//   MAIDASI(270)
//   Mairal (16)
//   MAKADWALE (4)(C)
//   MALAJANGAM VIRBHADRA (58)
//   MALHAR (25)(19)
//   MALHAV (25)(20)
//   Malhav(211)
//   MALI(182)
//   MANBHAV BHOPI(94)
//   Manbhav(94)
//   MANERI(309)
//   MANGELA (4)(10)
//   MANGELE (239)
//   MANGOLI (239)
//   MANIYAR(309)
//   MANKAR KHALU(273)
//   Mankar(273)
//   Mansuri(177)
//   Mansuri(90)
//   MANYAR BANGADIWALA(309)
//   MANYAR(309)
//   MANZI (25)(10)
//   Mapanjogi(103)
//   MAPANJOSHI (17)(2)
//   MARAGAMMAWALE (31)
//   MARAL(182)
//   MARAR(182)
//   Maratha
//   MARATHA KUNBI(83)
//   MARATHA TELI (181)
//   Marawar,baori(206)
//   MARBAR WAGHARI(206)
//   MARIAIWALE (31)
//   Marvar bori(96)
//   MARWADI NHAVI(348)
//   Marwadi Sonar (154)
//   Marwadi Sonar(154)
//   Marwadi Sunar (154)
//   Marwadi Sunar(154)
//   Marwadi Swarnakar (154)
//   Marwadi Swarnakar(154)
//   MARWAR BAORI(206)
//   MARWAR BORI(96)
//   MASANJOGI  (17)
//   MATHURA BANJARA(209)
//   Mathura Bnjara(209)
//   MATHURA LABHAN (7)(F)
//   MATHURA(104)
//   MATI WADDAR (12)(C)
//   MATIHARA(272)
//   MATIYARA(272)
//   MAZWAR(271)
//   ME(97)
//   Medar(100)
//   Mehtar(7)
//   MENDHAGI (13)(D)
//   MERU (153)
//   MERU KSHATRIYA SHIMPI(153)
//   MERU SHIMPI(153)
//   Mey(97)
//   Mhali(101)
//   MHALI(108)
//   Mhali(86)
//   Mina(98)
//   MISTRI(92)
//   Mitha(102)
//   MITKARIWANI(190)
//   Mitna (4)(15)
//   MOILY(28)
//   MOMIN(191)
//   MONDIWAR(274)
//   MONDIWARA(274)
//   Mourya(85)
//   MUJAWAR(339)
//   Mulaana(340)
//   MULANA(340)
//   MULANE(340)
//   MULANI(340)
//   MULYA(82)
//   MUNDA(275)
//   MUNNAR KAPU (2)
//   Munnerwar(5)(1)
//   Munnurwar (5)(2)
//   Munnurwar Telagu(5-5)
//   MURAI (85)
//   MUSLIM BELDAR (2)
//   MUSLIM BHAND(8)
//   MUSLIM BHANGI (7)
//   Muslim Gavandi (2)
//   MUSLIM GAWALI (36)
//   MUSLIM KAKAR 344
//   MUSLIM MADARI (34)
//   Muslim Raj (2)
//   Naalband(337)
//   Naamdhari paik(106)
//   Naamdhari(105)
//   NABHIK(108)
//   NABHIK(276)
//   NACCHIMAR (DALDI)(329)
//   NAI(276)
//   NAIKWADI (1)(B)
//   Nakkashi(111)
//   NALBAND(337)
//   Namdev Shimpi (153)
//   NAMDEV(293)
//   NAMDHARI PAIK(106)
//   NAMDHARI(105)
//   NANDIWALE  (18)
//   Nandiwale ( 18 )
//   NAPIT(108)
//   NATH (3)
//   NATH (3)(E)
//   Nath Bava (3)(C)
//   Nath Jogi(3)(D)
//   NATHJOGI (3)(D)
//   NATHPANTHI DAWARI GOSAVI (3)(E)
//   NATHPANTI (3)(E)
//   NATHPANTI GOSAVI (1)(12)
//   NAVADI (25)(18)
//   NAVI (108)
//   NAVI BANJARA
//   NAVISEN(108)
//   NAVLIGA(276)
//   Neelgar(172)
//   Neeli(112)
//   Neelkanti(113)
//   Nekar jada(114)
//   Nethura(109)
//   Neve Wani ( 342 )
//   NEVEWANI(342)
//   NEVI(190)
//   Nhavi (108)
//   NILGAR(172)
//   NILI(112)
//   NILI(172)
//   NILKANTI(113)
//   NIRALI(172)
//   Nirhalee(320)
//   NIRHALI (NIRALI)(320)
//   Nirshikari(107)
//   NONIYA(110)
//   NUNIYA(110)
//   OD 2
//   Oda(25)
//   Oda(25)
//   Odewar(25)
//   Odewar(25)
//   ODEWAR(308)
//   Odolu(25)
//   Odolu(25)
//   Ojhari (28 D)
//   Otankar(28)(A)
//   Otari (28)
//   Otkar (28) (B)
//   Otkari(28)(E)
//   Otokar(28)(E)
//   Paachbhatela(277)
//   Paamula(280)
//   PAANCHAL(266)
//   PACHBHOTALA(277)
//   PACHBOTALA(277)
//   PACHKALASHI(182)
//   Padampaari(278)
//   PADAMPARI(278)
//   Padhariya(115)
//   Padiyar(116)
//   PADLOR(253)
//   Padmashali (3)(9)
//   PAGE(239)
//   PAHAD(327)
//   PAHADI(327)
//   PAKHALI(11)
//   PAKHALI(120)
//   Pakhali, Sakka(120)
//   PALEWAR (25)(16)
//   PALPARADHI (8)
//   PALUMA(280)
//   PAMLOR (4)(D)
//   Panari(190)
//   PANCHAL LOHAR (8)
//   PANCHAL SUTAR(174)
//   Panchal(121)
//   PANCHAL(266)
//   PANCHAM(281)
//   PANCHAMA(281)
//   PANDA(282)
//   Pangul ( 19 )
//   Panka(122)
//   PARDESHI BHAMATA (10)(A)
//   PARDESHI BHAMATI (10)(B)
//   Pardeshi Bhamta (10)(A)
//   Pardeshi Bhamti (10)(B)
//   PARDESHI BHOI (25)(2)
//   PARDESHI(220)
//   PAREET(125)
//   Parit (125)
//   Parit/Dhobi(125)
//   PARIWAR(323)
//   PARJIYA SONI(53)
//   PATAVEGAR(126)
//   PATAVEKARI(126)
//   PATAVI(126)
//   PATAWA 346
//   PATEGAR(126)
//   PATHARE KSHATRIYA(182)
//   PATHARE-KSHATRIYA-PACHKALASHI(182)
//   PATHARWAT (12)(D)
//   PATHROD(304)
//   PATHRUT 3(D)
//   Patkar(126)
//   Patradavaru(117)
//   PATRADAWARU(117)
//   PATTEGAR(126)
//   PATVI (126)
//   Patwegar(126)
//   Patwekari(126)
//   PAWAR(189)
//   PENDARI
//   Pendhari(332)
//   PERAKA(123)
//   PERAKEWAD(123)
//   PERIKA(123)
//   PERIKE(123)
//   Perkewad(123)
//   Perki(123)
//   Phanade Banjara(212)
//   Phar(283)
//   Phasechari(118)
//   Phudgee(119)
//   Phul Mali (159)
//   Phulari(127)
//   Phulmali(182)
//   Phutgudi(175)
//   Pinjara(177)
//   Pinjari (177)
//   Pinjari(177)
//   PINJARI(284)
//   PONG(221)
//   Powar(189)
//   PURI (1)(13)
//   PURWALI(285)
//   PUTALI GAR(124)
//   QURESHI KHATIK(316)
//   QURESHI(257)
//   Rachbandhiya(131)
//   RACHBHOYA(286)
//   RACHEVAR(128)
//   Rachewar(128)
//   Raghavi(186)
//   Raikari(129)
//   RAIN (BAGWAN)(182)
//   Rajak(125)
//   RAJBHOI (25)(3)
//   RAJPARDHI (9)
//   Rajput Bhamta (183)
//   Rajput Bhamta(10)
//   RAJPUT LOHAR (8)
//   Rajput Lohar(8)
//   RAMOSHI(11)
//   Rangari Bhavsar(170)
//   Rangari(170)
//   Rangrej(Bhavsar,Rangari)(170)
//   RANGREZ(133)
//   Rangrez(170)
//   RAOT(134)
//   RATHOD (347)
//   RAUL (20)
//   RAUTIYA(287)
//   RAWAL (20)
//   RAWALYOGI
//   Rawat(134)
//   Rawtiya(134)
//   RAYIKAR(129)
//   REVA GUJAR(326)
//   REVE GUJAR(326)
//   S.K.P(182)
//   Saahis(147)
//   Saais(147)
//   Saare(292)
//   Saaunta(290)
//   SAGAR (1)(7)
//   SAGAR (29)(8)
//   SAHASRARJUN KSHATRIYA(126)
//   SAHDEV JOSHI 13(F)
//   SAHIS(147)
//   SAI (153)
//   SAIKALGAR (21)
//   SAIKALKAR (21)
//   SAIS(147)
//   SAITWAL(153)
//   Sakka(11)
//   SAKKA(120)
//   SALAT (13)(A)
//   SALAT WAGHARI (13)(B)
//   SALAT(13)
//   Salewar(3),(8)
//   Sali (3 )( 4 )
//   SALI (4)
//   SALMANI(108)
//   SALMANIYA(108)
//   Sanagar (29)(20)
//   SANCHALU WADDAR(2)
//   SANDURI(239)
//   SANGAR (29)(20)
//   SANGARI(288)
//   SANGATARASH (12)(E)
//   Sanjogi(140)
//   Santaal(289)
//   SANTAL(289)
//   SANYASI (1)(11)
//   sapalig (145)
//   Sapaliga (145)
//   SAPERA (148)
//   SAPGARUDI (7)
//   Sappaliga (145)
//   SAPWALE (34)
//   SARANIYA(141)
//   SARASWATI PARWAT 1(6)
//   SARAWADE (13)(M)
//   SARE(292)
//   SARODA (13)(H)
//   Saroda (142)
//   SARODE (13)(E)
//   SARODI (13)(E)
//   SASTIKAR(182)
//   SAUNTA(290)
//   Savkalaar(324)
//   SAVKALAR(324)
//   SAVTELI(291)
//   SAWATA MALI(182)
//   SAWTELI (181)
//   SAYKALGAR (21)
//   SEGAR 29(8)
//   SEKKALGAR (MUSLIM) (21)
//   SHARADAKAL (17)(3)
//   SHARADAKAR (17)(3)
//   SHARDAKAR (17)(3)
//   SHEGAR (29)(8)
//   SHEGAR(29)(8)
//   SHERIGAR(28)
//   SHETWAL(153)
//   Shigadiya Banjara(215)
//   SHIKALGAR (21)
//   Shikalgar(207)
//   SHIKALIGAAR (21)
//   SHIKALIGAR (21)
//   SHIKALIKAR (21)
//   SHIKALKAR(21)
//   SHIKH-SHIKALIKAR (21)
//   SHIKILGAR (21)
//   SHILAWAT(149)
//   SHIMPI (153)
//   SHINGADE BANJARA(210)
//   SHINGADYA BANJARA(215)
//   SHINGADYA(294)
//   Shingdav(294)
//   SHINGDAWA(294)
//   Shis(147)
//   SHIV SHIMPI (293)
//   SHIVHARE(254)
//   SHRAVAK SHIMPI(153)
//   Sikalgar (207)
//   SIKALGAR (21)
//   SIKALIGAR (21)
//   SIKALIGHAR (21)
//   SIKALIKAR (21)
//   SIKALKAR (21)
//   SIKILGAR (21)
//   SIKILKAR (21)
//   Sikkalgar(21)
//   SIKKALIGAR (21)
//   SIKKALKAR (21)
//   SINDHUR(295)
//   SINGARI (7)(N)
//   Singiwala(150)
//   Sinyur(295)
//   Somavanshiya Sahastrarjun Kshatriya(126)
//   SOMVANSHIYA(126)
//   SOMVANSHIYA-PATHARE KSHATRIYA(182)
//   Somwanshi arya Shatriya(8)
//   Somwanshi(8)
//   Sonar(154)
//   SONKOLI(4)(11)
//   SONTA(290)
//   SORE(296)
//   SUDGADSIDDHA (17)(1)
//   Sudhariya(146)
//   Sudi(122)
//   SUNAR BANJARA(213)
//   SUNNA(297)
//   SUNNAI(298)
//   Supalig (145)
//   Suppalig (145)
//   SUPPALING(145)
//   SURYAVANSHI GUJAR(326)
//   Suryawanshi Koli (4) ( 9 )
//   Sutar(153)
//   SUTAR(174)
//   SUTAR(182)
//   SUTHAR(174)
//   SUTHARIYA(146)
//   SWAKUL SALI (3) (4)
//   Swakul Sali(131)
//   Takari (3)(E)
//   Takari (3)(E)
//   TALWAR (1)(C)
//   TALWAR-KANADE(185)
//   TALWAR-KANADI(185)
//   Tambat(160)
//   Tambodi(195)
//   TAMBOLI MUSLIM PANFAROSH(195)
//   TAMBOLI(190)
//   Tamboli(195)
//   Tandel(155)
//   TARANE TELI (181)
//   Targal(9)
//   TARGALA(157)
//   Taru-Navadi (25)(18)
//   Telagu Kapevar(5-8)
//   TELAGU MADELWAR PARIT(125)
//   Telanga(2)
//   Telangi(2.07)
//   Telangi(29)
//   TELANGI(29)(10)
//   TELGU DARJI(153)
//   Telgu Madelwar (Parit) (125)
//   Telgu Madelwar(125)
//   Telgu Phulmali (5)(10)
//   TELGU SHIMPI(153)
//   Teli (181)
//   TELLARI (29)(11)
//   Telvar (29)(5)
//   TELWAR (29)(5)
//   Thelari(27)
//   THELLA PAMALWADAS(307)
//   Thetwar(158)
//   THIYA(224)
//   Thogti(161)
//   Thoriya(159)
//   THOTEWADU(301)
//   TILVAN TELI (181)
//   Timaali(302)
//   TIMALI(302)
//   TIRMAL (18)
//   Tirmal(18)
//   TIRTH ASHRAM (1)(9)
//   Twashta Kasar(160)
//   UCHALE (3)(F)
//   UCHILA(223)
//   UDASI (207)
//   UNDE(182)
//   V.Wani(190)
//   Vaadhai(174)
//   Vadar(12)
//   Vaddar (12)
//   VADWAL(182)
//   VAIDU (23)
//   Vaishya Wani(190)
//   VAITI (4)(12)
//   Vanjari(30)
//   VANMALI (182)
//   VANSFOD(164)
//   VANYA(251)
//   Varhade Dhangar (29)(14)
//   VASUDEO (24)
//   VASUDEV (24)
//   Vatari (28)(C)
//   Vatkar(28)(E)
//   Vatkari(28)(E)
//   Vatok(28)(F)
//   Vatokar(28)(E)
//   VEDU WAGHARI(318)
//   VINKAR(251)
//   VIR (16)(B)
//   Votankar(28)(E)
//   Waadhai(313)
//   Waadi(174)
//   Waalvai(303)
//   WADADER(304)
//   WADAI(174)
//   WADAR (12)
//   WADDAR (12)(E)
//   Wadee(162)
//   WADHAAI(174)
//   WADHAI(174)
//   WADHI(174)
//   WADI(162)
//   WADI(174)
//   WAGHARI (13)
//   Waghri(13)
//   WAGHWALE-SHAH (MUSLIM) (37)
//   WALAND(108)
//   WALAND(276)
//   WALMIKI (1)(D)
//   WALVAI(303)
//   WAN (1)(8)
//   WANADI(305)
//   WANI (190)
//   Wani (Kulwant)(190)
//   WANI(190)
//   WANJAR (30)
//   Wanjar(30)
//   WANJARA (30)
//   WANJARI (30)
//   Wansphod(164)
//   WARHADE DHANGAR (29)(14)
//   WARIK(108)
//   Warthi(166)
//   Watas(125)
//   Watkar(28)(E)
//   WATOKAR 28(E)
//   Wedu(318)
//   YADAV(198)
//   YALAM(333)
//   YALLAM(333)
//   YELAM(333)
//   YENADIWADAS(306)
//   Yenadiwads(306)
//   Yeragolawad(307)
//   YERGOLAWAD(307)
//   Yerkula(168)
//   ZADE SUTAR(174)
//   Zade(29)(15)
//   ZENDE (29)(16)
//   ZINGA BHOI (25)(1)
//   Zinga Bhoi(25)(1)
//   zomb
// ]
export const occupation_income_input = [
  {
    source_Name1: "Type of Occupation",
    tabIndex: "0",
    inputType: "text",
  },
  {
    source_Name1: "Name of Organization",
    tabIndex: "1",
    inputType: "text",
  },
  {
    source_Name1: "Designation/Rank",
    tabIndex: "2",
    inputType: "text",
  },
  {
    source_Name1: "Department",
    tabIndex: "3",
    inputType: "text",
  },
  {
    source_Name1: "Salary Range",
    tabIndex: "4",
    inputType: "text",
  },
  {
    source_Name1: "Total Annual Income",
    tabIndex: "5",
    inputType: "number",
  },
  {
    source_Name1: "Date of Joining (dd/mm/yyyy)",
    tabIndex: "6",
    inputType: "date",
  },
  {
    source_Name1: "If Pensioner, date of retirement (dd/mm/yyyy)",
    tabIndex: "7",
    inputType: "date",
  },
  {
    source_Name1: "Type of Business",
    tabIndex: "8",
    inputType: "text",
  },
  {
    source_Name1: "Total Annual Income",
    tabIndex: "9",
    inputType: "number",
  },
];

export const NonCreamyLayer_income_input = [
  {
    source: "Agriculture & Related Assets",
    t1: "2021",
    t2: "2022",
    t3: "2023",
  },
  {
    source: "Business & other sources",
    t1: "2021",
    t2: "2022",
    t3: "2023",
  },
  {
    source: "Salary/Pension/Other Resource",
    t1: "2021",
    t2: "2022",
    t3: "2023",
  },
];

export const ApllicantBenificiaryOtherState = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
];

export const districtData = [
  {
    name: "Ahmednagar",
    tahasil: [
      "Akola",
      "Jamkhed",
      "Karjat",
      "Kopargaon",
      "Nagar",
      "Nevasa",
      "Parner",
      "Pathardi",
      "Rahta",
      "Rahuri",
      "Sangamner",
      "Shevgaon",
      "Shrigonda",
      "Shrirampur",
    ],
  },

  {
    name: "Akola",
    tahasil: [
      "Akola",
      "Akot",
      "Balapur",
      "Barshitakli",
      "Murtijapur",
      "Patur",
      "Telhara",
    ],
  },
  {
    name: "Amravati",
    tahasil: [
      "Achalpur",
      "Amravati",
      "Anjangaon Surji",
      "Bhatkuli",
      "Chandur Railway",
      "Chandurbazar",
      "Chikhaldara",
      "Daryapur",
      "Dhamangaon Railway",
      "Dharni",
      "Morshi",
      "Nandgaon-Khandeshwar",
      "Teosa",
      "Warud",
    ],
  },

  {
    name: "Aurangabad",
    tahasil: [
      "Aurangabad",
      "Gangapur",
      "Kannad",
      "Khuldabad",
      "Paithan",
      "Phulambri",
      "Sillod",
      "Soegaon",
      "Vaijapur",
    ],
  },

  {
    name: "Beed",
    tahasil: [
      "Ambejogai",
      "Ashti",
      "Bid",
      "Dharur",
      "Georai",
      "Kaij",
      "Manjlegaon",
      "Parli",
      "Patoda",
      "Shirur (Kasar)",
      "Wadwani",
    ],
  },

  {
    name: "Bhandara",
    tahasil: ["Bhandara", "Mohadi", "Pauni", "Tumsar"],
  },

  {
    name: "Buldhana",
    tahasil: [
      "Buldana",
      "Chikhli",
      "Deolgaon Raja",
      "Jalgaon (Jamod)",
      "Khamgaon",
      "Lonar",
      "Malkapur",
      "Mehkar",
      "Motala",
      "Nandura",
      "Sangrampur",
      "Shegaon",
      "Sindkhed Raja",
    ],
  },

  {
    name: "Chandrapur",
    tahasil: [
      "Ballarpur",
      "Bhadravati",
      "Brahmapuri",
      "Chandrapur",
      "Chimur",
      "Gondpipri",
      "Jiwati",
      "Korpana",
      "Mul",
      "Nagbhir",
      "Pombhurna",
      "Rajura",
      "Sawali",
      "Sindewahi",
      "Warora",
    ],
  },

  {
    name: "Dhule",
    tahasil: ["Dhule", "Sakri", "Shirpur", "Sindkhede"],
  },

  {
    name: "Gadchiroli",
    tahasil: [
      "Aheri",
      "Armori",
      "Bhamragad",
      "Chamorshi",
      "Desaiganj (Vadasa)",
      "Dhanora",
      "Etapalli",
      "Gadchiroli",
      "Korchi",
      "Kurkheda",
      "Mulchera",
      "Sironcha",
    ],
  },

  {
    name: "Gondia",
    tahasil: [
      "Amgaon",
      "Arjuni Morgaon",
      "Deori",
      "Gondiya",
      "Goregaon",
      "Sadak-Arjuni",
      "Salekasa",
      "Tirora",
    ],
  },

  {
    name: "Hingoli",
    tahasil: ["Aundha (Nagnath)", "Basmath", "Hingoli", "Kalamnuri", "Sengaon"],
  },

  {
    name: "Jalgaon",
    tahasil: [
      "Amalner",
      "Bhadgaon",
      "Bhusawal",
      "Bodvad",
      "Chalisgaon",
      "Chopda",
      "Dharangaon",
      "Erandol",
      "Jalgaon",
      "Jamner",
      "Muktainagar",
      "Pachora",
      "Parola",
      "Raver",
      "Yawal",
    ],
  },

  {
    name: "Jalna",
    tahasil: [
      "Ambad",
      "Badnapur",
      "Bhokardan",
      "Ghansawangi",
      "Jafferabad",
      "Jalna",
      "Mantha",
      "Partur",
    ],
  },

  {
    name: "Kolhapur",
    tahasil: [
      "Ajra",
      "Bavda",
      "Bhudargad",
      "Chandgad",
      "Gadhinglaj",
      "Hatkanangle",
      "Kagal",
      "Karvir",
      "Panhala",
      "Radhanagari",
      "Shahuwadi",
      "Shirol",
    ],
  },

  {
    name: "Latur",
    tahasil: [
      "Ahmadpur",
      "Ausa",
      "Chakur",
      "Deoni",
      "Jalkot",
      "Latur",
      "Nilanga",
      "Renapur",
      "Shirur-Anantpal",
      "Udgir",
    ],
  },

  {
    name: "Mumbai Suburban",
    tahasil: ["Andheri", "Borivali", "Kurla"],
  },

  {
    name: "Nagpur",
    tahasil: [
      "Bhiwapur",
      "Hingna",
      "Kalameshwar",
      "Kamptee",
      "Katol",
      "Kuhi",
      "Mauda",
      "Nagpur (Rural)",
      "Nagpur (Urban)",
      "Narkhed",
      "Parseoni",
      "Ramtek",
      "Savner",
      "Umred",
    ],
  },

  {
    name: "Nanded",
    tahasil: [
      "Ardhapur",
      "Bhokar",
      "Biloli",
      "Deglur",
      "Dharmabad",
      "Hadgaon",
      "Himayatnagar",
      "Kandhar",
      "Kinwat",
      "Loha",
      "Mahoor",
      "Mudkhed",
      "Mukhed",
      "Naigaon (Khairgaon)",
      "Nanded",
      "Umri",
    ],
  },

  {
    name: "Nandurbar",
    tahasil: [
      "Akkalkuwa",
      "Akrani",
      "Nandurbar",
      "Nawapur",
      "Shahade",
      "Talode",
    ],
  },

  {
    name: "Nashik",
    tahasil: [
      "Baglan",
      "Chandvad",
      "Deola",
      "Dindori",
      "Igatpuri",
      "Kalwan",
      "Malegaon",
      "Nandgaon",
      "Nashik",
      "Niphad",
      "Peint",
      "Sinnar",
      "Surgana",
      "Trimbakeshwar",
      "Yevla",
    ],
  },

  {
    name: "Osmanabad",
    tahasil: [
      "Bhum",
      "Kalamb",
      "Lohara",
      "Osmanabad",
      "Paranda",
      "Tuljapur",
      "Umarga",
      "Washi",
    ],
  },

  {
    name: "Parbhani",
    tahasil: [
      "Gangakhed",
      "Jintur",
      "Manwath",
      "Palam",
      "Parbhani",
      "Pathri",
      "Purna",
      "Sailu",
      "Sonpeth",
    ],
  },

  {
    name: "Pune",
    tahasil: [
      "Ambegaon",
      "Baramati",
      "Bhor",
      "Daund",
      "Haveli",
      "Indapur",
      "Junnar",
      "Khed",
      "Mawal",
      "Mulshi",
      "Pune City",
      "Purandhar",
      "Shirur",
      "Velhe",
    ],
  },

  {
    name: "Raigad",
    tahasil: [
      "Alibag",
      "Karjat",
      "Khalapur",
      "Mahad",
      "Mangaon",
      "Mhasla",
      "Murud",
      "Panvel",
      "Pen",
      "Poladpur",
      "Roha",
      "Shrivardhan",
      "Sudhagad",
      "Tala",
      "Uran",
    ],
  },

  {
    name: "Ratnagiri",
    tahasil: [
      "Chiplun",
      "Dapoli",
      "Guhagar",
      "Khed",
      "Lanja",
      "Mandangad",
      "Rajapur",
      "Ratnagiri",
      "Sangameshwar",
    ],
  },

  {
    name: "Sangli",
    tahasil: [
      "Atpadi",
      "Jat",
      "Kadegaon",
      "Kavathemahankal",
      "Khanapur",
      "Miraj",
      "Palus",
      "Shirala",
      "Tasgaon",
      "Walwa",
    ],
  },

  {
    name: "Satara",
    tahasil: [
      "Jaoli",
      "Karad",
      "Khandala",
      "Khatav",
      "Koregaon",
      "Mahabaleshwar",
      "Man",
      "Patan",
      "Phaltan",
      "Satara",
      "Wai",
    ],
  },

  {
    name: "Sindhudurg",
    tahasil: [
      "Devgad",
      "Dodamarg",
      "Kankavli",
      "Kudal",
      "Malwan",
      "Sawantwadi",
      "Vaibhavvadi",
      "Vengurla",
    ],
  },

  {
    name: "Solapur",
    tahasil: [
      "Akkalkot",
      "Barshi",
      "Karmala",
      "Madha",
      "Malshiras",
      "Mangalvedhe",
      "Mohol",
      "Pandharpur",
      "Sangole",
      "Solapur North",
      "Solapur South",
    ],
  },

  {
    name: "Thane",
    tahasil: [
      "Ambarnath",
      "Bhiwandi",
      "Dahanu",
      "Jawhar",
      "Kalyan",
      "Mokhada",
      "Murbad",
      "Palghar",
      "Shahapur",
      "Talasari",
      "Thane",
      "Ulhasnagar",
      "Vada",
      "Vasai",
      "Vikramgad",
    ],
  },

  {
    name: "Washim",
    tahasil: [
      "Arvi",
      "Ashti",
      "Deoli",
      "Hinganghat",
      "Karanja",
      "Samudrapur",
      "Seloo",
      "Wardha",
    ],
  },

  {
    name: "Washim",
    tahasil: ["Karanja", "Malegaon", "Mangrulpir", "Manora", "Risod", "Washim"],
  },

  {
    name: "Yavatmal",
    tahasil: [
      "Arni",
      "Babulgaon",
      "Darwha",
      "Digras",
      "Ghatanji",
      "Kalamb",
      "Kelapur",
      "Mahagaon",
      "Maregaon",
      "Ner",
      "Pusad",
      "Ralegaon",
      "Umarkhed",
      "Wani",
      "Yavatmal",
      "Zari-Jamani",
    ],
  },
];

export const districtData1 = [
  {
    name: "Ahmednagar",
    tahasil: [
      "Akola",
      "Jamkhed",
      "Karjat",
      "Kopargaon",
      "Nagar",
      "Nevasa",
      "Parner",
      "Pathardi",
      "Rahta",
      "Rahuri",
      "Sangamner",
      "Shevgaon",
      "Shrigonda",
      "Shrirampur",
    ],
  },

  {
    name: "Akola",
    tahasil: [
      "Akola",
      "Akot",
      "Balapur",
      "Barshitakli",
      "Murtijapur",
      "Patur",
      "Telhara",
    ],
  },
  {
    name: "Amravati",
    tahasil: [
      "Achalpur",
      "Amravati",
      "Anjangaon Surji",
      "Bhatkuli",
      "Chandur Railway",
      "Chandurbazar",
      "Chikhaldara",
      "Daryapur",
      "Dhamangaon Railway",
      "Dharni",
      "Morshi",
      "Nandgaon-Khandeshwar",
      "Teosa",
      "Warud",
    ],
  },

  {
    name: "Aurangabad",
    tahasil: [
      "Aurangabad",
      "Gangapur",
      "Kannad",
      "Khuldabad",
      "Paithan",
      "Phulambri",
      "Sillod",
      "Soegaon",
      "Vaijapur",
    ],
  },

  {
    name: "Beed",
    tahasil: [
      "Ambejogai",
      "Ashti",
      "Bid",
      "Dharur",
      "Georai",
      "Kaij",
      "Manjlegaon",
      "Parli",
      "Patoda",
      "Shirur (Kasar)",
      "Wadwani",
    ],
  },

  {
    name: "Bhandara",
    tahasil: ["Bhandara", "Mohadi", "Pauni", "Tumsar"],
  },

  {
    name: "Buldhana",
    tahasil: [
      "Buldana",
      "Chikhli",
      "Deolgaon Raja",
      "Jalgaon (Jamod)",
      "Khamgaon",
      "Lonar",
      "Malkapur",
      "Mehkar",
      "Motala",
      "Nandura",
      "Sangrampur",
      "Shegaon",
      "Sindkhed Raja",
    ],
  },

  {
    name: "Chandrapur",
    tahasil: [
      "Ballarpur",
      "Bhadravati",
      "Brahmapuri",
      "Chandrapur",
      "Chimur",
      "Gondpipri",
      "Jiwati",
      "Korpana",
      "Mul",
      "Nagbhir",
      "Pombhurna",
      "Rajura",
      "Sawali",
      "Sindewahi",
      "Warora",
    ],
  },

  {
    name: "Dhule",
    tahasil: ["Dhule", "Sakri", "Shirpur", "Sindkhede"],
  },

  {
    name: "Gadchiroli",
    tahasil: [
      "Aheri",
      "Armori",
      "Bhamragad",
      "Chamorshi",
      "Desaiganj (Vadasa)",
      "Dhanora",
      "Etapalli",
      "Gadchiroli",
      "Korchi",
      "Kurkheda",
      "Mulchera",
      "Sironcha",
    ],
  },

  {
    name: "Gondia",
    tahasil: [
      "Amgaon",
      "Arjuni Morgaon",
      "Deori",
      "Gondiya",
      "Goregaon",
      "Sadak-Arjuni",
      "Salekasa",
      "Tirora",
    ],
  },

  {
    name: "Hingoli",
    tahasil: ["Aundha (Nagnath)", "Basmath", "Hingoli", "Kalamnuri", "Sengaon"],
  },

  {
    name: "Jalgaon",
    tahasil: [
      "Amalner",
      "Bhadgaon",
      "Bhusawal",
      "Bodvad",
      "Chalisgaon",
      "Chopda",
      "Dharangaon",
      "Erandol",
      "Jalgaon",
      "Jamner",
      "Muktainagar",
      "Pachora",
      "Parola",
      "Raver",
      "Yawal",
    ],
  },

  {
    name: "Jalna",
    tahasil: [
      "Ambad",
      "Badnapur",
      "Bhokardan",
      "Ghansawangi",
      "Jafferabad",
      "Jalna",
      "Mantha",
      "Partur",
    ],
  },

  {
    name: "Kolhapur",
    tahasil: [
      "Ajra",
      "Bavda",
      "Bhudargad",
      "Chandgad",
      "Gadhinglaj",
      "Hatkanangle",
      "Kagal",
      "Karvir",
      "Panhala",
      "Radhanagari",
      "Shahuwadi",
      "Shirol",
    ],
  },

  {
    name: "Latur",
    tahasil: [
      "Ahmadpur",
      "Ausa",
      "Chakur",
      "Deoni",
      "Jalkot",
      "Latur",
      "Nilanga",
      "Renapur",
      "Shirur-Anantpal",
      "Udgir",
    ],
  },

  {
    name: "Mumbai Suburban",
    tahasil: ["Andheri", "Borivali", "Kurla"],
  },

  {
    name: "Nagpur",
    tahasil: [
      "Bhiwapur",
      "Hingna",
      "Kalameshwar",
      "Kamptee",
      "Katol",
      "Kuhi",
      "Mauda",
      "Nagpur (Rural)",
      "Nagpur (Urban)",
      "Narkhed",
      "Parseoni",
      "Ramtek",
      "Savner",
      "Umred",
    ],
  },

  {
    name: "Nanded",
    tahasil: [
      "Ardhapur",
      "Bhokar",
      "Biloli",
      "Deglur",
      "Dharmabad",
      "Hadgaon",
      "Himayatnagar",
      "Kandhar",
      "Kinwat",
      "Loha",
      "Mahoor",
      "Mudkhed",
      "Mukhed",
      "Naigaon (Khairgaon)",
      "Nanded",
      "Umri",
    ],
  },

  {
    name: "Nandurbar",
    tahasil: [
      "Akkalkuwa",
      "Akrani",
      "Nandurbar",
      "Nawapur",
      "Shahade",
      "Talode",
    ],
  },

  {
    name: "Nashik",
    tahasil: [
      "Baglan",
      "Chandvad",
      "Deola",
      "Dindori",
      "Igatpuri",
      "Kalwan",
      "Malegaon",
      "Nandgaon",
      "Nashik",
      "Niphad",
      "Peint",
      "Sinnar",
      "Surgana",
      "Trimbakeshwar",
      "Yevla",
    ],
  },

  {
    name: "Osmanabad",
    tahasil: [
      "Bhum",
      "Kalamb",
      "Lohara",
      "Osmanabad",
      "Paranda",
      "Tuljapur",
      "Umarga",
      "Washi",
    ],
  },

  {
    name: "Parbhani",
    tahasil: [
      "Gangakhed",
      "Jintur",
      "Manwath",
      "Palam",
      "Parbhani",
      "Pathri",
      "Purna",
      "Sailu",
      "Sonpeth",
    ],
  },

  {
    name: "Pune",
    tahasil: [
      "Ambegaon",
      "Baramati",
      "Bhor",
      "Daund",
      "Haveli",
      "Indapur",
      "Junnar",
      "Khed",
      "Mawal",
      "Mulshi",
      "Pune City",
      "Purandhar",
      "Shirur",
      "Velhe",
    ],
  },

  {
    name: "Raigad",
    tahasil: [
      "Alibag",
      "Karjat",
      "Khalapur",
      "Mahad",
      "Mangaon",
      "Mhasla",
      "Murud",
      "Panvel",
      "Pen",
      "Poladpur",
      "Roha",
      "Shrivardhan",
      "Sudhagad",
      "Tala",
      "Uran",
    ],
  },

  {
    name: "Ratnagiri",
    tahasil: [
      "Chiplun",
      "Dapoli",
      "Guhagar",
      "Khed",
      "Lanja",
      "Mandangad",
      "Rajapur",
      "Ratnagiri",
      "Sangameshwar",
    ],
  },

  {
    name: "Sangli",
    tahasil: [
      "Atpadi",
      "Jat",
      "Kadegaon",
      "Kavathemahankal",
      "Khanapur",
      "Miraj",
      "Palus",
      "Shirala",
      "Tasgaon",
      "Walwa",
    ],
  },

  {
    name: "Satara",
    tahasil: [
      "Jaoli",
      "Karad",
      "Khandala",
      "Khatav",
      "Koregaon",
      "Mahabaleshwar",
      "Man",
      "Patan",
      "Phaltan",
      "Satara",
      "Wai",
    ],
  },

  {
    name: "Sindhudurg",
    tahasil: [
      "Devgad",
      "Dodamarg",
      "Kankavli",
      "Kudal",
      "Malwan",
      "Sawantwadi",
      "Vaibhavvadi",
      "Vengurla",
    ],
  },

  {
    name: "Solapur",
    tahasil: [
      "Akkalkot",
      "Barshi",
      "Karmala",
      "Madha",
      "Malshiras",
      "Mangalvedhe",
      "Mohol",
      "Pandharpur",
      "Sangole",
      "Solapur North",
      "Solapur South",
    ],
  },

  {
    name: "Thane",
    tahasil: [
      "Ambarnath",
      "Bhiwandi",
      "Dahanu",
      "Jawhar",
      "Kalyan",
      "Mokhada",
      "Murbad",
      "Palghar",
      "Shahapur",
      "Talasari",
      "Thane",
      "Ulhasnagar",
      "Vada",
      "Vasai",
      "Vikramgad",
    ],
  },

  {
    name: "Washim",
    tahasil: [
      "Arvi",
      "Ashti",
      "Deoli",
      "Hinganghat",
      "Karanja",
      "Samudrapur",
      "Seloo",
      "Wardha",
    ],
  },

  {
    name: "Washim",
    tahasil: ["Karanja", "Malegaon", "Mangrulpir", "Manora", "Risod", "Washim"],
  },

  {
    name: "Yavatmal",
    tahasil: [
      "Arni",
      "Babulgaon",
      "Darwha",
      "Digras",
      "Ghatanji",
      "Kalamb",
      "Kelapur",
      "Mahagaon",
      "Maregaon",
      "Ner",
      "Pusad",
      "Ralegaon",
      "Umarkhed",
      "Wani",
      "Yavatmal",
      "Zari-Jamani",
    ],
  },
];
