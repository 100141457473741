import React from "react";
import axios from "axios";
import { MdUploadFile } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import { Fragment, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { toast } from "react-toastify";
// import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { incNumber } from "../../Redux/actions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { json } from "react-router-dom";
import changeNumber from "../../Redux/reducers/reducers";
import swal from "sweetalert";

const ShopActForm = () => {
  const { shopid } = useParams();
  const navigate =useNavigate();
  const reducer = useSelector((state) => state.changeNumber);
  const dispatch = useDispatch();
  const [ShowOldShopAct, setOldShopAct] = useState(false);
  const [showloading, setShowloading] = useState(false);

  const [id, setId] = useState();
  const [form] = Form.useForm();
  const users = useSelector((state) => state.userData.user);
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const [FormFileerror, setFormFileerror] = useState({});
  const SingleUser = users?.filter((ele) => ele._id === userData.user._id);
  const [docs, setDocs] = useState({
    shopact_ownerPassportPhoto: "",
    shopact_ownerSignatureAsPer_PAN: "",
    shopact_adharCard: "",
    shopact_shopPhotograph: "",
    shopact_selfDeclaration: "",
    shopact_ownerPANCard: "",
    shopact_oldShopActForRenewal: "",
  });
  const pricess = useSelector((state) => state.price.formPrices);
  const [userFormDetails, setuserFormDetails] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/formPrice`)
      .then((res) => {
        const response = res.data;
        const filtered = response.filter(
          (item) => item.userID == userData.user._id
        );
        console.log(50, filtered);
        if (filtered.length == 0) {
          const userFormDetails = response.filter(
            (item) => item.userID === "ALL"
          );
          setuserFormDetails(userFormDetails);
        } else {
          const userFormDetails = response.filter(
            (item) => item.userID === userData.user._id
          );
          setuserFormDetails(userFormDetails);
        }
      })
      .catch((err) => console.log(40, err));
  }, []);
  console.log(25, userFormDetails);

  const [balance, setBalance] = useState(0);
  console.log(277, balance);
  const [Formerror, setFormerror] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  useEffect(() => {
    console.log(Formerror);
    if (Object.keys(Formerror).length == 0 && isSubmit) {
      console.log(Data);
    }
  }, [Formerror]);
  const validate = (values) => {
    const error = {};
    if (!values.title) {
      error.title = " Title is required";
    }
    if (!values.selectType) {
      error.selectType = " Select type is required";
    }
    if (!values.FullNameEnglish) {
      error.FullNameEnglish = " FullName  Is required";
    }
    if (!values.shopNameEnglish) {
      error.shopNameEnglish = " shop Name  is required";
    }
    if (!values.email) {
      error.email = " mobileNo is required";
    }
    if (!values.mobileNumber_registered_WithAdharCard) {
      error.mobileNumber_registered_WithAdharCard =
        " Mobile Number_registered_With AdharCard is required";
    }
    if (!values.gender) {
      error.gender = " gender is required";
    }
    if (!values.dob) {
      error.dob = " dob is required";
    }
    console.log("error object", error);
    return error;
  };

  const FileValidate = (Val) => {
    let fileError = {};
    if (!Val.shopact_ownerPassportPhoto) {
      fileError.shopact_ownerPassportPhoto = "File is Required";
    } else {
      if (Val.shopact_ownerPassportPhoto.size > 1024 * 1024) {
        fileError.shopact_ownerPassportPhoto =
          " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.shopact_ownerSignatureAsPer_PAN) {
      fileError.shopact_ownerSignatureAsPer_PAN = "File is Required";
    } else {
      if (Val.shopact_ownerSignatureAsPer_PAN.size > 1024 * 1024) {
        fileError.shopact_ownerSignatureAsPer_PAN =
          " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.shopact_adharCard) {
      fileError.shopact_adharCard = "File is Required";
    } else {
      if (Val.shopact_adharCard.size > 1024 * 1024) {
        fileError.shopact_adharCard = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.shopact_shopPhotograph) {
      fileError.shopact_shopPhotograph = "File is Required";
    } else {
      if (Val.shopact_shopPhotograph.size > 1024 * 1024) {
        fileError.shopact_shopPhotograph =
          " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.shopact_selfDeclaration) {
      fileError.shopact_selfDeclaration = "File is Required";
    } else {
      if (Val.shopact_selfDeclaration.size > 1024 * 1024) {
        fileError.shopact_selfDeclaration =
          " File size exceeds the limit of 1MB";
      }
    }
    console.log("error object", fileError);
    return fileError;
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userResponse"));
    if (userData) {
      axios.get(`https://api.maharashtraseva.com/api/recharge`).then((res) => {
        console.log(88, res.data);

        const allData = res.data.filter(
          (ele) => ele.user === userData.user._id
        );
        let amount = 0;
        let expence = 0;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.isExpence === "true") {
              expence += item.amount;
            } else {
              amount += item.amount;
            }
          });
        }
        setBalance(amount - expence);
      });
    }
  }, [reducer]);
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [Data, SetData] = useState({
    createdBy: userData.user._id,
    createdByName: userData.user?.name,
    application_type: "shopAct",
    status: "IN-PROGRESS",
    formPrice:"",
    selectType: "",
    title: "",
    oldShopActNo: "",
    FullNameEnglish: "",
    FullNameMarathi: "",
    residenceAddressEnglish: "",
    residenceAddressMarathi: "",
    shopNameEnglish: "",
    shopNameMarathi: "",
    natureOfBusinessEnglish: "",
    natureOfBusinessMarathi: "",
    Partner1_NameEnglish: "",
    Partner1_NameMarathi: "",
    shopAddress_with_PincodeEnglish: "",
    shopAddress_with_PincodeMarathi: "",
    mobileNo: "",
    email: "",
    adharNumber: "",
    mobileNumber_registered_WithAdharCard: "",
    gender: "",
    dob: "",
    residenceAddress_same_AadharAddress: "",

    no_of_years_staying_in_residenceAddress: "",

    IsShop_Rented: "",

    dateBusiness_Started: "",
    number_of_Employees: "",
    number_of_MenEmployees: "",
    number_of_WomenEmployees: "",
    Partner1_Name: "",
    Partner1_mobileNumber: "",
    Partner1_email: "",
    Partner1_address: "",
    Partner2_Name: "",
    Partner2_mobileNumber: "",
    Partner2_email: "",
    Partner2_address: "",
    Partner3_Name: "",
    Partner3_mobileNumber: "",
    Partner3_email: "",
    Partner3_address: "",
    Partner4_Name: "",
    Partner4_mobileNumber: "",
    Partner4_email: "",
    ownerPassport_photo: "",
    ownerSignatureAsPer_PAN: "",
    adharCard: "",
    shopPhotographFrom_FrontSide_WithBusinessBoard: "",
    selfDeclaration: "",
    ownerPANCard: "",
    oldShopAct_ForRenewal: "",
  });
  const handleDateChange = (date, dateString) => {
    SetData({ ...Data, dob: dateString });
  };
  const DateBissnessStarted = (date, dateString) => {
    SetData({ ...Data, dateBusiness_Started: dateString });
  };
  const [isVisible, setisVisible] = useState(false);

  console.log(25, userFormDetails);
  const [selectedRtoPricewe, setSelectedRtoPrice] = useState();
  const setFormPrice = (formType) => {
    switch (formType) {
      case "Shop Act new":
        {
          setSelectedRtoPrice(userFormDetails[0].shopActNew);
          setisVisible(true);
          SetData({ ...Data, selectType: formType,formPrice :userFormDetails[0].shopActNew });
        }
        break;
      case "Shop Act Renewl":
        {
          setSelectedRtoPrice(userFormDetails[0].shopActReNew);
          setisVisible(true);
          SetData({ ...Data, selectType: formType,formPrice:userFormDetails[0].shopActReNew });
        }
        break;
      default:
        break;
    }
  };
  const postLDJData = (e) => {
    e.preventDefault();
    const fileError = FileValidate(docs);
    const errors = validate(Data);
    console.log(299, errors);
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(fileError).length === 0
    ) {
      SetIsSubmit(true);
      if (balance > selectedRtoPricewe) {
        setShowloading(true);
        const mainDataPromise = new Promise((resolve, reject) => {
          // console.log(77, Data);
          axios
            .post(`https://api.maharashtraseva.com/api/shopact/create`, Data)
            .then((res) => {
              const response = res;
              resolve({
                status: true,
                message: "data Posted Successfully",
                data: res.data,
              });
            })
            .catch((err) => {
              console.log(err);
              setShowloading(false);
              reject({
                status: false,
                message: "Data Not posted",
              });
            });
        });

        mainDataPromise
          .then((res) => {
            // console.log(124,res.data)

            ownerPassport(res.data.data._id);
            ownerSignatureAsPer(res.data.data._id);
            adharCard(res.data.data._id);
            shopPhotographFrom(res.data.data._id);
            selfDeclaration(res.data.data._id);
            ownerPANCard(res.data.data._id);
            oldShopAct(res.data.data._id);
            debitFormBalance();
            SetData({...Data,
              selectType: "",
              title: "",
              oldShopActNo: "",
              FullNameEnglish: "",
              FullNameMarathi: "",
              residenceAddressEnglish: "",
              residenceAddressMarathi: "",
              shopNameEnglish: "",
              shopNameMarathi: "",
              natureOfBusinessEnglish: "",
              natureOfBusinessMarathi: "",
              Partner1_NameEnglish: "",
              Partner1_NameMarathi: "",
              shopAddress_with_PincodeEnglish: "",
              shopAddress_with_PincodeMarathi: "",
              mobileNo: "",
              email: "",
              adharNumber: "",
              mobileNumber_registered_WithAdharCard: "",
              gender: "",
              dob: "",
              residenceAddress_same_AadharAddress: "",
          
              no_of_years_staying_in_residenceAddress: "",
          
              IsShop_Rented: "",
          
              dateBusiness_Started: "",
              number_of_Employees: "",
              number_of_MenEmployees: "",
              number_of_WomenEmployees: "",
              Partner1_Name: "",
              Partner1_mobileNumber: "",
              Partner1_email: "",
              Partner1_address: "",
              Partner2_Name: "",
              Partner2_mobileNumber: "",
              Partner2_email: "",
              Partner2_address: "",
              Partner3_Name: "",
              Partner3_mobileNumber: "",
              Partner3_email: "",
              Partner3_address: "",
              Partner4_Name: "",
              Partner4_mobileNumber: "",
              Partner4_email: "",
              ownerPassport_photo: "",
              ownerSignatureAsPer_PAN: "",
              adharCard: "",
              shopPhotographFrom_FrontSide_WithBusinessBoard: "",
              selfDeclaration: "",
              ownerPANCard: "",
              oldShopAct_ForRenewal: "",
            })
            setShowloading(false);
          }).then(()=>{navigate("/RetailerDashboard")})
            
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error("Please Recharge");
      }
    } else {
      setFormerror(errors);
      setFormFileerror(fileError);
      swal("Filled the required form");
    }
  };
  const obj = {
    user: userData?.user._id,
    mode: "offline",
    amount: selectedRtoPricewe,
    isExpence: true,
    expenceFor: "ShopAct",
  };
  console.log(146, obj);
  const debitFormBalance = () => {
    axios
      .post("https://api.maharashtraseva.com/api/recharge/create", obj)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());
        swal("Good job!", "form submitted successfully!", "success");
      })
      .catch((err) => console.log(34, err));
  };
  const ownerPassport = (id) => {
    const formData = new FormData();
    formData.append("ownerPassport_photo", docs.shopact_ownerPassportPhoto);
    axios
      .put(
        `https://api.maharashtraseva.com/api/shopact_ownerPassportPhoto/${id}`,
        formData
      )
      .then((res) => console.log("addressUploaded", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const ownerSignatureAsPer = (id) => {
    console.log(143, id);
    const formData = new FormData();
    formData.append(
      "ownerSignatureAsPer_PAN",
      docs.shopact_ownerSignatureAsPer_PAN
    );
    axios
      .put(
        `https://api.maharashtraseva.com/api/shopact_ownerSignatureAsPer_PAN/${id}`,
        formData
      )
      .then((res) => console.log("ageProofUploaded", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const adharCard = (id) => {
    const formData = new FormData();
    formData.append("adharCard", docs.shopact_adharCard);
    axios
      .put(`https://api.maharashtraseva.com/api/shopact_adharCard/${id}`, formData)
      .then((res) => console.log("passportSizeUploadaed", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const shopPhotographFrom = (id) => {
    const formData = new FormData();
    formData.append(
      "shopPhotographFrom_FrontSide_WithBusinessBoard",
      docs.shopact_shopPhotograph
    );
    axios
      .put(`https://api.maharashtraseva.com/api/shopact_shopPhotograph/${id}`, formData)
      .then((res) => console.log("ageDeclarationForm Uploaded", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const selfDeclaration = (id) => {
    const formData = new FormData();
    formData.append("selfDeclaration", docs.shopact_selfDeclaration);
    axios
      .put(`https://api.maharashtraseva.com/api/shopact_selfDeclaration/${id}`, formData)
      .then((res) => console.log("ageDeclarationForm Uploaded", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const ownerPANCard = (id) => {
    const formData = new FormData();
    formData.append("ownerPANCard", docs.shopact_ownerPANCard);
    axios
      .put(`https://api.maharashtraseva.com/api/shopact_ownerPANCard/${id}`, formData)
      .then((res) => console.log("ageDeclarationForm Uploaded", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const oldShopAct = (id) => {
    const formData = new FormData();
    formData.append("oldShopAct_ForRenewal", docs.shopact_oldShopActForRenewal);
    axios
      .put(
        `https://api.maharashtraseva.com/api/shopact_oldShopActForRenewal/${id}`,
        formData
      )
      .then((res) => console.log("ageDeclarationForm Uploaded", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (shopid != undefined) {
      axios.get(`https://api.maharashtraseva.com/api/shopact/${shopid}`).then((res) => {
        const data = res.data[0];

        console.log("data", data);
        SetData(data);
        if (
          data?.isNew == true &&
          data?.status == "REJECTED" &&
          userData.user.role === "retailer"
        ) {
          axios
            .put(`https://api.maharashtraseva.com/api/shopact/${shopid}`, { isNew: false })
            .then((res) => {
              dispatch(incNumber());
            })
            .catch((err) => console.log(err));
        }
      });
    }
     
  }, [shopid]);

  const editHandler = (e) => {
    e.preventDefault();

    const obj = {
      ...Data,
      createdBy: userData.user._id,
      createdByName: userData.user?.name,
      application_type: "shopAct",
      status: "IN-PROGRESS",
    };

    const mainDataPromise = new Promise((resolve, reject) => {
      axios
        .put(`https://api.maharashtraseva.com/api/shopact/${shopid}`, obj)
        .then((res) => {
          const response = res;
          resolve({
            status: true,
            message: "data Updated Successfully",
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            status: false,
            message: "Data Not posted",
          });
        });
    });

    mainDataPromise
      .then((res) => {
        // console.log(124,res.data)

        ownerPassport(res.data._id);
        ownerSignatureAsPer(res.data._id);
        adharCard(res.data._id);
        shopPhotographFrom(res.data._id);
        selfDeclaration(res.data._id);
        ownerPANCard(res.data._id);
        oldShopAct(res.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const translateName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.FullNameEnglish,
      })
      .then((res) => {
        SetData({ ...Data, FullNameMarathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };
  const translateBussnessName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.shopNameEnglish,
      })
      .then((res) => {
        SetData({ ...Data, shopNameMarathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };
  const translateResAdd = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.residenceAddressEnglish,
      })
      .then((res) => {
        SetData({ ...Data, residenceAddressMarathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };
  const translateShopAdd = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.shopAddress_with_PincodeEnglish,
      })
      .then((res) => {
        SetData({ ...Data, shopAddress_with_PincodeMarathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };
  const translateNatureOFBussness = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.natureOfBusinessEnglish,
      })
      .then((res) => {
        SetData({ ...Data, natureOfBusinessMarathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };
  const TranslatePartner1Name = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.Partner1_NameEnglish,
      })
      .then((res) => {
        SetData({ ...Data, Partner1_NameMarathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };
  const clickHandler = () => {
    translateName();
  };
  const clickHandler1 = () => {
    translateBussnessName();
  };
  const clickHandler2 = () => {
    translateResAdd();
  };

  const clickHandler3 = () => {
    translateShopAdd();
  };
  const clickHandler4 = () => {
    translateNatureOFBussness();
  };
  const clickHandler5 = () => {
    TranslatePartner1Name();
  };

  return (
    <Fragment>
      <Breadcrumb title={"Shop Act Form"} />
      <Container fluid={true}>
        {isVisible && <h3>{` Form Price :${selectedRtoPricewe}`}</h3>}

        <Row>
          <Col sm="">
            <Card>
              <CardBody>
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Select Type
                      </label>
                      <Form.Item>
                        <Select
                          placeholder="Select Type OF Cast!"
                          onChange={(e) => {
                            if (e == "Shop Act Renewl") {
                              setOldShopAct(true);
                            } else {
                              setOldShopAct(false);
                            }
                            SetData({ ...Data, selectType: e });
                            setFormPrice(e);
                          }}
                        >
                          <Select.Option value="Shop Act new">
                            Shop Act New
                          </Select.Option>
                          <Select.Option value="Shop Act Renewl">
                            Shop Act Renew
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    {ShowOldShopAct && (
                      <>
                        <div className="col-md-4">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {" "}
                            <span className="red">*</span>{" "}
                            Old Shop Act Number
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input your middle Name!",
                              },
                            ]}
                          >
                            <Input
                        className="p-2"
                              placeholder="Please input your old Shop Act No !"
                              value={Data.oldShopActNo}
                              onChange={(e) => {
                                SetData({
                                  ...Data,
                                  oldShopActNo: e.target.value,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Title
                      </label>

                      <Form.Item>
                        <Select
                          placeholder="Select Type!"
                          value={Data.title}
                          onChange={(e) => {
                            SetData({ ...Data, title: e });
                          }}
                        >
                          <Select.Option  value="">--Select--</Select.Option>
                          <Select.Option value="Mr.">Mr.</Select.Option>
                          <Select.Option value="Mrs.">Mrs.</Select.Option>
                          <Select.Option value="Ms.">Ms.</Select.Option>
                        </Select>{" "}
                        <p className="red">{Formerror.title}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Full Name (English)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="Please input your First Name!"
                          value={Data.FullNameEnglish}
                          onChange={(e) =>
                            SetData({
                              ...Data,
                              FullNameEnglish: e.target.value,
                            })
                          }
                          onBlur={clickHandler}
                        />
                        <p className="red">{Formerror.FullNameEnglish}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Full Name (Marathi)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your middle Name!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="Please input your full Name marathi !"
                          value={Data.FullNameMarathi}

                          onChange={(e) => {
                            SetData({ ...Data, FullNameMarathi: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.FullNameMarathi}</p>
                      </Form.Item>
                    </div>

                    

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          type="Number"
                          placeholder="Please Enter your Mobile Number!"
                          value={Data.mobileNo}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 10) {
                              SetData({
                                ...Data,
                                mobileNo: sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                              SetData({
                                ...Data,
                                mobileNo: "", // Reset the adharNumber if it's too long
                              });
                            }
                            // SetData({ ...Data, mobileNo: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.mobileNo}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Email
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="PleasePlease Enter Your Email!"
                          value={Data.email}
                          onChange={(e) => {
                            SetData({ ...Data, email: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.email}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Aadhar Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          type="Number"
                          placeholder="Please input yourAadhar Number!"
                          value={Data.adharNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 12) {
                              SetData({
                                ...Data,
                                adharNumber: sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Adhar Number");
                              SetData({
                                ...Data,
                                adharNumber: "", // Reset the adharNumber if it's too long
                              });
                            }
                          }}
                        />
                        <p className="red">{Formerror.adharNumber}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Mobile number registered with Aadhar card?
                      </label>

                      <Form.Item>
                        <Radio.Group
                          value={Data.mobileNumber_registered_WithAdharCard}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              mobileNumber_registered_WithAdharCard:
                                e.target.value,
                            });
                          }}
                        >
                          <Radio value="yes">yes</Radio>
                          <Radio value="no">No</Radio>
                        </Radio.Group>
                        <p className="red">
                          {Formerror.mobileNumber_registered_WithAdharCard}
                        </p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Gender
                      </label>

                      <Form.Item>
                        <Radio.Group
                          value={Data.gender}
                          onChange={(e) => {
                            SetData({ ...Data, gender: e.target.value });
                          }}
                        >
                          <Radio value="male">Male</Radio>
                          <Radio value="female">Female</Radio>
                          <Radio value="others">Others</Radio>
                          <p className="red">{Formerror.gender}</p>
                        </Radio.Group>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Date Of Birth
                      </label>

                      <Form.Item>
                        <DatePicker
                          placeholder="Date Of Birth"
                          onChange={handleDateChange}
                        />
                        <p className="red">{Formerror.dob}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Residence Address(English)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Enter Residence Address English"
                          value={Data.residenceAddressEnglish}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              residenceAddressEnglish: e.target.value,
                            });
                          }}
                          onBlur={clickHandler2}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Residence Address(marathi)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Enter Residence Address"
                          value={Data.residenceAddressMarathi}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              residenceAddressMarathi: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        No. of years staying in Residence Address*
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          type="Number"
                          placeholder="No. of years staying in Residence Address"
                          value={Data.no_of_years_staying_in_residenceAddress}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              no_of_years_staying_in_residenceAddress:
                                e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Shop/Business Name (English)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="Enter Shop/Business Name"
                          value={Data.shopNameEnglish}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              shopNameEnglish: e.target.value,
                            });
                          }}
                          // ononBlur={clickHandler1}
                          onBlur={clickHandler1}
                        />
                        <p className="red">{Formerror.shopNameEnglish}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Shop/Business Name (Marathi)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your middle Name!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="Shop/Business Name (Marathi)!"
                          value={Data.shopNameMarathi}

                          onChange={(e) => {
                            SetData({ ...Data, shopNameMarathi: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.shopNameMarathi}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Shop/Business Nature(English)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Enter Shop/Business Nature"
                          value={Data.natureOfBusinessEnglish}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              natureOfBusinessEnglish: e.target.value,
                            });
                          }}
                          onBlur={clickHandler4}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Shop/Business Nature(Marathi)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Enter Shop/Business Nature"
                          value={Data.natureOfBusinessMarathi}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              natureOfBusinessMarathi: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Shop Address with Pincode(English) 
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Enter Shop Address with Pincode "
                          value={Data.shopAddress_with_PincodeEnglish}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              shopAddress_with_PincodeEnglish: e.target.value,
                            });
                          }}
                          onBlur={clickHandler3}
                        />
                        <p className="red">
                          {Formerror.shopAddress_with_PincodeEnglish}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Shop Address with Pincode(Marathi) 
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Enter Shop Address with Pincode "
                          value={Data.shopAddress_with_PincodeMarathi}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              shopAddress_with_PincodeMarathi: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Is Shop Rented?*
                      </label>

                      <Form.Item>
                        <Radio.Group
                          value={Data.IsShop_Rented}
                          onChange={(e) => {
                            SetData({ ...Data, IsShop_Rented: e.target.value });
                          }}
                        >
                          <Radio value="Yes">Yes</Radio>
                          <Radio value="No">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Date Business was Started
                      </label>

                      <Form.Item>
                        <DatePicker
                          placeholder="Date Of Birth"
                          onChange={DateBissnessStarted}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Number of Employees 
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your Full Name as per Records!",
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          type="Number"
                          placeholder="Enter Number of Employees *"
                          value={Data.number_of_Employees}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              number_of_Employees: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Number of Men Employees
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                          placeholder="Number of Men Employees"
                          value={Data.number_of_MenEmployees}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              number_of_MenEmployees: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Number of Women Employees
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                        placeholder="Number of Women Employees"
                          value={Data.number_of_WomenEmployees}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              number_of_WomenEmployees: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 1 Name (English)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        className="p-2"
                        placeholder="Partner 1 Name (English)"
                          value={Data.Partner1_NameEnglish}
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner1_NameEnglish: e.target.value,
                            });
                          }}
                          onBlur={clickHandler5}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 1 Name(Marathi)
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 1 Name(Marathi)"
                        className="p-2" value={Data.Partner1_NameMarathi} />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 1 Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 1 Mobile Number"
                        className="p-2"
                          type="Number"
                          value={Data.Partner1_mobileNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 10) {
                              SetData({
                                ...Data,
                                Partner1_mobileNumber: sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                              SetData({
                                ...Data,
                                Partner1_mobileNumber: "", // Reset the adharNumber if it's too long
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 1 Email
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 1 Email"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner1_email: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 1 Address
                      </label>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 1 Address"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner1_address: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 2 Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 2 Name"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner2_Name: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 2 Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 2 Mobile Number"
                        className="p-2"
                          type="Number"
                          value={Data.Partner2_mobileNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 10) {
                              SetData({
                                ...Data,
                                Partner2_mobileNumber: sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                              SetData({
                                ...Data,
                                Partner2_mobileNumber: "", // Reset the adharNumber if it's too long
                              });
                            }
                            // SetData({
                            //   ...Data,
                            //   Partner2_mobileNumber: e.target.value,
                            // });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 2 Email
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 2 Email"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner2_email: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 2 Address
                      </label>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 2 Address"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner2_address: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 3 Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 3 Name"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner3_Name: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 3 Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 3 Mobile Number"
                        className="p-2"
                          type="Number"
                          value={Data.Partner3_mobileNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 10) {
                              SetData({
                                ...Data,
                                Partner3_mobileNumber: sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                              SetData({
                                ...Data,
                                Partner3_mobileNumber: "", // Reset the adharNumber if it's too long
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 3 Email
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 3 Email"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner3_email: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 3 Address
                      </label>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 3 Address"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner3_address: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 4 Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 4 Name"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner4_Name: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 4 Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 4 Mobile Number"
                        className="p-2"
                          type="Number"
                          value={Data.Partner4_mobileNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 12) {
                              SetData({
                                ...Data,
                                Partner4_mobileNumber: sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                              SetData({
                                ...Data,
                                Partner4_mobileNumber: "", // Reset the adharNumber if it's too long
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 4 Email
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 4 Email"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner4_email: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Partner 4 Address
                      </label>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        placeholder="Partner 4 Address"
                        className="p-2"
                          onChange={(e) => {
                            SetData({
                              ...Data,
                              Partner4_address: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <a target="_blank" href="https://mhae-seva.s3.amazonaws.com/dcleration/Self_Declaration_Form+(1).pdf">Self Declearation</a> <br />
                        <a target="_blank" href="https://mhae-seva.s3.amazonaws.com/dcleration/shop+act+sample.pdf">Shop Act Sample Form</a>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <h5>Upload required documents: </h5>
                      <h6 className="red">
                        अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व आवश्यक
                        कागदपत्रे तपासून व स्कॅन करून अपलोड करावे. जर आवश्यक
                        कागदपत्रे चुकीची किंवा अस्पष्ट आढळल्यास सदर चा अर्ज
                        फेटाळला जाऊ शकतो.{" "}
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Owner Passport photo (Size
                        - Maximum 5 Kb to 20 Kb (photo length 160 pixels * width
                        200 pixels)) [Only (jpg,jpeg)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            shopact_ownerPassportPhoto: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">
                        {FormFileerror.shopact_ownerPassportPhoto}
                      </p>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Owner Signature As Per PAN
                        (Size - Maximum 5 Kb to 20 Kb (photo length 256 pixels *
                        wide 64)) [Only (jpg,jpeg)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            shopact_ownerSignatureAsPer_PAN: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">
                        {FormFileerror.shopact_ownerSignatureAsPer_PAN}
                      </p>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Aadhar Card (Size - Maximum 75 Kb to 100 Kb) [Only
                        (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            shopact_adharCard: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.shopact_adharCard}</p>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Shop Photograph From Front Side With Business Board
                        (Size - Maximum 75 Kb to 100 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            shopact_shopPhotograph: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">
                        {FormFileerror.shopact_shopPhotograph}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Self Declaration (Size - Maximum 1 MB) [Only
                        (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            shopact_selfDeclaration: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">
                        {FormFileerror.shopact_selfDeclaration}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Owner PAN Card (Size-Maximum 75 Kb to 100 Kb) [Only
                        (jpg,jpeg)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            shopact_ownerPANCard: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Old Shop Act For Renewal (Min 60 Days Before Of Expiry)
                        (Size - Maximum 75 Kb to 100 Kb) [Only (jpg,jpeg)] ]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            shopact_oldShopActForRenewal: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row text-center mt-5">
                    {
                    showloading ? (
                      <>
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) :
                    shopid == undefined ? (
                      <div className="col-md-12">
                        <Form.Item>
                          <Button
                           type="primary"
                            onClick={(e) => {
                              postLDJData(e);
                            }}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </div>
                    ) : (
                      <div className="col-md-12">
                        <Form.Item>
                          <Button
                          type="primary"
                            onClick={(e) => {
                              editHandler(e);
                            }}
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ShopActForm;
