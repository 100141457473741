import React from "react";
import axios from "axios";
import { MdUploadFile } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { toast } from "react-toastify";
import { incNumber } from "../../Redux/actions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import TextArea from "antd/es/input/TextArea";
import swal from "sweetalert";

const Egazette = () => {
  const [form] = Form.useForm();
  const [showloading, setShowloading] = useState(false);
  const { eid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.changeNumber);

  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const pricess = useSelector((state) => state.price.formPrices);
  const [FormFileerror, setFormFileerror] = useState({});
  const [userFormDetails, setuserFormDetails] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/formPrice`)
      .then((res) => {
        const response = res.data;
        const filtered = response.filter(
          (item) => item.userID == userData.user._id
        );
        console.log(50, filtered);
        if (filtered.length == 0) {
          const userFormDetails = response.filter(
            (item) => item.userID === "ALL"
          );
          setuserFormDetails(userFormDetails);
        } else {
          const userFormDetails = response.filter(
            (item) => item.userID === userData.user._id
          );
          setuserFormDetails(userFormDetails);
        }
      })
      .catch((err) => console.log(40, err));
  }, []);

  const [balance, setBalance] = useState(0);
  const [isVisible, setisVisible] = useState(false);

  const [selectedRtoPricewe, setSelectedRtoPrice] = useState(0);
  const [docs, setDocs] = useState({
    photoID: "",
    addressProof: "",
    applicationForm: "",
    identityProof: "",
    Passport_Size_Photo: "",
    OldName_Proof: "",
    PresentName_Proof: "",
    Cast_Certificate: "",
  });

  const [Data, setData] = useState({
    createdBy: userData.user._id,
    createdByName: userData.user?.name,
    application_type: "e_gazzet",
    status: "IN-PROGRESS",
    service_type: "",
    type_cast: "",
    title: "",
    firstName: "",
    middleName: "",
    formPrice: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    adharNumber: "",
    mobileNumber_registered_with_adharCard: "",
    gender: "",
    Dob: "",
    Old_Dob: "",
    New_Dob: "",
    OldName: "",
    NewName: "",
    reason: "",
    address: "",
  });
  const setFormPrice = (formType) => {
    // setData({ ...Data, type_cast: formType });
    switch (formType) {
      case "Open/OBC":
        {
          setSelectedRtoPrice(userFormDetails[0].eGazette_OpenOBC);
          setisVisible(true);
          setData({ ...Data, formPrice: userFormDetails[0].eGazette_OpenOBC, type_cast: formType });
        }
        break;
      case "SC":
        {
          setSelectedRtoPrice(userFormDetails[0].eGazette_SC);
          setisVisible(true);
          setData({ ...Data, formPrice: userFormDetails[0].eGazette_SC, type_cast: formType });
        }
        break;
      case "SBC":
        {
          setSelectedRtoPrice(userFormDetails[0].eGazette_SBC);
          setisVisible(true);
          setData({ ...Data, formPrice: userFormDetails[0].eGazette_SBC , type_cast: formType});
        }
        break;

      case "ST":
        {
          setSelectedRtoPrice(userFormDetails[0].eGazette_ST);
          setisVisible(true);
          setData({ ...Data, formPrice: userFormDetails[0].eGazette_ST , type_cast: formType});
        }
        break;
      case "VJ":
        {
          setSelectedRtoPrice(userFormDetails[0].eGazette_VJ);
          setisVisible(true);
          setData({ ...Data, formPrice: userFormDetails[0].eGazette_VJ , type_cast: formType});
        }
        break;
      case "NT":
        {
          setSelectedRtoPrice(userFormDetails[0].eGazette_NT);
          setisVisible(true);
          setData({ ...Data, formPrice: userFormDetails[0].eGazette_NT, type_cast: formType });
        }

        break;
      default:
        break;
    }
  };
  console.log(143, Data);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userResponse"));
    if (userData) {
      axios.get(`https://api.maharashtraseva.com/api/recharge`).then((res) => {
        const allData = res.data.filter(
          (ele) => ele.user === userData.user._id
        );
        let amount = 0;
        let expence = 0;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.isExpence == "true") {
              expence += item.amount;
            } else {
              amount += item.amount;
            }
          });
        }
        setBalance(amount - expence);
      });
    }
  }, [reducer]);

  const handleDateOfBirth = (date, dateString) => {
    setData({ ...Data, Dob: dateString });
  };
  const handleoldDateOfBirth = (date, dateString) => {
    setData({ ...Data, Old_Dob: dateString });
  };
  const handleNewDateOfBirth = (date, dateString) => {
    setData({ ...Data, New_Dob: dateString });
  };
  const [DOBChange, setDOBChange] = useState(false);
  const [NameChange, setNameChange] = useState(false);
  const EGazetteFormSubmit = (e) => {
    e.preventDefault();
    const errors = validate(Data);
    const fileErrors = FileValidate(docs);
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(fileErrors).length === 0
    ) {
      console.log(
        "🚀 ~ file: egazette.js:189 ~ EGazetteFormSubmit ~ obj.Dḁta:",
        Data
      );
      setShowloading(true);
      SetIsSubmit(true);
      const obj = {
        ...Data,
        formPrice: selectedRtoPricewe,
      };
      if (balance > selectedRtoPricewe) {
        const mainDataPromise = new Promise((resolve, reject) => {
          // console.log(77, Data);
          axios
            .post(`https://api.maharashtraseva.com/api/e_gazzet/create`, Data)
            .then((res) => {
              const response = res;
              resolve({
                status: true,
                message: "data Posted Successfully",
                data: res.data,
              });
            })
            .catch((err) => {
              console.log(err);
              setShowloading(false);
              reject({
                status: false,
                message: "Data Not posted",
              });
            });
        });

        mainDataPromise
          .then((res) => {
            console.log(124, res.data);

            uploadphotoID(res.data.data._id);
            uploadaddressProof(res.data.data._id);
            uploadCast_Certificate(res.data.data._id);
            uploadapplicationForm(res.data.data._id);
            uploadidentityProof(res.data.data._id);
            uploadPassport_Size_Photo(res.data.data._id);
            uploadOldName_Proof(res.data.data._id);
            uploadPresentName_Proof(res.data.data._id);
            debitFormBalance();
            setData({
              ...Data,
              service_type: "",
              type_cast: "",
              title: "",
              firstName: "",
              middleName: "",
              lastName: "",
              mobileNumber: "",
              email: "",
              adharNumber: "",
              mobileNumber_registered_with_adharCard: "",
              gender: "",
              Dob: "",
              Old_Dob: "",
              New_Dob: "",
              OldName: "",
              NewName: "",
              reason: "",
              address: "",
            });
            setDocs({
              ...docs,
              photoID: "",
              addressProof: "",
              applicationForm: "",
              identityProof: "",
              Passport_Size_Photo: "",
              OldName_Proof: "",
              PresentName_Proof: "",
              Cast_Certificate: "",
            });
            dispatch(incNumber());
          })
          .then((res) => {
            setShowloading(false);
            navigate("/retailer_console");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error("Please Recharge");
      }
    } else {
      setFormerror(errors);
      setFormFileerror(fileErrors);
      swal("Filled the required form");
    }
  };

  const debitFormBalance = () => {
    const obj = {
      user: userData?.user._id,
      mode: "offline",
      amount: selectedRtoPricewe,
      isExpence: true,
      expenceFor: "eGazzet",
    };
    axios
      .post("https://api.maharashtraseva.com/api/recharge/create", obj)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());
        swal("Good job!", "form submitted successfully!", "success");
      })
      .catch((err) => console.log(34, err));
  };
  const uploadphotoID = (id) => {
    const formData = new FormData();
    formData.append("photoID", docs.photoID);
    axios
      .put(`https://api.maharashtraseva.com/api/gazzet_photoID/${id}`, formData)
      .then((res) => console.log("gazzet_photoID", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadaddressProof = (id) => {
    const formData = new FormData();
    formData.append("addressProof", docs.addressProof);
    axios
      .put(`https://api.maharashtraseva.com/api/gazzet_addressProof/${id}`, formData)
      .then((res) => console.log("addressProof", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadCast_Certificate = (id) => {
    const formData = new FormData();
    formData.append("Cast_Certificate", docs.Cast_Certificate);
    axios
      .put(`https://api.maharashtraseva.com/api/gazzet_CastCertificate/${id}`, formData)
      .then((res) => console.log("Cast_Certificate", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadapplicationForm = (id) => {
    const formData = new FormData();
    formData.append("applicationForm", docs.applicationForm);
    axios
      .put(`https://api.maharashtraseva.com/api/gazzet_applicationForm/${id}`, formData)
      .then((res) => console.log("applicationForm", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadidentityProof = (id) => {
    const formData = new FormData();
    formData.append("identityProof", docs.identityProof);
    axios
      .put(`https://api.maharashtraseva.com/api/gazzet_identityProof/${id}`, formData)
      .then((res) => console.log("identityProof", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadPassport_Size_Photo = (id) => {
    const formData = new FormData();
    formData.append("Passport_Size_Photo", docs.Passport_Size_Photo);
    axios
      .put(`https://api.maharashtraseva.com/api/gazzet_PassportSizePhoto/${id}`, formData)
      .then((res) => console.log("Passport_Size_Photo", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadOldName_Proof = (id) => {
    const formData = new FormData();
    formData.append("OldName_Proof", docs.OldName_Proof);
    axios
      .put(`https://api.maharashtraseva.com/api/gazzet_OldNameProof/${id}`, formData)
      .then((res) => console.log("OldName_Proof", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadPresentName_Proof = (id) => {
    const formData = new FormData();
    formData.append("PresentName_Proof", docs.PresentName_Proof);
    axios
      .put(`https://api.maharashtraseva.com/api/gazzet_PresentNameProof/${id}`, formData)
      .then((res) => console.log("PresentName_Proof", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const [Formerror, setFormerror] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  useEffect(() => {
    console.log(Formerror);
    if (Object.keys(Formerror).length == 0 && isSubmit) {
      console.log(Data);
    }
  }, [Formerror]);
  const validate = (values) => {
    const error = {};
    if (!values.service_type) {
      error.service_type = " service type is required";
    }
    if (!values.type_cast) {
      error.type_cast = " Type Cast is required";
    }
    if (!values.title) {
      error.title = " Title Is required";
    }
    if (!values.firstName) {
      error.firstName = " First Name Is required";
    }
    if (!values.middleName) {
      error.middleName = " Middle  Name Is required";
    }
    if (!values.lastName) {
      error.lastName = " Last  Name Is required";
    }
    if (!values.mobileNumber) {
      error.mobileNumber = "Mobile Number Is required";
    }
    if (!values.email) {
      error.email = "Email Is required";
    }
    if (!values.adharNumber) {
      error.adharNumber = "Adhar Number Is required";
    }

    if (!values.gender) {
      error.gender = "Gender Is required";
    }
    if (!values.Dob) {
      error.Dob = "Dob Is required";
    }
    if (!values.address) {
      error.address = "Address Is required";
    }
    if (!values.reason) {
      error.reason = "Reason Is required";
    }

    console.log("error object", error);
    return error;
  };
  const FileValidate = (Val) => {
    let fileError = {};
    if (!Val.photoID) {
      fileError.photoID = "File is Required";
    } else {
      if (Val.photoID.size > 1024 * 1024) {
        fileError.photoID = " File size exceeds the limit of 1MB";
      }
    }
    if (!Val.addressProof) {
      fileError.addressProof = "File is Required";
    } else {
      if (Val.addressProof.size > 1024 * 1024) {
        fileError.addressProof = " File size exceeds the limit of 1MB";
      }
    }
    // if (!Val.applicationForm) {
    //   fileError.applicationForm = "File is Required";
    // } else {
    //   if (Val.applicationForm.size > 1024 * 1024) {
    //     fileError.applicationForm = " File size exceeds the limit of 1MB";
    //   }
    // }
    // if (!Val.identityProof) {
    //   fileError.identityProof = "File is Required";
    // } else {
    //   if (Val.identityProof.size > 1024 * 1024) {
    //     fileError.identityProof = " File size exceeds the limit of 1MB";
    //   }
    // }
    // if (!Val.Passport_Size_Photo) {
    //   fileError.Passport_Size_Photo = "File is Required";
    // } else {
    //   if (Val.Passport_Size_Photo.size > 1024 * 1024) {
    //     fileError.Passport_Size_Photo = " File size exceeds the limit of 1MB";
    //   }
    // }
    // if (!Val.OldName_Proof) {
    //   fileError.OldName_Proof = "File is Required";
    // } else {
    //   if (Val.OldName_Proof.size > 1024 * 1024) {
    //     fileError.OldName_Proof = " File size exceeds the limit of 1MB";
    //   }
    // }
    // if (!Val.PresentName_Proof) {
    //   fileError.PresentName_Proof = "File is Required";
    // } else {
    //   if (Val.PresentName_Proof.size > 1024 * 1024) {
    //     fileError.PresentName_Proof = " File size exceeds the limit of 1MB";
    //   }
    // }
    // if (!Val.Cast_Certificate) {
    //   fileError.Cast_Certificate = "File is Required";
    // } else {
    //   if (Val.Cast_Certificate.size > 1024 * 1024) {
    //     fileError.Cast_Certificate = " File size exceeds the limit of 1MB";
    //   }
    // }

    console.log("error object", fileError);
    return fileError;
  };

  useEffect(() => {
    if (eid != undefined) {
      axios.get(`https://api.maharashtraseva.com/api/e_gazzet/${eid}`).then((res) => {
        const data = res.data[0];

        console.log("data", data);
        setData(data);
        if (
          data?.isNew == true &&
          data?.status == "REJECTED" &&
          userData.user.role === "retailer"
        ) {
          axios
            .put(`https://api.maharashtraseva.com/api/e_gazzet/${eid}`, { isNew: false })
            .then((res) => {
              dispatch(incNumber());
            })
            .catch((err) => console.log(err));
        }
      });
    }
  }, [eid]);

  const editHandler = (e) => {
    e.preventDefault();

    const obj = {
      ...Data,
      createdBy: userData.user._id,
      createdByName: userData.user?.name,
      application_type: "e_gazzet",
      status: "IN-PROGRESS",
    };

    const mainDataPromise = new Promise((resolve, reject) => {
      axios
        .put(`https://api.maharashtraseva.com/api/e_gazzet/${eid}`, obj)
        .then((res) => {
          const response = res;
          resolve({
            status: true,
            message: "data Updated Successfully",
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            status: false,
            message: "Data Not posted",
          });
        });
    });

    mainDataPromise
      .then((res) => {
        // console.log(124,res.data)

        uploadphotoID(res.data.data._id);
        uploadaddressProof(res.data.data._id);
        uploadCast_Certificate(res.data.data._id);
        uploadapplicationForm(res.data.data._id);
        uploadidentityProof(res.data.data._id);
        uploadPassport_Size_Photo(res.data.data._id);
        uploadOldName_Proof(res.data.data._id);
        uploadPresentName_Proof(res.data.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Breadcrumb title={`e-Gazette`} />
      <Container fluid={true}>
        {isVisible && (
          <h3 className="mx-5">{` Form Price :${selectedRtoPricewe}`}</h3>
        )}

        <Row>
          <Col sm="">
            <Card>
              <CardBody>
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Service Type
                      </label>
                      <Form.Item>
                        <Select
                          placeholder="Please input your Service Type"
                          value={Data.service_type}
                          // className="form-control form-control-lg"
                          onChange={(e) => {
                            setData({ ...Data, service_type: e });
                            if (e === "Name Change") {
                              setNameChange(true);
                              setDOBChange(false);
                            } else if (e === "DOB Change") {
                              setNameChange(false);
                              setDOBChange(true);
                            }
                          }}
                          showSearch
                        >
                          <Select.Option value="Name Change">
                            Name Change
                          </Select.Option>
                          <Select.Option value="DOB Change">
                            DOB Change
                          </Select.Option>
                        </Select>
                        <p className="red">{Formerror.service_type}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>{" "}
                        Select Type Of Caste
                      </label>

                      <Form.Item>
                        <Select
                          placeholder="Please input your Caste "
                          value={Data.type_cast}
                          onChange={(e) => {
                            // console.log(629,Data.type_cast);
                            // setData({ ...Data, type_cast: e });
                            setFormPrice(e);
                          }}
                          showSearch
                        >
                          <Select.Option value="">
                           ---Select ---
                          </Select.Option>
                          <Select.Option value="Open/OBC">
                            Open/OBC
                          </Select.Option>
                          <Select.Option value="SC">SC</Select.Option>
                          <Select.Option value="SBC">SBC</Select.Option>
                          <Select.Option value="ST">ST</Select.Option>
                          <Select.Option value="VJ">VJ</Select.Option>
                          <Select.Option value="NT">NT</Select.Option>
                        </Select>
                      </Form.Item>
                      <p className="red">{Formerror.type_cast}</p>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Title
                      </label>

                      <Form.Item>
                        <Select
                          placeholder="Select Title"
                          value={Data.title}
                          onChange={(e) => {
                            setData({ ...Data, title: e });
                          }}
                          showSearch
                        >
                          <Select.Option value="Mr">Mr</Select.Option>
                          <Select.Option value="Mrs">Mrs</Select.Option>
                          <Select.Option value="Ms">Ms</Select.Option>
                        </Select>
                        <p className="red">{Formerror.title}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        First Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your First Name!"
                          value={Data.firstName}
                          onChange={(e) => {
                            setData({ ...Data, firstName: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.firstName}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Middle Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your middle Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your middle Name"
                          value={Data.middleName}
                          onChange={(e) => {
                            setData({ ...Data, middleName: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.middleName}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                         Last Name
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Last Name!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your Last Name!"
                          value={Data.lastName}
                          onChange={(e) => {
                            setData({ ...Data, lastName: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.lastName}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Mobile Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please input your Mobile Number!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          type="Number"
                          placeholder="Please input your Mobile Number!"
                          value={Data.mobileNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove non-numeric characters
                            const sanitizedValue = value.replace(/\D/g, "");

                            // Check if the input is a valid phone number (exactly 10 digits)
                            if (sanitizedValue.length <= 10) {
                              setData({
                                ...Data,
                                mobileNumber: sanitizedValue,
                              });
                            } else {
                              alert("Please Enter Valid Phone Number");
                              setData({
                                ...Data,
                                mobileNumber: "", // Reset the adharNumber if it's too long
                              });
                            }
                            // setData({ ...Data, mobileNO: e.target.value });
                            // setData({ ...Data, mobileNumber: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.mobileNumber}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Email
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            // message: "Please input your Email!",
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          placeholder="Please input your Email!"
                          value={Data.email}
                          onChange={(e) => {
                            setData({ ...Data, email: e.target.value });
                          }}
                        />{" "}
                        <p className="red">{Formerror.email}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Adhar Number
                      </label>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            // message:"Please input your Adhar Number!"
                          },
                        ]}
                      >
                        <Input
                          className="p-2"
                          type="Number"
                          placeholder="Please input your Adhar Number!"
                          value={Data.adharNumber}
                          onChange={(e) => {
                            setData({ ...Data, adharNumber: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.adharNumber}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Mobile number registered with Aadhar card?
                      </label>

                      <Form.Item>
                        <Radio.Group
                          value={Data.mobileNumber_registered_with_adharCard}
                          onChange={(e) => {
                            setData({
                              ...Data,
                              mobileNumber_registered_with_adharCard:
                                e.target.value,
                            });
                          }}
                        >
                          <Radio value="yes">Yes</Radio>
                          <Radio value="No">NO</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Gender
                      </label>
                      <Form.Item>
                        <Radio.Group
                          onChange={(e) => {
                            setData({ ...Data, gender: e.target.value });
                          }}
                        >
                          <Radio value="male">Male</Radio>
                          <Radio value="female">Female</Radio>
                          <Radio value="others">Others</Radio>
                        </Radio.Group>
                        <p className="red">{Formerror.gender}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>
                        Date Of Birth
                      </label>

                      <Form.Item>
                        <DatePicker
                          placeholder="Date Of Birth"
                          onChange={handleDateOfBirth}
                        />
                        <p className="red">{Formerror.Dob}</p>
                      </Form.Item>
                    </div>

                    {DOBChange && (
                      <>
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            Old Date of Brith
                          </label>
                          <Form.Item>
                            <DatePicker
                              placeholder=" Old Date Of Birth"
                              onChange={handleoldDateOfBirth}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {" "}
                            New Date Of Birth
                          </label>

                          <Form.Item>
                            <DatePicker
                              placeholder="  New Date Of Birth"
                              onChange={handleNewDateOfBirth}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                    {NameChange && (
                      <>
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {" "}
                            Old Name
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input
                              className="p-2"
                              placeholder="Please input your old Name!"
                              value={Data.OldName}
                              onChange={(e) => {
                                setData({ ...Data, OldName: e.target.value });
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                            {" "}
                            New Name
                          </label>

                          <Form.Item
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input
                              className="p-2"
                              placeholder="Please input your new Name!"
                              value={Data.NewName}
                              onChange={(e) => {
                                setData({ ...Data, NewName: e.target.value });
                              }}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Reason
                      </label>

                      <Form.Item rules={[]}>
                        <TextArea
                          rows={4}
                          placeholder="Please input your Reason!"
                          onChange={(e) => {
                            setData({ ...Data, reason: e.target.value });
                          }}
                        />{" "}
                        <p className="red">{Formerror.reason}</p>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Address
                      </label>

                      <Form.Item rules={[]}>
                        <TextArea
                          rows={4}
                          placeholder="Please input your Address!"
                          value={Data.address}
                          onChange={(e) => {
                            setData({ ...Data, address: e.target.value });
                          }}
                        />
                        <p className="red">{Formerror.address}</p>
                      </Form.Item>
                    </div>

                    <div className="col-md-6" style={{ marginLeft: "793px" }}>
                      <h5>Forms: </h5>
                      <u>
                        
                          <a
                            target="_blank"
                            href="https://mhae-seva.s3.amazonaws.com/dcleration/Gazette_DOB_Change_Form_English.pdf"
                          >
                            {" "}
                            Gazette DOB Change Form - English
                          </a>
                        
                      </u>
                      <br />
                      <u>
                        
                          <a
                            target="_blank"
                            href="https://mhae-seva.s3.amazonaws.com/dcleration/Gazette_DOB_Change_Form_Marathi.pdf"
                          >
                            {" "}
                            Gazette DOB Change Form - Marathi
                          </a>
                        
                      </u>
                      <br />
                      <u>
                        
                          <a
                            target="_blank"
                            href="https://mhae-seva.s3.amazonaws.com/dcleration/Gazette_Name_Change_Form_English_+(2).pdf"
                          >
                            {" "}
                            Gazette Name Change Form - English
                          </a>
                        
                      </u>
                      <br />
                      <u>
                        
                          <a
                            target="_blank"
                            href="https://mhae-seva.s3.amazonaws.com/dcleration/Gazette_Name_Change_Form_Marathi.pdf"
                          >
                            {" "}
                            Gazette Name Change Form - Marathi
                          </a>
                        
                      </u>
                      <br />
                      <u>
                        
                          <a
                            target="_blank"
                            href="https://mhae-seva.s3.amazonaws.com/dcleration/gazette+sampleform.pdf"
                          >
                            {" "}
                            Sample Form
                          </a>
                        
                      </u>
                    </div>
                    <div className="col-lg-12 mt-5">
                      <h5 className="red">
                        अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व आवश्यक
                        कागदपत्रे तपासून व स्कॅन करून अपलोड करावे. जर आवश्यक
                        कागदपत्रे चुकीची किंवा अस्पष्ट आढळल्यास सदर चा अर्ज
                        फेटाळला जाऊ शकतो.{" "}
                      </h5>
                    </div>
                    <div className="col-md-12">
                      <h5>Upload required documents: </h5>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Photo ID (Pan card or
                        Passport or Driving Licence or Aadhar Card or Voters Id)
                        (Size - Maximum 75 Kb to 100 Kb (200 DPI)) [Only
                        (jpg,jpeg,pdf)]*
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            photoID: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">{FormFileerror.photoID}</p>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Address Proof (Ration Card
                        or Aadhar Card or Electricity Bill or Passport) (Size -
                        Maximum 75 Kb to 100 Kb (200 DPI)) [Only
                        (jpg,jpeg,pdf)]*
                      </label>

                      <input
                        type="file"
                        name=""
                        className="form-control"
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            addressProof: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">{FormFileerror.addressProof}</p>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        Application Form : Duly Signed Application Form with all
                        details (Size - Maximum 75 Kb to 100 Kb (200 DPI)) [Only
                        (jpg,jpeg,pdf)] *
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            applicationForm: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">{FormFileerror.applicationForm}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        Identity Proof (Size - Maximum 75 Kb to 100 Kb (200
                        DPI)) [Only (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            identityProof: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">{FormFileerror.identityProof}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        Passport Size Photo (Size - Maximum 5 Kb to 20 Kb (
                        resize Width 160 × Height 212) [Only (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            Passport_Size_Photo: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        Old Name Proof (Size - Maximum 75 Kb to 100 Kb (200
                        DPI)) [Only (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            OldName_Proof: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">{FormFileerror.OldName_Proof}</p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        Present Name Proof (Size - Maximum 75 Kb to 100 Kb (200
                        DPI)) [Only (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            PresentName_Proof: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">
                        {FormFileerror.PresentName_Proof}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        Cast Certificate (Size - Maximum 75 Kb to 100 Kb (200
                        DPI)) [Only (jpg,jpeg,pdf)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            Cast_Certificate: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red m-2">
                        {FormFileerror.Cast_Certificate}
                      </p>
                    </div>
                  </div>

                  <div className="row text-center mt-5">
                    {showloading ? (
                      <>
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : eid == undefined ? (
                      <div className="col-md-12">
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={(e) => {
                              EGazetteFormSubmit(e);
                            }}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </div>
                    ) : (
                      <div className="col-md-12">
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={(e) => {
                              editHandler(e);
                            }}
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Egazette;
