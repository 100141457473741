import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { AgGridReact } from "ag-grid-react";

function RechargeHistry() {
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));
  const [filter, setFilter] = useState();
  const [total, setTotal] = useState();
  const recharge = useSelector((state) => state.recharge.recharge);
  const [startDate, setStartDate] = useState(''); // State to store start date
  const [endDate, setEndDate] = useState(''); // State to store end date
  const [filteredData, setFilteredData] = useState([]); // State to store filtered data

  console.log(15, recharge);
  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/recharge`)
      .then((res) => {
        const response = res.data.reverse();
        console.log(17, response);
        if (userRespons.user.role == "admin") {
          const alldata = response.filter((item) => item.isExpence == "false");
          setFilter(alldata);
          // console.log(24,alldata)
          let credit=0;
          if (alldata.length > 0) {
            alldata.forEach((item) => {
              if (item.isExpence === "false") {
                console.log(1111,item)
                if (item.amount!=="" &&  item.amount!==undefined) {
                  console.log(31,item.amount)
                  credit += item.amount
                }
              }} 
            )
            setTotal(credit)
            console.log(32,credit)
          }
        } else if (userRespons.user.role == "retailer") {
          const retailerData = response.filter(
            (item) =>
              item.isExpence == "false" && item.user == userRespons.user._id
          );
          setFilter(retailerData);
        }
      })
      .catch((err) => console.log(40, err));
  }, []);
  const GetDate=(date)=>{
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate
  }
  // const rechargeColumn = [
  //   {
  //     name: "Sr NO",
  //     style: {
  //       textAlign: "center",
  //       fontWaight: "700",
  //     },
  //     selector: (row, index) => index + 1,
  //   },
  //   {
  //     name: "User ",
  //     style: {
  //       textAlign: "center",
  //       fontWaight: "700",
  //     },
  //     selector: (row) => row?.userName,
  //   },
  //   {
  //     name: "Mode",
  //     style: {
  //       textAlign: "center",
  //     },
  //     selector: (row) => row?.mode,
  //   },
  //   {
  //     name: "Amount",
  //     style: {
  //       textAlign: "center",
  //     },
  //     selector: (row) => row?.amount,
  //   },
  // ];
  // const customStyles = {
  //   rows: {
  //     style: {
  //      border:"1px solid black",
  //      fontWaight:"900",
  //      // Add any other CSS properties you want to apply to the rows
  //      fontSize:"22px",
  //     },
  //   },
  //   headRow: {
  //     style: {
  //       // Add any styles for the header row
  //       fontWaight:"900",
  //       fontSize:"15px",
  //       border:"1px solid black",
  //       background:"#f36621",
  //       color:"white",
  //       textAlign:"center"
  //     },
  //   },
  //   headCells: {
  //     style: {
  //       // Add any styles for the header cells
  //       fontSize:"12px",
  //       border:"1px solid black"
  //     },
  //   },
  //   cells: {
  //     style: {
  //       fontSize:"12px",
  //       color:"black",
  //       // Add any styles for the regular cells
  //       border:"1px solid black"
  //     },
  //   },
  //   footer:{
  //     fontSize:"12px",
  //     color:"black",
  //     // Add any styles for the regular cells
  //     border:"1px solid black",
  //     innerHeight:"5rem",
  //     position:"sticky"

  //   },
  //   pagination: {
  //     style: {
  //       // Add any styles for the pagination section
  //       border:"1px solid black"
  //     },
  //   },
  // };
  const customStyles = {
    rows: {
      style: {
        border: "1px solid black",
        fontWeight:"600",
       fontSize:"18px",
      //  width:"fit-content"
        // Add any other CSS properties you want to apply to the rows
      },
    },
    headRow: {
      style: {
        // Add any styles for the header row
        border: "1px solid black",
        backgroundColor:"#0094ef  ",
        color: "white",
      },
    },
    headCells: {
      style: {
        // Add any styles for the header cells
        border: "1px solid black",
         width:"fit-content"
      },
    },
    cells: {
      style: {
        // Add any styles for the regular cells
        border: "1px solid black",
        width:"fit-content"
      },
    },
  };
  const rechargeColumn = [
    {
      name: "Sr No",
      style: {
        textAlign: "center",
        fontWaight: "700",
      },
      selector: (row, index) => index + 1,
    },
    {
      name: "User ",
      style: {
        textAlign: "center",
        fontWaight: "700",
      },
      selector: (row) => row?.userName,
    },
    {
      name: "Mode",
      style: {
        textAlign: "center",
      },
      selector: (row) => row?.mode,
    },
    {
      name: "Date",
      style: {
        textAlign: "center",
      },
      selector: (row) =>GetDate(row.createdAt),
    },
    {
      name: "Amount",
      style: {
        textAlign: "center",
      },
      selector: (row) => row?.amount,
    },
  ];




const CustomFooter = () => (
  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px',margin:"10rem" }}>
      <span>Total:</span>
      <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>1000</span>
  </div>
);
const searchByDateRange = () => {
  let credit=0;
  const filtered = filter.filter(item => {
      const createdDate = new Date(item.createdAt);
      return createdDate >= new Date(startDate) && createdDate <= new Date(endDate);
  });
  setFilter(filtered);
  if (filtered.length > 0) {
    filtered.forEach((item) => {
      if (item.isExpence === "false") {
        console.log(1111,item)
        if (item.amount!=="" &&  item.amount!==undefined) {
          console.log(31,item.amount)
          credit += item.amount
        }
      }} 
    )
    setTotal(credit)
    console.log(333,credit)
  }
};

  return (
    <div className="mt-5 text-center" >
          <Breadcrumb title={"Recharge History"} />
          <div className="row rext center">
            <div className="col-lg-4">
                {/* <input
                type="date"
                placeholder="Start Date"
                onChange={e => setStartDate(e.target.value)}
              /> */}
            <input type="date" class="form-control" id="inputEmail4"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
            
            />
            </div>
            <div className="col-lg-4">

            <input
                type="date"
                placeholder="End Date"
                class="form-control"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
            />
            </div>
            <div className="col-lg-4">

            <button onClick={searchByDateRange} className="btn btn-outline-primary">Search</button>
            </div>
          </div>
      <div className="row mt-5 fs-6 ">
      <DataTable
                    columns={rechargeColumn}
                    data={filter}
                    pagination={recharge.length > 10 ? true : false}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="300px"
                    customStyles={customStyles}
                    responsive
                  //   paginationComponentOptions={{
                  //     footer: <CustomFooter />,
                  // }}
                  />
               
      <div className="row border">
        <div className="col-lg-8"></div>
        <div className="col-lg-4"><h5>Total Recharge : {total}</h5></div>
      </div>
      </div>
    </div>
  );
  
}

export default RechargeHistry;
