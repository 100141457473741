import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { MdUploadFile } from "react-icons/md";
import { Button, Form, Input, Radio, Select, DatePicker, Upload } from "antd";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { useState } from "react";
import { districtData1, fatherOccupation } from "../../constants/data";
import { relationOfBanificiary_Applicant } from "../../constants/data";
import { useDispatch, useSelector } from "react-redux";
import { incNumber } from "../../Redux/actions";
import { toast } from "react-toastify";
import {
  districtData,
  ApllicantBenificiaryOtherState,
} from "../../constants/data";
import swal from "sweetalert";

function EWS() {
  const navigate =useNavigate()
  const [form] = Form.useForm();
  const [Data, SetData] = useState({
    statusfname: "",
    formPrice:"",
    fullName_English: "",
    fullName_Marathi: "",
    fatherName_english: "",
    fatherName_marathi: "",
    BirthDate: "",
    age: "",
    Gender: "",
    Bussness: "",
    phoneNUm: "",
    email: "",
    AdharNo: "",
    Applicant_address: "",
    Applicant_street: "",
    Applicant_Building: "",
    Applicant_locality: "",
    Applicant_landmark: "",
    Applicant_district: "",
    Applicant_taluka: "",
    Applicant_village: "",
    Applicant_pincode: "",
    Income_salary: "",
    Income_business: "",
    Income_agriculture: "",
    Income_investment: "",
    Income_others: "",
    total: "",
  });
  const [agree, setAgree] = useState(false);
  const [showloading, setShowloading] = useState(false);

  const [talukas, setTalukas] = useState([]);
  const [nameLoading, setNameLoading] = useState(false);
  const [fNameLoading, setFnameLoading] = useState(false);
  const [nameMarathi, setnameMarathi] = useState("");
  const [fnameMarathi, setfnameMarathi] = useState("");
  const [lnameMarathi, setlnameMarathi] = useState("");
  const [FormFileerror, setFormFileerror] = useState({});
  const [benificiaryLoading, setbenificiaryLoading] = useState(false);
  const [benificiarymarathi, setbenificiarymarathi] = useState("");
  const [migrationView, setMigrationView] = useState(false);
  const [parmanatDetails, SetPermanatDetails] = useState(false);
  const [age, setAge] = useState(null);
  const [benificiaryfatherLoading, setbenificiaryfatherLoading] =
    useState(false);
  const [benificiaryfathermarathi, setbenificiaryfathermarathi] = useState("");
  const [items, setItems] = useState();
  const [district, setDistrict] = useState("");
  const dispatch = useDispatch();
  const ID = items?.user?._id;
  const createdByName = items?.user?.name;
  const { id } = useParams();
  const users = useSelector((state) => state.userData.user);
  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const reducer = useSelector((state) => state.changeNumber);
  const [balance, setBalance] = useState(0);
  const [Formerror, setFormerror] = useState({});
  const [Benifierror, setBenifierror] = useState({});
  const [Fathererror, setFathererror] = useState({});
  const [isSubmit, SetIsSubmit] = useState(false);
  const [birthdate, setBirthdate] = useState("");
  const [user, setUser] = useState([]);
  const [getAgent, setgetAgent] = useState([]);
  const [GetFormPrice, setGetFormPrice] = useState();
  const [docs, setDocs] = useState({
    reshaCard: "",
    adharCard: "",
    lightBill: "",
    schoolLeaveCertificate: "",
    photo: "",
    incomeCertficate3yearOrForm16: "",
    castProof: "",
    selfDeclearation: "",
    FatherAdhar: "",
    childrenAdhar: "",
    applicantPhoto: "",
    benificiarryPhoto: "",
    fatherSchoolLeaveCer: "",
    childrenSchoolLeaveCer: "",
    otherFirst: "",
    otherSecond: "",
  });

  const [benificiary_info, setbenificiary_info] = useState({
    relationOfBanificiaryWithApplicant: "",
    benificiary_salution: "",
    Benificiary_Name_English: "",
    Benificiary_Name_marathi: "",
    Benificiary_Dob: "",
    Benificiary_age: "",
    Benificiary_gender: "",
    Benificiary_relation: "",
    Benificiary_mobNumber: "",
    Benificiary_Email: "",
    Benificiary_AdharNo: "",
    benificiary_occupation: "",
    Benificiary_Address: "",
    Benificiary_street: "",
    Benificiary_building: "",
    Benificiary_locality: "",
    Benificiary_landmark: "",
    Benificiary_District: "",
    Benificiary_taluka: "",
    Benificiary_village: "",
    Benificiary_pincode: "",
    benificiary_cast: "",
  });

  const [permanant_address, setpermanant_address] = useState({
    permanant_Address: "",
    permanant_street: "",
    permanant_Bulding: "",
    permanant_locality: "",
    permanant_landmark: "",
    permanant_district: "",
    permanant_taluka: "",
    permanant_village: "",
    permanant_pincode: "",
  });

  const [benificiary_father_details, setbenificiary_father_details] = useState({
    benificiaryFather_salution: "",
    benificiaryFather_fullName_english: "",
    benificiaryFather_fullName_marathi: "",
    benificiaryFather_DOB: "",
    benificiaryFather_age: "",
    benificiaryFather_occupation: "",
    benificiaryFather_mobileNO: "",
    benificiaryFather_email: "",
    benificiaryFather_UID: "",
    benificiaryFather_Address: "",
    benificiaryFather_street: "",
    benificiaryFather_building: "",
    benificiaryFather_Locality: "",
    benificiaryFather_Landmark: "",
    benificiaryFather_District: "",
    benificiaryFather_Taluka: "",
    benificiaryFather_Village: "",
    benificiaryFather_PinCode: "",
    benificiaryFather_cast: "",
  });

  const [migration, setMigration] = useState({
    migration_state: "",
    migration_district: "",
    migration_taluka: "",
    migration_village: "",
    migration_fromDate: "",
    migration_endDate: "",
    migration_year: "",
  });

  const [land_info, setland_info] = useState({
    land_salutation: "",
    land_holderName_english: "",
    land_holderName_marathi: lnameMarathi,
    land_holder_relation: "",
    land_holder_district: "",
    land_holder_taluka: "",
    land_holder_village: "",
    land_holder_pincode: "",
    land_holder_groupNO: "",
    land_holder_area: "",
    land_Area_unit: "",
    is_land_outside_village: "",
  });

  useEffect(() => {
    const userRespons = JSON.parse(localStorage.getItem("userResponse"));
    if (userRespons) {
      setItems(userRespons);
    }
    setTimeout(getData(), 1000);
  }, []);
  useEffect(() => {
    districtData1.filter((dist) => {
      if (dist.name.toLowerCase() == district.toLowerCase()) {
        setTalukas(dist.tahasil);
      }
    });
  }, [district]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userResponse"));
    if (userData) {
      axios.get(`https://api.maharashtraseva.com/api/recharge`).then((res) => {
        console.log(88, res.data);

        const allData = res.data.filter(
          (ele) => ele.user === userData.user._id
        );
        let amount = 0;
        let expence = 0;
        if (allData.length > 0) {
          allData.forEach((item) => {
            if (item.isExpence == "true") {
              expence += item.amount;
            } else {
              amount += item.amount;
            }
          });
        }
        console.log(53, amount, expence);
        setBalance(amount - expence);
      });
    }
  }, [reducer]);
  const getData = () => {
    axios
      .get(`https://api.maharashtraseva.com/api/user/getAll`)
      .then((res) => {
        const response = res.data;
        setUser(response);
        const userdata = JSON.parse(localStorage.getItem("userResponse"));
        if (userdata) {
          const getFormPrice = response.find(
            (item) => item._id === userdata.user._id
          );
          const getagent = response.filter(
            (item) => item._id === getFormPrice.agent
          );
          // setuserData(userdata);
          setgetAgent(getagent);
          setGetFormPrice(getFormPrice?.retaile_formPrice?.retailer_EWS);
          SetData({...Data,formPrice:getFormPrice?.retaile_formPrice?.retailer_EWS})
        }
        // console.log(37,response)
      })
      .catch((err) => console.log(40, err));
  };
  const translateName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.fullName_English,
      })
      .then((res) => {
        console.log(res.data.output);
        setnameMarathi(res.data.output);
        SetData({
          ...Data,
          fullName_Marathi: res.data.output,
        });

        // setNameLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const translateFName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: Data.fatherName_english,
      })
      .then((res) => {
        console.log(res.data.output);
        setfnameMarathi(res.data.output);
        // setFnameLoading(false);
        SetData({ ...Data, fatherName_marathi: res.data.output });
      })
      .catch((err) => console.log(err));
  };

  const translateLName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: land_info.land_holderName_english,
      })
      .then((res) => {
        console.log(res.data.output);
        setlnameMarathi(res.data.output);
      })
      .catch((err) => console.log(err));
  };

  const translatebenificiaryName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: benificiary_info.Benificiary_Name_English,
      })
      .then((res) => {
        setbenificiarymarathi(res.data.output);
        setbenificiaryLoading(false);
        setbenificiary_info({
          ...benificiary_info,
          Benificiary_Name_marathi: res.data.output,
        });
      })
      .catch((err) => console.log(err));
  };
  const translatebenificiaryFatherName = () => {
    axios
      .post("https://api.maharashtraseva.com/translate", {
        text: benificiary_father_details.benificiaryFather_fullName_english,
      })
      .then((res) => {
        console.log(res.data.output);
        setbenificiaryfathermarathi(res.data.output);
        // setbenificiaryfatherLoading(false);
        setbenificiary_father_details({
          ...benificiary_father_details,
          benificiaryFather_fullName_marathi: res.data.output,
        });
      })
      .catch((err) => console.log(err));
  };

  const totalfun = () => {
    let total = 0;
    console.log(142, Data.Income_salary);
    if (Data.Income_salary > 0) {
      total += Data.Income_salary;
    }
    if (Data.Income_business > 0) {
      total += Data.Income_business;
    }
    if (Data.Income_agriculture > 0) {
      total += Data.Income_agriculture;
    }
    if (Data.Income_investment > 0) {
      total += Data.Income_investment;
    }
    if (Data.Income_others > 0) {
      total += Data.Income_others;
    }
    // console.log('161',total)
    SetData({ ...Data, total });
  };

  const FileValidate = (Val) => {
    let fileError = {};
    
    if (!Val.childrenSchoolLeaveCer) {
      fileError.childrenSchoolLeaveCer = "File is Required";
    } else {
      if (Val.childrenSchoolLeaveCer.size > 500 * 1024) {
        fileError.childrenSchoolLeaveCer =
          " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.fatherSchoolLeaveCer) {
      fileError.fatherSchoolLeaveCer = "File is Required";
    } else {
      if (Val.fatherSchoolLeaveCer.size > 500 * 1024) {
        fileError.fatherSchoolLeaveCer =
          " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.benificiarryPhoto) {
      fileError.benificiarryPhoto = "File is Required";
    } else {
      if (Val.benificiarryPhoto.size > 500 * 1024) {
        fileError.benificiarryPhoto = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.applicantPhoto) {
      fileError.applicantPhoto = "File is Required";
    } else {
      if (Val.applicantPhoto.size > 500 * 1024) {
        fileError.applicantPhoto = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.childrenAdhar) {
      fileError.childrenAdhar = "File is Required";
    } else {
      if (Val.childrenAdhar.size > 500 * 1024) {
        fileError.childrenAdhar = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.FatherAdhar) {
      fileError.FatherAdhar = "File is Required";
    } else {
      if (Val.FatherAdhar.size > 500 * 1024) {
        fileError.FatherAdhar = " File size exceeds the limit of 500kb";
      }
    }


    if (!Val.lightBill) {
      fileError.lightBill = "File is Required";
    } else {
      if (Val.lightBill.size > 500 * 1024) {
        fileError.lightBill = " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.schoolLeaveCertificate) {
      fileError.schoolLeaveCertificate = "File is Required";
    } else {
      if (Val.schoolLeaveCertificate.size > 500 * 1024) {
        fileError.schoolLeaveCertificate =
          " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.incomeCertficate3yearOrForm16) {
      fileError.incomeCertficate3yearOrForm16 = "File is Required";
    } else {
      if (Val.incomeCertficate3yearOrForm16.size > 500 * 1024) {
        fileError.incomeCertficate3yearOrForm16 =
          " File size exceeds the limit of 500kb";
      }
    }
    if (!Val.selfDeclearation) {
      fileError.selfDeclearation = "File is Required";
    } else {
      if (Val.selfDeclearation.size > 500 * 1024) {
        fileError.selfDeclearation = " File size exceeds the limit of 500kb";
      }
    }
    return fileError;
  };
  const validate = (values) => {
    const error = {};
    if (!values.fullName_English) {
      error.fullName_English = " fullName English is required";
    }
    if (!values.fullName_Marathi) {
      error.fullName_Marathi = " fullName Marathi is required";
    }

    if (!values.fatherName_english) {
      error.fatherName_english = "father Name English is required";
    }
    if (!values.fatherName_marathi) {
      error.fatherName_marathi = " father Name Marathi is required";
    }
    if (!values.age) {
      error.age = " age is required";
    }
    if (!values.Gender) {
      error.Gender = " Gender is required";
    }
    if (!values.relation) {
      error.relation = " relation is required";
    }
    if (!values.phoneNUm) {
      error.phoneNUm = "  phoneNUm is required";
    }
    if (!values.BirthDate) {
      error.BirthDate = "  Birth Date is required";
    }

    if (!values.Applicant_address) {
      error.Applicant_address = "  Applicant address is required";
    }
    if (!values.Applicant_village) {
      error.Applicant_village = " Applicant village is required";
    }
    if (!values.Applicant_district) {
      error.Applicant_district = "Distict is required";
    }
    if (!values.Applicant_taluka) {
      error.Applicant_taluka = "taluka is required";
    }
    if (!values.Applicant_pincode) {
      error.Applicant_pincode = "Applicant pincode is required";
    }
    console.log("error object", error);
    return error;
  };
  const BeniVal = (value) => {
    const error = {};
    if (!value.relationOfBanificiaryWithApplicant) {
      error.relationOfBanificiaryWithApplicant =
        "कृपया लाभार्थीचे अर्जदाराशी नाते निवडा.";
    }
    if (!value.Benificiary_Name_English) {
      error.Benificiary_Name_English =
        "कृपया लाभार्थीच्या पूर्ण नावाची(इंग्रजी)नोंद करा.";
    }
    if (!value.Benificiary_Name_marathi) {
      error.Benificiary_Name_marathi =
        "कृपया लाभार्थीच्या पूर्ण नावाची(मराठी) नोंद करा.";
    }
    if (!value.Benificiary_Dob) {
      error.Benificiary_Dob = "कृपया लाभार्थीच्या जन्म दिनांकाची नोंद करा.";
    }
    if (!value.Benificiary_gender) {
      error.Benificiary_gender = "Is required";
    }
    if (!value.Benificiary_mobNumber) {
      error.Benificiary_mobNumber =
        "कृपया लाभार्थीच्या भ्रमणध्वनी क्रमांकाची नोंद करा.";
    }
    if (!value.benificiary_occupation) {
      error.benificiary_occupation = "Is required";
    }
    return error;
  };
  const FatherVal = (value) => {
    const error = {};
    if (!value.benificiaryFather_mobileNO) {
      error.benificiaryFather_mobileNO =
        "Please enter Beneficiary's fathers Mob. No.";
    }
    if (!value.benificiaryFather_fullName_marathi) {
      error.benificiaryFather_fullName_marathi =
        "Please enter Beneficiary's fathers full name in Marathi.";
    }
    if (!value.benificiaryFather_fullName_english) {
      error.benificiaryFather_fullName_english =
        "Please enter Beneficiary's fathers full name in English.";
    }
    if (!value.benificiaryFather_DOB) {
      error.benificiaryFather_DOB =
        "Please enter Beneficiary's fathers Date of Birth.";
    }
    if (!value.benificiaryFather_Address) {
      error.benificiaryFather_Address =
        "Please enter Beneficiary's fathers address.";
    }
    if (!value.benificiaryFather_District) {
      error.benificiaryFather_District =
        "Please select Beneficiary's fathers District.";
    }
    if (!value.benificiaryFather_Taluka) {
      error.benificiaryFather_Taluka =
        "Please select Beneficiary's fathers Taluka.";
    }
    if (!value.benificiaryFather_Village) {
      error.benificiaryFather_Village =
        "Please select Beneficiary's fathers village";
    }
    if (!value.benificiaryFather_PinCode) {
      error.benificiaryFather_PinCode =
        "Please enter Beneficiary's fathers pincode.";
    }
    if (!value.benificiaryFather_cast) {
      error.benificiaryFather_cast =
        "कृपया लाभार्थ्यांच्या वडिलांच्या जातीची नोंद करा..";
    }
    return error;
  };

  const handleDateChange = (date, dateString) => {
    SetData({ ...Data, BirthDate: dateString });
    setBirthdate(dateString);
  };
  const handleDateChange_benificiary = (date, dateString) => {
    setbenificiary_info({ ...benificiary_info, Benificiary_Dob: dateString });
  };

  const handleDateChange_benificiaryFatherDOB = (date, dateString) => {
    setbenificiary_father_details({
      ...benificiary_father_details,
      benificiaryFather_DOB: dateString,
    });
  };
  const handleDateChange_migration_fromDate = (date, dateString) => {
    setMigration({
      ...migration,
      migration_fromDate: dateString,
    });
  };
  const handleDateChange_migration_endDate = (date, dateString) => {
    setMigration({
      ...migration,
      migration_endDate: dateString,
    });
  };

  const EWSFormSubmit = (e) => {
    e.preventDefault();
    setShowloading(true);
    const fatherVal = FatherVal(benificiary_father_details);
    const benierror = BeniVal(benificiary_info);
    const errors = validate(Data);
    const FileVal = FileValidate(docs);
    // console.log(719, fatherVal, benierror, errors, FileVal);
    console.log(719, fatherVal, errors, FileVal);

    if (
      Object.keys(errors).length === 0 &&
      Object.keys(FileVal).length === 0 &&
      Object.keys(benierror).length === 0 &&
      Object.keys(fatherVal).length === 0
    ) {
    
      if (agree) {
        if (balance > GetFormPrice) {
          // setShowloading(false);
          const obj = {
            application_type: "EWS",
            status: "IN-PROGRESS",
            createdBy: ID,
            createdByName,
            agentId: getAgent[0]._id,
            agentName: getAgent[0].name,
            Data,
            benificiary_info,
            permanant_address,
            migration,
            land_info,
            benificiary_father_details,
          };
          console.log(719, obj);

          const mainDataPromise = new Promise((resolve, reject) => {
            axios
              .post(`https://api.maharashtraseva.com/api/Ews/create`, obj)
              .then((res) => {
                const response = res;
                resolve({
                  status: true,
                  message: "data Posted Successfully",
                  data: res.data,
                });
              })
              .catch((err) => {
                console.log(err);
                reject({
                  status: false,
                  message: "Data Not posted",
                });
                setShowloading(false);

              });
          });

          mainDataPromise
            .then((res) => {
              console.log(124, res.data);

              uploadreshaCard(res.data.data._id);
              // uploadadharCard(res.data.data._id);
              uploadlightBill(res.data.data._id);
              uploadschoolLeaveCertificate(res.data.data._id);
              // uploadphoto(res.data.data._id);
              uploadincomeCertficate3yearOrForm16(res.data.data._id);
              uploadcastProof(res.data.data._id);
              uploadselfDeclearation(res.data.data._id);
              uploadFatherAdhar(res.data.data._id);
              uploadchildrenAdhar(res.data.data._id);
              uploadapplicantPhoto(res.data.data._id);
              uploadbenificiarryPhoto(res.data.data._id);
              uploadfatherSchoolLeaveCer(res.data.data._id);
              uploadchildrenSchoolLeaveCer(res.data.data._id);
              uploadotherFirst(res.data.data._id);
              uploadotherSecond(res.data.data._id);
              CreaditAgent();
              debitFormBalance();
             
              setbenificiary_info({
                relationOfBanificiaryWithApplicant: null,
                benificiary_salution: null,
                Benificiary_Name_English: null,
                Benificiary_Name_marathi: null,
                Benificiary_Dob: null,
                Benificiary_age: null,
                Benificiary_gender: null,
                Benificiary_relation: null,
                Benificiary_mobNumber: null,
                Benificiary_Email: null,
                Benificiary_AdharNo: null,
                benificiary_occupation: null,
                Benificiary_Address: null,
                Benificiary_street: null,
                Benificiary_building: null,
                Benificiary_locality: null,
                Benificiary_landmark: null,
                Benificiary_District: null,
                Benificiary_taluka: null,
                Benificiary_village: null,
                Benificiary_pincode: null,
                benificiary_cast: null,
              });
              setland_info({
                land_salutation: null,
                land_holderName_english: null,
                land_holderName_marathi: null,
                land_holder_relation: null,
                land_holder_district: null,
                land_holder_taluka: null,
                land_holder_village: null,
                land_holder_pincode: null,
                land_holder_groupNO: null,
                land_holder_area: null,
                land_Area_unit: null,
                is_land_outside_village: null,
              });
              setMigration({
                migration_state: null,
                migration_district: null,
                migration_taluka: null,
                migration_village: null,
                migration_fromDate: null,
                migration_endDate: null,
                migration_year: null,
              });
              setbenificiary_father_details({
                benificiaryFather_salution: null,
                benificiaryFather_fullName_english: null,
                benificiaryFather_fullName_marathi: null,
                benificiaryFather_DOB: null,
                benificiaryFather_age: null,
                benificiaryFather_occupation: null,
                benificiaryFather_mobileNO: null,
                benificiaryFather_email: null,
                benificiaryFather_UID: null,
                benificiaryFather_Address: null,
                benificiaryFather_street: null,
                benificiaryFather_building: null,
                benificiaryFather_Locality: null,
                benificiaryFather_Landmark: null,
                benificiaryFather_District: null,
                benificiaryFather_Taluka: null,
                benificiaryFather_Village: null,
                benificiaryFather_PinCode: null,
                benificiaryFather_cast: null,
              });
              setpermanant_address({
                permanant_Address: null,
                permanant_street: null,
                permanant_Bulding: null,
                permanant_locality: null,
                permanant_landmark: null,
                permanant_district: null,
                permanant_taluka: null,
                permanant_village: null,
                permanant_pincode: null,
              });
          setShowloading(false)

            }).then(() => navigate("/RetailerDashboard"))
            .catch((err) => {
              console.log(err);
          setShowloading(false)

            });
        } else {
          toast.error("Please Recharge");
          setShowloading(false);

        }
      } else {
        toast.error("Please Accept Agreement");
        setShowloading(false);

      }
    } else {
      setFormerror(errors);
      setBenifierror(benierror);
      setFormFileerror(FileVal);
      setFathererror(fatherVal);
      swal("Please Fill All field", "error");
      setShowloading(false);

    }
  };

  const CreaditAgent = () => {
    const obj = {
      agentId: getAgent[0]._id,
      agentName: getAgent[0].name,
      creaditAmount: getAgent[0].agent_formPrice.agent_EWS,
      isWithdrowl: false,
      isrequest: false,
      creaditFor: "EWS",
      creaditBy: userData.user._id,
    };
    console.log(292, obj);
    axios
      .post(`https://api.maharashtraseva.com/api/wallet/create`, obj)
      .then((res) => {
        const response = res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const obj = {
    user: userData?.user._id,
    mode: "offline",
    amount: GetFormPrice,
    isExpence: true,
    expenceFor: "EWS",
  };
  const debitFormBalance = () => {
    axios
      .post("https://api.maharashtraseva.com/api/recharge/create", obj)
      .then((res) => {
        const response = res.data;
        dispatch(incNumber());
        swal("Good job!", "form submitted successfully!", "success");
        // navigate("/RetailerForm4")
      })
      .catch((err) => console.log(34, err));
  };

  const uploadreshaCard = (id) => {
    const formData = new FormData();
    formData.append("reshaCard", docs.reshaCard);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_reshaCard/${id}`, formData)
      .then((res) => console.log("reshaCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadadharCard = (id) => {
    const formData = new FormData();
    formData.append("adharCard", docs.adharCard);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_adharCard/${id}`, formData)
      .then((res) => console.log("adharCard", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadlightBill = (id) => {
    const formData = new FormData();
    formData.append("lightBill", docs.lightBill);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_lightBill/${id}`, formData)
      .then((res) => console.log("lightBill", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadschoolLeaveCertificate = (id) => {
    const formData = new FormData();
    formData.append("schoolLeaveCertificate", docs.schoolLeaveCertificate);
    axios
      .put(
        `https://api.maharashtraseva.com/api/EWS_schoolLeaveCertificate/${id}`,
        formData
      )
      .then((res) => console.log("photo", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadphoto = (id) => {
    const formData = new FormData();
    formData.append("photo", docs.photo);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_photo/${id}`, formData)
      .then((res) => console.log("form16", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadincomeCertficate3yearOrForm16 = (id) => {
    const formData = new FormData();
    formData.append(
      "incomeCertficate3yearOrForm16",
      docs.incomeCertficate3yearOrForm16
    );
    axios
      .put(
        `https://api.maharashtraseva.com/api/EWS_incomeCertficate3yearOrForm16/${id}`,
        formData
      )
      .then((res) => console.log("incomeCertficate3yearOrForm16", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadcastProof = (id) => {
    const formData = new FormData();
    formData.append("castProof", docs.castProof);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_castProof/${id}`, formData)
      .then((res) => console.log("incomeCertficate3yearOrForm16", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadselfDeclearation = (id) => {
    const formData = new FormData();
    formData.append("selfDeclearation", docs.selfDeclearation);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_selfDeclearation/${id}`, formData)
      .then((res) => console.log("selfDeclearation", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadFatherAdhar = (id) => {
    const formData = new FormData();
    formData.append("FatherAdhar", docs.FatherAdhar);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_FatherAdhar/${id}`, formData)
      .then((res) => console.log("FatherAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadchildrenAdhar = (id) => {
    const formData = new FormData();
    formData.append("childrenAdhar", docs.childrenAdhar);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_childrenAdhar/${id}`, formData)
      .then((res) => console.log("childrenAdhar", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadapplicantPhoto = (id) => {
    const formData = new FormData();
    formData.append("applicantPhoto", docs.applicantPhoto);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_applicantPhoto/${id}`, formData)
      .then((res) => console.log("applicantPhoto", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadbenificiarryPhoto = (id) => {
    const formData = new FormData();
    formData.append("benificiarryPhoto", docs.benificiarryPhoto);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_benificiarryPhoto/${id}`, formData)
      .then((res) => console.log("benificiarryPhoto", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadfatherSchoolLeaveCer = (id) => {
    const formData = new FormData();
    formData.append("fatherSchoolLeaveCer", docs.fatherSchoolLeaveCer);
    axios
      .put(
        `https://api.maharashtraseva.com/api/EWS_fatherSchoolLeaveCer/${id}`,
        formData
      )
      .then((res) => console.log("fatherSchoolLeaveCer", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadchildrenSchoolLeaveCer = (id) => {
    const formData = new FormData();
    formData.append("childrenSchoolLeaveCer", docs.childrenSchoolLeaveCer);
    axios
      .put(
        `https://api.maharashtraseva.com/api/EWS_childrenSchoolLeaveCer/${id}`,
        formData
      )
      .then((res) => console.log("childrenSchoolLeaveCer", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadotherFirst = (id) => {
    const formData = new FormData();
    formData.append("otherFirst", docs.otherFirst);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_otherFirst/${id}`, formData)
      .then((res) => console.log("otherFirst", res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadotherSecond = (id) => {
    const formData = new FormData();
    formData.append("otherSecond", docs.otherSecond);
    axios
      .put(`https://api.maharashtraseva.com/api/EWS_otherSecond/${id}`, formData)
      .then((res) => console.log("otherSecond", res.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const clickHandler = () => {
    // setNameLoading(true);
    translateName();
  };
  const clickHandler2 = () => {
    // setFnameLoading(true);
    translateFName();
  };
  const clickHandler3 = () => {
    // setbenificiaryLoading(true);
    translatebenificiaryName();
  };

  const clickHandler4 = () => {
    // setbenificiaryfatherLoading(true);
    translatebenificiaryFatherName();
  };

  const clickHandler5 = () => {
    translateLName();
  };

  useEffect(() => {
    if (id != undefined) {
      axios.get(`https://api.maharashtraseva.com/api/Ews/${id}`).then((res) => {
        const data = res.data[0];
        // console.log('datas',data.benificiary_father_details.benificiaryFather_fullName_english)
        SetData(data.Data);
        setbenificiary_info(data.benificiary_info);
        setbenificiary_father_details(data.benificiary_father_details);
        setpermanant_address(data.permanant_address);
        setMigration(data.migration);
        setland_info(data.land_info);
        if (
          data?.isNew == true &&
          data?.status == "REJECTED" &&
          userData.user.role === "retailer"
        ) {
          axios
            .put(`https://api.maharashtraseva.com/api/Ews/${id}`, { isNew: false })
            .then((res) => {
              dispatch(incNumber());
            })
            .catch((err) => console.log(err));
        }
      });
    }
  }, [id]);

  const editHandler = (e) => {
    const obj = {
      application_type: "EWS",
      status: "IN-PROGRESS",
      createdBy: ID,
      createdByName,
      Data,
      benificiary_info,
      benificiary_father_details: benificiary_father_details,
      permanant_address,
      migration,
      land_info,
    };

    if (agree) {
      const mainDataPromise = new Promise((resolve, reject) => {
        // console.log(77, Data);
        axios
          .put(`https://api.maharashtraseva.com/api/Ews/${id}`, obj)
          .then((res) => {
            const response = res;
            resolve({
              status: true,
              message: "data Posted Successfully",
              data: res.data,
            });
          })
          .catch((err) => {
            console.log(err);
            reject({
              status: false,
              message: "Data Not posted",
            });
          });
      });

      mainDataPromise
        .then((res) => {
          console.log(124, res.data);

          uploadreshaCard(res.data.data._id);
              // uploadadharCard(res.data.data._id);
              uploadlightBill(res.data.data._id);
              uploadschoolLeaveCertificate(res.data.data._id);
              // uploadphoto(res.data.data._id);
              uploadincomeCertficate3yearOrForm16(res.data.data._id);
              uploadcastProof(res.data.data._id);
              uploadselfDeclearation(res.data.data._id);
              uploadFatherAdhar(res.data.data._id);
              uploadchildrenAdhar(res.data.data._id);
              uploadapplicantPhoto(res.data.data._id);
              uploadbenificiarryPhoto(res.data.data._id);
              uploadfatherSchoolLeaveCer(res.data.data._id);
              uploadchildrenSchoolLeaveCer(res.data.data._id);
              uploadotherFirst(res.data.data._id);
              uploadotherSecond(res.data.data._id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const setTalukasData = (e) => {
    console.log(340, e);
    if (e) {
      const taluka = districtData1.filter((item) => item.name === e);
      setTalukas(taluka[0].tahasil);
    } else {
      console.log("Flop");
    }
  };

  const calculateAge = () => {
    const birthdateDate = new Date(birthdate);
    const currentDate = new Date();

    if (!isNaN(birthdateDate.getTime())) {
      const yearsDiff = currentDate.getFullYear() - birthdateDate.getFullYear();
      setAge(yearsDiff);
      SetData({ ...Data, age: yearsDiff });
    } else {
      alert("Please enter a valid birthdate.");
    }
  };
  return (
    <>
      <Breadcrumb
        title={"Economically Weaker Section Certificate :"}
        parent={"Certificate"}
      />
      <h3>Form Price :{GetFormPrice ? GetFormPrice : "0"}</h3>
      <Container fluid={true}>
        <Row>
          <Col sm="">
            <Card>
              <CardBody>
                {/* Applicant Details */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Applicant Details</span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Salutation<span className="red"></span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={Data.statusfname}
                            onChange={(e) => {
                              SetData({ ...Data, statusfname: e });
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select Salutation--
                            </Select.Option>
                            <Select.Option value="Kumar">Kumar</Select.Option>
                            <Select.Option value="Advocate">
                              Advocate
                            </Select.Option>
                            <Select.Option value="Shri">Mr.</Select.Option>
                            <Select.Option value="Kumari">Kumari</Select.Option>
                            <Select.Option value="Ms">Ms</Select.Option>
                            <Select.Option value="Shrimati">
                              Shrimati
                            </Select.Option>
                            <Select.Option value="Mrs">Mrs</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Full Name (English) <span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Full Name!"
                            value={Data.fullName_English}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                fullName_English: e.target.value,
                              });
                              setNameLoading(true);
                            }}
                            onBlur={clickHandler}
                          />
                          <p className="red">{Formerror.fullName_English}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Full Name (Marathi) <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Full Name!"
                            value={nameMarathi}
                            onChange={(e) => {
                              setnameMarathi(e.target.value);
                              SetData({
                                ...Data,
                                fullName_Marathi: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.fullName_Marathi}</p>

                          {nameLoading && <div class="loader"></div>}
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red"></span> Salutation
                        </label>

                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={Data.rto}
                            onChange={(e) => {
                              SetData({ ...Data, rto: e });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Salutation--
                            </Select.Option>
                            <Select.Option value="Mr">Mr.</Select.Option>
                            <Select.Option value="Shri">Shri</Select.Option>
                          </Select>
                          {/* <p className="red">{Formerror.fullName_Marathi}</p> */}
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Father's Name(English) <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full father  Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Full father  Name!"
                            value={Data.fatherName_english}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                fatherName_english: e.target.value,
                              });
                              // setFnameLoading(true);
                            }}
                            onBlur={clickHandler2}
                          />
                          <p className="red">{Formerror.fatherName_english}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Father's Name (Marathi)<span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Father Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Father Full Name!"
                            value={fnameMarathi}
                            onChange={(e) => {
                              setfnameMarathi(e.target.value);
                              SetData({
                                ...Data,
                                fatherName_marathi: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.fullName_Marathi}</p>

                          {fNameLoading && <div class="loader"></div>}
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Date Of Birth
                        </label>

                        <Form.Item>
                          <DatePicker
                            onChange={handleDateChange}
                            // onBlur={calculateAge}
                            format="DD-MM-YYYY"
                          />
                          <p className="red">{Formerror.BirthDate}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Age <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Age!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Age!"
                            value={Data.age}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                age: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.age}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Gender <span className="red">*</span>
                        </label>

                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={Data.Gender}
                            onChange={(e) => {
                              SetData({ ...Data, Gender: e });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Gender --
                            </Select.Option>
                            <Select.Option value="Male">Male</Select.Option>
                            <Select.Option value="Female">Female</Select.Option>
                            <Select.Option value="Other">Other</Select.Option>
                          </Select>
                          <p className="red">{Formerror.Gender}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span> Occupation
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your middle Name!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="--Select--"
                            value={Data.Bussness}
                            onChange={(e) => {
                              SetData({ ...Data, Bussness: e });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Occupation--
                            </Select.Option>
                            {fatherOccupation.map((item) => {
                              return (
                                <>
                                  <Select.Option value={item}>
                                    {item}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                          <p className="red">{Formerror.Bussness}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Relation
                        </label>

                        <Form.Item>
                          <Select
                            placeholder="Vehicle Type"
                            value={Data.relation}
                            onChange={(e) => {
                              SetData({ ...Data, relation: e });
                            }}

                            //   value={Relation}
                          >
                            <Select.Option value="" key="default">
                              --Select Relation--
                            </Select.Option>
                            <Select.Option value="Father"></Select.Option>
                            <Select.Option value="Mother"></Select.Option>
                            <Select.Option value="Husband"></Select.Option>
                            <Select.Option value="Gaurdian"></Select.Option>
                          </Select>
                          <p className="red">{Formerror.relation}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Mobile No <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="number"
                            maxLength="10"
                            minLength="10"
                            placeholder="Please input your Last Name!"
                            value={Data.phoneNUm}
                            onChange={(e) => {
                              // SetData({
                              //   ...Data,
                              //   phoneNUm: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 10) {
                                SetData({
                                  ...Data,
                                  phoneNUm: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Phone Number");
                                SetData({
                                  ...Data,
                                  phoneNUm: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                          <p className="red">{Formerror.phoneNUm}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Email
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Input Email!"
                            value={Data.email}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                email: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          UID No.
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your UDI Number!"
                            value={Data.AdharNo}
                            onChange={(e) => {
                              // SetData({
                              //   ...Data,
                              //   AdharNo: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 12) {
                                SetData({
                                  ...Data,
                                  AdharNo: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Adhar Number");
                                SetData({
                                  ...Data,
                                  AdharNo: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Residence Details */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Residence Details</span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Address<span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your address!"
                            value={Data.Applicant_address}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                Applicant_address: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.Applicant_address}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Street
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input street name!"
                            value={Data.Applicant_street}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                Applicant_street: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Building
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your middle Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input building number"
                            value={Data.Applicant_Building}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                Applicant_Building: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Locality
                        </label>

                        <Form.Item>
                          <Input
                            className="p-2"
                            placeholder="Please input locality name"
                            value={Data.Applicant_locality}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                Applicant_locality: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Landmark
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input landmark address"
                            value={Data.Applicant_landmark}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                Applicant_landmark: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span> District
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your middle Name!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="--Select District--"
                            value={Data.Applicant_district}
                            onChange={(e) => {
                              SetData({ ...Data, Applicant_district: e });
                              setTalukasData(e);
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select District --
                            </Select.Option>
                            {districtData1.map((item, i) => {
                              return (
                                <>
                                  <Select.Option value={item.name}>
                                    {item.name}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                          <p className="red">{Formerror.Applicant_district}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span> Taluka
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="Select State"
                            value={Data.Applicant_taluka}
                            onChange={(e) => {
                              SetData({ ...Data, Applicant_taluka: e });
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select Taluka--
                            </Select.Option>
                            {talukas.map((item, i) => {
                              return (
                                <>
                                  <Select.Option value={item}>
                                    {item}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                          <p className="red">{Formerror.Applicant_taluka}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span> Village
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input your Village  Name!"
                            value={Data.Applicant_village}
                            onChange={(e) => {
                              SetData({
                                ...Data,
                                Applicant_village: e.target.value,
                              });
                            }}
                          />
                          <p className="red">{Formerror.Applicant_village}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Pincode <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="number"
                            placeholder="Please input Pincode !"
                            value={Data.Applicant_pincode}
                            onChange={(e) => {
                              // SetData({
                              //   ...Data,
                              //   Applicant_pincode: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 6) {
                                SetData({
                                  ...Data,
                                  Applicant_pincode: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Phone Number");
                                SetData({
                                  ...Data,
                                  Applicant_pincode: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                          <p className="red">{Formerror.Applicant_pincode}</p>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Beneficiary Details */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Beneficiary Details</span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Relation of Applicant with Beneficiary
                          <span className="red">*</span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={
                              benificiary_info.relationOfBanificiaryWithApplicant
                            }
                            onChange={(e) => {
                              console.log(1919, e);
                              setbenificiary_info({
                                ...benificiary_info,
                                relationOfBanificiaryWithApplicant: e,
                              });
                              if (e == "Self") {
                                setbenificiary_info({
                                  ...benificiary_info,
                                  benificiary_salution: Data.statusfname,
                                  Benificiary_Name_English:
                                    Data.fullName_English,
                                  Benificiary_Dob: Data.BirthDate,
                                  Benificiary_age: Data.age,
                                  Benificiary_gender: Data.Gender,
                                  Benificiary_mobNumber: Data.phoneNUm,
                                  Benificiary_Email: Data.email,
                                  Benificiary_AdharNo: Data.AdharNo,
                                  benificiary_occupation: Data.Bussness,
                                  relationOfBanificiaryWithApplicant: e,
                                });
                              } else {
                                setbenificiary_info({
                                  ...benificiary_info,
                                  benificiary_salution: "",
                                  Benificiary_Name_English: "",
                                  Benificiary_Dob: "",
                                  Benificiary_age: "",
                                  Benificiary_gender: "",
                                  Benificiary_mobNumber: "",
                                  Benificiary_Email: "",
                                  Benificiary_AdharNo: "",
                                  benificiary_occupation: "",
                                  relationOfBanificiaryWithApplicant: e,
                                });
                              }
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Relation --
                            </Select.Option>
                            {relationOfBanificiary_Applicant.map((item, i) => (
                              <Select.Option value={item} key={i}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                          <p className="red">
                            {Benifierror.relationOfBanificiaryWithApplicant}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Beneficiary Salutation<span className="red">*</span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={benificiary_info.benificiary_salution}
                            onChange={(e) => {
                              setbenificiary_info({
                                ...benificiary_info,
                                benificiary_salution: e,
                              });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Salutation --
                            </Select.Option>
                            <Select.Option value="Kumar">Kumar</Select.Option>
                            <Select.Option value="Advocate">
                              Advocate
                            </Select.Option>
                            <Select.Option value="Shri">Mr.</Select.Option>
                            <Select.Option value="Kumari">Kumari</Select.Option>
                            <Select.Option value="Ms">Ms</Select.Option>
                            <Select.Option value="Shrimati">
                              Shrimati
                            </Select.Option>
                            <Select.Option value="Mrs">Mrs</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Beneficiary Name (English)
                          <span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="text"
                            placeholder="Please input Beneficiary Name!"
                            value={benificiary_info.Benificiary_Name_English}
                            onChange={(e) => {
                              setbenificiary_info({
                                ...benificiary_info,
                                Benificiary_Name_English: e.target.value,
                              });
                              setbenificiaryLoading(true);
                            }}
                            onBlur={clickHandler3}
                          />
                          <p className="red">
                            {Benifierror.Benificiary_Name_English}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Beneficiary Name (Marathi){" "}
                          <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input Beneficiary Name (Marathi) !"
                            value={benificiarymarathi}
                            onChange={(e) => {
                              setbenificiarymarathi(e.target.value);
                            }}
                          />
                          {benificiaryLoading && <div class="loader"></div>}
                          <p className="red">
                            {Benifierror.Benificiary_Name_English}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Date Of Birth
                        </label>

                        <Form.Item>
                          <DatePicker
                            // name="Benificiary_Dob"
                            // value={benificiary_info.Benificiary_Dob}

                            onChange={handleDateChange_benificiary}
                            format="DD-MM-YYYY"
                          />
                          <p className="red">{Benifierror.Benificiary_Dob}</p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Gender <span className="red">*</span>
                        </label>

                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={benificiary_info.Benificiary_gender}
                            onChange={(e) => {
                              setbenificiary_info({
                                ...benificiary_info,
                                Benificiary_gender: e,
                              });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Gender --
                            </Select.Option>
                            <Select.Option value="Male">Male</Select.Option>
                            <Select.Option value="Female">Female</Select.Option>
                            <Select.Option value="Other">Other</Select.Option>
                          </Select>
                          <p className="red">
                            {Benifierror.Benificiary_gender}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Mobile No <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="number"
                            placeholder="Please input Mobile No!"
                            value={benificiary_info.Benificiary_mobNumber}
                            onChange={(e) => {
                              setbenificiary_info({
                                ...benificiary_info,
                                Benificiary_mobNumber: e.target.value,
                              });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 10) {
                                setbenificiary_info({
                                  ...benificiary_info,
                                  Benificiary_mobNumber: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Phone Number");
                                setbenificiary_info({
                                  ...benificiary_info,
                                  Benificiary_mobNumber: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                          <p className="red">
                            {Benifierror.Benificiary_mobNumber}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Email
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input Email!"
                            value={benificiary_info.Benificiary_Email}
                            onChange={(e) => {
                              setbenificiary_info({
                                ...benificiary_info,
                                Benificiary_Email: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          UID No.
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please input ID No !"
                            value={benificiary_info.Benificiary_AdharNo}
                            onChange={(e) => {
                              // setbenificiary_info({
                              //   ...benificiary_info,
                              //   Benificiary_AdharNo: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 12) {
                                setbenificiary_info({
                                  ...benificiary_info,
                                  Benificiary_AdharNo: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Aadhar Number");
                                setbenificiary_info({
                                  ...benificiary_info,
                                  Benificiary_AdharNo: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Occupation<span className="red">*</span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={benificiary_info.benificiary_occupation}
                            onChange={(e) => {
                              setbenificiary_info({
                                ...benificiary_info,
                                benificiary_occupation: e,
                              });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Occupation --
                            </Select.Option>
                            {fatherOccupation.map((item) => {
                              return (
                                <>
                                  <Select.Option value={item}>
                                    {item}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Permanent Address */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Permanent Address</span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-12">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Permanent Address Same
                          as Present Address?
                        </label>
                        <Form.Item>
                          <Radio.Group>
                            <Radio
                              value="yes"
                              onClick={() => {
                                SetPermanatDetails(false);
                                setbenificiary_info({
                                  ...benificiary_info,
                                  Benificiary_Address: Data.Applicant_street,
                                  Benificiary_street: Data.Applicant_address,
                                  Benificiary_building: Data.Applicant_Building,
                                  Benificiary_locality: Data.Applicant_locality,
                                  Benificiary_landmark: Data.Applicant_landmark,
                                  Benificiary_District: Data.Applicant_district,
                                  Benificiary_taluka: Data.Applicant_taluka,
                                  Benificiary_village: Data.Applicant_village,
                                  Benificiary_pincode: Data.Applicant_pincode,
                                });
                              }}
                            >
                              Yes
                            </Radio>
                            <Radio
                              value="No"
                              onClick={() => SetPermanatDetails(true)}
                            >
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>{" "}
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Beneficiary Address Details */}
                {parmanatDetails && (
                  <>
                    <Form name="basic" autoComplete="off" layout={"vertical"}>
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Beneficiary Address Details
                              </span>
                            </h3>
                          </div>{" "}
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Address<span className="red">*</span>
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your First Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={permanant_address.permanant_Address}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_Address: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Street
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your First Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={permanant_address.permanant_street}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_street: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Building
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your middle Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={permanant_address.permanant_Bulding}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_Bulding: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Locality
                            </label>

                            <Form.Item>
                              <Input
                                className="p-2"
                                value={permanant_address.permanant_locality}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_locality: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Landmark<span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your First Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={permanant_address.permanant_landmark}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_landmark: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          {/* <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span> District
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your middle Name!",
                                },
                              ]}
                            >
                            
                              <Select
                                placeholder="--select--"
                                value={permanant_address.permanant_district}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_district: e,
                                  });
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select distict--
                                </Select.Option>
                                {districtData1.map((item, i) => {
                                  return (
                                    <>
                                      <Select.Option value={item}>
                                        {item}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Taluka <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Taluka!",
                                },
                              ]}
                            >
                             <Input
                            className="p-2"
                                value={permanant_address.permanant_taluka}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_taluka: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div> */}
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span> District
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your middle Name!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="--Select District--"
                                value={permanant_address.permanant_district}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_district: e,
                                  });
                                  setTalukasData(e);
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select District --
                                </Select.Option>
                                {districtData1.map((item, i) => {
                                  return (
                                    <>
                                      <Select.Option value={item.name}>
                                        {item.name}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              <span className="red">*</span> Taluka
                            </label>
                            <Form.Item>
                              <Select
                                placeholder="Select State"
                                value={permanant_address.permanant_taluka}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_taluka: e,
                                  });
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select Taluka--
                                </Select.Option>
                                {talukas.map((item, i) => {
                                  return (
                                    <>
                                      <Select.Option value={item}>
                                        {item}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                              <p className="red">
                                {Formerror.Applicant_taluka}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Village<span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Village!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={permanant_address.permanant_village}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_village: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Pincode <span className="red">*</span>
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Last Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={permanant_address.permanant_pincode}
                                onChange={(e) => {
                                  setpermanant_address({
                                    ...permanant_address,
                                    permanant_pincode: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          {/* <div className="col-md-4">
                    <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                      {" "}
                      UID No.
                    </label>

                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!",
                        },
                      ]}
                    >
                     <Input
                            className="p-2"
                        onChange={(e) => {
                          SetData({
                            ...Data,
                            application_last_name: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </div> */}
                        </div>
                      </div>
                    </Form>
                  </>
                )}
                {/* beneficiary migrated (Yes/No) */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            Beneficiary Migrated (Yes/No)
                          </span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-12">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Has beneficiary migrated
                          from a different state? (Yes/No) *
                        </label>
                        <form>
                          <Form.Item>
                            <Radio.Group>
                              <Radio
                                value="yes"
                                onClick={() => setMigrationView(true)}
                              >
                                Yes
                              </Radio>
                              <Radio
                                value="No"
                                onClick={() => setMigrationView(false)}
                              >
                                No
                              </Radio>
                            </Radio.Group>
                          </Form.Item>{" "}
                        </form>
                      </div>
                    </div>
                  </div>
                </Form>
                {/* Migration Address Details */}

                {migrationView && (
                  <>
                    <Form name="basic" autoComplete="off" layout={"vertical"}>
                      <div
                        className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                        style={{ backgroundColor: "#f9f9f9" }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Migration Address Details
                              </span>
                            </h3>
                          </div>{" "}
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              State<span className="red">*</span>
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your State!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={migration.migration_state}
                                onChange={(e) => {
                                  setMigration({
                                    ...migration,
                                    migration_state: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              District
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your District!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="--Select District--"
                                value={migration.migration_district}
                                onChange={(e) => {
                                  setMigration({
                                    ...migration,
                                    migration_district: e,
                                  });
                                  setTalukasData(e);
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select District --
                                </Select.Option>
                                {districtData1.map((item, i) => {
                                  return (
                                    <>
                                      <Select.Option value={item.name}>
                                        {item.name}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Taluka
                            </label>

                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Taluka",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select State"
                                value={migration.migration_taluka}
                                onChange={(e) => {
                                  setMigration({
                                    ...migration,
                                    migration_taluka: e,
                                  });
                                }}
                                showSearch
                              >
                                <Select.Option value="" key="default">
                                  --Select Taluka--
                                </Select.Option>
                                {talukas.map((item, i) => {
                                  return (
                                    <>
                                      <Select.Option value={item}>
                                        {item}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Village{" "}
                            </label>

                            <Form.Item>
                              <Input
                                className="p-2"
                                value={migration.migration_village}
                                onChange={(e) => {
                                  setMigration({
                                    ...migration,
                                    migration_village: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              From Date
                              <span className="red">*</span>
                            </label>

                            <Form.Item>
                              <DatePicker
                                onChange={handleDateChange_migration_fromDate}
                                format="DD-MM-YYYY"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              To Date
                              <span className="red">*</span>
                            </label>
                            <Form.Item>
                              <DatePicker
                                onChange={handleDateChange_migration_endDate}
                                format="DD-MM-YYYY"
                              />
                            </Form.Item>{" "}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              year{" "}
                            </label>

                            <Form.Item>
                              <Input
                                className="p-2"
                                value={migration.migration_year}
                                onChange={(e) => {
                                  setMigration({
                                    ...migration,
                                    migration_year: e.target.value,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </>
                )}

                {/* Beneficiary Caste/Category Details */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            Beneficiary Caste/Category Details
                          </span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-lg-6">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Caste <span className="red">*</span>
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Full Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={Data.benificiary_cast}
                                onChange={(e) => {
                                  setbenificiary_info({
                                    ...benificiary_info,
                                    benificiary_cast: e.target.value,
                                  });
                                }}
                              />
                              <p className="red m-2">
                                {Formerror.benificiary_cast}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 mt-4">
                            <span className="red ">
                              अर्ज भरताना दिलेल्या रकान्यामध्ये जातीचे शब्दलेखन
                              अचूक करावे, एकदा अर्ज स्वीकारल्यानंतर त्यात
                              कोणताही बदल करता येणार नाही.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                {/* Beneficiary Father Details  */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            Beneficiary Father Details{" "}
                          </span>
                        </h3>
                      </div>{" "}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Salutation<span className="red"></span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={
                              benificiary_father_details.benificiaryFather_salution
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_salution: e,
                              });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Salutation--
                            </Select.Option>
                            <Select.Option value="Kumar">Kumar</Select.Option>
                            <Select.Option value="Advocate">
                              Advocate
                            </Select.Option>
                            <Select.Option value="Shri">Mr.</Select.Option>
                            <Select.Option value="Kumari">Kumari</Select.Option>
                            <Select.Option value="Ms">Ms</Select.Option>
                            <Select.Option value="Shrimati">
                              Shrimati
                            </Select.Option>
                            <Select.Option value="Mrs">Mrs</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Full Name (English) <span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please enter Beneficiary's fathers full name in English."
                            value={
                              benificiary_father_details.benificiaryFather_fullName_english
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_fullName_english:
                                  e.target.value,
                              });
                              // setbenificiaryfatherLoading(true);
                            }}
                            onBlur={clickHandler4}
                          />
                          <p className="red m-2">
                            {Fathererror.benificiaryFather_fullName_english}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Full Name (Marathi) <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please enter Beneficiary's fathers full name in Marathi"
                            value={benificiaryfathermarathi}
                            onChange={(e) => {
                              // SetData({
                              //   ...Data,
                              //   fatherName_marathi: e.target.value,
                              // });
                              setbenificiaryfathermarathi(e.target.value);
                            }}
                          />
                          {benificiaryfatherLoading && (
                            <div class="loader"></div>
                          )}
                          <p className="red m-2">
                            {Fathererror.benificiaryFather_fullName_marathi}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span>Date Of Birth
                        </label>

                        <Form.Item>
                          <DatePicker
                            onChange={handleDateChange_benificiaryFatherDOB}
                            format="DD-MM-YYYY"
                          />
                          <p className="red m-2">
                            {Fathererror.benificiaryFather_DOB}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Age <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Age!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please enter Beneficiary's fathers age"
                            value={
                              benificiary_father_details.benificiaryFather_age
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_age: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      {/* <div className="col-md-4">
                    <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                      {" "}
                      <span className="red">*</span>Relation
                    </label>

                    <Form.Item>
                      <Select
                        placeholder="Vehicle Type"
                        onChange={(e) => {
                          SetData({ ...Data, relation: e });
                        }}

                        //   value={Relation}
                      >
                        <Select.Option value="Father"></Select.Option>
                        <Select.Option value="Mother"></Select.Option>
                        <Select.Option value="Husband"></Select.Option>
                        <Select.Option value="Gaurdian"></Select.Option>
                      </Select>
                    </Form.Item>
                  </div> */}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Mobile No <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="number"
                            placeholder="Please enter Beneficiary's fathers Mob. No."
                            value={
                              benificiary_father_details.benificiaryFather_mobileNO
                            }
                            onChange={(e) => {
                              // setbenificiary_father_details({
                              //   ...benificiary_father_details,
                              //   benificiaryFather_mobileNO: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 10) {
                                setbenificiary_father_details({
                                  ...benificiary_father_details,
                                  benificiaryFather_mobileNO: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Phone Number");
                                setbenificiary_father_details({
                                  ...benificiary_father_details,
                                  benificiaryFather_mobileNO: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                          <p className="red m-2">
                            {Fathererror.benificiaryFather_mobileNO}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Email
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please enter Beneficiary's fathers Email"
                            value={
                              benificiary_father_details.benificiaryFather_email
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_email: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          UID No.
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please enter Beneficiary's fathers UID NO!"
                            type="number"
                            value={
                              benificiary_father_details.benificiaryFather_UID
                            }
                            onChange={(e) => {
                              // setbenificiary_father_details({
                              //   ...benificiary_father_details,
                              //   benificiaryFather_UID: e.target.value,
                              // });
                              const value = e.target.value;
                              // Remove non-numeric characters
                              const sanitizedValue = value.replace(/\D/g, "");

                              // Check if the input is a valid phone number (exactly 10 digits)
                              if (sanitizedValue.length <= 12) {
                                setbenificiary_father_details({
                                  ...benificiary_father_details,
                                  benificiaryFather_UID: sanitizedValue,
                                });
                              } else {
                                alert("Please Enter Valid Phone Number");
                                setbenificiary_father_details({
                                  ...benificiary_father_details,
                                  benificiaryFather_UID: "", // Reset the adharNumber if it's too long
                                });
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Occupation<span className="red"></span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={
                              benificiary_father_details.benificiaryFather_occupation
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_occupation: e,
                              });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Occupation--
                            </Select.Option>
                            {fatherOccupation.map((item, i) => (
                              <Select.Option value={item} key={i}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Beneficiary Father Address Details */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            Beneficiary Father Address Details
                          </span>
                        </h3>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Address<span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please enter Beneficiary's fathers address.!"
                            value={
                              benificiary_father_details.benificiaryFather_Address
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_Address: e.target.value,
                              });
                            }}
                          />
                          <p className="red m-2">
                            {Fathererror.benificiaryFather_Address}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Street
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="text"
                            placeholder="Please enter street name"
                            value={
                              benificiary_father_details.benificiaryFather_street
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_street: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Building
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your middle Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="number"
                            placeholder="Please enter building number"
                            value={
                              benificiary_father_details.benificiaryFather_building
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_building: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Locality
                        </label>

                        <Form.Item>
                          <Input
                            className="p-2"
                            type="text"
                            placeholder="Please enter locality name"
                            value={
                              benificiary_father_details.benificiaryFather_Locality
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_Locality: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Landmark
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            placeholder="Please enter Landmark !"
                            value={
                              benificiary_father_details.benificiaryFather_Landmark
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_Landmark: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span> District
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your middle Name!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="--Select District--"
                            value={
                              benificiary_father_details.benificiaryFather_District
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_District: e,
                              });
                              setTalukasData(e);
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select District --
                            </Select.Option>
                            {districtData1.map((item, i) => {
                              return (
                                <>
                                  <Select.Option value={item.name}>
                                    {item.name}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                          <p className="red m-2">
                            {Fathererror.benificiaryFather_District}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span> Taluka
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="Select State"
                            value={
                              benificiary_father_details.benificiaryFather_Taluka
                            }
                            onChange={(e) => {
                              // SetData({ ...Data, benificiaryFather_Taluka: e });
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_Taluka: e,
                              });
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select Taluka--
                            </Select.Option>
                            {talukas.map((item, i) => {
                              return (
                                <>
                                  <Select.Option value={item}>
                                    {item}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                          <p className="red m-2">
                            {Fathererror.benificiaryFather_Taluka}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span> village
                        </label>
                        <Form.Item>
                          <Input
                            className="p-2"
                            type="text"
                            placeholder="Please input your Village!"
                            value={
                              benificiary_father_details.benificiary_father_details
                            }
                            onChange={(e) => {
                              // SetData({
                              //   ...Data,
                              //   benificiaryFather_Village: e.target.value,
                              // });
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_Village: e.target.value,
                              });
                            }}
                          />
                          <p className="red m-2">
                            {Fathererror.benificiaryFather_Village}
                          </p>
                        </Form.Item>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Pincode <span className="red">*</span>
                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Last Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            type="number"
                            placeholder="Please input Pincode!"
                            value={
                              benificiary_father_details.benificiaryFather_PinCode
                            }
                            onChange={(e) => {
                              setbenificiary_father_details({
                                ...benificiary_father_details,
                                benificiaryFather_PinCode: e.target.value,
                              });
                            }}
                          />
                          <p className="red m-2">
                            {Fathererror.benificiaryFather_PinCode}
                          </p>
                        </Form.Item>
                      </div>
                      {/* <div className="col-md-4">
                    <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                      {" "}
                      UID No.
                    </label>

                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!",
                        },
                      ]}
                    >
                     <Input
                            className="p-2"
                        onChange={(e) => {
                          SetData({
                            ...Data,
                            application_last_name: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </div> */}
                    </div>
                  </div>
                </Form>

                {/* Beneficiary Caste/Category Details */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">
                            Beneficiary Caste/Category Details
                          </span>
                        </h3>
                      </div>

                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-lg-6">
                            <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                              {" "}
                              Caste <span className="red">*</span>
                            </label>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Full Name!",
                                },
                              ]}
                            >
                              <Input
                                className="p-2"
                                value={
                                  benificiary_father_details.benificiaryFather_cast
                                }
                                onChange={(e) => {
                                  setbenificiary_father_details({
                                    ...benificiary_father_details,
                                    benificiaryFather_cast: e.target.value,
                                  });
                                }}
                              />
                              <p className="red m-2">
                                {Fathererror.benificiaryFather_cast}
                              </p>
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 mt-4">
                            <span className="red">
                              अर्ज भरताना दिलेल्या रकान्यामध्ये जातीचे शब्दलेखन
                              अचूक करावे, एकदा अर्ज स्वीकारल्यानंतर त्यात
                              कोणताही बदल करता येणार नाही.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Land Details */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Land Details</span>
                        </h3>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Salutation<span className="red"></span>
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={land_info.land_salutation}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_salutation: e,
                              });
                            }}
                          >
                            <Select.Option value="" key="default">
                              --Select Salutation--
                            </Select.Option>

                            <Select.Option value="Kumar">Kumar</Select.Option>
                            <Select.Option value="Advocate">
                              Advocate
                            </Select.Option>
                            <Select.Option value="Shri">Mr.</Select.Option>
                            <Select.Option value="Kumari">Kumari</Select.Option>
                            <Select.Option value="Ms">Ms</Select.Option>
                            <Select.Option value="Shrimati">
                              Shrimati
                            </Select.Option>
                            <Select.Option value="Mrs">Mrs</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Land Holder Name (English){" "}
                          <span className="red">*</span>
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={land_info.land_holderName_english}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_holderName_english: e.target.value,
                              });
                            }}
                            onBlur={clickHandler5}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Land Holder Name (Marathi)

                        </label>

                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your Full Name!",
                            },
                          ]}
                        >
                          <Input
                            className="p-2"
                            value={lnameMarathi}
                            onChange={(e) => {
                              setlnameMarathi(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                         Relation
                        </label>

                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={land_info.land_holder_relation}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_holder_relation: e,
                              });
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select Relation--
                            </Select.Option>
                            {relationOfBanificiary_Applicant.map((item, i) => {
                              return (
                                <>
                                  <Select.Option value={item} key={i}>
                                    {item}
                                  </Select.Option>
                                </>
                              );
                            })}
                            <Select.Option value="Kumar">Kumar</Select.Option>
                            <Select.Option value="Advocate">
                              Advocate
                            </Select.Option>
                            <Select.Option value="Shri">Mr.</Select.Option>
                            <Select.Option value="Kumari">Kumari</Select.Option>
                            <Select.Option value="Ms">Ms</Select.Option>
                            <Select.Option value="Shrimati">
                              Shrimati
                            </Select.Option>
                            <Select.Option value="Mrs">Mrs</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          District 
                        </label>

                        <Form.Item>
                          <Select
                            placeholder="--Select District--"
                            value={land_info.land_holder_district}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_holder_district: e,
                              });

                              setTalukasData(e);
                            }}
                            showSearch
                          >
                            <Select.Option value="" key="default">
                              --Select District --
                            </Select.Option>
                            {districtData1.map((item, i) => {
                              return (
                                <>
                                  <Select.Option value={item.name}>
                                    {item.name}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Taluka
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="Select State"
                            value={land_info.land_holder_taluka}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_holder_taluka: e,
                              });
                            }}
                          >
                            {" "}
                            <Select.Option value="" key="default">
                              --Select Taluka --
                            </Select.Option>
                            {talukas.map((item, i) => {
                              return (
                                <>
                                  <Select.Option value={item}>
                                    {item}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          village
                        </label>
                        {/* <Form.Item>
                          <Select
                            placeholder="Select State"
                            value={land_info.land_holder_village}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_holder_village: e,
                              });
                            }}
                          >
                            <Select.Option value="barshi"></Select.Option>
                          </Select>
                        </Form.Item> */}
                        <Form.Item>
                          <Input
                            className="p-2"
                            type="text"
                            placeholder="Please Input Village!"
                            value={land_info.land_holder_village}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_holder_village: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          PinCode
                        </label>

                        <Form.Item>
                          <Input
                            className="p-2"
                            type="Number"
                            value={land_info.land_holder_pincode}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_holder_pincode: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Gut No.
                        </label>

                        <Form.Item>
                          <Input
                            className="p-2"
                            value={land_info.land_holder_groupNO}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_holder_groupNO: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Area
                        </label>

                        <Form.Item>
                          <Input
                            className="p-2"
                            value={land_info.land_holder_area}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_holder_area: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                      {/* <div className="col-md-4">
                    <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                      {" "}
                      <span className="red">*</span>Relation
                    </label>

                    <Form.Item>
                      <Select
                        placeholder="Vehicle Type"
                        onChange={(e) => {
                          SetData({ ...Data, relation: e });
                        }}

                        //   value={Relation}
                      >
                        <Select.Option value="Father"></Select.Option>
                        <Select.Option value="Mother"></Select.Option>
                        <Select.Option value="Husband"></Select.Option>
                        <Select.Option value="Gaurdian"></Select.Option>
                      </Select>
                    </Form.Item>
                  </div> */}
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                        </label>
                        <Form.Item>
                          <Select
                            placeholder="--select--"
                            value={land_info.land_Area_unit}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                land_Area_unit: e,
                              });
                            }}
                          >
                            <Select.Option value="Acres">Acres</Select.Option>
                            <Select.Option value="Yards">Yards</Select.Option>
                            <Select.Option value="Other">
                              Square Mtrs
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          Is your land owned outside the village?(Y/N)
                          
                        </label>
                        <Form.Item>
                          <Radio.Group
                            value={land_info.is_land_outside_village}
                            onChange={(e) => {
                              setland_info({
                                ...land_info,
                                is_land_outside_village: e.target.value,
                              });
                            }}
                          >
                            <Radio value="yes">हो </Radio>
                            <Radio value="No">नाही</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>

                {/* Family income details through various sources */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-bg" role="form">
                          <div
                            className="col-lg-12 text-center border rounded mb-3"
                            style={{ backgroundColor: "#e0e0e0" }}
                          >
                            <h3 class="box-title">
                              <span id="CPH_lbl_AppDtl">
                                Family income details through various sources
                              </span>
                            </h3>
                          </div>
                          <div class="box-body">
                            <div class="row">
                              <div class="col-md-12 table-responsive">
                                <table
                                  id="CPH_Table1"
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="table-striped form-table"
                                >
                                  <tbody>
                                    <tr>
                                      <th>
                                        <span id="CPH_lblseno">Sr.No </span>
                                      </th>
                                      <th>
                                        <span id="CPH_lblincmesources">
                                          Income Source :
                                        </span>
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>1.</td>
                                      <td>
                                        <span id="CPH_Agriculture">
                                          Income from Salary / Wages
                                        </span>
                                      </td>
                                      <td id="CPH_td11">
                                        <div class="icon-addon">
                                          {/* <input
                                            class="lbl_value rupees form-control form-control"
                                            name="Income_salary"
                                            value={Data.Income_salary}
                                            onChange={(e) => {
                                              SetData({
                                                ...Data,
                                                Income_salary: parseInt(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            onBlur={() => {
                                              totalfun();
                                            }}
                                          /> */}
                                          <div class="input-group mb-3">
                                            <span class="input-group-text">
                                              ₹
                                            </span>
                                            <input
                                              class="form-control"
                                              type="Number"
                                              value={Data.Income_salary}
                                              onChange={(e) => {
                                                SetData({
                                                  ...Data,
                                                  Income_salary: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              onBlur={() => {
                                                totalfun();
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>2.</td>
                                      <td>
                                        <span id="CPH_Business">
                                          Income from Business Enterprises
                                        </span>
                                      </td>
                                      <td id="CPH_td12">
                                        <div class="icon-addon">
                                          <div class="input-group mb-3">
                                            <span class="input-group-text">
                                              ₹
                                            </span>
                                            <input
                                              class="form-control"
                                              type="Number"
                                              value={Data.Income_business}
                                              onChange={(e) => {
                                                SetData({
                                                  ...Data,
                                                  Income_business: parseInt(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              onBlur={() => {
                                                totalfun();
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span id="CPH_lbl3">3.</span>
                                      </td>
                                      <td>
                                        <span id="CPH_Others">
                                          Income from Agriculture
                                        </span>
                                      </td>
                                      <td id="CPH_td13">
                                        {/* <div class="icon-addon">
                                          <span>
                                            <i class="fa fa-inr"></i>
                                          </span>
                                          <input
                                            class="lbl_value rupees form-control form-control"
                                            value={Data.Income_agriculture}
                                            onChange={(e) => {
                                              SetData({
                                                ...Data,
                                                Income_agriculture: parseInt(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            onBlur={() => {
                                              totalfun();
                                            }}
                                          />
                                        </div> */}
                                        <div class="input-group mb-3">
                                          <span class="input-group-text">
                                            ₹
                                          </span>
                                          <input
                                            class="form-control"
                                            type="Number"
                                            value={Data.Income_agriculture}
                                            onChange={(e) => {
                                              SetData({
                                                ...Data,
                                                Income_agriculture: parseInt(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            onBlur={() => {
                                              totalfun();
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span id="CPH_lbl3">4.</span>
                                      </td>
                                      <td>
                                        <span id="CPH_Others">
                                          Income from Investment
                                        </span>
                                      </td>
                                      <td id="CPH_td13">
                                        <div class="input-group mb-3">
                                          <span class="input-group-text">
                                            ₹
                                          </span>
                                          <input
                                            class="form-control"
                                            type="Number"
                                            value={Data.Income_investment}
                                            onChange={(e) => {
                                              SetData({
                                                ...Data,
                                                Income_investment: parseInt(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            onBlur={() => {
                                              totalfun();
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span id="CPH_lbl3">5.</span>
                                      </td>
                                      <td>
                                        <span id="CPH_Others">
                                          Income from Others
                                        </span>
                                      </td>
                                      <td id="CPH_td13">
                                        <div class="input-group mb-3">
                                          <span class="input-group-text">
                                            ₹
                                          </span>
                                          <input
                                            class="form-control"
                                            type="Number"
                                            value={Data.Income_others}
                                            onChange={(e) => {
                                              SetData({
                                                ...Data,
                                                Income_others: parseInt(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            onBlur={() => {
                                              totalfun();
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>
                                        <span id="CPH_lblseno">
                                          Total Income
                                        </span>
                                      </th>
                                      <td id="CPH_td13">
                                        <div class="input-group mb-3 ml-5">
                                          <span class="input-group-text">
                                            ₹
                                          </span>
                                          <input
                                            class="form-control"
                                            type="Number"
                                            value={Data?.total}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <td></td>
                                    <tr>
                                      <td></td>
                                      {/* <span id="CPH_Label86" style="color:Red;">*</span> */}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                <div className="row">
                  <div className="col-lg-12">
                  <a href="https://mhae-seva.s3.amazonaws.com/PanAccept/selff+ewssss.pdf" target="_blank" className="fs-5"><b>स्वघोषणापत्र</b></a>
                  </div>
                </div>
                {/* Upload required documents */}
                <div
                  className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  <div className="row">
                    <div
                      className="col-lg-12 text-center border rounded mb-3"
                      style={{ backgroundColor: "#e0e0e0" }}
                    >
                      <h3 class="box-title">
                        <span id="CPH_lbl_AppDtl">
                          Upload required documents
                        </span>
                      </h3>
                    </div>
                    <div className="col-md-12">
                      <h6 className="red fs-6">
                        <b>
                          अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व आवश्यक
                          कागदपत्रे तपासून व स्कॅन करून अपलोड करावे. जर आवश्यक
                          कागदपत्रे चुकीची किंवा अस्पष्ट आढळल्यास सदर चा अर्ज
                          फेटाळला जाऊ शकतो.
                        </b>
                      </h6>
                      <h6 className="red fs-6">
                        <b>
                          IMP NOTE :-१) १९६७ पूर्वी जन्म झालेल्या (आजोबा , चुलते
                          , आत्या ) यांच्यापैकी एकाचा जात नमूद शाळेचा दाखला २)
                          उत्पन्न ७ लाखा पेक्षा कमी असावे , ३) शाळेच्या दाखल्यात
                          जात नमूद असावी.४)उत्पन्न दाखला.
                        </b>
                      </h6>
                    </div>

                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Address proof (Size -
                        Maximum 1 MB) [Only (jpg,jpeg,pdf)]
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            adharCard: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.adharCard}</p>
                    </div>

                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>शाळा सोर्ल्याचा
                        दाखला(Maximum 500Kb)PDF
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            schoolLeaveCertificate: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">
                        {FormFileerror.schoolLeaveCertificate}
                      </p>
                    </div>

                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        लाईट बिल (Maximum 500Kb)PDF
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            lightBill: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.lightBill}</p>
                    </div>

                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        रेशनकार्ड(Maximum 500Kb)PDF
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            reshaCard: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.reshaCard}</p>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        १९६७ पूर्वीचा परवाना (Maximum 500Kb)PDF
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            castProof: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.castProof}</p>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>३ वषउत्पन्न दाखला/ फॉम १६
                        (Maximum 500Kb)PDF
                      </label>

                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            incomeCertficate3yearOrForm16: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">
                        {FormFileerror.incomeCertficate3yearOrForm16}
                      </p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>self Declearation (Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            selfDeclearation: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.selfDeclearation}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Father Adhar Card (Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            FatherAdhar: e.target.files[0],
                          });
                          // console.log(992,e);
                        }}
                      />
                      <p className="red">{FormFileerror.FatherAdhar}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Benifiary Aadhar (Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            childrenAdhar: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">{FormFileerror.childrenAdhar}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Applicant Photo(Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            applicantPhoto: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">{FormFileerror.applicantPhoto}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Benificiary Photo(Size -
                        Maximum 500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            benificiarryPhoto: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">{FormFileerror.benificiarryPhoto}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span>Father School
                        leaveCertificate (Size - Maximum 500 Kb) [Only
                        (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            fatherSchoolLeaveCer: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">
                        {FormFileerror.fatherSchoolLeaveCer}
                      </p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        <span className="red">*</span> Benifiary School
                        leaveCertificate (Size - Maximum 500 Kb) [Only
                        (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            childrenSchoolLeaveCer: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">
                        {FormFileerror.childrenSchoolLeaveCer}
                      </p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                        Other (Size - Maximum 500
                        Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            otherFirst: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">{FormFileerror.otherFirst}</p>
                    </div>
                    <div className="col-md-6 mb-3 p-2">
                      <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                        {" "}
                       Other|| (Size - Maximum
                        500 Kb) [Only (jpg,jpeg)]
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name=""
                        id=""
                        onChange={(e) => {
                          setDocs({
                            ...docs,
                            otherSecond: e.target.files[0],
                          });
                        }}
                      />
                      <p className="red">{FormFileerror.otherSecond}</p>
                    </div>
                  </div>
                </div>

                {/* Agreement */}
                <Form name="basic" autoComplete="off" layout={"vertical"}>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <div className="row">
                      <div
                        className="col-lg-12 text-center border rounded mb-3"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Agreement</span>
                        </h3>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="" className="mb-3 fs-6 fw-bolder">
                          {" "}
                          <span className="red">*</span> मी घोषित करतो/ करते की
                          वरील सर्व माहिती माझ्या व्यक्तीगत माहिती व समजुतीनुसार
                          खरी आहे. सदर माहिती खोटी आढळून आल्यास, भारतीय दंड
                          संहिता १९६० कलम १९९ व २०० व अन्य/ संबंधित कायदयानुसार
                          माझ्यावर खटला भरला जाईल व त्यानुसार मी शिक्षेस पात्र
                          राहीन याची मला पूर्ण जाणीव आहे.
                        </label>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please fill that!",
                            },
                          ]}
                        >
                          <Radio.Group onChange={(e) => setAgree(true)}>
                            <Radio value="yes">मला मंजूर</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>

                <div className="row">
                  <div class="col-md-12 text-center">

                  {showloading ? (
                      <>
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) :(<>
                    {id == undefined ? (
                      <input
                        type="submit"
                        name="ctl00$CPH$btnsubmit"
                        value="Proceed"
                        onClick={(e) => {
                          EWSFormSubmit(e);
                        }}
                        id="CPH_btnsubmit"
                        tabindex="141"
                        class="btn btn-success m-3"
                      />
                    ) : (
                      <input
                        type="submit"
                        name="ctl00$CPH$btnsubmit"
                        value="Update"
                        onClick={(e) => {
                          editHandler(e);
                        }}
                        id="CPH_btnsubmit"
                        tabindex="141"
                        class="btn btn-success m-3"
                      />
                    )}

                    <input
                      type="submit"
                      name="ctl00$CPH$btnback"
                      value="Back"
                      onclick='return back();WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions("ctl00$CPH$btnback", "", true, "", "", false, false))'
                      id="CPH_btnback"
                      tabindex="142"
                      class="btn btn-warning m-3"
                    />
                    <input
                      type="submit"
                      name="ctl00$CPH$btnreset"
                      value="Reset"
                      onclick='return disp_confirm();WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions("ctl00$CPH$btnreset", "", true, "", "", false, false))'
                      id="CPH_btnreset"
                      tabindex="143"
                      class="btn btn-danger m-3"
                    />
                    
                    </>)
                    }

                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EWS;
