import React from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Breadcrumb = ({title,parent}) => {
	return (
		// <Container fluid={true}>
			<div className="page-header">
			
				<div className="row">
					<div className="col-lg-12">
						
						<div className="page-header-left" style={{width:"100%"}}>
							<h3>
								{title}
							</h3>
						</div>
					</div>
					

				</div>
			</div>
		// </Container>
	);
};

export default Breadcrumb;
