import changeNumber from "./reducers";
import { combineReducers } from "redux";
import { UserReducer,rechargeReducer,Userexpences,UserBalance,formsPrices, allFormDatareducer, formElevenReducer, formFourReducer, SingleUserFormsReducer, SingleUserTehsilFormsReducer, SingleAgentTehsilFormsReducer, } from "./Index";


const rootReducer = combineReducers({
    changeNumber: changeNumber,
     
    userData: UserReducer,
    recharge : rechargeReducer,
    expence : Userexpences,
    balance : UserBalance,
    price:formsPrices,
    allFormData:allFormDatareducer,
    formeleven:formElevenReducer,
    formFour:formFourReducer,
    singleUserForms:SingleUserFormsReducer,
    singleUserTehsilForms:SingleUserTehsilFormsReducer,
    SingleAgentForms:SingleAgentTehsilFormsReducer
  
})


export default rootReducer