import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import './styles.css';
// import './Application.css';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";

import { incNumber } from "../../Redux/actions";
import Breadcrumb from "../common/breadcrumb";

function Retailer_income() {
  const navigate =useNavigate();

  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));

  const [status, setStatus] = useState("");

  let { id } = useParams();
  const [selectedItem, setselectedItem] = useState(null);
  console.log(selectedItem);

  const dispatch = useDispatch();

  const form4 = useSelector((state) => state.formFour.form4);
  const [AllFOrm, setAllForm] = useState(form4);
  const getData = () => {
    if (AllFOrm.length > 0) {
      const selectedData = AllFOrm.find((item) => item._id === id);
      setselectedItem(selectedData);
    } else {
      axios
        .get("https://api.maharashtraseva.com/api/getAll")
        .then((res) => {
          const data = res.data;
          const selectedData = data.find((item) => item._id === id);
          setselectedItem(selectedData);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getData();
    // if (
    //   userRespons.user.role === "retailer" &&
    //   selectedItem?.status === "IN-PROGRESS"
    // ) {
     
    // } else if (
    //   userRespons.user.role === "admin" &&
    //   selectedItem?.status != "IN-PROGRESS"
    // ) {
     
    // } else {
    // axios
    //   .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, {
    //     isNew: false,
    //   })
    //   .then((res) => {
    //     dispatch(incNumber());
    //   })
    //   .catch((err) => console.log(err));
    //    }

    if(
      userRespons.user.role === "agent" &&
      selectedItem?.status != "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ){
      axios
      .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, { isNew: true })
      .then((res) => {
        dispatch(incNumber());
      })
      .catch((err) => console.log(err));
    }
   else if (
      userRespons.user.role === "agent" &&
      selectedItem?.status == "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status == "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, { isNew: true })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status != "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    }
  }, [id, form4]);
  const [subNote,setSubNote]=useState("");
  const [compNote,setCompNote]=useState("");
  const [rejNote,,setRejNote]=useState("");
  const [Acknowledgment, setAcknowledgment] = useState({
    Acknowledgment: "",
  });
  const [finalDocs, setfinalDocs] = useState({
    finalDocs: "",
  });
 
  const FormSubmit = (e) => {
    e.preventDefault();

    if (
      selectedItem.status === "IN-PROGRESS" ||
      selectedItem.status === "IN-Progress"
    ) {
      const formData = new FormData();
      formData.append("acknowledgmentDocument", Acknowledgment.Acknowledgment);
      axios
        .put(
          `https://api.maharashtraseva.com/api/incomeCertificate_acknowledgmentDocument/${id}`,
          formData
        )
        .then((res) => console.log("acknowledgmentDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
        const obj = {
          ...selectedItem,
          status: status,
          subNote: subNote,
          submitAt: new Date(),
          isNew: true,
        };
      axios
        .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, obj)
        .then((res) => {console.log("acknowledgmentDocument", res.data)
        dispatch(incNumber());
        swal("updated successfully", "success");
      }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedItem.status === "SUBMITTED") {
      const formData = new FormData();

      formData.append("finalDocument", finalDocs.finalDocs);
      axios
        .put(
          `https://api.maharashtraseva.com/api/incomeCertificate_finalDocument/${id}`,
          formData
        )
        .then((res) => console.log("finalDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
        const obj = {
          ...selectedItem,
          status: status,
          compNote: compNote,
          CompleteAt: new Date(),
          isNew: true,
        };
      axios
        .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, obj)
        .then((res) => {console.log("finalDocument", res.data);
        dispatch(incNumber());
        swal("updated successfully", "success");
      }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    }else  if (status === "REJECTED") {
      const obj = {
        ...selectedItem,
        status: status,
        rejNote: rejNote,
        rejectAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/income_Certificate/${id}`, obj)
        .then((res) => {
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))   
        .catch((err) => console.log(err));
    } 
  };
   const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  return (
    <>
      <Breadcrumb title={"Income Certificate"} />
      {selectedItem != null ? (
        <Container fluid={true}>
          <Row>
            <Col sm="">
              <Card>
                <CardBody>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <form class="inline-block form-inline ms-auto ">
                      {/* <div
                        className="col-lg-12 text-center border rounded"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Details</span>
                        </h3>
                      </div> */}
                         <div className="row">
                        <div className="col-lg-10">
                        {/* <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Details </span>
                        </h3> */}
                          
                        </div>
                        <div className="col-lg-2 mt-1">
                        <button
                            class="btn btn-outline-dark"
                            type="button"
                            onClick={() =>{
                              if (userRespons.user.role=="retailer") {
                                navigate(
                                  `${process.env.PUBLIC_URL}/RetailerForm4`
                                )
                              }else if(userRespons.user.role=="admin"){
                              navigate(
                                `${process.env.PUBLIC_URL}/ts`
                              )
                              }else{
                                navigate(
                                  `${process.env.PUBLIC_URL}/agent/application_Console`
                                )
                              }
                            }
                            }
                          >
                            Back
                          </button>
                        </div>
                      </div>
                      <div id="layoutSidenav_content">
                        <main>
                          <div class="container-fluid px-4 mt-4">
                            <div class="formlayout">
                              {/* अर्जदाराचा तपशील */}
                              <div class="row g-3">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      अर्जदाराचा तपशील
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> पूर्ण नाव(इंग्रजी) :</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["statusfname"] +
                                    ".  " +
                                    selectedItem.Data["fullName_English"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> पूर्ण नाव (मराठी) :</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["fullName_Marathi"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>वडिलांचे नाव(इंग्रजी) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["fatherName_english"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>वडिलांचे नाव(मराठी) :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["fatherName_marathi"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>मिळकत प्रमाणपत्र :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["incoCerYear"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>जन्मतारीख:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["BirthDate"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>वय:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["age"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>लिंग:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["Gender"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>व्यवसाय:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["work_type"]}{" "}
                                </div>

                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>भ्रमणध्वनी क्रमांक:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["phoneNUm"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>ई-मेल:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["email"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>पॅनकार्ड क्रमांक:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["PanNo"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>आधारकार्ड क्रमांक:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["AdharNo"]}{" "}
                                </div>
                              </div>
                              {/* अर्जदाराच्या निवासाचे तपशील */}
                              <div class="row g-3 mt-4">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      अर्जदाराच्या निवासाचे तपशील
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> पत्ता :</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["address"]}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> मार्ग:</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["streetName"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>विभाग :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["depart"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>इमारत :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["Building"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>लॅंडमार्क :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["landmark"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>जिल्हा:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["district"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>तालुका:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["taluka"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>गाव:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["village"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>पिनकोड:</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["pincode"]}{" "}
                                </div>
                              </div>
                              {/* कुटुंबातील सज्ञान व्यक्तींची माहीती : */}
                              <div class="row g-3 mt-4">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      कुटुंबातील सज्ञान व्यक्तींची माहीती :
                                    </span>
                                  </h3>
                                </div>
                                {selectedItem.family.map((item) => {
                                  return (
                                    <>
                                      <div className="row mt-4 border-bottom border-3 ">
                                        <div class="col-md-6 mt-3 border-bottom border-2">
                                          <label>
                                            {" "}
                                            <b> नाते:</b>
                                          </label>
                                          <br />
                                          {item.relation}{" "}
                                        </div>
                                        <div class="col-md-6 mt-3 border-bottom border-2">
                                          <label>
                                            {" "}
                                            <b> कुटुंबातील व्यक्तीचे नाव :</b>
                                          </label>
                                          <br />
                                          {item.name}
                                        </div>
                                        <div class="col-md-6 mt-3 border-bottom border-2">
                                          <label>
                                            <b>उत्पन्न :</b>{" "}
                                          </label>
                                          <br />
                                          {item.income}
                                        </div>
                                        <div class="col-md-6 mt-3 border-bottom border-2">
                                          <label>
                                            <b>व्यवसाय/नोकरी :</b>{" "}
                                          </label>
                                          <br />
                                          {item.occupation}{" "}
                                        </div>
                                        <div class="col-md-6 mt-3 border-bottom border-1">
                                          <label>
                                            <b>वय :</b>{" "}
                                          </label>
                                          <br />
                                          {item.age}{" "}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>

                              {/* लाभार्थीचा तपशील */}
                              <div class="row g-3 mt-4">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      लाभार्थीचा तपशील
                                    </span>
                                  </h3>
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> सदर व्यक्तीचे अर्जदाराशी नाते :</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["relationOfApplicant"]}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> संबोधन:</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["statusOfApplicant"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>लाभार्थीचे नाव :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["NameOfApplicant"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>प्रमाणपत्र का आवश्यक आहे :</b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["Building"]}{" "}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                    प्रमाणपत्र का आवश्यक आहे :
                                    </b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["ReasonOfApllication"]}{" "}
                                  {/* {selectedItem.Data["ReasonOfincome_get"]}{" "} */}
                                </div>
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    <b>
                                      उत्पन्नाचे प्रमाणपत्र कशासाठी हवे त्याचा
                                      तपशील :
                                    </b>{" "}
                                  </label>
                                  <br />
                                  {selectedItem.Data["ReasonOfincome_get"]}{" "}
                                </div>
                                
                               
                              </div>
                              {/* कुटुंबाला शेतीपासून मिळणाऱ्या उत्पन्नाचे विवरण: */}
                              <div class="row g-3 mt-4">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                      कुटुंबातील सज्ञान व्यक्तींची माहीती :
                                    </span>
                                  </h3>
                                </div>
                                {selectedItem.distibutionOfIncomefromAgri.map(
                                  (item) => {
                                    return (
                                      <>
                                        <div className="row mt-4 border-bottom border-3 ">
                                          <div class="col-md-6 mt-3 border-bottom border-2">
                                            <label>
                                              {" "}
                                              <b> खातेदाराचे नाव:</b>
                                            </label>
                                            <br />
                                            {item.accountHolder_Name}{" "}
                                          </div>
                                          <div class="col-md-6 mt-3 border-bottom border-2">
                                            <label>
                                              {" "}
                                              <b>शेतीचे एकूण क्षेत्र :</b>
                                            </label>
                                            <br />
                                            {item.totalfarm}
                                          </div>
                                          <div class="col-md-6 mt-3 border-bottom border-2">
                                            <label>
                                              <b>शेतीचे विवरण :</b>{" "}
                                            </label>
                                            <br />
                                            {item.farmingdetails}
                                          </div>
                                          <div class="col-md-6 mt-3 border-bottom border-2">
                                            <label>
                                              <b>जिल्हा:</b>{" "}
                                            </label>
                                            <br />
                                            {item.accountHolder_DIstrict}{" "}
                                          </div>
                                          <div class="col-md-6 mt-3 border-bottom border-1">
                                            <label>
                                              <b>तालुका :</b>{" "}
                                            </label>
                                            <br />
                                            {item.accountHolder_Taluka}{" "}
                                          </div>
                                          <div class="col-md-6 mt-3 border-bottom border-1">
                                            <label>
                                              <b>गाव :</b>{" "}
                                            </label>
                                            <br />
                                            {item.accountHolder_village}{" "}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                              {/* कुटुंबाला शेतीपासून मिळणाऱ्या उत्पन्नाचे विवरण: */}
                              <div class="row g-3 mt-4">
                                <div
                                  className="col-lg-12 text-center border rounded"
                                  style={{ backgroundColor: "#e0e0e0" }}
                                >
                                  <h3 class="box-title">
                                    <span id="CPH_lbl_AppDtl">
                                    विविध स्रोतांमार्फत कुटुंबाच्या उत्पन्नाचा तपशील :
                                    </span>
                                  </h3>
                                </div>
                                {selectedItem.incomeSource.map(
                                  (item) => {
                                    const keys = Object.keys(item);
                                    console.log(507,keys)
                                    return (
                                      <>
                                     
                                          <div className="row mt-4 border-bottom border-3 ">
                                            <div class="col-md-6 mt-3 border-bottom border-2">
                                              <label>
                                              
                                                <b> {keys[0]}</b>
                                              </label>
                                              <br />
                                              {item["2021"]}
                                            </div>
                                            <div class="col-md-6 mt-3 border-bottom border-2">
                                              <label>
                                              
                                                <b>{keys[1]}</b>
                                              </label>
                                              <br />
                                              {item["2022"]}
                                            </div>
                                            <div class="col-md-6 mt-3 border-bottom border-2">
                                              <label>
                                                <b>{keys[2]}</b>{" "}
                                              </label>
                                              <br />
                                              {item["2023"]}
                                            </div>
                                            <div class="col-md-6 mt-3 border-bottom border-2">
                                              <label>
                                                <b>{keys[3]}</b>{" "}
                                              </label>
                                              <br />
                                              {item.sourceName}{" "}
                                            </div>
                                            <div class="col-md-6 mt-3 border-bottom border-1">
                                              <label>
                                                <b>{keys[4]}</b>{" "}
                                              </label>
                                              <br />
                                              {item.discription}{" "}
                                            </div>
                                            {/* <div class="col-md-6 mt-3 border-bottom border-1">
                                              <label>
                                                <b>गाव :</b>{" "}
                                              </label>
                                              <br />
                                              {item.accountHolder_village}{" "}
                                            </div> */}
                                          </div>
                                      </>
                                    );
                                  }
                                )}
                                <div class="col-md-6 mt-4 border-bottom border-3">
                                  <label>
                                    {" "}
                                    <b> उत्पन्नाबाबत जोड कागदपत्र:</b>
                                  </label>
                                  <br />
                                  {selectedItem.Data["IncomeDOcsName"]}{" "}
                                </div>
                              </div>
                              <div class="row g-3">
                                <div class="row g-3">
                                  <div class="row g-3" id="dvtext">
                               
                                    <div class="col-md-12">
                                      <label>
                                        <b>Upload required documents: :</b>{" "}
                                      </label>
                                      <br />
                                      <b style={{ color: "red" }}>
                                        अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर
                                        सर्व आवश्यक कागदपत्रे तपासून व स्कॅन
                                        करून अपलोड करावे. जर आवश्यक कागदपत्रे
                                        चुकीची किंवा अस्पष्ट आढळल्यास सदर चा
                                        अर्ज फेटाळला जाऊ शकतो.
                                      </b>{" "}
                                    </div>
                                    <div class="col-md-6 my-5">
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.FatherAdhar}>
                                        वडिलांचे आधार कार्ड
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.childrenAdhar}>
                                        मुलगा / मुलगी आधार कार्ड
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.applicantPhoto}>
                                        अर्जदार फोटो 
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.benificiarryPhoto}>
                                        लाभार्र्थी फोटो 
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.otherFirst}>
                                        Other
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.otherSecond}>
                                        Other||
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.consentform}>
                                        घर भाड्याने असल्यास संमती पत्र
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.lightBill}>
                                        लाईट बिल
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.form16}>तलाठी उत्पन्न अहवाल / फॉम १६ </a>
                                      </button>
                                      <br />
                                      {/* <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.photo}>photo</a>
                                      </button> */}
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.reshaCard}>
                                        रेशनकार्ड 
                                        </a>
                                      </button>
                                      <br />
                                      <button
                                        style={{
                                          border: "none",
                                          color: "blue",
                                          marginTop:'8px',
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <a href={selectedItem.selfDeclearation}>
                                        स्वघोषनापत्र
                                        </a>
                                      </button>
                                      <br />
                                      {selectedItem.acknowledgmentDocument !=
                                        null && (
                                        <>
                                          <button
                                            style={{
                                              border: "none",
                                              color: "blue",
                                              marginTop:"8px",
                                              backgroundColor: "transparent",
                                            }}
                                          >
                                            <a
                                              href={
                                                selectedItem.acknowledgmentDocument
                                              }
                                            >
                                              acknowledgment Document
                                            </a>
                                          </button>
                                          <br />
                                        </>
                                      )}

                                      {selectedItem.finalDocument != null && (
                                        <>
                                          <button
                                            style={{
                                              border: "none",
                                              color: "blue",
                                              marginTop:"8px",
                                              backgroundColor: "transparent",
                                            }}
                                          >
                                            <a
                                              href={selectedItem.finalDocument}
                                            >
                                              final Document
                                            </a>
                                          </button>
                                          <br />
                                        </>
                                      )}
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="row">
                                        <div className="col-lg-12 "><h3 className="fs-4 fw-3"><b>HISTORY</b></h3></div>
                                        <div className="col-lg-12 mt-2"><b>Current Status : </b>{selectedItem.status}</div>
                                        <div className="col-lg-12 "><b>Created On : </b>{GetDate(selectedItem.createdAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Submit Note : </b>{selectedItem.subNote}</div>
                                        <div className="col-lg-12 "><b>submit On : </b>{GetDate(selectedItem.submitAt)}</div>
                                        <div className="col-lg-12 mt-2"><b>Reject Note : </b>{selectedItem.rejNote}</div>
                                        <div className="col-lg-12 "><b>Reject On : </b>{GetDate(selectedItem.rejectAt)}</div>

                                        <div className="col-lg-12 mt-2"><b>Complete Note : </b>{selectedItem.compNote}</div>
                                        <div className="col-lg-12 "><b>Complete On : </b>{GetDate(selectedItem.CompleteAt)}</div>
                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {
                                userData.user.role=='agent'?(
                                  <>
                                   {selectedItem.status == "IN-PROGRESS" ? (
                                  <div class="row g-3" id="dvtext">
                                    <div class="col-md-6">
                                      <label>
                                        {" "}
                                        <b>
                                          {" "}
                                          Status:
                                          <span class="text-danger">*</span>
                                        </b>{" "}
                                      </label>
                                      <br />
                                      <select
                                        name="state"
                                        class="form-select"
                                        required
                                        id="state"
                                        onChange={(e) =>
                                          setStatus(e.target.value)
                                        }
                                      >
                                        <option value="" selected>
                                          Select Status
                                        </option>
                                        <option Value="SUBMITTED">
                                          SUBMITTED
                                        </option>
                                        <option Value="REJECTED">
                                          REJECTED
                                        </option>
                                      </select>
                                    </div>

                                    <div class="col-md-6">
                                      <label>
                                        <b>
                                          Note:
                                          <span class="text-danger">*</span>
                                        </b>{" "}
                                      </label>{" "}
                                      <br />
                                      <textarea
                                        name="note"
                                        class="form-control"
                                        onChange={(e)=>{
                                          if(status=="SUBMITTED"){
                                            setSubNote(e.target.value)
                                          }else{
                                            setRejNote(e.target.value)
                                          }
                                        }}
                                      >
                                        {" "}
                                      </textarea>{" "}
                                    </div>
                                    {status === "SUBMITTED" && (
                                      <div class="col-md-6">
                                        <label>
                                          <b>Acknowledgment Document </b>{" "}
                                        </label>
                                        <input
                                          type="file"
                                          onChange={(e) => {
                                            setAcknowledgment({
                                              ...Acknowledgment,
                                              Acknowledgment: e.target.files[0],
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ) : selectedItem.status == "SUBMITTED" ? (
                                  <div class="row g-3" id="dvtext">
                                    <div class="col-md-6">
                                      <label>
                                        {" "}
                                        <b>
                                          {" "}
                                          Status:
                                          <span class="text-danger">*</span>
                                        </b>{" "}
                                      </label>
                                      <br />
                                      <select
                                        name="state"
                                        class="form-select"
                                        required
                                        id="state"
                                        onChange={(e) =>
                                          setStatus(e.target.value)
                                        }
                                      >
                                        <option value="" selected>
                                          Select State
                                        </option>

                                        <option Value="COMPLETE">
                                          COMPLETE
                                        </option>
                                      </select>
                                    </div>

                                    <div class="col-md-6">
                                      <label>
                                        <b>
                                          Note:
                                          <span class="text-danger">*</span>
                                        </b>{" "}
                                      </label>{" "}
                                      <br />
                                      <textarea
                                        name="note"
                                        class="form-control"
                                        onChange={(e)=>{
                                          setCompNote(e.target.value);
                                        }}
                                      >
                                        {" "}
                                      </textarea>{" "}
                                    </div>
                                    {status === "COMPLETE" && (
                                      <div class="col-md-6">
                                        <label>
                                          <b>Final Document </b>{" "}
                                        </label>
                                        <input
                                          type="file"
                                          onChange={(e) => {
                                            setfinalDocs({
                                              ...finalDocs,
                                              finalDocs: e.target.files[0],
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div class="row g-3 " id="dvtext">
                                  <div class="col-md-12">
                                    <button
                                      type="submit"
                                      name="submit"
                                      id="btnsubmit"
                                      class="btn btn-primary"
                                      value="Submit"
                                      onClick={(e) => {
                                        FormSubmit(e);
                                      }}
                                    >
                                      Submit
                                    </button>

                                    <button
                                      type="submit"
                                      name="submit"
                                      id="btnsubmit"
                                      class="btn btn-primary "
                                      value="Submit"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                                  </>):""
                              }
                            </div>
                          </div>
                        </main>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Card
            style={{
              height: "80vh",
              width: "80vw",
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <h3>Loading...</h3>
          </Card>
        </>
      )}
    </>
  );
}

export default Retailer_income;
