import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import './styles.css';
// import './Application.css';
import swal from "sweetalert";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { incNumber } from "../../Redux/actions";
import Breadcrumb from "../common/breadcrumb";

function Retailer_noncremylayer() {
  const navigate =useNavigate();

  const userData = JSON.parse(localStorage.getItem("userResponse"));
  const userRespons = JSON.parse(localStorage.getItem("userResponse"));

  const [status, setStatus] = useState("");
  console.log("status", status);

  let { id } = useParams();
  const [selectedItem, setselectedItem] = useState(null);
  console.log(selectedItem);
  const [subNote, setSubNote] = useState("");
  const [compNote, setCompNote] = useState("");
  const [rejNote, setRejNote] = useState("");
  const dispatch = useDispatch();
  const form4 = useSelector((state) => state.formFour.form4);
  const [AllFOrm, setAllForm] = useState(form4);
  const getData = () => {
    if (AllFOrm.length > 0) {
      const selectedData = AllFOrm.find((item) => item._id === id);
      setselectedItem(selectedData);
    } else {
      axios
        .get("https://api.maharashtraseva.com/api/getAll")
        .then((res) => {
          const data = res.data;
          const selectedData = data.find((item) => item._id === id);
          setselectedItem(selectedData);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getData();
    if(
      userRespons.user.role === "agent" &&
      selectedItem?.status != "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ){
      axios
      .put(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`, { isNew: true })
      .then((res) => {
        dispatch(incNumber());
      })
      .catch((err) => console.log(err));
    }
   else if (
      userRespons.user.role === "agent" &&
      selectedItem?.status == "IN-PROGRESS" &&
      selectedItem?.isNew == true
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status == "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`, { isNew: true })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    } else if (
      selectedItem?.isNew == true &&
      selectedItem?.status != "IN-PROGRESS" &&
      userRespons.user.role === "retailer"
    ) {
      axios
        .put(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`, { isNew: false })
        .then((res) => {
          dispatch(incNumber());
        })
        .catch((err) => console.log(err));
    }
  }, [id, form4]);

  const [Acknowledgment, setAcknowledgment] = useState({
    Acknowledgment: "",
  });
  const [finalDocs, setfinalDocs] = useState({
    finalDocs: "",
  });
  const FormSubmit = (e) => {
    e.preventDefault();

    if (
      selectedItem.status === "IN-PROGRESS" ||
      selectedItem.status === "IN-Progress"
    ) {
      const formData = new FormData();

      formData.append("acknowledgmentDocument", Acknowledgment.Acknowledgment);
      axios
        .put(
          `https://api.maharashtraseva.com/api/NonCriminal_acknowledgmentDocument/${id}`,
          formData
        )
        .then((res) => console.log("acknowledgmentDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        subNote: subNote,
        submitAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`, obj)
        .then((res) => {
          console.log("acknowledgmentDocument", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedItem.status === "SUBMITTED") {
      const formData = new FormData();

      formData.append("finalDocument", finalDocs.finalDocs);
      axios
        .put(
          `https://api.maharashtraseva.com/api/NonCriminal_finalDocument/${id}`,
          formData
        )
        .then((res) => console.log("finalDocument", res.data))
        .catch((err) => {
          console.log(err);
        });
      const obj = {
        ...selectedItem,
        status: status,
        compNote: compNote,
        CompleteAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`, obj)
        .then((res) => {
          console.log("finalDocument", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    } else if (status === "REJECTED") {
      const obj = {
        ...selectedItem,
        status: status,
        rejNote: rejNote,
        rejectAt: new Date(),
        isNew: true,
      };
      axios
        .put(`https://api.maharashtraseva.com/api/Non_Criminal/${id}`, obj)
        .then((res) => {
          console.log("finalDocument", res.data);
          dispatch(incNumber());
          swal("updated successfully", "success");
        }).then(()=>navigate("/agent/application_Console"))
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const GetDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = inputDate.toLocaleString();
    return formattedDate;
  };
  return (
    <>
      <Breadcrumb title={"NONCREAMYLAYER CERTIFICATE"} />
      {selectedItem != null ? (
        <Container fluid={true}>
          <Row>
            <Col sm="">
              <Card>
                <CardBody>
                  <div
                    className="border my-3 p-4 border-3 bg-dark  text-dark bg-opacity-50 rounded "
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    <form class="inline-block form-inline ms-auto ">
                     <div className="row mb-2">
                        <div className="col-lg-10">
                        {/* <h3 class="box-title">
                          <span id="CPH_lbl_AppDtl">Details </span>
                        </h3> */}
                          
                        </div>
                        <div className="col-lg-2 mt-1">
                        <button
                            class="btn btn-outline-dark"
                            type="button"
                            onClick={() =>{
                              if (userRespons.user.role=="retailer") {
                                navigate(
                                  `${process.env.PUBLIC_URL}/RetailerForm4`
                                )
                              }else if(userRespons.user.role=="admin"){
                              navigate(
                                `${process.env.PUBLIC_URL}/ts`
                              )
                              }else{
                                navigate(
                                  `${process.env.PUBLIC_URL}/agent/application_Console`
                                )
                              }
                            }
                            }
                          >
                            Back
                          </button>
                        </div>
                      </div>
                      <div id="layoutSidenav_content">
                        <main>
                          <div class="formlayout">
                            {/* Applicant Details */}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Applicant Details
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  {" "}
                                  <b> Full Name (English) : </b>
                                </label>
                                {selectedItem.Data["statusfname"] +
                                  ".  " +
                                  selectedItem.Data["fullName_English"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Full Name (Marathi) : </b>{" "}
                                </label>
                                {selectedItem.Data["fullName_Marathi"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Father's Name (English) : </b>{" "}
                                </label>
                                {selectedItem.Data["fatherName_english"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Father's Name (Marathi) : </b>{" "}
                                </label>
                                {selectedItem.Data["fatherName_marathi"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Date of Birth : </b>{" "}
                                </label>
                                {selectedItem.Data["BirthDate"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Age : </b>{" "}
                                </label>
                                {selectedItem.Data["age"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Gender : </b>{" "}
                                </label>
                                {selectedItem.Data["Gender"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Gender : </b>{" "}
                                </label>
                                {selectedItem.Data["Gender"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Mobile No : </b>{" "}
                                </label>
                                {selectedItem.Data["phoneNUm"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Email :</b>{" "}
                                </label>
                                {selectedItem.Data["email"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>UDI NO : </b>{" "}
                                </label>
                                {selectedItem.Data["UDI_NO"]}{" "}
                              </div>
                            </div>
                            {/*Applicant Address */}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Applicant Address
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Address : </b>{" "}
                                </label>
                                {selectedItem.Data["Applicant_address"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Street : </b>{" "}
                                </label>
                                {selectedItem.Data["Applicant_street"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Building : </b>{" "}
                                </label>
                                {selectedItem.Data["Applicant_Building"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  {" "}
                                  <b> Locality : </b>
                                </label>
                                {selectedItem.Data["Applicant_Locality"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Landmark : </b>{" "}
                                </label>
                                {selectedItem.Data["Applicant_landmark"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b> District : </b>{" "}
                                </label>
                                {selectedItem.Data["Applicant_district"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Taluka : </b>{" "}
                                </label>
                                {selectedItem.Data["Applicant_taluka"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b> Village : </b>{" "}
                                </label>
                                {selectedItem.Data["Applicant_village"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Pincode : </b>{" "}
                                </label>
                                {selectedItem.Data["Applicant_pincode"]}{" "}
                              </div>
                            </div>
                            {/* Relation of Beneficiary with Applicant */}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Relation of Beneficiary with Applicant:
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-12 mt-4 border-bottom border-3">
                                <label>
                                  <b> relation Of Banificiary </b>{" "}
                                </label>
                                {
                                  selectedItem.Data[
                                    "relationOfBanificiaryWithApplicant"
                                  ]
                                }{" "}
                              </div>
                            </div>
                            {/* Beneficiary Details  */}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Beneficiary Details :
                                  </span>
                                </h3>
                              </div>
                              {/*
   
    benificiary_Address: "",
    benificiary_Street: "",
    benificiary_Locality: "",
    benificiary_Building: "",
    benificiary_Landmark: "",
    benificiary_District: "",
    benificiary_Taluka: "",
    benificiary_Village: "",
    benificiary_Pincode: "",
    benificiaryFatherOrHusbund_Salutation: "",
    benificiaryFatherOrHusbund_FullNameEnglish: "",
    benificiaryFatherOrHusbund_FullNameMarathi: "",
    benificiaryFatherOrHusbund_Dob: "",
    benificiaryFatherOrHusbund_Gender: "",
    benificiaryFatherOrHusbund_MobileNO: "",
    benificiaryFatherOrHusbund_Email: "",
    benificiaryFatherOrHusbund_Uid: "", */}
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Full Name English : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Salutation"] +" "+ selectedItem.Data["benificiary_FullNameEnglish"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Full Name Marathi : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_FullNameMarathi"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Date Of Birth : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Dob"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Gender : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Gender"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Mobile NO : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_MobileNO"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Email : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Email"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary UID : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Uid"]} {" "} 
                              </div>
                            </div>
                            {/* Beneficiary Address  */}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Beneficiary Address :
                                  </span>
                                </h3>
                              </div>
                              {/*
    benificiaryFatherOrHusbund_Salutation: "",
    benificiaryFatherOrHusbund_FullNameEnglish: "",
    benificiaryFatherOrHusbund_FullNameMarathi: "",
    benificiaryFatherOrHusbund_Dob: "",
    benificiaryFatherOrHusbund_Gender: "",
    benificiaryFatherOrHusbund_MobileNO: "",
    benificiaryFatherOrHusbund_Email: "",
    benificiaryFatherOrHusbund_Uid: "", */}
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Address: </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Address"] } {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Street : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Street"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Locality : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Locality"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Building : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Building"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Landmark: </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Landmark"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary District : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_District"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Taluka : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Taluka"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Village : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Village"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Pincode : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Pincode"]} {" "} 
                              </div>
                            </div>
                            {/* Benificiary Father Details  */}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                  Benificiary Father Details :
                                  </span>
                                </h3>
                              </div>
                              {/*
    benificiaryFatherOrHusbund_Salutation: "",
    benificiaryFatherOrHusbund_FullNameEnglish: "",
    benificiaryFatherOrHusbund_FullNameMarathi: "",
    benificiaryFatherOrHusbund_Dob: "",
    benificiaryFatherOrHusbund_Gender: "",
    benificiaryFatherOrHusbund_MobileNO: "",
    benificiaryFatherOrHusbund_Email: "",
    benificiaryFatherOrHusbund_Uid: "", */}
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary Father Or Husbund FullName English: </b>{" "}
                                </label>
                                {selectedItem.Data["benificiaryFatherOrHusbund_Salutation"]+" "+ selectedItem.Data["benificiaryFatherOrHusbund_FullNameEnglish"] } {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiaryFatherOrHusbund_FullNameMarathi : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiaryFatherOrHusbund_FullNameMarathi"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiary benificiaryFatherOrHusbund_Dob : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiaryFatherOrHusbund_Dob"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiaryFatherOrHusbund_Gender : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiaryFatherOrHusbund_Gender"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>benificiaryFatherOrHusbund_MobileNO: </b>{" "}
                                </label>
                                {selectedItem.Data["benificiaryFatherOrHusbund_MobileNO"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3  p-3">
                                <label>
                                  <b>benificiaryFatherOrHusbund_Email : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiaryFatherOrHusbund_Email"]} {" "} 
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3  p-3">
                                <label>
                                  <b>benificiaryFatherOrHusbund_Uid : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiaryFatherOrHusbund_Uid"]} {" "} 
                              </div>
                              
                            </div>
                            {/*Beneficiary Caste/ Category Details*/}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Beneficiary Caste/ Category Details :
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Benificiary Cast : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Cast"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Benificiary Catagary : </b>{" "}
                                </label>
                                {selectedItem.Data["benificiary_Catagary"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Benificiary Validity Duration : </b>{" "}
                                </label>
                                {
                                  selectedItem.Data[
                                    "brnificiary_Validity_Duration"
                                  ]
                                }{" "}
                              </div>
                            </div>
                            {/*If migrated from one district to another district? (Yes/No)*/}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    If migrated from one district to another
                                    district? (Yes/No) :
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>migration From Another State : </b>{" "}
                                </label>
                                {
                                  selectedItem.Data[
                                    "migration_MH_From_Another_State"
                                  ]
                                }{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Migrant Distict : </b>{" "}
                                </label>
                                {selectedItem.Data["Migrant_Distict"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Migrant Taluka : </b>{" "}
                                </label>
                                {selectedItem.Data["Migrant_Taluka"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Migrant village : </b>{" "}
                                </label>
                                {selectedItem.Data["Migrant_village"]}{" "}
                              </div>
                            </div>
                            {/*Have you applied before for Caste Certificate (including other District) (Yes/No)*/}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Have you applied before for Caste
                                    Certificate (including other District)
                                    (Yes/No) :
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>
                                    {" "}
                                    Have you applied before for Caste
                                    Certificate :{" "}
                                  </b>{" "}
                                </label>
                                {
                                  selectedItem.Data[
                                    "isAppliedForCastCertificate"
                                  ]
                                }
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b> Is Certificate Given? : </b>{" "}
                                </label>
                                {selectedItem.Data["is_Certificate_Given"]}
                              </div>

                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>previous Cast Certificate Number</b>{" "}
                                </label>
                                {selectedItem.Data["previos_CastCerNum"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>previous Cast Certificate Place Issue</b>{" "}
                                </label>
                                {selectedItem.Data["previos_CastCerPlaceIssue"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>previous Cast Certificate Issued Date</b>{" "}
                                </label>
                                {selectedItem.Data["previos_CastCerIssuedDate"]}{" "}
                              </div>
                            </div>
                            {/*Income Details*/}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Income Details :
                                  </span>
                                </h3>
                              </div>

                              {selectedItem.NonCreamyLayerincomeSource.map(
                                (item) => {
                                  const keys = Object.keys(item);
                                  console.log(507, keys);
                                  return (
                                    <>
                                      <div className="row mt-4 border-bottom border-3 ">
                                        <div class="col-md-6 mt-3 border-bottom border-2">
                                          <label>
                                            <b>{keys[5]}</b>{" "}
                                          </label>
                                          <br />
                                          {item.source}{" "}
                                        </div>
                                        <div class="col-md-6 mt-3 border-bottom border-2">
                                          <label>
                                            <b> {keys[0]}</b>
                                          </label>
                                          <br />
                                          {item["2021"]}
                                        </div>
                                        <div class="col-md-6 mt-3 border-bottom border-2">
                                          <label>
                                            <b>{keys[1]}</b>
                                          </label>
                                          <br />
                                          {item["2022"]}
                                        </div>
                                        <div class="col-md-6 mt-3 border-bottom border-2">
                                          <label>
                                            <b>{keys[2]}</b>{" "}
                                          </label>
                                          <br />
                                          {item["2023"]}
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </div>

                            {/*Occupation and Income Details of Applicant’s Family*/}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Occupation and Income Details of Applicant's
                                    Family :
                                  </span>
                                </h3>
                              </div>

                              {selectedItem.occupation_income.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <div className="row mt-5">
                                        <div class="col-md-12 mt-3 border-bottom border-4 text-center">
                                          <label>
                                            <b>{item.person}</b>{" "}
                                          </label>
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b>Type of Occupation : </b>{" "}
                                          </label>
                                          {item.type}{" "}
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b> Name of Organization : </b>{" "}
                                          </label>
                                          {item.organizationName}{" "}
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b> Designation/Rank : </b>{" "}
                                          </label>
                                          {item.designation}{" "}
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b>Department : </b>{" "}
                                          </label>
                                          {item.department}{" "}
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b>Salary Range : </b>{" "}
                                          </label>
                                          {item.salaryRange}{" "}
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b> Total Annual Income : </b>{" "}
                                          </label>
                                          {item.annualIncome}{" "}
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b>
                                              Date of Joining (dd/mm/yyyy) :{" "}
                                            </b>{" "}
                                          </label>
                                          {item.dateOfJoining}{" "}
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b>
                                              If Pensioner, date of
                                              retirement(dd/mm/yyyy) :{" "}
                                            </b>{" "}
                                          </label>
                                          {item.dateOfRetirement}{" "}
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b>Type of Business : </b>{" "}
                                          </label>
                                          {item.dateOfRetirement}{" "}
                                        </div>
                                        <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                          <label>
                                            <b>Total Annual Income : </b>{" "}
                                          </label>
                                          {item.totalAnnualIncome}{" "}
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                              <div class="col-md-6 mt-5 border-bottom border-3">
                                <label>
                                  <b>Owner of property : </b>{" "}
                                </label>
                                {selectedItem.Data["propowner"]}{" "}
                              </div>
                              <div class="col-md-6 mt-5 border-bottom border-3">
                                <label>
                                  <b>Location : </b>{" "}
                                </label>
                                {selectedItem.Data["txtLocation"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Area : </b>{" "}
                                </label>
                                {selectedItem.Data["txtarea"]}{" "}
                                {selectedItem.Data["ddlareaunits"]}{" "}
                              </div>

                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Type of agricultural land : </b>{" "}
                                </label>
                                {selectedItem.Data["checkbox"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Area of irrigated land : </b>{" "}
                                </label>
                                {selectedItem.Data["txtirriarea"]}
                                {selectedItem.Data["ddlunits"]}
                              </div>

                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>
                                    Percentage of irrigated land as per
                                    constitutional ceiling under the state land
                                    ceiling act formula :
                                  </b>{" "}
                                </label>
                                {selectedItem.Data["txtpercentage"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>
                                    If you own both irrigated and dry land,
                                    percentage of irrigated land as per
                                    constitutional ceiling under the state land
                                    ceiling act formula As per :{" "}
                                  </b>{" "}
                                </label>
                                {selectedItem.Data["txtformula"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>
                                    {" "}
                                    total irrigated land as a percentage as
                                    calculated by the land ceiling act formula :
                                  </b>{" "}
                                </label>
                                {selectedItem.Data["txtceilingactform"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>
                                    Cultivation details Type of cultivation :
                                  </b>{" "}
                                </label>
                                {selectedItem.Data["ddlcultitype"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Location : </b>{" "}
                                </label>
                                {selectedItem.Data["txtcultilocation"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Area of irrigated land</b>{" "}
                                </label>
                                {selectedItem.Data["txtcultiarea"]}{" "}
                                {selectedItem.Data["ddlcultiunit"]}
                              </div>

                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Location of property</b>{" "}
                                </label>
                                {selectedItem.Data["txtproploc"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Details of property</b>{" "}
                                </label>
                                {selectedItem.Data["txtpropdetails"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Current use of property</b>{" "}
                                </label>
                                {selectedItem.Data["txtpropuse"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>
                                    Are you an income tax payee (Attach
                                    assessment if yes)
                                  </b>{" "}
                                </label>
                                {selectedItem.Data["CPH_radiobtnpayee"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>
                                    Have you paid wealth tax(Attach details)
                                  </b>{" "}
                                </label>
                                {selectedItem.Data["radiobtnwealthtax"]}{" "}
                              </div>
                            </div>
                            {/*Other Family Details*/}
                            <div class="row g-3 mb-4">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Other Family Details :
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Mother Name (English) :</b>{" "}
                                </label>
                                {selectedItem.Data["txtmothername"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Mother Name (Marathi) : </b>{" "}
                                </label>
                                {selectedItem.Data["txtmothername_LL"]}{" "}
                              </div>
                              {/* <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>mother salary : </b>{" "}
                                </label>
                                {selectedItem.Data["ddlmothersal"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>spouse salary : </b>{" "}
                                </label>
                                {selectedItem.Data["ddlspousesal"]}{" "}
                              </div> */}
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Spouse Name (English) : </b>{" "}
                                </label>
                                {selectedItem.Data["txtSpouseName"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Spouse Name (Marathi) : </b>{" "}
                                </label>
                                {selectedItem.Data["txtspousename_LL"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b> District : </b>{" "}
                                </label>
                                {selectedItem.Data["ddlfam_dist"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Taluka :</b>{" "}
                                </label>
                                {selectedItem.Data["ddlfam_taluka"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Village : </b>{" "}
                                </label>
                                {selectedItem.Data["ddlfam_Vill"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Pincode : </b>{" "}
                                </label>
                                {selectedItem.Data["txtfam_pincode"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Beneficial Family reason : </b>{" "}
                                </label>
                                {selectedItem.Data["txtbenefreason"]}{" "}
                              </div>
                              <div class="col-md-6 mt-4 border-bottom border-3 p-3">
                                <label>
                                  <b>Do you need the Affidavit?: </b>{" "}
                                </label>
                                {selectedItem.Data["ctl00$CPH$Affd"]}{" "}
                              </div>
                            </div>

                            <div className="row g-3">
                              <div
                                className="col-lg-12 text-center border rounded"
                                style={{ backgroundColor: "#e0e0e0" }}
                              >
                                <h3 class="box-title">
                                  <span id="CPH_lbl_AppDtl">
                                    Upload required documents :
                                  </span>
                                </h3>
                              </div>
                              <div class="col-md-12">
                                {/* <label>
                                <b>Upload required documents: :</b>{" "}
                              </label> */}
                                <b style={{ color: "red" }}>
                                  अर्ज पुढील कार्यवाहिस पाठवण्या अगोदर सर्व
                                  आवश्यक कागदपत्रे तपासून व स्कॅन करून अपलोड
                                  करावे. जर आवश्यक कागदपत्रे चुकीची किंवा
                                  अस्पष्ट आढळल्यास सदर चा अर्ज फेटाळला जाऊ शकतो.
                                </b>{" "}
                              </div>

                              <div class="col-md-6 ">
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.FatherAdhar}>
                                    FatherAdhar
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.childrenAdhar}>
                                    childrenAdhar
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.castCertificate}>
                                    castCertificate
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.lightBill}>lightBill</a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.applicantPhoto}>
                                    applicantPhoto
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.benificiarryPhoto}>
                                    benificiarryPhoto
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.reshanCard}>
                                    reshanCard
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.fatherSchoolLeaveCer}>
                                    fatherSchoolLeaveCer
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.childrenSchoolLeaveCer}>
                                    childrenSchoolLeaveCer
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.childrenSchoolLeaveCer}>
                                    childrenSchoolLeaveCer
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.taxRecipt}>taxRecipt</a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.incomeCertficate3year}>
                                    incomeCertficate3year
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.otherFirst}>
                                    otherFirst
                                  </a>
                                </button>
                                <br />
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    backgroundColor: "transparent",
                                    marginTop: "5px",
                                  }}
                                >
                                  <a href={selectedItem.otherSecond}>
                                    otherSecond
                                  </a>
                                </button>
                                <br />

                                {selectedItem.acknowledgmentDocument !=
                                  null && (
                                  <>
                                    <button
                                      style={{
                                        border: "none",
                                        color: "blue",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <a
                                        href={
                                          selectedItem.acknowledgmentDocument
                                        }
                                      >
                                        Acknowledgment Document
                                      </a>
                                    </button>
                                  </>
                                )}
                                <br />
                                {selectedItem.finalDocument != null && (
                                  <>
                                    <button
                                      style={{
                                        border: "none",
                                        color: "blue",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <a href={selectedItem.finalDocument}>
                                        final Document
                                      </a>
                                    </button>
                                  </>
                                )}
                              </div>
                              <div className="col-lg-6">
                                <div className="row">
                                  <div className="col-lg-12 ">
                                    <h3 className="fs-4 fw-3">
                                      <b>HISTORY</b>
                                    </h3>
                                  </div>
                                  <div className="col-lg-12 mt-2">
                                    <b>Current Status : </b>
                                    {selectedItem.status}
                                  </div>
                                  <div className="col-lg-12 ">
                                    <b>Created On : </b>
                                    {GetDate(selectedItem.createdAt)}
                                  </div>

                                  <div className="col-lg-12 mt-2">
                                    <b>Submit Note : </b>
                                    {selectedItem.subNote}
                                  </div>
                                  <div className="col-lg-12 ">
                                    <b>submit On : </b>
                                    {GetDate(selectedItem.submitAt)}
                                  </div>
                                  <div className="col-lg-12 mt-2">
                                    <b>Reject Note : </b>
                                    {selectedItem.rejNote}
                                  </div>
                                  <div className="col-lg-12 ">
                                    <b>Reject On : </b>
                                    {GetDate(selectedItem.rejectAt)}
                                  </div>

                                  <div className="col-lg-12 mt-2">
                                    <b>Complete Note : </b>
                                    {selectedItem.compNote}
                                  </div>
                                  <div className="col-lg-12 ">
                                    <b>Complete On : </b>
                                    {GetDate(selectedItem.CompleteAt)}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {userData.user.role == "agent" ? (
                              <>
                                {selectedItem.status == "IN-PROGRESS" ? (
                                  <div class="row g-3" id="dvtext">
                                    <div class="col-md-6">
                                      <label>
                                        {" "}
                                        <b>
                                          {" "}
                                          Status:
                                          <span class="text-danger">*</span>
                                        </b>{" "}
                                      </label>
                                      <br />
                                      <select
                                        name="state"
                                        class="form-select"
                                        required
                                        id="state"
                                        onChange={(e) =>
                                          setStatus(e.target.value)
                                        }
                                      >
                                        <option value="" selected>
                                          Select State
                                        </option>
                                        <option Value="SUBMITTED">
                                          SUBMITTED
                                        </option>
                                        <option Value="REJECTED">
                                          REJECTED
                                        </option>
                                      </select>
                                    </div>

                                    <div class="col-md-6">
                                      <label>
                                        <b>
                                          Note:
                                          <span class="text-danger">*</span>
                                        </b>{" "}
                                      </label>{" "}
                                      <br />
                                      <textarea
                                        name="note"
                                        class="form-control"
                                        onChange={(e) => {
                                          if (status == "SUBMITTED") {
                                            setSubNote(e.target.value);
                                          } else {
                                            setRejNote(e.target.value);
                                          }
                                        }}
                                      >
                                        {" "}
                                      </textarea>{" "}
                                    </div>
                                    {status === "SUBMITTED" && (
                                      <div class="col-md-6">
                                        <label>
                                          <b>Acknowledgment Document </b>{" "}
                                        </label>
                                        <input
                                          type="file"
                                          onChange={(e) => {
                                            setAcknowledgment({
                                              ...Acknowledgment,
                                              Acknowledgment: e.target.files[0],
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ) : selectedItem.status == "SUBMITTED" ? (
                                  <div class="row g-3" id="dvtext">
                                    <div class="col-md-6">
                                      <label>
                                        {" "}
                                        <b>
                                          {" "}
                                          Status:
                                          <span class="text-danger">*</span>
                                        </b>{" "}
                                      </label>
                                      <br />
                                      <select
                                        name="state"
                                        class="form-select"
                                        required
                                        id="state"
                                        onChange={(e) =>
                                          setStatus(e.target.value)
                                        }
                                      >
                                        <option value="" selected>
                                          Select State
                                        </option>

                                        <option Value="COMPLETE">
                                          COMPLETE
                                        </option>
                                      </select>
                                    </div>

                                    <div class="col-md-6">
                                      <label>
                                        <b>
                                          Note:
                                          <span class="text-danger">*</span>
                                        </b>{" "}
                                      </label>{" "}
                                      <br />
                                      <textarea
                                        name="note"
                                        class="form-control"
                                        onChange={(e) => {
                                          setCompNote(e.target.value);
                                        }}
                                      >
                                        {" "}
                                      </textarea>{" "}
                                    </div>
                                    {status === "COMPLETE" && (
                                      <div class="col-md-6">
                                        <label>
                                          <b>Final Document </b>{" "}
                                        </label>
                                        <input
                                          type="file"
                                          onChange={(e) => {
                                            setfinalDocs({
                                              ...finalDocs,
                                              finalDocs: e.target.files[0],
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div class="row g-3 " id="dvtext">
                                  <div class="col-md-12">
                                    <button
                                      type="submit"
                                      name="submit"
                                      id="btnsubmit"
                                      class="btn btn-primary"
                                      value="Submit"
                                      onClick={(e) => {
                                        FormSubmit(e);
                                      }}
                                    >
                                      Submit
                                    </button>

                                    <button
                                      type="submit"
                                      name="submit"
                                      id="btnsubmit"
                                      class="btn btn-primary "
                                      value="Submit"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </main>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Card
            style={{
              height: "80vh",
              width: "80vw",
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <h3>Loading...</h3>
          </Card>
        </>
      )}
    </>
  );
}

export default Retailer_noncremylayer;
