import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useSelector } from "react-redux";
import './LandPage.css'

function OurTeam() {
  const [Employee, setEmployee] = useState([]);
  const reducer = useSelector((state) => state.changeNumber);

  useEffect(() => {
    axios
      .get(`https://api.maharashtraseva.com/api/ourteam`)
      .then((res) => {
        console.log("banner", res.data);
        const data = res.data.reverse();
        setEmployee(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reducer]);

  return (
    <>
      <Header />
      <div className="row text-center" style={{ marginTop: "150px" }}>
        <div class="ourTeam">
          <h1>OUR TEAM </h1>
        </div>
        <div className="col-lg-12">
          <div class="card-category-5">
            {/* <div class="category-name">Personal Cards</div> <br />
          <br /> */}
            <ul class="all-pr-cards">
              {Employee.map((item) => {
                return (
                  <>
                    <li className="m-4">
                      <div class="per-card-1">
                        <div class="card-image">
                          <img src={item.photoID} />
                        </div>

                        <div class="card-content">
                          <div class="card-title">{item.FullName}</div>
                          <div class="per-position">{item.Designation}</div>
                          <div class="per-position">{item.Department}</div>
                          <div class="social-icons"></div>
                          <div class="card-btn  text-center">
                            {/* <button type="button" title="Delete">
                              <i
                                class="bi bi-trash3 fs-2 text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => deleteUser(item._id)}
                              ></i>
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurTeam;
