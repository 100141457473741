import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import "./log.css";
import Mesk_logo2 from "../../assets/images/image/Mesk_logo2.png";
import Logo1 from "../../assets/images/image/logoTm.jpg";

import "./log.css";
const Login = () => {
  const navigate = useNavigate();
  // const history = useNavigate();
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const users = useSelector((state) => state.userData.user);
  const submitHandler = (e) => {
    e.preventDefault();
    const obj = {
      username,
      password,
    };
    axios
      .post("https://api.maharashtraseva.com/api/signin", obj)
      .then((res) => {
        console.log(29, res);
        localStorage.setItem("userResponse", JSON.stringify(res.data));
        console.log(26, res.data.user.role);
        if (res.data.user.role == "retailer") {
          navigate("/RetailerDashboard");
        } else if (res.data.user.role == "admin") {
          // navigate('/RetailerDashboard')
          navigate("/dashboard");
        } else if (res.data.user.role == "agent") {
          // navigate('/RetailerDashboard')
          navigate("/AgentDashboard");
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };
  function myFunction() {
    var x = document.getElementById("myinput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  return (
    <Fragment>
      <div className="full">
        <section>
          <div class="content">
            <div class="left">
              <img className="sideImg" src={Logo1} alt="icon" />
              {/* <img className="headline" src="https://mhae-seva.s3.amazonaws.com/MahaESeva3.acec7a3eac4dff96c96d.png" alt="icon" /> */}
              {/* <h1 className="headline">MeSK™</h1> */}
            </div>

            <div class="right ">
              <div class="title text-wrap">
                <h6>Welcome to Maharashtra e-Seva Kendra™ </h6>
              </div>
              <div class="form">
                <form>
                  <div class="inputbox">
                    <label className="lab">Username</label>
                    <input
                      type="text"
                      placeholder="Enter Username"
                      required="required"
                      className="inp"
                      onChange={(e) => {
                        setusername(e.target.value);
                      }}
                      value={username}
                    />
                  </div>
                  <div class="inputbox">
                    <label className="lab">Password</label>
                    <input
                      type="password"
                      placeholder="Enter Your Password"
                      required="required"
                      className="inp"
                      id="myinput"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                    />
                  </div>

                  <div class="links">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      onClick={myFunction}
                    />
                    <label className="seva mx-2" for="flexCheckChecked">
                      Show Password
                    </label>
                  </div>
                  <div class="create">
                    <button type="submit"
                     value="Login"
                     className="Subbtn"
                     onClick={(e) => submitHandler(e)}
                    >Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        {/* <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
<script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script> */}
        {/* </div> */}
      </div>
    </Fragment>
  );
};

export default Login;
